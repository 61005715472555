import { getAuthCurrentOrganisationSubscriptionActive, getAuthUser, getOrganisation, getOrganisationMemberships } from '../../store/selectors';

export const OrganisationFreeTrialBannerComponent = {
    template: require('!raw-loader!./free-trial-banner.component.html').default,
    controllerAs: 'ctrl',
    controller: class OrganisationFreeTrialBannerController {

        static $inject = ['$ngRedux', '$scope', '$state', '$mdDialog', 'OrganisationsActions', 'hostname', 'protocol', 'port'];
        constructor($ngRedux, $scope, $state, $mdDialog, OrganisationsActions, hostname, protocol, port) {
            this.$ngRedux = $ngRedux;
            this.$scope = $scope;
            this.$state = $state;
            this.$mdDialog = $mdDialog;
            this.OrganisationsActions = OrganisationsActions;
            this.$scope.$on('$destroy', this.$ngRedux.connect((state) => {
                let cbPort = '';
                if (port && port !== '80' && port !== '443') {
                    cbPort = ':' + port;
                }
                const baseUrl = [protocol, '://', hostname, cbPort].join('');
                const user = getAuthUser(state);
                return {
                    baseUrl,
                    isSubscriptionActive: getAuthCurrentOrganisationSubscriptionActive(state),
                    userId: user ? user.id : null,
                    organisation: getOrganisation(state),
                    isOrganisationPage: !!this.isOrgPage()
                }
            }, null)(this));
        }

        removeMembers(ids) {
            this.$ngRedux.dispatch(this.OrganisationsActions.removeOrganisationMembership(ids));
        }

        cancelInvite(pendingEmail) {
            this.$ngRedux.dispatch(this.OrganisationsActions.cancelPendingOrganisationInvite(pendingEmail));
        }

        isOrgPage() {
            return this.$state.includes('home') || this.$state.includes('project') || this.$state.includes('organisation-admin');
        }
        
    }
}