import { Component, signal, computed, inject } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { ButtonModule } from 'primeng/button';
import { DialogModule } from 'primeng/dialog';
import { DynamicDialogConfig, DynamicDialogRef } from "primeng/dynamicdialog";
import { InputTextModule } from 'primeng/inputtext';
import { DropdownModule } from 'primeng/dropdown';
import { MapBuilderService } from "./map-builder.service";

interface SelectionType {
    id: string | number;
    label: string;
}

const layerTypes: SelectionType[] = [
    { id: 'heatmap', label: 'Heatmap' },
    { id: 'raster', label: 'Raster Image' },
    { id: 'geojson', label: 'Shapefile' }
];

const sourceTypes: SelectionType[] = [
    { id: 'records', label: 'Coreo Records' },
    { id: 'collections', label: 'Coreo Collections' }
];

@Component({
    template: `
    <div class="flex flex-col gap-y-2">
      <label for="name">Layer Name *</label>
      <input type="text" pInputText placeholder="Name" [(ngModel)]="layerName" required class="w-full"/>
    </div>
    <div class="flex flex-col gap-y-2">
      <label for="name">Layer Type *</label>
      <p-dropdown appendTo="body" [options]="layerTypes" optionLabel="label" optionValue="id" 
        [(ngModel)]="selectedLayerType" [showClear]="true" placeholder="Select a layer type" 
        [disabled]="isLoading()" (onChange)="clearAll()"/>
    </div>
    @switch (selectedLayerType) {
      @case ('heatmap') {
      <div class="flex flex-col gap-y-2">
        <label for="name">Heatmap Data Source *</label>
        <p-dropdown appendTo="body" [options]="sourceTypes" optionLabel="label" optionValue="id" 
          [(ngModel)]="selectedSourceType" [showClear]="true" placeholder="Select a source type" (onChange)="clearTypes()"/>
      </div>
      @switch (selectedSourceType) {
        @case ('records') {
        <div class="flex flex-col gap-y-2">
          <label for="name">Choose Form *</label>
          <p-dropdown appendTo="body" [options]="records" optionLabel="label" optionValue="id" 
            [(ngModel)]="selectedRecord" [showClear]="true" placeholder="Select a Form" />
        </div>
        }
        @case ('collections') {
        <div class="flex flex-col gap-y-2">
          <label for="name">Choose Collection *</label>
          <p-dropdown appendTo="body" [options]="collections" optionLabel="label" optionValue="id" 
            [(ngModel)]="selectedCollection" [showClear]="true" placeholder="Select a Collection" />
        </div>
        }
      }
      }
      @case ('raster') {
        @if (isLoading() || fileName()) {
          <div class="flex items-center gap-x-2">
            <span class="text-xs text-text-primary font-normal">{{ fileName() || 'Uploading Image' }}</span>
            @if (isLoading()) {
              <i class="pi pi-spin pi-spinner text-xs"></i>
            } @else {
              <i class="pi pi-times text-xs cursor-pointer" (click)="clearAll()"></i>
            }
          </div>
          @if (layerConfig()['source']) {
          <img [src]="layerConfig()['source']" class="w-auto h-48 object-contain" />
          }
        } @else {
          <p-button label="Upload GeoTIFF" [outlined]="true" (click)="uploadFile('image-upload')" />
          <span class="block w-full p-2 rounded text-xs font-semibold bg-warning">
            Image data will be automatically resized to a maximum width and/or height of 4096 pixels.
          </span>
          <input id="image-upload" accept="image/*" name="image" type="file" class="hidden" required (change)="handleImageFile($event)" />
        }
      }
      @case ('geojson') {
        @if (isLoading() || fileName()) {
          <div class="flex items-center gap-x-2">
            <span class="text-xs text-text-primary font-normal">{{ fileName() || 'Uploading Shapefile' }}</span>
            @if (isLoading()) {
              <i class="pi pi-spin pi-spinner text-xs"></i>
            } @else {
              <i class="pi pi-times text-xs cursor-pointer" (click)="clearAll()"></i>
            }
          </div>
        } @else{
          <p-button label="Upload Shapefile" [outlined]="true" (click)="uploadFile('shapefile-upload')" />
          <input id="shapefile-upload" accept=".zip" name="geojson" type="file" class="hidden" required (change)="handleShapefileFile($event)" />
        }
      }
    }
    @if (isBadFile()) {
    <span class="block w-full p-2 rounded text-xs text-white font-semibold bg-danger">
      Unable to process {{ selectedLayerType === 'raster' ? 'GeoTIFF image' : 'Shapefile' }}. Ensure 
      the file provided is a valid {{ selectedLayerType === 'raster' ? 'GeoTIFF' : 'Shapefile' }} with 
      the required metadata.
    </span>
    }
    <div class="pt-5 flex justify-end">
        <p-button label="Create Layer" size="large" (click)="onHideDialog()" [disabled]="!isValidConfig"/>
    </div>
  `,
    selector: 'app-map-builder-create-layer',
    standalone: true,
    imports: [
        FormsModule,
        ButtonModule,
        DialogModule,
        InputTextModule,
        DropdownModule
    ],
    styles: [`
    :host {
      @apply flex flex-col gap-y-4;
    }

    label {
      @apply text-xs text-text-primary font-normal flex justify-between items-center;
    }
  `]
})
export class MapBuilderCreateLayerComponent {

    records: SelectionType[];
    collections: SelectionType[];
    projectId: number;

    ref = inject(DynamicDialogRef);
    dialogConfig = inject(DynamicDialogConfig);
    mapBuilderService = inject(MapBuilderService);

    layerTypes = layerTypes;
    sourceTypes = sourceTypes;

    layerName: string;
    selectedLayerType: string;
    selectedSourceType: string;
    selectedRecord: number;
    selectedCollection: number;

    isLoading = signal<boolean>(false);
    isBadFile = signal<boolean>(false);
    fileName = signal<string>('');
    layerConfig = signal({});

    ngOnInit() {
        this.records = this.dialogConfig.data['records'];
        this.collections = this.dialogConfig.data['collections'];
        this.projectId = this.dialogConfig.data['projectId'];
    }

    uploadFile(id: string) {
        const file = document.getElementById(`${id}`);
        file.click();
    }

    handleImageFile = (event: any) => {
        const file: File = event.target.files[0];
        if (file?.type !== "image/tiff") {
            this.isBadFile.set(true);
            return;
        }

        this.isLoading.set(true);
        this.isBadFile.set(false);

        this.mapBuilderService.uploadGeoTIFF(this.projectId, file)
            .then(({ url: source, bounds }) => {
                this.isLoading.set(false);
                this.fileName.set(file.name);
                this.layerConfig.set({ source, layout: bounds.slice(0, 4), sourceType: 'image' });
            })
            .catch(err => {
                console.error(err);
                this.isBadFile.set(true);
                this.isLoading.set(false);
            });
    }

    handleShapefileFile = (event: any) => {
        const file: File = event.target.files[0];

        const validTypes = [
            'application/zip',
            'application/x-zip-compressed',
            'application/octet-stream',
            'application/x-zip',
        ];

        if (!file || !validTypes.includes(file.type) && !file.name.toLowerCase().endsWith('.zip')) {
            this.isBadFile.set(true);
            return;
        }

        this.isLoading.set(true);
        this.isBadFile.set(false);

        this.mapBuilderService.uploadShapefile(this.projectId, file)
            .then(({ url: source, bounds }) => {
                this.isLoading.set(false);
                this.fileName.set(file.name);
                this.layerConfig.set({ source, bounds, sourceType: 'geojson' });
            })
            .catch(err => {
                console.error(err);
                this.isBadFile.set(true);
                this.isLoading.set(false);
            });
    }

    clearAll() {
        this.isBadFile.set(false);
        this.isLoading.set(false);
        this.fileName.set('');
        this.layerConfig.set({});
        this.selectedSourceType = null;
        this.selectedRecord = null;
        this.selectedCollection = null;
    }

    clearTypes() {
        this.selectedRecord = null;
        this.selectedCollection = null;
    }

    get isValidConfig() {
        if (!this.layerName) return false;
        switch (this.selectedLayerType) {
            case 'heatmap': {
                return this.selectedSourceType && (this.selectedRecord || this.selectedCollection);
            }
            case 'raster': {
                return !!this.layerConfig()['source'];
            }
            case 'geojson': {
                return !!this.layerConfig()['source'];
            }
            default: {
                return false;
            }
        }
    }

    async onHideDialog() {
        if (this.selectedLayerType === 'heatmap') {
            this.layerConfig.set({
                type: 'heatmap',
                layerType: 'heatmap',
                sourceType: this.selectedSourceType,
                sourceId: this.selectedSourceType === 'records' ? this.selectedRecord : this.selectedCollection,
                paint: {}
            });
        }

        this.layerConfig.update(config => ({ ...config, layerType: this.selectedLayerType }));

        this.ref.close({ name: this.layerName, config: this.layerConfig() });
    }
}