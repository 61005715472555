import { ComponentRef, Injectable, ViewContainerRef } from "@angular/core";
import difference from '@turf/difference';
import { feature, polygon } from '@turf/helpers';
import { MultiPolygon } from "geojson";
import { MapMenuControlComponent } from "src/app/shared/components/map-menu-control/map-menu-control.component";
import { environment } from "src/environments/environment";

export const safelyAddSource = (map: mapboxgl.Map, id: string, source: mapboxgl.AnySourceData, replace = false) => {
    // If the source already exists and we are not replacing it, return
    const existing = map.getSource(id);
    if (typeof existing !== 'undefined' && !replace) {
        return;
    }
    if (existing && replace) {
        map.removeSource(id);
    }
    map.addSource(id, source);
}

export const safelyAddLayer = (map: mapboxgl.Map, layer: mapboxgl.AnyLayer, replace = false) => {
    const existing = map.getLayer(layer.id);
    if (typeof existing !== 'undefined' && !replace) {
        return
    }
    if (existing && replace) {
        map.removeLayer(layer.id);
    }
    map.addLayer(layer);
}

export const safelyRemoveLayer = (map: mapboxgl.Map, id: string) => {
    if (id && typeof map.getLayer(id) !== 'undefined') {
        map.removeLayer(id);
    }
};

export const safelyMoveLayer = (map: mapboxgl.Map, id: string) => {
    if (id && typeof map.getLayer(id) !== 'undefined') {
        map.moveLayer(id);
    }
};

export const safelyRemoveSource = (map: mapboxgl.Map, id: string) => {
    if (id && typeof map.getSource(id) !== 'undefined') {
        map.removeSource(id);
    }
};

export const baseStyles = [{
    id: 'streets',
    url: 'mapbox://styles/mapbox/streets-v12',
    name: 'Streets',
    mapboxName: 'Mapbox Streets',
    preview: 'url(/assets/images/mapbox-streets-preview.png)',
    dark: false,
}, {
    id: 'satellite',
    url: 'mapbox://styles/mapbox/satellite-v9',
    name: 'Satellite',
    mapboxName: 'Mapbox Satellite',
    preview: 'url(/assets/images/mapbox-satellite-preview.jpeg)',
    dark: true

}, {
    id: 'satellite-streets',
    url: 'mapbox://styles/mapbox/satellite-streets-v12',
    name: 'Sat/Streets',
    mapboxName: 'Mapbox Satellite Streets',
    preview: 'url(/assets/images/mapbox-satellite-streets-preview.jpeg)',
    dark: true,
}, {
    id: 'outdoors',
    url: 'mapbox://styles/mapbox/outdoors-v12',
    name: 'Outdoors',
    mapboxName: 'Mapbox Outdoors',
    preview: 'url(/assets/images/mapbox-outdoors-preview.png)',
    dark: false
}, {
    id: 'bing',
    url: '',
    name: 'Bing',
    mapboxName: 'Bing Maps',
    preview: 'url(/assets/images/mapbox-satellite-preview.jpeg)',
    dark: true
}] as const;

export type MapBaseStyle = typeof baseStyles[number];
export type MapBaseStyleId = MapBaseStyle['id'];

export type MapProjectBoundsHandle = {
    setColor: (color: string) => void;
    remove: () => void;
};

interface MapMenuControlOptions {
    position?: 'top-left' | 'top-right' | 'bottom-left' | 'bottom-right';
    showProjectBoundsToggle?: boolean;
}

export const MAP_MENU_LAYERS_CHANGED_EVENT = 'coreo.layers.change';
export const MAP_MENU_STYLE_CHANGED_EVENT = 'coreo.style.change';

@Injectable({
    providedIn: 'root'
})
export class MapsService {

    public attachMapMenuControl(map: mapboxgl.Map, viewContainerRef: ViewContainerRef, options: MapMenuControlOptions = {}): ComponentRef<MapMenuControlComponent> {
        const position = options.position ?? 'top-right';
        const showProjectBoundsToggle = options.showProjectBoundsToggle ?? true;


        const controlComponent = viewContainerRef.createComponent(MapMenuControlComponent);
        controlComponent.setInput('map', map);
        controlComponent.setInput('showProjectBoundsToggle', showProjectBoundsToggle);
        controlComponent.location.nativeElement.classList.add(position);

        map.addControl({
            onAdd: () => controlComponent.location.nativeElement,
            onRemove: () => controlComponent.destroy()
        }, position);

        return controlComponent;
    }


    public async loadBingMapStyle(): Promise<mapboxgl.Style> {
        const imagerySet = 'Aerial';
        const culture = 'en-GB';
        const d = await fetch(`https://dev.virtualearth.net/REST/V1/Imagery/Metadata/${imagerySet}?output=json&uriScheme=https&include=ImageryProviders&key=${environment.bingMapsKey}`);
        const data = await d.json();
        const resourceSets = data.resourceSets[0];
        const resources = resourceSets.resources;
        const resource = resources[0];

        const imageUrl: string = resource.imageUrl;
        const imageUrlSubdomains: string[] = resource.imageUrlSubdomains;

        const tiles = imageUrlSubdomains.map(subdomain => {
            return imageUrl.replace('{subdomain}', subdomain)
                .replace('{culture}', culture);
        });

        const minzoom = resource.zoomMin;
        // 20 seems to be the practical max zoom level, so don't go below that
        const maxzoom = Math.min(resource.zoomMax, 20);

        const attribution = [
            `<a target="_blank" href="https://www.bing.com/maps" data-bm="101">© 2025 Microsoft</a>`,
            `&copy; TomTom`,
            `<a target="_blank" href="https://www.openstreetmap.org/copyright" data-bm="101">© OpenStreetMap</a>`,
            `Earthstar Geographics  SIO`
        ].join(', ');

        return {
            version: 8,
            glyphs: "mapbox://fonts/mapbox/{fontstack}/{range}.pbf",
            name: 'Bing Maps',
            sources: {
                'bing': {
                    type: 'raster',
                    tiles: tiles,
                    tileSize: 256,
                    minzoom,
                    maxzoom,
                    data: {
                        attribution
                    }
                }
            },
            layers: [{
                id: 'bing',
                type: 'raster',
                source: 'bing'
            }]
        };
    }

    addProjectBoundaryLayer(map: mapboxgl.Map, bounds: MultiPolygon, color = '#000'): MapProjectBoundsHandle {

        safelyAddSource(map, 'projectBoundsLineSource', {
            type: 'geojson',
            data: bounds
        }, true);

        const globalLayer = polygon([[
            [-180, -90],
            [-180, 90],
            [180, 90],
            [180, -90],
            [-180, -90]
        ]]);
        const appLayer = feature(bounds);
        const data = difference(globalLayer, appLayer);

        map.addSource('projectBoundsFillSource', {
            type: 'geojson',
            data
        });

        map.addLayer({
            id: 'projectBoundsLine',
            type: 'line',
            source: 'projectBoundsLineSource',
            paint: {
                'line-width': 2,
                'line-color': color
            }
        });

        map.addLayer({
            id: 'projectBoundsFill',
            type: 'fill',
            source: 'projectBoundsFillSource',
            paint: {
                'fill-opacity': 0.2,
                'fill-color': color
            }
        });

        return {
            setColor: (color) => {
                map.setPaintProperty('projectBoundsFill', 'fill-color', color);
                map.setPaintProperty('projectBoundsLine', 'line-color', color);
            },
            remove: () => {
                safelyRemoveLayer(map, 'projectBoundsFill');
                safelyRemoveLayer(map, 'projectBoundsLine');
                safelyRemoveSource(map, 'projectBoundsFillSource');
                safelyRemoveSource(map, 'projectBoundsLineSource');
            }
        }
    }
}
