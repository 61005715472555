<form (ngSubmit)="create()" class="space-y-5">
    <div class="flex flex-col gap-1">
        <label for="form-name text-text-primary">Form name <sup>*</sup></label>
        <p class="text-xs text-text-secondary">The name of this form, also used for categorising responses</p>
        <input id="form-name" type="text" pInputText placeholder="" name="name" [(ngModel)]="name" required #nameControl="ngModel" [ngClass]="{'ng-dirty ng-invalid': nameControl.hasError('required') && nameControl.dirty}" />
    </div>
    <div class="flex flex-col gap-1">
        <label for="form-title text-text-primary">Form title <sup>*</sup></label>
        <p class="text-xs text-text-secondary">The title of this form, as presented to the user when submitting data</p>
        <input id="form-title" type="text" pInputText placeholder="" name="title" [(ngModel)]="title" required #titleControl="ngModel" [ngClass]="{'ng-dirty ng-invalid': titleControl.hasError('required') && titleControl.dirty}" />
    </div>
    <div class="flex items-center justify-end pt-5">
        <p-button type="submit" label="Create form" [disabled]="!canSubmit()" [loading]="loading()" size="large"></p-button>
    </div>
</form>