<div class="flex-grow flex items-center justify-center bg-white relative h-full w-full">
    <div class="max-w-md w-full px-4">
        <h1 class="font-semibold text-2xl mb-[30px]">Single Sign-On</h1>
        <form [formGroup]="ssoLoginForm" (ngSubmit)="ssoLogin()">
            <div class="flex flex-col gap-1 mb-6">
                <label>Domain</label>
                <input name="domain" type="text" pInputText required formControlName="domain"
                    placeholder="Enter your Single Sign-On domain">
            </div>

            @if(ssoLoginError()){
            <p class="bg-danger-lighter text-danger p-4 rounded-md font-bold my-4 text-center"> {{ssoLoginError()}}</p>
            }

            <p-button size="large" label="Sign In" class="block" type="submit" [disabled]="!ssoLoginForm.valid"
                [loading]="submitting()"></p-button>

            <a class="inline-block w-full text-center text-primary text-sm mt-6" ui-sref="login" href="/login">Sign in a different way</a>
        </form>
    </div>
</div>