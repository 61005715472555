<div class="w-full h-full flex flex-col">

    @if(parent()){
    <div class="w-full bg-danger text-white text-center font-semibold px-10 py-4" ng-if="ctrl.parent">
        This project is derived from a master project "{{parent().name}}". Its forms, collections, pages and map
        layers
        cannot
        be
        edited. You may upload additional ones to augment the project.
    </div>
    }


    <div class="w-full h-full flex">
        <!-- Layer Selector -->
        <div class="w-80 p-2 border-r border-grey-20 overflow-y-auto">
            <app-map-builder-sidebar [projectId]="projectId" [isTestData]="isTestData()"/>
        </div>
        <!-- Layer Styler -->
        <div class="w-96 p-3 overflow-y-auto">
            <app-map-builder-editor (zoomLayer)="mapComponent().zoomToLayer()" [isTestData]="isTestData()"
                (deleteLayer)="deleteLayer()" />
        </div>
        <!-- Map -->
        <div class="flex-grow relative">
            <app-map-builder-map [projectId]="projectId" (onUpdateTestData)="isTestData.set($event)"/>
        </div>
    </div>
</div>