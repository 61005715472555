import { Component, computed, EventEmitter, inject, Output } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { InputSwitchModule } from 'primeng/inputswitch';
import { ProjectFormService } from '../project-form.service';
import { Attribute } from '../project-form.model';
import { TooltipModule } from 'primeng/tooltip';
import { NgClass } from '@angular/common';

const geometryTypes = [
    'point',
    'linestring',
    'polygon',
    'multipoint',
    'multilinestring',
    'multipolygon'
] as const;
type GeometryType = typeof geometryTypes[number];

type GeometryConfig = {
    baseStyle: "streets-v12";
    types: GeometryType[];
    enforceBounds: boolean;
}

@Component({
    selector: 'app-geometry-settings',
    templateUrl: 'geometry-settings.component.html',
    standalone: true,
    imports: [
        FormsModule,
        InputSwitchModule,
        TooltipModule,
        NgClass
    ]
})

export class GeometrySettingsComponent {

    form = inject(ProjectFormService);

    attribute = this.form.selectedAttribute;
    projectHasBounds = this.form.projectHasBounds;

    tooltip = computed(() => {
        return `${this.projectHasBounds() ? "When enabled the geometry entered must be within the project bounds." : "Project doesn't have bounds set"}`;
    });

    @Output() update: EventEmitter<Partial<Attribute>> = new EventEmitter();

    geometryTypes = geometryTypes;

    config = computed(() => {
        const attribute = this.attribute();
        const config = attribute.config as GeometryConfig;
        return {
            baseStyle: "streets-v12",
            types: config.types ?? [],
            enforceBounds: config.enforceBounds ?? false
        };
    });

    types = computed(() => this.config().types);
    enforceBounds = computed(() => {
        this.projectHasBounds() ? this.config().enforceBounds : false;
    });

    updateTypes(type: GeometryType) {
        const types = this.types();
        
        if (types.findIndex(t => t === type) > -1) {
            /** Remove type from types */
            this.configChanged({
                types: types.filter(t => t !== type)
            });
        } else {
            /** Add type to types */
            this.configChanged({
                types: [...types, type]
            });
        }
    }

    hasGeometryType(type: GeometryType) {
        const types = this.types();
        return types.findIndex(t => t === type) > -1;
    }

    configChanged(obj: Partial<GeometryConfig>) {
        this.update.emit({
            config: {
                ...this.config(),
                ...obj
            }
        });
    }
}