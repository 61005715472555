import { Component, computed, inject, OnInit, signal } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { CollectionItemService } from './collection-item.service';
import { ConfirmationService } from 'primeng/api';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { ButtonModule } from 'primeng/button';
import { DatePipe } from '@angular/common';
import { MediaItem } from '../collection.model';

@Component({
    selector: 'app-collection-item-media-edit',
    templateUrl: 'collection-item-media-edit.component.html',
    standalone: true,
    imports: [
        ReactiveFormsModule,
        InputTextareaModule,
        ButtonModule,
        DatePipe
    ]
})

export class CollectionItemMediaEditComponent implements OnInit {

    dialogConfig = inject(DynamicDialogConfig);
    dialogRef = inject(DynamicDialogRef);
    confirmationService = inject(ConfirmationService);
    collectionItemService = inject(CollectionItemService);

    item = signal<MediaItem | null>(null);

    mediaType = computed(() => {
        const item = this.item();
        const type = item.type.split('/')[0];
        return type; // 'image' || 'audio'
    });

    src = computed(() => {
        const item = this.item();
        return item.url;
    });

    form = signal<FormGroup | null>(null);
    caption = signal<string | null>(null);
    captionCharacterCount = computed(() => {
        const caption = this.caption();
        return caption ? caption.length : 0;
    });

    constructor() { }

    ngOnInit() {
        this.item.set(this.dialogConfig.data['item']);
        this.form.set(new FormGroup({
            caption: new FormControl(this.item().caption)
        }));
        this.form().controls['caption'].valueChanges.subscribe(c => this.caption.set(c));
    }

    save() {
        const caption = this.form().controls['caption'].value ?? null;
        const update = {
            ...this.item(),
            caption
        }
        this.dialogRef.close(update);
    }
}