import { NgClass } from '@angular/common';
import { Component, computed, EventEmitter, HostListener, input, Output, signal } from '@angular/core';
import { Form } from './project-forms.component';
import { TooltipModule } from 'primeng/tooltip';
import { SvgIconComponent } from 'angular-svg-icon';

@Component({
    selector: 'app-project-forms-item',
    templateUrl: './project-forms-item.component.html',
    standalone: true,
    imports: [
        NgClass,
        TooltipModule,
        SvgIconComponent
    ]
})

export class ProjectFormsItemComponent {

    form = input.required<Form>();
    childFormIds = input.required<number[]>();
    canSort = input(true);

    @Output() open = new EventEmitter<void>();
    @Output() delete = new EventEmitter<void>();
    
    @HostListener('click') onClick() {
        this.open.emit();
    }

    @HostListener('mouseenter') onMouseEnter() {
        this.hovering.set(true);
    }

    @HostListener('mouseleave') onMouseLeave() {
        this.hovering.set(false);
    }

    hovering = signal<boolean>(false);

    attributes = computed(() => this.form().attributes);
    questionCount = computed(() => this.attributes().length);
    visibleQuestionCount = computed(() => this.attributes().filter(a => a.visible).length);
    parentAndChild = computed(() => this.childFormIds().includes(this.form().id));

    deleteItem(event: Event) {
        event.preventDefault();
        event.stopPropagation();
        this.delete.emit();
    }
}