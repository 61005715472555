import { computed, inject, Injectable, signal } from '@angular/core';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Geometry } from 'geojson';
import { catchError, map, of, take, takeLast, zip, switchMap, firstValueFrom } from 'rxjs';
import { ApiService } from 'src/app/core';
import { Collection, CollectionAttribute, CollectionItem, CollectionItemPreview, CollectionItemPreviewSchema, CollectionSchema, MediaItem, MediaItemSchema } from '../collection.model';
import { z } from 'zod';

class AttributeQuestion<T> {
    value: T | undefined;
    path: string;
    label: string;
    required: boolean;
    type: 'text' | 'float';
constructor(
    options: {
        value?: T;
        path?: string;
        label?: string;
        required?: boolean;
        type?: 'text' | 'float';
    } = {},
    ) {
        this.value = options.value;
        this.path = options.path;
        this.label = options.label;
        this.required = !!options.required;
        this.type = options.type;
    }
}

@Injectable()
export class CollectionItemService {

    private apiService = inject(ApiService);
    private httpClient = inject(HttpClient);

    itemId = signal<number | null>(null);
    collectionId = signal<number | null>(null);
    projectId = signal<number | null>(null);
    geometryCollection = signal(false);

    mode = signal<'create' | 'edit'>('edit');

    loading = signal(false);
    mediaLoading = signal(false);
    collectionName = signal('');
    item = signal<CollectionItem | null>(null);
    geometry = signal<Geometry | null>(null);
    attributes = signal<CollectionAttribute[]>([]);
    attributeQuestions = signal<AttributeQuestion<string | number>[]>([]);

    isGeometryEditing = signal(false);
    editedGeometry = signal<Geometry>(null);
    geometryDirty = signal(false);
    hasGeometryEdits = computed(() => !!this.editedGeometry());
    geometryLoading = signal(false);

    valueForm = signal<FormGroup | null>(null);
    value = signal<string | null>(null);
    valueError = computed(() => {
        const value = this.value();
        if (!value || value.match(/^ *$/) !== null) {
            return 'Name (Value) is required';
        } else {
            return null;
        }
    });
    valueFormDirty = signal(false);
    attributeForm = signal<FormGroup | null>(null);
    attributeFormDirty = signal(false);
    geoCustomizationForm = signal<FormGroup | null>(null);
    geoCustomizationFormDirty = signal(false);

    mediaItems = signal<MediaItem[]>([]);
    newMediaItems = signal<MediaItem[]>([]);
    newMediaItemsDirty = signal(false);

    preview = computed<CollectionItemPreview>(() => {
        const valueForm = this.valueForm();
        const attributeForm = this.attributeForm();
        const attributes = this.attributes();
        const media = this.mediaItems();
        const geometry = this.geometry();
        const data = attributes.filter(a => a.visible).map(a => {
            return {
                label: a.label,
                value: attributeForm.controls[a.path].value
            }
        });

        try {
            return CollectionItemPreviewSchema.parse({
                title: valueForm.controls['value'].value,
                data,
                mediaItems: media,
                geometry
            });
        } catch (err) {
            if (err instanceof z.ZodError) {
              console.warn(err.issues);
            }
        }
    });

    loadItem() {
        this.loading.set(true);
        this.valueFormDirty.set(false);
        this.attributeFormDirty.set(false);
        this.geoCustomizationFormDirty.set(false);

        const query = `query AAGetCollectionItem{
            project(id: ${this.projectId()}){
                collections(where: { id: ${this.collectionId()} }){
                    attributes{
                        id
                        label
                        type
                        path
                        visible
                        order
                    }
                    items(where: { id: ${this.itemId()}}){
                        id
                        key
                        value
                        geometry{
                            type
                            coordinates
                        }
                        data
                        mediaItems{
                            id
                            url
                            name
                            type
                            caption
                            sort
                            createdAt
                            size
                        }
                        icon
                        color
                        sort
                    }
                    geometric
                }
            }
        }`;

        this.apiService.graphql<{ project: { collections: Collection[] } }>(query).pipe(
            takeLast(1),
            catchError((e) => {
                console.warn(e);
                // show error toast/message?
                return of(null);
            })
        ).subscribe(res => {
            if (!!res) {
                try {
                    const parsedCollection = CollectionSchema.parse(res.project.collections[0]);
                    const item = parsedCollection.items[0];
                    const attributes = parsedCollection.attributes;
                    this.geometryCollection.set(parsedCollection.geometric);

                    this.setValues(item, attributes);
                } catch (err) {
                    if (err instanceof z.ZodError) {
                      console.warn(err.issues);
                    }
                }
            }
        });
    }

    setValues(item: CollectionItem, attributes: CollectionAttribute[]) {
        this.loading.set(true);
        this.item.set(item);
        this.value.set(item.value);
        this.attributes.set(attributes);
        if (item.mediaItems?.length) {
            if (this.mode() === 'create') {
                this.newMediaItems.set(item.mediaItems);
            } else {
                this.mediaItems.set(item.mediaItems);
            }
        }
        if (item.geometry) {
            this.geometry.set(item.geometry);
        }

        this.initForms();
    }

    initForms() {
        this.valueForm.set(new FormGroup({
            value: new FormControl(this.item().value, Validators.required)
        }));

        this.valueForm().valueChanges.pipe().subscribe(value => {
            this.value.set(value.value);
            this.valueFormDirty.set(this.valueForm().dirty);
        });

        this.geoCustomizationForm.set(new FormGroup({
            icon: new FormControl(this.item().icon),
            color: new FormControl(this.item().color)
        }));

        this.geoCustomizationForm().valueChanges.pipe().subscribe(value => {
            this.item.update(item => ({ ...item, icon: value.icon, color: value.color }));
            this.geoCustomizationFormDirty.set(this.geoCustomizationForm().dirty);
        });

        if (this.attributes().length > 0) {
            this.buildAttributeForm();
        }

        this.loading.set(false);
    }

    private buildAttributeForm() {
        const item = this.item();
        const attributes = this.attributes();

        const questions: AttributeQuestion<string | number>[] = attributes.map(a => {
            return new AttributeQuestion({
                value: item.data?.[a.path],
                required: false,
                type: a.type as 'text' || 'float',
                path: a.path,
                label: a.label
            });
        });

        this.attributeQuestions.set(questions);

        const group: any = {};
        
        questions.forEach((question) => {
            group[question.path] = new FormControl(question.value || null);
        });

        this.attributeForm.set(new FormGroup(group));
        this.attributeForm().valueChanges.pipe().subscribe(value => {
            this.attributeFormDirty.set(this.attributeForm().dirty);
        });
    }

    async updateItem() {
        this.loading.set(true);

        const query = `mutation AAUpdateCollectionItem($input: ItemUpdateInput!){
            updateCollectionItem(input: $input){
                id
            }
        }`;

        const input =  {
            id: this.itemId(),
            value: this.value(),
        }

        if (!!this.attributeForm()) {
            input['data'] = this.attributeForm().getRawValue();
        }
        if (this.geoCustomizationFormDirty()) {
            input['color'] = this.geoCustomizationForm().get('color').value;

            const iconControl = this.geoCustomizationForm().get('icon');
            if (!iconControl?.pristine) {
                const base64Icon = this.geoCustomizationForm().get('icon').value;
                input['icon'] = base64Icon ? await this.saveIconToAWS(base64Icon) : null;
            }
        }

        this.apiService.graphql<{ updateCollectionItem: { id: number } }>(query, { input }).pipe(
            take(1),
            catchError((e) => {
                console.error(e);
                return of(null);
            })
        ).subscribe(res => {
            if (!!res) {
                this.loadItem();
            } else {
                this.loading.set(false);
            }
        });
    }

    /** For new collections created in form builder, create mode */
    async getCreateCollectionItem(): Promise<CollectionItem> {
        const data = this.attributeForm()?.getRawValue();
        let item = {
            ...this.item(),
            value: this.value(),
            data,
            mediaItems: this.newMediaItems()
        }
        if (!!this.attributeForm()) {
            item['data'] = this.attributeForm().getRawValue();
        }
        if (this.geoCustomizationFormDirty()) {
            item['color'] = this.geoCustomizationForm().get('color').value;

            const iconControl = this.geoCustomizationForm().get('icon');
            if (!iconControl?.pristine) {
                const base64Icon = this.geoCustomizationForm().get('icon').value;
                item['icon'] = base64Icon;
            }
        }
        if (this.editedGeometry()) {
            item['geometry'] = this.editedGeometry();
        }
        return item;
    }

    deleteItem(id: number) {        
        const query = `mutation AADeleteCollectionItem{
            deleteCollectionItem(input: { id: ${id} })
        }`;

        return this.apiService.graphql<{ deleteCollectionItem: number }>(query);
    }

    createMediaItem(file: File) {
        if (this.mode() === 'create') {
            const sort = this.mediaItems().length;
            const url = URL.createObjectURL(file);

            const item: MediaItem = {
                id: -Math.abs(Date.now()),
                url,
                size: file.size,
                name: file.name,
                type: file.type,
                sort
            }

            this.newMediaItems.update(items => [...items, item]);
            this.newMediaItemsDirty.set(true);
        } else if (this.mode() === 'edit') {
            this.mediaLoading.set(true);
    
            const query = `mutation AACreateMediaItem($input: ProjectMediaItemCreateInput!){
                createMediaItem(input: $input){
                    id
                    url
                    name
                    type
                    caption
                    sort
                    createdAt
                    size
                }
            }`;
    
            /** Ensure item is added to the end of the list */
            const sort = this.mediaItems().length;
    
            const input =  {
                itemId: this.itemId(),
                size: file.size,
                name: file.name,
                type: file.type,
                projectId: this.projectId(),
                sort
            }
    
            this.apiService.graphql<{ createMediaItem: MediaItem }>(query, { input }, { file }).pipe(
                take(1),
                catchError((e) => {
                    console.error(e);
                    return of(null);
                })
            ).subscribe(res => {
                if (!!res) {
                    const item = MediaItemSchema.parse(res.createMediaItem);
                    this.mediaItems.update(items => [...items, item]);
                }
                this.mediaLoading.set(false);
            });
        }
    }

    deleteMediaItem(id: number) {
        this.mediaLoading.set(true);

        const query = `mutation AACreateMediaItem($input: ProjectMediaItemDeleteInput!){
            deleteMediaItem(input: $input)
        }`;

        const input =  {
            id
        }

        this.apiService.graphql<{ deleteMediaItem: number }>(query, { input }).pipe(
            take(1),
            catchError((e) => {
                console.error(e);
                return of(null);
            })
        ).subscribe(res => {
            if (!!res) {
                this.mediaItems.update(items => items.filter(i => i.id !== id));
            }
            this.mediaLoading.set(false);
        });
    }

    updateMediaItem(update: MediaItem) {
        const query = `mutation AAUpdateMediaItem($input: UpdateMediaItemInput!){
            updateMediaItem(input: $input){
                id
                url
                name
                type
                caption
                sort
                createdAt
                size
            }
        }`;

        const input =  {
            id: update.id,
            caption: update.caption,
            sort: update.sort
        }

        return this.apiService.graphql<{ updateMediaItem: MediaItem }>(query, { input }).pipe(
            take(1),
            catchError((e) => {
                console.error(e);
                return of(null);
            }),
            map(r => r.updateMediaItem)
        );
    }

    updateMediaItemsSort(items: MediaItem[]) {
        this.mediaLoading.set(true);

        const updates = items.map(item => {
            return this.updateMediaItem(item);
        });

        zip(updates).subscribe(res => {
            // console.log('updateMediaItemsSort', res);
            this.mediaItems.set(res);
            this.mediaLoading.set(false);
        });
    }

    getPresignedUrl(contentType: string, fileName: string) {
        const query = `
            query PresignedUrl($projectId: Int!, $fileName: String!, $contentType: String!) {
                url: presignedURL(projectId: $projectId, fileName: $fileName, contentType: $contentType)
            }
        `;

        return this.apiService.graphql<{ url: string }>(query, {
            projectId: this.projectId(),
            fileName,
            contentType
        }).pipe(
            take(1),
            catchError((e) => {
                console.error(e);
                return of(null);
            }),
            map(r => r.url)
        )
    }

    saveIconToAWS(base64: string) {
        const file: File = this.base64ToFile(base64);
        return firstValueFrom(
            this.getPresignedUrl(file.type, file.name).pipe(
                switchMap((presignedUrl: string) => {
                    const headers = new HttpHeaders({
                        'Content-Type': file.type
                    });
                    return this.httpClient.put(presignedUrl, file, { headers }).pipe(
                        map(() => presignedUrl.split('?')[0])
                    )
                })
            )
        );
    }

    base64ToFile(base64): File {
        const arr = base64.split(',');
        const mime = arr[0].match(/:(.*?);/)[1];
        const bstr = atob(arr[1]);
        let n = bstr.length;
        const u8arr = new Uint8Array(n);
    
        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }
    
        return new File([u8arr], `${Date.now()}_icon`, { type: mime });
    }

    startMapEditing() {
        this.isGeometryEditing.set(true);
    }

    stopMapEditing(save: boolean) {
        if (save) {
            if (this.mode() === 'edit') {
                this.geometryLoading.set(true);
                const geometry = this.editedGeometry();
                const query = `mutation AAUpdateCollectionItem($input: ItemUpdateInput!){
                    updateCollectionItem(input: $input){
                        id
                        geometry{
                            type
                            coordinates
                        }
                    }
                }`;
        
                const input =  {
                    id: this.itemId(),
                    geometry
                }
    
                this.apiService.graphql<{ updateCollectionItem: { id: number; geometry: Geometry } }>(query, { input }).pipe(
                    take(1),
                    catchError((e) => {
                        console.error(e);
                        return of(null);
                    })
                ).subscribe(res => {
                    if (!!res) {
                        this.geometry.set(res.updateCollectionItem.geometry);
                        this.isGeometryEditing.set(false);
                        this.editedGeometry.set(null);
                        this.geometryLoading.set(false);
                    }
                });
            } else if (this.mode() === 'create') {
                this.geometry.set(this.editedGeometry());
                this.geometryDirty.set(true)
                this.isGeometryEditing.set(false);
            }
        } else {
            this.isGeometryEditing.set(false);
            this.editedGeometry.set(null);
            this.geometryDirty.set(false);
        }
    }

    updateEditedGeometry(geometry: Geometry) {
        this.editedGeometry.set(geometry);
    }
}