import { Component, computed, EventEmitter, inject, Output } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { InputSwitchModule } from 'primeng/inputswitch';
import { TooltipModule } from 'primeng/tooltip';
import { ProjectFormService } from '../project-form.service';
import { Attribute } from '../project-form.model';
import { SelectButtonModule } from 'primeng/selectbutton';

type MultiConfig = {
    collectionTooltip: boolean;
}
type ModalConfig = {
    collectionTooltip: boolean;
    defaultLayout: 'list' | 'card';
}
type ListQuestionType = 'multi' | 'modal';
type ListType = 'multiselect' | 'select';

@Component({
    selector: 'app-list-settings',
    templateUrl: 'list-settings.component.html',
    standalone: true,
    imports: [
        FormsModule,
        SelectButtonModule,
        InputSwitchModule,
        TooltipModule
    ]
})

export class ListSettingsComponent {

    form = inject(ProjectFormService);

    attribute = this.form.selectedAttribute;

    @Output() update: EventEmitter<Partial<Attribute>> = new EventEmitter();

    choiceTypeOptions = [{ label: 'Multiple choice', value: 'multi' }, { label: 'Searchable list', value: 'modal' }];
    defaultLayoutOptions = [{ label: 'List view', value: 'list' }, { label: 'Cards', value: 'card' }];

    questionType = computed(() => {
        const attribute = this.attribute();
        return attribute.questionType as ListQuestionType;
    });

    listType = computed(() => {
        const attribute = this.attribute();
        return attribute.type as ListType;
    });

    config = computed(() => {
        const attribute = this.attribute();
        const questionType = this.questionType();
        if (questionType === 'modal') {
            const config = attribute.config as ModalConfig;
            return {
                collectionTooltip: config.collectionTooltip ?? false,
                defaultLayout: config.defaultLayout ?? 'list'
            };
        } else {
            const config = attribute.config as MultiConfig;
            return {
                collectionTooltip: config.collectionTooltip ?? false,
            };
        }
    });

    choiceType = computed(() => this.questionType());
    allowMultipleSelection = computed(() => {
        const listType = this.listType();
        return listType === 'multiselect';
    });
    collectionTooltip = computed(() => this.config().collectionTooltip);
    defaultLayout = computed(() => this.config().defaultLayout);

    allowMultipleSelectionChanged(multiple: boolean) {
        // console.log('allowMultipleSelectionChanged', multiple);
        const type: ListType = multiple ? 'multiselect' : 'select';
        this.update.emit({ type });
    }

    choiceTypeChanged($event) {
        // console.log('choiceTypeChanged', $event);
        this.update.emit({ questionType: $event });
    }

    defaultLayoutChanged($event) {
        // console.log('defaultLayoutChanged', $event);
        this.update.emit({
            config: {
                ...this.config(),
                defaultLayout: $event
            }
        });
    }

    collectionTooltipChanged($event) {
        // console.log('collectionTooltipChanged', $event);
        this.update.emit({
            config: {
                ...this.config(),
                collectionTooltip: $event
            }
        });
    }
}