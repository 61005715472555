<form class="w-full p-4 space-y-5">
    <div class="flex flex-col gap-1">
        <label class="text-sm text-text-primary font-normal" for="field">Lookup field <sup>*</sup></label>
        <form>
            <p-dropdown [options]="fieldOptions" [ngModel]="field()" (ngModelChange)="configChanged({ field: $event })" name="field" optionLabel="label" optionValue="value" [showClear]="false" placeholder="Choose a lookup field" required #lookupFieldControl="ngModel" [ngClass]="{'ng-dirty ng-invalid': lookupFieldControl.hasError('required')}"></p-dropdown>
            @if(lookupFieldControl.hasError('required')){
                <small class="text-danger">Lookup field is required</small>
            }
        </form>
    </div>

    <div class="flex flex-col gap-1">
        <label class="text-sm text-text-primary font-normal" for="localisation">Localisation</label>
        <p-dropdown [filter]="true" filterBy="label" [options]="localisationOptions" [ngModel]="localisation()" (ngModelChange)="configChanged({ localisation: $event })" name="localisation" optionLabel="label" optionValue="value" [showClear]="false" placeholder="Choose a localisation"></p-dropdown>
    </div>
</form>