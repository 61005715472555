<div class="h-full w-full flex flex-col">
    <div class="flex-none flex items-center pb-5">
        <input class="w-full" type="search" pInputText placeholder="Search" (input)="handleSearch($event)">
    </div>
    <div class="grow overflow-y-auto no-scrollbar">
        @if (suggestions$ | async; as suggestions) {
            @for (suggestion of suggestions; track suggestion) {
                <p (click)="selectSuggestion(suggestion)" class="py-1 border-b border-white hover:border-grey-15 hover:cursor-pointer">{{ suggestion }}</p>
            }
        }
    </div>
</div>