import { NgClass } from '@angular/common';
import { Component, computed, EventEmitter, inject, input, Output } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CalendarModule } from 'primeng/calendar';
import { DropdownChangeEvent, DropdownModule } from 'primeng/dropdown';
import { InputNumberModule } from 'primeng/inputnumber';
import { InputTextModule } from 'primeng/inputtext';
import { TooltipModule } from 'primeng/tooltip';
import { AttributeConditionRule, UNARY_OPERATORS } from './project-form.model';
import { ProjectFormService } from './project-form.service';

const numberConditionOptions = [
    { value: "eq", name: "is equal to" },
    { value: "ne", name: "is not equal to" },
    { value: "lt", name: "is less than" },
    { value: "lte", name: "is less than or equal to" },
    { value: "gt", name: "is greater than" },
    { value: "gte", name: "is greater than or equal to" },
    { value: "answered", name: "is answered" },
    { value: "unanswered", name: "is not answered" }
];

const textConditionOptions = [
    { value: "is", name: "is" },
    { value: "not", name: "is not" },
    { value: "contains", name: "contains" },
    { value: "notcontains", name: "does not contain" },
    { value: "matches", name: "matches regex" },
    { value: "notmatches", name: "does not match regex" },
    { value: "answered", name: "is answered" },
    { value: "unanswered", name: "is not answered" }
];

const dateConditionOptions = [
    { value: "is", name: "is" },
    { value: "not", name: "is not" },
    { value: "before", name: "is before" },
    { value: "after", name: "is after" },
    { value: "answered", name: "is answered" },
    { value: "unanswered", name: "is not answered" }
];

const datetimeConditionOptions = [
    { value: "before", name: "is before (date)" },
    { value: "beforetime", name: "is before (time)" },
    { value: "after", name: "is after (date)" },
    { value: "aftertime", name: "is after (time)" },
    { value: "answered", name: "is answered" },
    { value: "unanswered", name: "is not answered" }
];

const selectConditionOptions = [
    { value: "is", name: "is" },
    { value: "not", name: "is not" },
    { value: "answered", name: "is answered" },
    { value: "unanswered", name: "is not answered" }
];

const multiselectConditionOptions = [
    { value: "contains", name: "contains" },
    { value: "notcontains", name: "does not contain" },
    { value: "answered", name: "is answered" },
    { value: "unanswered", name: "is not answered" }
];

const booleanConditionOptions = [
    { value: false, name: 'is "No"' },
    { value: true, name: 'is "Yes"' }
];

@Component({
    selector: 'app-project-form-condition-rule',
    templateUrl: './project-form-condition-rule.component.html',
    standalone: true,
    imports: [
        NgClass,
        FormsModule,
        DropdownModule,
        InputNumberModule,
        InputTextModule,
        CalendarModule,
        TooltipModule
    ],
    styles: [`:host {@apply block text-text-primary space-y-2;}`]
})
export class ProjectFormConditionRuleComponent {

    form = inject(ProjectFormService);
    index = input.required<number>();

    @Output() remove: EventEmitter<void> = new EventEmitter();
    @Output() update: EventEmitter<AttributeConditionRule> = new EventEmitter();

    attributes = this.form.attributes;
    questions = this.form.attributesForConditions;
    collections = this.form.collections;
    selectedAttribute = this.form.selectedAttribute;

    rule = computed(() => {
        return this.selectedAttribute().conditions.rules[this.index()];
    });

    path = computed(() => this.rule().path);

    attribute = computed(() => {
        /** Current rule attribute */
        const attributes = this.attributes();
        const path = this.path();
        return attributes.find(a => a.path === path);
    });

    attributeType = computed(() => this.attribute()?.type);

    conditionKey = computed(() => {
        const type = this.attributeType();
        switch (type) {
            case 'integer':
            case 'float':
                return 'numberCondition';
            case 'text':
                return 'textCondition';
            case 'date':
                return 'dateCondition';
            case 'datetime':
                return 'datetimeCondition';
            case 'select':
            case 'multiselect':
                return 'selectCondition';
            case 'boolean':
                return 'isTrue';
        }
    });

    condition = computed(() => {
        return this.rule()[this.conditionKey()]
    });

    conditionOptions = computed(() => {
        const type = this.attributeType();
        switch (type) {
            case 'integer':
            case 'float':
                return numberConditionOptions;
            case 'text':
                return textConditionOptions;
            case 'date':
                return dateConditionOptions;
            case 'datetime':
                return datetimeConditionOptions;
            case 'select':
                return selectConditionOptions;
            case 'multiselect':
                return multiselectConditionOptions;
            case 'boolean':
                return booleanConditionOptions;
            default:
                return [];
        }
    });

    collectionItems = computed(() => {
        const attribute = this.attribute();
        if (!attribute?.collectionId) {
            return [];
        }
        const collections = this.collections();
        const items = collections.find(c => c.id === attribute.collectionId).items;
        if (items) {
            return items;
        } 
    });

    ngOnInit() {
        if (typeof this.collectionItems() === 'undefined') {
            this.form.loadCollectionItems(this.attribute().collectionId);
        }
    }

    comparandKey = computed(() => {
        const type = this.attributeType();
        switch (type) {
            case 'integer':
            case 'float':
                return 'numberComparand';
            case 'text':
                return 'textComparand';
            case 'date':
                return 'dateComparand';
            case 'datetime':
                return 'datetimeComparand';
            case 'select':
            case 'multiselect':
                return 'selectComparand';
        }
    });

    comparand = computed(() => {
        const comparandKey = this.comparandKey();
        const condition = this.condition();
        if (!comparandKey) {
            /** Boolean doesn't have a comparand */
            return null;
        }
        if (this.isUnaryOperator(condition as string)) {
            return null;
        }
        return this.rule()[comparandKey];
    });

    dateComparand = computed(() => {
        const type = this.attributeType();
        const comparand = this.comparand();
        if (!!comparand && (type === 'date' || type === 'datetime')) {
            const date = new Date(comparand);
            return date;
        } else {
            return null;
        }
    });

    conditionHasError = computed(() => {
        const condition = this.condition();
        return condition === null || condition === undefined;
    });

    comparandHasError = computed(() => {
        const comparand = this.comparand();
        const condition = this.condition();
        const type = this.attributeType();
        if (condition === 'answered' || condition === 'unanswered') {
            return false;
        }
        if (type === 'boolean') {
            return false;
        }
        return comparand === null || comparand === undefined;
    });

    showOrderWarning = computed(() => {
        if (!this.path()) {
            return false;
        }
        const attributes = this.attributes();
        const selectedAttributeIndex = attributes.findIndex(a => a.id === this.selectedAttribute().id);
        const ruleAttributeIndex = attributes.findIndex(a => a.id === this.attribute().id);

        return ruleAttributeIndex > selectedAttributeIndex;
    });

    isUnaryOperator(operand: string) {
        return UNARY_OPERATORS.includes(operand);
    }

    comparandChanged(value: any) {
        const rule = this.rule();
        const comparandKey = this.comparandKey();
        this.update.emit({
            ...rule,
            ...{ [comparandKey]: value }
        });
    }

    dateComparandChanged(date: Date) {
        this.comparandChanged(date ? date.toUTCString() : null);
    }

    conditionChanged($event: DropdownChangeEvent) {
        const condition = $event.value;
        const conditionKey = this.conditionKey();
        const rule = this.rule();
        const comparandKey = this.comparandKey();

        if (rule && conditionKey !== 'isTrue' && this.isUnaryOperator(condition)) {
            delete rule[comparandKey];
        }
        this.update.emit({
            ...rule,
            [conditionKey]: condition
        });
    }

    questionChanged($event: DropdownChangeEvent) {
        const path = $event.value;
        const rule = this.rule();
        const attribute = this.attributes().find(a => a.path === path);

        delete rule.numberCondition;
        delete rule.numberComparand;
        delete rule.textCondition;
        delete rule.textComparand;
        delete rule.dateCondition;
        delete rule.dateComparand;
        delete rule.datetimeCondition;
        delete rule.datetimeComparand;
        delete rule.selectCondition;
        delete rule.selectComparand;
        delete rule.selectCondition;
        delete rule.isTrue;

        const type = attribute.type;
        switch (type) {
            case 'integer':
            case 'float':
                rule.numberCondition = 'eq';
                rule.numberComparand = null;
                break;
            case 'text':
                rule.textCondition = 'is';
                rule.textComparand = null;
                break;
            case 'date':
                rule.dateCondition = 'is';
                rule.dateComparand = null;
                break;
            case 'datetime':
                rule.datetimeCondition = 'before';
                rule.datetimeComparand = null;
                break;
            case 'select':
                rule.selectCondition = 'is';
                rule.selectComparand = null;
                this.form.loadCollectionItems(attribute.collectionId);
                break;
            case 'multiselect':
                rule.selectCondition = 'contains';
                rule.selectComparand = null;
                this.form.loadCollectionItems(attribute.collectionId);
                break;
            case 'boolean':
                rule.isTrue = true;
                break;
        }

        this.update.emit({
            ...rule,
            path
        });
    }
}