import { Component, computed, effect, ElementRef, EventEmitter, inject, OnDestroy, Output, signal, viewChild } from '@angular/core';
import { CollectionService } from '../collection.service';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ProjectFormService } from 'src/app/features/projects/project-form/project-form.service';
import { MessageService } from 'primeng/api';
import { ApiService } from '@core/services';
import { catchError, fromEvent, of, Subject, take, takeUntil } from 'rxjs';
import { Geometry } from 'geojson';
import { ButtonModule } from 'primeng/button';
import { InputTextModule } from 'primeng/inputtext';
import { TooltipModule } from 'primeng/tooltip';
import mapboxgl, { Map as MapboxMap, MapOptions } from 'mapbox-gl';
import { environment } from 'src/environments/environment';
import MapboxGIS from '@natural-apptitude/coreo-mapbox';
import { CoreoGeometryType, MapboxGISMode } from '@natural-apptitude/coreo-mapbox/dist/types';
import { CollectionMapToolbarComponent, CreateGeoType } from '../gis/collection-map-toolbar.component';
import { NgClass } from '@angular/common';
import { DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
    selector: 'app-create-geometry-item',
    templateUrl: './create-geometry-item.component.html',
    standalone: true,
    imports: [
        ButtonModule,
        FormsModule,
        ReactiveFormsModule,
        InputTextModule,
        TooltipModule,
        CollectionMapToolbarComponent,
        NgClass
    ],
    providers: [
        MessageService,
        ProjectFormService
    ],
})

export class CreateGeometryItemComponent implements OnDestroy {

    apiService = inject(ApiService);
    collectionService = inject(CollectionService);
    formService = inject(ProjectFormService);
    messageService = inject(MessageService);
    dialogRef = inject(DynamicDialogRef);
    
    @Output() back = new EventEmitter<boolean>();

    itemKeyInput = viewChild<HTMLInputElement>('itemKeyInput');
    newItemMap = viewChild<ElementRef>('newItemMap');
    toolsWrapper = viewChild<ElementRef>('toolsWrapper');

    destroy$ = new Subject<void>();

    private map: MapboxMap;
    private resizeObserver: ResizeObserver;
    
    collectionId = this.collectionService.collectionId.asReadonly();
    location = this.collectionService.location.asReadonly();
    mode = this.collectionService.mode.asReadonly();
    newCollectionItems = this.collectionService.newCollectionItems.asReadonly();
    featureCollection = this.collectionService.mapFeatureCollection.asReadonly();
    collectionBounds = this.collectionService.mapCollectionBounds.asReadonly();
    
    loading = signal(false);
    gis = signal<MapboxGIS>(null);
    itemValue = signal<string>(null);
    itemKey = signal<string>(null);
    itemKeyDirty = signal(false);
    duplicateKeyError = signal(false);
    
    geometry = signal<Geometry>(null);
    
    canSave = computed(() => {
        return this.itemKey() && this.itemValue() && this.geometry();
    });

    constructor() {
        effect(() => {
            if (this.featureCollection() && this.collectionBounds()) {
                this.initMap();
            }
        });
    }

    initMap() {
        const mapOptions: MapOptions = {
            accessToken: environment.mapboxApiKey,
            container: this.newItemMap().nativeElement,
            style: 'mapbox://styles/mapbox/streets-v12',
            projection: { name: 'mercator' },
            bounds: this.collectionBounds()
        };

        this.map = new MapboxMap(mapOptions);

        this.map.on('style.load', () => {
            this.map.addSource('collection', {
                type: 'geojson',
                data: this.featureCollection(),
                generateId: true
            });
            
            this.map.addLayer({
                id: 'polygon-fill',
                source: 'collection',
                type: 'fill',
                paint: {
                    'fill-color': '#ff0000',
                    'fill-opacity': [
                        'case',
                        ['boolean', ['feature-state', 'hover'], false],
                        0.6,
                        0.3
                    ]
                },
                'filter': ['==', '$type', 'Polygon']
            });

            this.map.addLayer({
                id: 'polygon-outline',
                source: 'collection',
                type: 'line',
                paint: {
                    'line-color': '#FFFFFF',
                    'line-width': 1
                },
                'filter': ['==', '$type', 'Polygon']
            });

            this.map.addLayer({
                id: 'line',
                source: 'collection',
                type: 'line',
                paint: {
                    'line-color': '#F02F1D',
                    'line-width': [
                        'case',
                        ['boolean', ['feature-state', 'hover'], false],
                        5,
                        3
                    ]
                },
                'filter': ['==', '$type', 'LineString']
            });

            this.map.addLayer({
                id: 'point',
                source: 'collection',
                type: 'circle',
                filter: [
                    'all',
                    ['!has', 'pc'],
                    ['==', '$type', 'Point']
                ],
                paint: {
                    'circle-color': '#C521E0',
                    'circle-radius': [
                        'case',
                        ['boolean', ['feature-state', 'hover'], false],
                        12,
                        10
                    ],
                    'circle-opacity': [
                        'case',
                        ['boolean', ['feature-state', 'hover'], false],
                        0.8,
                        0.5
                    ],
                    'circle-stroke-width': 1,
                    'circle-stroke-color': '#FFFFFF'
                }
            });

            // const layerIds = ['polygon-fill', 'polygon-outline', 'line', 'point'];

            // this.map.on('click', layerId, (event) => {
            //     if (event.features.length === 0) return;
            //     const item = event.features[0].properties['id'];
            //     this.item.emit(item);
            // });
            this.addFullscreenControl();

            this.resizeObserver = new ResizeObserver(() => this.map.resize());
            this.resizeObserver.observe(this.newItemMap().nativeElement);

            this.loading.set(false);

            this.startEditing();
        });
    }

    startEditing() {
        const feature = null;
        const geometryType: CoreoGeometryType = 'Polygon';
        const mode: MapboxGISMode = 'create';
        const modes: MapboxGISMode[] = ['create'];
    
        // const settings = this.mapsService.getMapGISSettings();
    
        this.gis.set(new MapboxGIS({
            geometryType,
            allowedGeometryTypes: ['Polygon', 'LineString', 'Point', 'MultiPolygon'],
            modes,
            mode,
            feature,
            interface: 'desktop',
            toolbarControl: false,
            typeControl: false,
            buttonsControl: false,
            // TODO: store settings?
            // preventOverlaps: settings.preventOverlaps,
            // snapping: settings.snapping,
            // snapTolerance: settings.snapTolerance,
            // snapToBasemap: settings.snapToBaseLayer,
            // showMetrics: settings.showMetrics,
            // simplificationAmount: settings.simplificationAmount as any,
            snapTo: ['vertex'],
            // TODO: show other features?
            snapToLayer(layer) {
                return layer.id === 'point' || layer.id === 'line' || layer.id === 'polygon-outline';
            },
            // queryFeatures: (point, mode) => {
            //     const result = this.map.queryRenderedFeatures(point as any, {
            //         layers: this.layers,
            //         validate: false
            //     }).map(f => f.id!);
        
            //     if (rlb && ((mode === 'overlaps') || (mode === 'diff'))) {
            //         result.push('redline');
            //     }
        
            //     return result;
            // },
            // getFeatures: async (ids) => {
            //     const f = this.features().features.filter(a => ids.includes(a.id!));
            //     const result = f as Feature<CoreoGeometry>[];
            //     if (ids.includes('redline')) {
            //         result.push({
            //             id: 'redline',
            //             ...this.mapsService.getGlobalMaskLayer(this.redLineBoundary()!.geometry)
            //         } as any)
            //     }
            //     return result;
            // },
        }));
    
    
        fromEvent(this.gis(), 'geometry').pipe(takeUntil(this.destroy$)).subscribe((e: any) => {
            if (e?.geometry) {
                this.geometry.set(e.geometry);
            }
        });
    
        fromEvent(this.gis(), 'state').pipe(takeUntil(this.destroy$)).subscribe((state: any) => {
            // console.log(state);
            // If we need to save settings            
        });
    
        this.map.addControl(this.gis() as any);
    }

    clearEditing() {
        if (this.gis()) {
          this.map.removeControl(this.gis() as any);
        }
        this.gis.set(undefined);
    }

    changeGeoType(type: CreateGeoType) {
        this.gis()?.setGeometryType(type);
    }

    changingFullscreen = () => {
        document.fullscreenElement?.appendChild(this.toolsWrapper()!.nativeElement);
    }

    addFullscreenControl() {
        this.map.addControl(new mapboxgl.FullscreenControl(), 'top-left');
    
        const fs = this.newItemMap()?.nativeElement.querySelector('.mapboxgl-ctrl-fullscreen');
        fs?.addEventListener('click', async () => {
            await this.waitForFullscreenChange();
            this.changingFullscreen();
        });
    }

    removeFullscreenControl() {
        const fs = this.newItemMap()?.nativeElement.querySelector('.mapboxgl-ctrl-fullscreen');
        fs?.removeEventListener('click', this.changingFullscreen);
    }
    
    waitForFullscreenChange = () => {
        return new Promise((resolve) => {
          const onFullscreenChange = () => {
            document.fullscreenElement
              ? resolve(null)
              : document.removeEventListener('fullscreenchange', onFullscreenChange);
          };
    
          document.addEventListener('fullscreenchange', onFullscreenChange);
        });
    };

    setKey() {
        if (this.itemKeyDirty()) {
            return;
        }
        const value = this.itemValue().toLowerCase().trim().replace(/\s+/g, '_');
        this.itemKey.set(value);
    }

    save() {
        if (this.mode() === 'create') {
            const key = this.itemKey();
            const value = this.itemValue();
            const id = this.formService.generateId();
            // check for duplicate key
            const keys = this.newCollectionItems().map(item => item.key);
            if (keys.includes(key)) {
                this.duplicateKeyError.set(true);
                this.messageService.add({ severity: 'error', summary: 'Error', detail: 'New item has a duplicate key. Keys must be unique.' });
                return;
            }
            this.collectionService.addNewCollectionItem({ id, key, value });
            this.collectionService.addMapFeature(id, this.geometry());
            this.handleBack(true);
        } else {
            /** If existing collection */
            this.loading.set(true);

            const key = this.itemKey();
            const value = this.itemValue();
            const geometry = this.geometry();
    
            const query = `mutation addNewCollectionItem($input: ItemCreateInput!){
                createCollectionItem(input: $input){
                    id
                }
            }`;
    
            const input = {
                collectionId: this.collectionId(),
                key,
                value,
                geometry
            };
    
            this.apiService.graphql<{ createCollectionItem: { id: number; } }>(query, { input }).pipe(
                take(1),
                catchError((e) => {
                    console.error(e);
                    this.duplicateKeyError.set(true);
                    this.messageService.add({ severity: 'error', summary: 'Error', detail: `${e.message}` });
                    return of(null);
                })
            ).subscribe(res => {
                if (!!res) {
                    this.collectionService.addMapFeature(res.createCollectionItem.id, geometry);
                    this.handleBack(true);
                    this.loading.set(false);
                } else {
                    this.loading.set(false);
                }
            });
        }
    }

    handleBack(saved: boolean = false) {
        const location = this.location();
        this.itemKey.set(null);
        this.itemValue.set(null);

        if (location === 'modal') {
            this.back.emit(saved);
        } else if (location === 'page') {
            this.dialogRef.close(saved);
        }
    }

    ngOnDestroy() {
        this.destroy$.next();
        this.destroy$.complete();
        this.removeFullscreenControl();
    }
}