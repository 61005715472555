import { Component, Input, Output, EventEmitter, input, inject, signal, computed, WritableSignal } from "@angular/core";

import { AccordionModule } from "primeng/accordion";
import { OrganisationTier } from "src/app/core/models/organisation.model";
import { UpdatedSubscriptionData } from "src/app/features/organisations/organisation-settings/organisation-settings.component"
import { FormControl, ReactiveFormsModule } from "@angular/forms";
import { InputNumberModule } from "primeng/inputnumber";
import { InputTextModule } from "primeng/inputtext";
import { ButtonModule } from "primeng/button";
import { ApiService } from "src/app/core";
import { DialogModule } from "primeng/dialog";
import { InputSwitchModule } from 'primeng/inputswitch';
import { CommonModule, Location } from "@angular/common";
import { toSignal } from "@angular/core/rxjs-interop";
import { CurrencyPipe } from "src/app/shared/pipes/currency.pipe";
import { DateToNowPipe } from "src/app/shared/pipes/date-to-now.pipe";
import { map } from 'rxjs/operators';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ConfirmationService } from 'primeng/api';
import { environment } from "src/environments/environment";

enum Tier {
    FUNDAMENTALS = environment.fundamentalsId,
    PROFESSIONAL = environment.professionalId,
    PROFESSIONAL_PLUS = environment.professionalPlusId
}

enum Addon {
    UKHAB = "UKHab License"
}

enum BillingInterval {
    MONTH = "month",
    YEAR = "year",
}

enum ChangeType {
    UPGRADE = 'upgrade',
    DOWNGRADE_PLAN = 'downgrade_plan',
    DOWNGRADE_SEATS = 'downgrade_seats',
}

const TIER_FEATURES = {
    [Tier.FUNDAMENTALS]: [
        "Unlimited Projects and Forms",
        "Create your own or choose from a selection of our core templates",
        "Access to a web-based version of Coreo, ideal for use in the office",
        "Unlimited media storage",
        "Record logging (Audit trail)",
        "Multi-factor authentication",
        "Ticket based support"
    ],
    [Tier.PROFESSIONAL]: [
        "All the features of Fundamentals plus…",
        "Initial 1:1 orientation session with one of our experts",
        "Offline maps, including satellite",
        "Access to premium templates",
        "Access to the UKHab & BNG Platform (additional fee applies)",
        "Calculated fields",
        "Zapier integrations and webhooks (e.g. custom data workflows)"
    ],
    [Tier.PROFESSIONAL_PLUS]: [
        "All the features of Professional and…",
        "Access to Bing maps (high quality satellite imagery)",
        "Data-driven styling",
        "Custom export templates (coming soon)",
        "Workflow automations (coming soon)"
    ]
};

const ADDON_FEATURES = {
    [Addon.UKHAB]: [
        "Access to the official UKHab Survey App",
        "In-app condition assessments",
        "In-app species recording",
        "Access to the UKHab and BNG platform",
        "Export to the metric for BNG calculations",
        "Export condition assessments to the Defra workbook"
    ]
};

const SAVED_PERCENTAGE = {
    [Tier.FUNDAMENTALS]: 10,
    [Tier.PROFESSIONAL]: 10,
    [Tier.PROFESSIONAL_PLUS]: 10
};

interface AddonOption {
    id: string;
    name: string;
    features: string[];
    pricePerSeat: number;
}

interface TierOption {
    id: Tier;
    name: string;
    features: string[];
    isAddons: boolean;
    pricePerSeat: number;
    savedPercentage: number;
    isCurrentTierOption: boolean;
    isAvailableForSelection: boolean;
}

interface LineItem {
    amount: number;
    description: string;
}

const MONTHLY_PRICE = "monthlyPrice";
const YEARLY_PRICE = "yearlyPrice";
const VAT_RATE = 20;

@Component({
    selector: "app-update-plan",
    templateUrl: "./update-plan.component.html",
    standalone: true,
    imports: [
        CommonModule,
        AccordionModule,
        ReactiveFormsModule,
        InputNumberModule,
        InputTextModule,
        ButtonModule,
        DialogModule,
        InputSwitchModule,
        CurrencyPipe,
        DateToNowPipe,
        ConfirmDialogModule
    ],
    providers: [ConfirmationService],
    styles: [`
        .p-button.p-button-icon-only {
            width: 1rem;
            padding: 0rem 1rem;
        }
    `]
})
export class UpdatePlanComponent {
    private readonly previewInvoiceQuery: string = `query PreviewInvoice ($organisationId: Int!, $seats: Int!, $ukhabSeats: Int!, $organisationTierId: Int!, $interval: String!) {
        previewInvoice(
            organisationId: $organisationId, 
            seats: $seats, 
            ukhabSeats: $ukhabSeats, 
            organisationTierId: $organisationTierId, 
            interval: $interval
        ) { amountDue, proratedItems { description, amount }, upcomingCharges { description, amount }, upcomingChargesSubTotal }
    }`;

    private buildSubscriptionMutation(name: string, includeSchedule = false): string {
        const scheduledChangesFields = includeSchedule
            ? `scheduledChanges {
                    pendingTier
                    pendingSeatQuantity
                    pendingLicenseQuantity
                    scheduleStart
                    scheduleEnd
                }`
            : '';
    
        const mutation = name.charAt(0).toLowerCase() + name.slice(1);
    
        return `mutation ${name}($input: ${name}Input!) {
            ${mutation}(input: $input) {
                id, 
                seats, 
                ukhabSeats, 
                tier { 
                    id
                    monthlyPrice
                    name
                    yearlyPrice
                },
                subscription {
                    interval
                    isCancelled
                    isExpired
                    isPastDue
                    periodEnd
                    periodStart
                    ${scheduledChangesFields}
                }
            }
        }`;
    }

    private readonly upgradeSubscriptionMutation = this.buildSubscriptionMutation('UpgradeSubscription');
    private readonly downgradeSubscriptionMutation = this.buildSubscriptionMutation('DowngradeSubscription', true);
    private readonly cancelPendingDowngradeMutation = this.buildSubscriptionMutation('CancelPendingDowngrade', true);

    public Math = Math;
    private location = inject(Location);
    private apiService = inject(ApiService);
    private confirmationService = inject(ConfirmationService);

    public BillingInterval = BillingInterval;
    public ChangeType = ChangeType;
    public OrganisationTier = Tier;

    projects = input<any[]>([]);
    baseUrl = input<string>("");
    tierId = input<number | null>(null);
    periodEnd = input<string | null>(null);
    organisationId = input<number | null>(null);
    trialTimeLeft = input<string | null>(null);
    isFreeTrial = input<boolean>(false);
    isFreeTrialExpired = input<boolean>(false);
    isSubscription = input<boolean>(false);
    seatsFilled = input<number | null>(null);
    interval = input<'month' | 'year'>('year');
    currentPlanSeats = input<number>(null);
    currentAddonSeats = input<number>(null);
    organisationTiers = input<OrganisationTier[]>([]);
    error = signal<string | null>(null);
    proratedItems = signal<LineItem[]>([]);
    upcomingCharges = signal<LineItem[]>([]);

    _isVisibleUpdatePlanDialog: WritableSignal<boolean> = signal(false);
    _isDowngradePending: WritableSignal<boolean> = signal(false);

    @Input() set isVisibleUpdatePlanDialog(value: boolean) {
        this._isVisibleUpdatePlanDialog.set(value);
        if (value) {
            this.step.set('select-plan')
        }
    }
    @Output() visibleUpdatePlanChange = new EventEmitter<{ isVisibleUpdatePlanDialog: boolean, seatsSelected: number, updatedData: UpdatedSubscriptionData }>();
    @Output() onCancelPendingChange = new EventEmitter<{}>();
    
    @Input() set isDowngradePending(value: boolean) {
        this._isDowngradePending.set(value);
    }
    currentOrganisationTier = computed<OrganisationTier | null>(() => this.organisationTiers().find(orgTier => orgTier.id === this.tierId()));
    ukhabProjects = computed<any[]>(() => this.projects()?.filter(project => project.androidAppUrl && project.androidAppUrl.includes('ukhab')));

    sharedLink = signal<string>("");
    redirectionUrl = signal<string>("");

    isProcessingUpdate = signal<boolean>(false);
    isProcessingInvoice = signal<boolean>(false);
    isSuccessfullyCopied = signal<boolean>(false);
    isProcessingSharedLinkBtn = signal<boolean>(false);
    cancelledPendingChanges = signal<boolean>(false);

    step = signal<
        | "select-plan"
        | "select-addon"
        | "payment-summary"
        | "payment-to-stripe"
        | "upgrade-successful"
        | "upgrade-failed"
        | "downgrade-successful"
        | "downgrade-failed"
    >("select-plan");
    modalHeader = computed<string>(() => {
        switch (this.step()) {
            case 'select-plan':
                return "Manage your plan";
            case 'select-addon':
                return "UKHab App & BNG Platform licences";
            case 'payment-summary':
                return "Payment summary";
            case 'payment-to-stripe':
                return "Payment";
            case 'upgrade-successful':
                return "Upgrade Successful";
            case 'upgrade-failed':
                return "Upgrade Failed";
            case 'downgrade-successful':
                let header = "Downgrade Successful";
                if (this.changeType() === ChangeType.DOWNGRADE_SEATS) {
                    header = "Update Successful";
                }
                return header;
            case 'downgrade-failed':
                return "Downgrade Failed";
        }
    });

    updateSuccessfulMessage = computed<string>(() => {
        if (!this.updatedData()) return '';

        if (
            this.changeType() === ChangeType.DOWNGRADE_SEATS ||
            this.changeType() === ChangeType.DOWNGRADE_PLAN
        ) {
            let message = "Your subscription has been successfully";
            if (this.changeType() === ChangeType.DOWNGRADE_PLAN) {
                message += " downgraded.";
            } else {
                message += " updated.";
            }
            message += `\nThe changes will take effect on <strong>${this.updatedData().subscription?.scheduledChanges?.scheduleStart}</strong>.`;
            return message;
        } else {
            const tierName = this.tierId() !== this.updatedData().tier.id ? this.updatedData().tier.name : '';
            const updatedPlanSeats = this.updatedData().seats - this.currentPlanSeats();
            const updatedAddonSeats = this.updatedData().ukhabSeats - this.currentAddonSeats();
    
            if (tierName) {
                return `You are now on the Coreo <strong>${tierName}</strong> Plan`;
            }
            const seatMessage = updatedPlanSeats > 0 
                ? `added <strong>${Math.abs(updatedPlanSeats)} seat${Math.abs(updatedPlanSeats) > 1 ? 's' : ''}</strong>`
                : '';
            const addonMessage = updatedAddonSeats > 0 
                ? `added <strong>${Math.abs(updatedAddonSeats)} UKHab licence${Math.abs(updatedAddonSeats) > 1 ? 's' : ''}</strong>`
                : '';
            const combinedMessage = [seatMessage, addonMessage].filter(Boolean).join(' and ');
            return combinedMessage ? `You have ${combinedMessage} to your plan` : '';
        }
    });

    changeType = computed<ChangeType>(() => {
        const selectedPlanId = this.selectedPlan()?.id;
        const currentTierId = this.currentOrganisationTier()?.id;
    
        const tierDifference = selectedPlanId - currentTierId;
        const seatsDifference = this.differencePlanSeats();
        const addonSeatsDifference = this.differenceAddonSeats();
    
        if (!this.isSubscription()) {
            return ChangeType.UPGRADE;
        } else if (tierDifference > 0 || seatsDifference > 0 || addonSeatsDifference > 0) {
            return ChangeType.UPGRADE;
        } else if (tierDifference < 0) {
            return ChangeType.DOWNGRADE_PLAN;
        } else if (seatsDifference < 0 || addonSeatsDifference < 0) {
            return ChangeType.DOWNGRADE_SEATS;
        }
        return ChangeType.UPGRADE;
    });
    
    vatAmount = computed<number>(() => {
        return (this.amountDue() * VAT_RATE) / 100;
    });

    totalDue = computed<number>(() => {
        return (this.amountDue() + this.vatAmount());
    });

    upcomingChargesVatAmount = computed<number>(() => {
        return (this.upcomingChargesSubTotal() * VAT_RATE) / 100;
    });

    upcomingChargesTotal = computed<number>(() => {
        return (this.upcomingChargesSubTotal() + this.upcomingChargesVatAmount());
    });

    intervalOption = signal<BillingInterval>(BillingInterval.YEAR);
    intervalOptions = [
        { name: BillingInterval.MONTH, label: "Pay Monthly" },
        { name: BillingInterval.YEAR, label: "Pay Yearly" },
    ];

    seatsPlanControl = new FormControl();
    seatsAddonControl = new FormControl();

    planSeats = toSignal<number>(this.seatsPlanControl.valueChanges.pipe(
        map(value => Number(value))
    ));
    addonSeats = toSignal<number>(this.seatsAddonControl.valueChanges.pipe(
        map(value => Number(value))
    ));
    differencePlanSeats = computed<number>(() => this.planSeats() - this.currentPlanSeats());
    differenceAddonSeats = computed<number>(() => this.addonSeats() - this.currentAddonSeats());

    tierOptions = computed<TierOption[]>(() => {
        const tiers = [Tier.FUNDAMENTALS, Tier.PROFESSIONAL, Tier.PROFESSIONAL_PLUS];
        return this.organisationTiers()
        // Ensure that only the supported plans are used
        .filter((tier) => tiers.includes(tier.id))
        .map((tier) => {
            const organisationTier = this.organisationTiers().find(orgTier => orgTier.id === tier.id);
            const pricePerSeat: number = organisationTier[this.intervalOption() === BillingInterval.MONTH ? MONTHLY_PRICE : YEARLY_PRICE];
            const isAvailableForSelection = !this.isSubscription() || !this.isCurrentTierOption(tier.id) || !!this.differencePlanSeats() || !!this.differenceAddonSeats();
            return {
                id: tier.id,
                name: tier.name,
                features: TIER_FEATURES[tier.id],
                isAddons: !!organisationTier?.addons?.length,
                pricePerSeat,
                savedPercentage: SAVED_PERCENTAGE[tier.id],
                isCurrentTierOption: this.isCurrentTierOption(tier.id),
                isAvailableForSelection
            };
        });
    });

    addonOption = computed<AddonOption | null>(() => {
        const organisationTierWithAddon = this.organisationTiers().find(orgTier => orgTier.addons?.length > 0);
        if (!organisationTierWithAddon) return null;
        const addon = organisationTierWithAddon.addons[0];
        const pricePerSeat = addon[this.intervalOption() === BillingInterval.MONTH ? MONTHLY_PRICE : YEARLY_PRICE];
        return {
            id: addon.productId,
            name: addon.name,
            features: ADDON_FEATURES[addon.name],
            pricePerSeat
        };
    });

    isModalClosable = computed<boolean>(() => {
        if (
            this.isProcessingInvoice() ||
            this.isProcessingUpdate() ||
            this.step() === "payment-summary" ||
            this.step() === "downgrade-successful" ||
            this.step() === "upgrade-successful"
        ) {
            return false;
        }
        return true;
    });

    isAddAddonForCurrentOrganisationTier = signal<boolean>(false);
    selectedPlan = signal<TierOption | null>(null);

    amountDue = signal<number>(0);
    upcomingChargesSubTotal = signal<number>(0);;
    updatedData = signal<UpdatedSubscriptionData | null>(null);

    ngOnChanges() {
        this.resetModal();
        this.seatsPlanControl.setValue(this.isFreeTrial() ? this.seatsFilled() : this.currentPlanSeats());
        this.seatsAddonControl.setValue(this.currentAddonSeats());
        if (!this.isFreeTrial()) {
            this.intervalOption.set(this.interval() === 'month' ? BillingInterval.MONTH : BillingInterval.YEAR);
        }
    }

    isIntegerPrice(price: number) {
        return Number.isInteger(price);
    }

    isCurrentTierOption(id: Tier) {
        return !this.isSubscription()
            ? this.tierId() === id 
            : this.tierId() === id && this.interval() === this.intervalOption();
    }

    selectPlan(tier: TierOption, event: Event) {
        this.selectedPlan.set(tier);
        if (this.changeType() === ChangeType.DOWNGRADE_PLAN || this.changeType() === ChangeType.DOWNGRADE_SEATS) {
            if (this._isDowngradePending()) {
                this.confirmCancelDowngrade(event);
            } else {
                this.confirmDowngrade(event);
            }
        } else if (this._isDowngradePending()) {
            this.confirmCancelDowngrade(event);
        } else {
            this.confirmPlanSelection();
        }
    }

    confirmPlanSelection() {
        const tier = this.selectedPlan();
        if (tier.isAddons && !this.addonSeats() && !this.currentAddonSeats()){
            this.step.set('select-addon');
        } else {
            this.paymentSummary();
        }
    }

    addAddonForCurrentOrganisationTier() {
        const currentOrganisationTierOption = this.tierOptions().find(tier => tier.isCurrentTierOption);
        this.selectedPlan.set(currentOrganisationTierOption);
        this.step.set('select-addon');
        this.isAddAddonForCurrentOrganisationTier.set(true);
    }

    resetToFirstStep() {
        const planSeats = this.planSeats();
        const interval = this.intervalOption();
        this.step.set('select-plan');
        this.resetModal();
        this.seatsPlanControl.setValue(planSeats);
        this.seatsAddonControl.setValue(0);
        this.intervalOption.set(interval);
    }

    goBack() {
        switch(this.step()) {
            case 'select-addon':
                this.resetToFirstStep();
                break;
            case 'payment-summary':
                if (this.selectedPlan().isAddons && (this.isAddAddonForCurrentOrganisationTier() || !this.isSubscription() || !this.isCurrentTierOption(this.selectedPlan().id))) {
                    this.step.set('select-addon');
                } else {
                    this.resetToFirstStep();
                }
                break;
            default: console.error('Invalid step');
        }
    }

    paymentSummary() {
        this.step.set('payment-summary');
        if (this.isSubscription()) {
            this.isProcessingInvoice.set(true);
            this.apiService.graphql<{ previewInvoice: { amountDue: number, proratedItems: LineItem[], upcomingCharges: LineItem[], upcomingChargesSubTotal: number } }>
            (this.previewInvoiceQuery, this.generateInputUpdateCheckout()).subscribe(response => {
                this.amountDue.set(Number(response.previewInvoice.amountDue));
                this.upcomingChargesSubTotal.set(Number(response.previewInvoice.upcomingChargesSubTotal));
                this.proratedItems.set(response.previewInvoice.proratedItems);
                this.upcomingCharges.set(response.previewInvoice.upcomingCharges);
                this.isProcessingInvoice.set(false);
            });
        } else {
            const planPrice = this.selectedPlan().pricePerSeat * this.planSeats();
            const addonPrice = this.addonOption() ? this.addonOption().pricePerSeat * this.addonSeats() : 0;
            this.amountDue.set(planPrice + addonPrice);
        }
    }

    paymentToCheckout() {
        if (this.isSubscription()) {
            if (this.changeType() === 'upgrade') {
                this.upgradeSubscription();
            } else if (this.changeType() === ChangeType.DOWNGRADE_PLAN || this.changeType() === ChangeType.DOWNGRADE_SEATS) {
                this.downgradeSubscription();
            } else {
                console.error("Failed to update subscription")
            }
        } else {
            this.step.set('payment-to-stripe');
            this.planCheckout();
        }
    }

    upgradeSubscription() {
        this.isProcessingUpdate.set(true);
        this.apiService.graphql<{ upgradeSubscription: any }>
        (this.upgradeSubscriptionMutation, { input: this.generateInputUpdateCheckout() }).subscribe(response => {
            this.isProcessingUpdate.set(false);
            this.updatedData.set(response.upgradeSubscription);
            this.step.set('upgrade-successful');
        }, (error) => {
            console.error(error);
            this.error.set(error.message);
            this.isProcessingUpdate.set(false);
            this.step.set('upgrade-failed');
        });
    }

    downgradeSubscription() {
        this.isProcessingUpdate.set(true);
        this.apiService.graphql<{ downgradeSubscription: any }>
        (this.downgradeSubscriptionMutation, { input: this.generateInputUpdateCheckout() }).subscribe(response => {
            this.isProcessingUpdate.set(false);
            this.updatedData.set(response.downgradeSubscription);
            this.step.set('downgrade-successful');
        }, (error) => {
            console.error(error);
            this.error.set(error.message);
            this.isProcessingUpdate.set(false);
            this.step.set('downgrade-failed');

        });
    }

    generateInputUpdateCheckout() {
        return {
            organisationId: this.organisationId(),
            organisationTierId: this.selectedPlan().id,
            seats: this.planSeats(),
            ukhabSeats: this.addonSeats(),
            interval: this.intervalOption()
        };
    }

    generateInputCheckout() {
        const priceId: string = `${this.intervalOption() === BillingInterval.MONTH ? MONTHLY_PRICE : YEARLY_PRICE}Id`;

        const plan = this.organisationTiers().find(orgTier => orgTier.id === this.selectedPlan().id);
        const addon = plan.addons?.length ? plan.addons[0] : null;

        const lineItems = [
            { priceId: plan[priceId], quantity: this.planSeats() },
            ...(addon && this.addonSeats() ? [{ priceId: addon[priceId], quantity: this.addonSeats() }] : [])
        ];

        return {
            organisationId: this.organisationId(),
            lineItems,
            ...(addon && { ukhabSeats: this.addonSeats() }),
            returnUrl: `${this.baseUrl()}${this.location.path()}`,
            organisationTierId: plan.id,
            seats: this.planSeats()
        };
    }

    planCheckout() {
        const input = this.generateInputCheckout();

        this.apiService.graphql<{ createCheckoutSession: { url: string } }>(`mutation CreateCheckoutSession($input: CreateCheckoutSessionInput!) {
            createCheckoutSession(input: $input) { url }
        }`, { input }).subscribe((response) => {
            this.redirectionUrl.set(response?.createCheckoutSession.url);
        });
    }

    generateLink() {
        this.isProcessingSharedLinkBtn.set(true);
        const input = {
            ...this.generateInputCheckout(),
            successMessage: "Thank you for your payment! Your transaction was successful. You can return to Coreo or close this page."
        }
        delete input.returnUrl;
        this.apiService.graphql<{ createPaymentLink: { url: string } }>(`mutation CreatePaymentLink($input: CreatePaymentLinkInput!) {
            createPaymentLink(input: $input) { url }
        }`, { input }).subscribe((response) => {
            this.sharedLink.set(response?.createPaymentLink.url);
            this.isProcessingSharedLinkBtn.set(false);
        });
    }

    copyText(text: string) {
        navigator.clipboard.writeText(text).then(() => {
          this.isSuccessfullyCopied.set(true);
        }).catch(err => {
          console.error('Failed to copy text: ', err);
        });
    }

    confirmRedirection() {
        this.redirectionUrl() && (window.location.href = this.redirectionUrl());
    }

    onHideDialog(seatsSelected: number = 0, updatedData: UpdatedSubscriptionData | null = null) {
        this._isVisibleUpdatePlanDialog.set(false);
        if (this.cancelledPendingChanges() && !this._isDowngradePending()) {
            this.onCancelPendingChange.emit();
            this.cancelledPendingChanges.set(false);
        }
        this.visibleUpdatePlanChange.emit({ isVisibleUpdatePlanDialog: false, seatsSelected, updatedData });
    }

    resetModal() {
        this.sharedLink.set('');
        this.redirectionUrl.set('');
        this.isProcessingUpdate.set(false);
        this.isProcessingInvoice.set(false);
        this.isSuccessfullyCopied.set(false);
        this.isProcessingSharedLinkBtn.set(false);
        this.isAddAddonForCurrentOrganisationTier.set(false);
        this.selectedPlan.set(null);
        this.amountDue.set(0);
        this.intervalOption.set(BillingInterval.YEAR);
        this.updatedData.set(null);
    }

    confirmDowngrade($event: Event) {
        this._isVisibleUpdatePlanDialog.set(false);
        let header = 'Confirm';
        let message = 'Are you sure you want to';
        if (this.changeType() === ChangeType.DOWNGRADE_PLAN) {
            header += ' plan downgrade';
            message += ' downgrade your plan?';
        } else {
            header += ' changes';
            message += ' update your plan?';
        }
        const planSeats = this.currentPlanSeats();
        const planLicenses = this.currentAddonSeats();
        const selectedSeats = this.planSeats();
        const selectedLicenses = this.addonSeats();
        const selectedPlan = this.selectedPlan();
        this.confirmationService.confirm({
            defaultFocus: 'none',
            message,
            target: $event.target,
            header,
            rejectLabel: 'Cancel',
            rejectIcon: 'none',
            rejectButtonStyleClass: 'p-button p-button-lg p-button-outlined',
            acceptLabel: 'Confirm',
            acceptIcon: 'none',
            acceptButtonStyleClass: 'p-button p-button-lg p-button',
            accept: () => {
                this.seatsPlanControl.setValue(selectedSeats);
                this.seatsAddonControl.setValue(selectedLicenses);
                this.selectedPlan.set(selectedPlan);
                this._isVisibleUpdatePlanDialog.set(true);
                this.confirmPlanSelection();
            },
            reject: () => {
                this.seatsPlanControl.setValue(planSeats);
                this.seatsAddonControl.setValue(planLicenses);
                this._isVisibleUpdatePlanDialog.set(true);
            }
        });
    }

    confirmCancelDowngrade($event: Event) {
        this._isVisibleUpdatePlanDialog.set(false);
        let header = 'Cancel pending changes';
        let message = 'You have pending changes on this account already. Please confirm that you wish to continue.';
        const planSeats = this.currentPlanSeats();
        const planLicenses = this.currentAddonSeats();
        const selectedSeats = this.planSeats();
        const selectedLicenses = this.addonSeats();
        const selectedPlan = this.selectedPlan();
        this.confirmationService.confirm({
            defaultFocus: 'none',
            message,
            target: $event.target,
            header,
            rejectLabel: 'Cancel',
            rejectIcon: 'none',
            rejectButtonStyleClass: 'p-button p-button-lg p-button-outlined',
            acceptLabel: 'Confirm',
            acceptIcon: 'none',
            acceptButtonStyleClass: 'p-button p-button-lg p-button',
            accept: () => {
                this.seatsPlanControl.setValue(selectedSeats);
                this.seatsAddonControl.setValue(selectedLicenses);
                this.selectedPlan.set(selectedPlan);
                this._isVisibleUpdatePlanDialog.set(true);
                this.cancelDowngrade();
            },
            reject: () => {
                this.seatsPlanControl.setValue(planSeats);
                this.seatsAddonControl.setValue(planLicenses);
                this._isVisibleUpdatePlanDialog.set(true);
            },
        });
    }

    cancelDowngrade() {
        this.isProcessingUpdate.set(true);
        this.apiService.graphql<{ cancelPendingDowngrade: any }>(
          this.cancelPendingDowngradeMutation, 
          { input: { organisationId: this.organisationId() } }
        ).subscribe(response => {
            this.isProcessingUpdate.set(false);
            this.updatedData.set(response.cancelPendingDowngrade);
            this._isDowngradePending.set(false);
            this.confirmPlanSelection();
            this.cancelledPendingChanges.set(true);
        }, (error) => {
            console.error(error);
            this.error.set(error.message);
        });
    }
}
