import { Component, EventEmitter, OnInit, Output, computed, effect, inject, signal, viewChild } from '@angular/core';
import { CollectionTableComponent } from './collection-table/collection-table.component';
import { CollectionAttributeTableComponent } from './attribute-table/attribute-table.component';
import { ButtonModule } from 'primeng/button';
import { DialogService, DynamicDialogModule, DynamicDialogRef } from 'primeng/dynamicdialog';
import { CollectionImportType, ImportCollectionModalComponent } from './import-collection-modal/import-collection-modal.component';
import { ApiService } from '../../../core/services/api.service';
import { CoreoJob, JobsService, jobFieldsFragment } from '../../../core/services/jobs.service';
import { catchError, filter, of, take, tap } from 'rxjs';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { InputTextModule } from 'primeng/inputtext';
import { MenuModule } from 'primeng/menu';
import { ConfirmationService, MenuItem, MessageService } from 'primeng/api';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ToastModule } from 'primeng/toast';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { NgClass } from '@angular/common';
import { CollectionService } from './collection.service';
import { CreateGeometryItemComponent } from './create-geometry-item/create-geometry-item.component';
import { SvgIconComponent } from 'angular-svg-icon';

@Component({
    selector: 'app-collection-detail',
    templateUrl: 'collection-detail.component.html',
    imports: [
        ButtonModule,
        DynamicDialogModule,
        CollectionTableComponent,
        CollectionAttributeTableComponent,
        FormsModule,
        ReactiveFormsModule,
        InputTextModule,
        MenuModule,
        ConfirmDialogModule,
        ToastModule,
        ProgressSpinnerModule,
        NgClass,
        CreateGeometryItemComponent,
        SvgIconComponent
    ],
    providers: [
        DialogService,
        ConfirmationService,
        MessageService,
        DynamicDialogRef
    ],
    standalone: true,
    styles: [`:host { @apply block h-full w-full relative;}`]
})

export class CollectionDetailComponent implements OnInit {

    dialogService = inject(DialogService);
    apiService = inject(ApiService);
    confirmationService = inject(ConfirmationService);
    jobService = inject(JobsService);
    collectionService = inject(CollectionService);
    dialogRef = inject(DynamicDialogRef);

    @Output() item: EventEmitter<number> = new EventEmitter();
    @Output() delete: EventEmitter<void> = new EventEmitter();
    @Output() save: EventEmitter<void> = new EventEmitter();

    itemsTable = viewChild<CollectionTableComponent>('itemsTable');
    attributeTable = viewChild<CollectionAttributeTableComponent>('attributeTable');

    collectionName = signal('');
    editName = signal(false);
    nameForm = new FormGroup({
        name: new FormControl('', Validators.required),
    });
    nameLoading = signal(false);

    settingsMenuItems: MenuItem[] = [
        {
            label: 'Delete collection',
            icon: 'fa-solid fa-trash-can',
            class: 'text-danger',
            command: ($event) => this.deleteCollectionCheck($event)
        }
    ];
    importMenuItems: MenuItem[] = [
        { label: 'From CSV', command: () => this.importCollection('csv') },
        { label: 'GeoJSON', command: () => this.importCollection('geojson') },
        { label: 'Zipped Shapefile', command: () => this.importCollection('shapefile') }
    ];

    projectId = this.collectionService.projectId;
    collectionId = this.collectionService.collectionId;
    mode = this.collectionService.mode;
    location = this.collectionService.location;
    isGeometryCollection = this.collectionService.isGeometryCollection;

    newCollectionName = this.collectionService.newCollectionName;
    newCollectionItems = this.collectionService.newCollectionItems;
    newCollectionAttributes = this.collectionService.newCollectionAttributes;
    canSaveNewCollection = this.collectionService.canSaveNewCollection;
    importingNewCollection = signal(false);
    newCollectionSaving = signal(false);
    importingCollection = signal(false);

    showAttributeTable = signal(false);
    attributeTableVisible = computed(() => {
        const showAttributeTable =this.showAttributeTable();
        const location = this.location();
        return location === 'page' || showAttributeTable;
    });

    showCreateGeometryItem = signal(false);

    constructor() {
        effect(() => {
            const name = this.newCollectionName();
            if (!!name) {
                this.collectionService.collectionDirty.set(true);
            }
        }, {
            allowSignalWrites: true
        });
    }

    ngOnInit() {
        if (this.mode() === 'edit') {
            this.loadCollection();
        }
    }

    private loadCollection() {
        const query = `query loadCollection($projectId: Int!, $collectionId: Int!){
            project(id: $projectId){
                collections(where: { id: $collectionId }){
                    name
                    geometric
                }
            }
        }`;

        this.apiService.graphql<{ project: { collections: { name: string; geometric: boolean; }[] } }>(query, { projectId: this.projectId(), collectionId: this.collectionId(), }).pipe(
            take(1),
            catchError((e) => {
                console.warn(e);
                return of(null);
            })
        ).subscribe(res => {
            if (!!res) {
                const collection = res.project.collections[0];
                this.collectionName.set(collection.name);
                this.collectionService.geometric.set(collection.geometric);
            }
        });
    }

    toggleAttributeTable() {
        this.showAttributeTable.update(show => !show);
    }

    deleteCollectionCheck($event) {
        this.confirmationService.confirm({
            defaultFocus: 'none',
            message: 'Are you sure you want to delete this collection, and all items within?<br>This cannot be undone.',
            target: $event.target,
            header: 'Delete collection',
            rejectLabel: 'Cancel',
            rejectIcon: 'none',
            rejectButtonStyleClass: 'p-button p-button-lg p-button-outlined',
            acceptLabel: 'Delete collection',
            acceptIcon: 'none',
            acceptButtonStyleClass: 'p-button p-button-lg p-button-danger',
            accept: () => {
                this.deleteCollection();
            }
        });
    }

    private deleteCollection() {
        const query = `mutation AADeleteCollection($input: CollectionDeleteInput!){
            deleteCollection(input: $input)
        }`;

        this.apiService.graphql<{ deleteCollection: boolean }>(query, { input: { id: this.collectionId() } }).pipe(
            take(1),
            catchError((e) => {
                console.warn(e);
                return of(null);
            })
        ).subscribe(res => {
            if (!!res && res.deleteCollection) {
                this.delete.emit();
            } else {
                setTimeout(() => {
                    this.confirmationService.confirm({
                        defaultFocus: 'none',
                        message: 'Unable to delete this collection as it is required by one or more questions.',
                        header: 'Unable to delete collection',
                        rejectLabel: 'Close',
                        rejectIcon: 'none',
                        rejectButtonStyleClass: 'p-button p-button-lg p-button-outlined',
                        acceptVisible: false,
                    });
                }, 100);
            }
        });
    }

    updateName() {
        this.nameLoading.set(true);

        const query = `mutation updateCollectionName($input: CollectionUpdateInput!){
            updateCollection(input: $input){
                name
            }
        }`;

        const input = {
            id: this.collectionId(),
            name: this.nameForm.get('name').value
        };

        this.apiService.graphql<{ updateCollection: { name: string } }>(query, { input }).pipe(
            take(1),
            catchError((e) => {
                console.warn(e);
                return of(null);
            })
        ).subscribe(res => {
            if (!!res) {
                this.collectionName.set(res.updateCollection.name);
                this.editName.set(false);
            }
            this.nameLoading.set(false);
        });
    }

    importCollection(importType: CollectionImportType) {
        const headerMap = {
            csv: 'CSV',
            geojson: 'geoJSON',
            shapefile: 'shapefile'
        }
        const header = `Import from ${headerMap[importType]}`;

        this.dialogRef = this.dialogService.open(ImportCollectionModalComponent, {
            header,
            data: {
                projectId: this.projectId(),
                collectionId: this.collectionId(),
                importType,
                newCollection: this.mode() === 'create',
                name: this.newCollectionName() || null,
            },
            width: '620px'
        });

        this.dialogRef.onClose.pipe(
                filter(res => !!res),
                tap(res => {
                    if (this.mode() === 'create' && this.location() === 'modal') {
                        this.importingNewCollection.set(true);
                    } else if (this.mode() === 'edit' && this.location() === 'modal') {
                        this.importingCollection.set(true);
                    }
                    this.collectionService.newCollectionJob.set(res);
                    this.collectionService.collectionDirty.set(false);
                })
            ).subscribe((result: CoreoJob) => {
                if (this.mode() === 'edit') {
                    this.jobService.addJob(result).pipe(
                        ).subscribe({
                            complete: () => {
                                this.refreshAll();
                                this.collectionService.newCollectionJob.set(null);
                                this.importingCollection.set(false);
                            }
                        });
                } else if (this.mode() === 'create' && result.id) {
                    this.jobService.addJob(result).pipe(
                        filter(job => job.status === 'complete'),
                        tap((job) => {
                            this.collectionId.set(parseInt(job.url));
                            this.mode.set('edit');
                            this.loadCollection();
                            this.refreshAll();
                            this.collectionService.newCollectionJob.set(null);
                            this.importingNewCollection.set(false);
                        })
                    ).subscribe();
                }
            });
    }

    exportCollection() {
        const mutation = `mutation CoreoAAExportCollection($input: exportCollectionToCSVInput!){
            exportCollectionToCSV(input: $input){
                ...jobFields
            }
        }
        ${jobFieldsFragment}`;
        const input = {
            id: this.collectionId()
        };
        this.apiService.graphql<{ exportCollectionToCSV: CoreoJob }>(mutation, { input }).subscribe((response) => {
            this.jobService.addJob(response.exportCollectionToCSV);
        });
    }

    handleCreateGeometryItem() {
        const location = this.location();

        if (location === 'modal') {
            this.showCreateGeometryItem.set(true);
        } else if (location === 'page') {
            this.dialogRef = this.dialogService.open(CreateGeometryItemComponent, {
                header: 'Add geometry item',
                data: {
                    collectionId: this.collectionId(),
                },
                height: '95vh',
                width: '95vw',
                contentStyle: { 'padding': '0px', 'background': '#F3F7FC' }
            });
    
            this.dialogRef.onClose.subscribe((refresh: boolean) => {
                if (refresh) {
                    if (refresh) {
                        this.refreshItems();
                    }
                }
            });
        }
    }

    handleBackFromCreateGeometryItem(refresh: boolean) {
        this.showCreateGeometryItem.set(false);
        if (refresh) {
            this.refreshItems();
        }
    }

    handleItemClick(id: number) {
        this.item.emit(id);
    }

    saveNewCollection() {
        if (!this.canSaveNewCollection()) {
            return;
        }
        this.newCollectionSaving.set(true);
        this.save.emit();
    }

    refreshItems() {
        this.itemsTable().refresh(true);
    }

    refreshAttributes() {
        this.attributeTable().refresh()
    }

    refreshAll() {
        this.refreshItems();
        if (this.attributeTableVisible()) {
            this.refreshAttributes();
        }
    }
}