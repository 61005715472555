import { Component, effect, inject, input } from "@angular/core";
import { ButtonModule } from "primeng/button";
import { MapBuilderService } from "./map-builder.service";
import { MapBuilderItem } from "./map-builder.types";
import { MapBuilderCreateLayerComponent } from './map-builder-create-layer.component';
import { DialogService } from 'primeng/dynamicdialog';
import { OrderListModule } from 'primeng/orderlist';

@Component({
    selector: 'app-map-builder-sidebar',
    template: `            
        <div class="h-full flex flex-col justify-between">
            <div class="flex flex-col">
                <h4 class="font-bold text-sm mb-1">Form Layers</h4>
                <p-orderList class="map-order-list" [value]="formLayers()" [dragdrop]="true" (onReorder)="handleLayersReorder(true)">
                    <ng-template let-form pTemplate="item">
                        <div [attr.data-selected]="selected()?.uuid === form.uuid" (click)="select(form)"
                            class="bg-white rounded shadow p-2 cursor-pointer text-xs data-[selected=true]:font-semibold data-[selected=true]:bg-primary data-[selected=true]:text-white">
                            {{form.config.name}}
                        </div>
                    </ng-template>
                </p-orderList>
                @if(!isTestData()) {
                <h4 class="font-bold text-sm flex flex-grow-0 justify-between items-center mb-1">
                    Other Layers
                    <p-button size="small" styleClass="icon-only" (click)="createNewLayer()">
                        <i class="fa-solid fa-plus"></i>
                    </p-button>
                </h4>
                <p-orderList class="map-order-list" [value]="otherLayers()" [dragdrop]="true" (onReorder)="handleLayersReorder()">
                    <ng-template let-layer pTemplate="item">
                        <div [attr.data-selected]="selected()?.uuid === layer.uuid" (click)="select(layer)"
                            class="bg-white rounded shadow p-2 cursor-pointer text-xs flex flex-col data-[selected=true]:font-semibold data-[selected=true]:bg-primary data-[selected=true]:text-white" (click)="select(layer)">
                            <span>{{layer.config.name}}</span>
                            <span class="data-[selected=false]:text-text-secondary data-[selected=true]:text-white">
                                ({{layerLabels[layer.type]}})
                            </span>
                        </div>
                    </ng-template>
                </p-orderList>
                }
            </div>

            <p-button label="Save Changes" size="large" (click)="save()" [disabled]="!dirty()" class="w-full"/>
        </div>
    `,
    standalone: true,
    imports: [
        ButtonModule,
        OrderListModule
    ],
    providers: [
        DialogService
    ]
})
export class MapBuilderSidebarComponent {

    projectId = input<number>();
    isTestData = input<boolean>();

    mapBuilderService = inject(MapBuilderService);
    dialogService = inject(DialogService);

    formLayers = this.mapBuilderService.formLayers;
    otherLayers = this.mapBuilderService.otherLayers;
    selected = this.mapBuilderService.selected;
    dirty = this.mapBuilderService.dirty;

    layerLabels: Record<'image' | 'geojson' | 'heatmap' | 'collection', string> = {
        image: 'Image',
        geojson: 'GeoJSON',
        heatmap: 'Heatmap',
        collection: 'Collection'
    };

    handleLayersReorder(isForm: boolean = false) {
        this.mapBuilderService.reorderLayers(isForm);
    }

    select(item: MapBuilderItem) {
        this.mapBuilderService.select(item);
    }

    createNewLayer() {
        const records = this.formLayers().map(l => ({ id: l.config.id, label: l.config.name }));
        const collections = this.otherLayers().filter(l => l.type === 'collection')
            .map(l => ({ id: l.config.id, label: l.config.name }));

        const dialogRef = this.dialogService.open(MapBuilderCreateLayerComponent, {
            header: 'Create Map Layer',
            data: { records, collections, projectId: this.projectId() },
            width: '480px',
            modal: true
        });
        dialogRef.onClose.subscribe(async (res) => {
            if (res) {
                const { name, config: { paint, layout, layerType, sourceType, sourceId, source, bounds } } = res;

                const sort = this.otherLayers().length + 1;
                const type = layerType === 'heatmap' ? 'heatmap' : null;
    
                const newLayer = {
                    id: new Date().valueOf() * -1,
                    visible: true,
                    type, name, paint, layout, sourceType, 
                    sourceId, sort, source, bounds
                };
    
                this.mapBuilderService.createCustomLayer(newLayer);
                // const l = await this.mapBuilderService.saveCustomLayer(this.projectId(), newLayer);
                // this.mapBuilderService.addCustomLayer(l.id, newLayer);
            }
        });
    }

    save() {
        this.mapBuilderService.save();
    }

}