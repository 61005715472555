@if(parent){
<div class="w-full bg-danger text-white text-center font-semibold px-10 py-4">
    This project is derived from a master project "{{parent.name}}". Its forms, collections, pages and map layers cannot
    be
    edited. You may upload additional ones to augment the project.
</div>
}

<p-toast position="bottom-center" [life]="2000"></p-toast>

<div class="flex flex-col h-full p-8 max-w-7xl mx-auto w-full">
    <p-panel class="coreo-list">
        <ng-template pTemplate="header">
            <div class="coreo-list-header">
                <h2>
                    <i class="fa-solid fa-file"></i>Pages
                </h2>
                <p-button size="large" (click)="createPageModal($event)">
                    <svg-icon src="/assets/icons/plus.svg"></svg-icon>Create Page
                </p-button>
            </div>
        </ng-template>

        <ng-template pTemplate="content">
            <p-table [value]="pages()" [reorderableColumns]="true" (onRowReorder)="pagesReordered($event)"
                class="coreo-list-content">
                <ng-template pTemplate="body" let-page let-index="rowIndex">
                    <tr [pReorderableRow]="index" (click)="rowSelect(page.id)" class="coreo-list-content-item">
                        <div class="coreo-list-content-item-body text-xl">
                            <i class="icon ion-md-{{page.icon || 'help'}} text-grey-105"></i>
                            <span class="text-base font-bold text-primary w-[30%]">{{ page.title }}</span>
                            <span class="text-black text-sm w-[50%]">{{ typeMap[page.type] || 'Unknown' }}</span>
                            <i pTooltip="This page is the welcome page and will greet new users" tooltipPosition="top"
                                [class.invisible]="project.welcomePageId !== page.id"
                                class="fa-solid fa-house-chimney"></i>
                            <i pTooltip="This page has not been published" tooltipPosition="top"
                                [class.invisible]="page.published" class="fa-solid fa-eye-slash"></i>
                            <i (click)="deletePageModal($event, page.id)" pTooltip="Delete this page"
                                tooltipPosition="top" [class.invisible]="role !== 'admin'"
                                class="fa-solid fa-trash-can text-primary"></i>
                            <i [class.invisible]="role !== 'admin'" class="fa-solid fa-grip-vertical text-grey-105"
                                pReorderableRowHandle></i>
                        </div>
                    </tr>
                </ng-template>
            </p-table>
        </ng-template>
    </p-panel>

    @if(showEmpty()){
    <div class="coreo-list-no-content">
        <i class="fa-solid fa-file"></i>
        <h1>There are no pages yet.</h1>
        <p>Pages are a great way to display information about your project. You can create a custom information page
            using the custom page builder, or import a collection as a list or detail view</p>
        <p-button size="large" (click)="createPageModal($event)">
            <svg-icon src="/assets/icons/plus.svg"></svg-icon>Create Page
        </p-button>
    </div>
    }
</div>