<form class="w-full p-4 space-y-5">
    <div class="flex items-center justify-between">
        <label class="text-sm text-text-primary font-normal flex items-center" for="limitNumber">
            Limit number of repeated entries
        </label>
        <p-inputSwitch inputId="limitNumber" name="limitNumber" [ngModel]="limitNumber()" (ngModelChange)="limitChanged($event)" />
    </div>
    @if (limitNumber()) {
        <div class="space-y-1">
            <div class="flex items-start justify-normal gap-5">
                <div class="flex flex-col gap-1">
                    <label class="text-xs text-grey-70 font-normal" for="min">Minimum entries</label>
                    <p-inputNumber 
                        inputId="min"
                        name="min"
                        [min]="0"
                        [ngModel]="min()"
                        (ngModelChange)="update.emit({config: { min: $event, max: max() }})"
                        placeholder="Min no. of repeats"
                        styleClass="w-full"
                        [ngClass]="{'ng-dirty ng-invalid': minMaxError()}" /> 
                </div>
                <div class="flex flex-col gap-1">
                    <label class="text-xs text-grey-70 font-normal" for="max">Maximum entries</label>
                    <p-inputNumber 
                        inputId="max" 
                        name="max"
                        [min]="0"
                        [ngModel]="max()"
                        (ngModelChange)="update.emit({config: { min: min(), max: $event }})"
                        placeholder="Max no. of repeats"
                        styleClass="w-full"
                        [ngClass]="{'ng-dirty ng-invalid': minMaxError()}" /> 
                </div>
            </div>
            @if (minMaxError()) {
                <small class="text-danger">Maximum entries can't be less than the minimum</small>
            }
        </div>
    }
</form>