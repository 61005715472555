import { Component, Input, Output, EventEmitter } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ColorPickerModule } from 'primeng/colorpicker';

@Component({
    selector: 'app-color-picker',
    template: `
    <div class="flex border rounded-md max-w-[132px] max-h-[42px] overflow-hidden {{ !isValidColor(color) ? 'border-danger' : 'border-[#D3DBE3]'}}">
        <p-colorPicker appendTo="body" [(ngModel)]="color" (ngModelChange)="updateColor($event)" />
        <input #colorInput type="text" minlength="7" maxlength="7" placeholder="#ffffff" [(ngModel)]="color"
            (ngModelChange)="updateColor($event)" class="text-sm text-black w-[90px] flex items-center outline-none border-transparent px-2.5" />
    </div>
    `,
    standalone: true,
    imports: [
        FormsModule,
        ColorPickerModule,
        ReactiveFormsModule
    ]
})
export class ColorPickerComponent {
    @Input() color: string = '#ffffff';
    @Output() colorChange: EventEmitter<string> = new EventEmitter();

    isValidColor(color: string) {
        return /^#([A-Fa-f0-9]{6})$/.test(color);
    }

    updateColor(color: string) {
        if (this.isValidColor(color)) { 
            this.colorChange.emit(color.toLocaleLowerCase());
        }
    }
}