<form class="w-full p-4 space-y-5">
    <div class="flex flex-col gap-1">
        @if (saved()) {
            <label class="text-sm text-text-primary font-normal" for="collections">For the {{ listType() }} answers you are using:</label>
            <p class="font-semibold">{{ collectionName() }}</p>
        }
        @else {
            <label class="text-sm text-text-primary font-normal" for="collections">Use an existing collection to choose answers from <sup>*</sup></label>
            <form>
                <p-dropdown [options]="collections()" [ngModel]="collectionId()" (ngModelChange)="selectCollection($event)" name="collections" optionLabel="name" optionValue="id" [showClear]="false" placeholder="Choose a collection" required #collectionControl="ngModel" [ngClass]="{'ng-dirty ng-invalid': collectionControl.hasError('required')}"></p-dropdown>
                @if(collectionControl.hasError('required')){
                    <small class="text-danger">A collection is required</small>
                }
            </form>
        }
        @if (answers()) {
            <p class="text-[10px] uppercase text-text-secondary mt-1">Answers:</p>
            <p class="text-xs text-text-primary font-semibold">{{ answers() }}</p>
        }
    </div>

    <div class="flex flex-col items-center justify-center gap-4">
        @if (collectionId()) {
            <p-button styleClass="px-5 w-full" class="w-full" label="Edit selected collection" size="large" [outlined]="true" (click)="openListModal(collectionId())"></p-button>
        }
        @if (!saved()) {
            <p-button styleClass="px-5 w-full" class="w-full" label="Create a new collection" size="large" [outlined]="true" (click)="openListModal()"></p-button>
        }
    </div>
</form>