<div class="relative h-full w-full flex flex-col">
    <div class="grow p-4">
        <div class="relative h-full w-full overflow-hidden flex items-center justify-center">
            @if (currentImage(); as image) {
            <img [src]="src()" alt="" class="absolute h-full object-contain">
            }
        </div>
    </div>
    <div class="flex-none p-5 flex items-center justify-end border-t border-grey-border" [ngClass]="{'justify-between': multiple()}">
        @if (multiple()) {
        <div class="flex items-center gap-5">
            <p-button (click)="previous()" [text]="true" [disabled]="first()">
                <i class="fa-solid fa-chevron-left"></i>
                Previous
            </p-button>
            <p-button (click)="next()" [text]="true" [disabled]="last()">
                Next
                <i class="fa-solid fa-chevron-right"></i>
            </p-button>
        </div>
        }
        <p-button (click)="downloadImage()" label="Download image"></p-button>
    </div>
</div>