<div class="h-[82px] flex justify-end gap-4">

    <!-- Geo Types -->
    @if((currentMode$ | async) === 'create'){
        <div class="h-full bg-white rounded-3xl p-[10px]">
            <div class="flex flex-col items-center gap-1">
                <div class="flex items-center gap-1">
                    <svg-icon class="block h-3 w-3 text-primary cursor-pointer" src="/assets/icons/chevron-left.svg" (click)="changeGeoType(false)"/>
                    <div class="text-[#444] rounded-full border-2 border-primary w-12 h-12 flex items-center justify-center">
                        <svg-icon src="/assets/icons/{{geoTypes[currentGeoTypeIndex()].icon}}.svg" class="w-5 h-5 block" />
                    </div>
                    <svg-icon class="block h-3 w-3 text-primary cursor-pointer -scale-x-100" src="/assets/icons/chevron-left.svg" (click)="changeGeoType(true)"/>
                </div>
                <p class="text-[10px] font-semibold leading-none whitespace-nowrap">{{geoTypes[currentGeoTypeIndex()].label}}</p>
            </div>
        </div>
    }
  
    <!-- Delete/Draw -->
    <div class="h-full bg-white rounded-3xl px-4 py-[10px] flex gap-5">
        <button class="flex flex-col items-center gap-1" (click)="toggleDrawing()"
        [pTooltip]="tooltips.snapping" tooltipPosition="bottom" [showDelay]="showTooltipDelay" [hideDelay]="hideTooltipDelay">
            <div class="rounded-full shadow-lg w-12 h-12 flex items-center justify-center"
            [ngClass]="{'text-[#444]': !drawing, 'text-white': drawing, 'bg-primary': drawing}">
                <svg-icon src="/assets/icons/edit-pen.svg" class="w-6 h-6 block" />
            </div>
            <p class="text-[10px] font-semibold leading-none whitespace-nowrap">Freehand</p>
        </button>
      <button class="flex flex-col items-center gap-1" [disabled]="!(canComplete$ | async)"
        (click)="complete()"  [pTooltip]="tooltips.complete" tooltipPosition="bottom" [showDelay]="showTooltipDelay" [hideDelay]="hideTooltipDelay">
        <div class="text-[#444] rounded-full shadow-lg w-12 h-12 flex items-center justify-center">
          <svg-icon src="/assets/icons/check.svg" class="w-6 h-6 block" />
        </div>
        <p class="text-[10px] font-semibold leading-none whitespace-nowrap">Complete</p>
      </button>
      <button class="flex flex-col items-center gap-1" [disabled]="!(canDelete$ | async) && !(isPolygonSelected$ | async)"
        (click)="delete()"  [pTooltip]="tooltips.delete" tooltipPosition="bottom" [showDelay]="showTooltipDelay" [hideDelay]="hideTooltipDelay">
        <div class="text-[#444] rounded-full shadow-lg w-12 h-12 flex items-center justify-center">
          <svg-icon src="/assets/icons/trash.svg" class="w-6 h-6 block" />
        </div>
        <p class="text-[10px] font-semibold leading-none whitespace-nowrap">Delete</p>
      </button>
      <button class="flex flex-col items-center gap-1" (click)="toggleSnapping()"
        [pTooltip]="tooltips.snapping" tooltipPosition="bottom" [showDelay]="showTooltipDelay" [hideDelay]="hideTooltipDelay">
        <div class="rounded-full shadow-lg w-12 h-12 flex items-center justify-center"
          [ngClass]="{'text-[#444]': !snapping, 'text-white': snapping, 'bg-primary': snapping}">
          <svg-icon src="/assets/icons/snap.svg" class="w-8 h-8 block" />
        </div>
        <p class="text-[10px] font-semibold leading-none whitespace-nowrap">Snapping</p>
      </button>
  
      <button class="flex flex-col items-center gap-1" (click)="toggleOverlaps()"
          [pTooltip]="tooltips.overlaps" tooltipPosition="bottom" [showDelay]="showTooltipDelay" [hideDelay]="hideTooltipDelay">
          <div class="rounded-full shadow-lg w-12 h-12 flex items-center justify-center"
            [ngClass]="{'text-[#444]': !preventOverlaps, 'text-white': preventOverlaps, 'bg-primary': preventOverlaps}">
            <svg-icon src="/assets/icons/no-overlap.svg" class="w-6 h-6 block" />
          </div>
        <p class="text-[10px] font-semibold leading-none whitespace-nowrap">No Overlaps</p>
      </button>
    <!-- Settings -->
    <!-- <app-project-map-toolbar-settings [gis]="gis()"></app-project-map-toolbar-settings> -->
    </div>
  
    <!-- Edit Controls -->
    <div class="h-full bg-white rounded-3xl px-4 py-[10px] flex gap-5" [ngClass]="{'edit-controls': true, 'editing': editing$ | async}">
  
      <!-- Edit Complete Options -->
      <button class="flex flex-col items-center gap-1 edit-complete" (click)="cancelEdit()"
        *ngIf="editing$ | async" [@enterAnimation] >
        <div class="text-[#444] rounded-full shadow-lg w-12 h-12 flex items-center justify-center">
          <svg-icon src="/assets/icons/close.svg" class="w-6 h-6 block text-danger" />
        </div>
        <p class="text-[10px] font-semibold leading-none whitespace-nowrap">Cancel</p>
      </button>
      <button class="flex flex-col items-center gap-1 edit-complete edit-complete-complete"
        (click)="completeEdit()" *ngIf="(editing$ | async) && (showCompleteEdit$ | async)" [@enterAnimation]>
        <div class="text-[#444] rounded-full shadow-lg w-12 h-12 flex items-center justify-center">
          <svg-icon src="/assets/icons/check.svg" class="w-6 h-6 block text-green-400" />
        </div>
        <p class="text-[10px] font-semibold leading-none whitespace-nowrap">Done</p>
      </button>
  
      <!-- Edit Options -->
      <button class="flex flex-col items-center gap-1 edit-option" (click)="setMode('cut')"
        *ngIf="!(editing$ | async)" [@enterAnimation] [disabled]="!(canEdit$ | async)"  [pTooltip]="tooltips.cutout" tooltipPosition="bottom" [showDelay]="showTooltipDelay" [hideDelay]="hideTooltipDelay">
        <div class="text-[#444] rounded-full shadow-lg w-12 h-12 flex items-center justify-center">
          <svg-icon src="/assets/icons/cutout.svg" class="w-6 h-6 block" />
        </div>
        <p class="text-[10px] font-semibold leading-none whitespace-nowrap">Cutout</p>
      </button>
      <button class="flex flex-col items-center gap-1 edit-option" (click)="setMode('merge')"
        *ngIf="!(editing$ | async)" [@enterAnimation] [disabled]="!(canEdit$ | async)"  [pTooltip]="tooltips.merge" tooltipPosition="bottom" [showDelay]="showTooltipDelay" [hideDelay]="hideTooltipDelay">
        <div class="text-[#444] rounded-full shadow-lg w-12 h-12 flex items-center justify-center">
          <svg-icon src="/assets/icons/merge.svg" class="w-6 h-6 block" />
        </div>
        <p class="text-[10px] font-semibold leading-none whitespace-nowrap">Extend</p>
      </button>
      <button class="flex flex-col items-center gap-1 edit-option" (click)="setMode('split')"
        *ngIf="!(editing$ | async)" [@enterAnimation] [disabled]="!(canEdit$ | async)"  [pTooltip]="tooltips.split" tooltipPosition="bottom" [showDelay]="showTooltipDelay" [hideDelay]="hideTooltipDelay">
        <div class="text-[#444] rounded-full shadow-lg w-12 h-12 flex items-center justify-center">
          <svg-icon src="/assets/icons/split.svg" class="w-6 h-6 block" />
        </div>
        <p class="text-[10px] font-semibold leading-none whitespace-nowrap">Split</p>
      </button>
      <button class="flex flex-col items-center gap-1 edit-option" (click)="setMode('diff')"
        *ngIf="!(editing$ | async)" [@enterAnimation] [disabled]="!(canEdit$ | async)"  [pTooltip]="tooltips.diff" tooltipPosition="bottom" [showDelay]="showTooltipDelay" [hideDelay]="hideTooltipDelay">
        <div class="text-[#444] rounded-full shadow-lg w-12 h-12 flex items-center justify-center">
          <svg-icon src="/assets/icons/diff.svg" class="w-6 h-6 block" />
        </div>
        <p class="text-[10px] font-semibold leading-none whitespace-nowrap">Clip</p>
      </button>
      <button class="flex flex-col items-center gap-1 edit-option" (click)="diffAll()"
        *ngIf="!(editing$ | async)" [@enterAnimation] [disabled]="!(canEdit$ | async)"  [pTooltip]="tooltips.diffAll" tooltipPosition="bottom" [showDelay]="showTooltipDelay" [hideDelay]="hideTooltipDelay">
        <div class="text-[#444] rounded-full shadow-lg w-12 h-12 flex items-center justify-center">
          <svg-icon src="/assets/icons/diff_all.svg" class="w-6 h-6 block" />
        </div>
        <p class="text-[10px] font-semibold leading-none whitespace-nowrap">Clip All</p>
      </button>
    </div>

  </div>
  