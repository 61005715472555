<div class="flex items-center justify-between">
    <div class="flex items-center gap-2">
        <p class="font-bold">Set condition</p>
        @if (showOrderWarning()) {
            <i class="fa-solid fa-triangle-exclamation text-danger cursor-pointer" pTooltip="The selected question occurs after the current question. This may cause a user to miss the current question becoming visible."></i>
        }
    </div>
    <i class="fas fa-trash-can text-danger cursor-pointer mr-1" (click)="remove.emit()"></i>
</div>
<form class="space-y-2">

    <!-- Question -->
    <p-dropdown
        name="question"
        #questionControl="ngModel"
        [options]="questions()"
        [ngModel]="path()"
        (onChange)="questionChanged($event)" 
        optionLabel="name"
        optionValue="value"
        placeholder="Select answer label"
        class="block"
        appendTo="body"
        required
        [ngClass]="{'ng-dirty ng-invalid': questionControl.hasError('required')}" />
    
    @if (condition() !== null && condition !== undefined) {
        <!-- Condition -->
        <p-dropdown
            name="condition"
            #conditionControl="ngModel"
            [options]="conditionOptions()"
            [ngModel]="condition()"
            (onChange)="conditionChanged($event)" 
            optionLabel="name"
            optionValue="value"
            placeholder="Select condition"
            class="block"
            appendTo="body"
            required 
            [ngClass]="{'ng-dirty ng-invalid': conditionControl.hasError('required')}"/>

    
        <!-- Comparand -->
        @if (condition() !== 'answered' && condition() !== 'unanswered') {
            @switch (attributeType()) {
                @case ('integer') {
                    <p-inputNumber
                        name="integerComparand"
                        #integerComparandControl="ngModel"
                        [ngModel]="comparand()"
                        class="w-full"
                        styleClass="w-full"
                        (onInput)="comparandChanged($event.value)"
                        placeholder="Enter number"
                        required
                        [ngClass]="{'ng-dirty ng-invalid': integerComparandControl.hasError('required')}" /> 
                }
                @case ('float') {
                    <p-inputNumber
                        name="floatComparand"
                        #floatComparandControl="ngModel"
                        [ngModel]="comparand()"
                        mode="decimal" [minFractionDigits]="0"
                        [maxFractionDigits]="10"
                        class="w-full"
                        styleClass="w-full"
                        (onInput)="comparandChanged($event.value)"
                        placeholder="Enter number"
                        required
                        [ngClass]="{'ng-dirty ng-invalid': floatComparandControl.hasError('required')}" /> 
                }
                @case ('text') {
                    <input
                        name="textComparand"
                        #textComparandControl="ngModel"
                        type="text" pInputText
                        [ngModel]="comparand()"
                        (ngModelChange)="comparandChanged($event)"
                        class="w-full"
                        placeholder="Enter text"
                        required
                        [ngClass]="{'ng-dirty ng-invalid': textComparandControl.hasError('required')}" /> 
                }
                @case ('date') {
                    <p-calendar
                        name="dateComparand"
                        #dateComparandControl="ngModel"
                        dateFormat="d MM yy"
                        appendTo="body"
                        [ngModel]="dateComparand()"
                        [iconDisplay]="'input'"
                        [showIcon]="true"
                        (onSelect)="dateComparandChanged($event)"
                        class="block"
                        styleClass="w-full"
                        placeholder="Select date"
                        required
                        [ngClass]="{'ng-dirty ng-invalid': dateComparandControl.hasError('required')}" /> 
                }
                @case ('datetime') {
                    <p-calendar
                        name="datetimeComparand"
                        #datetimeComparandControl="ngModel"
                        dateFormat="d MM yy"
                        appendTo="body"
                        [ngModel]="dateComparand()"
                        [iconDisplay]="'input'"
                        [showIcon]="true"
                        (onSelect)="dateComparandChanged($event)"
                        class="block"
                        styleClass="w-full"
                        [showTime]="true"
                        placeholder="Select date and time"
                        required
                        [ngClass]="{'ng-dirty ng-invalid': datetimeComparandControl.hasError('required')}" /> 
                }
                @case ('select') {
                    <p-dropdown
                        name="selectComparand"
                        #selectComparandControl="ngModel"
                        [options]="collectionItems()"
                        [loading]="!collectionItems()"
                        [ngModel]="comparand()"
                        (onChange)="comparandChanged($event.value)"
                        optionLabel="value"
                        optionValue="key"
                        class="block"
                        placeholder="Select value"
                        appendTo="body"
                        required
                        [ngClass]="{'ng-dirty ng-invalid': selectComparandControl.hasError('required')}" /> 
                }
                @case ('multiselect') {
                    <p-dropdown
                        name="multiselectComparand"
                        #multiselectComparandControl="ngModel"
                        [options]="collectionItems()"
                        [loading]="!collectionItems()"
                        [ngModel]="comparand()"
                        (onChange)="comparandChanged($event.value)"
                        optionLabel="value"
                        optionValue="key"
                        class="block"
                        placeholder="Select value"
                        appendTo="body"
                        required
                        [ngClass]="{'ng-dirty ng-invalid': multiselectComparandControl.hasError('required')}" /> 
                }
            }
        }
    }

    @if(questionControl.hasError('required') || conditionHasError() || comparandHasError()){
        <small class="block text-danger">All condition fields are required</small>
    }
</form>