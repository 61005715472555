<p-confirmDialog></p-confirmDialog>
<p-dialog header="Pending subscription changes" [(visible)]="visible" [modal]="true" [draggable]="false" [closable]="true"  styleClass="sm:w-[300px]">
    <div class="flex flex-col gap-4">
        <h2 class="text-sm text-[#444444]">
            Your scheduled changes to your subscription will be effective on <strong>{{ subscriptionService._organisation()?.subscription?.scheduledChanges?.scheduleStart }}.</strong>
        </h2>

        <div class="flex flex-col bg-[#F3F7FC] rounded-lg">
            @if (subscriptionService._organisation()?.subscription?.scheduledChanges?.pendingTier !== null) {
                <div class="flex justify-between items-center p-4">
                    <span class="text-xs text-[#878F97]">Plan changes</span>
                    <span class="text-sm text-[#444444] font-semibold">
                        {{ subscriptionService._organisation()?.tier.name }} 
                        <i class="fas fa-arrow-right mx-1"></i> 
                        {{ subscriptionService._organisation()?.subscription?.scheduledChanges?.pendingTier }}
                    </span>
                </div>
            }
            @if (subscriptionService._organisation()?.subscription?.scheduledChanges?.pendingSeatQuantity !== null) {
                <div class="flex justify-between items-center p-4">
                    <span class="text-xs text-[#878F97]">Plan seat changes</span>
                    <span class="text-sm text-[#444444] font-semibold">
                        {{ subscriptionService._organisation()?.seats }} 
                        <i class="fas fa-arrow-right mx-1"></i> 
                        {{ subscriptionService._organisation()?.subscription?.scheduledChanges?.pendingSeatQuantity }}
                    </span>
                </div>
            }
            @if (subscriptionService._organisation()?.subscription?.scheduledChanges?.pendingLicenseQuantity !== null) {
                <div class="flex justify-between items-center p-4">
                    <span class="text-xs text-[#878F97]">UKHab Licenses</span>
                    <span class="text-sm text-[#444444] font-semibold">
                        {{ subscriptionService._organisation()?.ukhabSeats }} 
                        <i class="fas fa-arrow-right mx-1"></i> 
                        {{ subscriptionService._organisation()?.subscription?.scheduledChanges?.pendingLicenseQuantity }}
                    </span>
                </div>
            }
        </div>
    </div>
    <div class="pt-4">
        <p-button type="button" size="large" label="Cancel pending changes" class="w-full" styleClass="w-full" (click)="confirmCancelPending($event)" />
    </div>
</p-dialog>
<div class="flex justify-center items-center w-full">
    <form [formGroup]="organisationProfileForm" (ngSubmit)="updateOrganisation()" class="w-full">
        @if (subscriptionService.isSubscription() && _isSuperAdmin()) {
        <div class="flex items-center justify-center gap-8 mt-6 text-xs text-grey-50">
            <span>CUSTOMER_ID: {{ subscriptionService._organisation()?.customerId }}</span>
            <span>SUBSCRIPTION_ID: {{ subscriptionService._organisation()?.subscriptionId }}</span>
        </div>
        }
        <div class="flex justify-center space-x-4 p-4 w-full">
            <p-panel class="flex flex-col max-w-[800px]" styleClass="w-full">
                <ng-template pTemplate="header">
                    <div class="flex items-center gap-3">
                        <i class="fas fa-building"></i>
                        <span class="p-panel-title">Organisation Settings</span>
                    </div>
                    <p-button [disabled]="organisationProfileForm.invalid || organisationProfileForm.pristine" size="large" label="Update Profile" type="submit"/>
                </ng-template>
                <div class="flex flex-col items-center justify-center h-[360px]">
                    <div class="flex flex-col items-center justify-center gap-5">
                        <p-avatar styleClass="w-36 h-36 items-center bg-[#DFEEFF] rounded-full overflow-hidden">
                            <img [src]="imageUrl || './assets/icons/coreo-logo-square.svg'" 
                                class="w-36 h-36 object-cover" [class.p-6]="!imageUrl" />
                        </p-avatar>
                        <p-button type="button" (click)="isVisibleUploadImageDialog.set(true)" [outlined]="true" label="Change picture"/>
                    </div>
                    <div class="w-full">
                        <label for="name" class="text-sm text-grey-120 font-normal">Organisation Name</label>
                        <input type="text" pInputText formControlName="name" class="w-full mt-2">
                    </div>
                </div>
            </p-panel>
            @if(subscriptionService.isNewPaymentFeatureAvailable()){
            <p-panel class="flex flex-col max-w-sm">
                <ng-template pTemplate="header">
                    <div class="flex items-center gap-3 py-2">
                        <i class="fas fa-credit-card"></i>
                        <span class="p-panel-title">Billing Settings</span>
                    </div>
                </ng-template>
                <div class="flex flex-col items-center gap-9 h-[360px]">
                    <div class="flex flex-col gap-1 items-center justify-center">
                        <span class="text-[10px] font-bold uppercase text-grey-50">Coreo Plan</span>
                        <div class="text-lg font-semibold">{{subscriptionService._organisation()?.tier.name}}</div>
                        <div class="text-[12px] text-grey-50">
                            @if (subscriptionService.isSubscription()) {
                            <span>{{ subscriptionMessage() }}</span>
                            } @else if (subscriptionService.isFreeTrial()) {
                            <span class="p-error">{{ freeTrialMessage() }}</span>
                            }
                        </div>
                        @if (subscriptionService.isSubscription() && subscriptionService.isDowngradePending()) {
                            <div class="py-2">
                                <p-button label="View pending changes" icon="pi pi-info-circle" (click)="showDialog()" [outlined]="true"></p-button>
                            </div>
                        }
                    </div>
                    <div class="flex flex-col justify-center gap-2 w-full">
                        <div class="max-h-9 flex items-center justify-between border-b border-[#D3DBE3]">
                            <span class="text-[10px] font-semibold text-[#878F97] uppercase">Plan SEATS</span>
                            <span class="text-xs text-[#444444]">
                                <span class="font-normal pr-2.5">{{ subscriptionService._seatsFilled() }} used</span>
                                <span class="font-semibold pl-2.5 border-l border-[#444444]">{{ planSeatsAvailable() }} available</span>
                            </span>
                        </div>
                        @if(subscriptionService._organisation()?.ukhabSeats) {
                        <div class="max-h-9 flex items-center justify-between border-b border-[#D3DBE3]">
                            <span class="text-[10px] font-semibold text-[#878F97] uppercase">UKHAB</span>
                            <span class="text-xs font-semibold text-[#444444]">{{ subscriptionService._organisation()?.ukhabSeats }} licence{{subscriptionService._organisation()?.ukhabSeats > 1 ? 's' : ''}}</span>
                        </div>
                        }
                    </div>
                    <div class="flex flex-col justify-end w-full gap-4">
                        @if (!subscriptionService._organisation()?.subscription?.isCancelled && subscriptionService._organisation().subscription) {
                            <p-button type="button" size="large" [label]="subscriptionService.isSubscription() ? 'Manage Plan' : 'Manage your plan'" styleClass="w-full" (click)="isVisibleUpdatePlanDialog.set(true)" [disabled]="!subscriptionService._tiers().length"></p-button>
                        }

                        @if(!subscriptionService.isFreeTrial() && subscriptionService.isSubscription()) {
                        <p-button type="button" size="large" [outlined]="true" class="w-full" styleClass="w-full" (click)="subscriptionService.createBillingSession()">
                            {{subscriptionService.isSubscriptionCancelled() ? 'Reactivate subscription' : 'Manage Payment'}}
                            <i class="fa-solid fa-arrow-up-right-from-square"></i>
                        </p-button>
                        }
                    </div>
                </div>
            </p-panel>
            }
        </div>
    </form>
</div>

<app-upload-organisation-image
    [isVisibleUploadImageDialog]="isVisibleUploadImageDialog()"
    (visibleUploadImageChange)="onVisibleUploadImage($event)"/>

@if(subscriptionService._tiers().length) {
<app-update-plan
    [baseUrl]="_baseUrl()"
    [projects]="subscriptionService._organisation()?.projects"
    [seatsFilled]="subscriptionService._seatsFilled()"
    [isFreeTrial]="subscriptionService.isFreeTrial()"
    [isFreeTrialExpired]="subscriptionService.isFreeTrialExpired()"
    [isSubscription]="subscriptionService.isSubscription()"
    [tierId]="subscriptionService.currentTierId()"
    [organisationId]="subscriptionService._organisation()?.id"
    [interval]="subscriptionService.currentSubscriptionInterval()"
    [organisationTiers]="subscriptionService._tiers()"
    [trialTimeLeft]="subscriptionService._organisation()?.freeTrialEnd"
    [currentPlanSeats]="subscriptionService._organisation()?.seats"
    [currentAddonSeats]="subscriptionService._organisation()?.ukhabSeats || 0"
    [periodEnd]="subscriptionService._organisation()?.subscription?.periodEnd || null"
    [isVisibleUpdatePlanDialog]="isVisibleUpdatePlanDialog()" 
    [isDowngradePending]="subscriptionService.isDowngradePending()"
    (visibleUpdatePlanChange)="onVisibleUpdatePlan($event)"
    (onCancelPendingChange)="subscriptionService.onCancelPendingChange($event)" />
}

<app-remove-seats-filled
    [userId]="_userId()"
    [seatsFilled]="subscriptionService._seatsFilled()"
    [memberships]="subscriptionService._memberships()"
    [pendingMemberships]="subscriptionService._pendingMemberships()"
    [seatsSelected]="seatsSelected()"
    [isVisibleRemoveSeatsDialog]="isVisibleRemoveSeatsDialog()"
    (visibleRemoveSeatsChange)="onVisibleRemoveSeats($event)"
    (cancelPendingInvitation)="subscriptionService.onCancelPendingInvite($event)"/>

<p-toast position="bottom-center" [life]="2000"></p-toast>
