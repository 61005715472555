<form class="w-full p-4 space-y-5">
    <div class="flex flex-col gap-1">
        <label class="text-sm text-text-primary font-normal">Query a geometry collection</label>
        <label class="text-[10px] uppercase text-text-secondary">Select a preloaded geometry collection <sup>*</sup></label>
        <form>
            <p-dropdown [options]="geometryCollections()" [ngModel]="collectionId()" (ngModelChange)="onSelect($event)" name="collections" optionLabel="name" optionValue="id" [showClear]="false" placeholder="Choose a collection" required #collectionControl="ngModel" [ngClass]="{'ng-dirty ng-invalid': collectionControl.hasError('required')}"></p-dropdown>
            @if(collectionControl.hasError('required')){
                <small class="text-danger">A collection is required</small>
            }
        </form>
    </div>
</form>