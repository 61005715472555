
// Vendor Libraries
import * as angular from 'angular';
import ngSanitize from "angular-sanitize";
import { default as uiRouter } from "@uirouter/angularjs";
import * as LocalStorageModule from "angular-local-storage";
import * as bootstrap from "bootstrap/dist/js/bootstrap";
import bootstrapIconPicker from "bootstrap-iconpicker/bootstrap-iconpicker/js/bootstrap-iconpicker";
import * as uiBootstrap from "angular-ui-bootstrap";
import * as uiSortable from "angular-ui-sortable";
import toastr from "angular-toastr";
import hcMarked from "angular-marked";
import ngAria from "angular-aria";
import ngMessages from "angular-messages";
import ngMaterial from "angular-material";

import angularSummernote from "angular-summernote/dist/angular-summernote";
import colorPicker from "angularjs-color-picker";
import ngClipboard from "ngclipboard/dist/ngclipboard";
import ngAnimate from 'angular-animate';

require("angular-legacy-sortablejs-maintained");
import * as Sentry from "@sentry/browser";

// Redux
import ngRedux from "ng-redux";
import { middleware, enhancers } from "../store/store";
import rootReducer from "../store/root-reducer";

// Action Services
import ProjectActions from "../store/project/project.actions";
import ProjectsActions from "../store/projects/projects.actions";
import ProjectFormActions from "../store/form/form.actions";
import ProjectPageActions from "../store/page/page.actions";
import AuthActions from "../store/auth/auth.actions";
import RecordsActions from "../store/records/records.actions";
import RecordActions from "../store/record/record.actions";
import OrganisationsActions from "../store/organisations/organisations.actions";
import ProjectTemplatesActions from "../store/project-templates/project-templates.actions";

// Middleware
import { toastMiddleware, errorMiddleware } from "../store/middleware";
import {
    recordsMiddleware,
    recordsRefreshMiddleware,
} from "../store/records/records.middleware";

// Services
import CoreoAPI from './coreoApi';
import GeoJSON from './geojson';
import MapUtils from './mapUtils';
import Mapbox from './mapbox.service';
import MapboxStyle from './mapboxStyle.service';
import Tabulator from './tabulator.service';
import Boundaries from './boundaries.service';
import Modal from './modal.service';

// Factories
import CoreoGraphQLQuery from "./coreoGraphQLQuery";
import coreoQuery from "./coreoQuery";
import eventEmitter from "./utils/eventemitter.mixin";

// Directives
import AppHeaderDirective from "./app-header/app-header.directive";
import { ListDirective, ListItemDirective } from "./list/list.directive";
import HelpTooltipDirective from "./help-tooltip/help-tooltip.directive";
import FileChangeDirective from "./file-change/file-change.directive";
import CompareToDirective from "./compareTo.directive";
import StringToNumberDirective from "./stringToNumber.directive";
import DateOnlyDirective from "./dateOnly.directive";
import IFrameOnLoadDirective from "./iframeOnLoad.directive";
import CoreoSortableDirective from "./coreoSortable.directive";
import IconPickerDirective from "./icon-picker/icon-picker.directive";
import UsernameCheckDirective from "./usernameCheck.directive";
import PasswordCheckDirective from "./passwordCheck.directive";

// Components
import { CollectionItemComponent } from "./collection-item/collection-item.component";
import { CollectionSelectComponent } from "./collection-select/collection-select.component";
import { SearchableListComponent } from "./searchable-list/searchable-list.component";
import { PasswordFeedbackComponent } from "./passwordFeedback.component";
import { UsernameFeedbackComponent } from "./usernameFeedback.component";
import { AppBreadcrumbsComponent } from "./breadcrumbs/breadcrumbs.component";
import { AppTutorialComponent } from "./tutorial/tutorial.component";
import { AppTutorialCoachmarkComponent } from "./tutorial/tutorial-coachmark.component";
import { SelectAutocompleteComponent } from "./select-autocomplete/select-autocomplete.component";
import { MinScreenSizeBlockComponent } from "./min-screen-size-block/min-screen-size-block.component";
import { SidebarContentComponent } from "./sidebar-content/sidebar-content.component";

// Filters
import ioniconFilter from "./filters/ionicon.filter";
import capitalizeFilter from "./filters/capitalize.filter";
import bytesFilter from "./filters/bytes.filter";
import booleanFilter from "./filters/boolean.filter";
import cutFilter from "./filters/cut.filter";
import validationErrFilter from "./filters/validationErr.filter";
import collectionSelectAttributes from "./filters/collectionSelectAttributes.filter";
import authProviderNameFilter from "./filters/authProviderName.filter";
import authProviderIconFilter from "./filters/authProviderIcon.filter";

// Constants
import MAP_BASE_STYLES from "./mapbox-base-styles.constants";
import MAPBOX_STYLE_SPEC from "./mapbox-stye-spec.constant";

// CSS
import { getAuthIsAdmin, getAuthIsLoggedIn, getAuthOrganisationId, getOrganisationsList, getAuthCurrentOrganisationFreeTrialExpired, getAuthCurrentOrganisationSubscriptionExpired, getAuthCurrentOrganisationSubscriptionPastDue } from '../store/selectors';
import imageProxyFilter from './filters/imageProxy.filter';

uiSortable.name = 'ui.sortable';
angularSummernote.name = 'summernote';

export default angular.module("app.main", [
    uiRouter,
    LocalStorageModule,
    colorPicker.name,
    uiBootstrap,
    uiSortable.name,
    ngAnimate,
    toastr,
    hcMarked,
    ngAria,
    ngMessages,
    ngMaterial,
    angularSummernote.name,
    "app.auth",
    ngSanitize,
    ngRedux,
    ngClipboard
])
    .service('CoreoAPI', CoreoAPI)
    .service('GeoJSON', GeoJSON)
    .service('MapUtils', MapUtils)
    .service('Mapbox', Mapbox)
    .service('MapboxStyle', MapboxStyle)
    .service('Tabulator', Tabulator)
    .service('Boundaries', Boundaries)
    .service('ModalService', Modal)
    .service('ProjectActions', ProjectActions)
    .service('ProjectsActions', ProjectsActions)
    .service('ProjectFormActions', ProjectFormActions)
    .service('ProjectPageActions', ProjectPageActions)
    .service('AuthActions', AuthActions)
    .service('RecordsActions', RecordsActions)
    .service('RecordActions', RecordActions)
    .service('OrganisationsActions', OrganisationsActions)
    .service('ProjectTemplatesActions', ProjectTemplatesActions)
    .factory('toastMiddleware', toastMiddleware)
    .factory('errorMiddleware', errorMiddleware)
    .factory('recordsMiddleware', recordsMiddleware)
    .factory('recordsRefreshMiddleware', recordsRefreshMiddleware)
    .factory('CoreoGraphQLQuery', CoreoGraphQLQuery)
    .factory('CoreoQuery', coreoQuery)
    .factory('eventEmitter', eventEmitter)
    .directive('appHeader', ['$state', '$mdMenu', '$ngRedux', 'AuthActions', '$rootScope', '$transitions', '$location', 'docsUrl', ($state, $mdMenu, $ngRedux, AuthActions, $rootScope, $transitions, $location, docsUrl) => new AppHeaderDirective($state, $mdMenu, $ngRedux, AuthActions, $rootScope, $transitions, $location, docsUrl)])
    .directive('list', () => new ListDirective())
    .directive('listItem', () => new ListItemDirective())
    .directive('helpTooltip', () => new HelpTooltipDirective())
    .directive('fileChange', () => new FileChangeDirective())
    .directive('compareTo', () => new CompareToDirective())
    .directive('convertToNumber', () => new ConvertToNumberDirective())
    .directive('iframeOnload', () => new IFrameOnLoadDirective())
    .directive('coreoSortable', () => new CoreoSortableDirective())
    .directive('iconPicker', () => new IconPickerDirective())
    .directive('coreoPermissions', () => new CoreoPermissionsDirective())
    .directive('usernameCheck', ['$q', 'CoreoAPI', ($q, CoreoAPI) => new UsernameCheckDirective($q, CoreoAPI)])
    .directive('passwordCheck', ['$q', ($q) => new PasswordCheckDirective($q, CoreoAPI)])
    .directive('dateOnly', () => new DateOnlyDirective())
    .directive('stringToNumber', () => new StringToNumberDirective())
    .component('collectionItem', CollectionItemComponent)
    .component('collectionSelect', CollectionSelectComponent)
    .component('searchableList', SearchableListComponent)
    .component('passwordFeedback', PasswordFeedbackComponent)
    .component('usernameFeedback', UsernameFeedbackComponent)
    .component('appBreadcrumbs', AppBreadcrumbsComponent)
    .component('appTutorial', AppTutorialComponent)
    .component('appTutorialCoachmark', AppTutorialCoachmarkComponent)
    .component('tutorial', AppTutorialComponent)
    .component('coachmark', AppTutorialCoachmarkComponent)
    .component('selectAutocomplete', SelectAutocompleteComponent)
    .component('minScreenSizeBlock', MinScreenSizeBlockComponent)
    .component('sidebarContent', SidebarContentComponent)
    .filter('ionicon', ioniconFilter)
    .filter('capitalize', capitalizeFilter)
    .filter('authProviderName', authProviderNameFilter)
    .filter('authProviderIcon', authProviderIconFilter)
    .filter('bytes', bytesFilter)
    .filter('boolean', booleanFilter)
    .filter('cut', cutFilter)
    .filter('validationErr', validationErrFilter)
    .filter('collectionSelectAttributes', collectionSelectAttributes)
    .filter('imageProxy', imageProxyFilter)
    .constant('MAP_BASE_STYLES', MAP_BASE_STYLES)
    .constant('MAPBOX_STYLE_SPEC', MAPBOX_STYLE_SPEC)
    .factory('$exceptionHandler', ['$log', function ($log) {
        return function sentryExceptionHandler(exception, cause) {
            Sentry.captureException(exception);
            $log.error(exception, cause);
        }
    }])
    .config([
        '$urlServiceProvider',
        '$stateProvider',
        '$locationProvider',
        '$httpProvider',
        '$mdThemingProvider',
        '$mdInkRippleProvider',
        '$ariaProvider',
        '$ngReduxProvider',
        '$animateProvider',
        function ($urlServiceProvider, $stateProvider, $locationProvider, $httpProvider, $mdThemingProvider, $mdInkRippleProvider, $ariaProvider, $ngReduxProvider, $animateProvider) {
            Sentry.init({
                debug: false,
                dsn: "https://82670caaccb34fedab2ee67c5c942413@o416754.ingest.sentry.io/1313429",
                integrations: [
                    Sentry.browserTracingIntegration({
                        // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
                        // tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
                    }),
                    Sentry.replayIntegration({
                        maskAllText: false,
                        blockAllMedia: false,
                    }),
                ],
                // Performance Monitoring
                tracesSampleRate: 1.0, //  Capture 100% of the transactions
                // Session Replay
                replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
                replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
            });

            $locationProvider.html5Mode(true);
            $locationProvider.hashPrefix("");
            $urlServiceProvider.config.strictMode(false);

            // A list of states that you can enter whether or not your are authenticated
            const allowedUnauthenticatedStates = [
                "organisation-invitation",
                "intermediarypage.joined-organisation",
                "intermediarypage.join-project"
            ];

            $stateProvider
                .state("app", {
                    abstract: true,
                    template: require("!raw-loader!../layouts/app.layout.html").default,
                    controller: "AppLayoutController as ctrl",// This is a hack to get the controller to load
                })
                .state("unauthenticated", {
                    abstract: true,
                    template: require("!raw-loader!../layouts/unauthenticated.layout.html").default,
                    onEnter: ['$transition$', '$ngRedux', 'AuthActions', ($transition$, $ngRedux, AuthActions) => {
                        const toState = $transition$.to();
                        return AuthActions.init($ngRedux.dispatch).then(
                            (authenticated) => {
                                if (
                                    authenticated &&
                                    !allowedUnauthenticatedStates.includes(
                                        toState.name
                                    )
                                ) {
                                    return $transition$.router.stateService.target(
                                        "home"
                                    );
                                }
                                return true;
                            }
                        );
                    }],
                    controller: ['$scope', '$state', function ($scope, $state) {
                        $scope.state = $state;
                    }],
                });

            $urlServiceProvider.rules.otherwise("/");

            // alternatively, register the interceptor via an anonymous factory
            $httpProvider.interceptors.push(function () {
                return {
                    response: function (response) {
                        if (
                            response.config.url.indexOf("/graphql") !== -1 &&
                            response.data.errors
                        ) {
                            console.warn(
                                "GraphQL Error",
                                response.data.errors,
                                response
                            );
                        }
                        return response;
                    },
                };
            });

            // Setup theme
            $mdThemingProvider
                .theme("default")
                .primaryPalette("blue-grey")
                .accentPalette("blue");
            $mdThemingProvider.disableTheming();
            $mdInkRippleProvider.disableInkRipple();

            $ariaProvider.config({
                bindRoleForClick: false,
                bindKeydown: false,
            });

            $animateProvider.classNameFilter(/^((?!(spinner)).)*$/);

            $ngReduxProvider.createStoreWith(
                rootReducer,
                middleware,
                enhancers,
                {
                }
            );
        }])
    .run(['$window', '$location', '$transitions', 'googleAnalyticsId', '$mdDateLocale', '$filter', '$ngRedux', 'OrganisationsActions', function (
        $window,
        $location,
        $transitions,
        googleAnalyticsId,
        $mdDateLocale,
        $filter,
        $ngRedux,
        OrganisationsActions
    ) {
        const authState = {};
        $ngRedux.connect((state) => ({
            isLoggedIn: getAuthIsLoggedIn(state),
            isAdmin: getAuthIsAdmin(state),
            orgId: getAuthOrganisationId(state),
            organisations: getOrganisationsList(state),
            organisationFreeTrialExpired: getAuthCurrentOrganisationFreeTrialExpired(state),
            organisationSubscriptionExpired: getAuthCurrentOrganisationSubscriptionExpired(state),
            organisationSubscriptionPastDue: getAuthCurrentOrganisationSubscriptionPastDue(state),
        }), null)(authState);

        // $trace.enable('TRANSITION');
        // Setup Google Analytics
        if (googleAnalyticsId) {
            $window.ga("create", googleAnalyticsId, "auto");
        }

        $transitions.onBefore({}, function (ev) {
            if (authState.isLoggedIn && authState.organisations === null) {
                return $ngRedux.dispatch(
                    OrganisationsActions.loadOrganisations(authState.isAdmin && authState.orgId)
                ).then(() => {
                    return true;
                });
            }
            // Check free trial expired
            if ((authState.organisationFreeTrialExpired || authState.organisationSubscriptionExpired || authState.organisationSubscriptionPastDue) && !authState.isAdmin) {
                const to = ev.to();
                if (to.name === 'home') {
                    return true;
                }

                return ev.router.stateService.target('home', null, {
                    notify: true,
                    inherit: false,
                    reload: true
                });
            }
            // All OK
            return true;
        });

        $transitions.onSuccess({}, () => {
            window.scrollTo(0, 0);
            if (googleAnalyticsId) {
                $window.ga("send", "pageview", $location.path());
            }
        });

        // Angular material  now reparses the date after formatting meaning the parse
        // function must be ready to accept our british datestrings which the default Date object
        // won't recognise (e.g. 24/07/2020)

        // Also for some reason somewhere in angular material is calling this with date objects..?
        // Hence the extra handling

        $mdDateLocale.parseDate = function (dateString) {
            function isValidDate(d) {
                return !isNaN(d.valueOf());
            }
            if (
                (dateString instanceof Date && !isValidDate(dateString)) ||
                (typeof dateString === "string" && dateString.length === 0)
            ) {
                return new Date(NaN);
            } else if (dateString instanceof Date) {
                return dateString;
            }
            const splitDateString = dateString.split("/");
            const americanisedDateString = [
                splitDateString[1],
                splitDateString[0],
                splitDateString[2],
            ];
            return new Date(americanisedDateString);
        };

        $mdDateLocale.formatDate = function (date) {
            return date ? $filter("date")(date, "dd/MM/yyyy") : "";
        };
    }]);
