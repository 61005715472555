<p-toast position="bottom-center" />
<p-confirmDialog styleClass="hide-close"></p-confirmDialog>

@if (isGeometryCollection()) {
    <app-create-geometry-item (back)="handleBackFromCreateGeometryItem($event)" [ngClass]="{'hidden': !showCreateGeometryItem()}"></app-create-geometry-item>
}

<div class="h-full w-full grid grid-cols-1 grid-rows-[auto_1fr] gap-8 overflow-hidden" [ngClass]="{'p-5': location() === 'modal', 'p-10': location() === 'page', 'hidden': showCreateGeometryItem()}">
    <div class="w-full flex justify-between gap-5" [ngClass]="{'items-end': mode() === 'create', 'items-center': mode() === 'edit'}">
        @if (mode() === 'create') {
            <div class="flex flex-col gap-2">
                <label for="tempName" class="text-xs text-text-primary">Name of Collection <sup>*</sup></label>
                <input [(ngModel)]="newCollectionName" #newCollectionNameControl="ngModel" required type="text" pInputText id="tempName" type="text" placeholder="Add a name" class="w-full min-w-56 h-11" [ngClass]="{'ng-dirty ng-invalid': newCollectionNameControl.hasError('required') && newCollectionNameControl.dirty}" />
            </div>
        }
        @else if (editName()) {
            <form [formGroup]="nameForm" (ngSubmit)="updateName()" class="w-full flex items-center gap-[10px]">
                <input [ngModel]="collectionName()" type="text" pInputText id="name" type="text" placeholder="Name of collection" formControlName="name" class="w-full min-w-56 h-11" />
                <p-button type="submit" [loading]="nameLoading()" [outlined]="true" size="large" [disabled]="!nameForm.valid || nameForm.get('name').value === collectionName()" label="Update"></p-button>
                <p-button type="button" [text]="true" size="large" label="Cancel" (click)="editName.set(false)"></p-button>
            </form>
        }
        @else {
            <span class="flex items-center gap-1">
                <h2 class="text-3xl font-semibold text-nowrap truncate">{{ collectionName() }}</h2>
                <p-button [text]="true" size="large" (click)="editName.set(true)">
                    <i class="fa-solid fa-pen"></i>
                </p-button>
            </span>
        }
        <div class="flex items-center justify-end gap-[10px]">
            @if (location() === 'page') {
                <p-button [text]="true" size="large" (click)="settingsMenu.toggle($event)">
                    <i class="fa-solid fa-gear"></i>Settings
                </p-button>
                <p-menu #settingsMenu [popup]="true" [model]="settingsMenuItems" appendTo="body">
                    <ng-template pTemplate="item" let-item>
                        <div class="p-menuitem-link {{ item.class }}">
                            <i class="{{ item.icon }}"></i>
                            <span>{{ item.label }}</span>
                        </div>
                    </ng-template>
                </p-menu>
            }

            @if (location() === 'modal') {
                <p-button [text]="true" size="large" (click)="toggleAttributeTable()">
                    <i class="fa-solid fa-layer-group"></i>
                    {{ showAttributeTable() ? 'Hide attributes' : 'Show attributes' }}
                </p-button>
            }
            
            <p-button label="Import" [outlined]="true" size="large" (click)="importMenu.toggle($event)" [loading]="importingCollection()">
                <i class="fa-solid fa-cloud-arrow-up"></i>
            </p-button>
            <p-menu #importMenu [popup]="true" [model]="importMenuItems" appendTo="body">
                <ng-template pTemplate="item" let-item>
                    <div class="p-menuitem-link">
                        <span>{{ item.label }}</span>
                    </div>
                </ng-template>
            </p-menu>

            @if (mode() === 'create') {
                <p-button label="Save collection" size="large" (click)="saveNewCollection()" [disabled]="!canSaveNewCollection()" [loading]="newCollectionSaving()"></p-button>
            }

            @if (mode() === 'edit') {
                <p-button label="Export collection" [outlined]="true" size="large" (click)="exportCollection()">
                    <i class="fa-solid fa-cloud-arrow-down"></i>
                </p-button>
            }
        </div>
    </div>
    
    <div class="h-full w-full grid grid-rows-1 grid-cols-12 gap-5 min-h-0">
        <app-collection-table 
            #itemsTable
            (item)="handleItemClick($event)"
            (addNewGeometryItem)="handleCreateGeometryItem()"
            class="rounded-md overflow-hidden shadow-panel col-span-7"
            [ngClass]="{'col-span-full': location() === 'modal' && !showAttributeTable()}"
        ></app-collection-table>
        
        @if (attributeTableVisible()) {
            <app-attribute-table
                #attributeTable
                (hasUpdated)="refreshItems()"
                class="rounded-md overflow-hidden shadow-panel col-span-5"
            ></app-attribute-table>
        }
    </div>
</div>

@if (importingNewCollection()) {
    <div class="absolute inset-0 flex items-center justify-center bg-background opacity-70">
        <svg-icon src="/assets/icons/spinner.svg" class="h-10 w-10"></svg-icon>
    </div>
}