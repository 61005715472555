import { Component, computed, EventEmitter, inject, Output } from '@angular/core';
import { ProjectFormService } from '../project-form.service';
import { Attribute } from '../project-form.model';
import { FormsModule } from '@angular/forms';
import { DropdownModule } from 'primeng/dropdown';
import { NgClass } from '@angular/common';

type Projection = 'WHAT3WORDS' | 'OSNATGRID' | '27700' | '29903';

type CoordinateProjectionConfig = {
    projection: Projection;
}

@Component({
    selector: 'app-coordinate-projection-settings',
    templateUrl: 'coordinate-projection-settings.component.html',
    standalone: true,
    imports: [
        NgClass,
        FormsModule,
        DropdownModule
    ]
})

export class CoordinateProjectionSettingsComponent {

    form = inject(ProjectFormService);
    
    attribute = this.form.selectedAttribute;
    
    @Output() update: EventEmitter<Partial<Attribute>> = new EventEmitter();
    
    config = computed(() => {
        const attribute = this.attribute();
        const config = attribute.config as CoordinateProjectionConfig;
        return {
            projection: config.projection ?? null
        };
    });

    projection = computed(() => this.config().projection);

    projectionOptions: {label: string; value: Projection}[] = [
        { label: 'what3words', value: 'WHAT3WORDS' },
        { label: 'Ordnance Survey National Grid', value: 'OSNATGRID' },
        { label: 'British National Grid (EPSG:27700)', value: '27700' },
        { label: 'Irish Grid (EPSG:29903)', value: '29903' }
    ];
}
