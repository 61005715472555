import { Component, Input, computed, inject, input } from '@angular/core';
import { FormGroup, ReactiveFormsModule } from "@angular/forms";
import { SliderModule } from "primeng/slider";
import { DropdownModule } from "primeng/dropdown";
import { MapBuilderItem } from "./map-builder.types";
import { MapBuilderService } from './map-builder.service';
import { TooltipModule } from 'primeng/tooltip';

@Component({
    selector: 'app-map-builder-style-editor',
    standalone: true,
    template: `
        <div [formGroup]="form()" class="flex flex-col gap-y-4">
            <!-- Primary/Fallback Color -->
            <div class="flex items-center justify-between">
                <label>{{form().get('styleAttributeId')?.value > 0 ? 'Fallback' : 'Primary'}} Color</label>
                @if(isForm()){
                <i class="fa-solid fa-circle-exclamation text-sm text-text-secondary hover:text-text-primary cursor-pointer mr-auto ml-2"
                [pTooltip]="tooltipFallbackColor"
                tooltipStyleClass="min-w-[278px] max-w-[278px]"
                tooltipPosition="right"
                ></i>
                }
                <input type="color" class="color-picker" formControlName="color">
            </div>

            @if(showPoint()){
            <h4 class="text-sm font-bold">Point</h4>
            @if(form().get('pointStyle').value !== 'data-icons'){
            <div class="flex flex-col gap-y-4">
                <label>Radius <span>{{form().get('pointRadius').value}}</span></label>
                <p-slider [min]="1" [max]="20" [step]="1" formControlName="pointRadius" />
            </div>
            <div class="flex flex-col gap-y-4">
                <label>Opacity <span>{{form().get('pointOpacity').value}}</span></label>
                <p-slider [min]="0.0" [max]="1.0" [step]="0.1" formControlName="pointOpacity" />
            </div>
            <div class="flex items-center justify-between">
                <label>Border Color</label>
                <input type="color" class="color-picker" formControlName="pointBorderColor">
            </div>
            <div class="flex flex-col gap-y-4">
                <label>Border Width <span>{{form().get('pointBorderWidth').value}}</span></label>
                <p-slider [min]="0" [max]="20" [step]="1" formControlName="pointBorderWidth" />
            </div>
            <div class="flex flex-col gap-y-4">
                <label>Border Opacity <span>{{form().get('pointBorderOpacity').value}}</span></label>
                <p-slider [min]="0.0" [max]="1.0" [step]="0.1" formControlName="pointBorderOpacity" />
            </div>
            }
            }

            @if(showLinestring()){
            <h4 class="text-sm font-bold">LineString</h4>
            <div class="flex flex-col gap-y-4">
                <label>Line Width <span>{{form().get('lineWidth').value}}</span></label>
                <p-slider [min]="0" [max]="20" [step]="1" formControlName="lineWidth" />
            </div>
            }

            @if(showPolygon()){
            <h4 class="text-sm font-bold">Polygon</h4>
            <div class="flex flex-col gap-y-4">
                <label>Polygon Opacity <span>{{form().get('polygonOpacity').value}}</span></label>
                <p-slider [min]="0.0" [max]="1.0" [step]="0.1" formControlName="polygonOpacity" />
            </div>
            <div class="flex flex-col gap-y-4">
                <label>Border Width <span>{{form().get('polygonBorderWidth').value}}</span></label>
                <p-slider [min]="0" [max]="20" [step]="1" formControlName="polygonBorderWidth" />
            </div>
            <div class="flex items-center justify-between">
                <label>Border Color</label>
                <input type="color" class="color-picker" formControlName="polygonBorderColor">
            </div>
            <div class="flex flex-col gap-y-4">
                <label>Border Opacity <span>{{form().get('polygonBorderOpacity').value}}</span></label>
                <p-slider [min]="0.0" [max]="1.0" [step]="0.1" formControlName="polygonBorderOpacity" />
            </div>
            }
        </div>
        <ng-template #tooltipFallbackColor>
            <span class="text-xs">
                The fallback color is used when no matching styling can be found for a record, and when the map is zoomed out.
            </span>
        </ng-template>
    `,
    imports: [
        ReactiveFormsModule,
        SliderModule,
        DropdownModule,
        TooltipModule
    ],
    styles: [`
        label {
            @apply text-xs text-text-primary font-normal flex justify-between items-center;
        }
        .color-picker {
            width: 36px;
            border-radius: 100%;
            background: none;
            cursor: pointer;
        }
        .color-picker::-webkit-color-swatch {
            border-radius: 100%;
            border: none;
            padding: 0;
        }
    `]
})
export class MapBuilderStyleEditorComponent {

    mapBuilderService = inject(MapBuilderService);

    form = input<FormGroup>();
    selected = this.mapBuilderService.selected;
    isForm = computed(() => this.selected()?.type === 'form');


    showPoint = computed(() => {
        const selected = this.selected();
        if (selected.type === 'geojson') {
            return true;
        }
        return selected?.type === 'collection' ||
            (selected?.type === 'form' && selected.geometryTypes.includes('point'));
    });
    showPolygon = computed(() => {
        const selected = this.selected();
        if (selected.type === 'geojson') {
            return true;
        }
        return selected?.type === 'collection' ||
            (selected?.type === 'form' && selected.geometryTypes.includes('polygon'));
    });
    showLinestring = computed(() => {
        const selected = this.selected();
        if (selected.type === 'geojson') {
            return true;
        }
        return selected?.type === 'collection' ||
            (selected?.type === 'form' && selected.geometryTypes.includes('linestring'));
    });

    regenerateData() {
        this.mapBuilderService.generateData();
    }
}