import { Component, EventEmitter, HostListener, Output, computed, input, signal } from '@angular/core';
import { Collection } from './project-collections.component';
import { CommonModule } from '@angular/common';
import { SvgIconComponent } from 'angular-svg-icon';

@Component({
    selector: 'app-collections-list-item',
    templateUrl: 'collections-list-item.component.html',
    standalone: true,
    imports: [
        CommonModule,
        SvgIconComponent
    ]
})

export class CollectionsListItemComponent {

    item = input.required<Collection>();

    @Output() open = new EventEmitter<void>();
    @Output() delete = new EventEmitter<void>();

    @HostListener('click') onClick() {
        this.open.emit();
    }

    @HostListener('mouseenter') onMouseEnter() {
        this.hovering.set(true);
    }

    @HostListener('mouseleave') onMouseLeave() {
        this.hovering.set(false);
    }

    hovering = signal<boolean>(false);

    icon = computed(() => {
        const item = this.item();
        return `/assets/icons/${item.geometric ? 'location' : 'list'}.svg`;
    });

    deleteItem(event: Event) {
        event.preventDefault();
        event.stopPropagation();
        this.delete.emit();
    }
}