<div class="px-10 pt-10 max-w-7xl mx-auto space-y-4 flex flex-col h-full overflow-hidden">
    @if(isFreeTrialExpired) {
    <free-trial-expired />
    } @else if(isSubscriptionExpired || (isSubscriptionPastDue && isLocked)) {
    <subscription-expired [organisation]="currentOrganisation"/>
    } @else {
    @if(homeService.current(); as c){
    <div class="flex items-center justify-between">
        @if(search()){
        <h2 class="h-12 text-2xl font-semibold flex-1">Search Results</h2>
        } @else if(favourites()){
        <h2 class="h-12 text-2xl font-semibold flex-1">Favourites</h2>
        } @else {
        @if(c.path.length){
        <button class="cursor-pointer flex items-start h-full max-w-fit" (click)="up()">
            <i class="pi pi-angle-left text-[#878F97] hover:text-primary text-2xl mr-2"></i>
        </button>
        }
        <h2 class="h-12 text-2xl font-semibold flex flex-col flex-1 overflow-x-hidden">
            @if(search()){
            Search Results
            } @else if(favourites()){
            Favourites
            } @else {
            <span class="truncate">{{c.node.name ?? 'Projects'}}</span>
            @if(homeService.breadcrumbs().length > 1){
            <p-breadcrumb [model]="homeService.breadcrumbs()" (onItemClick)="breadcrumbClick($event)"/>
            }
            }
        </h2>
        }
        @if(isOrgAdmin()){
        <div class="flex flex-1 gap-4">
            <p-button size="large" (click)="newProject()">
                <svg-icon src="/assets/icons/plus.svg"></svg-icon>New Project
            </p-button>
            <p-button size="large" (click)="openProjectTemplates()">
                <svg-icon src="/assets/icons/template.svg"></svg-icon>Project From Template
            </p-button>
            <p-button size="large" [outlined]="true" (click)="openNewFolderModal()">
                <i class="fas fa-folder-plus"></i>New Folder
            </p-button>
        </div>
        }
    </div>

    <div class="flex relative basis-10 flex-shrink-0">
        <app-home-toolbar (move)="moveMultipleModal()" (favourite)="modifyFavourite($event)" />
    </div>


    @if(!homeService.empty()){
    <div class="home-sort-container" [ngClass]="{'reverse': homeService.sortReverse()}">
        <button [ngClass]="{'active': homeService.sortField() === 'name'}" (click)="homeService.sort('name')">
            Name<i class="fas fa-sort-amount-down"></i>
        </button>
    </div>
    }

    <div class="flex-grow overflow-auto no-scrollbar pb-4">

        <ul class="divide-y divide-[#D3DBE3] select-none">
            @for(f of homeService.currentFolders(); track f.id){
            <app-home-folder [folder]="f" (moveProjectToFolder)="moveProjectToFolder($event,f)"
                (moveFolderToFolder)="moveFolderToFolder($event,f)" (open)="openFolder(f)"
                (select)="selectFolder($event, f)" (delete)="deleteItem(f)" [selected]="isFolderSelected(f)"
                [selectionCount]="selectionCount()" (rename)="openRenameFolderModal(f)" (move)="moveFolderModal(f)"
                (favourite)="modifyFavourite([f])" [isOrgAdmin]="isOrgAdmin()"></app-home-folder>
            }
            @for(project of homeService.currentProjects(); track project.id){
            <app-home-project [project]="project" (open)="openProject(project)" [isOrgAdmin]="isOrgAdmin()"
                (select)="selectProject($event, project)" [selected]="isProjectSelected(project)"
                (move)="moveProjectModal(project)" (clone)="cloneProject(project)" [selectionCount]="selectionCount()"
                (settings)="openProject(project,'config')" (delete)="deleteItem(project)"
                (members)="openProject(project,'users')" (favourite)="modifyFavourite([project])"></app-home-project>
            }
        </ul>

        @if(homeService.empty() && !homeService.loading()){
        <app-home-empty (newProject)="newProject()" />
        }
    </div>
    }
    }
</div>


<p-toast position="bottom-center"></p-toast>
<app-home-item-remove [isVisibleRemoveDialog]="isVisibleRemoveDialog" [deletionItem]="deletionItem" (handlingDeletionChange)="onHandlingDeletionChange($event)"/>
