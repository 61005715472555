import { Component, Output, Input, EventEmitter, signal } from "@angular/core";
import { ButtonModule } from 'primeng/button';
import { DialogModule } from 'primeng/dialog';
import { DragDropModule } from "primeng/dragdrop";

@Component({
  templateUrl: './upload-organisation-image.component.html',
  selector: 'app-upload-organisation-image',
  standalone: true,
  imports: [
    ButtonModule,
    DialogModule,
    DragDropModule
  ]
})
export class UploadOrganisationImageComponent {

  @Input() isVisibleUploadImageDialog: boolean = false;
  @Output() visibleUploadImageChange = new EventEmitter<{ isVisibleUploadImageDialog: boolean, imageUrl: string | null, image: File }>();

  image = signal<File | null>(null);
  imageUrl = signal<string | null>(null);

  onDrop(event: any) {
      if (!event.dataTransfer.files || !event.dataTransfer.files.length) return;

      const file = event.dataTransfer.files[0];
      this.onFileSelected({ target: { files: [file] } });
  }

  onFileSelected(event: any) {
    const file: File = event.target.files[0];
    if (!file) return;

    const reader = new FileReader();
    this.image.set(file);

    reader.onload = (e: any) => {
      if (e.target.result) {
        this.imageUrl.set(e.target.result);
        this.onHideDialog();
      }
    }
    reader.readAsDataURL(file);
}

  onHideDialog() {
    this.isVisibleUploadImageDialog = false;
    this.visibleUploadImageChange.emit({ 
      isVisibleUploadImageDialog: this.isVisibleUploadImageDialog,
      imageUrl: this.imageUrl(),
      image: this.image()
    });
  }
}
