<p-toast position="bottom-center" [life]="4000"></p-toast>
<div class="h-full w-full p-5 flex flex-col overflow-y-auto no-scrollbar" [ngClass]="{'pb-16': !child()}">
    @if (loading() && !child()) {
        <div class="h-full w-full flex items-center justify-center">
            <svg-icon src="/assets/icons/spinner.svg" class="h-12 w-12"></svg-icon>
        </div>
    }
    @else if (empty()) {
        <div [ngClass]="{'border border-dashed border-[#D3DBE3] rounded p-5': child()}" class="empty-container relative w-full h-full" #empty>
            <div class="empty-placeholder absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 flex flex-col items-center">
                @if (!child()) {
                    <svg-icon class="text-primary-100 h-[152px] w-[152px]" src="/assets/icons/clipboard-list.svg"></svg-icon>
                    <span class="text-sm text-text-secondary">Drag your first question here</span>
                }
                @else {
                    <span class="text-xs text-text-primary">Drag & drop a question to build the child form</span>
                }
            </div>
        </div>
    }
    @else {
        <app-project-form-builder-section [formId]="formId()"></app-project-form-builder-section>
    }
</div>