import { Component, inject, Input } from "@angular/core";
import { ButtonModule } from "primeng/button";
import { OrganisationSettings } from "src/app/core/models/organisation.model";
import { SubscriptionService } from "src/app/core/services/subscription.service";

@Component({
    selector: 'subscription-expired',
    standalone: true,
    template: `
        <img class="h-20 mb-10" src="../../assets/images/logo.svg" />
        <div class="bg-white rounded-lg p-8 flex flex-col gap-4">
            <h1 class="font-extrabold text-2xl text-grey-100 leading-8">
                Your subscription has expired!
            </h1>
            <div class="text-grey-80">
                But don't worry. Your projects and data will be retained for a further {{ subscriptionService.daysUntilDelete() }} days. 
                To continue where you left off,
                @if (subscriptionService.isSubscriptionPastDue()) {
                    settle your outstanding payment via the billing portal.

                    <div class="flex flex-col justify-end w-full gap-4 pt-6">
                        <p-button type="button" size="large" [outlined]="true" class="w-full" styleClass="w-full" (click)="subscriptionService.createBillingSession()">
                            Manage Payment
                            <i class="fa-solid fa-arrow-up-right-from-square"></i>
                        </p-button>
                    </div>
                } @else {
                    upgrade your membership.
                }
            </div>
        </div>
    `,
    styles: [`
        :host { @apply w-[540px] mx-auto flex flex-col items-center justify-center; }
    `],
    imports: [
        ButtonModule
    ],
    providers: [
        SubscriptionService,
    ],
})

export class SubscriptionExpiredComponent {
    public subscriptionService = inject(SubscriptionService);
    @Input() organisation: OrganisationSettings;
    ngOnInit() {
        if (this.organisation?.id) {
            this.subscriptionService.init(this.organisation);
        }
    }
}