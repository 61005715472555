import { Component, EventEmitter, inject, Input, OnInit, Output } from '@angular/core';
import { CollectionDetailComponent } from './collection-detail.component';
import { CollectionService } from './collection.service';

@Component({
    selector: 'app-collection-detail-page',
    templateUrl: 'collection-detail-page.component.html',
    standalone: true,
    imports: [
        CollectionDetailComponent
    ],
    providers: [
        CollectionService
    ],
    styles: [`:host { @apply block h-full w-full relative; }`]
})

export class CollectionDetailPageComponent implements OnInit {

    collectionService = inject(CollectionService);

    @Input() projectId: number;
    @Input() collectionId: number;

    @Output() item: EventEmitter<number> = new EventEmitter();
    @Output() delete: EventEmitter<void> = new EventEmitter();

    ngOnInit() { 
        this.collectionService.projectId.set(this.projectId);
        this.collectionService.collectionId.set(this.collectionId);
        this.collectionService.location.set('page');
        this.collectionService.mode.set('edit');
    }
}