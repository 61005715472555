import angular from 'angular';
import { ROOT_FOLDER } from 'src/app/features/home/home.models';
import { getAuthCurrentOrganisation, getAuthOrganisationId, getAuthUserId, getOrganisationFreeTrialExpired, getOrganisationSubscriptionExpired, getOrganisationSubscriptionPastDue } from '../store/selectors';

export default angular
    .module('app.home', ['ui.router'])
    .config(['$stateProvider', function ($stateProvider) {

        $stateProvider
            .state('home', {
                parent: 'app',
                url: '/?id',
                template: `
                    <app-home 
                        [id]="ctrl.id"
                        [organisation-id]="ctrl.organisationId"
                        [user-id]="ctrl.userId"
                        [is-free-trial-expired]="ctrl.isFreeTrialExpired"
                        [is-subscription-expired]="ctrl.isSubscriptionExpired"
                        [is-subscription-past-due]="ctrl.isSubscriptionPastDue"
                        [is-locked]="ctrl.isLocked"
                        [current-organisation]="ctrl.currentOrganisation"
                        (folder)="ctrl.folder($event)" 
                        (project)="ctrl.project($event)" />
                `,
                controllerAs: 'ctrl',
                params: {
                    id: {
                        dynamic: true,
                    }
                },
                controller: class HomeController {

                    public id: string;
                    public organisationId: number;
                    public currentOrganisation: any;
                    public userId: number;
                    public isFreeTrialExpired: boolean;
                    public isSubscriptionExpired: boolean;
                    public isSubscriptionPastDue: boolean;

                    static $inject = ['$scope', '$state', '$stateParams', '$transitions', '$ngRedux'];
                    constructor(private $scope, private $state, private $stateParams, private $transitions, private $ngRedux) {
                    }

                    $onInit() {
                        this.id = this.$stateParams.id;
                        this.$scope.$on('$destroy', this.$transitions.onSuccess({ to: 'home' }, (trans) => {
                            const params = trans.paramsChanged();
                            this.id = params.id;
                        }));

      
                        this.$scope.$on('$destroy', this.$ngRedux.connect((state) => {
                            const now = new Date();
                            let isLocked = false;
                            const subscription = this.currentOrganisation?.subscription;
                            if (subscription?.periodEnd) {
                                const nextPeriodEnd = new Date(subscription.periodEnd);
                                const billingInterval = subscription.interval === "month" ? 30 : 365;
                                const previousPeriodEnd = new Date(nextPeriodEnd);
                                previousPeriodEnd.setDate(previousPeriodEnd.getDate() - billingInterval);
                        
                                const lockDate = new Date(previousPeriodEnd);
                                lockDate.setDate(lockDate.getDate() + 30);
                        
                                if (now >= lockDate) {
                                    isLocked = true;
                                }
                            }
                            return {
                                userId: getAuthUserId(state),
                                organisationId: getAuthOrganisationId(state),
                                currentOrganisation: getAuthCurrentOrganisation(state),
                                isFreeTrialExpired: getOrganisationFreeTrialExpired(state),
                                isSubscriptionExpired: getOrganisationSubscriptionExpired(state),
                                isSubscriptionPastDue: getOrganisationSubscriptionPastDue(state),
                                isLocked,
                            };
                        }, null)(this));
                    }

                    folder(id: string) {
                        this.$state.go('.', { id: id === ROOT_FOLDER ? null : id });
                    }

                    project(event: { project: any, state: string }) {
                        this.$state.go(event.state, {
                            orgSlug: this.currentOrganisation.slug,
                            id: event.project.slug
                        });
                    }
                }
            })
    }]);