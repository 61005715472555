<form class="w-full p-4 space-y-5">
    <div class="flex items-center justify-between gap-2">
        <label class="text-sm text-text-primary font-normal" for="wholeNumbersOnly">
            Use whole numbers only
        </label>
        <p-inputSwitch inputId="wholeNumbersOnly" name="wholeNumbersOnly" [ngModel]="wholeNumbersOnly()" (ngModelChange)="wholeNumbersOnlyChanged($event)" />
    </div>

    <div class="flex items-start justify-normal gap-5">
        <div class="flex flex-col gap-1">
            <label class="text-xs text-grey-70 font-normal" for="min">Minimum possible value</label>
            <p-inputNumber 
                inputId="min"
                name="min"
                [ngModel]="min()"
                (ngModelChange)="update.emit({config: { min: $event, max: max(), showButtons: showButtons() }})"
                placeholder="e.g. 0"
                styleClass="w-full" /> 
        </div>
        <div class="flex flex-col gap-1">
            <label class="text-xs text-grey-70 font-normal" for="max">Maximum possible value</label>
            <p-inputNumber 
                inputId="max" 
                name="max"
                [ngModel]="max()"
                (ngModelChange)="update.emit({config: { min: min(), max: $event, showButtons: showButtons() }})"
                placeholder="e.g. 10"
                styleClass="w-full" /> 
        </div>
    </div>

    @if (minMaxError()) {
        <small class="text-danger text-center">Minimum value must be less than maximum</small>
    }

    <!-- Hiding for now as hasn't been added in the app yet. Needs tooltip text? -->
    <!-- <div class="flex items-center gap-2">
        <label class="grow text-sm text-text-primary font-normal flex items-center justify-between" for="showButtons">
            Include +/- buttons?
            <i class="fa-solid fa-circle-info text-text-secondary" pTooltip="Whether to show increment and decrement buttons in the app" tooltipPosition="left"></i>
        </label>
        <p-inputSwitch inputId="showButtons" name="showButtons" [ngModel]="showButtons()" (ngModelChange)="update.emit({config: { min: min(), max: max(), showButtons: $event }})" />
    </div> -->
</form>