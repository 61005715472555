import { Component, effect, ElementRef, EventEmitter, inject, OnInit, Output, signal, viewChild, ViewContainerRef } from '@angular/core';
import { CollectionService } from '../collection.service';
import { environment } from 'src/environments/environment';
import { LngLatBoundsLike, Map as MapboxMap, MapOptions } from 'mapbox-gl';
import { Feature, Geometry } from 'geojson';
import { ApiService } from '@core/services';
import { catchError, of, take } from 'rxjs';
import { CollectionItem } from '../../collection.model';
import bbox from '@turf/bbox';
import { SvgIconComponent } from 'angular-svg-icon';

export type FeatureProperties = {
    id: number;
}

@Component({
    selector: 'app-collection-map',
    templateUrl: './collection-map.component.html',
    standalone: true,
    imports: [
        SvgIconComponent
    ]
})

export class CollectionMapComponent implements OnInit {

    apiService = inject(ApiService);
    collectionService = inject(CollectionService);
    viewContainerRef = inject(ViewContainerRef);

    @Output() item: EventEmitter<number> = new EventEmitter();

    collectionMap = viewChild<ElementRef>('collectionMap');
    
    projectId = this.collectionService.projectId;
    collectionId = this.collectionService.collectionId;
    mode = this.collectionService.mode;
    featureCollection = this.collectionService.mapFeatureCollection;
    collectionBounds = this.collectionService.mapCollectionBounds;
    loading = signal(true);
    
    private map: MapboxMap;
    private resizeObserver: ResizeObserver;

    constructor() {
        effect(() => {
            const featureCollection = this.featureCollection();
            const collectionBounds = this.collectionBounds();
            if (featureCollection && collectionBounds) {
                this.initMap();
            }
        })
    }

    ngOnInit() {
        if (this.mode() === 'create') {
            const items = this.collectionService.newCollectionItems();
            this.initFeatures(items);
        } else if (this.mode() === 'edit') {
            this.loadCollection();
        }
    }

    loadCollection() {
        const query = `query AACollectionMap($projectId: Int!, $collectionId: Int!){
            project(id: $projectId){
                collections(where: {id: $collectionId}){
                    items{
                        id
                        geometry{
                            type
                            coordinates
                        }
                    }
                    boundingBox{
                        coordinates
                    }
                }
            }
        }`;

        const input  = {
            projectId: this.projectId(),
            collectionId: this.collectionId()
        }

        this.apiService.graphql<{ project: { collections: { items: CollectionItem[]; boundingBox: any }[] } }>(query, input).pipe(
            take(1),
            catchError((e) => {
                console.error(e);
                return of(null);
            })
        ).subscribe(res => {
            if (!!res) {
                const  { items, boundingBox } = res.project.collections[0];
                const coordinates = boundingBox.coordinates.flat().map(i => parseFloat(i));
                this.collectionBounds.set(coordinates);
                this.initFeatures(items);
            }
        });
    }

    initFeatures(items: CollectionItem[]) {
        const features: Feature<Geometry, FeatureProperties>[] = items.map(item => {
            const { id, geometry } = item;
            return {
                type: 'Feature',
                properties: {
                    id,
                },
                geometry
            }
        });

        this.featureCollection.set({
            type: 'FeatureCollection',
            features
        });

        if (!this.collectionBounds()) {
            const bounds = bbox(this.featureCollection());
            this.collectionBounds.set(bounds as LngLatBoundsLike);
        }
    }

    initMap () {
        const mapOptions: MapOptions = {
            accessToken: environment.mapboxApiKey,
            container: this.collectionMap().nativeElement,
            style: 'mapbox://styles/mapbox/streets-v12',
            projection: { name: 'mercator' },
            bounds: this.collectionBounds()
        };

        this.map = new MapboxMap(mapOptions);

        this.map.on('style.load', () => {
            this.map.addSource('collection', {
                type: 'geojson',
                data: this.featureCollection(),
                generateId: true
            });
            
            this.map.addLayer({
                id: 'polygon-fill',
                source: 'collection',
                type: 'fill',
                paint: {
                    'fill-color': '#ff0000',
                    'fill-opacity': [
                        'case',
                        ['boolean', ['feature-state', 'hover'], false],
                        0.6,
                        0.3
                    ]
                },
                'filter': ['==', '$type', 'Polygon']
            });

            this.map.addLayer({
                id: 'polygon-outline',
                source: 'collection',
                type: 'line',
                paint: {
                    'line-color': '#FFFFFF',
                    'line-width': 1
                },
                'filter': ['==', '$type', 'Polygon']
            });

            this.map.addLayer({
                id: 'line',
                source: 'collection',
                type: 'line',
                paint: {
                    'line-color': '#F02F1D',
                    'line-width': [
                        'case',
                        ['boolean', ['feature-state', 'hover'], false],
                        5,
                        3
                    ]
                },
                'filter': ['==', '$type', 'LineString']
            });

            this.map.addLayer({
                id: 'point',
                source: 'collection',
                type: 'circle',
                filter: [
                    'all',
                    ['!has', 'pc'],
                    ['==', '$type', 'Point']
                ],
                paint: {
                    'circle-color': '#C521E0',
                    'circle-radius': [
                        'case',
                        ['boolean', ['feature-state', 'hover'], false],
                        12,
                        10
                    ],
                    'circle-opacity': [
                        'case',
                        ['boolean', ['feature-state', 'hover'], false],
                        0.8,
                        0.5
                    ],
                    'circle-stroke-width': 1,
                    'circle-stroke-color': '#FFFFFF'
                }
            });

            const layerIds = ['polygon-fill', 'polygon-outline', 'line', 'point'];
            let featureId = null;

            for (const layerId of layerIds) {
                this.map.on('mousemove', layerId, (event) => {
                    this.map.getCanvas().style.cursor = 'pointer';
          
                    if (event.features.length === 0) return;
                    
                    if (featureId !== null) {
                        this.map.removeFeatureState({
                            source: 'collection',
                            id: featureId
                        });
                    }
          
                    featureId = event.features[0].id;
          
                    this.map.setFeatureState(
                        {
                            source: 'collection',
                            id: featureId
                        },
                        {
                            hover: true
                        }
                    );
                });
          
                this.map.on('mouseleave', layerId, () => {
                    if (featureId !== null) {
                        this.map.setFeatureState(
                            {
                                source: 'collection',
                                id: featureId
                            },
                            {
                                hover: false
                            }
                        );
                    }
                    featureId = null;
                    this.map.getCanvas().style.cursor = '';
                });

                
                
                this.map.on('click', layerId, (event) => {
                    if (event.features.length === 0) return;
                    const item = event.features[0].properties['id'];
                    this.item.emit(item);
                });
            }

            this.resizeObserver = new ResizeObserver(() => this.map.resize());
            this.resizeObserver.observe(this.collectionMap().nativeElement);

            this.loading.set(false);
        });
    }
}