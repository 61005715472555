<p-confirmDialog styleClass="hide-close"></p-confirmDialog>
<p-toast position="bottom-center" [life]="2000"></p-toast>

@if (item()) {
<div class="bg-background">
    <!-- Header -->
    <div class="flex-none w-full flex items-center justify-between gap-5 sticky top-0 z-10 bg-background" [ngClass]="{'p-5': location() === 'modal', 'px-10 py-8': location() === 'page'}">
        <div class="min-w-32 flex items-center gap-2">
            <button (click)="goBackCheck($event)">
                <i class="fa-solid fa-chevron-left text-xl text-text-secondary"></i>
            </button>
            <h2 class="text-3xl font-semibold text-nowrap truncate">{{ item().value }}</h2>
        </div>
        <div class="flex items-center justify-end gap-4">
            <!-- Delete item -->
            <p-button [disabled]="loading()" (click)="deleteItemCheck($event)" size="large" [text]="true"
                severity="danger" label="Delete item">
                <i class="fa-solid fa-trash-can"></i>
            </p-button>
            <!-- Show preview -->
            <p-button [disabled]="loading() || !!valueError()" (click)="preview() ? hidePreview() : showPreview()" size="large" [outlined]="true" [label]="preview() ? 'Hide preview' : 'Show preview'">
                <i class="fa-solid fa-eye"></i>
            </p-button>
            <!-- Save -->
            <p-button [disabled]="!canSave()" (click)="saveItem()" size="large" [label]="mode() === 'edit' ? 'Save changes' : 'Done'">
                @if (mode() === 'edit') {
                    <i class="fa-solid fa-floppy-disk"></i>
                }
            </p-button>
        </div>
    </div>
    <!-- Main -->
    <div class="h-full w-full grid grid-rows-1 grid-cols-12 gap-5 min-h-0 bg-background" [ngClass]="{'px-5': location() === 'modal', 'px-10': location() === 'page'}">
        <!-- Item details -->
        <div class="h-full" [ngClass]="{'col-span-8': preview(), 'col-span-full': !preview()}">
            <div class="w-full flex flex-col max-w-7xl pb-10 gap-8 mx-auto bg-background">
                <!-- Detail -->
                <p-panel class="p-0">
                    <ng-template pTemplate="header">
                        <div class="flex items-center">
                            <p class="p-panel-title">Detail of collection item</p>
                        </div>
                        <p class="font-semibold">Key: {{ item().key }}</p>
                    </ng-template>
                    <!-- Value -->
                    @if (valueForm()) {
                    <form [formGroup]="valueForm()" class="p-5">
                        <div class="flex items-start gap-4">
                            <label for="value" class="text-sm font-semibold w-1/5 min-w-44 pt-3">Name (Value) *</label>
                            <div class="w-full">
                                <input pInputText type="text" formControlName="value" id="value" class="w-full" />
                                @if (valueError()) {
                                <p class="text-xs text-danger leading-none pt-4">{{ valueError() }}</p>
                                }
                            </div>
                        </div>
                    </form>
                    }
                    <!-- Attributes/Data -->
                    @if (attributeForm()) {
                    <div class="h-10 px-4 flex items-center bg-primary-100 border-t border-b border-primary-300">
                        <p class="text-xs text-text-primary font-semibold">Attributes</p>
                    </div>
                    <form [formGroup]="attributeForm()" class="p-5 space-y-5">
                        @for (question of attributeQuestions(); track question) {
                        <div class="flex items-start gap-4">
                            <label [for]="question.path" class="text-sm font-semibold w-1/5 min-w-44 pt-2 truncate">{{ question.label
                                }}</label>
                            @if (question.type === 'text') {
                            <textarea [autoResize]="true" pInputTextarea [formControlName]="question.path" [id]="question.path"
                                class="w-full">
                            </textarea>
                            }
                            @else if (question.type === 'float') {
                            <p-inputNumber [minFractionDigits]="0" [formControlName]="question.path" [inputId]="question.path"
                                styleClass="w-full" class="w-full"></p-inputNumber>
                            }
                        </div>
                        }
                    </form>
                    }
                </p-panel>
                <!-- Media -->
                <p-panel class="">
                    <ng-template pTemplate="header">
                        <div class="flex items-center">
                            <p class="p-panel-title">Media</p>
                        </div>
                        <p-button [outlined]="true" label="Upload media file" (click)="addMedia($event)"
                            [disabled]="mediaLoading()">
                            <svg-icon src="/assets/icons/plus.svg"></svg-icon>
                        </p-button>
                        <input #mediaInput type="file" accept="capture=image/jpeg, image/png, image/jpg, audio/*"
                            (change)="selectMediaHandler()" class="sr-only opacity-0" />
                    </ng-template>
                    @if (mediaItems().length) {
                        <p class="text-center text-xs font-semibold">The first image will be shown with item value as a choice in the Coreo app. Click and drag to reorder.</p>
                
                        <app-collection-item-media-list [items]="mediaItems()"></app-collection-item-media-list>
                    }
                    @else {
                        <div class="flex flex-col items-center gap-4 p-10 pb-12">
                            <svg-icon src="/assets/icons/file-image.svg" class="text-primary-100 w-10 h-auto"></svg-icon>
                            <p class="text-text-primary text-center font-semibold">No media yet.</p>
                            <p class="w-full max-w-[360px] text-text-secondary text-sm text-center">Upload any images or sound files you would like to see attached to this collection item in the app. Click "Show preview" to see how they look.</p>
                        </div>
                    }
                </p-panel>
                <!-- Geometry -->
                @if (showMap()) {
                    <p-panel class="p-0">
                        <ng-template pTemplate="header">
                            <div class="flex items-center">
                                <p class="p-panel-title">Geometry</p>
                            </div>
                            @if (!geometry()) {
                                <p-button [outlined]="true" label="Cancel" (click)="addGeometry.set(false)"></p-button>
                            }
                        </ng-template>
                        <div class="relative w-full h-80">
                            <app-collection-item-map></app-collection-item-map>
                        </div>
                    </p-panel>
                }
                @else {
                    <div class="w-full p-4 rounded-lg bg-white shadow-panel flex items-center justify-between">
                        <p class="text-lg font-semibold text-text-primary">Geometry</p>
                        <p-button [outlined]="true" label="Add geometry" (click)="addGeometry.set(true)">
                            <svg-icon src="/assets/icons/plus.svg"></svg-icon>
                        </p-button>
                    </div>
                }
                <!-- Data driven styling -->
                @if(ddsEnabled){
                <p-panel class="" [header]="'Map geometry customisation'">
                    <p class="text-sm text-text-primary mb-5">
                        This section is for styling how records associated with this collection item are shown on the map. Once you have added custom pins or colours to each collection item if you want then to be visible on your form maps you can do this in the
                        @if (location() === 'page') {
                            <a (click)="mapb.emit()" class="font-semibold underline text-primary cursor-pointer">map builder</a> 
                        }
                        @else {
                            map builder
                        }
                         section. In the map builder, to active your styling, select a form and in the Style Attribute dropdown, select the form's question associated with this collection.</p>
        
                    <div class="h-full w-full grid grid-cols-1 lg:grid-cols-3 gap-5">
                        <div class="h-full w-full rounded-md border border-grey-border overflow-hidden flex flex-col">
                            <div class="relative grow h-56 lg:h-full w-full map-wrapper bg-grey-40">
                                <div class="absolute inset-0" #geoCustomMap></div>
                            </div>
                            <p class="text-sm text-text-primary font-semibold text-center p-[10px]">Example of how pins & geometry will&nbsp;look</p>
                        </div>
                        <div class="h-full w-full rounded-md border border-grey-border overflow-hidden flex flex-col p-5">
                            <div class="grow flex flex-col gap-4 text-text-primary pb-5">
                                <h2 class="text-sm font-semibold">Custom map pin icon</h2>
                                <p class="text-sm">Customise how this item's pin will be shown when recorded on the map using an image or icon.</p>
                                <p class="text-[10px]">For best results please keep image size between 90 and 250px, with a solid coloured background.</p>
                            </div>
                            <div class="flex-none flex items-center gap-4">
                                <p-button type="button" [outlined]="true" size="large" (click)="showUploadCustomPin()">
                                    @if (isNoGeoPin()) {
                                        <svg-icon src="/assets/icons/plus.svg"></svg-icon>
                                    }
                                    {{isNoGeoPin() ? 'Upload' : 'Change'}} map pin
                                </p-button>
                                @if(!isNoGeoPin()){
                                <i class="fa-solid fa-trash-can text-xl text-danger cursor-pointer" (click)="iconChange(null)"></i>
                                }
                            </div>
                        </div>
                        <div class="h-full w-full rounded-md border border-grey-border overflow-hidden flex flex-col gap-4 p-5">
                            <div class="grow flex flex-col gap-4 text-text-primary pb-5">
                                <h2 class="text-sm font-semibold">Custom pin & geometry colour</h2>
                                <p class="text-sm">Choose a colour for this item's geometry (point, polygons and linestrings) when shown on the map.</p>
                                <p class="text-[10px]">If not selected it will be set as the default form colour. This can be edited in the map builder.</p>
                            </div>
                            @if(isNoGeoColor()){
                            <p-button type="button" [outlined]="true" size="large" (click)="setDefaultColor()">
                                <svg-icon src="/assets/icons/plus.svg"></svg-icon>
                                Pick custom colour
                            </p-button>
                            } @else {
                            <div class="flex-none flex items-center gap-4 max-h-11">
                                <app-color-picker [color]="this.item().color" (colorChange)="colorChange($event)"/>
                                <i class="fa-solid fa-trash-can text-xl text-danger cursor-pointer" (click)="colorChange(null)"></i>
                            </div>
                            }
                        </div>
                    </div>
                </p-panel>
                }
            </div>
        </div>
        <!-- Preview -->
        @if (preview()) {
        <div class="col-span-4 pb-10">
            <app-collection-item-preview class="sticky" [ngClass]="{'top-[108px]': location() === 'page', 'top-[84px]': location() === 'modal'}"></app-collection-item-preview>
        </div>
        }
    </div>
</div>
}
@else if (loading()) {
    <div class="absolute inset-0 bg-white bg-opacity-70 flex items-center justify-center">
        <svg-icon src="/assets/icons/spinner.svg" class="h-10 w-10"></svg-icon>
    </div>
}