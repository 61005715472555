import { Component, computed, inject } from '@angular/core';
import { ProjectFormService } from './project-form.service';
import { Attribute, AttributeConditionRule } from './project-form.model';
import { DropdownModule } from 'primeng/dropdown';
import { FormsModule } from '@angular/forms';
import { ProjectFormConditionRuleComponent } from './project-form-condition-rule.component';
import { ButtonModule } from 'primeng/button';

@Component({
    selector: 'app-project-form-conditions',
    templateUrl: './project-form-conditions.component.html',
    standalone: true,
    imports: [
        FormsModule,
        DropdownModule,
        ButtonModule,
        ProjectFormConditionRuleComponent
    ],
    styles: [`:host {@apply block w-full text-text-primary space-y-5;}`]
})
export class ProjectFormConditionsComponent {

    form = inject(ProjectFormService);

    attribute = this.form.selectedAttribute;
    attributes = this.form.attributes;

    anyOptions = [{name: 'all', value: false}, {name: 'any', value: true}];

    /** This is a bit of a hack - if we use the actual rules to loop over and display then the component re-renders each time one changes ie for every keyboard input and the input looses focus */
    dummyRulesArray = computed(() => Array.from(Array(this.attribute().conditions.rules.length).keys()));

    conditions = computed(() => this.attribute().conditions);

    any = computed(() => this.attribute().conditions.any);

    rules = computed(() => this.attribute().conditions.rules);

    update(obj: Partial<Attribute>) {
        this.form.updateSelectedAttribute(obj);
    }

    anyChanged(event: boolean) {
        if (this.any() === event) {
            return;
        }
        
        this.update({
            conditions: {
                any: event,
                rules: this.rules()
            }
        });
    }

    addRule() {
        this.update({
            conditions: {
                ...this.conditions(),
                rules: [
                    ...this.rules(),
                    { path: null }
                ]
            },
        });
    }

    removeRule(index: number) {
        const newRules = [
            ...this.rules().slice(0, index),
            ...this.rules().slice(index + 1)
        ];
        this.update({
            conditions: {
                ...this.conditions(),
                rules: newRules
            }
        });
    }

    updateRule(rule: AttributeConditionRule, index: number) {
        const rules = this.rules();
        rules.splice(index, 1, rule);
        this.update({
            conditions: {
                ...this.conditions(),
                rules
            }
        });
    }
}