import { Component, computed, EventEmitter, inject, Output } from '@angular/core';
import { ProjectFormService } from '../project-form.service';
import { Attribute } from '../project-form.model';
import { FormsModule } from '@angular/forms';
import { DropdownModule } from 'primeng/dropdown';
import { RGEO_FIELDS, RGEO_LOCALISATIONS } from './rgeo.constants';
import { NgClass } from '@angular/common';

type ReverseGeolocationConfig = {
    field: string; // "formattedAddress";
    localisation: string; // "en-GB"
}

@Component({
    selector: 'app-reverse-geolocation-settings',
    templateUrl: 'reverse-geolocation-settings.component.html',
    standalone: true,
    imports: [
        NgClass,
        FormsModule,
        DropdownModule
    ]
})

export class ReverseGeolocationSettingsComponent {

    form = inject(ProjectFormService);
    
    attribute = this.form.selectedAttribute;
    
    @Output() update: EventEmitter<Partial<Attribute>> = new EventEmitter();
    
    config = computed(() => {
        const attribute = this.attribute();
        const config = attribute.config as ReverseGeolocationConfig;
        return {
            field: config.field ?? null, // "formattedAddress";
            localisation: config.localisation ?? null // "en-GB"
        }
    });

    field = computed(() => this.config().field);
    localisation = computed(() => this.config().localisation);

    fieldOptions = RGEO_FIELDS;
    localisationOptions = RGEO_LOCALISATIONS;

    configChanged(obj: Partial<ReverseGeolocationConfig>) {
        this.update.emit({
            config: {
                ...this.config(),
                ...obj
            }
        });
    }
}
