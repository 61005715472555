import { ChangeDetectionStrategy, Component, computed, inject, input } from "@angular/core";
import { find } from "lodash";
import { AttributeConditionRule, UNARY_OPERATORS } from "./project-form.model";
import { ProjectFormService } from "./project-form.service";

@Component({
    selector: 'app-project-form-conditions-summary-rule',
    templateUrl: './project-form-conditions-summary-rule.component.html',
    standalone: true,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProjectFormConditionsSummaryRuleComponent {

    form = inject(ProjectFormService);

    rule = input.required<AttributeConditionRule>();

    attributes = this.form.attributes;
    collections = this.form.collections;

    path = computed(() => this.rule().path);
    /** Current rule attribute */
    attribute = computed(() => this.attributes().find(a => a.path === this.path()));
    attributeType = computed(() => this.attribute()?.type);
    label = computed(() => this.attribute()?.label);
    collectionItems = computed(() => {
        const attribute = this.attribute();
        const collections = this.collections();

        if (!attribute?.collectionId) {
            return [];
        }
        const items = collections.find(c => c.id === attribute.collectionId).items;
        if (items) {
            return items;
        }
    });

    ngOnInit() {
        if (typeof this.collectionItems() === 'undefined') {
            this.form.loadCollectionItems(this.attribute().collectionId);
        }
    }

    summary = computed(() => {
        const type = this.attributeType();
        const rule = this.rule();
        const label = this.label();
        const items = this.collectionItems();

        if (!type || !rule || !label) {
            return '';
        }

        switch (type) {
            case 'integer':
            case 'float': {
                if (rule.numberComparand === null) {
                    return null;
                }
                const conditions = {
                    eq: 'is equal to',
                    ne: 'is not equal to',
                    lt: 'is less than',
                    lte: 'is less than or equal to',
                    gt: 'is greater than',
                    gte: 'is greater than or equal to',
                    answered: 'has been answered',
                    unanswered: 'has not been answered'
                };
                return label + ' ' + conditions[rule.numberCondition] + (!this.isUnaryOperator(rule.numberCondition) ? ' ' + rule.numberComparand : '');
            }
            case 'text': {
                if (rule.textComparand === null) {
                    return null;
                }
                const conditions = {
                    is: 'is',
                    not: 'is not',
                    contains: 'contains',
                    notcontains: 'does not contain',
                    matches: 'matches regex',
                    notmatches: 'does not match regex',
                    answered: 'has been answered',
                    unanswered: 'has not been answered'
                };
                return label + ' ' + conditions[rule.textCondition] + (!this.isUnaryOperator(rule.textCondition) ? ' "' + rule.textComparand + '"' : '');
            }
            case 'date': {
                if (rule.dateComparand === null) {
                    return null;
                }
                const conditions = {
                    is: 'is',
                    not: 'is not',
                    before: 'before',
                    after: 'after',
                    answered: 'has been answered',
                    unanswered: 'has not been answered'
                };
                const date = new Date(rule.dateComparand);
                return label + ' ' + conditions[rule.dateCondition] + (!this.isUnaryOperator(rule.dateCondition) ? ' ' + date.toDateString() : '');
            }
            case 'datetime': {
                if (rule.datetimeComparand === null) {
                    return null;
                }
                const conditions = {
                    before: 'is before',
                    after: 'is after',
                    beforetime: 'is before',
                    aftertime: 'is after'
                };
                const date = new Date(rule.datetimeComparand);
                if (rule.datetimeCondition === 'before' || rule.datetimeCondition === 'after') {
                    return label + ' ' + conditions[rule.datetimeCondition] + (!this.isUnaryOperator(rule.datetimeCondition) ? ' ' + date.toDateString() : '');
                } else {
                    return label + ' ' + conditions[rule.datetimeCondition] + (!this.isUnaryOperator(rule.datetimeCondition) ? ' ' + date.toTimeString() : '');
                }
            }
            case 'multiselect':
            case 'select': {
                if (rule.selectComparand === null) {
                    return null;
                }
                let conditions = {};
                if (type === 'multiselect') {
                    conditions = {
                        contains: 'contains',
                        notcontains: 'does not contain',
                        answered: 'has been answered',
                        unanswered: 'has not been answered'
                    };
                } else {
                    conditions = {
                        is: 'is',
                        not: 'is not',
                        answered: 'has been answered',
                        unanswered: 'has not been answered'
                    };
                }
                const item = find(items, { key: rule.selectComparand });
                const value = item ? ('"' + item.value + '"') : rule.selectComparand;
                return label + ' ' + conditions[rule.selectCondition] + (!this.isUnaryOperator(rule.selectCondition) ? ' ' + value : '');
            }
            case 'boolean': {
                return label + ' was answered with ' + (rule.isTrue ? '"Yes"' : '"No"');
            }
        }
    });

    isUnaryOperator(operand: string) {
        return UNARY_OPERATORS.includes(operand);
    }

}
