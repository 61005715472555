import { Component, computed, EventEmitter, inject, Output } from '@angular/core';
import { ProjectFormService } from '../project-form.service';
import { InputTextModule } from 'primeng/inputtext';
import { FormsModule } from '@angular/forms';
import { InputSwitchModule } from 'primeng/inputswitch';
import { TooltipModule } from 'primeng/tooltip';
import { Attribute } from '../project-form.model';
import { SelectButtonModule } from 'primeng/selectbutton';

type TextShortConfig = {
    autocomplete: boolean;
    format: string;
}
type TextLongConfig = {
    format: string;
}

type TextQuestionType = 'short' | 'long';

@Component({
    selector: 'app-text-settings',
    templateUrl: './text-settings.component.html',
    standalone: true,
    imports: [
        FormsModule,
        SelectButtonModule,
        InputSwitchModule,
        InputTextModule,
        TooltipModule
    ]
})

export class TextSettingsComponent {

    form = inject(ProjectFormService);

    attribute = this.form.selectedAttribute;

    @Output() update: EventEmitter<Partial<Attribute>> = new EventEmitter();

    questionTypeOptions: {label: string; value: TextQuestionType}[] = [{ label: 'Single line', value: 'short' }, { label: 'Multiple line', value: 'long' }];

    questionType = computed(() => {
        const attribute = this.attribute();
        return attribute.questionType as TextQuestionType;
    });

    config = computed(() => {
        const attribute = this.attribute();
        const questionType = this.questionType();
        if (questionType === 'short') {
            const config = attribute.config as TextShortConfig;
            return {
                autocomplete: config.autocomplete ?? false,
                format: config.format ?? null
            };
        } else {
            const config = attribute.config as TextLongConfig;
            return {
                format: config.format ?? null
            }
        }
    });

    autocomplete = computed(() => this.config().autocomplete);
    format = computed(() => this.config().format);

    showFormat = computed(() => {
        const format = this.format();

        return format !== null;
    });

    questionTypeChanged($event: TextQuestionType) {
        console.log('questionTypeChanged', $event);
        if ($event === 'short') {
            this.update.emit({ questionType: $event, config: this.config() });
        } else {
            this.update.emit({ questionType: $event, config: { format: this.format() } });
        }
    }

    showFormatChanged(show: boolean) {
        const autocomplete = this.autocomplete();
        if (!show) {
            this.update.emit({config: { autocomplete, format: null }});
        } else {
            this.update.emit({config: { autocomplete, format: '' }});
        }
    }

    formatChanged(format: string) {
        if (this.questionType() === 'short') {
            this.update.emit({ config: { autocomplete: this.autocomplete(), format } });
        } else {
            this.update.emit({ config: { format } });
        }
    }
}