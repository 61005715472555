import { Component, Input, Output, EventEmitter, signal, computed, inject, WritableSignal } from "@angular/core";
import { OrganisationSettings } from "src/app/core/models/organisation.model";
import { ApiService } from "src/app/core/services/api.service";
import { UpdatePlanComponent } from "src/app/features/organisations/organisation-subscription/update-plan.component";
import { RemoveSeatsFilledComponent } from "src/app/features/organisations/organisation-subscription/remove-seats-filled.component";
import { ButtonModule } from 'primeng/button';
import { SubscriptionService } from "src/app/core/services/subscription.service";

@Component({
    selector: 'subscription-banner',
    standalone: true,
    templateUrl: './subscription-banner.component.html',
    imports: [
        ButtonModule,
        UpdatePlanComponent,
        RemoveSeatsFilledComponent
    ],
    providers: [
        SubscriptionService,
    ],
})
export class SubscriptionBannerComponent {
    apiService = inject(ApiService);
    subscriptionService = inject(SubscriptionService);

    _userId: WritableSignal<number> = signal(null);
    _baseUrl: WritableSignal<string> = signal('');
    _seatsFilled: WritableSignal<number> = signal(null);

    @Input() set userId(userId: number) { this._userId.set(userId) }
    @Input() set baseUrl(baseUrl: string) { this._baseUrl.set(baseUrl) }
    @Input() set organisation(organisation: OrganisationSettings) {
        if (organisation?.id) {
            this.subscriptionService.init(organisation);
        }
    }

    @Output() removeMembers: EventEmitter<any> = new EventEmitter();
    @Output() cancelInvite: EventEmitter<any> = new EventEmitter();

    isShowBanner = computed<boolean>(() =>
        this.subscriptionService.isFreeTrial() ||
        (!this.subscriptionService._organisation()?.subscription && 
        !this.subscriptionService.isFreeTrial() && !!this.subscriptionService._organisation()?.renewalDate) ||
        this.subscriptionService.isSubscriptionExpired() ||
        this.subscriptionService.isSubscriptionPastDue()
    );

    isVisibleUpdatePlanDialog = signal<boolean>(false);
    isVisibleRemoveSeatsDialog = signal<boolean>(false);

    seatsSelected = signal<number>(0);

    ngOnInit() {
        if (this.subscriptionService.isNewPaymentFeatureAvailable()) {
            this.subscriptionService.loadMemberships();
        }
    }

    constructor() {
        this.subscriptionService.cancelInvite.subscribe((pendingEmail: string) => {
            this.cancelInvite.emit(pendingEmail);
        });
    }

    onVisibleUpdatePlan(event: { isVisibleUpdatePlanDialog: boolean, seatsSelected: number }) {
        const { isVisibleUpdatePlanDialog, seatsSelected } = event;
        this.isVisibleUpdatePlanDialog.set(isVisibleUpdatePlanDialog);
        if (seatsSelected) {
            this.seatsSelected.set(seatsSelected);
            this.isVisibleRemoveSeatsDialog.set(true);
        }
    }

    onVisibleRemoveSeats(event: { isVisibleRemoveSeatsDialog: boolean, userIds: number[], isBack: boolean }) {
        const { userIds, isVisibleRemoveSeatsDialog, isBack } = event;
        this.isVisibleRemoveSeatsDialog.set(isVisibleRemoveSeatsDialog);
        this.seatsSelected.set(0);
        if (isBack) {
            this.isVisibleUpdatePlanDialog.set(true);
        }
        if (userIds.length) {
            const updatedMemberships = this.subscriptionService._memberships().filter(member => !userIds.includes(member.userId));
            this.subscriptionService.updateMemberships(updatedMemberships);
            this.removeMembers.emit(userIds);
            this.isVisibleUpdatePlanDialog.set(true);
        }
    }
}