import { Component, computed, EventEmitter, inject, Output } from '@angular/core';
import { ProjectFormService } from '../project-form.service';
import { FormsModule } from '@angular/forms';
import { InputSwitchModule } from 'primeng/inputswitch';
import { TooltipModule } from 'primeng/tooltip';
import { Attribute } from '../project-form.model';
import { InputNumberModule } from 'primeng/inputnumber';

type NumberConfig = {
    min: string;
    max: string;
    showButtons: boolean;
}

@Component({
    selector: 'app-number-settings',
    templateUrl: 'number-settings.component.html',
    standalone: true,
    imports: [
        FormsModule,
        InputSwitchModule,
        InputNumberModule,
        TooltipModule
    ]
})

export class NumberSettingsComponent {

    form = inject(ProjectFormService);

    attribute = this.form.selectedAttribute;

    @Output() update: EventEmitter<Partial<Attribute>> = new EventEmitter();

    config = computed(() => {
        const attribute = this.attribute();
        const config = attribute.config as NumberConfig;
        return {
            min: config.min ?? null,
            max: config.max ?? null,
            showButtons: config.showButtons ?? false
        };
    });

    type = computed(() => this.attribute().type);
    wholeNumbersOnly = computed(() => {
        const type = this.type();
        return type === 'integer';
    });

    min = computed(() => this.config().min);
    max = computed(() => this.config().max);
    showButtons = computed(() => this.config().showButtons);
    minMaxError = computed(() => {
        const min = this.min();
        const max = this.max();
        if (
            min !== undefined &&
            min !== null &&
            max !== undefined &&
            max !== null
        ) {
            if (min > max) {
                return true;
            } else {
                return false;
            }
        } else {
            return false;
        }
    });

    wholeNumbersOnlyChanged(wholeNumbers: boolean) {
        const type = wholeNumbers ? 'integer' : 'float';
        this.update.emit({ type });
    }
}