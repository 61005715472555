import { Component, computed, EventEmitter, inject, OnInit, Output, signal } from '@angular/core';
import { ProjectFormService } from '../project-form.service';
import { Attribute } from '../project-form.model';
import { ApiService } from 'src/app/core';
import { catchError, of, take } from 'rxjs';
import { FormsModule } from '@angular/forms';
import { DropdownModule } from 'primeng/dropdown';
import { TooltipModule } from 'primeng/tooltip';

type FormOption = {
    id: number;
    name:string;
}

@Component({
    selector: 'app-association-settings',
    templateUrl: 'association-settings.component.html',
    standalone: true,
    imports: [
        FormsModule,
        DropdownModule,
        TooltipModule
    ]
})

export class AssociationSettingsComponent implements OnInit {

    form = inject(ProjectFormService);
    apiService = inject(ApiService);

    @Output() update: EventEmitter<Partial<Attribute>> = new EventEmitter();
    
    projectId = this.form.projectId;
    formId = this.form.formId;
    attribute = this.form.selectedAttribute;

    formOptions = signal<FormOption[]>([]);

    associatedSurveyId = computed(() => this.attribute().associatedSurveyId);
    canChooseForm = computed(() => this.attribute().id < 0);
    linkedFormName = computed(() => {
        const associatedSurveyId = this.associatedSurveyId();
        const forms = this.formOptions();
            
        const form = forms.find(f => f.id === associatedSurveyId);
        return form ? form.name : '';
    });

    ngOnInit(): void {
        this.getProjectForms();
    }

    getProjectForms() {
        const query = `query CoreoAAGetForms($projectId: Int!, $formId: Int!){
            project(id: $projectId){
                forms: surveys(where:{id: {not: $formId}}){
                    id
                    name
                }
            }
        }`;

        this.apiService.graphql<{ project: { forms: FormOption[] } }>(query, { projectId: this.projectId(), formId: this.formId() }).pipe(
            take(1),
            catchError((e) => {
                console.error(e);
                return of(null)
            })
        ).subscribe(res => {
            this.formOptions.set(res.project.forms);
        });
    }
}