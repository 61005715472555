import angular from 'angular';
import ProjectTemplatesActions from "../store/project-templates/project-templates.actions";
import { getAuthUser, getAuthIsAdmin, getOrganisation } from '../store/selectors';
import { OrganisationFreeTrialBannerComponent } from './free-trial-banner/free-trial-banner.component';
import OrganisationInvitationController from './invitation/organisation-invitation.controller';
import OrganisationMembersController from './members/organisation-members.controller';
import OrganisationController from './organisation.controller.js';
import OrganisationSettingsController from './settings/organisation-settings.controller';

import { ORGANISATION_ADMIN, ORGANISATION_OWNER } from '../permissions/permissions.constants';

export default angular
    .module('app.organisations', ['ngSanitize', 'ui.router'])
    .service('ProjectTemplatesActions', ProjectTemplatesActions)
    .controller('OrganisationController', OrganisationController)
    .controller('OrganisationSettingsController', OrganisationSettingsController)
    .controller('OrganisationMembersController', OrganisationMembersController)
    .controller('OrganisationInvitationController', OrganisationInvitationController)
    .component('organisationFreeTrialBanner', OrganisationFreeTrialBannerComponent)
    .config(['$stateProvider', function ($stateProvider) {
        $stateProvider
            .state('organisation-admin', {
                abstract: true,
                template: require('!raw-loader!../layouts/app.layout.html').default,
                controller: 'AppLayoutController as ctrl',
                resolve: {
                    users: ['$ngRedux', 'OrganisationsActions', function ($ngRedux, OrganisationsActions) {
                        return $ngRedux.dispatch(OrganisationsActions.loadOrganisationMemberships());
                    }],
                    organisation: ['$ngRedux', function ($ngRedux) {
                        return getOrganisation($ngRedux.getState());
                    }]
                }
            })
            .state('organisation-admin.members', {
                url: '/organisation-members?name&username&role&email&state',
                template: require('!raw-loader!./members/organisation-members.html').default,
                controller: 'OrganisationMembersController as ctrl',
                params: {
                    email: {
                        value: null,
                        dynamic: true
                    },
                    name: {
                        value: null,
                        dynamic: true
                    },
                    username: {
                        value: null,
                        dynamic: true
                    },
                    role: {
                        value: null,
                        dynamic: true
                    },
                    state: {
                        value: null,
                        dynamic: true
                    }
                },
                resolve: {
                    projects: ['CoreoAPI', 'organisation', (CoreoAPI, organisation) => {
                        const query = `query( $orgId: Int! ) {
                            organisation(id: $orgId) {
                                projects{
                                    id
                                    name
                                    hasEmptySeats
                                }
                            }
                        }`
                        return CoreoAPI.gql(query, { orgId: organisation.id }).then(result => {
                            return result.organisation.projects;
                        });
                    }]
                },
                data: {
                    breadcrumb: {
                        label: 'Organisation Members',
                        parent: 'organisation-admin'
                    },
                    permissions: [
                        ORGANISATION_ADMIN
                    ]
                }
            })
            .state('organisation-admin.settings', {
                url: '/organisation-settings',
                template: `<app-organisation-settings
                [organisation]="ctrl.organisation"
                [user-id]="ctrl.userId"
                [is-super-admin]="ctrl.isSuperAdmin"
                [base-url]="ctrl.baseUrl"
                (update)="ctrl.update($event)"
                (update-subscription-data)="ctrl.updateSubscriptionData($event)"
                (remove-members)="ctrl.removeMembers($event)"
                (cancel-invite)="ctrl.cancelInvite($event)" />`,
                // template: require('!raw-loader!./settings/organisation-settings.html').default,
                controllerAs: 'ctrl',
                controller: class OrganisationSettingsController {
                    static $inject = ['$state', '$scope', '$ngRedux', 'OrganisationsActions', 'hostname', 'protocol', 'port'];
                    constructor(public $state, public $scope, public $ngRedux, public OrganisationsActions, hostname, protocol, port) {

                        $scope.$on('$destroy', $ngRedux.connect(state => {
                            let cbPort = '';
                            if (port && port !== '80' && port !== '443') {
                                cbPort = ':' + port;
                            }
                            const baseUrl = [protocol, '://', hostname, cbPort].join('');
                            const user = getAuthUser(state);
                            return {
                                baseUrl,
                                userId: user ? user.id : null,
                                isSuperAdmin: getAuthIsAdmin(state),
                                organisation: getOrganisation(state),
                            }
                        })(this));
                    }
                    update(event) {
                        const { name, image } = event;
                        this.$ngRedux.dispatch(this.OrganisationsActions.updateOrganisation(name, image));
                    }
                    updateSubscriptionData(event) {
                        this.$ngRedux.dispatch(this.OrganisationsActions.updateOrganisationSubscription(event));
                    }
                    removeMembers(ids) {
                        this.$ngRedux.dispatch(this.OrganisationsActions.removeOrganisationMembership(ids));
                    }
                    cancelInvite(pendingEmail) {
                        this.$ngRedux.dispatch(this.OrganisationsActions.cancelPendingOrganisationInvite(pendingEmail));
                    }
                },
                // controller: 'OrganisationSettingsController',
                data: {
                    breadcrumb: {
                        label: 'Organisation Settings',
                        parent: 'organisation-admin'
                    },
                    permissions: [
                        ORGANISATION_OWNER
                    ]
                }
            })
            .state('organisation-invitation', {
                url: '/invitations/org/:token',
                parent: 'unauthenticated',
                template: require('!raw-loader!./invitation/organisation-invitation.html').default,
                controller: 'OrganisationInvitationController as ctrl',
            });
    }]);