<div class="text-text-primary text-sm">
    @if (mediaType() === 'audio') {
        <audio [controls]="true" class="w-full mb-5">
            <source [src]="src()" [type]="item().type">
            Your browser does not support this audio tag
        </audio>
    }
    <h3 class="text-lg font-semibold mb-2">{{ item().name }}</h3>
    <p class="mb-4">{{ item().type }} / {{ item().size }}kb</p>
    @if (item().createdAt) {
        <p class="mb-4">File uploaded on <span class="font-semibold">{{ item().createdAt | date:'mediumDate' }}</span></p>
    }
    <p>Add a caption/description (this will be shown in app if you tap an image)</p>
    <p class="text-[10px] text-text-primary text-right">{{ captionCharacterCount() }} / 255</p>
    <form [formGroup]="form()">
        <textarea 
            rows="1"
            pInputTextarea
            formControlName="caption"
            id="caption"
            class="w-full"
            [maxlength]="255"
            [autoResize]="true"
            placeholder="Add a caption" >
        </textarea>
    </form>
    <div class="flex items-center justify-end pt-5">
        <p-button size="large" label="Save" (click)="save()"></p-button>
    </div>
</div>