<div class="h-1/2 flex gap-5 overflow-hidden">
    <div class="panel h-full relative grow">
        <div class="panel-header">
            <h2>Enter javascript code</h2>
        </div>
        <ngx-monaco-editor style="height: calc(100% - 64px); width: 100%; background: #1e1e1e" [ngModel]="attribute.config.expression"
            [options]="editorOptions" (onInit)="onEditor($event)" />
        @if (validationError || runtimeError) {
        <p class="absolute bottom-5 left-5 w-[calc(100% - 40px)] text-danger bg-white rounded px-3 py-2">
            {{validationError || runtimeError}}
        </p>
        }
    </div>
    <div class="panel h-full w-[310px]">
        <div class="panel-header">
            <h2>Available fields to use</h2>
        </div>
        <ul class="p-5 h-full flex flex-col gap-5 overflow-y-auto">
            @for (field of fields; track $index) {
            <li class="flex justify-between flex-wrap border-b border-[#D3DBE3] text-[#444] text-base">
                {{field.name}}
                @if(field.type) {
                <span class="text-[#878F97] text-sm select-none">{{field.type}}</span>
                }
            </li>
            }
        </ul>
    </div>
</div>
<div class="h-1/2 flex flex-col gap-5 overflow-hidden">
    <div class="grow h-full flex gap-5 overflow-hidden">
        <div class="panel h-full min-w-[418px]">
            <div class="panel-header">
                <h2>Enter test values</h2>
            </div>
            <ul class="p-5 h-full w-full flex flex-col gap-5 overflow-y-auto">
                @for (testField of testFields; track $index) {
                    <li class="flex flex-col gap-2 flex-none">
                        <label>{{testField.name}}</label>
                        @switch (testField.type) {
                            @case ('number') {
                            <input type="number" pInputText [(ngModel)]="testField.value" 
                                (ngModelChange)="updateTestFieldValue(testField)" required/>
                            }
                            @case ('boolean') {
                            <p-checkbox [(ngModel)]="testField.value" [binary]="true" 
                                (ngModelChange)="updateTestFieldValue(testField)"/>
                            }
                            @case ('Item') {
                            <p-dropdown appendTo="body" optionLabel="value" optionValue="key" [options]="collectionsMap()[testField.collectionId].items || []" 
                                [(ngModel)]="testField.value" (ngModelChange)="updateTestFieldValue(testField)"/>          
                            }
                            @case ('Item[]') {
                            <p-multiSelect styleClass="w-full" appendTo="body" optionLabel="value" optionValue="key" [options]="collectionsMap()[testField.collectionId].items || []" 
                                [(ngModel)]="testField.value" (ngModelChange)="updateTestFieldValue(testField)"/>
                            }
                            @default {
                            <input type="text" pInputText [(ngModel)]="testField.value" 
                                (ngModelChange)="updateTestFieldValue(testField)" required/>
                            }
                        }
                    </li>
                } @empty {
                <div class="h-full w-full flex justify-center items-center p-5">
                    <p class="text-[#878F97] text-xs text-center">No values to enter</p>
                </div>
                }
            </ul>
        </div>
        <div class="panel h-full w-full">
            <div class="panel-header">
                <h2>Test your calculation</h2>
                <p-button size="large" [disabled]="itemDataLoading || validationError || runtimeError || !editor?.getValue()" (click)="execute()">
                    @if (itemDataLoading) {
                    <i class="spinner"></i>
                    } @else {
                    Test Expression
                    }
                </p-button>
            </div>
            <div class="p-5 h-full">
                @if(output) {
                <textarea class="w-full h-full border-none resize-none overflow-y-auto" type="text" pInputTextarea disabled [ngModel]="output"></textarea>
                } @else {
                <div class="h-full w-full flex justify-center items-center p-5">
                    <p class="text-[#878F97] text-xs text-center">
                        Once you have written your expression above, click the “Test Expression” button to make sure your code works
                    </p>
                </div>
                }
            </div>
        </div>
    </div>
    <!-- <div class="shrink h-11 flex items-center justify-end"> -->
        <p-button size="large" label="Save" [disabled]="!editor?.getValue()" (click)="save()" class="ml-auto" />
    <!-- </div> -->
</div>