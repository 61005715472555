import { Component, computed, inject, OnDestroy, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { CheckboxModule } from 'primeng/checkbox';
import { DropdownModule } from 'primeng/dropdown';
import { MultiSelectModule } from 'primeng/multiselect';
import { DynamicDialogRef, DynamicDialogConfig } from 'primeng/dynamicdialog';
import { ButtonModule } from 'primeng/button';
import { ApiService } from 'src/app/core';
import { MonacoEditorModule } from 'ngx-monaco-editor-v2';
import { firstValueFrom } from 'rxjs';
import es6Def from 'src/es6.js';
import { ProjectFormService } from '../project-form.service';
import { FormCollectionItem } from '../project-form.model';

const TEST_FIELDS_BLACKLIST = ['$username', '$displayName'];
const ATTRIBUTE_AND_COLLECTION_TYPE_MAPPINGS = {
    text: 'string',
    date: 'string',
    datetime: 'string',
    email: 'string',
    float: 'number',
    integer: 'number',
    url: 'string',
    boolean: 'boolean',
    select: 'Item',
    multiselect: 'Item[]',
}

@Component({
    selector: 'app-calculated-settings-modal',
    templateUrl: 'calculated-settings-modal.component.html',
    standalone: true,
    imports: [
        FormsModule,
        InputTextModule,
        InputTextareaModule,
        CheckboxModule,
        DropdownModule,
        MultiSelectModule,
        ButtonModule,
        MonacoEditorModule
    ],
    styles: [`
        :host { @apply h-full flex flex-col gap-5; }
        .panel { @apply flex flex-col rounded-lg border border-[#D3DBE3] m-0 overflow-hidden; }
        .panel-header { @apply min-h-16 h-16 flex justify-between items-center text-lg text-[#444] font-semibold border-b border-[#D3DBE3] px-5; }
    `]
})

export class CalculatedSettingsModalComponent implements OnInit, OnDestroy {

    apiService = inject(ApiService);
    dialogRef = inject(DynamicDialogRef);
    dialogConfig = inject(DynamicDialogConfig);
    form = inject(ProjectFormService);

    expressionEvaluator: any;

    user = this.dialogConfig['data']['user'];
    projectId = this.dialogConfig['data']['projectId'];
    attribute = this.dialogConfig['data']['attribute'];
    attributes = this.dialogConfig['data']['attributes'];

    collections = this.form.collections;

    collectionsMap = computed(() => {
        const collections = this.collections();
        
        const map = collections.reduce((m, c) => {
            m[c.id] = c;
            return m;
        }, {});
        
        return map;
    });

    editorOptions = {
        theme: 'vs-dark',
        language: 'typescript',
        minimap: { enabled: false },
        wordWrap: "on",
        renderLineHighlight: 'none',
        automaticLayout: true
    };
    
    editor = null;
    output = null;
    loading = true;
    libSource: string;
    fields: {name: string, type: string, collectionId: number | null}[] = [];
    testFields: {name: string, type: string, collectionId: number | null, value: any}[] = [];
    fieldNamesMap: {[key: string]: string}[] = [];
    es5Expression = null;
    runtimeError = null;
    validationError = null;
    itemDataLoading: boolean = false;
    babel = require('@babel/standalone');

    ngOnInit(): void {
        // TODO: collectionId is undefined when returned from a multiselect, but when CoreoExpressionEvaluator is directly imported for testing it's returned correctly
        import('@natural-apptitude/coreo-expressions/dist/esm').then(({ CoreoExpressionEvaluator }) => {
        // import('../../../../../../../expressions').then(({ CoreoExpressionEvaluator }) => {
            
            const itemResolver = async (collectionId: number, key: string) => {
                return this.collectionsMap()[collectionId]?.items.find((i: FormCollectionItem) => i.key === key) || null;
            }
    
            this.expressionEvaluator = new CoreoExpressionEvaluator(
                [...this.attributes],
                itemResolver,
                this.user
            );
    
            this.fieldNamesMap = this.expressionEvaluator.getRecordFieldNamesMap();
            
            const expression = this.attribute?.config?.expression;
            if (expression) {
                this.attribute.config.expression = this.expressionEvaluator.deserialiseExpression(expression);
            }
    
            this.buildCustomLibAndFieldNames();
            this.setTestFieldValues(this.attribute.config.expression);
        });
    }

    ngOnDestroy(): void {
        const monaco = (window as any).monaco;
        if (monaco) {
            monaco.editor.getModels().forEach(model => model.dispose());
        }
    }
    
    buildCustomLibAndFieldNames() {
        const fieldNames = this.expressionEvaluator.getAvailableFieldNames();
        let libSource = `
            interface Item {
                key: string;
                value: string;
                data: any;
            }
        `;
    
        this.fields = fieldNames.map(name => {    
            if (this.fieldNamesMap[name] === this.attribute.uuid) return;
    
            const attribute = this.attributes.find(({ uuid }) => uuid === this.fieldNamesMap[name]);
    
            const type = attribute ? this.getAttributeType(attribute) : 'string';
    
            libSource += `declare const ${name}: ${type}; `;
    
            return {
                name,
                type,
                collectionId: attribute?.collectionId
            };
        }).filter(Boolean);
    
        this.libSource = libSource;
    }
    
    getAttributeType(attribute) {
        let type = ATTRIBUTE_AND_COLLECTION_TYPE_MAPPINGS[attribute.type];
        if (!type && attribute.questionType === 'expression') {
            const configType = attribute.config.type;
            type = configType === 'float' ? 'number' : configType || 'string';
        }
        return type || 'string';
    }
    
    setTestFieldValues(text) {
        if (!text) return;

        this.fields
            .filter(field => !TEST_FIELDS_BLACKLIST.includes(field.name))
            .forEach(async (field) => {
                if (text.includes(field.name) && !this.testFields.some(({ name }) => name === field.name)) {
                    // If text includes the field and the field hasn't already been added
                    // added it
                    // If collectionId check and load the collection items
                    if (field.collectionId && !this.collectionsMap()[field.collectionId]?.items) {
                        const { items, data } = await firstValueFrom(this.form.getCollectionItems(field.collectionId));
                        this.form.setCollectionItems(field.collectionId, items, data);
                    }
                    this.testFields.push({ name: field.name, value: null, type: field.type, collectionId: field.collectionId });
                } else if (!text.includes(field.name) && this.testFields.some(({ name }) => name === field.name)) {
                    // If text doesn't include the field and the field has already been added
                    // remove it
                    this.testFields = this.testFields.filter(({ name }) => name !== field.name);
                }
            });
    }

    async updateTestFieldValue(field) {
        const ogIndex = this.testFields.findIndex(({ name }) => field.name === name);

        this.testFields[ogIndex] = {
            ...field,
            value: field.type === 'number' ? Number(field.value) : field.value
        };

        // TODO: This doesn't add anything now as we're using collections from the form service - have removed and have had no issues so far but keeping in just in case - Remove after testing
        // const testField = this.testFields[ogIndex];
        // const val = testField.value;
        // if (testField.collectionId && val) {
        //     this.itemDataLoading = true;
        //     console.log('HERE', testField, val);
            
        //     // const items = await firstValueFrom(this.getCollectionItemsByKeys(testField.collectionId, val, !!Array.isArray(val)));
        //     // const collectionIdx = this.collections().findIndex(c => c.id === testField.collectionId);
        //     // const collection = cloneDeep(this.collections[collectionIdx]);
        //     // for (const item of items) {
        //     //     const oldItem = collection.items.find(i => i.id === item.id);
        //     //     oldItem.data = item.data;
        //     // }
        //     // this.collections = [
        //     //     ...this.collections.slice(0, collectionIdx),
        //     //     collection,
        //     //     ...this.collections.slice(collectionIdx + 1)
        //     // ]
        //     this.itemDataLoading = false;
        // }
    }

    // TODO: this is redundant if not using the above can remove - should use collections from the form service and not get via api - Remove after testing
    // getCollectionItemsByKeys(collectionId: number, keys: string | string[], isMultipleKeys: boolean): Observable<any[]> {
    //     const query = `query CoreoAAGetProjectCollections{
    //         project(id: ${this.projectId}){
    //             collections(where:{id: ${collectionId}}){
    //                 items(where:{key: ${isMultipleKeys ? `{ in: ${JSON.stringify(keys)}}` : JSON.stringify(keys)}}){
    //                     id,
    //                     key,
    //                     value,
    //                     data,
    //                     geometry{
    //                         type,
    //                         coordinates
    //                     }
    //                 }
    //             }
    //         }
    //     }`;
    
    //     return this.apiService.graphql<{ project: { collections: Collection[] } }>(query).pipe(
    //         take(1),
    //         catchError((e) => {
    //             console.error(e);
    //             return of([]);
    //         }),
    //         map((res: { project: { collections: Collection[] } }) => {
    //             const { project: { collections } } = res;
    //             if (!collections || !collections.length) return [];
    //             return collections[0].items;
    //         })
    //     );
    // }

    save() {
        const attributeIds: number[] = [];

        for (const field of this.testFields) {
            const attribute = this.attributes.find(({ uuid }) => uuid === this.fieldNamesMap[field.name]);
            if (attribute) {
                attributeIds.push(attribute.id);
            }
        }

        this.es5Expression = this.transpileExpression(this.editor.getValue());
        const serialisedExpression = this.expressionEvaluator.serialiseExpression(this.editor.getValue());
        this.dialogRef.close({
            config: { 
                es5Expression: this.es5Expression,
                expression: serialisedExpression
            },
            attributeIds
        });
    }

    transpileExpression(expression, ast = false) {
        const serialiseExpression = this.expressionEvaluator.serialiseExpression(`(()=>{${expression}})()`);

        return this.babel.transform(serialiseExpression, { presets: ['env'], ast }).code;
    }

    evaluateExpression() {
        this.output = null;
        this.runtimeError = null;
        let recordData = {};
        this.testFields.forEach(({ name, value }) => recordData[this.attributes.find(a => a.uuid === this.fieldNamesMap[name]).path] = value);
        this.expressionEvaluator.setRecord({ data: recordData });
        this.expressionEvaluator.setExpressionAttribute({ ...this.attribute, config: { ...this.attribute.config, es5Expression: this.es5Expression } });
        this.expressionEvaluator.evaluateExpression().then((output) => this.output = output).catch(e => this.runtimeError = e.message);
    }

    execute() {
        // console.log('Executng');
        this.es5Expression = this.transpileExpression(this.editor.getValue());
        this.evaluateExpression();
    }

    onEditor(editor) {
        this.editor = editor;
        // console.log('EDITOR!', editor);
        const monaco = (window as any).monaco;

        // Define custom es2015 lib
        monaco.languages.typescript.typescriptDefaults.addExtraLib(es6Def, 'es6.d.ts');

        // Define new lib with custom variables and types
        const libUri = 'ts:filename/fields.d.ts';
        monaco.languages.typescript.typescriptDefaults.addExtraLib(this.libSource, libUri);
        monaco.editor.createModel(this.libSource, 'typescript', monaco.Uri.parse(libUri));

        // Setup watcher to track errors
        monaco.editor.onDidChangeMarkers(() => {
            const modelMarkers = monaco.editor.getModelMarkers();
            const errors = modelMarkers.filter(marker => marker.severity === 8);
            this.validationError = errors.length > 0 ? errors[0].message : null;
        });

        // Setup watcher to track editor content change and update field variables
        this.editor.onDidChangeModelContent(() => this.setTestFieldValues(this.editor.getValue()));

        this.loading = false;
    }
}