import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';
import { Question } from './form.types';
import { AsyncPipe, CommonModule } from '@angular/common';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { SelectButtonModule } from 'primeng/selectbutton';
import { CheckboxModule } from 'primeng/checkbox';
import { ListboxModule } from 'primeng/listbox';
import { DropdownModule } from 'primeng/dropdown';
import { MultiSelectModule } from 'primeng/multiselect';
import { ButtonModule } from "primeng/button";
import { TooltipModule } from 'primeng/tooltip';
import { SignaturePadComponent } from './signature-pad/signature-pad.component';
import { DialogService, DynamicDialogModule, DynamicDialogRef } from 'primeng/dynamicdialog';
import { RecordLookupModalComponent } from './record-lookup/record-lookup-modal.component';
import { GeometryPickerComponent } from './geometry-picker/geometry-picker.component';
import { RecordAttachment } from '../records.service';
import { SearchableListComponent } from './searchable-list/searchable-list.component';
import { NumericAnswerComponent } from './numeric-answer/numeric-answer.component';
import { MultipleChoiceComponent } from './multiple-choice/multiple-choice.component';
import { YesNoComponent } from './yes-no/yes-no.component';
import { DatePickerComponent } from './date-picker/date-picker.component';
import { RecordButtonRemoveComponent } from './buttons/record-button-remove.component';
import { RecordButtonAddComponent } from './buttons/record-button-add.component';
import { TextAnswerComponent } from './text-answer/text-answer.component';
import { FormImageComponent } from './form-image/form-image.component';
import { ChildFormModalComponent } from './child-form/child-form-modal.component';
import { FormTextBlockComponent } from './text-block/form-text-block.component';
import { FormAudioComponent } from './form-audio/form-audio.component';

@Component({
    selector: 'app-form-question',
    templateUrl: 'form-question.component.html',
    imports: [
        CommonModule,
        ReactiveFormsModule,
        AsyncPipe,
        InputTextModule,
        InputTextareaModule,
        SelectButtonModule,
        CheckboxModule,
        ListboxModule,
        DropdownModule,
        MultiSelectModule,
        ButtonModule,
        SignaturePadComponent,
        DynamicDialogModule,
        TooltipModule,
        SearchableListComponent,
        GeometryPickerComponent,
        NumericAnswerComponent,
        MultipleChoiceComponent,
        YesNoComponent,
        DatePickerComponent,
        RecordButtonAddComponent,
        RecordButtonRemoveComponent,
        TextAnswerComponent,
        FormImageComponent,
        FormTextBlockComponent,
        FormAudioComponent
    ],
    providers: [
        DialogService
    ],
    standalone: true
})

export class FormQuestionComponent implements OnInit, OnDestroy {

    @Input() question!: Question;
    @Input() form!: FormGroup;
    @Input() isEdit!: boolean;

    @ViewChild('fileInput', { static: false }) fileInput: ElementRef;

    public isSlider: boolean = false;
    public isExpression: boolean = false;
    public yesNoOptions: any[] = [{label: 'Yes', value: true}, {label: 'No', value: false}];
    
    private dialogRef: DynamicDialogRef | undefined;

    constructor(
        private dialogService: DialogService
    ) {}

    ngOnInit(): void {
        // console.log(this.question.type, this.question.questionType, this.question);
        this.isExpression = this.question.questionType === 'expression';
        this.isSlider = this.question.questionType === 'slider';
    }

    ngOnDestroy() {
        if (this.dialogRef) {
            this.dialogRef.close();
        }
    }

    get value() {
        return this.form.controls[this.question.path].value;
    }

    get isValid() {
        return this.form.controls[this.question.path].valid;
    }

    get error() {
        if (this.form.controls[this.question.path].status === 'DISABLED') {
            /** For hidden conditional questions */
            return null;
        }
        if (!this.isValid) {
            const errors = this.form.controls[this.question.path].errors;
            if (errors['required']) {
                return 'This question is required'
            }
            if (errors['pattern'] && this.question.type === 'url') {
                return 'Value must be a valid url';
            }
            if (errors['pattern']) {
                return 'Text must match the required pattern';
            }
            if (errors['minlength']) {
                const min = errors['minlength'].requiredLength;
                return `This field requires a minimum of ${min} entries`;
            }
            if (errors['email']) {
                return 'Value must be a valid email address';
            }
        } else {
            return null;
        }
    }

    showAddImageButton(): boolean {
        if (this.question.questionType === 'photo' && this.question.type === 'media') {
            if (this.value.length < 1) {
                return true;
            }
        } else if (this.question.questionType === 'mediaGallery' && this.question.type === 'attachment') {
            if (!!this.question.config.max) {
                return this.value.length < this.question.config.max;
            } else {
                return true;
            }
        } else {
            return false;
        }
    }

    showAddAudioButton(): boolean {
        if (this.question.questionType === 'audio' && this.question.type === 'media') {
            if (this.value.length < 1) {
                return true;
            }
        } else {
            return false;
        }
    }

    public addFile($event: Event) {
        $event.preventDefault();
        this.fileInput.nativeElement.click();
    }

    public selectFileHandler() {
        const files = this.fileInput.nativeElement.files;
        this.handleFiles(files!);
    }

    private handleFiles(fileList: FileList) {
        if (fileList.length > 0) {
            // add check for file size? + error message https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input/file
            const file: File = fileList[0];
            const url =  URL.createObjectURL(file);
            const data: RecordAttachment = {
                id: null,
                projectId: this.question.projectId,
                attributeId: this.question.id,
                file: file,
                url: url,
                mimeType: file.type,
            }

            this.addAttachment(this.question.path, data);
        }
    }

    public async handleSignature(dataUrl: string) {
        const res = await fetch(dataUrl);
        const blob = await res.blob();
        const data: RecordAttachment = {
            id: null,
            projectId: this.question.projectId,
            attributeId: this.question.id,
            file: blob,
            url: dataUrl,
            mimeType: 'image/png',
        }

        this.addAttachment(this.question.path, data);
    }

    public openRecordLookup() {
        this.dialogRef = this.dialogService.open(RecordLookupModalComponent, {
            data: {
                surveyId: this.question.associatedSurveyId,
                projectId: this.question.projectId
            },
            header: this.question.label,
            width: '75vw',
            height: '75vh',
            modal: true,
            breakpoints: {
                '960px': '75vw',
                '640px': '90vw'
            },
            contentStyle: { 'padding': '0px' }
        });

        this.dialogRef.onClose.subscribe((associateId: number) => {
            if (associateId) {
                this.addAssociate(this.question.path, associateId);
            } 
        });
    }

    public addChildRecord() {
        this.dialogRef = this.dialogService.open(ChildFormModalComponent, {
            data: {
                projectId: this.question.projectId,
                surveyId: this.question.associatedSurveyId,
                attributeId: this.question.id,
            },
            header: this.question.label,
            width: '420px',
            height: '75vh',
            modal: true,
            contentStyle: { 'padding': '0px' }
        });

        this.dialogRef.onClose.subscribe(data => {
            if (!!data) {
                this.addAssociate(this.question.path, data);
            }
        });
    }

    public removeAttachment(path: string, attachment: RecordAttachment) {
        const values: RecordAttachment[] = this.form.controls[path].value;        
        const newValues = values.filter(v => v.url !== attachment.url);
        
        this.form.controls[path].setValue(newValues);
        this.form.markAsDirty();
    }

    public addAttachment(path: string, attachment: RecordAttachment) {
        const values = this.form.controls[path].value;
        const newValues = [...values, attachment];

        this.form.controls[path].setValue(newValues);
        this.form.markAsDirty();
    }

    public removeAssociate(path: string, associateId: number) {
        const values: number[] = this.form.controls[path].value;        
        const newValues = values.filter(v => v !== associateId);
        
        this.form.controls[path].setValue(newValues);
        this.form.markAsDirty();
    }

    public addAssociate(path: string, associateId: number) {
        const values: number[] = this.form.controls[path].value;
        const newValues = [...values, associateId];

        this.form.controls[path].setValue(newValues);
        this.form.markAsDirty();
    }
}