import { computed, EventEmitter, inject, Injectable, signal, WritableSignal } from "@angular/core";
import { ApiService } from "./api.service";
import {
    OrganisationSettings,
    OrganisationTierSchema,
} from "../models/organisation.model";
import { OrganisationTier } from "src/app/core/models/organisation.model";
import { z } from "zod";
import { formatDistanceToNowStrict } from "date-fns";
import { MessageService } from "primeng/api";

const OrganisationMember = z.object({
    role: z.string(),
    pendingEmail: z.string().nullable().optional(),
    user: z
        .object({
            id: z.number(),
            displayName: z.string(),
            email: z.string().nullable(),
            username: z.string().nullable(),
        })
        .nullable(),
});

const OrganisationTiers = z.array(OrganisationTierSchema);
const OrganisationMembers = z.array(OrganisationMember);

const OrganisationTiersQueryResult = z.object({
    organisationTiers: OrganisationTiers,
});

const OrganisationQueryResult = z.object({
    memberships: OrganisationMembers,
});

const QueryResult = z.object({
    organisation: OrganisationQueryResult,
});

export type OrganisationMembership  = {
    role: string;
    userId: number;
    username: string;
    email: string;
};

export type OrganisationMembershipItem = OrganisationMembership & {
    selected: boolean;
    name: string;
};

export type PendingOrganisationMembershipItem = Partial<OrganisationMembershipItem> & {
    pendingEmail: string;
};

@Injectable()
export class SubscriptionService {
    private apiService = inject(ApiService);
    private messageService = inject(MessageService);

    public _organisation: WritableSignal<OrganisationSettings> = signal(null);
    public _tiers = signal<OrganisationTier[]>([]);
    public _memberships = signal<OrganisationMembershipItem[]>([]);
    public _pendingMemberships = signal<PendingOrganisationMembershipItem[]>([]);
    public _seatsFilled: WritableSignal<number> = signal(null);

    public removeMembers = new EventEmitter<string>();
    public cancelInvite = new EventEmitter<string>();

    formatDistanceToNowStrict = formatDistanceToNowStrict;

    init(organisation: OrganisationSettings): void {
        this._organisation.set(organisation);
        if (this.isNewPaymentFeatureAvailable()) {
            this.loadTiers();
        }
    }

    loadTiers() {
        this.apiService
            .graphql<z.infer<typeof OrganisationTiersQueryResult>>(
                `query AAGetOrganisationTiers {
            organisationTiers {
                id, name, monthlyPrice, yearlyPrice, monthlyPriceId, yearlyPriceId, productId,
                addons { name, productId, monthlyPriceId, yearlyPriceId, monthlyPrice, yearlyPrice }
            }
        }`
            )
            .subscribe((t) => {
                const result = OrganisationTiersQueryResult.parse(t);
                if (result && result.organisationTiers) {
                    this._tiers.set(result.organisationTiers);
                }
            });
    }

    loadMemberships() {
        const input = { id: this._organisation()?.id };
        this.apiService
            .graphql<z.infer<typeof QueryResult>>(
                `query AAGetOrganisationMemberships($id: Int!) {
            organisation(id: $id) { 
                memberships {
                    userId, role, pendingEmail
                    user { id, displayName, username, email }
                } 
            }
        }`,
                input
            )
            .subscribe((t) => {
                const result = QueryResult.parse(t);
                const members = result.organisation.memberships;
                this._seatsFilled.set(members.length);
                const memberships = members
                    .filter((a) => a.user !== null)
                    .map((member) => ({
                        selected: false,
                        role: member.role,
                        name: member.user?.displayName ?? "",
                        userId: member.user?.id ?? 0,
                        email: member.user?.email,
                        username: member.user?.username,
                    }))
                    .sort((a, b) => a.name.localeCompare(b.name));

                const pendingMemberships = members
                    .filter(member => member.user === null && member.pendingEmail !== null)
                    .map(member => ({
                        selected: false,
                        role: member.role,
                        pendingEmail: member.pendingEmail,
                    }))
                    .sort((a, b) => a.pendingEmail.localeCompare(b.pendingEmail));

                this._pendingMemberships.set(pendingMemberships);
                this._memberships.set(memberships);
            });
    }

    updateMemberships(memberships: OrganisationMembershipItem[]) {
        this._memberships.set(memberships);
        this._seatsFilled.set(memberships.length + this._pendingMemberships().length);
    }

    currentSubscriptionInterval = computed<'month' | 'year'>(() => this.isSubscription() ? this._organisation()?.subscription.interval : 'month');

    isOwner = computed<boolean>(() => this._organisation()?.role === "owner");

    isFreeTrialActive = computed<boolean>(() =>
        this._organisation()?.freeTrialEnd && !this._organisation()?.freeTrialExpired
    );

    isFreeTrialExpired = computed<boolean>(() =>
        this._organisation()?.freeTrialEnd && this._organisation()?.freeTrialExpired

    );

    isFreeTrial = computed<boolean>(() =>
        this.isFreeTrialActive() || this.isFreeTrialExpired()
    );

    isSubscriptionActive = computed<boolean>(() =>
        !this.isFreeTrialActive() && !this.isFreeTrialExpired() &&
        this._organisation()?.subscription && !this._organisation()?.subscription.isExpired
    );

    isSubscriptionExpired = computed<boolean>(() =>
        !this.isFreeTrialActive() && !this.isFreeTrialExpired() &&
        this._organisation()?.subscription && this._organisation()?.subscription.isExpired
    );

    isSubscriptionPastDue = computed<boolean>(() =>
        !this.isFreeTrialActive() && !this.isFreeTrialExpired() &&
        this._organisation()?.subscription && this._organisation()?.subscription.isPastDue
    );

    isSubscriptionCancelled = computed<boolean>(() =>
        this.isSubscriptionActive() && this._organisation()?.subscription &&
        this._organisation()?.subscription.isCancelled
    );

    isSubscription = computed<boolean>(() => this.isSubscriptionActive() || this.isSubscriptionExpired());

    // NEEDS TO BE REMOVED LATER
    isNewPaymentFeatureAvailable = computed<boolean>(() => Boolean(this._organisation()?.renewalDate));
    // isNewPaymentFeatureAvailable = computed<boolean>(() => true);

    currentTier = computed<OrganisationTier | null>(
        () =>
            this._tiers().find(
                (tier) => tier.id === this._organisation()?.tier.id
            ) || null
    );

    currentTierId = computed<number | null>(
        () => this.currentTier()?.id || null
    );

    currentInterval = computed<"month" | "year">(() =>
        this.isSubscription()
            ? this._organisation()?.subscription.interval
            : "month"
    );

    freeTrialMessage = computed<string>(() => {
        return this.isFreeTrialExpired()
            ? "Expired"
            : `Expires In ${
                  this.isFreeTrialActive()
                      ? formatDistanceToNowStrict(
                            new Date(this._organisation()?.freeTrialEnd)
                        )
                      : 0
              }`;
    });

    isDowngradePending = computed<boolean>(() => {
        return !!this._organisation()?.subscription?.scheduledChanges;
    });

    computeDaysUntil(targetDate: Date, now: Date): number {
        return Math.ceil((targetDate.getTime() - now.getTime()) / (1000 * 60 * 60 * 24));
    }

    calculateDates(subscription: any): { lockDate: Date, deleteDate: Date } {
        const nextPeriodEnd = new Date(subscription.periodEnd);
        const billingInterval = subscription.interval === "month" ? 30 : 365;
        const previousPeriodEnd = new Date(nextPeriodEnd);
        previousPeriodEnd.setDate(previousPeriodEnd.getDate() - billingInterval);
        const lockDate = new Date(previousPeriodEnd);
        lockDate.setDate(lockDate.getDate() + 30);
        const deleteDate = new Date(lockDate);
        deleteDate.setDate(deleteDate.getDate() + (subscription.isCancelled && subscription.isExpired ? 60 : 30));
        return { lockDate, deleteDate };
    }

    daysUntilLocked = computed<number>(() => {
        const subscription = this._organisation()?.subscription;
        if (!subscription) return 0;
        const now = new Date();
        const { lockDate } = this.calculateDates(subscription);
        return this.computeDaysUntil(lockDate, now);
    });
    
    daysUntilDelete = computed<number>(() => {
        const subscription = this._organisation()?.subscription;
        if (!subscription) return 0;    
        const now = new Date();
        const { deleteDate } = this.calculateDates(subscription);
        return this.computeDaysUntil(deleteDate, now);
    });

    subscriptionMessage = computed<string>(() => {
        let msg = 'Your subscription has expired';
        if (this.daysUntilLocked() > 0) {
            msg += ` - in ${this.daysUntilLocked()} days your account will be locked.`;
        } else if (this.daysUntilDelete() > 0) {
            msg += ` - in ${this.daysUntilDelete()} days your account and all data will be deleted in accordance with our T&Cs.`;
        }
        return msg;
    });

    createBillingSession() {
        if (!this.isSubscription()) return;
        const input = { customerId: this._organisation().customerId };
        this.apiService.graphql<{ createBillingSession: { url: string } }>(`mutation CreateBillingSession($input: CreateBillingSessionInput!) {
            createBillingSession(input: $input) { url }
        }`, { input }).subscribe((response) => {
            const { url } = response?.createBillingSession;
            window.open(url, '_blank');
        });
    }

    onCancelPendingInvite(event: { pendingEmail: string }) {
        const { pendingEmail } = event;
        this.cancelInvite.emit(pendingEmail);
        const updatedPendingMemberships = this._pendingMemberships().filter(
            (member) => member.pendingEmail !== pendingEmail
        );
        this._pendingMemberships.set(updatedPendingMemberships);
        this._seatsFilled.set(this._pendingMemberships().length + this._memberships().length);
    }

    showSuccess(detail: string = 'Organisation Updated') {
        this.messageService.add({ severity: "success", detail });
    }

    showError() {
        this.messageService.add({ severity: "error", detail: "Something went wrong on our end. Please try again later." });
    }

    onCancelPendingChange(event) {
        const organisation = { ...this._organisation() };
        if (organisation?.subscription && organisation?.subscription?.scheduledChanges) {
            organisation.subscription = { ...organisation.subscription }; 
            organisation.subscription.scheduledChanges = null;
            this._organisation.set(organisation);
            this.showSuccess('Successfully removed any pending changes');
        }
    }
}
