<p-confirmDialog [closable]="false" styleClass="w-[400px] text-xs text-text-primary" />
@if (isDivider()) {
    <div class="relative h-8 flex items-center justify-center my-5 hover:cursor-pointer group/divider">
        <div class="absolute inset-x-2 border border-t border-grey-border border-dashed"></div>
        <p class="absolute text-xs text-text-secondary p-1 bg-background opacity-100 group-hover/divider:opacity-0">Page divider</p>
        <div class="absolute drag-handle w-[30px] h-[30px] bg-background hover:bg-grey-15 text-lg text-grey-40 hover:text-grey-50 rounded-md cursor-move flex items-center justify-center opacity-0 group-hover/divider:opacity-100 transition-opacity] pointer-events-none group-hover/divider:pointer-events-auto">
            <i class="fa-solid fa-grip-vertical"></i>
        </div>
        <button class="absolute right-4 w-[30px] h-[30px] bg-background hover:bg-grey-15 text-lg text-grey-40 hover:text-grey-50 opacity-0 group-hover/divider:opacity-100 transition-opacity pointer-events-none group-hover/divider:pointer-events-auto rounded-md flex justify-center items-center" (click)="deleteDivider($event)">
            <i class="fa-solid fa-trash-can"></i>
        </button>
    </div>
}
@else if (isChildForm()) {
    <div class="attribute-container">
        <div class="h-10 flex items-center bg-grey-110 border-b border-grey-30 pl-3 rounded-t-[4px]">
            <div class="attribute-container-inner flex items-center justify-start gap-3 grow overflow-hidden">
                <svg-icon [src]="question()?.iconUrl" class="min-w-6 text-grey-60"></svg-icon>
                
                <h2 class="w-full text-xs text-text-primary font-semibold uppercase truncate">{{ attribute().form?.name }}</h2>
                
                <p-button label="Child form settings" [text]="true" icon="fa-solid fa-gear" (click)="subFormSettings()" [disabled]="deleted()"></p-button>
            </div>
    
            <div class="flex-none p-2 flex items-center justify-end">
                <i [ngClass]="{'fa-trash-can': !deleted(), 'fa-arrow-rotate-left': deleted()}" class="delete fa-solid text-lg w-[30px] aspect-square text-grey-50 opacity-0 transition-opacity hover:bg-primary hover:bg-opacity-[0.04] pointer-events-none rounded-md flex justify-center items-center cursor-pointer" (click)="deleted() ? restore() : deleteNoticeCheck($event)"></i>
                <i class="drag-handle fa-solid fa-grip-vertical text-lg w-[30px] aspect-square text-grey-30 cursor-move flex justify-center hover:text-[#6CA9FF]"></i>
            </div>
        </div>
        <div class="relative flex items-start gap-5 p-5 pr-[52px]" [ngClass]="{'pb-2': showChildFormBuilder(), 'pb-6': !showChildFormBuilder()}">
            <div class="attribute-container-inner flex-grow flex flex-col gap-1">
                <h2 class="text-sm font-semibold">{{attribute().text}}</h2>
                @if(hasDescription()){
                    <p class="text-[10px]">{{attribute().description}}</p>
                }
            </div>
            @if (childFormHasAttributes()) {
                <button class="absolute top-5 right-[11px] w-8 h-8 flex items-center justify-center rounded-md hover:bg-primary hover:bg-opacity-[0.04]" (click)="openChildFormBuilder.set(!showChildFormBuilder())">
                    <svg [ngClass]="{'rotate-180': !showChildFormBuilder()}" width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g id="arrow">
                            <path id="Vector" d="M10.3262 8.83567H1.67383C1.07471 8.83567 0.774678 8.1113 1.19831 7.68767L5.52448 3.36151C5.78708 3.0989 6.2129 3.0989 6.47551 3.36151L10.8017 7.68767C11.2253 8.1113 10.9253 8.83567 10.3262 8.83567Z" fill="#878F97"/>
                        </g>
                    </svg>
                </button>
            }
        </div>
        @if (showChildFormBuilder()) {
            <div class="attribute-container-inner pt-0" [ngClass]="{'pointer-events-none': deleted()}">
                <app-project-form-builder [formId]="this.childFormId" [child]="true"></app-project-form-builder>
            </div>
        }
        <!-- Conditions -->
        @if(hasConditions()){
            <div class="px-4 pb-[21px]">
                <app-project-form-conditions-summary [attribute]="attribute()"></app-project-form-conditions-summary>
            </div>
        }
    </div>
}
@else {
    @if (isGeometry() && !isFirst()) {
        <div class="relative h-8 flex items-center justify-center my-5">
            <div class="absolute inset-x-2 border border-t border-grey-border border-dashed"></div>
            <p class="absolute text-xs text-text-secondary p-1 bg-background">Mandatory page divider</p>
        </div>
    }
    <div class="attribute-container flex items-start justify-normal group">
        <div class="attribute-container-inner pl-4 pr-[6px] py-[21px] flex flex-col gap-4">
            <!-- Body -->
            <div class="flex justify-start gap-4" [ngClass]="{'items-center': (!hasDescription() && !isTextBlock()), 'items-start': (hasDescription() || isTextBlock())}">
                <div class="icon-wrapper">
                    <svg-icon [src]="question()?.iconUrl"></svg-icon>
                </div>
                <div class="flex-grow">
                    @if (isTextBlock()) {
                        <p-editor [ngModel]="text()" (onTextChange)="updateTextBlock($event)" [style]="{ height: '180px' }" />
                    }
                    @else {
                        <h2 class="text-sm font-semibold leading-5">
                            {{ text() }}
                            @if (isMandatory()) {
                                <sup>*</sup>
                            }
                        </h2>
                        @if (hasDescription()) {
                            <p class="text-[10px] leading-[14px]">{{ attribute().description }}</p>
                        }
                    }
                </div>
            </div>
            <!-- Conditions -->
            @if (hasConditions()) {
                <app-project-form-conditions-summary [attribute]="attribute()"></app-project-form-conditions-summary>
            }
        </div>
        <!-- Side Buttons -->
        <div class="flex-none w-[46px] h-full p-2 flex flex-col">
            <i class="drag-handle fa-solid fa-grip-vertical text-lg h-[30px] aspect-square text-grey-30 cursor-move flex justify-center hover:text-primary-200"></i>
            <i [ngClass]="{'fa-trash-can': !deleted(), 'fa-arrow-rotate-left': deleted()}" class="fa-solid text-lg h-[30px] aspect-square text-grey-50 opacity-0 group-hover:opacity-100 transition-opacity hover:bg-primary-10 pointer-events-none group-hover:pointer-events-auto rounded-md flex justify-center items-center cursor-pointer" (click)="deleted() ? restore() : deleteNoticeCheck($event)"></i>
        </div>
    </div>
    @if (isGeometry() && !isLast()) {
        <div class="relative h-8 flex items-center justify-center my-5">
            <div class="absolute inset-x-2 border border-t border-grey-border border-dashed"></div>
            <p class="absolute text-xs text-text-secondary p-1 bg-background">Mandatory page divider</p>
        </div>
    }
}