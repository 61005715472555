import { Component, computed, inject, input, OnInit } from '@angular/core';
import { CollectionItemService } from './collection-item.service';
import { ConfirmationService } from 'primeng/api';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { CollectionItemMediaEditComponent } from './collection-item-media-edit.component';
import { MediaItem } from '../collection.model';

@Component({
    selector: 'app-collection-item-media',
    templateUrl: 'collection-item-media.component.html',
    standalone: true,
})

export class CollectionItemMediaComponent {

    collectionItemService = inject(CollectionItemService);
    confirmationService = inject(ConfirmationService);
    dialogService = inject(DialogService);

    item = input<MediaItem>();

    mode = this.collectionItemService.mode;

    mediaType = computed(() => {
        const item = this.item();
        const type = item.type.split('/')[0];
        return type; // 'image' || 'audio'
    });

    src = computed(() => {
        const item = this.item();
        return item.url;
    });

    dialogRef: DynamicDialogRef | undefined;

    edit($event: Event) {
        $event.preventDefault();
        $event.stopPropagation();

        const header = `${this.mediaType() === 'image' ? 'Image' : 'Sound file'} information`;

        this.dialogRef = this.dialogService.open(CollectionItemMediaEditComponent, {
            data: {
                item: this.item()
            },
            header,
            width: '402px',
            modal: true,
        });

        this.dialogRef.onClose.subscribe((update: MediaItem) => {
            if (update) {
                if (this.mode() === 'create') {
                    this.collectionItemService.newMediaItems.update(items => {
                        const index = items.findIndex(i => i.id === update.id);
                        items.splice(index, 1, update);
                        return items;
                    });
                    this.collectionItemService.newMediaItemsDirty.set(true);
                } else if (this.mode() === 'edit') {
                    this.collectionItemService.updateMediaItem(update).subscribe(res => {
                        if (!!res) {
                            const item = res;
                            // update media items
                            this.collectionItemService.mediaItems.update(items => {
                                const index = items.findIndex(i => i.id === item.id);
                                items.splice(index, 1, item);
                                return items;
                            });
                        }
                    });
                }
            }
        });
    }

    deleteMediaItemCheck($event) {
        $event.preventDefault();
        $event.stopPropagation();
        
        if (this.mode() === 'edit') {
            this.confirmationService.confirm({
                defaultFocus: 'none',
                message: 'Are you sure you want to delete this media item?<br>This cannot be undone.',
                target: $event.target,
                header: 'Delete media item',
                rejectLabel: 'Cancel',
                rejectIcon: 'none',
                rejectButtonStyleClass: 'p-button p-button-lg p-button-outlined',
                acceptLabel: 'Delete item',
                acceptIcon: 'none',
                acceptButtonStyleClass: 'p-button p-button-lg p-button-danger',
                accept: () => {
                    this.deleteMediaItem();
                }
            });
        } else {
            this.deleteMediaItem();
        }
    }

    private deleteMediaItem() {
        const { id } = this.item();
        if (this.mode() === 'edit') {
            this.collectionItemService.deleteMediaItem(id);
        } else if (this.mode() === 'create') {
            this.collectionItemService.newMediaItems.update(items => items.filter(i => i.id !== id));
        }
    }
}