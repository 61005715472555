<form class="w-full p-4 space-y-5">
    <div class="flex items-center justify-between">
        <label class="text-sm text-text-primary font-normal flex items-center" for="singleOnly">
            Single photo only
        </label>
        <p-inputSwitch inputId="singleOnly" name="singleOnly" [ngModel]="singleOnly()" (ngModelChange)="singleOnlyChanged($event)" />
    </div>
    @if (!singleOnly()) {
        <div class="flex items-center justify-between">
            <label class="text-sm text-text-primary font-normal flex items-center" for="limitNumber">
                Set a range of photos needed
            </label>
            <p-inputSwitch inputId="limitNumber" name="limitNumber" [ngModel]="limitNumber()" (ngModelChange)="limitChanged($event)" />
        </div>
        @if (limitNumber()) {
            <div class="flex items-start justify-normal gap-5">
                <div class="flex flex-col gap-1">
                    <label class="text-xs text-grey-70 font-normal" for="min">Minimum number needed</label>
                    <p-inputNumber 
                        inputId="min"
                        name="min"
                        [min]="0"
                        [ngModel]="min()"
                        (ngModelChange)="configChanged({ min: $event })"
                        placeholder="e.g. 1"
                        styleClass="w-full" /> 
                </div>
                <div class="flex flex-col gap-1">
                    <label class="text-xs text-grey-70 font-normal" for="max">Maximum number</label>
                    <p-inputNumber 
                        inputId="max" 
                        name="max"
                        [min]="0"
                        [ngModel]="max()"
                        (ngModelChange)="configChanged({ max: $event })"
                        placeholder="e.g. 5"
                        styleClass="w-full" /> 
                </div>
            </div>
        }
    }
</form>