import { Pipe, PipeTransform } from "@angular/core";
import { formatDistanceToNowStrict } from "date-fns";

@Pipe({
    name: "dateToNow",
    standalone: true
})
export class DateToNowPipe implements PipeTransform {
    transform(value: string): string {
        return formatDistanceToNowStrict(new Date(value));
    }
}