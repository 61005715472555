import { Component, Input, inject, signal, viewChild } from "@angular/core";
import { MapBuilderEditorComponent } from "./map-builder-editor.component";
import { MapBuilderMapComponent } from './map-builder-map.component';
import { MapBuilderSidebarComponent } from "./map-builder-sidebar.component";
import { MapBuilderService } from "./map-builder.service";

@Component({
    selector: 'app-map-builder',
    templateUrl: './map-builder.component.html',
    standalone: true,
    providers: [MapBuilderService],
    imports: [
        MapBuilderSidebarComponent,
        MapBuilderEditorComponent,
        MapBuilderMapComponent
    ]
})
export class MapBuilderComponent {

    @Input() projectId: number;
    mapComponent = viewChild(MapBuilderMapComponent);

    mapBuilderService = inject(MapBuilderService);
    parent = this.mapBuilderService.parent;
    isTestData = signal<boolean>(false);

    ngOnInit() {
        this.mapBuilderService.init(this.projectId);
    }

    deleteLayer() {
        this.mapBuilderService.deleteLayer();
    }
}