@if(isShowBanner()){
<div class="relative flex justify-center items-center w-full bg-white h-8 text-danger border-b border-grey-10">
    @if(subscriptionService.isFreeTrial()){
    <div class="flex items-center justify-center gap-2 font-bold">
        <span class="text-xs">Free Trial</span>
        <span class="max-w-fit flex items-center gap-2 rounded-xl h-6 p-2 bg-danger text-white text-xs capitalize">
            <i class="fas fa-clock"></i>{{ subscriptionService.freeTrialMessage() }}
            @if(subscriptionService.isOwner() && subscriptionService.isNewPaymentFeatureAvailable()) {
                - 
                <span class="underline cursor-pointer text-white" 
                    [class.opacity-50]="!subscriptionService._tiers().length"
                    (click)="subscriptionService._tiers().length && isVisibleUpdatePlanDialog.set(true)">
                    Upgrade Now
                </span>
            }
        </span>
    </div>
    } @else {
        <div class="flex items-center justify-center gap-2">
            <span>{{ subscriptionService.subscriptionMessage() }}</span>
            @if(subscriptionService.isOwner() && !subscriptionService.isSubscriptionPastDue()) {
                <span class="max-w-fit flex items-center gap-2 rounded-xl h-6 p-2 bg-primary text-white text-xs capitalize">
                    <span class="underline cursor-pointer" 
                        [class.opacity-50]="!subscriptionService._tiers().length"
                        (click)="subscriptionService._tiers().length && isVisibleUpdatePlanDialog.set(true)">
                        Upgrade Now
                    </span>
                </span>
            }
        </div>
    }
</div>
}

@if(subscriptionService._tiers().length && subscriptionService.isOwner()) {
<app-update-plan
    [baseUrl]="_baseUrl()"
    [seatsFilled]="subscriptionService._seatsFilled()"
    [projects]="subscriptionService._organisation()?.projects"
    [isFreeTrial]="subscriptionService.isFreeTrial()"
    [isFreeTrialExpired]="subscriptionService.isFreeTrialExpired()"
    [isSubscription]="subscriptionService.isSubscription()"
    [tierId]="subscriptionService.currentTierId()"
    [organisationId]="subscriptionService._organisation()?.id"
    [interval]="subscriptionService.currentInterval()"
    [organisationTiers]="subscriptionService._tiers()"
    [trialTimeLeft]="subscriptionService._organisation()?.freeTrialEnd"
    [currentPlanSeats]="subscriptionService._organisation()?.seats"
    [currentAddonSeats]="subscriptionService._organisation()?.ukhabSeats || 0"
    [periodEnd]="subscriptionService._organisation()?.subscription?.periodEnd || null"
    [isVisibleUpdatePlanDialog]="isVisibleUpdatePlanDialog()" 
    (visibleUpdatePlanChange)="onVisibleUpdatePlan($event)" />

<app-remove-seats-filled
    [userId]="_userId()"
    [seatsFilled]="subscriptionService._seatsFilled()"
    [seatsSelected]="seatsSelected()"
    [memberships]="subscriptionService._memberships()"
    [pendingMemberships]="subscriptionService._pendingMemberships()"
    [isVisibleRemoveSeatsDialog]="isVisibleRemoveSeatsDialog()"
    (visibleRemoveSeatsChange)="onVisibleRemoveSeats($event)"
    (cancelPendingInvitation)="subscriptionService.onCancelPendingInvite($event)"/>
}