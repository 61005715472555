import { Component, computed, EventEmitter, inject, Output } from '@angular/core';
import { InputNumberModule } from 'primeng/inputnumber';
import { FormsModule } from '@angular/forms';
import { InputSwitchModule } from 'primeng/inputswitch';
import { InputTextModule } from 'primeng/inputtext';
import { ProjectFormService } from '../project-form.service';
import { NgClass } from '@angular/common';

export type SubformConfig = {
    min: number;
    max: number;
}

@Component({
    selector: 'app-subform-settings',
    templateUrl: 'subform-settings.component.html',
    standalone: true,
    imports: [
        FormsModule,
        InputNumberModule,
        InputSwitchModule,
        InputTextModule,
        NgClass
    ]
})

export class SubformSettingsComponent {

    form = inject(ProjectFormService);

    attribute = this.form.selectedAttribute;

    @Output() update: EventEmitter<{ config: SubformConfig }> = new EventEmitter();
    
    config = computed(() => {
        const attribute = this.attribute();
        const config = attribute.config as SubformConfig;
        console.log(config);
        
        return {
            min: config.min ?? null,
            max: config.max ?? null
        };
    });

    min = computed(() => this.config().min);
    max = computed(() => this.config().max);
    minMaxError = computed(() => {
        return this.max() < this.min();
    });

    limitNumber = computed(() => {
        const min = this.min();
        const max = this.max();

        return min !== null || max !== null;
    });
    
    limitChanged(limit: boolean) {
        if (!limit) {
            this.update.emit({config: { min: null, max: null }});
        } else {
            this.update.emit({config: { min: 0, max: 0 }});
        }
    }
}