import { Component, Output, EventEmitter, signal, inject, computed, Input, WritableSignal } from "@angular/core";
import { FormGroup, Validators, FormControl, ReactiveFormsModule } from "@angular/forms";
import { DatePipe } from "@angular/common";
import { PanelModule } from "primeng/panel";
import { ToastModule } from "primeng/toast";
import { ButtonModule } from "primeng/button";
import { InputTextModule } from "primeng/inputtext";
import { AvatarModule } from "primeng/avatar";
import { AvatarGroupModule } from "primeng/avatargroup";
import { ApiService } from "src/app/core";
import { UpdatePlanComponent } from "../organisation-subscription/update-plan.component";
import { SuccessfulSubscriptionModalComponent } from '../organisation-subscription/successful-subscription-modal.component';
import { RemoveSeatsFilledComponent } from "../organisation-subscription/remove-seats-filled.component";
import { UploadOrganisationImageComponent } from "./upload-organisation-image.component";
import { OrganisationSettings } from "src/app/core/models/organisation.model";
import { DialogModule } from 'primeng/dialog';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ConfirmationService } from 'primeng/api';
import { SubscriptionService } from "@core/services/subscription.service";

interface UpdatedSubscriptionScheduleData {
    scheduleStart: string;
    scheduleEnd: string;
    pendingTier: string;
    pendingSeatQuantity: number;
    pendingLicenseQuantity: number;
}

export interface UpdatedSubscriptionData {
    id: number, 
    seats: number, 
    ukhabSeats: number, 
    tier: { 
      id: number,
      monthlyPrice: number,
      name: string,
      yearlyPrice: number
    },
    subscription: {
      interval: string,
      isCancelled: boolean,
      isExpired: boolean,
      isPastDue: boolean,
      periodEnd: string,
      periodStart: string,
      scheduledChanges?: UpdatedSubscriptionScheduleData
    }
}

@Component({
    selector: "app-organisation-settings",
    templateUrl: "./organisation-settings.component.html",
    standalone: true,
    imports: [
        DatePipe,
        ToastModule,
        PanelModule,
        ButtonModule,
        InputTextModule,
        DialogModule,
        AvatarModule,
        AvatarGroupModule,
        ReactiveFormsModule,
        UpdatePlanComponent,
        SuccessfulSubscriptionModalComponent,
        RemoveSeatsFilledComponent,
        UploadOrganisationImageComponent,
        ConfirmDialogModule,
    ],
    providers: [DatePipe, ConfirmationService, SubscriptionService],
    styles: [`
        :host { @apply relative flex w-full h-full flex flex-col items-center justify-center; }
        p-button i {
            font-size: 14px !important;
        }
    `]
})
export class OrganisationSettingsComponent {
    public subscriptionService = inject(SubscriptionService);

    _userId: WritableSignal<number> = signal(null);
    _isSuperAdmin: WritableSignal<boolean> = signal(false);
    _baseUrl: WritableSignal<string> = signal('');
    visible: boolean = false;

    @Input() set userId(userId: number) { this._userId.set(userId) }
    @Input() set isSuperAdmin(isSuperAdmin: boolean) { this._isSuperAdmin.set(isSuperAdmin) }
    @Input() set baseUrl(baseUrl: string) { this._baseUrl.set(baseUrl) }
    @Input() set organisation(organisation: OrganisationSettings) {
        if (organisation?.id) {
            this.subscriptionService.init(organisation);
        }
    }

    @Output() update: EventEmitter<any> = new EventEmitter();
    @Output() removeMembers: EventEmitter<any> = new EventEmitter();
    @Output() updateSubscriptionData: EventEmitter<UpdatedSubscriptionData> = new EventEmitter();
    @Output() cancelInvite: EventEmitter<any> = new EventEmitter();

    public imageUrl: string | ArrayBuffer | null = null;

    private datePipe = inject(DatePipe);
    private apiService = inject(ApiService);
    private confirmationService = inject(ConfirmationService);

    organisationProfileForm: FormGroup = new FormGroup({
        name: new FormControl<string>('', [Validators.required]),
        image: new FormControl<File | null>(null)
    });

    freeTrialMessage = computed<string>(() => {
        const date = this.datePipe.transform(new Date(this.subscriptionService._organisation()?.freeTrialEnd), 'dd MMMM yyyy');
        return this.subscriptionService.isFreeTrialExpired() 
            ? 'Free trial expired' 
            : `Free trial ends on ${date}`;
    });
    subscriptionMessage = computed<string>(() => {
        const date = this.datePipe.transform(new Date(this.subscriptionService._organisation()?.subscription.periodEnd), 'dd MMMM yyyy');
        return this.subscriptionService.isSubscriptionCancelled() 
            ? `Your subscription will end on ${date}`
            : this.subscriptionService.isSubscriptionActive()
            ? `Plan renews on ${date}` 
            : `Plan expired`;
    });
    planSeatsAvailable = computed<number>(() => {
        const organisationSeats = this.subscriptionService._organisation()?.seats || 0;
        return Math.max(organisationSeats - this.subscriptionService._seatsFilled(), 0);
    });

    seatsSelected = signal<number>(0);

    isVisibleUploadImageDialog = signal<boolean>(false);
    isVisibleRemoveSeatsDialog = signal<boolean>(false);
    isVisibleUpdatePlanDialog = signal<boolean>(false);

    constructor() {
        this.subscriptionService.cancelInvite.subscribe((pendingEmail: string) => {
            this.cancelInvite.emit(pendingEmail);
        });
    }

    ngOnInit() {
        this.initOrganisationProfileForm();
        if (this.subscriptionService.isNewPaymentFeatureAvailable()) {
            this.subscriptionService.loadMemberships();
        }
    }

    initOrganisationProfileForm() {
        const { name, imageUrl } = this.subscriptionService._organisation();
        this.organisationProfileForm.controls['name'].setValue(name);
        this.imageUrl = imageUrl || null;
        this.organisationProfileForm.markAsPristine();
    }

    updateOrganisation() {
        if (this.organisationProfileForm.invalid) return;
        const name = this.organisationProfileForm.get("name").value;
        const image: File = this.organisationProfileForm.get("image").value;
        this.update.emit({ name, image });
        this.organisationProfileForm.markAsPristine();
    }

    onVisibleUploadImage(event: { isVisibleUploadImageDialog: boolean, imageUrl: string | null, image: File | null }) {
        const { imageUrl, image, isVisibleUploadImageDialog } = event;
        this.isVisibleUploadImageDialog.set(isVisibleUploadImageDialog);
        if (imageUrl && image) {
            this.imageUrl = imageUrl;
            this.organisationProfileForm.controls['image'].setValue(image);
            this.organisationProfileForm.markAsDirty();
        };
    }

    onVisibleUpdatePlan(event: { isVisibleUpdatePlanDialog: boolean, seatsSelected: number, updatedData: UpdatedSubscriptionData | null }) {
        const { isVisibleUpdatePlanDialog, seatsSelected, updatedData } = event;
        this.isVisibleUpdatePlanDialog.set(isVisibleUpdatePlanDialog);

        if (seatsSelected) {
            this.seatsSelected.set(seatsSelected);
            this.isVisibleRemoveSeatsDialog.set(true);
        }
        if (updatedData) {
            this.updateSubscriptionData.emit(updatedData);
        }
    }

    onVisibleRemoveSeats(event: { isVisibleRemoveSeatsDialog: boolean, userIds: number[], isBack: boolean }) {
        const { userIds, isVisibleRemoveSeatsDialog, isBack } = event;
        this.isVisibleRemoveSeatsDialog.set(isVisibleRemoveSeatsDialog);
        this.seatsSelected.set(0);
        if (isBack) {
            this.isVisibleUpdatePlanDialog.set(true);
        }
        if (userIds.length) {
            const updatedMemberships = this.subscriptionService._memberships().filter(member => !userIds.includes(member.userId));
            this.subscriptionService.updateMemberships(updatedMemberships);
            this.removeMembers.emit(userIds);
            this.isVisibleUpdatePlanDialog.set(true);
        }
    }

    onCancelPendingInvite(event: { pendingEmail: string }) {
        const { pendingEmail } = event;
        this.cancelInvite.emit(pendingEmail);
        const updatedPendingMemberships = this.subscriptionService._pendingMemberships().filter(
            (member) => member.pendingEmail !== pendingEmail
        );
        this.subscriptionService._pendingMemberships.set(updatedPendingMemberships);
    }

    showDialog() {
        this.visible = true;
    }

    confirmCancelPending($event: Event) {
        this.visible = false;
        let header = 'Cancel pending changes';
        let message = 'Are you sure you want to cancel any pending changes to your subscription?';
        this.confirmationService.confirm({
            defaultFocus: 'none',
            message,
            target: $event.target,
            header,
            rejectLabel: 'Cancel',
            rejectIcon: 'none',
            rejectButtonStyleClass: 'p-button p-button-lg p-button-outlined',
            acceptLabel: 'Confirm',
            acceptIcon: 'none',
            acceptButtonStyleClass: 'p-button p-button-lg p-button',
            accept: () => this.cancelPendingDowngrade(),
            reject: () => {
                this.visible = true;
            },
        });
    }

    cancelPendingDowngrade() {
        const mutation = ` mutation CancelPendingDowngrade($input: CancelPendingDowngradeInput!) {
            cancelPendingDowngrade(input: $input) {
                id,
                seats
                ukhabSeats,
                tier {
                    id
                    monthlyPrice
                    name
                    yearlyPrice
                },
                subscription {
                    interval
                    isCancelled
                    isExpired
                    isPastDue
                    periodEnd
                    periodStart
                    scheduledChanges {
                        pendingTier
                        pendingSeatQuantity
                        pendingLicenseQuantity
                        scheduleStart
                        scheduleEnd
                    }
                }
            }
        }`;
        this.apiService.graphql<{ cancelPendingDowngrade: any }>(mutation, { input: { organisationId: this.subscriptionService._organisation().id } }
        ).subscribe(response => {
            this.updateSubscriptionData.emit(response.cancelPendingDowngrade);
        });
    }
}
