import { AsyncPipe, NgFor } from '@angular/common';
import { Component, ElementRef, computed, inject, input, viewChild } from '@angular/core';
import Sortable, { SortableEvent } from 'sortablejs';
import { ProjectFormAttributeComponent } from './project-form-attribute.component';
import { Attribute, QuestionId } from './project-form.model';
import { ProjectFormService } from './project-form.service';
import { ToastModule } from 'primeng/toast';
import { MessageService } from 'primeng/api';

@Component({
    selector: 'app-project-form-builder-section',
    templateUrl: './project-form-builder-section.component.html',
    styleUrls: ['./project-form-builder-section.component.scss'],
    standalone: true,
    imports: [
        NgFor,
        AsyncPipe,
        ProjectFormAttributeComponent,
        ToastModule
    ],
    providers: [
        MessageService
    ]
})
export class ProjectFormBuilderSectionComponent {
    
    container = viewChild<ElementRef>('container');
    
    form = inject(ProjectFormService);
    messageService = inject(MessageService);

    formId = input.required<number>();

    attributes = computed<Attribute[]>(() => {
        const formId = this.formId();
        /** Return only attributes for this form */
        return this.form.attributes().filter(a => a.formId === formId).sort((a, b) => a.order - b.order);
    });

    sectionSortable: Sortable | undefined;

    ngAfterViewInit() {
        this.sectionSortable  = new Sortable(this.container()!.nativeElement, {
            group: {
                name: 'questions',
                put: true,
                pull: false,
                revertClone: false
            },
            sort: true,
            handle: '.drag-handle',
            dataIdAttr: 'data-aid',
            onAdd: (event: SortableEvent) => {
                const questionId: QuestionId = (event as any).originalEvent.dataTransfer.getData('Text');
                (event as any).item.parentNode.removeChild(event.item);
                
                if (questionId) {
                    /** If geometry only allow one per form*/
                    if (questionId === 'geometry' && this.form.hasGeometry(this.formId())) {
                        return;
                    }
                    /** If a divider don't allow at start of section */
                    if (questionId === 'divider') {
                        const newIndex = event.newIndex;
                        if (newIndex === 0) {
                            return;
                        }
                        /** Or next to a geometry question as not needed */
                        const order = this.sectionSortable.toArray();
                        if (this.form.hasGeometry(this.formId())) {
                            const geometryId = this.attributes().find(a => a.questionType === 'geometry').id;
                            const geometryIndex = order.findIndex(id => Number(id) === geometryId );
                            
                            if (newIndex === geometryIndex + 1 || newIndex === geometryIndex) {
                                return;
                            }
                        }
                        /** Or next to another divider */
                        const dividerIds = this.attributes().filter(a => a.type === 'divider').map(a => a.id);
                        if (dividerIds.some(id => (id === Number(order[newIndex]) || id === Number(order[newIndex - 1])))) {
                            return;
                        }
                    }

                    if (questionId ==='childForm') {
                        /** If trying to add a child form check to see if allowed - maximum four forms deep */
                        if (this.form.limitChildForms(this.formId())) {
                            return;
                        }
                    }

                    /** Don't allow geometry questions that are dependent on a location question if no location question in the form */
                    if (questionId === 'areaLookup' || questionId === 'coordinateProjection' || questionId === 'reverseGeolocation') {
                        if (!this.attributes().some(a => a.questionType === 'geometry')) {
                            this.addGeometryError(questionId);
                            return;
                        }
                    }

                    const attributeId = this.form.addAttribute(questionId, this.formId(), event.newIndex);

                    if (questionId !== 'divider') {
                        this.form.selectAttribute(attributeId);
                    }
                }
            },
            onEnd: (event: SortableEvent) => {
                const order = this.sectionSortable.toArray();
                const id: number = Number(event.item.dataset['aid']);
                const newIndex: number = event.newIndex;
                const isDivider: boolean = this.form.attributeIsDivider(id);
                
                /** Check if divider ends up at start or end after dragging and delete if so */
                if (isDivider) {
                    /** If a divider don't allow at start or end of section */
                    if (newIndex === 0 || newIndex === order.length - 1) {
                        this.form.deleteDivider(id);
                        this.form.updateOrder(order);
                        return;
                    }
                    /** Or next to a geometry question as not needed */
                    if (this.form.hasGeometry(this.formId())) {
                        const geometryId = this.attributes().find(a => a.questionType === 'geometry').id;
                        const geometryIndex = order.findIndex(id => Number(id) === geometryId );
                        
                        if (newIndex === geometryIndex + 1 || newIndex === geometryIndex - 1) {
                            this.form.deleteDivider(id);
                            this.form.updateOrder(order);
                            return;
                        }
                    }
                    /** Or next to another divider */
                    const dividerIds = this.attributes().filter(a => a.type === 'divider').filter(a => a.id !== id).map(a => a.id);
                    if (dividerIds.some(id => (id === Number(order[newIndex - 1]) || id === Number(order[newIndex + 1])))) {
                        this.form.deleteDivider(id);
                        this.form.updateOrder(order);
                        return;
                    }
                }
                
                /** If a location question ends up next to a divider then remove the divider */
                if (this.attributes().find(a => a.id === Number(id))?.questionType === 'geometry') {
                    const prevId = Number(order[newIndex - 1]);
                    const nextId = Number(order[newIndex + 1]);
                    if (this.form.attributeIsDivider(prevId)) {
                        this.form.deleteDivider(prevId);
                    }
                    if (this.form.attributeIsDivider(nextId)) {
                        this.form.deleteDivider(nextId);
                    }
                }

                /** Update attribute order this helps with knowing if the geometry question is at the start or end */
                this.form.updateOrder(order);
            }
        });
    }

    private addGeometryError(questionId: QuestionId) {
        let question = '';
        if (questionId === 'areaLookup') {
            question = 'Area lookup';
        } else if (questionId === 'coordinateProjection') {
            question = 'Coordinate projection';
        } else if (questionId === 'reverseGeolocation') {
            question = 'Reverse geolocation';
        }
        this.messageService.add({ 
            severity: 'error',
            summary: `Unable to add ${question} question`,
            detail: 'The form requires a Location / Geometry question first.'
        });
    }
}