<div class="w-full bg-white rounded-lg shadow-panel">
    <div class="h-[54px] bg-primary p-4 rounded-t-lg flex items-center">
        <p class="text-white font-semibold text-lg leading-none">In app card preview</p>
    </div>
    @if (geometry()) {
        <div #previewMap class="w-full h-52"></div>
    }
    @if (images().length > 0) {
        <div class="w-full bg-grey-30">
            <div class="w-full h-52 relative">
                <img [src]="currentImage().url" alt="" class="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 max-h-52">
            </div>
            <div class="w-full flex items-center justify-center gap-2 p-[14px]">
                @if (images().length > 1) {
                    @for (dot of images(); track dot.id; let index = $index) {
                        <button (click)="currentImageIndex.set(index)" class="h-2 w-2 rounded-full" [ngClass]="currentImageIndex() === index ? 'bg-primary' : 'bg-grey-30'"></button>
                    }
                }
            </div>
        </div>
    }
    <div class="flex flex-col gap-4 p-4 pb-8">
        <h3 class="text-3xl font-semibold text-black break-words pb-1">{{ preview().title }}</h3>
        @if (audio().length > 0) {
            <div class="flex flex-col gap-3 px-2">
                @for (item of audio(); track item.id) {
                    <audio [controls]="true" class="w-full">
                        <source [src]="item.url" [type]="item.type">
                        Your browser does not support this audio tag
                    </audio>
                }
            </div>
        }
        @if (data().length > 0) {
            @for (item of data(); track item) {
                <div class="flex flex-col gap-1 text-sm text-text-primary">
                    <h4 class="font-semibold">{{ item.label }}</h4>
                    <p [innerHTML]="item.value"></p>
                </div>
            }
        }
    </div>
</div>