<div class="flex items-center gap-4">
    <form [formGroup]="searchForm" [ngClass]="{'hidden': selectionCount() > 0}">
        <span class="p-input-icon-left" [class.p-input-icon-right]="searchForm.value.search">
            <i class="pi pi-search"></i>
            <input type="text" pInputText formControlName="search" placeholder="Search for projects" class="w-80" />
            @if(searchForm.value.search){
            <i class="pi pi-times cursor-pointer" (click)="searchForm.reset()"></i>
            }
        </span>
    </form>

    <button class="border rounded-full flex items-center justify-center gap-2 h-10 w-36 cursor-pointer
        text-primary text-sm {{ homeService.isFavouritesOnly() ? 'bg-[#DFEBFF]' : 'bg-white' }}"
        (click)="homeService.isFavouritesOnly.set(!homeService.isFavouritesOnly())">
        <i class="fa-solid fa-eye{{ homeService.isFavouritesOnly() ? '-slash' : '' }}"></i>Favourites
    </button>
</div>

<div class="absolute inset-0 z-10 border border-[#D3DBE3] rounded-lg bg-white flex items-center h-[52px] px-4 gap-4 overflow-hidden"
    [ngClass]="{'block': selectionCount() > 0, 'hidden': selectionCount() === 0}">
    <button (click)="clearSelection()"><i class="far fa-minus-square text-2xl text-primary"></i></button>

    <span class="block font-semibold flex-shrink-0 mr-[50px]">{{selectionCount()}} selected</span>

    <div class="flex gap-8">
        @if(isOrgAdmin()){
        <button class="flex items-center flex-grow-0 whitespace-pre gap-2" (click)="move.emit()">
            <svg-icon src="/assets/icons/move.svg" class="text-primary h-5 w-5" />Move</button>
        }

        @if(isSameFavouriteStatus()){
        <button class="flex items-center flex-grow-0 whitespace-pre gap-2" (click)="favourite.emit(selectedItems())">
            <i class="{{selectedItems()[0].isFavourite ? 'fas' : 'far'}} fa-star fa-regular text-primary fa-lg"></i>
            {{ selectedItems()[0].isFavourite ? 'Remove from favourites' : 'Add to favourites' }}
        </button>
        }
        <!-- @if(isOrgAdmin()){
        <button class="flex items-center flex-grow-0 whitespace-pre gap-2" (click)="delete.emit()"><i
                class="far fa-trash-alt text-primary fa-lg"></i>Delete</button>
        } -->
    </div>
    <p class="ml-auto flex-shrink-0 text-sm">Double click on a project or folder to open it</p>
</div>