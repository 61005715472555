import { Component, computed, EventEmitter, inject, OnDestroy, Output } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { DropdownModule } from 'primeng/dropdown';
import { Attribute } from '../project-form.model';
import { ProjectFormService } from '../project-form.service';
import { ApiService } from 'src/app/core';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { ButtonModule } from 'primeng/button';
import { CalculatedSettingsModalComponent } from './calculated-settings-modal.component';
import { NgClass } from '@angular/common';

type CollectionItem = {
    id: number;
    key: string;
    value: string,
    data: any,
    geometry: {
        type: string,
        coordinates: any
    }
}

export type Collection = {
    id: number;
    name: string;
    items: CollectionItem[];
}

@Component({
    selector: 'app-calculated-settings',
    templateUrl: 'calculated-settings.component.html',
    standalone: true,
    imports: [
        FormsModule,
        DropdownModule,
        ButtonModule,
        NgClass
    ],
    providers: [
        DialogService
    ]
})

export class CalculatedSettingsComponent implements OnDestroy {

    form = inject(ProjectFormService);
    apiService = inject(ApiService);
    dialogService = inject(DialogService);

    user = this.form.user;
    projectId = this.form.projectId;
    attribute = this.form.selectedAttribute;
    attributes = this.form.attributes;
    calculatedFields = this.form.calculatedFields;

    attributesForCalculatedFieldEditor = computed(() => {
        const attributes = this.attributes();
        return attributes
            .filter(a => a.type !== 'divider')
            .filter(a => !a.deleted)
            .filter(a => a.formId === this.attribute().formId);
    });

    @Output() update: EventEmitter<Partial<Attribute>> = new EventEmitter();

    calculatedFieldType = computed(() => this.attribute().type);

    calculatedFieldDisplayTypes = [
        { label: 'Text', value: 'text' },
        { label: 'Date', value: 'date' },
        { label: 'Date/time', value: 'datetime' },
        { label: 'Number', value: 'float' },
        { label: 'Yes/No', value: 'boolean' }
    ];

    private dialogRef: DynamicDialogRef;

    openCalculatedFieldEditor() {
        this.dialogRef = this.dialogService.open(CalculatedSettingsModalComponent, {
            data: {
                user: this.user(),
                projectId: this.projectId(),
                attribute: this.attribute(),
                attributes: this.attributesForCalculatedFieldEditor()
            },
            header: 'Calculated field editor',
            width: '90%',
            height: '90%',
            modal: true,
            contentStyle: { 'padding': '20px', 'overflow': 'hidden', 'background': '#F3F7FC' }
        });

        this.dialogRef.onClose.subscribe((result) => {
            if (result) {
                this.update.emit({config: result.config});
                this.calculatedFields.update((calculatedFields) => {
                    calculatedFields.set(this.attribute().id, result.attributeIds);
                    return new Map(calculatedFields);
                });
            }
        });
    }

    ngOnDestroy(): void {
        if (this.dialogRef) {
            this.dialogRef.close();
        }
    }
}