import { Component, computed, effect, ElementRef, inject, viewChild } from '@angular/core';
import { AsyncPipe, CommonModule } from '@angular/common';
import { ProjectFormService } from './project-form.service';
import { FormsModule } from '@angular/forms';
import { InputTextModule } from 'primeng/inputtext';
import { Attribute, FormSettings, QuestionId } from './project-form.model';
import { SubformSettingsComponent } from './project-form-details-settings/subform-settings.component';
import { TextSettingsComponent } from './project-form-details-settings/text-settings.component';
import { NumberSettingsComponent } from './project-form-details-settings/number-settings.component';
import { DatetimeSettingsComponent } from './project-form-details-settings/datetime-settings.component';
import { ListSettingsComponent } from './project-form-details-settings/list-settings.component';
import { ListSelectionComponent } from './project-form-details-settings/list-selection.component';
import { SliderSettingsComponent } from './project-form-details-settings/slider-settings.component';
import { GeometrySettingsComponent } from './project-form-details-settings/geometry-settings.component';
import { GeometryPickerSettingsComponent } from './project-form-details-settings/geometry-picker-settings.component';
import { CoordinateProjectionSettingsComponent } from './project-form-details-settings/coordinate-projection-settings.component';
import { ReverseGeolocationSettingsComponent } from './project-form-details-settings/reverse-geolocation-settings.component';
import { AreaLookupSettingsComponent } from './project-form-details-settings/area-lookup-settings.component';
import { PhotoSettingsComponent } from './project-form-details-settings/photo-settings.component';
import { CalculatedSettingsComponent } from './project-form-details-settings/calculated-settings.component';
import { AssociationSettingsComponent } from './project-form-details-settings/association-settings.component';
import { InputSwitchModule } from 'primeng/inputswitch';
import { TooltipModule } from 'primeng/tooltip';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { ProjectFormConditionsComponent } from './project-form-conditions.component';
import { ButtonModule } from 'primeng/button';

@Component({
    selector: 'app-project-form-details',
    templateUrl: './project-form-details.component.html',
    styleUrls: ['./project-form-details.component.scss'],
    standalone: true,
    imports: [
        CommonModule,
        AsyncPipe,
        FormsModule,
        InputTextModule,
        InputTextareaModule,
        InputSwitchModule,
        TooltipModule,
        TextSettingsComponent,
        SubformSettingsComponent,
        NumberSettingsComponent,
        DatetimeSettingsComponent,
        ListSettingsComponent,
        ListSelectionComponent,
        SliderSettingsComponent,
        GeometrySettingsComponent,
        GeometryPickerSettingsComponent,
        CoordinateProjectionSettingsComponent,
        ReverseGeolocationSettingsComponent,
        AreaLookupSettingsComponent,
        PhotoSettingsComponent,
        CalculatedSettingsComponent,
        AssociationSettingsComponent,
        ProjectFormConditionsComponent,
        ButtonModule
    ]
})
export class ProjectFormDetailsComponent {

    form = inject(ProjectFormService);

    container = viewChild<ElementRef>('container');

    attributes = this.form.attributes;
    attribute = this.form.selectedAttribute;
    question = this.form.selectedQuestion;
    attributesForConditions = this.form.attributesForConditions;
    errors = this.form.errors;

    questionId = computed(() => this.question()?.id);
    text = computed(() => this.attribute()?.text ?? '');
    label = computed(() => this.attribute()?.label ?? '');
    description = computed(() => this.attribute()?.description ?? '');
    help = computed(() => this.attribute()?.help ?? '');
    exportPath = computed(() => this.attribute()?.exportPath ?? '');
    mandatory = computed(() => this.attribute()?.required);
    hidden = computed(() => !this.attribute()?.visible);
    hasConditions = computed(() => this.attribute().conditions?.rules?.length > 0);

    childFormName = computed(() => this.attribute()?.form?.name ?? '');
    childFormTitle = computed(() => this.attribute()?.form?.title ?? '');

    showDetails = computed(() => {
        const q = this.question();
        return q.id !== 'textblock';
    });

    isChildForm = computed(() => {
        const q = this.question();
        return q.id === 'childForm';
    });

    showQuestionField = computed(() => {
        const questionsWithoutQuestionField: QuestionId[] = [
            'coordinateProjection',
            'reverseGeolocation',
            'areaLookup',
        ];
        return questionsWithoutQuestionField.indexOf(this.question().id) === -1;
    });

    showAnswerField = (() => {
        const questionsWithoutAnswerField: QuestionId[] = [
            'geometry'
        ];
        return questionsWithoutAnswerField.indexOf(this.question().id) === -1;
    });

    showExportPath = (() => {
        const questionsWithoutAnswerField: QuestionId[] = [
            'geometry'
        ];
        return questionsWithoutAnswerField.indexOf(this.question().id) === -1;
    });

    showSettings = computed(() => {
        const questionsWithSettings: QuestionId[] = [
            'childForm',
            'text',
            'number',
            'date',
            'list',
            'slider',
            'geometry',
            'geometryPicker',
            'coordinateProjection',
            'reverseGeolocation',
            'areaLookup',
            'photo',
            'calculated',
            'association'
        ];
        return questionsWithSettings.indexOf(this.question().id) !== -1;
    });

    showProperties = computed(() => {
        const questionsWithProperties: QuestionId[] = [
            'text',
            'number',
            'date',
            'yesno',
            'checkbox',
            'list',
            'slider',
            'geometry',
            'geometryPicker',
            'photo',
            'signature',
            'calculated', // hidden setting only - from old form builder
            'association' // hidden setting only - from old form builder
        ];
        return questionsWithProperties.indexOf(this.question().id) !== -1;
    });

    duplicatePathError = computed(() => {
        const formErrors = this.errors();

        if (formErrors) {
            const attributeError = formErrors[this.attribute().id];
            if (attributeError && attributeError['duplicateExportPath']) {
                return true;
            } else {
                return false;
            }
        } else {
            return false;
        }
    });

    constructor() {
        effect(() => {
            const container = this.container();
            const id = this.questionId();
            // On id change scroll to top
            if (container && id) {
                container.nativeElement.scrollTop = 0;
            }
        });
    }

    update(obj: Partial<Attribute>) {
        this.form.updateSelectedAttribute(obj);
    }

    updateAnswer(answer: string) {
        /** Add a temp path based on the label so we can use it before the form is submitted ie for setting conditions */
        const path = `${answer.toLocaleLowerCase().replace(/\s/g, '_').replace(/[^_a-zA-Z_\d]/g, '')}_${new Date().valueOf()}`;
        
        this.update({
            label: answer,
            path
        });
    }

    updateChildFormSettings(obj: Partial<FormSettings>) {
        const formSettings = this.attribute().form;
        const update = { ...formSettings, ...obj };
        this.update({ form: update });
    }

    addCondition() {
        this.update({
            conditions: {
                any: false,
                rules: [{
                    path: null
                }]
            },
        });
    }
}