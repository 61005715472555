<form class="w-full p-4 space-y-5">
    <div class="flex items-center justify-between gap-2">
        <label class="text-sm text-text-primary font-normal" for="wholeNumbersOnly">
            Use whole numbers only
        </label>
        <p-inputSwitch inputId="wholeNumbersOnly" name="wholeNumbersOnly" [ngModel]="wholeNumbersOnly()" (ngModelChange)="wholeNumbersOnlyChanged($event)" />
    </div>

    <div class="flex items-start justify-normal gap-5">
        <div class="flex flex-col gap-1">
            <label class="text-xs text-grey-70 font-normal" for="min">Minimum value</label>
            <p-inputNumber 
                inputId="min"
                name="min"
                [ngModel]="min()"
                (ngModelChange)="configChanged({min: $event})"
                placeholder="e.g. 0"
                styleClass="w-full"
                required
                #minControl="ngModel"
                [ngClass]="{'ng-dirty ng-invalid': minControl.hasError('required') || minMaxError()}" /> 
            @if (minControl.hasError('required')) {
                <small class="text-danger">Min value is required</small>
            }
        </div>
        <div class="flex flex-col gap-1">
            <label class="text-xs text-grey-70 font-normal" for="max">Maximum value</label>
            <p-inputNumber 
                inputId="max" 
                name="max"
                [ngModel]="max()"
                (ngModelChange)="configChanged({max: $event})"
                placeholder="e.g. 10"
                styleClass="w-full"
                required
                #maxControl="ngModel"
                [ngClass]="{'ng-dirty ng-invalid': maxControl.hasError('required') || minMaxError()}" /> 
            @if (maxControl.hasError('required')) {
                <small class="text-danger">Max value is required</small>
            }
        </div>
    </div>

    @if (!maxControl.hasError('required') && !minControl.hasError('required') && minMaxError()) {
        <small class="text-danger text-center">Minimum value must be less than maximum</small>
    }

    <div class="flex items-start justify-normal gap-5">
        <div class="flex flex-col gap-1">
            <label class="text-xs text-grey-70 font-normal flex items-center justify-between" for="step">
                Steps
            </label>
            <p-inputNumber 
                inputId="step"
                name="step"
                [min]="0"
                [ngModel]="step()"
                (ngModelChange)="configChanged({step: $event})"
                placeholder="Enter step size"
                styleClass="w-full" /> 
        </div>
        <div class="flex flex-col gap-1">
            <label class="text-xs text-grey-70 font-normal" for="defaultValue">Starting value</label>
            <p-inputNumber 
                inputId="defaultValue" 
                name="defaultValue"
                [min]="0"
                [ngModel]="defaultValue()"
                (ngModelChange)="configChanged({defaultValue: $event})"
                placeholder="e.g. 0"
                styleClass="w-full" /> 
        </div>
    </div>
</form>