import { Component, signal, computed, inject, viewChild } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { ButtonModule } from 'primeng/button';
import { DialogModule } from 'primeng/dialog';
import { DynamicDialogRef } from "primeng/dynamicdialog";
import { DragDropModule } from "primeng/dragdrop";
import { ImageCropperComponent } from 'ngx-image-cropper';
import { CheckboxModule } from 'primeng/checkbox';

const DEFAULT_DEMENSION = 256;

@Component({
  template: `
    @if(imageUrl()) {
      <div class="flex flex-col w-full h-full">
        <div class="flex items-center justify-center min-w-[360px] min-h-[360px] max-w-[360px] max-h-[360px] mx-auto relative">
        <image-cropper
            #cropper
            class="{{ imageLoaded() ? 'opacity-100' : 'opacity-0' }}"
            [imageURL]="imageUrl()"
            [aspectRatio]="1"
            [onlyScaleDown]="true"
            [transform]="transform()"
            [resizeToWidth]="256"
            [resizeToHeight]="256"
            alignImage="center"
            format="png"
            output="base64"
            [roundCropper] = "makeCircular"
            (imageLoaded)="imageLoaded.set(true)" />
          @if(!imageLoaded()) {
          <span class="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">
            <i class="pi pi-spin pi-spinner text-2xl"></i>
          </span>
          }
        </div>
        <div class="flex items-center py-3 justify-between">
          <div class="flex items-center gap-x-2.5">
            <i class="text-lg text-grey-50 cursor-pointer fa-solid fa-magnifying-glass-minus" (click)="zoomOut()"></i>
            <input type="range" min="0.1" max="2" step="0.1" [value]="scaleValue()" (input)="changeZoom($event)" class="flex-1"/>
            <i class="text-lg text-grey-50 cursor-pointer fa-solid fa-magnifying-glass-plus" (click)="zoomIn()"></i>
          </div>
          <div class="flex items-center justify-end gap-x-2.5">
            <label class="text-sm text-text-secondary font-normal" for="circular">Make icon circular</label>
            <p-checkbox [(ngModel)]="makeCircular" [binary]="true" inputId="circular" />
          </div>
        </div>
        <div class="border-t border-[#D3DBE3] pt-5 flex justify-end flex-none">
            <p-button type="submit" size="large" (click)="onHideDialog()">
              <i class="fa-solid fa-cloud-arrow-up"></i> 
              Upload icon
            </p-button>
        </div>
      </div>
    } @else {
      <div pDroppable (onDrop)="onDrop($event)" class="drop-area">
        <i class="text-7xl text-primary fa-solid fa-cloud-arrow-up"></i>
        <h2 class="text-base text-black text-center font-semibold leading-tight">Drag & drop an image <br>or</h2>
        <p-button type="button" size="large" (click)="fileInput.click()" label="Browse File"/>
      </div>
      <input type="file" #fileInput class="hidden" (change)="onFileSelected($event)" accept="image/*" />
    }
  `,
  selector: 'app-collection-item-pin-upload',
  standalone: true,
  imports: [
    FormsModule,
    ButtonModule,
    DialogModule,
    DragDropModule,
    ImageCropperComponent,
    CheckboxModule
  ],
  styles: [`
    ::ng-deep image-cropper > div:first-child {
        display: flex;
        justify-content: center;
    }
  `]
})
export class CollectionItemPinUploadComponent {

  ref = inject(DynamicDialogRef);

  cropper = viewChild<ImageCropperComponent>('cropper');

  imageChangedEvent: Event | null = null;
  imageUrl = signal<string | null>(null);
  scaleValue = signal<number>(1);
  transform = computed<{scale: number}>(() => ({ scale: this.scaleValue() }));
  imageLoaded = signal<boolean>(false);
  makeCircular = false;

  ngOnChanges() {
    this.imageUrl.set(null);
  }

  onDrop(event: any) {
      if (!event.dataTransfer.files || !event.dataTransfer.files.length) return;

      const file = event.dataTransfer.files[0];
      this.onFileSelected({ target: { files: [file] } });
  }

  onFileSelected(event: any) {
      const file: File = event.target.files[0];
      if (!file) return;

      const reader = new FileReader();

      reader.onload = (e: any) => e.target.result && this.imageUrl.set(e.target.result);
      reader.readAsDataURL(file);
  }

  changeZoom(event: Event) {
    const inputElement = event.target as HTMLInputElement;
    this.scaleValue.set(parseFloat(inputElement.value));
  }

  zoomOut() {
    const scale = Number((this.transform().scale - .1).toFixed(1));
    !!scale && this.scaleValue.set(scale);
  }

  zoomIn() {
    const scale = Number((this.transform().scale + .1).toFixed(1));
    scale <= 2 && this.scaleValue.set(scale);
  }

  async resizeImage(base64String: string, targetWidth: number, targetHeight: number): Promise<string> {
    return new Promise((resolve, reject) => {
      const img = new Image();
      
      img.src = base64String;
  
      img.onload = () => {
        const canvas = document.createElement('canvas');
          const ctx = canvas.getContext('2d', { willReadFrequently: true });
  
        canvas.width = targetWidth;
        canvas.height = targetHeight;

          ctx.clearRect(0, 0, targetWidth, targetHeight);

        if (this.makeCircular) {
          ctx.beginPath();
          ctx.arc(targetWidth / 2, targetHeight / 2, Math.min(targetWidth, targetHeight) / 2, 0, Math.PI * 2);
          ctx.closePath();
          ctx.clip();
        }
  
        ctx.drawImage(img, 0, 0, targetWidth, targetHeight);
  
          const resizedBase64 = canvas.toDataURL('image/png', 1.0);
  
        resolve(resizedBase64);
      };
  
      img.onerror = () => reject("Error loading image");
    });
  }

  async onHideDialog() {
    const icon = this.cropper().crop()['base64'];
    const resizeIcon = await this.resizeImage(icon, DEFAULT_DEMENSION, DEFAULT_DEMENSION);
    this.ref.close({ icon: resizeIcon });
  }
}