import { Component, Output, EventEmitter, computed, signal, ViewChild, input, Input } from "@angular/core";
import { AvatarModule } from "primeng/avatar";
import { ButtonModule } from "primeng/button";
import { OverlayPanel, OverlayPanelModule } from "primeng/overlaypanel";

@Component({
    selector: 'app-organisation-menu',
    standalone: true,
    templateUrl: './organisation-menu.component.html',
    imports: [
        OverlayPanelModule,
        ButtonModule,
        AvatarModule
    ],

    styles: [`
        :host {
            @apply block mr-3;
        }
    `]
})
export class OrganisationMenuComponent {

    @ViewChild('op') op: OverlayPanel;

    @Input() organisations: any[];
    @Input() organisationId: number;
    @Input() isSuperAdmin: boolean;

    @Output() organisation = new EventEmitter<number>;
    @Output() orgSettings = new EventEmitter<void>;
    @Output() orgMembers = new EventEmitter<void>;
    @Output() orgProjects = new EventEmitter<void>;

    currentId = signal<number>(undefined);
    currentOrganisation = computed(() => (this.organisations ?? []).find(o => o.id === this.currentId()));
    count = signal<number>(0);

    ngOnInit() {
        this.currentId.set(this.organisationId);
        this.count.set((this.organisations || []).length);
    }

    ngOnChanges() {
        this.currentId.set(this.organisationId);
        this.count.set((this.organisations || []).length);
    }

    toggleOverlay(event: Event) {
        this.op.toggle(event);
    }
    switchOrganisation(id: number) {
        this.organisation.emit(id);
        this.op.hide();
    }

    handleOrgSettings() {
        this.orgSettings.emit();
        this.op.hide();
    }

    handleOrgMembers() {
        this.orgMembers.emit();
        this.op.hide();
    }

    handleOrgProjects() {
        this.orgProjects.emit();
        this.op.hide();
    }
}