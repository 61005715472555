<div class="w-full rounded-[4px] border p-3 pl-0 flex items-center justify-start gap-[9px]" [ngClass]="{'border-danger': hasErrors(), 'border-grey-50': !hasErrors()}">
    <div class="px-3 flex-none flex items-center justify-center">
        <svg-icon src="/assets/icons/eye-off.svg" class="h-4 w-4 text-grey-50"></svg-icon>
    </div>
    <div class="text-text-primary text-[10px] leading-[14px]">
        <p>{{ attribute()?.type === 'text' ? 'Text' : 'Question' }} is hidden unless {{ any() ? 'any' : 'all' }} of the following conditions are met:</p>
        <ul class="font-semibold">
            @for(rule of rules(); track $index){
                <app-project-form-conditions-summary-rule [rule]="rule"></app-project-form-conditions-summary-rule>
            }
        </ul>
    </div>
</div>