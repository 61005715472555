import { ChangeDetectionStrategy, Component, EventEmitter, Output, input } from "@angular/core";
import { ButtonModule } from "primeng/button";

@Component({
  selector: 'app-collection-map-edit-button',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    ButtonModule
  ],
  template: `
  @if (!editing()) {
    <button class="absolute bottom-8 right-5 h-14 w-14 rounded-full flex items-center justify-center bg-primary shadow text-white text-lg" (click)="startEditing()">
    @if (create()) {
        <i class="fa-solid fa-plus"></i>
    }
    @else {
        <i class="fa-solid fa-pen"></i>
    }
    </button>
  } @else {
    <div  class="absolute bottom-8 right-5 flex gap-3 z-50">
      @if(hasEdits()){
        <p-button label="Discard Changes" [outlined]="true" (click)="stopEditing(false)"></p-button>
        <p-button label="Save Changes" (click)="stopEditing(true)"></p-button>
      }  @else {
        <p-button label="Stop Editing" (click)="stopEditing(false)"></p-button>
      }
    </div>
  }`,
  standalone: true
})
export class CollectionMapEditButtonComponent {

    hasEdits = input(false);
    editing = input(false);
    create = input(false);

    @Output() start: EventEmitter<void> = new EventEmitter();
    @Output() stop: EventEmitter<boolean> = new EventEmitter();

    startEditing() {
        this.start.emit();
    }

    stopEditing(save: boolean) {
        this.stop.emit(save);
    }
}
