import { Component, OnInit, Input, Output, EventEmitter, inject } from '@angular/core';
import { ReactiveFormsModule, FormControl, FormGroup, Validators } from "@angular/forms";
import { ToastModule } from "primeng/toast";
import { PanelModule } from 'primeng/panel';
import { MessageService } from "primeng/api";
import { ButtonModule } from 'primeng/button';
import { CalendarModule } from 'primeng/calendar';
import { InputTextModule } from 'primeng/inputtext';
import { DropdownModule } from 'primeng/dropdown';
import { TransferOrganisationComponent } from './transfer-organisation.component';
import { ApiService } from "src/app/core";
import { OrganisationInfoSchema } from '../../../core/models/organisation.model';
import { DialogService, DynamicDialogModule } from 'primeng/dynamicdialog';

@Component({
    selector: 'app-admin-organisation',
    templateUrl: './admin-organisation.component.html',
    imports: [
        ReactiveFormsModule,
        ToastModule,
        PanelModule,
        ButtonModule,
        CalendarModule,
        InputTextModule,
        DropdownModule,
        DynamicDialogModule
    ],
    standalone: true,
    styles: [
        `:host{ @apply block h-full w-full overflow-scroll p-8;}`
    ],
    providers: [MessageService, DialogService]
})
export class AdminOrganisationComponent implements OnInit {

    private apiService = inject(ApiService);
    private messageService = inject(MessageService);
    private dialogService = inject(DialogService);

    @Input() organisationId: number;

    @Output() dashboard: EventEmitter<number> = new EventEmitter();
    @Output() members: EventEmitter<number> = new EventEmitter();
    @Output() settings: EventEmitter<number> = new EventEmitter();
    @Output() update: EventEmitter<number> = new EventEmitter();

    organisationForm = new FormGroup({
        name: new FormControl('', [Validators.required]),
        slug: new FormControl('', [Validators.required]),
        seats: new FormControl<number>(0, [Validators.required]),
        ukhabSeats: new FormControl<number>(0, [Validators.required]),
        customerId: new FormControl(''),
        subscriptionId: new FormControl(''),
        contributorSeats: new FormControl<number>(0, [Validators.required]),
        organisationTierId: new FormControl<number | null>(null, [Validators.required]),
        renewalDate: new FormControl<Date | null>(null),
        freeTrialEnd: new FormControl<Date | null>(null)
    });

    organisation: any = null;
    organisationTiers: any = null;

    seatsFilled: number = 0;

    isVisibleDialog: boolean = false;
    subscriptionDataUpdated: boolean = false;

    private readonly getOrganisationQuery: string = `query getAdminOrganisation($id: Int!) {
    organisationTiers { id name }
    organisation(id: $id) { id, name, slug, seats, ukhabSeats, customerId, subscriptionId, contributorSeats, freeTrialEnd, renewalDate, organisationTierId,
        memberships { id },
        owner { id, displayName }
      }
    }`;

    ngOnInit() {
        this.initOrganisationData();
    }

    initOrganisationData() {
        this.apiService.graphql(this.getOrganisationQuery, { id: this.organisationId })
            .subscribe(response => {
                const parsedResponse = OrganisationInfoSchema.parse(response);
                const { name, slug, seats, ukhabSeats, customerId, subscriptionId, renewalDate, contributorSeats, freeTrialEnd, memberships } = this.organisation = parsedResponse.organisation;
                this.seatsFilled = memberships.length;
                this.organisationTiers = parsedResponse.organisationTiers;
                const tier = this.organisationTiers.find(tier => this.organisation.organisationTierId === tier.id);
                this.organisationForm.setValue({ 
                    name, 
                    slug, 
                    seats,
                    ukhabSeats: Number(ukhabSeats),
                    customerId,
                    subscriptionId,
                    contributorSeats,
                    organisationTierId: tier.id, 
                    renewalDate: renewalDate ? new Date(renewalDate) : null,
                    freeTrialEnd: freeTrialEnd ? new Date(freeTrialEnd) : null 
                });
            });
    }

    getDirtyValues() {
        const subscriptionFields = ['seats', 'ukhabSeats', 'customerId', 'subscriptionId', 'organisationTierId'];
        const dirtyValues = {};
        Object.keys(this.organisationForm.controls).forEach(key => {
            const currentControl = this.organisationForm.controls[key];
            if (currentControl.dirty) {
                dirtyValues[key] = currentControl.value;
                if (subscriptionFields.includes(key) && !this.subscriptionDataUpdated) {
                    this.subscriptionDataUpdated = true;
                }
            }
        });
        return dirtyValues;
    }

    onSubmit() {
        const input = this.getDirtyValues();
        const query = this.subscriptionDataUpdated
            ? `mutation updateOrganisation($input: OrganisationUpdateInput!) {
                    result: updateOrganisation(input: $input) {
                        id
                        customerId
                        subscriptionId
                        seats
                        ukhabSeats
                        tier {
                            id
                            monthlyPrice
                            name
                            yearlyPrice
                        }
                        subscription {
                            interval
                            isCancelled
                            isExpired
                            isPastDue
                            periodEnd
                            periodStart
                            scheduledChanges {
                                pendingTier
                                pendingSeatQuantity
                                pendingLicenseQuantity
                                scheduleStart
                                scheduleEnd
                            }
                        }
                    }
                }`
            : `mutation updateOrganisation($input: OrganisationUpdateInput!) {
                    result: updateOrganisation(input: $input) {
                        id
                    }
                }`;
        this.apiService.graphql<any>(query, {
            input: {
                id: this.organisationId,
                ...input
            }
        })
            .subscribe((response) => {
                this.initOrganisationData();
                this.organisationForm.markAsPristine();
                if (this.subscriptionDataUpdated) {
                    this.update.emit(response.result);
                } else {
                    this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Organisation updated' });
                }
            },
                (error) => {
                    console.error(error);
                    this.organisationForm.markAsPristine();
                    this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Organisation failed to update' });
                });
    }

    openTransferDialog(event: Event) {
        event.preventDefault();
        event.stopPropagation();

        const ref = this.dialogService.open(TransferOrganisationComponent, {
            header: 'Transfer ownership',
            data: { organisationId: this.organisationId }
        });

        ref.onClose.subscribe((result) => {
            if (result) {
                this.initOrganisationData();
                this.organisationForm.reset();
            }
            console.log('Dialog result:', result);
        });
    }

    onVisibleChange(event: { isVisibleDialog: boolean; isTransfered: boolean }) {

        // this.isVisibleDialog = event.isVisibleDialog;
        // if (!event.isTransfered) return;
        // this.initOrganisationData();
        // this.organisationForm.reset();
    }

    goToDashboard() {
        this.dashboard.emit(this.organisationId);
    }

    goToMembers() {
        this.members.emit(this.organisationId);
    }

    goToSettings() {
        this.settings.emit(this.organisationId);
    }
}