import { Component, effect, ElementRef, inject, OnDestroy, viewChild, ViewContainerRef } from '@angular/core';
import { CollectionItemService } from './collection-item.service';
import { MapsService } from '@core/services/maps.service';
import mapboxgl, { LngLatBoundsLike, LngLatLike, Map as MapboxMap, MapOptions } from 'mapbox-gl';
import { environment } from 'src/environments/environment';
import { Feature, Geometry } from 'geojson';
import bbox from '@turf/bbox';
import { CollectionMapEditButtonComponent } from '../collection/gis/collection-map-edit-button.component';
import { CoreoGeometryType, MapboxGISMode } from '@natural-apptitude/coreo-mapbox/dist/types';
import MapboxGIS from '@natural-apptitude/coreo-mapbox';
import { fromEvent, Subject, takeUntil } from 'rxjs';
import { SvgIconComponent } from 'angular-svg-icon';

@Component({
    selector: 'app-collection-item-map',
    template: `
    <div class="absolute inset-0 bg-white">
        <div #collectionItemMap class="w-full h-full"></div>
        <app-collection-map-edit-button [editing]="isGeometryEditing()" [hasEdits]="hasGeometryEdits()" [create]="!geometry()" (start)="startEditing()" (stop)="stopEditing($event)" />
        @if (mapLoading) {
            <div class="h-full w-full flex items-center justify-center z-10">
                <svg-icon src="/assets/icons/spinner.svg" class="h-10 w-10"></svg-icon>
            </div>
        }
    </div>`,
    standalone: true,
    imports: [
        CollectionMapEditButtonComponent,
        SvgIconComponent
    ]
})

export class CollectionItemMapComponent implements OnDestroy {

    collectionItemService = inject(CollectionItemService);
    mapsService = inject(MapsService);
    viewContainerRef = inject(ViewContainerRef);

    collectionItemMap = viewChild<ElementRef>('collectionItemMap');

    destroy$ = new Subject<void>();

    geometry = this.collectionItemService.geometry;
    isGeometryEditing = this.collectionItemService.isGeometryEditing;
    hasGeometryEdits = this.collectionItemService.hasGeometryEdits;
    mapLoading = this.collectionItemService.geometryLoading;

    private map: MapboxMap;
    private resizeObserver: ResizeObserver;
    gis: MapboxGIS | undefined;

    constructor() {
        effect(() => {
            const geometry = this.geometry();
            const map = this.collectionItemMap();
            if (!!geometry && !!map) {
                this.initMap();
            } else if (!geometry && !!map) {
                this.initEmptyMap();
            }
        });
    }

    private initMap() {
        const mapOptions: MapOptions = {
            accessToken: environment.mapboxApiKey,
            container: this.collectionItemMap().nativeElement,
            style: 'mapbox://styles/mapbox/streets-v12',
            projection: { name: 'mercator' },
        };

        const feature: Feature<Geometry, any> = {
            type: 'Feature',
            geometry: this.geometry(),
            properties: {}
        };

        if (feature.geometry.type === "Point") {
            mapOptions.center = feature.geometry.coordinates as LngLatLike;
            mapOptions.zoom = 16;
        } else {
            const bounds = bbox(feature.geometry);
            mapOptions.bounds = bounds as LngLatBoundsLike;
            mapOptions.fitBoundsOptions = { padding: 20 };
        }

        this.map = new MapboxMap(mapOptions);

        this.map.on('style.load', () => {
            this.map.addSource('item', {
                type: 'geojson',
                data: {
                    type: 'FeatureCollection',
                    features: [feature]
                }
            });

            if (feature.geometry.type === 'Point') {
                this.map.addLayer({
                    id: 'point',
                    source: 'item',
                    type: 'circle',
                    filter: [
                        'all',
                        ['!has', 'pc'],
                        ['==', '$type', 'Point']
                    ],
                    paint: {
                        'circle-color': '#C521E0',
                        'circle-radius': 10,
                        'circle-opacity': 0.5,
                        'circle-stroke-width': 1,
                        'circle-stroke-color': '#FFFFFF'
                    }
                });
            } else if (feature.geometry.type === 'LineString') {
                this.map.addLayer({
                    id: 'line',
                    source: 'item',
                    type: 'line',
                    paint: {
                        'line-color': '#F02F1D',
                        'line-width': 3
                    },
                    'filter': ['==', '$type', 'LineString']
                });
            } else if (feature.geometry.type === 'Polygon' || feature.geometry.type === 'MultiPolygon') {
                this.map.addLayer({
                    id: 'polygon-fill',
                    source: 'item',
                    type: 'fill',
                    paint: {
                        'fill-color': '#ff0000',
                        'fill-opacity': 0.3
                    },
                    'filter': ['==', '$type', 'Polygon']
                });
                this.map.addLayer({
                    id: 'polygon-outline',
                    source: 'item',
                    type: 'line',
                    paint: {
                        'line-color': '#FFFFFF',
                        'line-width': 1
                    },
                    'filter': ['==', '$type', 'Polygon']
                });
            }
        });

        this.map.scrollZoom.disable();
        this.map.addControl(new mapboxgl.NavigationControl());
        this.addFullscreenControl();

        this.resizeObserver = new ResizeObserver(() => this.map.resize());
        this.resizeObserver.observe(this.collectionItemMap().nativeElement);

        this.map.on('resize', () => {
            if (document.fullscreenElement) {
                this.map.scrollZoom.enable();
            } else {
                this.map.scrollZoom.disable();
            }
        });
    }

    startEditing() {
        let feature = null;
        let geometryType: CoreoGeometryType = 'Polygon';
        let mode: MapboxGISMode = 'create';
        let modes: MapboxGISMode[] = ['create', 'update', 'delete'];

        if (!!this.geometry()) {
            feature = {
                type: 'Feature',
                geometry: this.geometry(),
                properties: {}
            };
            geometryType = (feature.geometry.type as CoreoGeometryType);
            mode = 'update';
        }

        this.gis = new MapboxGIS({
            geometryType,
            allowedGeometryTypes: ['Polygon', 'MultiPolygon', 'LineString', 'MultiLineString', 'Point', 'MultiPoint'],
            modes,
            mode,
            feature,
            interface: 'desktop',
            toolbarControl: false,
            typeControl: true,
            buttonsControl: false,
            logging: false
        });
    
        this.collectionItemService.startMapEditing();
    
        fromEvent(this.gis, 'geometry').pipe(takeUntil(this.destroy$)).subscribe((e: any) => {
            if (e?.geometry) {
                this.collectionItemService.updateEditedGeometry(e.geometry);
            }
        });
    
        this.map.addControl(this.gis as any);
    }

    clearEditing() {
        if (this.gis) {
          this.map.removeControl(this.gis as any);
        }
        this.gis = undefined;
    }

    stopEditing(save: boolean) {
        this.collectionItemService.stopMapEditing(save);
        this.clearEditing();
    }

    addFullscreenControl() {
        this.map.addControl(new mapboxgl.FullscreenControl(), 'top-left');
    }

    private initEmptyMap() {
        const mapOptions: MapOptions = {
            accessToken: environment.mapboxApiKey,
            container: this.collectionItemMap().nativeElement,
            style: 'mapbox://styles/mapbox/streets-v12',
            projection: { name: 'mercator' },
        };

        const defaultBounds = [
            2.0153808593734936, 56.6877748258257,
            -7.0153808593762506, 47.45206245445874
        ];
        mapOptions.bounds = defaultBounds as LngLatBoundsLike;
        mapOptions.fitBoundsOptions = { padding: 20 };

        this.map = new MapboxMap(mapOptions);        
    }

    ngOnDestroy() {
        this.stopEditing(false);
        this.destroy$.next();
        this.destroy$.complete();
    }
}