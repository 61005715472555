import { Injectable, computed, inject, signal } from "@angular/core";
import { NG_REDUX } from "@core/core.module";
import ngRedux from 'ng-redux';
import { getProjectOrgFeatures } from "src/store/selectors";

@Injectable({
    providedIn: 'root'
})
export class ReduxService {
    private $ngRedux = inject<ngRedux.INgRedux>(NG_REDUX);
    state = signal<any>(this.$ngRedux.getState());

    constructor() {
        this.$ngRedux.subscribe(() => this.state.set(this.$ngRedux.getState()));

    }
}
