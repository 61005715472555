import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
    name: "currency",
    standalone: true
})
export class CurrencyPipe implements PipeTransform {
    transform(amount: number, decimal: number = 0): string {
        return Intl.NumberFormat("en-GB", {
            style: "currency",
            currency: "GBP",
            minimumFractionDigits: decimal,
            maximumFractionDigits: decimal,
        }).format(amount);
    }
}