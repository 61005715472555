<form class="w-full p-4 space-y-5">
    <div class="flex flex-col gap-1">
        <label class="text-sm text-text-primary font-normal" for="projections">Projection <sup>*</sup></label>
        <form>
            <p-dropdown [options]="projectionOptions" [ngModel]="projection()" (ngModelChange)="update.emit({config: {projection: $event}})" name="projections" optionLabel="label" optionValue="value" [showClear]="false" placeholder="Choose a projection" required #projectionControl="ngModel" [ngClass]="{'ng-dirty ng-invalid': projectionControl.hasError('required')}"></p-dropdown>
            @if(projectionControl.hasError('required')){
                <small class="text-danger">Projection is required</small>
            }
        </form>
    </div>
</form>