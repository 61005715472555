import { NgClass } from '@angular/common';
import { Component, computed, forwardRef, HostBinding, HostListener, inject, input, signal } from '@angular/core';
import { ProjectFormBuilderComponent } from './project-form-builder.component';
import { ProjectFormConditionsSummaryComponent } from './project-form-conditions-summary.component';
import { Attribute, QuestionId } from './project-form.model';
import { attributeDeleteNoticePreferenceKey, attributeDeleteNoticePreferenceVersion, ProjectFormService } from './project-form.service';
import { ButtonModule } from 'primeng/button';
import { ProjectFormSettingsModalComponent } from './project-form-settings-modal.component';
import { DialogService } from 'primeng/dynamicdialog';
import { SvgIconComponent } from 'angular-svg-icon';
import { EditorModule, EditorTextChangeEvent } from 'primeng/editor';
import { FormsModule } from '@angular/forms';
import { ConfirmationService } from 'primeng/api';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ApiService } from '@core/services';
import { catchError, of, take } from 'rxjs';

@Component({
    selector: 'app-project-form-attribute',
    templateUrl: './project-form-attribute.component.html',
    standalone: true,
    imports: [
        ProjectFormConditionsSummaryComponent,
        NgClass,
        FormsModule,
        forwardRef(() => ProjectFormBuilderComponent),
        ButtonModule,
        SvgIconComponent,
        EditorModule,
        ConfirmDialogModule
    ],
    providers: [
        DialogService,
        ConfirmationService
    ],
    styleUrls: ['./project-form-attribute.component.scss']
})
export class ProjectFormAttributeComponent {

    form = inject(ProjectFormService);
    dialogService = inject(DialogService);
    confirmationService = inject(ConfirmationService);
    apiService = inject(ApiService);

    attribute = input.required<Attribute>();

    selectedAttributeId = this.form.selectedAttributeId;
    errors = this.form.errors;
    conditionRulePaths = this.form.conditionRulePaths;
    attributesInCalculatedFields = this.form.attributesUsedInCalculatedFields;

    question = computed(() => {
        const q = this.attribute();
        return this.form.getQuestion(q);
    });

    isDivider = computed(() => {
        const attribute = this.attribute();
        return attribute.type === 'divider';
    });

    isGeometry = computed(() => {
        const attribute = this.attribute();
        return attribute.questionType === 'geometry';
    });

    isFirst = computed(() => {
        const attribute = this.attribute();
        const attributes = this.form.attributes()
            .filter(a => a.formId === attribute.formId)
            .sort((a, b) => a.order - b.order);
        const index = attributes.findIndex(a => a.id === attribute.id);
        return index === 0;
    });

    isLast = computed(() => {
        const attribute = this.attribute();
        const attributes = this.form.attributes()
            .filter(a => a.formId === attribute.formId)
            .sort((a, b) => a.order - b.order);
        const index = attributes.findIndex(a => a.id === attribute.id);
        return index === attributes.length - 1;
    });
    
    isChildForm = computed(() => {
        const attribute = this.attribute();
        return attribute.questionType === 'child';
    });

    childFormHasAttributes = computed(() => {
        const attributes = this.form.attributes();
        return attributes.some(a => a.formId === this.childFormId);
    });

    openChildFormBuilder = signal(true);

    showChildFormBuilder = computed(() => {
        const hasAttributes = this.childFormHasAttributes();
        const open = this.openChildFormBuilder();

        if (!hasAttributes) {
            return true;
        } else {
            return open;
        }
    });

    isTextBlock = computed(() => {
        const attribute = this.attribute();
        return attribute.questionType === 'text';
    });

    text = computed(() => {
        const attribute = this.attribute();
        const question = this.question();
        const questionsWithoutText: QuestionId[] = [
            'coordinateProjection',
            'reverseGeolocation',
            'areaLookup',
        ];
        if (questionsWithoutText.indexOf(question.id) !== -1) {
            return question.label;
        } else {
            return attribute.text;
        }
    });

    isMandatory = computed(() => {
        const attribute = this.attribute();
        return attribute.required;
    });
    
    hasDescription = computed(() => {
        const attribute = this.attribute();
        return !!attribute.description;
    });
    
    hasConditions = computed(() => {
        const attribute = this.attribute();
        return attribute.conditions?.rules?.length > 0;
    });

    hasError = computed(() => {
        const errors = this.errors();

        if (errors) {
            const errors = Object.keys(this.errors());
            return errors.includes(`${this.attribute().id}`);
        } else {
            return false;
        }
    });

    selected = computed(() => this.attribute().id === this.selectedAttributeId());

    deleted = computed(() => this.attribute().deleted);
    
    childFormId: number;

    @HostBinding('class.divider') get divider() { return this.isDivider(); }
    @HostBinding('class.geometry') get geometry() { return this.isGeometry(); }
    @HostBinding('class.child-form') get child() { return this.isChildForm(); }
    @HostBinding('class.selected') get isSelected() { return this.selected(); }
    @HostBinding('class.deleted') get isDeleted() { return this.deleted(); }
    @HostBinding('class.error') get error() { return this.hasError(); }
    
    @HostListener('click', ['$event'])
    select(event: Event) {
        event.preventDefault();
        event.stopPropagation();
        if (this.deleted()) {
            return;
        }
        this.form.selectAttribute(this.attribute().id);
    }
    
    ngOnInit() {
        this.childFormId = this.attribute().associatedSurveyId;
    }

    private confirm(header: string, message: string, accept: Function) {
        this.confirmationService.confirm({
            header,
            message,
            acceptLabel: "Delete",
            rejectLabel: "Cancel",
            acceptIcon: "none",
            rejectIcon: "none",
            acceptButtonStyleClass: "p-button p-button-lg p-button-danger",
            rejectButtonStyleClass: "p-button p-button-lg p-button-outlined",
            defaultFocus: "none",
            closeOnEscape: false,
            accept: () => {
                accept();
            }
        });
    }

    private deleteGeometryCheck() {
        /** Check if the form the geometry question is in contains any questions depending on it */
        const dependents = this.form.attributes()
            .filter(a => a.formId === this.attribute().formId)
            .filter(a => {
                return (
                    a.type ==='coordinatetransform' ||
                    a.type === 'rgeolocation' ||
                    a.type === 'geometryquery'
                )
            })
            .filter(a => !a.deleted);

        if (dependents.length) {
            const header = 'Delete location question?';

            const message = `This form contains ${dependents.length > 1 ? '' : 'a'} question${dependents.length > 1 ? 's' : ''} which depend on this location question.<br><br>Deleting the location question will also delete the dependent question${dependents.length > 1 ? 's' : ''}.<br><br>Dependent questions will need to be restored manually if the location question is restored.`;

            const accept = () => {
                const attributesToDelete = [this.attribute().id, ...dependents.map(d => d.id)];
                attributesToDelete.forEach(a => this.form.deleteAttribute(a));
                this.form.selectedAttributeId.set(null);
            };

            this.confirm(header, message, accept);
        } else {
            this.delete();
        }
    }

    private usedInConditionRuleCheck() {
        const header = 'Delete question?';

        const message = `This form contains conditions that depend on this question.<br><br>Deleting this question will remove any conditions associated with it.<br><br>They will be restored if this question is restored.`;

        const accept = () => {
            this.delete();
        };

        this.confirm(header, message, accept);
    }

    private getCalculatedFieldQuestions(): string[] {
        /** Get calculated field question names */
        const calculatedFields = this.form.calculatedFields();
        const keys = calculatedFields.keys();
        const questions = [];
        for (const key of keys) {
            const ids = calculatedFields.get(key);
            const question = this.form.attributes().find(a => a.id === key).text;
            if (ids.includes(this.attribute().id)) {
                if (!questions.includes(question)) {
                    questions.push(question);
                }
            }
        }
        return questions;
    }

    private usedInCalculatedFieldCheck() {
        const header = 'Delete question?';
        const questions = this.getCalculatedFieldQuestions();
        const message = `This question is being used in the <strong>${questions.join(', ')}</strong> calculated field question${questions.length === 1 ? '' : 's'}.<br><br>Deleting this question will break the calculated field${questions.length === 1 ? '' : 's'}. You will need to manually update the calculated field questions${questions.length === 1 ? '' : 's'} to reflect this change.`;

        const accept = () => {
            this.delete();
        };

        this.confirm(header, message, accept);
    }

    private usedCalculatedFieldsAndConditionsCheck() {
        const header = 'Delete question?';
        const questions = this.getCalculatedFieldQuestions();
        const message = `This question is being used in the <strong>${questions.join(', ')}</strong> calculated field question${questions.length === 1 ? '' : 's'} and in question conditions.<br><br>Deleting this question will break the calculated field${questions.length === 1 ? '' : 's'}. You will need to manually update the calculated field questions${questions.length === 1 ? '' : 's'} to reflect this change.<br><br>Deleting this question will also remove any conditions associated with it. Conditions will be restored if this question is restored.`;

        const accept = () => {
            this.delete();
        };

        this.confirm(header, message, accept);
    }

    deleteDivider(event: Event) {
        event.preventDefault();
        event.stopPropagation();
        this.form.deleteDivider(this.attribute().id);
    }

    deleteNoticeCheck(event: Event) {
        event.preventDefault();
        event.stopPropagation();

        const deleted = this.form.canImmediatelyDelete(this.attribute());

        if (deleted) {
            return;
        } else {
            const showNotice = this.form.showAttributeDeleteNotice();
            
            if (showNotice) {
                const message = `<p>As you have filled in some information attached to this question it will not be immediately deleted. You can restore the question at anytime using the <span class="inline-block -rotate-90 scale-150 text-grey-50">&#8634;</span>&#160;&#160;icon.</p><br>
                <p>The question will be permanently deleted once you save the&nbsp;form.</p>`;
    
                this.confirmationService.confirm({
                    target: event.target as EventTarget,
                    message,
                    header: 'Deleting questions',
                    acceptLabel: "Continue",
                    acceptIcon: "none",
                    acceptButtonStyleClass: "p-button p-button-lg",
                    rejectVisible: false,
                    defaultFocus: "none",
                    closeOnEscape: false,
                    accept: () => {
                        this.setNoticeSeen();
                        setTimeout(() => {
                            this.deleteCheck();
                        }, 300);
                    }
                });
            } else {
                this.deleteCheck();
            }
        }
    }

    private setNoticeSeen() {
        const query = `mutation AAProjectFormUpdateUserPreferences($input: UpdatePreferences!){
            updateUserPreferences(input: $input){
                preferences
            }
        }`;
        const input = {
            preferences: {
                [attributeDeleteNoticePreferenceKey]: attributeDeleteNoticePreferenceVersion
            }
        }

        this.apiService.graphql(query, { input }).pipe(
            take(1),
            catchError((e) => {
                console.warn(e);
                return of(null);
            })
        ).subscribe(res => {
            if (!!res) {
                this.form.showAttributeDeleteNotice.set(false);
            }
        });
    }

    private deleteCheck() {
        const { id, path} = this.attribute();
        if (this.isGeometry()) {
            this.deleteGeometryCheck();
        } else if (this.attributesInCalculatedFields().includes(id) && this.conditionRulePaths().includes(path)) {
            this.usedCalculatedFieldsAndConditionsCheck();
        } else if (this.attributesInCalculatedFields().includes(id)) {
            this.usedInCalculatedFieldCheck();
        } else if (this.conditionRulePaths().includes(path)) {
            this.usedInConditionRuleCheck();
        } else {
            this.delete();
        }
    }
    
    private delete() {
        this.form.deleteAttribute(this.attribute().id);
        this.form.selectedAttributeId.set(null);
    }

    restoreGeometryDependentsCheck() {
        const locationQuestion = this.form.attributes()
            .filter(a => a.formId === this.attribute().formId)
            .find(a => a.questionType === 'geometry');

        if (locationQuestion && !locationQuestion.deleted) {
            /** Form has a n active location question can restore */
            this.form.restoreAttribute(this.attribute().id);
        } else {
            /** Form doesn't have a location question or it is marked as deleted, prevent restoring the question and show alert */
            this.confirmationService.confirm({
                message: `This question depends on the form having an active location question.<br><br>You will need to restore the location question before you can restore this question.`,
                header: 'Unable to restore',
                acceptLabel: "Continue",
                rejectVisible: false,
                acceptIcon: "none",
                acceptButtonStyleClass: "p-button p-button-lg",
                defaultFocus: "none",
                closeOnEscape: false,
            });
        }
    }

    restore() {
        const type = this.attribute().type;
        if (
            type ==='coordinatetransform' ||
            type === 'rgeolocation' ||
            type === 'geometryquery'
        ) {
            this.restoreGeometryDependentsCheck();
        } else {
            this.form.restoreAttribute(this.attribute().id);
        }
    }

    updateTextBlock(event: EditorTextChangeEvent) {
        this.form.updateSelectedAttribute({ text: event.htmlValue });
    }

    subFormSettings() {
        if (this.deleted()) {
            return;
        }
        /** Ensure attribute is selected when click open settings */
        this.form.selectAttribute(this.attribute().id);
        
        const { titleAttributeId, secondaryTitleAttributeId, thankyou, allowMemberUpdate, allowOwnRecordDelete, isPrivate, visible } = this.attribute().form;

        this.dialogService.open(ProjectFormSettingsModalComponent, {
            header: 'Child form settings',
            data: {
                isChildForm: true,
                formId: this.childFormId,
                settings: {
                    titleAttributeId,
                    secondaryTitleAttributeId,
                    thankyou,
                    allowMemberUpdate,
                    allowOwnRecordDelete,
                    isPrivate,
                    visible
                }
            },
            modal: true,
            width: '640px'
        });
    }
}