
import { AsyncPipe, NgClass, NgFor } from '@angular/common';
import { Component, ElementRef, computed, inject, viewChildren } from '@angular/core';
import { ProjectFormService } from './project-form.service';
import { Question, QuestionCategory } from './project-form.model';
import Sortable from 'sortablejs';
import { SvgIconComponent } from 'angular-svg-icon';
import { TooltipModule } from 'primeng/tooltip';
import { MessageService } from 'primeng/api';
import { ToastModule } from 'primeng/toast';

interface FormQuestionGroup {
    id: string;
    name: string;
    questions: Question[];
}

@Component({
    selector: 'app-project-form-questions',
    templateUrl: './project-form-questions.component.html',
    styleUrls: ['./project-form-questions.component.scss'],
    standalone: true,
    imports: [
        NgFor,
        AsyncPipe,
        NgClass,
        SvgIconComponent,
        TooltipModule,
        ToastModule
    ],
    providers: [
        MessageService
    ]
})
export class ProjectFormQuestionsComponent {

    form = inject(ProjectFormService);
    messageService = inject(MessageService);

    lists = viewChildren<ElementRef>('questions');

    selectedAttribute = this.form.selectedAttribute;
    projectSurveyIds = this.form.projectSurveyIds;
    attributes = this.form.attributes;

    questions = computed<any>(() => {
        let questions;
        /** If project only has one form remove the linked form/association question */
        if (this.projectSurveyIds().length === 1) {
            questions = this.form.questions().filter(q => q.questionType !== 'association');
        } else {
            questions = this.form.questions();
        }

        const categories: [string, QuestionCategory][] = [
            ['Form Design', 'form-design'],
            ['Basic Answers', 'basic'],
            ['Choices', 'choices'],
            ['Geometry', 'geometry'],
            ['Attach', 'attach'],
            ['Advanced', 'advanced']
        ];

        const grouped: FormQuestionGroup[] = categories.map(([name, category]) => {
            return {
                id: category,
                name,
                questions: questions.filter(q => q.category === category)
            }
        });
        return grouped;
    });

    disablePageDivider = computed(() => {
        return this.attributes().length < 1;
    });

    ngAfterViewInit() {
        for (const list of this.lists()) {
            const questionList = Sortable.create(list.nativeElement, {
                group: {
                    name: 'questions',
                    put: false,
                    pull: 'clone',
                    revertClone: true
                },
                sort: false,
                animation: 150,
                filter: '.filtered',
                ghostClass: "sortable-ghost",
                setData: (dataTransfer, dragEl) => {
                    dataTransfer.setData('Text', dragEl.getAttribute('qid'));
                }
            });
        }
    }

    add(question: Question) {
        let formId = this.form.formId();
        if (this.selectedAttribute()) {
            /** associatedSurveyId means it's a child form so add the question to that form, else use the selectedAttribute formId to put the question in the form the user is in (top level or child or nested child) */ 
            formId = this.selectedAttribute().associatedSurveyId ?? this.selectedAttribute().formId;
        }

        if (question.id ==='childForm') {
            /** If trying to add a child form check to see if allowed - maximum four forms deep */
            if(this.form.limitChildForms(formId)) {
                return;
            }
        }

        /** If geometry only allow one per form*/
        if (question.id === 'geometry' && this.form.hasGeometry(formId)) {
            return;
        }


        /** Don't allow geometry questions that are dependent on a location question if no location question in the form */
        if (question.id === 'areaLookup' || question.id === 'coordinateProjection' || question.id === 'reverseGeolocation') {
            /** Ensure we are only checking attributes in the current form */
            const attributes = this.form.attributes().filter(a => a.formId === formId);
            if (!attributes.some(a => a.questionType === 'geometry')) {
                this.addGeometryError(question.title);
                return;
            }
        }

        const attributeId = this.form.addAttribute(question.id, formId);
        this.form.selectAttribute(attributeId);
    }

    private addGeometryError(question: string) {
        this.messageService.add({ 
            severity: 'error',
            summary: `Unable to add ${question} question`,
            detail: 'The form requires a Location / Geometry question first.'
        });
    }
}