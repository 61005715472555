import { computed, inject, Injectable, signal } from '@angular/core';
import { keyBy } from 'lodash';
import { catchError, map, Observable, of, take } from 'rxjs';
import { v4 as uuid } from 'uuid';
import { ApiService } from '../../../core/services/api.service';
import { SubformConfig } from './project-form-details-settings/subform-settings.component';
import { Attribute, AttributeConditionRule, CoreoAttribute, FormCollection, FormCollectionItem, FormSettings, QuestionId, questionRegistry, UpdateFormAttributeInputType, UpdateFormInputType, UpdateSubformInputType, UserCalculated } from './project-form.model';

const formSettingsFields = `
    name
    title
    titleAttributeId
    secondaryTitleAttributeId
    thankyou
    allowMemberUpdate
    allowOwnRecordDelete
    isPrivate: private
    visible
`;

const formAttributeFields = `
    id
    uuid
    label
    type
    questionType
    config
    help
    description
    path
    text
    exportPath
    sectionIdx
    associatedSurveyId
    collectionId
    order
    conditions
    required
    visible
    formId: surveyId
`;

/** Child form nesting is limited to 5 deep */
const formFields = `
    ${formSettingsFields}
    attributes{
        ${formAttributeFields}
        form{
            ${formSettingsFields}
            attributes{
                ${formAttributeFields}
                form{
                    ${formSettingsFields}
                    attributes{
                        ${formAttributeFields}
                        form{
                            ${formSettingsFields}
                            attributes{
                                ${formAttributeFields}
                                form{
                                    ${formSettingsFields}
                                    attributes{
                                        ${formAttributeFields}
                                    }
                                }
                            }
                        }
                    }
                }   
            }
        }   
    }
`;

export const projectFormNoticePreferenceKey = 'projectFormNotice';
export const projectFormNoticePreferenceVersion = 1;
export const attributeDeleteNoticePreferenceKey = 'projectFormAttributeDeleteNotice';
export const attributeDeleteNoticePreferenceVersion = 1;

@Injectable()
export class ProjectFormService {

    apiService = inject(ApiService);

    user = signal<UserCalculated | null>(null);
    formId = signal<number | undefined>(undefined);
    projectId = signal<number | undefined>(undefined);
    projectHasBounds = signal(false);
    projectSurveyIds = signal<number[]>([]);
    parsedChildFormIds = signal<number[]>([]);

    questions = signal(questionRegistry).asReadonly();
    formLoading = signal(false);
    attributes = signal<Attribute[]>([]);
    formSettings = signal<FormSettings | null>(null);
    selectedAttributeId = signal<number | null>(null);

    showAttributeDeleteNotice = signal(false);

    collections = signal<FormCollection[]>([]);

    private collectionItemLoads: Map<number, Observable<any>> = new Map();

    selectedAttribute = computed(() => {
        const id = this.selectedAttributeId();
        const attributes = this.attributes();
        return attributes.find(attribute => attribute.id === id);
    });
    
    selectedQuestion = computed(() => {
        const attribute = this.selectedAttribute();
        return attribute ? this.getQuestion(attribute) : null;
    });

    deletedAttributes = computed(() => {
        return this.attributes().filter(attribute => attribute.deleted);
    });

    dirtyAttributes = computed(() => {
        return this.attributes().filter(attribute => attribute.dirty);
    });

    dirty = computed(() => {
        return this.dirtyAttributes().length > 0;
    });

    errors = signal<{ [key: string]: string } | null>(null);

    attributesForConditions = computed(() => {
        const attributes = this.attributes();
        const attribute = this.selectedAttribute();
        return attributes
            /** Only attributes that are in the same form */
            .filter(a => a.formId === attribute.formId)
            /** Remove media attributes */
            .filter(a => (
                a.type !== 'attachment' &&
                a.type !== 'media'
            ))
            /** Remove deleted */
            .filter(a => !a.deleted)
            /** Check attributes have all necessary fields */
            .filter(a => (
                a.path && 
                a.label && 
                a.id !== attribute.id &&
                !!a.type && 
                !!a.questionType
            )).map(a => {
                return {
                    name: a.label,
                    value: a.path
                }
            });
    });

    conditionRulePaths = computed(() => {
        const attributes = this.attributes();
        const paths: string[] = attributes.map(attribute => {
                const rules = attribute.conditions.rules;
                const paths = rules.map(rule => (rule.path));
                return paths;
            }).flat();
        return paths;    
    });

    deletedConditionRules = signal(new Map<number, AttributeConditionRule[]>());

    /** Id of calculated field and the attributes used in it */
    calculatedFields = signal(new Map<number, number[]>());
    attributesUsedInCalculatedFields = computed(() => {
        const calculatedFields = this.calculatedFields();
        const values = calculatedFields.values();
        let ids = [];
        for (const value of values) {
            ids = [...ids, ...value];
        }
        const unique = [...new Set(ids)];
        return unique;
    });

    init(projectId: number, formId: number) {
        this.formLoading.set(true);
        this.projectId.set(projectId);
        this.formId.set(formId);
        return this.getForm();
    }

    getQuestion(attribute: Attribute) {
        const question = this.questions().find(q => q.type === attribute.type && q.questionType === attribute.questionType);
        return question;
    }

    private parseAttributes(attributes: CoreoAttribute[], formId: number = this.formId()): Attribute[] {
        /** Add dirty & deleted fields to attributes then add divider attributes between sections where needed */
        const sortedAttributes = [...attributes];
        sortedAttributes.sort((a, b) => a.sectionIdx - b.sectionIdx || a.order - b.order);

        let attributeWithDividers: Attribute[] = [];

        let previousSectionIndex = 0;
        let previousAttributeIsGeometry = false;

        for (const attribute of sortedAttributes) {
            /** If it's a new section add a divider before, unless it's a geometry attribute or the previous attribute was geometry */
            if (
                (attribute.sectionIdx !== previousSectionIndex) && 
                attribute.questionType !== 'geometry' &&
                !previousAttributeIsGeometry
            ) {
                const id = this.generateId();
                const newDivider: Attribute = {
                    type: 'divider',
                    questionType: null,
                    formId: formId,
                    id,
                    uuid: uuid(),
                    label: null,
                    text: null,
                    help: null,
                    exportPath: null,
                    description: null,
                    sectionIdx: previousSectionIndex,
                    order: null,
                    dirty: false,
                    conditions: {
                        any: false,
                        rules: []
                    },
                    config: {},
                    path: null,
                    associatedSurveyId: null,
                    collectionId: null,
                    required: false,
                    visible: true,
                    form: null,
                    deleted: false
                };
                attributeWithDividers.push(newDivider);
            }

            attributeWithDividers.push({
                ...attribute,
                order: null,
                dirty: false,
                deleted: null
            });

            previousSectionIndex = attribute.sectionIdx;
            previousAttributeIsGeometry = attribute.questionType === 'geometry'
        }

        const parsedAttributes = attributeWithDividers.map((a, index) => ({...a, order: index}));
        return parsedAttributes;
    }

    getChildFormAttributes(attributes: CoreoAttribute[]): Attribute[] {
        const childForms = attributes.filter(a => a.questionType === 'child');
        
        let all = [];

        if (childForms.length) {
            childForms.forEach(child => {
                const { form, associatedSurveyId } = child;
                const { attributes: childAttributes } = form;

                if (!!form && childAttributes.length && !this.parsedChildFormIds().includes(associatedSurveyId)) {
                    /** Store when a child form has been parsed so we don't parse it more than once */
                    this.parsedChildFormIds.update(ids => [...ids, associatedSurveyId]);
                    all = [...all, ...this.parseAttributes(childAttributes, associatedSurveyId), ...this.getChildFormAttributes(childAttributes)];
                }
                // Can we/should we remove the attributes from the form here? Leave for now in case future changes requires the nested structure
                
            });
        }
        
        return all;
    }

    private checkAttributesUsedForCalculatedFields(attributes: Attribute[]) {
        import('@natural-apptitude/coreo-expressions/dist/esm').then(({ CoreoExpressionEvaluator }) => {
            const itemResolver = async (collectionId: number, key: string) => {
                return null;
            }

            const expressionEvaluator = new CoreoExpressionEvaluator(
                attributes,
                itemResolver,
                this.user()
            );

            const fieldNamesMap = expressionEvaluator.getRecordFieldNamesMap();

            const calculatedFieldAttributes = attributes.filter(a => a.questionType === 'expression');

            const fieldNames = expressionEvaluator.getAvailableFieldNames().filter(fn => !['$username', '$displayName'].includes(fn));
            
            if (calculatedFieldAttributes.length) {
                for (const field of calculatedFieldAttributes) {
                    const expression = expressionEvaluator.deserialiseExpression(field.config.expression);
                    const attributeIds = [];
                    for (const name of fieldNames) {
                        /** Get any field names that are used in the expression */
                        if (expression.includes(name)) {
                            /** Find which attribute it belongs to and update signal */
                            const uuid = fieldNamesMap[name];
                            const id = attributes.find(a => a.uuid === uuid).id;
                            attributeIds.push(id);
                        }
                    }
                    this.calculatedFields.update((calculatedFields) => {
                        calculatedFields.set(field.id, attributeIds);
                        return new Map(calculatedFields);
                    });
                }
            }
        });
        
    }

    private async getForm() {
        const query = `query CoreoAAGetForm($projectId: Int!, $formId: Int!){
            project(id: $projectId){
                geometry{
                    type
                }
                surveys{
                    id
                }
                forms: surveys(where:{id: $formId}){
                    ${formFields}
                }
                collections{
                    id
                    name
                    geometric
                }
            }
        }`;

        return this.apiService.graphql<{ 
            project: { 
                geometry: {
                    type: string;
                }
                surveys: {
                    id: number;
                }[]
                forms: { 
                    name: string;
                    title: string;
                    titleAttributeId: number;
                    secondaryTitleAttributeId: number;
                    thankyou: string;
                    allowMemberUpdate: boolean;
                    allowOwnRecordDelete: boolean;
                    isPrivate: boolean;
                    visible: boolean;
                    attributes: CoreoAttribute[];
                }[];
                collections: {
                    id: number;
                    name: string;
                    geometric: boolean;
                }[]
            }
        }>(query, { projectId: this.projectId(), formId: this.formId() }).subscribe(t => {
            
            const { project: { forms, collections, geometry, surveys } } = t;
            const form = forms[0];
            if (!form) {
                return;
            }

            if (!!geometry?.type) {
                this.projectHasBounds.set(true);
            }

            if (surveys && surveys.length) {
                this.projectSurveyIds.set(surveys.map(s => s.id));
            }

            const { attributes } = form;
            console.log(attributes);
            
            if (!attributes) {
                this.attributes.set([]);
            } else {
                this.parsedChildFormIds.set([]);
                const childFormAttributes = this.getChildFormAttributes(attributes);
                const allAttributes = [...this.parseAttributes(attributes), ...childFormAttributes];
                this.attributes.set(allAttributes);                
            }

            const { 
                name,
                title,
                titleAttributeId,
                secondaryTitleAttributeId,
                thankyou,
                allowMemberUpdate,
                allowOwnRecordDelete,
                isPrivate,
                visible
            } = form;

            this.formSettings.set({ 
                name,
                title,
                titleAttributeId,
                secondaryTitleAttributeId,
                thankyou,
                allowMemberUpdate,
                allowOwnRecordDelete,
                isPrivate,
                visible
            });

            if (collections) {
                this.collections.set(collections.map(c => ({...c, items: null, data: null})));
            }

            this.checkAttributesUsedForCalculatedFields(this.attributes());
            this.formLoading.set(false);
        });
    }

    loadCollection(collectionId: number, selectCollection: boolean = false) {
        const query = `query CoreoAAGetCollectionItems{
            project(id: ${this.projectId()}){
                collections(where: { id: ${collectionId} }){
                    id
                    name
                    geometric
                    items{
                        key
                        value
                        data
                    }
                }
            }
        }`;

        this.apiService.graphql<{ project: { collections: FormCollection[] } }>(query).pipe(
            take(1),
            catchError((e) => {
                console.error(e);
                return of(null);
            }),
            map(res => (res.project.collections[0]))
        ).subscribe(collection => {
            /** If collection already exists in service remove it, then add new collection */
            this.collections.update(collections => collections.filter(c => c.id !== collectionId));
            this.collections.update(collections => [...collections, collection]);
            /** If selected collection */
            if (selectCollection) {
                this.updateSelectedAttribute({ collectionId: collection.id });
            }
        });
    }

    getCollectionItems(collectionId: number): Observable<{ items: FormCollectionItem[]; data: any }> {
        const query = `query CoreoAAGetCollectionItems{
            project(id: ${this.projectId()}){
                collections(where: { id: ${collectionId} }){
                    id
                    items{
                        key
                        value
                        data
                    }
                }
            }
        }`;

        return this.apiService.graphql<{ project: { collections: FormCollection[] } }>(query).pipe(
            take(1),
            catchError((e) => {
                console.error(e);
                return of(null);
            }),
            map(res => ({ items: res.project.collections[0].items, data: res.project.collections[0].data }))
        );
    }
        
    setCollectionItems(collectionId: number, items: FormCollectionItem[], data: any) {
        this.collections.update(collections => collections.map(c => {
            if (c.id === collectionId) {
                return {
                    ...c,
                    items,
                    data
                }
            } else {
                return c;
            }
        }));
    }

    loadCollectionItems(collectionId: number) {

        if (this.collectionItemLoads.has(collectionId)) {
            return this.collectionItemLoads.get(collectionId);
        }

        const obs = this.getCollectionItems(collectionId);
        this.collectionItemLoads.set(collectionId, obs);

        obs.subscribe(res => {
            if (res) {
                this.setCollectionItems(collectionId, res.items, res.data ?? {});
            }
        });
    }

    selectAttribute(id: number) {
        const attribute = this.attributes().find(a => a.id === id);
        if (!attribute) {
            return;
        }
        if (attribute.type === 'divider') {
            return;
        }
        this.selectedAttributeId.set(id);
    }

    generateId() {
        const id = window.crypto.getRandomValues(new Int32Array(1))[0];

        if (id < 0) {
            return id;
        } else {
            return this.generateId();
        }
    }

    addAttribute(questionId: QuestionId, formId: number, index?: number): number {
        const currentFormAttributes = this.attributes().filter(a => a.formId === formId).sort((a, b) => a.order - b.order);
        const question = this.questions().find(q => q.id === questionId);
        
        if (!question) {
            return;
        }

        const id = this.generateId();
        
        const newAttribute: Attribute = {
            type: question.type,
            questionType: question.questionType,
            formId,
            id,
            uuid: uuid(),
            label: null,
            text: null,
            help: null,
            exportPath: null,
            description: null,
            sectionIdx: 0,
            order: index ?? currentFormAttributes.length,
            dirty: questionId === 'divider',
            conditions: {
                any: false,
                rules: []
            },
            config: {},
            path: null,
            associatedSurveyId: question.questionType === 'child' ? id : null, // temp id won't be submitted
            collectionId: null,
            required: false,
            visible: true,
            form: question.questionType === 'child' ? {
                name: null,
                title: null,
                titleAttributeId: null,
                secondaryTitleAttributeId: null,
                thankyou: null,
                allowMemberUpdate: false,
                allowOwnRecordDelete: false,
                isPrivate: false,
                attributes: []
            } : null,
            deleted: false
        };

        if (typeof index === 'undefined') {
            this.attributes.update(attributes => ([...attributes, newAttribute]));
        } else {
            /** Update order on attributes after the new one */
            const pre = currentFormAttributes.slice(0, index);
            const post = currentFormAttributes.slice(index);
            const updatedCurrentFormAttributes = [
                ...pre,
                newAttribute,
                ...post.map(a => ({ ...a, order: a.order + 1 }))
            ];
            const otherFormAttributes = this.attributes().filter(a => a.formId !== formId);
            this.attributes.set([
                ...updatedCurrentFormAttributes,
                ...otherFormAttributes
            ]);
        }

        return id;
    }

    canImmediatelyDelete(attribute: Attribute): boolean {
        /** If the attribute can be immediately deleted (ie a divider or no data set) then remove */
        const { text, label, description, help, exportPath, form, questionType, associatedSurveyId, config, conditions } = attribute;

        if (questionType === 'child') {
            const childFormQuestionIds = this.attributes().filter(a => a.formId === associatedSurveyId).map(a => (a.id));
            const canDeleteChildForm: boolean = (
                !text && 
                !label &&
                !description &&
                !help &&
                !exportPath &&
                !form?.name &&
                !form?.title &&
                !form?.titleAttributeId &&
                !form?.secondaryTitleAttributeId &&
                !form?.thankyou &&
                form?.allowMemberUpdate !== null  &&
                form?.allowMemberUpdate !== null  &&
                form?.isPrivate !== null  &&
                !Object.keys(config).length &&
                !conditions.rules.length &&
                !childFormQuestionIds.length 
            );
                
            if (canDeleteChildForm) {
                const attributes = this.attributes().filter(a => a.id !== attribute.id);
                this.attributes.set(attributes);
                return true;
            } else {
                return false;
            }
        } else {
            const canDeleteAttribute: boolean = (
                !text && 
                !label &&
                !description &&
                !help &&
                !exportPath &&
                !Object.keys(config).length &&
                !conditions.rules.length
            );
            if (canDeleteAttribute) {
                const attributes = this.attributes().filter(a => a.id !== attribute.id).map(a => ({ ...a, dirty: true }));
                this.attributes.set(attributes);
                return true;
            } else {
                return false;
            }
        }
    }

    deleteDivider(id: number) {
        const attributes = this.attributes().filter(a => a.id !== id).map(a => ({ ...a, dirty: true }));
        this.attributes.set(attributes);
    }

    deleteAttribute(id: number) {
        const attribute = this.attributes().find(a => a.id === id);
        const { path } = attribute;

        const attributes = this.attributes().map(a => {
            if (a.id === attribute.id) {
                /** mark as deleted */
                return {
                    ...a,
                    deleted: true,
                    dirty: true
                }
            } else {
                /** Check if the attribute is used in a condition rule and remove the condition rule, store so it can be restored if the attribute is restored */
                const removedRules = a.conditions.rules.filter(rule => rule.path === path);
                this.deletedConditionRules.update((deletedConditionRules) => {
                    let rules = [...removedRules];
                    const deletedRules = deletedConditionRules.get(a.id);

                    if (deletedRules?.length > 0) {
                        rules = [...rules, ...deletedRules];
                    }

                    deletedConditionRules.set(a.id, rules);
                    return new Map(deletedConditionRules);
                });

                return {
                    ...a,
                    conditions: {
                        ...a.conditions,
                        rules: a.conditions.rules.filter(rule => rule.path !== path)
                    }
                }
            }
        });

        this.attributes.set(attributes);
    }

    deleteChildForm(attribute: Attribute) {
        const { id, text, label, path, associatedSurveyId, form } = attribute;

        const childFormQuestionIds = this.attributes().filter(a => a.formId === associatedSurveyId).map(a => (a.id));

        if (
            /** If required fields aren't set yet and there are no questions in the form then just remove */
            // Should there be checks for other data ie settings, conditions?
            !text && 
            !label &&
            !form?.name &&
            !form?.title &&
            !childFormQuestionIds.length
        ) {
            const attributes = this.attributes().filter(a => a.id !== id);
            this.attributes.set(attributes);
            return;
        }

        let attributesToDelete = [id];

        const findChildrenToDelete = (ids: number[]) => {
            attributesToDelete = [...attributesToDelete, ...ids];
            
            ids.forEach(id => {
                const attr = this.attributes().find(a => a.id === id);

                if (attr.questionType === 'child') {
                    const questionIds = this.attributes().filter(a => a.formId === attr.associatedSurveyId).map(a => (a.id));
                    
                    if (questionIds.length) {
                        return findChildrenToDelete(questionIds);
                    } else {
                        return;
                    }
                }
            });
        }

        if (childFormQuestionIds.length) {
            findChildrenToDelete(childFormQuestionIds);
        }

        const attributes = this.attributes().map(a => {
            if (attributesToDelete.includes(a.id)) {
                /** mark as deleted */
                return {
                    ...a,
                    deleted: true,
                    dirty: true
                }
            } else {
                /** Check if the attribute is used in a condition rule and remove the condition rule, store so it can be restored if the attribute is restored */
                const removedRules = a.conditions.rules.filter(rule => rule.path === path);
                this.deletedConditionRules.update((deletedConditionRules) => {
                    let rules = [...removedRules];
                    const deletedRules = deletedConditionRules.get(a.id);

                    if (deletedRules?.length > 0) {
                        rules = [...rules, ...deletedRules];
                    }

                    deletedConditionRules.set(a.id, rules);
                    return new Map(deletedConditionRules);
                });

                return {
                    ...a,
                    conditions: {
                        ...a.conditions,
                        rules: a.conditions.rules.filter(rule => rule.path !== path)
                    }
                }
            }
        });

        this.attributes.set(attributes);
    }

    restoreAttribute(id: number) {
        let attributesToRestoreIds = [id];

        const findChildrenToRestore = (ids: number[]) => {
            attributesToRestoreIds = [...attributesToRestoreIds, ...ids];

            ids.forEach(id => {
                const attr = this.attributes().find(a => a.id === id);

                if (attr.questionType === 'child') {
                    const questionIds = this.attributes().filter(a => a.formId === attr.associatedSurveyId).map(a => (a.id));
                    
                    if (questionIds.length) {
                        return findChildrenToRestore(questionIds);
                    } else {
                        return;
                    }
                }
            });
        }

        const attribute = this.attributes().find(a => a.id === id);
        const { associatedSurveyId } = attribute;

        if (associatedSurveyId) {
            const childFormQuestionIds = this.attributes().filter(a => a.formId === associatedSurveyId).map(a => (a.id));
            
            if (childFormQuestionIds.length) {
                findChildrenToRestore(childFormQuestionIds);
            }
        }

        const attributesToRestorePaths: string[] = attributesToRestoreIds.map(id => this.attributes().find(a => a.id === id).path);

        const attributesWithDeletedRules: number[] = Array.from(this.deletedConditionRules().keys());

        const attributes = this.attributes().map(a => {
            let attribute = {...a};
            /** Restore attributes if it's id matches */
            if (attributesToRestoreIds.includes(a.id)) {
                attribute = {
                    ...attribute,
                    deleted: false,
                    dirty: true
                }
            }

            /** Does attribute have any deleted rules - check map */
            if (attributesWithDeletedRules.includes(a.id)) {
                /** Do any deleted rule paths match the paths of attributes being restored - if so add them back in */
                const deletedRules = this.deletedConditionRules().get(a.id);
                const rulesToRestore = [];
                for (const rule of deletedRules) {
                    if (attributesToRestorePaths.includes(rule.path)) {
                        rulesToRestore.push(rule);
                    }
                }
                attribute = {
                    ...attribute,
                    conditions: {
                        ...attribute.conditions,
                        rules: [...attribute.conditions.rules, ...rulesToRestore]
                    }
                }
            }

            return attribute;
        });

        this.attributes.set(attributes);
    }

    limitChildForms(id: number): boolean {
        /** Limit child form nesting to 4 deep */
        const attributes = this.attributes();
        const target = attributes.find(a => a.associatedSurveyId === id);
        
        let ids = [];
        function getParentForms(attribute: Attribute): number[] {
            const form = attributes.find(a => a.associatedSurveyId === attribute.formId);
            if (form) {
                ids = [...ids, form.associatedSurveyId];
                return getParentForms(form);            
            } else {
                return ids;
            }
        }
        
        if (target) {
            ids = [target.associatedSurveyId];
            getParentForms(target);
            /** Ids of nested forms with the target being the deepest - if there are already four then don't allow another level */
            return ids.length >= 4;
        } else {
            return false
        };
    }   

    attributeIsDivider(id: number): boolean {
        return this.attributes().find(a => a.id === id)?.type === 'divider';
    }

    hasGeometry(formId: number) {
        const attributes = this.attributes().filter(a => a.formId === formId);
        return attributes.some(a => a.questionType === 'geometry');
    }

    updateSelectedAttribute(obj: Partial<Attribute>) {
        const attributes = this.attributes().map(attribute => {
            if (attribute.id === this.selectedAttributeId()) {
                return { ...attribute, ...obj, dirty: true };
            }
            return attribute;
        });
        this.attributes.set(attributes);
    }

    updateOrder(order: string[]) {
        const attributes = this.attributes().map(a => {
            const newIndex = order.findIndex(id => Number(id) === a.id);
            /** If index is -1 then it's in another form ,order stays the same */
            return {
                ...a,
                order: newIndex > -1 ? newIndex : a.order,
                dirty: true
            }
        });
        attributes.sort((a, b) => a.order - b.order);
        this.attributes.set(attributes);
    }

    validateAttributeConditions(rules: AttributeConditionRule[], questions: Attribute[], attribute: Attribute) {
        let errors = {};
        const questionIndex = keyBy(questions, 'path');
        const UNARY_OPERATORS = ['answered', 'unanswered'];
  
        const setRuleError = (ruleIndex, error) => {
          errors[ruleIndex] = errors[ruleIndex] || {};
          errors = {
            ...errors,
            [ruleIndex]: {
                ...errors[ruleIndex],
                [error]: true
            }
          }
        }
  
        const validateCondition = (condition: string, ruleIndex: number) => {
            if (!condition) {
                setRuleError(ruleIndex, 'noCondition');
            }
        }

        const validateComparand = (comparand: any, ruleIndex: number) => {
            if (comparand == null || comparand === undefined) {
                setRuleError(ruleIndex, 'noComparand');
            }
        }
        
        const isUnaryOperator = (operand: string) => UNARY_OPERATORS.includes(operand);
        
        rules.forEach((rule, i) => {
            const question = Object.keys(questionIndex).length !== 0 ? questionIndex[rule.path] : null;
            if (question) {
                const attributeIndex = questions.findIndex(a => a.id === attribute.id);
                const ruleAttributeIndex = questions.findIndex(a => a.id === question.id);
                if (ruleAttributeIndex > attributeIndex) {
                    setRuleError(i, 'conditionalBeforeQuestion');
                }
                const { type } = question;
                switch (type) {
                    case 'float':
                    case 'integer':
                        validateCondition(rule['numberCondition'], i);
                        !isUnaryOperator(rule['numberCondition']) && validateComparand(rule['numberComparand'], i);
                        break;
                    case 'text':
                        validateCondition(rule['textCondition'], i);
                        !isUnaryOperator(rule['textCondition']) && validateComparand(rule['textComparand'], i);
                        break;
                    case 'date':
                        validateCondition(rule['dateCondition'], i);
                        !isUnaryOperator(rule['dateCondition']) && validateComparand(rule['dateComparand'], i);
                        break;
                    case 'datetime':
                        validateCondition(rule['datetimeCondition'], i);
                        !isUnaryOperator(rule['datetimeCondition']) && validateComparand(rule['datetimeComparand'], i);
                        break;
                    case 'select':
                    case 'multiselect':
                        validateCondition(rule['selectCondition'], i);
                        !isUnaryOperator(rule['selectCondition']) && validateComparand(rule['selectComparand'], i);
                        break;
                    case 'boolean':
                        if (rule['isTrue'] == null || rule['isTrue'] === undefined) {
                            setRuleError(i, 'noCondition');
                        }
                    break;
                }
            } else {
                setRuleError(i, 'noQuestion');
            }
        });
        return errors;
    }

    isCalculatedAttribute(attribute: Attribute): boolean {
        return ['rgeolocation', 'geometryquery', 'coordinatetransform'].includes(attribute.type);
    } 

    validateAttribute(attribute: Attribute, attributes: Attribute[]) {
        const errors = {};
        const isCalculated = this.isCalculatedAttribute(attribute);
        const { questionType, text, type, label, associatedSurveyId, config, collectionId, exportPath, id, conditions, form } = attribute;
        const otherExportPaths = attributes.filter(a => a.id !== id).map(a => a.exportPath);

        if (questionType === 'expression') {
            if (!text) errors['noQuestion'] = true;
            if (!label) errors['noLabel'] = true;
            if (!type) errors['noCalculatedFieldType'] = true;
            if (type && !config.expression) errors['noCalculatedFieldExpression'] = true;
            return errors; // return early so other validation errors are not dragged in on this check for UI reasons
        }

        if (questionType === 'text' || questionType === 'section' || type === 'divider') {
            return errors;
        }

        if (!exportPath && id >= 0 && questionType !== 'geometry') { // exportPath will be autocreated on attribute create, but not after
            errors['noExportPath'] = true;
        }

        if (otherExportPaths.includes(exportPath) && questionType !== 'geometry' && !(id < 0 && !exportPath)) {
            errors['duplicateExportPath'] = true;
        }

        if (!text && !isCalculated) {
            errors['noQuestion'] = true;
        }

        if (!label && (type || questionType === 'child' || questionType === 'association')) {
            errors['noLabel'] = true;
        }

        if (!associatedSurveyId && (questionType === 'child' || questionType === 'association')) {
            errors['noAssociatedSurvey'] = true;
        }

        if (questionType === 'child') {
            if (!form?.name) {
                errors['noName'] = true;
            }
            if (!form?.title) {
                errors['noTitle'] = true;
            }
            if (Object.keys(config).length) {
                /** Has config values */
                const { min, max } = config as SubformConfig;
                if (min > max) {
                    errors['childFormMinMax'] = true;
                }
            }
        }

        if (conditions && conditions.rules && Object.keys(conditions.rules).length > 0) {
            const { rules } = conditions;
            const questions = attribute ? attributes.filter(a => a.path && a.label) : [];
            const conditionErrors = this.validateAttributeConditions(rules, questions, attribute);
            if (Object.keys(conditionErrors).length !== 0) {
                errors['conditions'] = conditionErrors;
            }
        }
        
        switch (type) {
            case 'select':
            case 'multiselect': {
                if (!collectionId) {
                    errors['noCollection'] = true;
                }
                break;
            }
            case 'float':
            case 'integer': {
                if (questionType === 'slider') {
                    const min = parseInt(config.min, 10);
                    const max = parseInt(config.max, 10);
                    if (isNaN(min) || isNaN(max)) {
                        errors['minMax'] = true;
                    }
                    if (min > max) {
                        errors['minMax'] = true;
                    }
                } else {
                    const min = parseInt(config.min, 10);
                    const max = parseInt(config.max, 10);
                    if (
                        min !== undefined &&
                        min !== null &&
                        max !== undefined &&
                        max !== null
                    ) {
                        if (min > max) {
                            errors['minMax'] = true;
                        }
                    }
                }
                break;
            }
            case 'rgeolocation': {
                if (!config.field) {
                    errors['noRgeoField'] = true;
                }
                break;
            }
            case 'coordinatetransform': {
                if (!config.projection) {
                    errors['noCTProjection'] = true;
                }
                break;
            }
            case 'geometryquery': {
                if (!config.operation) {
                    errors['noGQOperation'] = true;
                }
                if (!collectionId) {
                    errors['noGQCollection'] = true;
                }
                break;
            }
        }
        return errors;
    };

    canSave(): boolean {
        this.errors.set(null);

        let errors = {};
        if (!this.formSettings().name) {
            errors['settings'] = { 'noName': true };
        }
        if (!this.formSettings().title) {
            errors['settings'] = {
                ...(errors['settings'] || {}),
                noTitle: true
            };
        }

        /** Validate parent/child forms separately - fixes duplicate export paths error over different forms, we may want to enforce this in the future but not for now. Example - Different UKHab child forms have duplicate export paths which can't be changed at the moment */
        const formIds = [...new Set(this.attributes().map(a => a.formId))];
        for (const id of formIds) {
            const attributes = this.attributes().filter(a => a.formId === id);
            errors = attributes
                .filter(a => !a.deleted)
                .reduce((acc, attribute) => {
                    const attributeErrors = this.validateAttribute(attribute, attributes);
                    if (Object.keys(attributeErrors).length > 0) {
                        acc[attribute.id] = attributeErrors;
                    }
                    return acc;
                }, errors);
        }

        this.errors.set(errors);

        this.attributes.set(this.attributes().map(a => {
            return { 
                ...a, 
                dirty: false,
            }
        }));
        
        if (Object.keys(errors).length > 0) {
            const id = Number((Object.keys(errors)).filter(e => e !== 'settings')[0]);
            console.warn('FORM HAS ERRORS', errors, Object.keys(errors), id);
            this.selectedAttributeId.set(null);

            return false;
        } else {
            return true;
        }
    }

    childFormToUpdateInput(attribute: Attribute): UpdateSubformInputType {
        const attributes = this.attributes().filter(a => a.formId === attribute.associatedSurveyId);
        return {
            name: attribute.form?.name,
            title: attribute.form?.title,
            titleAttributeId: attribute.form?.titleAttributeId,
            secondaryTitleAttributeId: attribute.form?.secondaryTitleAttributeId,
            thankyou: attribute.form?.thankyou,
            allowMemberUpdate: attribute.form?.allowMemberUpdate,
            allowOwnRecordDelete: attribute.form?.allowOwnRecordDelete,
            private: attribute.form?.isPrivate,
            attributes: this.attributesToUpdateInput(attributes)
        }
    }

    attributeToUpdateInput(attribute: Attribute): UpdateFormAttributeInputType {
        return {
            id: attribute.id,
            sectionIdx: attribute.sectionIdx,
            required: attribute.required,
            type: attribute.type,
            questionType: attribute.questionType,
            description: attribute.description,
            text: attribute.text,
            exportPath: attribute.exportPath,
            conditions: attribute.conditions,
            config: JSON.stringify(attribute.config),
            uuid: attribute.uuid,
            label: attribute.label,
            order: attribute.order,
            help: attribute.help,
            path: attribute.path,
            visible: attribute.visible,
            associatedSurveyId: attribute.associatedSurveyId,
            collectionId: attribute.collectionId,
            form: attribute.questionType === 'child' ? this.childFormToUpdateInput(attribute) : null
        }
    }

    attributesToUpdateInput(attributes: Attribute[]): UpdateFormAttributeInputType[] {
        attributes.sort((a,b) => a.order - b.order);
        let sectionIdx = 0;
        
        const parsed = attributes
            /** Remove deleted questions */
            .filter(a => !a.deleted)
            /** Add correct section indexes */
            .map(a => {
                if (a.type === 'divider' || a.questionType === 'geometry') {
                    sectionIdx = ++sectionIdx;
                }
                return {
                    ...a,
                    sectionIdx: a.questionType === 'geometry' ? sectionIdx++ : sectionIdx,
                }
                
            })
            /** Remove dividers */
            .filter(a => a.type !== 'divider')
            /** Make sure they're in the correct order */
            .sort((a, b) => a.order - b.order)
            /** Reassign order as deleted and dividers have been removed */
            .map((a, index) => ({ ...a, order: index }))
            /** Finally parse into correct format tor api */
            .map(a => this.attributeToUpdateInput(a));

        return parsed;
    }

    save() {
        const input: UpdateFormInputType = {
            id: this.formId(),
            name: this.formSettings().name,
            title: this.formSettings().title,
            titleAttributeId: this.formSettings().titleAttributeId,
            secondaryTitleAttributeId: this.formSettings().secondaryTitleAttributeId,
            thankyou: this.formSettings().thankyou,
            allowMemberUpdate: this.formSettings().allowMemberUpdate,
            allowOwnRecordDelete: this.formSettings().allowOwnRecordDelete,
            private: this.formSettings().isPrivate,
            attributes: this.attributesToUpdateInput(this.attributes().filter(a => a.formId === this.formId()))
        }

        const query = `mutation AAProjectFormUpdate($input: UpdateFormInputType){
            updateForm(input: $input){
                ${formFields}
            }
        }`;

        this.apiService.graphql<{ 
            updateForm: { 
                name: string;
                title: string;
                titleAttributeId: number;
                secondaryTitleAttributeId: number;
                thankyou: string;
                allowMemberUpdate: boolean;
                allowOwnRecordDelete: boolean;
                private: boolean;
                attributes: CoreoAttribute[];
            }
        }>(query, { input }).pipe(
            take(1),
            catchError((e) => {
                console.error(e);
                // Toast/alert - do we need/have a global service for this
                return of(null);
            })
        ).subscribe(res => {
            if (res) {
                const { attributes } = res.updateForm;

                if (!attributes) {
                    this.attributes.set([]);
                } else {
                    this.parsedChildFormIds.set([]);
                    const childFormAttributes = this.getChildFormAttributes(attributes);
                    const allAttributes = [...this.parseAttributes(attributes), ...childFormAttributes];
                    this.attributes.set(allAttributes);
                    /** Keep track of project surveys add any new child forms */
                    const formIds = this.attributes().filter(a => a.questionType === 'child').map(a => a.id);
                    this.projectSurveyIds.update(ids => {
                        const newIds = [...ids, ...formIds];
                        const unique = [...new Set(newIds)];
                        return unique;
                    });
                    this.selectedAttributeId.set(null);
                }
            }
        });
    }
}