<form class="w-full p-4 space-y-5">
    <div class="flex flex-col gap-1">
        <label class="text-sm text-text-primary font-normal" for="choiceType">Choice type</label>
        <p-selectButton ariaLabelledBy="choiceType" name="choiceType" [options]="choiceTypeOptions" [ngModel]="choiceType()" [multiple]="false" [unselectable]="true" optionLabel="label" optionValue="value" styleClass="w-full" (ngModelChange)="choiceTypeChanged($event)"></p-selectButton>
    </div>

    @if (choiceType() === 'modal') {
        <div class="flex flex-col gap-1">
            <label class="grow text-sm text-text-primary font-normal flex items-center justify-between" for="defaultLayout">
                Default display type
                <i class="fa-solid fa-circle-info text-text-secondary" pTooltip="This is how the searchable list will be displayed by default. If the list is image based a card view may be preferable and if text based a list view may be preferable. The user can chose to change this view whilst in the app. " tooltipPosition="left"></i>
            </label>
            <p-selectButton ariaLabelledBy="defaultLayout" name="defaultLayout" [options]="defaultLayoutOptions" [ngModel]="defaultLayout()" [multiple]="false" [unselectable]="true" optionLabel="label" optionValue="value" styleClass="w-full" (ngModelChange)="defaultLayoutChanged($event)"></p-selectButton>
        </div>
    }
        
    <div class="flex items-center gap-2">
        <label class="grow text-sm text-text-primary font-normal" for="allowMultipleSelection">Allow multiple selection</label>
        <p-inputSwitch inputId="allowMultipleSelection" name="allowMultipleSelection" [ngModel]="allowMultipleSelection()" (ngModelChange)="allowMultipleSelectionChanged($event)" />
    </div>
        
    <div class="flex items-center gap-2">
        <label class="grow text-sm text-text-primary font-normal flex items-center justify-between" for="collectionTooltip">
            Offer collection item tooltip
            <i class="fa-solid fa-circle-info text-text-secondary" pTooltip="The tool tip will contain the collection item name and any information that has been saved against attributes in the collection. It can also contain an image. Attributes and images can be set from in the “Collections” section of Coreo. " tooltipPosition="left"></i>
        </label>
        <p-inputSwitch inputId="collectionTooltip" name="collectionTooltip" [ngModel]="collectionTooltip()" (ngModelChange)="collectionTooltipChanged($event)" />
    </div>
</form>