<div class="table-item__inner">
    <div class="w-1/2 flex items-center justify-start gap-4">
        <svg-icon [src]="icon()" class="h-5 w-5 text-text-secondary"></svg-icon>
        <p class="table-item__primary-text w-1/2 truncate">{{ item().name }}</p>
    </div>
    <div class="w-1/2 flex items-center justify-between">
        <p>{{ item().createdAt | date:'dd/MM/yyyy' }}</p>
        <div class="table-item__buttons">
            <button class="table-item__button" [ngClass]="hovering() ? 'inline-block' : 'hidden'"
                (click)="deleteItem($event)">
                <i class="fa-solid fa-trash-can"></i>
            </button>
        </div>
    </div>
</div>