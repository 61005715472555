import { z } from 'zod';

export const MediaItemSchema = z.object({
    id: z.number(),
    url: z.string(),
    name: z.string(),
    type: z.string(),
    caption: z.string().nullable(),
    sort: z.number(),
    createdAt: z.string(),
    size: z.number()
});

export const CollectionItemSchema = z.object({
    id: z.number(),
    key: z.string(),
    value: z.string(),
    geometry: z.any().nullable(),
    data: z.any(),
    mediaItems: MediaItemSchema.array(),
    icon: z.string().nullable(),
    color: z.string().nullable(),
    sort: z.number().nullable()
});

export const CollectionAttributeSchema = z.object({
    id: z.number(),
    label: z.string(),
    type: z.string(),
    path: z.string().nullable(),
    visible: z.boolean(),
    order: z.number().nullable()
});

export const CollectionSchema = z.object({
    attributes: CollectionAttributeSchema.array(),
    items: CollectionItemSchema.array(),
    geometric: z.boolean()
});

export const CollectionItemPreviewSchema = z.object({
    title: z.string(),
    data: z.object({
        label: z.string(),
        value: z.any().nullable()
    }).array(),
    mediaItems: MediaItemSchema.array(),
    geometry: z.any().nullable()
});

export type MediaItem = z.infer<typeof MediaItemSchema>;
export type CollectionItem = z.infer<typeof CollectionItemSchema>;
export type CollectionAttribute = z.infer<typeof CollectionAttributeSchema>;
export type Collection = z.infer<typeof CollectionSchema>;
export type CollectionItemPreview = z.infer<typeof CollectionItemPreviewSchema>;