<p-dialog header="Remove members" [(visible)]="isVisibleRemoveSeatsDialog" [draggable]="false" [modal]="true" [resizable]="false" styleClass="sm:w-[960px] sm:h-[577px]" (onHide)="onHideDialog()">
    <div class="flex flex-col w-full">
        <div class="w-full flex flex-col sm:flex-row sm:items-start px-4">
            <div class="flex-grow text-sm pb-4">
                <h2>
                    You have <strong>{{ seatsFilled }} members</strong> on your team and 
                    have chosen <strong>{{ seatsSelected }} seats</strong> for your Coreo plan
                </h2>
                <h2 class="p-error font-bold">Please remove {{ seatsFilled - seatsSelected }} members</h2>
            </div>
            <form [formGroup]="searchForm" class="flex mt-4 sm:mt-0 sm:ml-4 items-end p-input-icon-left">
                <i class="pi pi-search"></i>
                <input type="text" pInputText formControlName="search" placeholder="Search for members" class="sm:w-auto w-60" />
            </form>
        </div>
        <p-tabView [(activeIndex)]="activeIndex">
            <p-tabPanel header="Members ({{ filteredMemberships().length }})">
                <div class="border-b border-[#D3DBE3] gap-4 pt-1 pb-3 flex items-center px-4">
                    <div class="relative">
                        <p-checkbox inputId="selectAll" [ngModel]="isSelectAll()" [binary]="true" (ngModelChange)="selectAll()" />
                        @if(isSelectAll() === null){
                        <label for="selectAll" class="absolute block h-0.5 w-2.5 bg-primary rounded-lg top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 pointer-event-none"></label>
                        }
                    </div>
                    <span class="items-center cursor-pointer min-w-60" (click)="sort('name')">
                        Name
                        @if (sortColumn().field === 'name') {
                        <i class="pi ml-3 pi-sort-amount-{{sortColumn().field === 'name' && sortColumn().type === 'desc' ? 'up-alt' : 'down'}}"></i>
                        }
                    </span>
                    <span class="flex-grow flex items-center cursor-pointer" (click)="sort('email')">
                        Email
                        @if (sortColumn().field === 'email') {
                        <i class="pi ml-3 pi-sort-amount-{{sortColumn().field === 'email' && sortColumn().type === 'desc' ? 'up-alt' : 'down'}}"></i>
                        }
                    </span>
                    <span class="block min-w-36 cursor-pointer" (click)="sort('role')">
                        Role
                        @if (sortColumn().field === 'role') {
                        <i class="pi ml-3 pi-sort-amount-{{sortColumn().field === 'role' && sortColumn().type === 'desc' ? 'up-alt' : 'down'}}"></i>
                        }
                    </span>
                </div>
                <ul class="overflow-y-scroll px-4 pt-2.5 pb-4 h-[210px]">
                    @for(member of filteredMemberships(); track member.userId;){
                    <li class="flex items-center gap-4 py-2">
                        <p-checkbox [ngModel]="member.selected" [binary]="true" (ngModelChange)="selectMember(member)" [disabled]="member.userId === userId" />
            
                        <div class="min-w-60">
                            <p class="font-bold py-1">{{ member.name }}</p>
                            <p class="text-xs">{{ member.username }}</p>
                        </div>
                        
                        <p class="flex-grow">{{ member.email }}</p>
                        <p class="min-w-36 ml-auto capitalize">{{ member.role }}</p>
                    </li>
                    }
                </ul>
                <div class="w-full flex items-center justify-end border-t border-[#D3DBE3] pt-4 gap-4">
                    <p-button size="large" [outlined]="true" (click)="onHideDialog([], true)">Back to plans</p-button>
                    <p-button size="large" [disabled]="!allowRemove()" (click)="confirmRemove()">Remove selected ({{ selectedCount() }})</p-button>
                </div>
            </p-tabPanel>
            <p-tabPanel header="Pending ({{ filteredPendingMemberships().length }})">
                <div class="border-b border-[#D3DBE3] gap-4 pt-1 pb-3 flex items-center px-4">
                    <div class="relative w-[135px]">
                    </div>
                    <span class="flex-grow flex items-center cursor-pointer" (click)="sortPending('pendingEmail')">
                        Email
                        @if (pendingSortColumn().field === 'pendingEmail') {
                        <i class="pi ml-3 pi-sort-amount-{{pendingSortColumn().field === 'pendingEmail' && pendingSortColumn().type === 'desc' ? 'up-alt' : 'down'}}"></i>
                        }
                    </span>
                    <span class="block min-w-36 cursor-pointer" (click)="sortPending('role')">
                        Role
                        @if (pendingSortColumn().field === 'role') {
                        <i class="pi ml-3 pi-sort-amount-{{pendingSortColumn().field === 'role' && pendingSortColumn().type === 'desc' ? 'up-alt' : 'down'}}"></i>
                        }
                    </span>
                </div>
                <ul class="overflow-y-scroll px-4 pt-2.5 pb-4 h-[210px]">
                    @for(member of filteredPendingMemberships(); track member.pendingEmail;){
                    <li class="flex items-center gap-4 py-2">
                        <p-button type="button" severity="danger" [outlined]="true" label="Cancel" (click)="confirmCancel(member)"/>

                        <p class="flex-grow">{{ member.pendingEmail }}</p>
                        <p class="min-w-36 ml-auto capitalize">{{ member.role }}</p>
                    </li>
                    }
                </ul>
                <div class="w-full flex items-center justify-end border-t border-[#D3DBE3] pt-4 gap-4">
                    <p-button size="large" [outlined]="true" (click)="onHideDialog([], true)">Back to plans</p-button>
                </div>
            </p-tabPanel>
        </p-tabView>
    </div>
</p-dialog>
<p-confirmDialog/>
