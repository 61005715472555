<div class="h-full w-full grid grid-cols-1 grid-rows-[auto_1fr] p-5">
    <div class="w-full flex items-center justify-between pb-5">
        <p>Click on the map to start drawing your geometry.</p>
        <div class="flex items-center justify-end gap-4">
            <p-button label="Cancel" [outlined]="true" (click)="handleBack()"></p-button>
            <p-button label="Save item" [disabled]="!canSave()" (click)="save()" [loading]="loading()"></p-button>
        </div>
    </div>
    <div class="h-full w-full bg-white rounded-lg overflow-hidden">
        <form class="w-full flex gap-4 p-4">
            <!-- Name input -->
            <div class="w-2/3 flex flex-col gap-1">
                <label class="text-xs font-normal" for="itemValueInput">Add item name <sup>*</sup></label>
                <input pInputText #itemValueInput id="itemValueInput" name="itemValueInput" [(ngModel)]="itemValue" required type="text" class="w-full h-9" autocomplete="off" placeholder="List item name" (input)="setKey(); duplicateKeyError.set(false)" />
            </div>
            <div class="w-1/3 flex items-end gap-4">
                <!-- Key input -->
                <div class="flex flex-col gap-1">
                    <label class="text-xs font-normal flex items-center justify-between" for="itemKeyInput">
                        <span>Item key <sup>*</sup></span>
                        <i 
                            class="fa-solid fa-circle-info text-text-secondary cursor-pointer"
                            pTooltip="The key is a permanent label for your list item, which is stored in the data base. It will remain the same, even if the item name is changed. If you do not set a custom key, the key will be the same as the item name. Once set it cannot be changed."
                            tooltipPosition="left"
                        ></i>
                    </label>
                    <input pInputText #itemKeyInput id="itemKeyInput" name="itemKeyInput" [(ngModel)]="itemKey" (input)="itemKeyDirty.set(true)" required type="text" class="w-full h-9" autocomplete="off" placeholder="Custom key" [ngClass]="{'ng-invalid ng-dirty': duplicateKeyError()}" (input)="duplicateKeyError.set(false)" />
                </div>
            </div>
        </form>
        <div class="h-full w-full relative bg-primary-200">
            <div #newItemMap class="h-full w-full"></div>
            <div #toolsWrapper>
                <!-- <app-map-settings [open]="settingsOpen()" [customLayers]="customLayers()" /> -->
                @if (gis()) {
                    <app-collection-map-toolbar [gis]="gis()" (geoTypeChanged)="changeGeoType($event)" class="absolute top-2 right-2 z-10" />
                }
            </div>
        </div>
    </div>
</div>