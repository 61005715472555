<form class="w-full p-4 space-y-5">
    <div class="flex flex-col gap-1">
        <label class="text-sm text-text-primary font-normal" for="questionType">Input type</label>
        <p-selectButton ariaLabelledBy="questionType" name="questionType" [options]="questionTypeOptions" [ngModel]="questionType()" [multiple]="false" [unselectable]="true" optionLabel="label" optionValue="value" styleClass="w-full" (ngModelChange)="questionTypeChanged($event)"></p-selectButton>
    </div>

    @if (questionType() === 'short') {
        <div class="flex items-center gap-2">
            <label class="grow text-sm text-text-primary font-normal flex items-center justify-between" for="autocomplete">
                Use previous answers
                <i class="fa-solid fa-circle-info text-text-secondary" pTooltip="When enabled the app will allow the user to search through previously recorded answers" tooltipPosition="left"></i>
            </label>
            <p-inputSwitch inputId="autocomplete" name="autocomplete" [ngModel]="autocomplete()" (ngModelChange)="update.emit({config: { autocomplete: $event, format: format() }})" />
        </div>
    }
    <div class="flex items-center gap-2">
        <label class="grow text-sm text-text-primary font-normal flex items-center justify-between" for="showFormat">
            Use an exact answer
            <i class="fa-solid fa-circle-info text-text-secondary" pTooltip="Answers must match a regular expression" tooltipPosition="left"></i>
        </label>
        <p-inputSwitch inputId="showFormat" name="showFormat" [ngModel]="showFormat()" (ngModelChange)="showFormatChanged($event)" />
    </div>
    @if (showFormat()) {
        <div class="flex flex-col gap-1">
            <input 
                id="format" 
                type="text" 
                pInputText
                placeholder="" 
                name="format" 
                [ngModel]="format()"
                (ngModelChange)="formatChanged($event)" />
            <p class="text-[10px] font-normal text-text-secondary">A regular expression that the text must match.</p>
            <a href="https://regexr.com/" target="_blank" rel="noopener noreferrer" class="text-[10px] font-normal text-text-secondary flex items-center gap-1"><span class="underline">regexr.com</span><i class="fa-solid fa-arrow-up-right-from-square"></i></a>
        </div>
    }
</form>