import { Component, OnInit, inject } from '@angular/core';
import { JobsService } from './core/services/jobs.service';
import { NG_REDUX } from './core/core.module';
import { UserService } from './core/services/user.service';
import { DialogService } from 'primeng/dynamicdialog';
import { SuccessfulSubscriptionModalComponent } from './features/organisations/organisation-subscription/successful-subscription-modal.component';

@Component({
    selector: 'app-root',
    template: '',
    providers: [DialogService]
})
export class AppComponent implements OnInit {

    userService = inject(UserService);
    jobsService = inject(JobsService);
    dialogService = inject(DialogService);

    ngOnInit() {
        this.userService.populate();
        this.jobsService.init();
        this.checkSuccessfulSubscription();
    }

    checkSuccessfulSubscription() {
        const url = new URL(window.location.href);
        const params = url.searchParams;
    
        if (params.has('successfulPayment') && params.has('tier')) {
            const tierName = params.get('tier');
    
            ['tier', 'successfulPayment'].forEach(param => params.delete(param));
    
            setTimeout(() => {
                window.history.replaceState({}, document.title, url.toString());
                this.showSuccessfulSubscriptionModal(tierName);
            }, 2000);
        }
    }
    

    showSuccessfulSubscriptionModal(tierName: string) {
        this.dialogService.open(SuccessfulSubscriptionModalComponent, {
            header: 'Update Successful',
            width: '510px',
            modal: true,
            closable: false,
            data: { tierName }
        });
    }
}