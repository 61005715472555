<p-confirmDialog styleClass="hide-close"></p-confirmDialog>
<p-toast position="bottom-center" [life]="2000"></p-toast>

<p-table
    [value]="attributes()"
    (onRowReorder)="reorderRows($event)" 
    [loading]="loading()"
    [scrollable]="true"
    scrollHeight="flex"
    styleClass="bg-white"
>
    <ng-template pTemplate="caption">
        <div class="coreo-list-header gap-4">
            <span class="flex items-center">
                <h2 class="whitespace-nowrap">Attributes</h2>
                <i 
                    class="fa-solid fa-circle-info text-text-secondary ml-2 cursor-pointer"
                    pTooltip="This is a description that can be added to each of the collection items. If set to visible these descriptions will be shown as part of the collection item’s description or tool tip in the app. Numerical attributes can also be used to sort items in your collection."
                    tooltipPosition="right"
                ></i>
            </span>
            <p-button class="h-9" styleClass="h-full" (click)="newAttributeForm.reset(); addAttributeForm.toggle($event)">
                <svg-icon src="/assets/icons/plus.svg"></svg-icon><span class="is-hidden lg:inline-block">Add</span>
            </p-button>
        </div>
        <p-overlayPanel #addAttributeForm>
            <form [formGroup]="newAttributeForm" (ngSubmit)="onNewAttributeSubmit(); addAttributeForm.hide()" class="flex flex-col gap-5 min-w-72">
                <!-- Type -->
                <p-selectButton [options]="typeOptions" [multiple]="false" [unselectable]="true" optionLabel="label" optionValue="value" formControlName="type" styleClass="w-full"></p-selectButton>
                <!-- Visible -->
                <div class="flex items-center gap-2">
                    <label class="grow text-sm text-text-primary font-normal cursor-pointer" for="visible">Visible in app</label>
                    <p-inputSwitch inputId="visible" formControlName="visible" />
                </div>
                <!-- Name -->
                <div class="flex flex-col gap-1">
                    <label for="label" class="text-xs font-normal">Attribute name <sup>*</sup></label>
                    <input type="text" pInputText id="label" type="text" placeholder="Attribute name" formControlName="label" class="w-full" />
                </div>
                <!-- Submit -->
                <p-button class="w-full" styleClass="w-full" type="submit" [disabled]="!newAttributeForm.valid">
                    <svg-icon src="/assets/icons/plus.svg"></svg-icon>Add attribute
                </p-button>
            </form>
        </p-overlayPanel>
    </ng-template>

    <ng-template pTemplate="body" let-attribute let-index="rowIndex">
        <tr [pReorderableRow]="index" class="table-item table-item--thin">
            <div class="table-item__inner justify-between">
                <p class="flex items-center gap-2">
                    <span class="max-w-32 truncate">{{ attribute.label }}</span>
                    @if (!attribute.visible) {
                        <i class="fa-solid fa-eye-slash text-[10px] text-text-primary"></i>
                    }
                </p>
                <div class="table-item__buttons">
                    <!-- Delete -->
                    <button class="table-item__button">
                        <i (click)="deleteAttributeCheck($event, attribute.id)" class="fa-solid fa-trash-can"></i>
                    </button>
                    <!-- Edit -->
                    <button class="table-item__button" (click)="editAttribute(attribute); editAttributeFormPanel.toggle($event)">
                        <i class="fa-solid fa-pen text-primary"></i>
                    </button>
                    <!-- Reorder -->
                    <i class="fa-solid fa-grip-vertical" pReorderableRowHandle></i>
                </div>
            </div>
        </tr>
        <p-overlayPanel #editAttributeFormPanel>
            <form [formGroup]="editAttributeForm" (ngSubmit)="onEditAttributeSubmit(); editAttributeFormPanel.hide()" class="flex flex-col gap-5 min-w-72">
                <!-- Type -->
                <p-selectButton [options]="typeOptions" [multiple]="false" [unselectable]="true" optionLabel="label" optionValue="value" formControlName="type" styleClass="w-full"></p-selectButton>
                <!-- Visible -->
                <div class="flex items-center gap-2">
                    <label class="grow text-sm text-text-primary font-normal cursor-pointer" for="visible">Visible in app</label>
                    <p-inputSwitch inputId="visible" formControlName="visible" />
                </div>
                <!-- Name -->
                <div class="flex flex-col gap-1">
                    <label for="label" class="text-xs font-normal">Attribute name <sup>*</sup></label>
                    <input type="text" pInputText id="label" type="text" placeholder="Attribute name" formControlName="label" class="w-full" />
                </div>
                <!-- Submit -->
                <p-button class="h-9 block" styleClass="h-full" type="submit" [disabled]="!editAttributeForm.dirty || !editAttributeForm.valid">
                    Save changes
                </p-button>
            </form>
        </p-overlayPanel>
    </ng-template>

    <ng-template pTemplate="emptymessage">
        <div class="absolute h-full w-full flex flex-col items-center justify-center">
            <i class="fa-solid fa-layer-group text-5xl text-primary-100 mb-5"></i>
            <p class="text-base font-semibold">There are no attributes yet.</p>
            <p class="max-w-80 text-sm text-text-secondary text-center mt-6">Attributes are optional text or numerical values that can be added to each collection item.</p>
        </div>
    </ng-template>
</p-table>
