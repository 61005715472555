import { Component, Output, Input, EventEmitter, signal } from "@angular/core";
import { ButtonModule } from 'primeng/button';
import { DialogModule } from 'primeng/dialog';
import { DragDropModule } from "primeng/dragdrop";

@Component({
    template: `
    <p-dialog header="Upload a profile image" [(visible)]="isOpen" [draggable]="false" [modal]="true" [resizable]="false" styleClass="sm:w-[560px] sm:h-[608px]" (onHide)="abort()">
        @if(imageUrl()) {
          <div class="flex flex-col w-full h-full">
            <div class="flex items-center justify-between grow">
                <img [src]="imageUrl()" class="w-2/3 aspect-square rounded-full object-cover mx-auto" />
            </div>
            <div class="border-t border-[#D3DBE3] pt-5 flex justify-end flex-none">
                <p-button type="submit" size="large" (click)="submit()">
                  <i class="fa-solid fa-cloud-arrow-up"></i>Upload image
                </p-button>
            </div>
          </div>
        } @else {
          <div pDroppable (onDrop)="onDrop($event)" class="drop-area">
            <i class="text-7xl text-primary fa-solid fa-cloud-arrow-up"></i>
            <h2 class="text-base text-black text-center font-semibold leading-tight">Drag & drop an image <br>or</h2>
            <p-button type="button" size="large" (click)="fileInput.click()" label="Browse File"/>
          </div>
          <input type="file" #fileInput class="hidden" (change)="onFileSelected($event)" accept="image/*" />
        }
    </p-dialog>
  `,
    selector: 'app-upload-profile-image',
    standalone: true,
    imports: [
        ButtonModule,
        DialogModule,
        DragDropModule
    ]
})
export class UploadProfileImageComponent {

    @Input() isOpen: boolean = false;
    @Output() imageChange = new EventEmitter<{ imageUrl: string; image: File }>();
    @Output() cancel = new EventEmitter<void>();

    image = signal<File | null>(null);
    imageUrl = signal<string | null>(null);

    ngOnChanges() {
        this.imageUrl.set(null);
    }

    onDrop(event: any) {
        if (!event.dataTransfer.files || !event.dataTransfer.files.length) return;

        const file = event.dataTransfer.files[0];
        this.onFileSelected({ target: { files: [file] } });
    }

    onFileSelected(event: any) {
        const file: File = event.target.files[0];
        if (!file) return;

        const reader = new FileReader();
        this.image.set(file);

        reader.onload = (e: any) => e.target.result && this.imageUrl.set(e.target.result);
        reader.readAsDataURL(file);
    }

    abort() {
        this.isOpen = false;
        this.cancel.emit();
    }

    submit(imageUrl: string | null = null) {
        this.isOpen = false;
        this.imageChange.emit({
            imageUrl: imageUrl || this.imageUrl(),
            image: this.image()
        });
    }
}