import { inject, Injectable } from "@angular/core";
import { map } from "rxjs";
import { ApiService } from "src/app/core";

export type LoginResult = {
    token: string;
    pre2FAToken: string;
    user: {
        id: string;
        email: string;
        role: string;
        imageUrl: string;
        displayName: string;
        username: string;
        provider: string;
        preferences: any;
    }
}

type SSOLoginResult = {
    redirectUrl: string;
}

@Injectable({
    providedIn: 'root'
})
export class AuthService {
    api = inject(ApiService);

    login(email: string, password: string) {
        const mutation = `mutation AALogin($input: LoginInput!){
            login(input: $input){
                token,
                pre2FAToken,
                user { 
                    id, 
                    email, 
                    role, 
                    imageUrl, 
                    displayName, 
                    username, 
                    provider, 
                    preferences 
                }
            }
        }`;
        const input = {
            email,
            password
        };

        return this.api.graphql<{ login: LoginResult }>(mutation, { input }).pipe(
            map(res => res.login)
        );
    }

    ssoLogin(domain: string) {
        const mutation = `mutation AASSOLogin($input: SSOLoginInput!){
            ssoLogin(input: $input){
                redirectUrl
            }
        }`;
        const input = {
            domain
        };

        return this.api.graphql<{ ssoLogin: SSOLoginResult }>(mutation, { input }).pipe(
            map(res => res.ssoLogin)
        );
    }

    signup(
        email: string, 
        password: string, 
        displayName: string, 
        marketingConsent: boolean,
        trialOrganisationName: string
    ) {
        const mutation = `mutation AASignup($input: SignupInput!){
            signup(input: $input){
                token
            }
        }`;

        const input = {
            email,
            password,
            displayName,
            marketingConsent,
            trialOrganisationName,
            legacy: false
        };

        return this.api.graphql<{ signup: any }>(mutation, { input }).pipe(
            map(res => {
                console.log(res);
                return res.signup;
            })
        );
    }

    verify2FA(pre2FAToken: string, token: string) {
        const mutation = `mutation AAVerify2FA($input: MFAVerifyInput!){
            mfaAuthenticatorVerify(input: $input) {
                token,
                user {
                    id,
                    email,
                    role,
                    imageUrl,
                    displayName,
                    username,
                    provider,
                    preferences
                }
            }
        }`;
        const input = {
            pre2FAToken,
            token
        };

        return this.api.graphql<{ mfaAuthenticatorVerify: LoginResult }>(mutation, { input }).pipe(
            map(res => res.mfaAuthenticatorVerify)
        );
    }

    recover2FA(pre2FAToken: string, recoveryCode: string) {
        const mutation = `mutation AARecover2FA($input: MFARecoverInput!){
            mfaAuthenticatorRecover(input: $input) {
                token,
                user {
                    id,
                    email,
                    role,
                    imageUrl,
                    displayName,
                    username,
                    provider,
                    preferences
                }
            }
        }`;
        const input = {
            pre2FAToken,
            recoveryCode
        };

        return this.api.graphql<{ mfaAuthenticatorRecover: LoginResult }>(mutation, { input }).pipe(
            map(res => res.mfaAuthenticatorRecover)
        );
    }

    setup2FA() {
        const mutation = `mutation AASetup2FA{
            mfaAuthenticatorSetup {
                qrCode
                secret
            }
        }`;

        return this.api.graphql<{ mfaAuthenticatorSetup: { qrCode: string; secret: string } }>(mutation).pipe(
            map(res => res.mfaAuthenticatorSetup)
        );
    }

    confirm2FA(token: string) {
        const mutation = `mutation AASetup2FAConfirm($input: MFASetupConfirmInput!){
            mfaAuthenticatorSetupConfirm(input: $input) {
                token
                recoveryCodes
            }
        }`;

        return this.api.graphql<{ mfaAuthenticatorSetupConfirm: { token: string; recoveryCodes: string[] } }>(mutation, { input: { token } }).pipe(
            map(res => res.mfaAuthenticatorSetupConfirm)
        );
    }

    disable2FA() {
        const mutation = `mutation AADisable2FA{
            mfaAuthenticatorDisable
        }`;
        return this.api.graphql<{ mfaAuthenticatorDisable: boolean }>(mutation).pipe(
            map(res => res.mfaAuthenticatorDisable)
        );
    }

    regenerate2FARecoveryCodes() {
        const mutation = `mutation AARegenerate2FARecoveryCodes{
            authMfaAuthenticatorRegenerateRecoveryCodes {
                recoveryCodes
            }
        }`;
        return this.api.graphql<{ authMfaAuthenticatorRegenerateRecoveryCodes: { recoveryCodes: string[] } }>(mutation).pipe(
            map(res => res.authMfaAuthenticatorRegenerateRecoveryCodes.recoveryCodes)
        );
    }
}