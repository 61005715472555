import angular from 'angular';
import CoreoAPIService from '../../main/coreoApi';
import { getProjectCollection, getProjectCollections, getProjectId, getProjectOrganisationId } from '../selectors';

function projectActions(ProjectService, ProjectPagesService, ProjectMediaService, ProjectMapsService, CoreoAPI: CoreoAPIService) {

    const loadProject = slug => (dispatch) => {
        dispatch({
            type: 'LOAD_PROJECT',
            slug
        });

        return ProjectService.load(slug).then(project => {
            dispatch({
                type: 'LOAD_PROJECT_SUCCESS',
                project
            });
            return project;
        }, error => {
            dispatch({
                type: 'LOAD_PROJECT_FAILURE',
                error
            });
            return null;
        });
    };

    const loadProjectStats = id => (dispatch) => {
        dispatch({
            type: 'LOAD_PROJECT_STATS',
            id
        });

        return ProjectService.loadStats(id).then(stats => {
            dispatch({
                type: 'LOAD_PROJECT_STATS_SUCCESS',
                stats
            });
            return stats;
        }, error => dispatch({
            type: 'LOAD_PROJECT_STATS_FAILURE',
            error
        }));
    }


    const createFormSuccess = form => (dispatch) => {
        dispatch({
            type: 'CREATE_FORM_SUCCESS',
            form
        });
        return form;
    };

    const deleteProject = () => (dispatch, getState) => {
        dispatch({
            type: 'DELETE_PROJECT'
        });
        const projectId = getProjectId(getState())

        return ProjectService.deleteProject(projectId).then(() => dispatch({
            type: 'DELETE_PROJECT_SUCCESS',
            projectId,
            toast: 'Project Deleted'
        })
            , err => dispatch({
                type: 'DELETE_PROJECT_FAILURE',
                err
            }));
    };

    const loadCollection = (id) => (dispatch, getState) => {
        const collection = getProjectCollection(id)(getState());
        if (typeof collection.items !== 'undefined') {
            return collection;
        }

        return ProjectService.getCollection(getProjectId(getState()), id).then(collection => {
            dispatch({
                type: 'LOAD_COLLECTION',
                collection
            });
            return collection;
        });
    }

    const loadCollectionItem = (collectionId, itemId) => (dispatch, getState) => {

        const projectId = getProjectId(getState());

        return ProjectService.getCollectionItem(projectId, collectionId, itemId).then(item => {
            return item;
        });
    };

    const updateProject = (project) => (dispatch) => {
        return ProjectService.updateProject(project).then((updatedProject) => dispatch({
            type: 'UPDATE_PROJECT_SUCCESS',
            project: Object.assign(project, { imageUrl: updatedProject.imageUrl, hasEmptySeats: updatedProject.hasEmptySeats }),
            toast: 'Project updated'
        }), err => dispatch({
            type: 'UPDATE_PROJECT_FAILURE',
            err,
            toastError: 'Failed to update project'
        }));
    }

    const createCredential = credential => (dispatch, getState) => {
        const projectId = getProjectId(getState());
        return ProjectService.createCredential({
            ...credential,
            projectId
        }).then((result) => dispatch({
            type: 'CREATE_CREDENTIAL_SUCCESS',
            credential: result,
            toast: 'Credential Created'
        }), err => dispatch({
            type: 'CREATE_CREDENTIAL_FAILURE',
            err,
            toastError: 'Failed to create credential'
        }));
    };

    const updateCredential = credential => (dispatch) => {
        return ProjectService.updateCredential(credential).then(() => dispatch({
            type: 'UPDATE_CREDENTIAL_SUCCESS',
            credential,
            toast: 'Credential Updated'
        }), err => dispatch({
            type: 'UPDATE_CREDENTIAL_FAILURE',
            err,
            toastError: 'Failed to update credential'
        }));
    };


    const addProjectPage = page => (dispatch) => {
        dispatch({
            type: 'ADD_PROJECT_PAGE',
        })
        return ProjectPagesService.createPage(page).then((result) => {
            page.id = result;

            dispatch({
                type: 'ADD_PROJECT_PAGE_SUCCESS',
                toast: 'New page created',
                page
            })
            return page;
        }, err => {
            return dispatch({
                type: 'ADD_PROJECT_PAGE_FAILURE',
                err,
                toastError: 'Unable to create new page'
            })
        });
    }

    const deleteProjectPage = id => (dispatch) => {
        dispatch({
            type: 'DELETE_PROJECT_PAGE',
        })
        return ProjectPagesService.deletePage(id).then((_result) => {
            return dispatch({
                type: 'DELETE_PROJECT_PAGE_SUCCESS',
                toast: 'Page Deleted',
                id
            })
        }, err => {
            return dispatch({
                type: 'DELETE_PROJECT_PAGE_FAILURE',
                err,
                toastError: 'Unable to delete page'
            })
        });
    };

    const reorderProjectPages = pageOrderMap => dispatch => {
        return ProjectPagesService.updatePages(pageOrderMap).then(() =>
            dispatch({
                type: 'REORDER_PROJECT_PAGES',
                order: pageOrderMap
            })
        );
    };

    const deleteItemMediaItem = (collectionId, itemId, mediaItemId) => (dispatch) => {
        dispatch({
            type: 'DELETE_COLLECTION_ITEM_MEDIA',
        })
        return ProjectMediaService.deleteMediaItems([mediaItemId]).then(() =>
            dispatch({
                type: 'DELETE_COLLECTION_ITEM_MEDIA_SUCCESS',
                collectionId,
                itemId,
                mediaItemId
            })
            , err => {
                dispatch({
                    type: 'DELETE_COLLECTION_ITEM_MEDIA_FAILURE',
                    err
                })
            })
    }

    const createItemMediaItem = (collectionId, itemId, mediaItem) => (dispatch, getState) => {
        const projectId = getProjectId(getState());
        dispatch({
            type: 'CREATE_COLLECTION_ITEM_MEDIA',
        })
        return ProjectMediaService.createItemMediaItem(mediaItem, itemId, projectId, mediaItem.file).then(result =>
            dispatch({
                type: 'CREATE_COLLECTION_ITEM_MEDIA_SUCCESS',
                collectionId,
                itemId,
                mediaItem: result
            })
            , err => {
                dispatch({
                    type: 'CREATE_COLLECTION_ITEM_MEDIA_FAILURE',
                    err
                })
            })
    }

    const updateItemMediaItem = (collectionId, itemId, mediaItemId, mediaItemUpdate) => (dispatch) => {
        dispatch({
            type: 'UPDATE_COLLECTION_ITEM_MEDIA',
        })
        return ProjectMediaService.updateMediaItem(mediaItemUpdate).then(() =>
            dispatch({
                type: 'UPDATE_COLLECTION_ITEM_MEDIA_SUCCESS',
                collectionId,
                itemId,
                mediaItemId,
                mediaItemUpdate
            })
            , err => {
                dispatch({
                    type: 'UPDATE_COLLECTION_ITEM_MEDIA_FAILURE',
                    err
                })
            })
    }

    const sortItemMediaItems = (collectionId, itemId, orderMap) => (dispatch, getState) => {
        const collections = getProjectCollections(getState());
        const collection = collections.find(c => c.id === collectionId);
        const item = angular.copy(collection.items.find(i => i.id === itemId));

        for (const mediaItem of item.mediaItems) {
            mediaItem.sort = orderMap[mediaItem.id];
        }

        //This causes a store update without the sorted items, resulting in a brief flash to the unsorted items

        // dispatch({
        //     type: 'SORT_COLLECTION_ITEM_MEDIA',
        // })

        return ProjectMediaService.updateMediaItems(item.mediaItems).then(() =>
            dispatch({
                type: 'SORT_COLLECTION_ITEM_MEDIA_SUCCESS',
                collectionId,
                itemId,
                orderMap
            })
            , err => {
                dispatch({
                    type: 'SORT_COLLECTION_ITEM_MEDIA_FAILURE',
                    err
                })
            })
    }

    const updateProjectUserRole = (userId, role) => (dispatch, getState) => {
        const projectId = getProjectId(getState());

        const query = `mutation updateProjectUserRole($userId: Int!,  $projectId: Int!){
        result: updateProjectMembership(input: {userId: $userId, projectId: $projectId, role: ${role}}){
            userId
        }
    }`;

        dispatch({
            type: 'UPDATE_PROJECT_USER_ROLE',
            projectId,
            userId,
            role
        });

        return CoreoAPI.mutation(query, null, {
            variables: {
                userId,
                projectId
            }
        }).then(result => {
            dispatch({
                type: 'UPDATE_PROJECT_USER_ROLE_SUCCESS',
                toast: 'User role updated',
                projectUser: result
            });
            return result;
        }, err => {
            let message = err.message;
            if (message === 'This organisation user does not exist') {
                message = 'This user must be a member of the organisation. Please add them to the organisation and try again.'
            }
            dispatch({
                type: 'UPDATE_PROJECT_USER_ROLE_FAILURE',
                toastError: message,
                err
            });
            throw err;
        });
    };

    const addProjectUser = (userId, role) => async (dispatch, getState) => {
        const state = getState();
        const projectId = getProjectId(state);
        const organisationId = getProjectOrganisationId(state);

        const query = `mutation inviteProjectUser($organisationId: Int!, $userId: Int!, $projectId: Int!, $role: String!){
        addOrganisationUserToProject(input: { organisationId: $organisationId, userId: $userId, projectId: $projectId, role: $role}){
            data,
            marketingConsent,
            role,
            status,
            createdAt,
            userId,
            user{
                id,
                displayName,
                username,
                email
            }
        }
        }`;

        dispatch({
            type: 'ADD_PROJECT_USER',
            projectId,
            organisationId,
            userId,
            role
        });

        try {
            const result = await CoreoAPI.gql(query, {
                organisationId,
                projectId,
                userId,
                role
            });
            const projectUser = result.addOrganisationUserToProject;
            dispatch({
                type: 'ADD_PROJECT_USER_SUCCESS',
                toast: 'User added to project',
                projectUser
            });
            return projectUser;
        } catch (err) {
            dispatch({
                type: 'ADD_PROJECT_USER_FAILURE',
                toastError: 'Failed to add user to project',
                err
            });
        }
    };

    const deleteProjectUser = (userId) => (dispatch, getState) => {
        const state = getState();
        const projectId = getProjectId(state);

        const query = `mutation removeProjectUser($userId: Int!, $projectId: Int!){
        result: downgradeProjectUser(input: { userId: $userId, projectId: $projectId})
        }`;

        dispatch({
            type: 'REMOVE_PROJECT_USER',
            projectId,
            userId
        });

        return CoreoAPI.mutation(query, null, {
            variables: {
                projectId,
                userId
            }
        }).then((result) => {
            dispatch({
                type: 'REMOVE_PROJECT_USER_SUCCESS',
                toast: result ? 'User removed from project' : 'User was made a contributor',
                projectId,
                userId
            });
            return result;
        }, err => dispatch({
            type: 'REMOVE_PROJECT_USER_FAILURE',
            toastError: 'Failed to remove user from project',
            err
        }));
    }

    const createProjectRecordStatus = recordStatus => (dispatch, getState) => {
        dispatch({
            type: 'CREATE_PROJECT_RECORD_STATUS',
        })

        return ProjectService.createProjectVerificationState(recordStatus).then(recordStatus => {
            dispatch({
                type: 'CREATE_PROJECT_RECORD_STATUS_SUCCESS',
                recordStatus,
                toast: 'State added'
            })
        }, err => {
            dispatch({
                type: 'CREATE_PROJECT_RECORD_STATUS_FAILURE',
                err,
                toast: {
                    type: 'error',
                    message: 'Failed to create record status'
                }
            })
        })
    }

    const updateProjectRecordStatus = (recordStatus) => (dispatch, getState) => {
        dispatch({
            type: 'UPDATE_PROJECT_RECORD_STATUS',
        })

        return ProjectService.updateProjectVerificationState(recordStatus).then(recordStatus => {
            dispatch({
                type: 'UPDATE_PROJECT_RECORD_STATUS_SUCCESS',
                recordStatus,
                toast: 'State updated'
            })
        }, err => {
            dispatch({
                type: 'UPDATE_PROJECT_RECORD_STATUS_FAILURE',
                err,
                toast: {
                    type: 'error',
                    message: 'Failed to update record status'
                }
            })
        })
    }

    const updateProjectRecordStatusOrder = statuses => (dispatch, getState) => {
        dispatch({
            type: 'UPDATE_PROJECT_RECORD_STATUS_ORDER',
        })

        const projectId = getProjectId(getState());
        const statusesToUpdate = [];

        for (var i = 0; i < statuses.length; i++) {
            const status = statuses[i];
            if (status.sort !== i) {
                status.sort = i;
                statusesToUpdate.push({
                    ...status,
                    projectId,
                    sort: i
                });
            }
        }

        const promises = [];
        for (const status of statusesToUpdate) {
            promises.push(ProjectService.updateProjectVerificationState(status));
        }

        return Promise.all(promises).then(() => {
            dispatch({
                type: 'UPDATE_PROJECT_RECORD_STATUS_ORDER_SUCCESS',
                states: statuses,
                toast: 'Record Status order updated'
            })
        }).catch(err => dispatch({
            type: 'UPDATE_PROJECT_RECORD_STATUS_ORDER_FAILURE',
            toastError: 'Failed to updated Record Status order',
            err
        }))

    }

    const deleteProjectRecordStatus = stateId => (dispatch, getState) => {
        const projectId = getProjectId(getState());
        dispatch({
            type: 'UPDATE_PROJECT_RECORD_STATUS',
        })

        return ProjectService.deleteProjectVerificationState(projectId, stateId).then(recordStatus => {
            dispatch({
                type: 'DELETE_PROJECT_RECORD_STATUS_SUCCESS',
                recordStatus,
                toast: 'State deleted'
            })
        }, err => {
            dispatch({
                type: 'DELETE_PROJECT_RECORD_STATUS_FAILURE',
                err,
                toast: {
                    type: 'error',
                    message: 'Failed to delete record status'
                }
            })
        })
    }

    const deleteProjectMapLayer = id => dispatch => {
        return ProjectMapsService.deleteMapLayer(id).then(() => dispatch({
            type: 'DELETE_PROJECT_MAP_LAYER_SUCCESS',
            id
        }));
    }

    return {
        loadProject,
        loadProjectStats,
        deleteProject,
        createFormSuccess,
        loadCollection,
        loadCollectionItem,
        updateProject,
        createCredential,
        updateCredential,
        reorderProjectPages,
        addProjectPage,
        deleteProjectPage,
        deleteItemMediaItem,
        createItemMediaItem,
        updateItemMediaItem,
        sortItemMediaItems,
        addProjectUser,
        deleteProjectUser,
        updateProjectUserRole,
        createProjectRecordStatus,
        updateProjectRecordStatus,
        deleteProjectRecordStatus,
        updateProjectRecordStatusOrder,
        deleteProjectMapLayer
    };
};

projectActions.$inject = ['ProjectService', 'ProjectPagesService', 'ProjectMediaService', 'ProjectMapsService', 'CoreoAPI'];
export default projectActions;