<div>
    @if (newCollection) {
        <div class="w-full pb-5">
            <label class="block font-normal mb-2" for="name">Name</label>
            <input pInputText [autofocus]="true" id="name" [(ngModel)]="name" placeholder="Name" class="w-full" />
        </div>
    }
    @if (importType !== 'empty') {
        <div class="w-full pb-5 border-b border-grey-30">
            @if (file()) {
            <div class="flex flex-col gap-1">
                <p class="text-xs text-text-primary">Uploading file</p>
                <div class="h-11 flex items-center justify-between">
                    <p class="text-sm font-semibold truncate pr-5">{{ file().name }}</p>
                    <i class="fa-solid fa-circle-xmark text-danger cursor-pointer" (click)="clearFile()"></i>
                </div>
            </div>
            }
            @else {
            <p-button label="Choose {{ importTypeMap[importType].choose }} file to upload" [outlined]="true" size="large"
                (click)="chooseFile($event)" styleClass="w-full"></p-button>
            <input #fileInput type="file" [accept]="importTypeMap[importType].accept" required (change)="readFile($event)"
                class="sr-only opacity-0" />
            }
        </div>
        <div class="py-5 space-y-4">
            @switch (importType) {
                @case ('csv') {
                <p>Once you have selected your file, you will then be asked to select which a field (column) in your cvs you want to be used as your <strong>Key</strong> values, and which field you want to be used for the main <strong>Value</strong> (the name) of each list item.</p>
                <p>Alternatively you can set your csv up fields titled Key and Value. Each attribute should also have its own uniquely named field.</p>
                }
            }
            <!-- CSV -->
            @if (importType === 'csv' && fileOk()) {
                <div class="flex flex-col gap-2">
                    <label class="text-sm text-text-primary font-normal flex items-center justify-between" for="keyProperty">
                        <div>
                            <span class="block">Select Key property field</span>
                            <span class="text-[10px] block">(Each entry must be unique)</span>
                        </div>
                        <i class="fa-solid fa-circle-info text-text-secondary" pTooltip="Select a column in your csv containing value's you would like to be used as each item's key. This is a permanent label for your list items, which is stored in the data base, and cannot be changed, once set." tooltipPosition="left" [positionLeft]="-4"></i>
                    </label>
                    <p-dropdown id="keyProperty" [options]="csvColumns()" [(ngModel)]="keyProperty"
                        placeholder="Select key" appendTo="body"></p-dropdown>
                </div>
                <div class="flex flex-col gap-2">
                    <label class="text-sm text-text-primary font-normal flex items-center justify-between" for="valueProperty">
                        <span>Select Value property field</span>
                        <i class="fa-solid fa-circle-info text-text-secondary" pTooltip="Select a column in your csv containing the value's you would like to b e displayed as the main name of each list item." tooltipPosition="left" [positionLeft]="-4"></i>
                    </label>
                    <p-dropdown id="valueProperty" [options]="csvColumns()" [(ngModel)]="valueProperty"
                        placeholder="Select property" appendTo="body"></p-dropdown>
                </div>
                <div class="flex flex-col gap-2">
                    <label class="block text-sm text-text-primary font-normal" for="csvGeometryType">Select geometry type</label>
                    <p-dropdown id="csvGeometryType" [options]="csvGeometryTypeOptions" optionLabel="label" optionValue="key" [(ngModel)]="csvGeometryType" placeholder="Select geometry type" appendTo="body" (onChange)="clearCSVColumns()"></p-dropdown>
                </div>
                @if (csvGeometryType() === 'latlng') {
                <div class="flex flex-col gap-2">
                    <label class="block text-sm text-text-primary font-normal" for="csvGeometryLatColumn">Select latitude column</label>
                    <p-dropdown id="csvGeometryLatColumn" [options]="csvColumns()" [(ngModel)]="csvGeometryLatColumn" appendTo="body"></p-dropdown>
                </div>
                <div class="flex flex-col gap-2">
                    <label class="block text-sm text-text-primary font-normal" for="csvGeometryLngColumn">Select longitude column</label>
                    <p-dropdown id="csvGeometryLngColumn" [options]="csvColumns()" [(ngModel)]="csvGeometryLngColumn" appendTo="body"></p-dropdown>
                </div>
                }
                @if (csvGeometryType() === 'wkt') {
                <div class="flex flex-col gap-2">
                    <label class="block text-sm text-text-primary font-normal" for="csvGeometryWKTColumn">Select geometry column</label>
                    <p-dropdown id="csvGeometryWKTColumn" [options]="csvColumns()" [(ngModel)]="csvGeometryWKTColumn" appendTo="body"></p-dropdown>
                </div>
                }
                @if (csvGeometryType() === 'GeoJSON') {
                <div class="flex flex-col gap-2">
                    <label class="block text-sm text-text-primary font-normal" for="csvGeometryGeoJSONColumn">Select geometry column</label>
                    <p-dropdown id="csvGeometryGeoJSONColumn" [options]="csvColumns()" [(ngModel)]="csvGeometryGeoJSONColumn" appendTo="body"></p-dropdown>
                </div>
                }
                @if (csvGeometryType() !== 'none') {
                <div class="flex flex-col gap-2">
                    <label class="block text-sm text-text-primary font-normal" for="epsgCode">Select EPSG coordinate reference system</label>
                    <p-dropdown id="epsgCode" [options]="epsgCodeOptions" optionLabel="label" optionValue="key" [(ngModel)]="epsgCode" appendTo="body"></p-dropdown>
                </div>
                } 
            }
            <!-- Geojson -->
            @if (importType === 'geojson' && fileOk() && geoJsonPaths().length > 0) {
            <div class="flex flex-col gap-2">
                <label class="text-sm text-text-primary font-normal flex items-center justify-between" for="keyProperty">
                    <div>
                        <span class="block">Select Key property field</span>
                        <span class="text-[10px] block">(Each entry must be unique)</span>
                    </div>
                    <i class="fa-solid fa-circle-info text-text-secondary" pTooltip="Select a column in your csv containing value's you would like to be used as each item's key. This is a permanent label for your list items, which is stored in the data base, and cannot be changed, once set." tooltipPosition="left" [positionLeft]="-4"></i>
                </label>
                <p-dropdown id="keyProperty" [options]="geoJsonPaths()" [(ngModel)]="keyProperty"
                    placeholder="Select key" appendTo="body"></p-dropdown>
            </div>
            <div class="flex flex-col gap-2">
                <label class="text-sm text-text-primary font-normal flex items-center justify-between" for="valueProperty">
                    <span>Select Value property field</span>
                    <i class="fa-solid fa-circle-info text-text-secondary" pTooltip="Select a column in your csv containing the value's you would like to b e displayed as the main name of each list item." tooltipPosition="left" [positionLeft]="-4"></i>
                </label>
                <p-dropdown id="valueProperty" [options]="geoJsonPaths()" [(ngModel)]="valueProperty"
                    placeholder="Select property" appendTo="body"></p-dropdown>
            </div>
            }
            <!-- Shapefile -->
            @if (importType === 'shapefile' && shapefileOptions()) {
            <div class="flex flex-col gap-2">
                <label class="block text-sm text-text-primary font-normal">More than one shapefile found. Please choose one to import.</label>
                <p-dropdown [options]="shapefileOptions()" [(ngModel)]="shapefileToUse" placeholder="Select shapefile"
                    appendTo="body" (onChange)="updateShapefileInfo()"></p-dropdown>
            </div>
            }
    
            @if (importType === 'shapefile' && fileOk() && shapefileFields().length > 0) {
            <div class="flex flex-col gap-2">
                <label class="text-sm text-text-primary font-normal flex items-center justify-between" for="keyProperty">
                    <div>
                        <span class="block">Select Key property field</span>
                        <span class="text-[10px] block">(Each entry must be unique)</span>
                    </div>
                    <i class="fa-solid fa-circle-info text-text-secondary" pTooltip="Select a column in your csv containing value's you would like to be used as each item's key. This is a permanent label for your list items, which is stored in the data base, and cannot be changed, once set." tooltipPosition="left" [positionLeft]="-4"></i>
                </label>
                <p-dropdown id="keyProperty" [options]="shapefileFields()" [(ngModel)]="keyProperty"
                    placeholder="Select key" appendTo="body"></p-dropdown>
            </div>
            <div class="flex flex-col gap-2">
                <label class="text-sm text-text-primary font-normal flex items-center justify-between" for="valueProperty">
                    <span>Select Value property field</span>
                    <i class="fa-solid fa-circle-info text-text-secondary" pTooltip="Select a column in your csv containing the value's you would like to b e displayed as the main name of each list item." tooltipPosition="left" [positionLeft]="-4"></i>
                </label>
                <p-dropdown id="valueProperty" [options]="shapefileFields()" [(ngModel)]="valueProperty"
                    placeholder="Select property" appendTo="body"></p-dropdown>
            </div>
            }
            
            @if (processing()) {
                <div class="flex items-center justify-center">
                    <svg-icon src="/assets/icons/spinner.svg" class="h-5 w-5"></svg-icon>
                </div>
            }
            
            @if (error()) {
                <p class="text-sm text-danger">{{ error() }}</p>
            }
        </div>
    }
    <div class="flex items-center justify-end">
        <p-button label="{{ newCollection ? 'Create' : 'Import' }} {{ importTypeMap[importType].label }}" [disabled]="!canSubmit() || !!error()" [loading]="loading()" size="large" (click)="submit()"></p-button>
    </div>
</div>