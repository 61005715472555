import { Component, input, OnInit } from '@angular/core';
import { FormGroup, FormsModule } from '@angular/forms';
import { Question } from '../form.types';
import { CalendarModule } from 'primeng/calendar';
import { formatISO } from 'date-fns';

@Component({
    selector: 'app-date-picker',
    templateUrl: 'date-picker.component.html',
    standalone: true,
    imports: [
        FormsModule,
        CalendarModule
    ]
})

export class DatePickerComponent implements OnInit {

    question = input.required<Question>();
    form = input.required<FormGroup>();

    private _date: Date;

    public get date() {
        return this._date;
    }

    public set date(val: Date) {
        this._date = val;
        if (val) {
            let dateString: string;
            if (this.question().type === 'datetime') {
                dateString = formatISO(val);
            } else {
                dateString = formatISO(val, { representation: 'date' });
            }
            
            this.form().controls[this.question().path].setValue(dateString);
        } else {
            this.form().controls[this.question().path].setValue(null);
        }
    }

    ngOnInit() {
        let value = this.form().controls[this.question().path].value;

        if (this.question().config?.auto && !value) {
            switch (this.question().type) {
                case 'datetime': 
                case 'date': 
                    this.date = new Date();
                break;
                default: {
                    console.error('DO NOT KNOW HOW TO AUTO THIS TYPE');
                }
            }
        } else {
            this._date = value ? new Date(value) : null;
        }
    }
}