<p-confirmDialog styleClass="hide-close"></p-confirmDialog>

<div class="h-full w-full bg-background rounded-t-lg flex flex-col justify-start">
    <div class="min-h-[72px] max-h-[72px] px-5 bg-primary rounded-t-lg flex items-center justify-between">
        <h2 class="text-lg text-white font-semibold">{{ mode() === 'edit' ? 'Edit collection' : 'Create collection' }}</h2>
        <button class="flex-none h-8 w-8 rounded-full flex items-center justify-center bg-primary text-base text-white hover:bg-[#e9ecef] hover:text-primary" (click)="closeCheck($event)">
            <i class="pi pi-times"></i>
        </button>
    </div>
    
    @if (showCollectionItemDetail()) {
        <div class="h-[calc(100% - 72px)] w-full overflow-y-auto">
            <app-collection-item-detail
                [projectId]="projectId()"
                [collectionId]="collectionId()"
                [itemId]="collectionItemId()"
                [location]="location()"
                [mode]="mode()"
                [selectedNewItem]="selectedNewCollectionItem()"
                [newAttributes]="newCollectionAttributes()"
                (back)="showCollectionItemDetail.set(false)"
                (saveNewCollectionItem)="updateNewCollectionItem($event)"
                (deleteNewCollectionItem)="removeNewCollectionItem($event)"
                (deleteEditCollectionItem)="handleEditItemDelete()"
                (isDirty)="collectionItemDirty.set(true)"
                class="relative"
            ></app-collection-item-detail>
        </div>
    }
    @else {
        <app-collection-detail
            #collectionDetail
            (item)="itemSelect($event)"
            (save)="saveNewCollection()"
            class="bg-background h-[calc(100% - 72px)] w-full overflow-hidden"
        ></app-collection-detail>
    }
</div>