import { Component, computed, EventEmitter, inject, Output } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { InputNumberModule } from 'primeng/inputnumber';
import { InputSwitchModule } from 'primeng/inputswitch';
import { TooltipModule } from 'primeng/tooltip';
import { ProjectFormService } from '../project-form.service';
import { Attribute } from '../project-form.model';
import { NgClass } from '@angular/common';

type SliderConfig = {
    defaultValue: number;
    max: number;
    min: number;
    step: number;
}

@Component({
    selector: 'app-slider-settings',
    templateUrl: 'slider-settings.component.html',
    standalone: true,
    imports: [
        NgClass,
        FormsModule,
        InputSwitchModule,
        InputNumberModule,
        TooltipModule
    ]
})

export class SliderSettingsComponent {

    form = inject(ProjectFormService);

    attribute = this.form.selectedAttribute;

    @Output() update: EventEmitter<Partial<Attribute>> = new EventEmitter();

    config = computed(() => {
        const attribute = this.attribute();
        const config = attribute.config as SliderConfig;
        return {
            defaultValue: config.defaultValue ?? null,
            max: config.max ?? null,
            min: config.min ?? null,
            step: config.step ?? null
        };
    });

    defaultValue = computed(() => this.config().defaultValue);
    max = computed(() => this.config().max);
    min = computed(() => this.config().min);
    step = computed(() => this.config().step);
    minMaxError = computed(() => this.min() > this.max());

    type = computed(() => this.attribute().type);
    wholeNumbersOnly = computed(() => {
        const type = this.type();
        return type === 'integer';
    });

    wholeNumbersOnlyChanged(wholeNumbers: boolean) {
        const type = wholeNumbers ? 'integer' : 'float';
        this.update.emit({ type });
    }

    configChanged(obj: Partial<SliderConfig>) {
        this.update.emit({
            config: {
                ...this.config(),
                ...obj
            }
        });
    }
}