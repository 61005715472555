import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { DatePipe } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { FilterMetadata } from 'primeng/api';
import { ButtonModule } from 'primeng/button';
import { InputTextModule } from 'primeng/inputtext';
import { TableModule, TableLazyLoadEvent } from 'primeng/table';
import { CreateOrganisationComponent } from './create-organisation.component';
import { ApiService } from "src/app/core";
import { YesNoPipe } from "src/app/shared/pipes/yesno.pipe";
import { z } from 'zod';

const OrganisationTableItemSchema = z.object({
    id: z.number(),
    name: z.string(),
    slug: z.string(),
    createdAt: z.string(),
    freeTrialEnd: z.string().nullable(),
    freeTrialExpired: z.boolean(),
    tier: z.object({
        name: z.string()
    })
});

const OrganisationTableItemsSchema = z.object({
    organisations: OrganisationTableItemSchema.array(),
    count: z.number()
});
type OrganisationTableItem = z.infer<typeof OrganisationTableItemSchema>;

@Component({
    selector: 'app-admin-organisations',
    templateUrl: './admin-organisations.component.html',
    imports: [
        DatePipe,
        FormsModule,
        ButtonModule,
        InputTextModule,
        TableModule,
        CreateOrganisationComponent,
        YesNoPipe
    ],
    standalone: true,
    styles: [
        `:host{ @apply block h-full w-full overflow-hidden p-8;}`
    ]
})
export class AdminOrganisationsComponent implements OnInit {

    @Input() offset: string = "0";
    @Input() limit: string = "10";
    @Input() search: string = '';
    @Input() order: string;
    @Input() where: { [key: string]: any };

    @Output() organisation: EventEmitter<any> = new EventEmitter();
    @Output() change: EventEmitter<any> = new EventEmitter();

    first: number;
    rows: number;
    filters = {};
    sortField: string;
    sortOrder: number;

    organisations: OrganisationTableItem[] = [];
    totalOrganisations: number;
    loading: boolean = false;

    isVisibleDialog: boolean = false;

    private readonly allOrganisationsQuery: string = `query AAGetOrganisations($offset: Int!, $limit: Int!, $order: String!, $where: SequelizeJSON) {
    organisations(offset: $offset, limit: $limit, order: $order, where: $where) {
      id, name, slug, createdAt, freeTrialEnd, freeTrialExpired, tier { name }
    }
    count: organisationsCount(where: $where)
  }`;

    constructor(
        private apiService: ApiService
    ) { }

    ngOnInit() {
        console.log('ngOnInit', this.offset);
        this.rows = parseInt(this.limit);
        this.first = parseInt(this.offset);

        if (this.order) {
            this.sortField = this.order.replace('reverse:', '');
            this.sortOrder = this.order.startsWith('reverse:') ? -1 : 1;
        }

        if (this.where) {
            for (const k in this.where) {
                this.filters[k] = {
                    value: this.where[k],
                    matchMode: k === 'id' ? 'startsWith' : 'equals'
                };
            }
        }
    }

    loadOrganisations(event: TableLazyLoadEvent) {
        console.log('Loading event', event);
        this.loading = true;
        const field = event.sortField === 'freeTrialExpired' ? 'freeTrialEnd' : event.sortField;
        const order = `${event.sortOrder === -1 ? 'reverse:' : ''}${field}`;
        const where = {
            and: [
                event.filters['name'] && event.filters['name']['value']
                    ? { name: { iLike: `%${event.filters['name']['value']}%` } } : [],
                event.filters['slug'] && event.filters['slug']['value']
                    ? { slug: { iLike: `%${event.filters['slug']['value']}%` } } : []
            ]
        };

        const input = { offset: event.first, limit: event.rows, order, where }

        const compactFilters = Object.keys(event.filters).reduce((acc, key) => {
            if ((event.filters[key] as FilterMetadata).value) {
                acc[key] = (event.filters[key] as FilterMetadata).value;
            }
            return acc;
        }, {});

        this.change.emit({
            offset: event.first,
            limit: event.rows,
            order,
            q: Object.keys(compactFilters).length > 0 ? JSON.stringify(compactFilters) : ''
        });

        this.apiService.graphql(this.allOrganisationsQuery, input)
            .subscribe((response: any) => {
                console.log('HERE', response);
                try {
                    const parsedResponse = OrganisationTableItemsSchema.parse(response);
                    this.organisations = response.organisations;
                    this.totalOrganisations = response.count;
                } catch (e) {
                    console.warn(e.format());
                } finally {
                    this.loading = false;
                }

            });
    }

    rowSelect(event: any) {
        this.organisation.emit(event.id);
    }

    onVisibleChange(event: { isVisibleDialog: boolean; organisationId: number | null }) {
        this.isVisibleDialog = event.isVisibleDialog;
        if (event.organisationId) this.organisation.emit(event.organisationId);
    }
}