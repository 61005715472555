import { Component, computed, EventEmitter, inject, Output } from '@angular/core';
import { InputNumberModule } from 'primeng/inputnumber';
import { FormsModule } from '@angular/forms';
import { InputSwitchModule } from 'primeng/inputswitch';
import { InputTextModule } from 'primeng/inputtext';
import { ProjectFormService } from '../project-form.service';
import { Attribute } from '../project-form.model';

type MediaGalleryConfig = {
    min: number;
    max: number;
}

@Component({
    selector: 'app-photo-settings',
    templateUrl: 'photo-settings.component.html',
    standalone: true,
    imports: [
        FormsModule,
        InputNumberModule,
        InputSwitchModule,
        InputTextModule
    ]
})

export class PhotoSettingsComponent {

    form = inject(ProjectFormService);

    attribute = this.form.selectedAttribute;

    @Output() update: EventEmitter<Partial<Attribute>> = new EventEmitter();
    
    config = computed(() => {
        const attribute = this.attribute();
        const config = attribute.config as MediaGalleryConfig;
        return {
            min: config.min ?? null,
            max: config.max ?? null
        };
    });

    min = computed(() => this.config().min);
    max = computed(() => this.config().max);

    singleOnly = computed(() => {
        const attribute = this.attribute();
        return attribute.questionType === 'photo';
    });

    limitNumber = computed(() => {
        const min = this.min();
        const max = this.max();

        return min !== null || max !== null;
    });

    singleOnlyChanged(singleOnly: boolean) {
        if (singleOnly) {
            this.update.emit({
                questionType: 'photo',
                type: 'media',
                config: {}
            });
        } else {
            this.update.emit({
                questionType: 'mediaGallery',
                type: 'attachment',
                config: this.config()
            });
        }
    }
    
    limitChanged(limit: boolean) {
        if (!limit) {
            this.update.emit({config: { min: null, max: null }});
        } else {
            this.update.emit({config: { min: 0, max: 0 }});
            this.singleOnlyChanged(false);
        }
    }

    configChanged(obj: Partial<MediaGalleryConfig>) {
        this.update.emit({
            config: {
                ...this.config(),
                ...obj
            }
        });
    }
}