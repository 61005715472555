import { Component, computed, effect, ElementRef, inject, SecurityContext, signal, viewChild, ViewContainerRef } from '@angular/core';
import * as marked from 'marked';
import { DomSanitizer } from '@angular/platform-browser';
import { CommonModule } from '@angular/common';
import { CollectionItemService } from './collection-item.service';
import { LngLatBoundsLike, LngLatLike, Map as MapboxMap } from 'mapbox-gl';
import { environment } from 'src/environments/environment';
import { Feature } from 'geojson';
import bbox from '@turf/bbox';
import { MapsService } from '@core/services/maps.service';

@Component({
    selector: 'app-collection-item-preview',
    templateUrl: 'collection-item-preview.component.html',
    standalone: true,
    imports: [
        CommonModule
    ]
})

export class CollectionItemPreviewComponent {

    sanitizer = inject(DomSanitizer);
    collectionItemService = inject(CollectionItemService);
    mapsService = inject(MapsService);
    viewContainerRef = inject(ViewContainerRef);

    previewMap = viewChild<ElementRef>('previewMap');

    private map: MapboxMap;
    private resizeObserver: ResizeObserver;
    
    preview = this.collectionItemService.preview;
    geometry = computed(() => this.preview().geometry);

    constructor() {
        effect(() => {
            const geometry = this.geometry();
            const map = this.previewMap();
            if (!!geometry && !!map) {
                this.initMap();
            }
        });
    }

    images = computed(() => {
        const preview = this.preview();
        return preview.mediaItems.filter(item => {
            const type = item.type.split('/')[0];
            return type === 'image';
        }).sort((a, b) => a.sort - b.sort);
    });
    currentImageIndex = signal(0);
    currentImage = computed(() => {
        const images = this.images();
        const index = this.currentImageIndex();
        return images[index];
    });

    audio = computed(() => {
        const preview = this.preview();
       
        return preview.mediaItems.filter(item => {
            const type = item.type.split('/')[0];
            return type === 'audio';
        });
    });

    data = computed(() => {
        const preview = this.preview();
        const markedData = preview.data.filter(d => !!d.value).map(d => {
            return {
                ...d,
                value: this.sanitizer.sanitize(SecurityContext.HTML, marked.parse(`${d.value}`))
            }
        });
        return markedData;
    });

    private initMap() {
        const mapOptions: mapboxgl.MapboxOptions = {
            accessToken: environment.mapboxApiKey,
            container: this.previewMap().nativeElement,
            style: 'mapbox://styles/mapbox/streets-v12',
            projection: { name: 'mercator' },
        };

        const feature: Feature = {
            type: 'Feature',
            geometry: this.geometry(),
            properties: {}
        };

        if (feature.geometry.type === "Point") {
            mapOptions.center = feature.geometry.coordinates as LngLatLike;
            mapOptions.zoom = 16;
        } else {
            const bounds = bbox(feature.geometry);
            mapOptions.bounds = bounds as LngLatBoundsLike;
            mapOptions.fitBoundsOptions = { padding: 20 };
        }

        this.map = new MapboxMap(mapOptions);

        this.map.on('style.load', () => {
            this.map.addSource('item', {
                type: 'geojson',
                data: {
                    type: 'FeatureCollection',
                    features: [feature]
                }
            });

            if (feature.geometry.type === 'Point') {
                this.map.addLayer({
                    id: 'point',
                    source: 'item',
                    type: 'circle',
                    filter: [
                        'all',
                        ['!has', 'pc'],
                        ['==', '$type', 'Point']
                    ],
                    paint: {
                        'circle-color': '#C521E0',
                        'circle-radius': 10,
                        'circle-opacity': 0.5,
                        'circle-stroke-width': 1,
                        'circle-stroke-color': '#FFFFFF'
                    }
                });
            } else if (feature.geometry.type === 'LineString') {
                this.map.addLayer({
                    id: 'line',
                    source: 'item',
                    type: 'line',
                    paint: {
                        'line-color': '#F02F1D',
                        'line-width': 3
                    },
                    'filter': ['==', '$type', 'LineString']
                });
            } else if (feature.geometry.type === 'Polygon' || feature.geometry.type === 'MultiPolygon') {
                this.map.addLayer({
                    id: 'polygon-fill',
                    source: 'item',
                    type: 'fill',
                    paint: {
                        'fill-color': '#ff0000',
                        'fill-opacity': 0.3
                    },
                    'filter': ['==', '$type', 'Polygon']
                });
                this.map.addLayer({
                    id: 'polygon-outline',
                    source: 'item',
                    type: 'line',
                    paint: {
                        'line-color': '#FFFFFF',
                        'line-width': 1
                    },
                    'filter': ['==', '$type', 'Polygon']
                });
            }
        });

        this.map.scrollZoom.disable();
        this.resizeObserver = new ResizeObserver(() => this.map.resize());
        this.resizeObserver.observe(this.previewMap().nativeElement);
    }
}