import { computed, inject, Injectable, signal } from "@angular/core";
import { CollectionAttribute, CollectionItem, MediaItem } from "../collection.model";
import { Feature, FeatureCollection } from "geojson";
import { Geometry } from "geojson";
import { LngLatBoundsLike } from "mapbox-gl";
import { FeatureProperties } from "./collection-map/collection-map.component";
import { SortMode } from "./collection-table/collection-table.component";
import { ApiService } from "@core/services";
import { FormCollection } from "../../projects/project-form/project-form.model";
import { catchError, firstValueFrom, map, of, switchMap, take } from "rxjs";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { CoreoJob } from "@core/services/jobs.service";

@Injectable()
export class CollectionService {

    apiService = inject(ApiService);
    httpClient = inject(HttpClient);

    collectionId = signal<number>(null);
    projectId = signal<number>(null);
    geometric = signal(false);
    location = signal<'page' | 'modal'>(null);
    mode = signal<'create' | 'edit'>(null);
    mapFeatureCollection = signal<FeatureCollection<Geometry, FeatureProperties>>(null);
    mapCollectionBounds = signal<LngLatBoundsLike>(null);
    
    /** When creating a new collection from the form builder we have to store all the data before submitting it in one go */
    newCollectionName = signal('');
    newCollectionItems = signal<CollectionItem[]>([]);
    newCollectionSortMode = signal<SortMode>('manual');
    newCollectionSortAttributeId = signal<number>(null);
    newCollectionSortReverse = signal(false);
    newCollectionAttributes = signal<CollectionAttribute[]>([]);
    newCollectionJob = signal<CoreoJob>(null);

    /** Use to see if we need to show a warning when closing the modal */
    collectionDirty = signal(false);
    
    isGeometryCollection = computed(() => {
        const mode = this.mode();
        
        if (mode === 'edit') {
            return this.geometric();
        } else if (mode == 'create') {
            // const items = this.newCollectionItems();
            // return items.some(i => !!i.geometry);
            return false;
        }
    });

    canSaveNewCollection = computed(() => {
        const name = this.newCollectionName();
        const items = this.newCollectionItems();
        return !!name && items.length;
    });

    addNewCollectionItem(item: CollectionItem) {
        this.collectionDirty.set(true);
        this.newCollectionItems.update(items => [...items, item]);
    }

    updateNewCollectionItem(item: CollectionItem) {
        this.collectionDirty.set(true);
        this.newCollectionItems.update(items => items.map(a => {
            if (a.id === item.id) {
                return item;
            } else {
                return a;
            }
        }));
    }

    removeNewCollectionItem(id: number) {
        this.collectionDirty.set(true);
        this.newCollectionItems.update(items => items.filter(i => i.id !== id));
    }

    addNewCollectionAttribute(attribute: CollectionAttribute) {
        this.collectionDirty.set(true);
        this.newCollectionAttributes.update(attributes => [...attributes, attribute]);
        console.log('addNewCollectionAttribute', this.newCollectionAttributes());

    }

    updateNewCollectionAttribute(attribute: CollectionAttribute) {
        this.collectionDirty.set(true);
        this.newCollectionAttributes.update(attributes => attributes.map(a => {
            if (a.id === attribute.id) {
                return attribute;
            } else {
                return a;
            }
        }));        
    }

    addMapFeature(id: number, geometry: Geometry) {
        this.collectionDirty.set(true);
        const feature: Feature<Geometry, FeatureProperties> = {
            type: 'Feature',
            properties: {
                id,
            },
            geometry
        };

        this.mapFeatureCollection.update(fc => {
            return {
                ...fc,
                features: [...fc.features, feature]
            }
        });
    }

    removeNewCollectionAttribute(id: number) {
        this.collectionDirty.set(true);
        this.newCollectionAttributes.update(items => items.filter(a => a.id !== id));
    }

    getPresignedUrl(contentType: string, fileName: string) {
        const query = `
            query PresignedUrl($projectId: Int!, $fileName: String!, $contentType: String!) {
                url: presignedURL(projectId: $projectId, fileName: $fileName, contentType: $contentType)
            }
        `;

        return this.apiService.graphql<{ url: string }>(query, {
            projectId: this.projectId(),
            fileName,
            contentType
        }).pipe(
            take(1),
            catchError((e) => {
                console.error(e);
                return of(null);
            }),
            map(r => r.url)
        )
    }

    base64ToFile(base64: string): File {
        const arr = base64.split(',');
        const mime = arr[0].match(/:(.*?);/)[1];
        const bstr = atob(arr[1]);
        let n = bstr.length;
        const u8arr = new Uint8Array(n);
    
        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }
    
        return new File([u8arr], `${Date.now()}_icon`, { type: mime });
    }

    saveToAWS(file: File) {
        return firstValueFrom(
            this.getPresignedUrl(file.type, file.name).pipe(
                switchMap((presignedUrl: string) => {
                    const headers = new HttpHeaders({
                        'Content-Type': file.type
                    });
                    return this.httpClient.put(presignedUrl, file, { headers }).pipe(
                        map(() => presignedUrl.split('?')[0])
                    )
                })
            )
        );
    }

    async saveNewCollection(): Promise<FormCollection> {
        const name = this.newCollectionName();
        const sortMode = this.newCollectionSortMode();
        const sortAttributeId = this.newCollectionSortAttributeId();
        const sortReverse = this.newCollectionSortReverse();
        const newCollectionItems = this.newCollectionItems();
        const newCollectionAttributes = this.newCollectionAttributes();

        let sortAttributeIdx = null;
        if (sortAttributeId) {
            sortAttributeIdx = newCollectionAttributes.findIndex(a => a.id === sortAttributeId);
        }

        const items = await Promise.all(newCollectionItems.map(async (item) => {
            delete item.id;
            let mediaItems: MediaItem[] = []
            if (item.mediaItems?.length) {
                for (const mediaItem of item.mediaItems) {
                    delete mediaItem.id
                    const file = await fetch(mediaItem.url).then(r => r.blob().then(b => new File([b], mediaItem.name)));
                    const url = await this.saveToAWS(file);
                    mediaItems.push({
                        ...mediaItem,
                        url
                    });
                }
            }

            if (item.icon) {
                const file: File = this.base64ToFile(item.icon);
                item['icon'] = await this.saveToAWS(file);
            }

            return {
                ...item,
                mediaItems
            };
        })); // don't submit ids, submit mediaItems, submit icon
        const attributes = newCollectionAttributes.map(attribute => {
            delete attribute.id;
            return attribute;
        }); // don't submit ids;

        const input = {
            projectId: this.projectId(),
            name,
            items,
            attributes,
            sortMode,
            sortAttributeIdx,
            sortReverse
        }
        
        const query = `mutation AACreateNewCollection($input: CollectionCreateInput!){
            createCollection(input: $input){
                id
                name
                geometric
                items{
                    key
                    value
                    data
                }
            }
        }`;    
        
        return firstValueFrom(this.apiService.graphql<{ createCollection: FormCollection }>(query, { input }).pipe(
            catchError((e) => {
                console.error(e);
                return of(null);
            }),
            map(res => (res.createCollection))
        ));
    }
}
