import { ChangeDetectionStrategy, Component, computed, inject, input } from "@angular/core";
import { ProjectFormService } from "./project-form.service";
import { Attribute } from "./project-form.model";
import { ProjectFormConditionsSummaryRuleComponent } from "./project-form-conditions-summary-rule.component";
import { SvgIconComponent } from "angular-svg-icon";
import { NgClass } from "@angular/common";

@Component({
    selector: 'app-project-form-conditions-summary',
    templateUrl: './project-form-conditions-summary.component.html',
    standalone: true,
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [
        ProjectFormConditionsSummaryRuleComponent,
        SvgIconComponent,
        NgClass
    ]
})
export class ProjectFormConditionsSummaryComponent {

    form = inject(ProjectFormService);

    attribute = input.required<Attribute>();

    conditions = computed(() => this.attribute().conditions);
    any = computed(() => this.conditions().any);
    rules = computed(() => this.conditions().rules);

    hasErrors = computed(() => {
        const attribute = this.attribute();
        const rules = this.rules();
        const questions = this.form.attributes().filter(a => a.formId === attribute.formId);
        const errors = this.form.validateAttributeConditions(rules, questions, attribute);
        const hasErrors = Object.keys(errors).length > 0;
        return hasErrors;
    });
}
