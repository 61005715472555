import { NgClass } from '@angular/common';
import { Component, computed, effect, EventEmitter, inject, OnDestroy, Output } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ButtonModule } from 'primeng/button';
import { DropdownModule } from 'primeng/dropdown';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { catchError, interval, of, Subscription, switchMap } from 'rxjs';
import { ApiService } from 'src/app/core';
import { CoreoJob, JobsService } from '../../../../core/services/jobs.service';
import { Attribute } from '../project-form.model';
import { ProjectFormService } from '../project-form.service';
import { ListCreateEditModalComponent } from './list-create-edit-modal.component';

@Component({
    selector: 'app-list-selection',
    templateUrl: 'list-selection.component.html',
    standalone: true,
    imports: [
        NgClass,
        FormsModule,
        ButtonModule,
        DropdownModule
    ],
    providers: [
        DialogService
    ]
})

export class ListSelectionComponent implements OnDestroy {

    form = inject(ProjectFormService);
    apiService = inject(ApiService);
    dialogService = inject(DialogService);
    jobService = inject(JobsService);

    @Output() update: EventEmitter<Partial<Attribute>> = new EventEmitter();
    
    projectId = this.form.projectId;
    attribute = this.form.selectedAttribute;
    collections = this.form.collections;

    collectionId = computed(() => this.attribute().collectionId);

    collectionName = computed(() => {
        const id = this.collectionId();
        if (id) {
            return this.collections().find(c => c.id === id).name;
        } else {
            return '';
        }
    });

    listType = computed(() => {
        const attribute = this.attribute();
        return attribute.questionType === 'modal' ? 'searchable list' : 'multiple choice';
    });

    items = computed(() => {
        const id = this.collectionId();
        if (!id) {
            return [];
        }
        const collections = this.collections();
        const items = collections.find(c => c.id === id).items;
        if (items) {
            return items;
        }
    });

    answers = computed(() => {
        const items = this.items()
        if (items?.length) {
                const answers = items.slice(0, 5);
                const answersString = answers.map(a => a.value).join(', ');
                const countString = items.length > 6 ? `...(${items.length - 6} more)` : '';
                return `${answersString}${countString}`;
        } else {
            return null;
        }
    });

    saved = computed(() => this.attribute().id > 0);

    private dialogRef: DynamicDialogRef;
    private pollingSubscription: Subscription;

    constructor() {
        effect(() => {
            const items = this.items();
            if (typeof items === 'undefined') {
                this.form.loadCollectionItems(this.collectionId());
            }
        });
    }

    ngOnDestroy(): void {
        if (this.dialogRef) {
            this.dialogRef.close();
        }
    }

    selectCollection(id: number) {
        this.update.emit({collectionId: id});
    }

    openListModal(id: number = null) {
        this.dialogRef = this.dialogService.open(ListCreateEditModalComponent, {
            data: {
                collectionId: id,
                projectId: this.projectId(),
            },
            closeOnEscape: false,
            showHeader: false,
            modal: true,
            height: '90vh',
            width: '90vw',
            contentStyle: { 'padding': '0px', 'overflow': 'hidden', 'background': 'transparent', 'position': 'relative' }
        });

        /** On close load collection as it will be new or there may be changes */ 
        this.dialogRef.onClose.subscribe((result: { collectionId: number; job: CoreoJob }) => {
            const { collectionId, job } = result;
            if (result && collectionId) {
                /** if there's a collection id either it's an existing collection but may have updates so load it, else it's a newly imported collection so load it and select it */
                const selectCollection = id !== collectionId;
                /** If not the same id then it's a new collection so select it */
                this.form.loadCollection(collectionId, selectCollection);
            } else if (result && job) {
                /** The modal has been closed but the job is still running watch for the job to finish then load the collection */
                this.startCollectionJobPolling(job.id);
            }   
        });
    }

    startCollectionJobPolling(id: number): void {
        const pollingInterval = 5000;
        this.pollingSubscription = interval(pollingInterval).pipe(
            switchMap(() => this.jobService.getJobs().pipe(
                catchError(error => {
                    console.error('Poll error', error);
                    return of([]);
                })
            )
            )).subscribe(response => {
                const collectionJob = response.find(j => j.id === id);
                if (collectionJob) {
                    if (collectionJob.status === 'complete' && collectionJob.url) {
                        this.form.loadCollection(parseInt(collectionJob.url), false);
                        this.stopPolling();
                    }
                }
            });
    }

    private stopPolling(): void {
        if (this.pollingSubscription) {
            this.pollingSubscription.unsubscribe();
            this.pollingSubscription = undefined;
        }
    }
}