<p-confirmDialog [closable]="false" styleClass="w-[580px] text-xs text-text-primary" />
<div class="w-full h-full flex flex-col">
    <app-project-form-toolbar />
    <div class="flex flex-grow overflow-hidden">
        <app-project-form-questions />
        <app-project-form-builder [formId]="formId" />
        @if (selectedAttribute()) {
            <app-project-form-details />
        }
    </div>
</div>