@if (recordGeometry() && !noMap()) {
    <div class="relative h-72 w-full">
        <app-record-map 
            [feature]="feature()"
            [baseStyleName]="mapStyleName()"
            (onStyleChanged)="onMapStyleChanged.emit($event)"
        ></app-record-map>
    </div>
}
@if (recordData().length > 0) {
    <div class="p-7">

        <ul>
            @for (data of recordData(); track data) {
                <li class="p-3" [style.background-color]="data.highlight">
                    <p class="text-xs font-normal text-text-secondary uppercase mb-2 leading-none">{{ data.attribute.label }}</p>
                    @if (data.attribute.type === 'media' || data.attribute.type === 'attachment') {
                        @if (data.attribute.questionType === 'signature') {
                            <div class="flex items-center justify-start gap-4">
                                @for (attachment of data.value; track attachment) {
                                    <div class="relative z-0 h-[187px] aspect-video p-4 rounded-md bg-grey-20 shadow-panel overflow-hidden">
                                        <img [src]="attachment.url | twicPic:'resize=320'" alt="" class="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 max-h-[187px]">
                                    </div>
                                }
                            </div>
                        }
                        @else if (data.attribute.questionType === 'mediaGallery' || data.attribute.questionType === 'photo') {
                            <div class="flex items-center justify-start gap-4 flex-wrap">
                                @for (attachment of data.value; track attachment) {
                                    <div [ngClass]="{ 'hover:cursor-pointer': !readonly }" class="relative z-0 w-[187px] aspect-square rounded-md bg-grey-20 shadow-panel overflow-hidden" (click)="clickImage(data.attribute.label, data.attribute.path, data.value, attachment)">
                                        <img [src]="attachment.url | twicPic:'resize=320'" alt="" class="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 max-h-[187px] max-w-[187px]">
                                    </div>
                                }
                            </div>
                        }
                        @else if (data.attribute.questionType === 'audio') {
                            <div class="flex flex-col gap-3">
                                @for (attachment of data.value; track attachment) {
                                    <div>
                                        <audio controls [src]="attachment.url"></audio>
                                    </div>
                                }
                            </div>
                        }
                    }
                    @else if (data.attribute.type === 'multiselect') {
                        <p class="text-sm font-normal text-text-primary leading-none">
                            @for (item of data.value; track item; let last = $last) {
                                {{ item }}{{ last ? '' : ',' }}
                            }
                            @empty {
                                -
                            }
                        </p>
                    }
                    @else if (data.attribute.questionType === 'association' || data.attribute.questionType === 'child') {
                        <div class="space-y-3">
                            @for (associate of data.value; track associate) {
                                <button (click)="clickRecord(associate.id)" class="h-12 w-full px-4 flex items-center justify-between border border-grey-30 rounded-[4px]">
                                    <p class="text-sm font-normal text-text-primary">{{ associate.title }}</p>
                                    <i class="fas fa-chevron-right text-grey-50"></i>
                                </button>
                            }
                        </div>
                    }   
                    @else {
                        <p class="text-sm font-normal text-text-primary leading-none">{{ data.value ?? '-' }}</p>
                    }
                </li>
            }
        </ul>
        @if (otherAssociates().length) {
            <ul>
                @for (item of otherAssociates(); track item) {
                    <li class="p-3">
                        <p class="text-xs font-normal text-text-secondary uppercase mb-2 leading-none">{{ item.label }}</p>
                        @for (associate of item.associates; track associate; let last = $last) {
                            <button (click)="clickRecord(associate.id)" class="h-12 w-full px-4 flex items-center justify-between border border-grey-30 rounded-[4px]" [ngClass]="{'mb-3': !last}">
                                <p class="text-sm font-normal text-text-primary">{{ associate.title }}</p>
                                <i class="fas fa-chevron-right text-grey-50"></i>
                            </button>
                        }
                    </li>
                }
            </ul>
        }
    </div>
}
@else {
    <div class="min-h-60 h-full w-full pt-4">
        <div class="flex items-center justify-center">
            <p-progressSpinner styleClass="h-8 w-8" class="h-8 w-8"></p-progressSpinner>
        </div>
    </div>
}