import { CommonModule } from '@angular/common';
import { Component, computed, inject, OnInit, signal } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ApiService } from '@core/services';
import { ButtonModule } from 'primeng/button';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { InputTextModule } from 'primeng/inputtext';
import { catchError, of, take } from 'rxjs';

@Component({
    selector: 'app-project-forms-create',
    templateUrl: './project-forms-create.component.html',
    standalone: true,
    imports: [
        CommonModule,
        FormsModule,
        InputTextModule,
        ButtonModule
    ]
})

export class ProjectFormsCreateComponent implements OnInit {

    dialogRef = inject(DynamicDialogRef);
    dialogConfig = inject(DynamicDialogConfig);
    apiService = inject(ApiService);

    projectId = signal<number>(null);
    loading = signal(false);
    name = signal('');
    title = signal('');

    canSubmit = computed(() => {
        return !!this.name() && !!this.title();
    });

    ngOnInit() {
        const { data } = this.dialogConfig;
        this.projectId.set(data['projectId']);
    }

    create() {
        this.loading.set(true);
        const name = this.name();
        const title = this.title()
        const projectId = this.projectId()

        const query = `mutation AACreateForm($input: createSurveyInput!){
            createSurvey(input: $input){
                id
                allowMemberUpdate
                allowOwnRecordDelete
                mapSort
                mapVisible
                name
                private
                projectId
                secondaryTitleAttributeId
                shapefileExportAvailable
                slug
                sort
                style
                thankyou
                title
                titleAttributeId
                verification
                visible
            }
        }`;

        const input = {
            projectId,
            name,
            title
        }

        this.apiService.graphql<{ createSurvey: { id: number } }>(query, { input }).pipe(
            take(1),
            catchError((e) => {
                console.error(e);
                return of(null);
            })
        ).subscribe(res => {
            if (!!res) {
                this.dialogRef.close(res.createSurvey);
            }
            this.loading.set(false);
        });
    }
}