<div class="flex-grow flex items-center justify-center bg-white relative h-full w-full">

    <div class="max-w-md w-full px-4">
        @switch(state()){

        @case("login"){
        <h1 class="font-semibold text-2xl mb-[30px]">Sign in to Coreo</h1>
        <form [formGroup]="loginForm" (ngSubmit)="login()">
            <div class="flex flex-col gap-1 mb-6">
                <label>Email</label>
                <input name="email" type="email" pInputText autocomplete="email" required formControlName="email"
                    placeholder="Enter your email">
            </div>

            <div class="flex flex-col gap-1 mb-4">
                <label>Password</label>
                <div class="relative">
                    <input [type]="passwordType()" pInputText autocomplete="current-password" required
                        formControlName="password" placeholder="Enter your password" class="w-full">
                    <i class="absolute right-2 top-1/2 transform -translate-y-1/2 fa-solid text-grey-40"
                        (click)="togglePasswordType()"
                        [ngClass]="{'fa-eye': passwordType() === 'password', 'fa-eye-slash': passwordType() === 'text'}"></i>
                </div>
            </div>

            <a class="block font-semibold text-sm text-center w-full my-4 text-primary" ui-sref="passwordreset"
                href="/passwordreset">Forgot your password</a>

            @if(loginError()){
            <p class="bg-danger-lighter text-danger p-4 rounded-md font-bold my-4 text-center"> {{loginError()}}</p>
            }

            <p-button size="large" label="Sign In" class="block" type="submit" [disabled]="!loginForm.valid"
                [loading]="submitting()"></p-button>

            <div class="flex justify-between mt-6 mb-14">
                <a class="text-primary text-sm" ui-sref="oauth" href="/oauth">Log in with Google or Facebook</a>
                <a class="text-primary text-sm" ui-sref="sso" href="/sso">Use Single Sign-On</a>
            </div>
            
            <p class="text-xs text-text-primary text-center mb-4">Don't have an account yet?</p>

            <a href="/signup"
                class="h-11 w-full border border-primary rounded-md flex items-center justify-center text-primary text-sm font-semibold">Sign
                up to Coreo</a>
        </form>
        }

        @case("2fa"){
        <a class="inline-block text-sm text-primary font-semibold mb-14 cursor-pointer" (click)="state.set('login')"><i
                class="fa-solid fa-chevron-left mr-2"></i>Sign in page</a>
        <h1 class="font-semibold text-2xl mb-2 text-grey-100">Two-Factor Authentication</h1>
        <p class="text-text-primary text-xs mb-7">Enter the verification code from your authenticator app</p>
        <app-two-fa-input #twoFAInput label="Verification code" [verifying]="verifying2FA()"
            (codeValid)="mfa($event)"></app-two-fa-input>
        <a class="inline-block w-full text-sm text-primary font-semibold text-center cursor-pointer"
            (click)="state.set('2faRecover')">Verify with recovery code</a>
        }

        @case("2faRecover") {
        <a class="inline-block text-sm text-primary font-semibold mb-14 cursor-pointer" (click)="state.set('login')"><i
                class="fa-solid fa-chevron-left mr-2"></i>Sign in page</a>
        <h1 class="font-semibold text-2xl mb-2 text-grey-100">Enter recovery code</h1>
        <p class="text-text-primary text-xs mb-7">Enter one of your single use recovery codes</p>
        <form [formGroup]="recoverForm" (ngSubmit)="recover()" class="mb-4">
            <div class="flex flex-col mb-5">
                <label class="text-text-primary text-xs mb-1">Recovery code</label>
                <input type="text" pInputText required formControlName="code" placeholder="Recovery code">
            </div>
            <p-button size="large" label="Verify" class="block" [disabled]="!recoverForm.valid"
                type="submit"></p-button>
        </form>
        <div class="h-8">
            @if(recoverError()){
            <p class="text-danger">There was an error with your code, please try again. Remember each recovery code can
                only be used once.</p>
            }
        </div>
        <a class="inline-block w-full text-sm text-primary font-semibold text-center cursor-pointer"
            (click)="state.set('2fa')">Verify with authenticator</a>
        }
        }

    </div>
</div>