<p-confirmDialog></p-confirmDialog>
<p-dialog [(visible)]="_isVisibleUpdatePlanDialog" [draggable]="false" [modal]="true" [resizable]="false" class="p-0 overscroll" (onHide)="onHideDialog()" [closable]="isModalClosable()">
    <ng-template pTemplate="header">
        <p class="text-lg font-semibold">{{ isProcessingUpdate() ? 'Updating plan' : modalHeader() }}</p>
        @if(step() === 'select-plan' && !isSubscription()) {
        <div class="absolute top-9 left-1/2 -translate-y-1/2 -translate-x-1/2 rounded-lg border border-[#D3DBE3] bg-white w-[190px]">
            <div class="flex px-1 py-1.5">
                @for (option of intervalOptions; track $index) {
                <div class="flex-1 px-2 py-1 text-[10px] text-center cursor-pointer uppercase rounded font-semibold tracking-wide text-[#878F97] {{intervalOption() === option.name ? 'bg-primary text-white' : ''}}"
                    (click)="intervalOption.set(option.name)">
                    {{ option.label }}
                </div>
                }
            </div>
        </div>
        }
    </ng-template>
    @if (isProcessingInvoice() || isProcessingUpdate()) {
    <!-- LOADER -->
    <div class="flex flex-col justify-center h-80 w-[510px] p-4 gap-4">
        <div class="flex justify-center items-center max-h-24">
            <div class="relative h-24 max-w-24 rounded-full flex items-center justify-center animate-spin"
                style="background: conic-gradient(#6CA9FF 0% 25%, #0069DF 25% 100%);">
                <div class="w-16 h-16 bg-white rounded-full"></div>
            </div>
        </div>
        <span class="text-sm text-center text-[#444444]">
            {{isProcessingUpdate() ? 'Updating your Coreo Plan' : 'Fetching pricing data'}}
        </span>
    </div>
    } @else {
        @switch(step()){
            <!-- SELECT PLAN -->
            @case ('select-plan') {
            <div class="flex flex-col flex-grow w-[960px] xl:w-[1160px] relative p-5" [class.pt-10]="isFreeTrial()">
                <!-- TRIAL BANNER -->
                @if (isFreeTrial()) {
                <div class="w-full bg-[#CD5642] text-center text-xs text-white font-medium p-1 absolute top-0 left-0">
                    @if (isFreeTrialExpired()) {
                        Your free trial has expired
                    } @else if (trialTimeLeft()) {
                        You have {{ trialTimeLeft() | dateToNow }} left on your free trial. Select a plan to keep using Coreo.
                    }
                </div>
                }
        
                <div class="flex items-center space-x-4 border-b border-[#D3DBE3] pb-5 mb-5">
                    <div class="flex items-center justify-between relative">
                        <div class="flex flex-col justify-center items-center">
                            <p-inputNumber [showButtons]="true" buttonLayout="horizontal" spinnerMode="horizontal" [formControl]="seatsPlanControl"
                                [min]="1" [step]="1" styleClass="items-center" 
                                inputStyleClass="border-none w-[58px] font-semibold text-2xl text-center focus:ring-0 {{seatsFilled() > planSeats() ? 'text-[#CC5642]' : 'text-[#444444]'}}" 
                                decrementButtonClass="bg-white w-10 py-4 border-2 border-primary rounded-md text-primary text-base hover:bg-[#F2F8FE] {{planSeats() < 2 ? 'opacity-[0.4] pointer-events-none' : ''}}" 
                                incrementButtonClass="bg-white w-10 py-4 border-2 border-primary rounded-md text-primary text-base hover:bg-[#F2F8FE]" 
                                incrementButtonIcon="fa-solid fa-plus" decrementButtonIcon="fa-solid fa-minus"/>
                            <p class="text-sm text-[#444444] select-none">Select the <b class="font-semibold">number of seats</b> to be include in your chosen plan</p>
                            <span class="text-xs select-none {{seatsFilled() > planSeats() ? 'text-[#CC5642]' : 'text-[#878F97]'}}">
                                You are currently filling {{ seatsFilled() }} seats of your plan
                                @if(seatsFilled() > planSeats()) {
                                <span class="font-semibold underline cursor-pointer" (click)="onHideDialog(planSeats())">Remove {{seatsFilled() - planSeats()}} member to continue</span>
                                }
                            </span>
                        </div>
                        @if(currentOrganisationTier()?.addons?.length && currentAddonSeats() === 0) {
                        <p-button type="button" size="large" label="Add UKHab licences" [outlined]="true" (click)="addAddonForCurrentOrganisationTier()" styleClass="absolute right-0 top-1/2 -translate-y-1/2" />
                        } @else if(currentOrganisationTier()?.addons?.length && currentAddonSeats()) {
                        <div class="flex flex-col justify-center items-center">
                            <p-inputNumber [showButtons]="true" buttonLayout="horizontal" spinnerMode="horizontal" [formControl]="seatsAddonControl"
                                [min]="0" [max]="planSeats()" [step]="1" styleClass="items-center"  
                                inputStyleClass="border-none w-[58px] font-semibold text-[#444444] text-2xl text-center focus:ring-0 {{planSeats() < addonSeats() ? 'text-[#CC5642]' : 'text-[#444444]'}}" 
                                decrementButtonClass="bg-white w-10 py-4 border-2 border-primary rounded-md text-primary text-base hover:bg-[#F2F8FE] {{addonSeats() < 1 ? 'opacity-40 pointer-events-none' : ''}}" 
                                incrementButtonClass="bg-white w-10 py-4 border-2 border-primary rounded-md text-primary text-base hover:bg-[#F2F8FE] {{addonSeats() >= planSeats() ? 'opacity-40 pointer-events-none' : ''}}" 
                                incrementButtonIcon="fa-solid fa-plus" decrementButtonIcon="fa-solid fa-minus"/>
                            <p class="text-sm text-[#444444] select-none">Select the <b class="font-semibold">number of UKHab licences</b> to be include in your plan</p>
                            <span class="text-xs select-none {{planSeats() < addonSeats() ? 'text-[#CC5642]' : 'text-[#878F97]'}}">
                                UKHab licences cannot exceed you total number of plan seats
                            </span>
                        </div>
                        }
                    </div>
                </div>
            
                <div class="flex flex-wrap items-start items-stretch gap-5">
                    @for(option of tierOptions(); track option.id; let i = $index) {
                    <div class="flex flex-col justify-between items-start border rounded-lg border-[#D3DBE3]">
                        <div class="p-4 w-full">
                            <p class="w-full text-[10px] font-semibold uppercase {{option.isCurrentTierOption ? 'text-[#009E19]' : 'text-grey-50'}}">
                            @if (option.isCurrentTierOption) {
                                Current Plan {{ isFreeTrial() ? '- Free Trial'  : '' }}
                            } @else {
                                Plan
                            }
                            </p>
                            <p class="text-lg font-semibold text-[#444444]">{{ option.name }}</p>
                            <p class="flex gap-1 items-center text-xs font-normal text-[#444444] h-5">
                                @if (intervalOption() === 'year') {
                                    {{ option.pricePerSeat / 12 | currency: isIntegerPrice(option.pricePerSeat / 12) ? 0 : 2 }} 
                                    per user per month
                                } @else {
                                    {{ option.pricePerSeat | currency }} 
                                    per user per {{ intervalOption() }}
                                }
                                @if(!isSubscription() && intervalOption() === 'year') {
                                <span class="px-1.5 py-0.5 bg-[#57B771] rounded-lg text-[10px] text-white font-semibold uppercase">You save {{ option.savedPercentage }}%</span>
                                }
                            </p>
                            @if(addonSeats() > 0 && !option.isAddons) {
                                <p class="text-xs text-center font-normal text-[#CC5642] h-12 flex items-center justify-center mt-8">You cannot choose this plan because you have purchased UKHab licences. To select this plan you will need to remove your UKHab licences first</p>
                                <div class="w-full pt-4">
                                    <p-button type="button" size="large" severity="danger" [outlined]="true" label="Remove UKHab from current plan" class="w-full" styleClass="w-full" (click)="seatsAddonControl.setValue(0)" />
                                </div> 
                            } @else if (addonSeats() <= 0 && ukhabProjects()?.length > 0 && !option.isAddons) {
                                <p class="text-xs text-center font-normal text-[#CC5642] h-12 flex items-center justify-center mt-8">You cannot choose this plan because you have existing UKHab projects. To select this plan you will need to remove your UKHab projects first</p>
                                <div class="w-full pt-4">
                                    <p-button type="button" size="large" [label]="isFreeTrial() ? 'Choose this plan' : option.isCurrentTierOption ? 'Update current plan' : 'Change to this plan'" 
                                        class="w-full" styleClass="w-full" [disabled]="true"/>
                                </div>
                            } @else {
                            <div class="h-12 flex flex-col justify-center mt-8">
                                <div class="flex gap-1 items-center">
                                    <span class="text-[26px] font-semibold text-[#444444]">{{ (option.pricePerSeat * planSeats() + (option.isAddons ? (addonSeats() * addonOption().pricePerSeat) : 0)) | currency }}</span>
                                    <span class="text-sm font-normal text-[#444444]">per {{ intervalOption() }}</span>
                                </div>
                                <p class="text-sm font-normal text-[#878F97]">
                                    @if (addonSeats() > 0) {
                                        {{ planSeats() }} seat{{ planSeats() > 1 ? 's' : '' }} ({{ planSeats() * option.pricePerSeat | currency }})
                                        and
                                        {{ addonSeats() }} UKHab licence{{ addonSeats() > 1 ? 's' : '' }} ({{ addonSeats() * addonOption().pricePerSeat | currency }})
                                    } @else {
                                        {{ planSeats() }} seat{{ planSeats() > 1 ? 's' : '' }}
                                    }
                                </p>
                            </div>
                            <div class="w-full pt-4">
                                <p-button type="button" size="large" [label]="isFreeTrial() ? 'Choose this plan' : option.isCurrentTierOption ? 'Update current plan' : 'Change to this plan'" 
                                    class="w-full" styleClass="w-full" (click)="selectPlan(option, $event)" [disabled]="(seatsFilled() > planSeats()) || (planSeats() < addonSeats()) || !option.isAvailableForSelection"/>
                            </div>
                            }
                        </div>
                        <div class="w-full h-3 {{option.isCurrentTierOption ? 'bg-[#CBECD1]' : 'bg-[#F6F6F6]'}}"></div>
                        <div class="flex flex-col justify-between p-4 w-full">
                            <div class="flex flex-col gap-4">
                                <p class="text-sm font-semibold text-[#000000]">Features</p>
                                <ul class="list-disc space-y-1 text-xs text-[#000000] pl-[18px]">
                                    @for (feature of option.features; track $index) {
                                    <li>{{ feature }}</li>
                                    }
                                </ul>
                            </div>
                            @if(option.isAddons) {
                            <div class="flex justify-start items-end pt-4">
                                <div class="flex ustify-start items-center gap-1">
                                    <img src="./assets/images/ukhab_logo.png" alt="ukhab_logo" class="h-[20px]">
                                    <span class="font-normal text-[10px]">seats available with this package</span>
                                </div>
                            </div>
                            }
                        </div>
                    </div>
                    }
                </div>
            </div>
            }
            <!-- SELECT ADDON -->
            @case ('select-addon') {
            <div class="flex flex-col flex-grow w-[960px] gap-5 p-5 h-auto">
                <div class="flex flex-col justify-center items-center">
                    <p-inputNumber [showButtons]="true" buttonLayout="horizontal" spinnerMode="horizontal" [formControl]="seatsAddonControl"
                        [min]="0" [max]="planSeats()" [step]="1" styleClass="items-center"  
                        inputStyleClass="border-none w-[58px] font-semibold text-[#444444] text-2xl text-center focus:ring-0 text-[#444444]" 
                        decrementButtonClass="bg-white w-10 py-4 border-2 border-primary rounded-md text-primary text-base hover:bg-[#F2F8FE] {{addonSeats() < 1 ? 'opacity-40 pointer-events-none' : ''}}" 
                        incrementButtonClass="bg-white w-10 py-4 border-2 border-primary rounded-md text-primary text-base hover:bg-[#F2F8FE] {{addonSeats() >= planSeats() ? 'opacity-40 pointer-events-none' : ''}}" 
                        incrementButtonIcon="fa-solid fa-plus" decrementButtonIcon="fa-solid fa-minus"/>
                    <p class="text-sm text-[#444444] select-none">Select the <b class="font-semibold">number of UKHab licences</b> to be include in your chosen plan</p>
                    <span class="text-xs text-[#878F97] select-none">
                        (UKHab licences cannot exceed you total number of plan seats)
                    </span>
                </div>
                <div class="border rounded-lg border-[#D3DBE3] rounded-lg">
                    <div class="flex items-center justify-center p-5">
                        <div class="flex flex-col">
                            <span class="text-[10px] text-[#878F97] font-semibold uppercase">add-on</span>
                            <span class="text-lg text-[#444444] font-semibold">UKHab App and BNG Platform</span>
                            <span class="text-sm text-[#444444] font-normal">{{addonOption().pricePerSeat | currency}} per user per {{ intervalOption() }}</span>
                            <span class="text-xs text-[#878F97] font-normal mb-4">{{ addonSeats() }} UKHab licences</span>
                            <span class="text-base text-[#444444] font-semibold">Total extra {{ addonOption().pricePerSeat * addonSeats() | currency }} per {{ intervalOption() }}</span>
                        </div>
                        <div class="flex flex-col items-center justify-center bg-[#F2F8FE] rounded-lg h-28">
                            <span class="text-xs text-[#878F97] font-normal">{{selectedPlan()?.name}} Plan + UKHab total</span>
                            <span class="text-base text-[#444444] font-semibold">
                                {{ ((addonOption().pricePerSeat * addonSeats()) + (selectedPlan()?.pricePerSeat * planSeats())) | currency }} per {{ intervalOption() }}
                            </span>
                        </div>
                    </div>
                    <div class="w-full h-3 bg-[#F6F6F6]"></div>
                    <div class="flex flex-col gap-4 p-5">
                        <p class="text-sm font-semibold text-[#000000]">What you get</p>
                        <ul class="list-disc space-y-1 text-xs text-[#000000] pl-[18px] flex flex-wrap">
                            @for (feature of addonOption().features; track $index) {
                            <li class="basis-1/2 box-border">{{ feature }}</li>
                            }
                        </ul>
                    </div>
                </div>
                <div class="flex w-full gap-4">
                    <div class="flex-1">
                        <p-button type="button" size="large" [label]="isAddAddonForCurrentOrganisationTier() ? 'Back' : 'Change plan'" class="w-full" styleClass="w-full" [outlined]="true" (click)="goBack()"></p-button>
                    </div>
                    <div class="flex-1">
                        <p-button type="button" size="large" [label]="isAddAddonForCurrentOrganisationTier() ? 'Add UKHab' : addonSeats() > 0 ? 'Payment summary' : 'Skip and go to payment summary'" 
                            [disabled]="isAddAddonForCurrentOrganisationTier() && addonSeats() <= 0" class="w-full" styleClass="w-full" (click)="paymentSummary()"></p-button>
                    </div>
                </div>
            </div>
            }
            <!-- PAYMENT SUMMARY -->
            @case ('payment-summary') {
            <div class="flex flex-col w-[640px] max-w-full p-4 gap-4 overflow-x-hidden">
                <h2 class="text-sm text-[#444444]">
                    @if (isSubscription() && periodEnd()) {
                        You are currently on the <span class="font-semibold">{{ currentOrganisationTier().name }}</span> plan with 
                        <span class="font-semibold">{{ currentPlanSeats() }}</span> {{ currentPlanSeats() > 1 ? 'seats' : 'seat' }}.
                        @if (currentAddonSeats() > 0) {
                            This includes <span class="font-semibold">{{ currentAddonSeats() }}</span> UKHab {{ currentAddonSeats() > 1 ? 'licenses' : 'license' }}.
                        }
                        Your current billing cycle will end on <span class="font-semibold">{{ periodEnd() | date }}</span>.
                        <br><br>
                        @if (changeType() === ChangeType.UPGRADE) {
                            Review today's charges for your updated plan. This includes prorated charges based on the changes to your subscription.
                        } @else if (changeType() === ChangeType.DOWNGRADE_PLAN || changeType() === ChangeType.DOWNGRADE_SEATS) {
                            <span class="font-semibold">Your requested changes will take effect in the next billing cycle</span>. Below is a summary of the adjustments to your plan.
                        }
                    } @else {
                        You are about to upgrade your plan to <span class="font-semibold">{{ selectedPlan().name }}</span>.
                    }
                </h2>
        
                <div class="flex flex-col gap-[2px]">
                    @if (changeType() === ChangeType.UPGRADE) {
                        <div class="flex flex-col bg-[#F3F7FC] rounded-t-lg">
                            @if (!isSubscription()) {
                                <div class="flex justify-between items-center p-4">
                                    <span class="text-xs text-[#878F97]">
                                        You have selected {{ planSeats() }} seat{{ Math.abs(planSeats()) > 1 ? 's' : '' }} 
                                        on the {{ selectedPlan().name }} plan
                                    </span>
                                    <span class="text-sm text-[#444444] font-semibold">
                                        {{ (planSeats() * selectedPlan().pricePerSeat) | currency }} 
                                        per {{ intervalOption() }}
                                    </span>
                                </div>
                            } @else {
                                <div *ngFor="let item of proratedItems()" class="py-3 px-4">
                                    <div class="flex justify-between items-center">
                                        <span class="text-xs text-[#878F97]">{{ item.description }}</span>
                                        <span class="text-sm text-[#444444] font-semibold">{{ item.amount | currency: 2 }}</span>
                                    </div>
                                </div>
                            }
                            @if (!isSubscription() && addonSeats() > 0) {
                                <div class="flex justify-between items-center p-4">
                                    <span class="text-xs text-[#878F97]">
                                        You have selected {{ Math.abs(addonSeats()) }} UKHab licence{{ Math.abs(addonSeats()) > 1 ? 's' : '' }}
                                    </span>
                                    <span class="text-sm text-[#444444] font-semibold">
                                        {{ (addonSeats() * addonOption().pricePerSeat) | currency }} per {{ intervalOption() }}
                                    </span>
                                </div>
                            }
                        </div>

                        <div class="flex justify-between items-center py-3 px-4 bg-[#F3F7FC] text-sm text-[#444444] font-semibold">
                            <span>Subtotal</span>
                            <span>{{ amountDue() | currency: 2 }}</span>
                        </div>
                        <div class="flex justify-between items-center py-3 px-4 bg-[#F3F7FC] text-sm text-[#444444] font-semibold">
                            <span>VAT (20%)</span>
                            <span>{{ vatAmount() | currency: 2 }}</span>
                        </div>
                        <div class="flex justify-between items-center py-3 px-4 bg-[#F3F7FC] rounded-b-lg text-sm text-[#444444] font-semibold">
                            <span>Total to pay today</span>
                            <span>{{ totalDue() | currency: 2 }}</span>
                        </div>
                    } @else if (changeType() === ChangeType.DOWNGRADE_PLAN || changeType() === ChangeType.DOWNGRADE_SEATS) {
                        <div class="flex flex-col bg-[#F3F7FC] rounded-lg">
                            @if (selectedPlan().id !== currentOrganisationTier().id) {
                                <div class="flex justify-between items-center p-4">
                                    <span class="text-xs text-[#878F97]">
                                        Plan changes
                                    </span>
                                    <span class="text-sm text-[#444444] font-semibold">
                                        {{ currentOrganisationTier().name }}
                                        <i class="fas fa-arrow-right mx-1"></i>
                                        {{ selectedPlan().name }}
                                    </span>
                                </div>
                            }
                            @if (differencePlanSeats() < 0) {
                                <div class="flex justify-between items-center p-4">
                                    <span class="text-xs text-[#878F97]">
                                        Plan seat changes
                                    </span>
                                    <span class="text-sm text-[#444444] font-semibold">
                                        {{ currentPlanSeats() }}
                                        <i class="fas fa-arrow-right mx-1"></i>
                                        {{ planSeats() }}
                                    </span>
                                </div>
                            }
                            @if (differenceAddonSeats() < 0) {
                                <div class="flex justify-between items-center p-4">
                                    <span class="text-xs text-[#878F97]">
                                        UKHab Licenses
                                    </span>
                                    <span class="text-sm text-[#444444] font-semibold">
                                        {{ currentAddonSeats() }}
                                        <i class="fas fa-arrow-right mx-1"></i>
                                        {{ addonSeats() }}
                                    </span>
                                </div>
                            }
                        </div>
                    }

                    @if (isSubscription() && periodEnd()) {
                        <h2 class="text-base font-semibold text-[#444444] py-3">
                            Next {{ interval() }}ly billing cycle starting {{ periodEnd() | date }}
                        </h2>
    
                        <div class="flex flex-col bg-[#F3F7FC] rounded-t-lg">
                            <div *ngFor="let item of upcomingCharges()" class="py-3 px-4">
                                <div class="flex justify-between items-center">
                                    <span class="text-xs text-[#878F97]">{{ item.description }}</span>
                                    <span class="text-sm text-[#444444] font-semibold">{{ item.amount | currency: 2 }}</span>
                                </div>
                            </div>
                        </div>
    
                        <div class="flex justify-between items-center py-3 px-4 bg-[#F3F7FC] text-sm text-[#444444] font-semibold">
                            <span>Subtotal</span>
                            <span>{{ upcomingChargesSubTotal() | currency: 2 }}</span>
                        </div>
                        <div class="flex justify-between items-center py-3 px-4 bg-[#F3F7FC] text-sm text-[#444444] font-semibold">
                            <span>VAT (20%)</span>
                            <span>{{ upcomingChargesVatAmount() | currency: 2 }}</span>
                        </div>
                        <div class="flex justify-between items-center py-3 px-4 bg-[#F3F7FC] rounded-b-lg text-sm text-[#444444] font-semibold">
                            <span>New {{ interval() }}ly total</span>
                            <span>{{ upcomingChargesTotal() | currency: 2 }}</span>
                        </div>
                    }
                </div>
        
                @if (isSubscription()) {
                    <p class="text-[10px] text-[#878F97]">
                        By clicking
                        @if (changeType() === ChangeType.UPGRADE) {
                             “Update and pay now”
                        } @else if (changeType() === ChangeType.DOWNGRADE_PLAN) {
                             “Confirm plan downgrade”
                        } @else if (changeType() === ChangeType.DOWNGRADE_SEATS) {
                             “Confirm changes”
                        }
                        you agree to
                        {{
                            selectedPlan()?.id === tierId() 
                                ? 'modify the number of seats and/or UKHab licences included in your package'
                                : 'update to a new plan'
                        }}.
                        @if (changeType() === ChangeType.UPGRADE) {
                            <span class="font-extrabold">You will be invoiced and charged today for the prorated amount reflecting these changes</span>,
                            which will cover the period from today until the end of your current billing cycle.
                        } @else if (changeType() === ChangeType.DOWNGRADE_PLAN || changeType() === ChangeType.DOWNGRADE_SEATS) {
                            These changes will take effect at the start of your next billing cycle, and the adjusted rate will apply from that point forward.
                        }
                    </p>
                }
                
                <p class="text-[10px] text-[#878F97]">
                    All transactions will be completed in the external payments portal <span class="font-extrabold">Stripe</span>. 
                    Coreo does not store any payment details. <span class="font-extrabold">By subscribing to Coreo you are agreeing with our <a href="https://coreo.io/terms/" target="_blank" class="font-extrabold underline">terms & conditions</a></span>.
                </p>
                
                <p-button 
                    type="button" size="large" 
                    [label]="isSubscription() ? (changeType() === ChangeType.UPGRADE ? 'Update and pay now' : changeType() === ChangeType.DOWNGRADE_PLAN ? 'Confirm plan downgrade' : 'Confirm changes') : 'Go to payment'" 
                    styleClass="w-full" 
                    (click)="paymentToCheckout()">
                </p-button>
                <p-button type="button" size="large" label="Back" [outlined]="true" styleClass="w-full" (click)="goBack()"></p-button>
            </div>
            }
            <!-- PAYMENT TO STRIPE -->
            @case ('payment-to-stripe') {
            <div class="flex flex-col items-center w-[510px] p-4 gap-4">
                <img class="h-[85px] my-9" src="../../../assets/icons/stripe.png" />
                <span class="text-sm text-[#444444] text-center font-semibold">You are about to leave Coreo and go to the Stripe payment portal to finish your plan setup.</span>
                <span class="text-sm text-[#878F97] text-center mb-3">
                    A payment link can also be generated, which can be shared with others. Once generated you can close this window. 
                    Your account will be updated automatically once payment is complete. 
                </span>
                <p-button size="large" class="w-full" styleClass="w-full text-center" label="Go to payment portal" [disabled]="!this.redirectionUrl()" (click)="confirmRedirection()" />
                @if (sharedLink()) {
                <span class="w-full p-input-icon-right">
                    <i class="pi {{isSuccessfullyCopied() ? 'pi-check text-success pointer-events-none' : 'pi-copy cursor-pointer text-primary'}}" (click)="copyText(sharedLink())"></i>
                    <input type="text" class="w-full" pInputText readonly [value]="sharedLink()" />
                </span>
                } @else {
                <p-button type="button" size="large" [outlined]="true" [disabled]="isProcessingSharedLinkBtn()" class="w-full" styleClass="w-full" (click)="generateLink()">
                    Generate payment link
                    @if(isProcessingSharedLinkBtn()) {
                        <i class="fas fa-spinner fa-spin"></i>
                    }
                </p-button>
                }
            </div>
            }
            @case ('upgrade-successful') {
                <div class="flex flex-col items-center justify-center w-[510px] p-4 gap-6">
                    <img class="coreo-logo h-[90px] w-auto mt-12" src="../../../../assets/images/logo.svg" />
                    <p class="text-lg text-[#444444] font-semibold">Upgrade Successful</p>
                    <p class="text-sm text-[#878F97]" [innerHTML]="updateSuccessfulMessage()"></p>
                    <p-button type="button" size="large" label="Continue" class="w-full" styleClass="w-full" (click)="onHideDialog(0, updatedData())"></p-button>
                </div>
            }
            @case ('upgrade-failed') {
                <div class="flex flex-col items-center justify-center w-[510px] p-4 gap-6">
                    <img class="coreo-logo h-[90px] w-auto mt-12" src="../../../../assets/images/logo.svg" />
                    <p class="text-lg text-[#444444] font-semibold">Upgrade Failed</p>
                    <p class="text-sm text-[#CC5642]" [innerHTML]="this.error()"></p>
                    <p-button type="button" size="large" label="Continue" class="w-full" styleClass="w-full" (click)="onHideDialog(0)"></p-button>
                </div>
            }
            @case ('downgrade-successful') {
                <div class="flex flex-col items-center justify-center w-[510px] p-4 gap-6">
                    <img class="coreo-logo h-[90px] w-auto mt-12" src="../../../../assets/images/logo.svg" />
                    <p class="text-lg text-[#444444] font-semibold">
                        @if (changeType() === ChangeType.DOWNGRADE_PLAN) {
                            Downgrade Successful
                        } @else {
                            Update Successful
                        }
                    </p>
                    <p class="text-sm text-[#878F97]" [innerHTML]="updateSuccessfulMessage()"></p>
                    <p-button type="button" size="large" label="Continue" class="w-full" styleClass="w-full" (click)="onHideDialog(0, updatedData())"></p-button>
                </div>
            }
            @case ('downgrade-failed') {
                <div class="flex flex-col items-center justify-center w-[510px] p-4 gap-6">
                    <img class="coreo-logo h-[90px] w-auto mt-12" src="../../../../assets/images/logo.svg" />
                    <p class="text-lg text-[#444444] font-semibold">Downgrade Failed</p>
                    <p class="text-sm text-[#CC5642]" [innerHTML]="this.error()"></p>
                    <p-button type="button" size="large" label="Continue" class="w-full" styleClass="w-full" (click)="onHideDialog(0)"></p-button>
                </div>
            }
        }
    }
</p-dialog>
