@if(question()){
    <div #container class="w-full h-full bg-white overflow-hidden overflow-y-auto no-scrollbar pb-10">
        @if (showDetails()) {

            <h2>{{question().title}} Details</h2>

            @if (isChildForm()) {
                <form class="p-4 pb-0 space-y-3">
                    <div class="flex flex-col gap-1">
                        <label for="question-child-form-name" class="flex items-center justify-between">
                            <span>Child form's name <sup>*</sup></span>
                            <i class="fa-solid fa-circle-info text-text-secondary" pTooltip="The name of this form, also used for categorising responses" tooltipPosition="left" [positionLeft]="-4"></i>
                        </label>
                        <input id="question-child-form-name" type="text" pInputText placeholder="Add the child form's name" name="childFormName" [ngModel]="childFormName()" (ngModelChange)="updateChildFormSettings({name: $event})" required #childFormNameControl="ngModel" [ngClass]="{'ng-dirty ng-invalid': childFormNameControl.hasError('required')}" />
                        @if(childFormNameControl.hasError('required')){
                        <small class="text-danger">Form name is required</small>
                        }
                    </div>

                    <div class="flex flex-col gap-1">
                        <label for="question-child-form-title" class="flex items-center justify-between">
                            <span>Child form's call to action <sup>*</sup></span>
                            <i class="fa-solid fa-circle-info text-text-secondary" pTooltip="The title of this form, as presented to users when submitting data" tooltipPosition="left" [positionLeft]="-4"></i>
                        </label>
                        <input id="question-child-form-title" type="text" pInputText placeholder="Add the child form's call to action" name="childFormTitle" [ngModel]="childFormTitle()" (ngModelChange)="updateChildFormSettings({title: $event})" required #childFromTitleControl="ngModel" [ngClass]="{'ng-dirty ng-invalid': childFromTitleControl.hasError('required')}" />
                        @if(childFromTitleControl.hasError('required')){
                        <small class="text-danger">Call to action is required</small>
                        }
                    </div>
                </form>
            }
    
            <form class="p-4 space-y-3" [ngClass]="{'pt-3': isChildForm()}">
                @if (showQuestionField()) {
                    <div class="flex flex-col gap-1">
                        <label for="question-text">Question <sup>*</sup></label>
                        <input id="question-text" type="text" pInputText placeholder="Add the question" name="text"
                            [ngModel]="text()" (ngModelChange)="update({text: $event})" required #textControl="ngModel" [ngClass]="{'ng-dirty ng-invalid': textControl.hasError('required')}" />
                        @if(textControl.hasError('required')){
                        <small class="text-danger">Question is required</small>
                        }
                    </div>
                }
    
                @if (showAnswerField()) {
                    <div class="flex flex-col gap-1">
                        <label for="question-answer-label" class="flex items-center justify-between">
                            <span>Answer label <sup>*</sup></span>
                            <i class="fa-solid fa-circle-info text-text-secondary" pTooltip="The name the answers to this question will be stored under" tooltipPosition="left" [positionLeft]="-4"></i>
                        </label>
                        <input id="question-answer-label" type="text" pInputText placeholder="Add answer label" name="label" required [ngModel]="label()" (ngModelChange)="updateAnswer($event)" #labelControl="ngModel" [ngClass]="{'ng-dirty ng-invalid': labelControl.hasError('required')}" />
                        @if(labelControl.hasError('required')){
                        <small class="text-danger">Answer label is required</small>
                        }
                    </div>
                }
    
                <div class="flex flex-col gap-1">
                    <label for="question-description" class="flex items-center justify-between">
                        <span>Description</span>
                        <i class="fa-solid fa-circle-info text-text-secondary" pTooltip="The description will be displayed directly under the input field in the app" tooltipPosition="left" [positionLeft]="-4"></i>
                    </label>
                    <textarea id="question-description" rows="1" pInputTextarea placeholder="Add a brief description of the question" name="description" [ngModel]="description()"
                    (ngModelChange)="update({description: $event})" #descriptionControl="ngModel" [autoResize]="true"></textarea>
                </div>
    
                <div class="flex flex-col gap-1">
                    <label for="question-help-text" class="flex items-center justify-between">
                        <span>Help text</span>
                        <i class="fa-solid fa-circle-info text-text-secondary" pTooltip="Help text will be available as a button in the app" tooltipPosition="left" [positionLeft]="-4"></i>
                    </label>
                    <textarea id="question-help-text" rows="1" pInputTextarea placeholder="Add help text" name="help" [ngModel]="help()" (ngModelChange)="update({help: $event})" #helpControl="ngModel" [autoResize]="true"></textarea>
                </div>
                
                @if (showExportPath()) {
                    <div class="flex flex-col gap-1">
                        <label for="question-export-label" class="flex items-center justify-between">
                            <span>Export label</span>
                            <i class="fa-solid fa-circle-info text-text-secondary" pTooltip="The label that the data will be exported under" tooltipPosition="left" [positionLeft]="-4"></i>
                        </label>
                        <input id="question-export-label" type="text" pInputText placeholder="Add the export label"
                            name="exportPath" [ngModel]="exportPath()" (ngModelChange)="update({exportPath: $event})"
                            #exportPathControl="ngModel" [class.ng-invalid]="duplicatePathError()" [class.ng-dirty]="duplicatePathError()" />
                        @if(duplicatePathError()){
                            <small class="text-danger">Duplicate export path</small>
                        }
                    </div>
                }
            </form>
        }

        @if (question().id === 'list') {
            <h3>List Selection</h3>
            <app-list-selection (update)="update($event)"></app-list-selection>
        }

        @if (showSettings()) {
            <h3>{{question().title}} settings</h3>

            @switch (question().id) {
                @case ('childForm') {
                    <app-subform-settings (update)="update($event)"></app-subform-settings>
                }
                @case ('text') {
                    <app-text-settings (update)="update($event)"></app-text-settings>
                }
                @case ('number') {
                    <app-number-settings (update)="update($event)"></app-number-settings>
                }
                @case ('date') {
                    <app-datetime-settings (update)="update($event)"></app-datetime-settings>
                }
                @case ('list') {
                    <app-list-settings (update)="update($event)"></app-list-settings>
                }
                @case ('slider') {
                    <app-slider-settings (update)="update($event)"></app-slider-settings>
                }
                @case ('geometry') {
                    <app-geometry-settings (update)="update($event)"></app-geometry-settings>
                }
                @case ('geometryPicker') {
                    <app-geometry-picker-settings (update)="update($event)"></app-geometry-picker-settings>
                }
                @case ('coordinateProjection') {
                    <app-coordinate-projection-settings (update)="update($event)"></app-coordinate-projection-settings>
                }
                @case ('reverseGeolocation') {
                    <app-reverse-geolocation-settings (update)="update($event)"></app-reverse-geolocation-settings>
                }
                @case ('areaLookup') {
                    <app-area-lookup-settings (update)="update($event)"></app-area-lookup-settings>
                }
                @case ('photo') {
                    <app-photo-settings (update)="update($event)"></app-photo-settings>
                }
                @case ('calculated') {
                    <app-calculated-settings (update)="update($event)"></app-calculated-settings>
                }
                @case ('association') {
                    <app-association-settings (update)="update($event)"></app-association-settings>
                }
            }
        }

        @if (showProperties()) {
            <h3>Properties</h3>

            <form class="p-4 space-y-5">
                @if (question().id !== 'calculated') {
                    <div class="flex items-center gap-2">
                        <label class="grow text-sm text-text-primary font-normal" for="mandatory">Make mandatory</label>
                        <p-inputSwitch inputId="mandatory" name="mandatory" [ngModel]="mandatory()" (ngModelChange)="update({ required: $event })" />
                    </div>
                }
                @if (question().id !== 'association') {
                    <div class="flex items-center gap-2">
                        <label class="grow text-sm text-text-primary font-normal flex items-center justify-between" for="hidden">
                            Hide question
                            <i class="fa-solid fa-circle-info text-text-secondary" pTooltip="Hidden questions will not be visible in the app" tooltipPosition="left" [positionLeft]="-4"></i>
                        </label>
                        <p-inputSwitch inputId="hidden" name="hidden" [ngModel]="hidden()" (ngModelChange)="update({ visible: !$event })" />
                    </div>
                }
            </form>
        }

        <h3>Conditions</h3>
        <div class="p-4 flex flex-col items-center gap-5">
            @if (hasConditions()) {
                <app-project-form-conditions></app-project-form-conditions>
            }
            @else if (attributesForConditions().length < 1) {
                <p>No questions are currently available for creating condition rules.</p>
            }
            @else {
                <p-button size="large" [outlined]="true" (click)="addCondition()" styleClass="px-5 w-full" class="w-full">Add a condition</p-button>
            }
        </div>
    </div>
}