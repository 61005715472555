import { Component, EventEmitter, Input, OnInit, Output, effect, inject } from '@angular/core';
import { NgFor } from '@angular/common';
import { ProjectFormBuilderComponent } from './project-form-builder.component';
import { ProjectFormDetailsComponent } from './project-form-details.component';
import { ProjectFormQuestionsComponent } from './project-form-questions.component';
import { ProjectFormToolbarComponent } from './project-form-toolbar.component';
import { attributeDeleteNoticePreferenceKey, attributeDeleteNoticePreferenceVersion, projectFormNoticePreferenceKey, projectFormNoticePreferenceVersion, ProjectFormService } from './project-form.service';
import { UserCalculated } from './project-form.model';
import { ConfirmationService } from 'primeng/api';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ApiService } from '@core/services';
import { catchError, of, take } from 'rxjs';

@Component({
    selector: 'app-project-form',
    templateUrl: './project-form.component.html',
    styleUrls: ['./project-form.component.scss'],
    standalone: true,
    imports: [
        NgFor,
        ProjectFormQuestionsComponent,
        ProjectFormDetailsComponent,
        ProjectFormBuilderComponent,
        ProjectFormToolbarComponent,
        ConfirmDialogModule
    ],
    providers: [
        ProjectFormService,
        ConfirmationService
    ]
})
export class ProjectFormComponent implements OnInit {

    form = inject(ProjectFormService);
    confirmationService = inject(ConfirmationService);
    apiService = inject(ApiService);

    @Input() user: UserCalculated;
    @Input() formId: number;
    @Input() projectId: number;

    @Output() dirtyChanged: EventEmitter<boolean> = new EventEmitter();

    selectedAttribute = this.form.selectedAttribute;
    dirty = this.form.dirty;

    constructor() {
        effect(() => {
            const dirty = this.dirty();
            this.dirtyChanged.emit(dirty);
        });
    }

    ngOnInit() {
        const query = `query AAProjectFormGetViewer{
            viewer {
                preferences
            }
        }`;

        let showNotice = false;

        this.apiService.graphql<{ viewer: { preferences: any } }>(query).pipe(
            take(1),
            catchError((e) => {
                console.warn(e);
                return of(null);
            })
        ).subscribe(res => {
            if (!!res) {
                const { preferences } = res.viewer;
                
                if (!preferences[projectFormNoticePreferenceKey] || preferences[projectFormNoticePreferenceKey] !== projectFormNoticePreferenceVersion) {
                    showNotice = true;
                }

                if (!preferences[attributeDeleteNoticePreferenceKey] || preferences[attributeDeleteNoticePreferenceKey] !== attributeDeleteNoticePreferenceVersion) {
                    this.form.showAttributeDeleteNotice.set(true);
                }
            }
        });


        this.form.init(this.projectId, this.formId);
        this.form.user.set(this.user);

        if (showNotice) {
            const message = `<p class="mb-4">We've rebuilt the form builder to save you even more time by being quicker and more intuitive to use. We've updated a number of things in the user interfaces, as well as some key functionality. New feature and updates include:</p>
            <h2 class="text-lg font-semibold mb-4">Key new features</h2>
            <h3 class="text-sm font-semibold mb-1">Inline child forms</h3>
            <p class="mb-4">You can now build forms within forms. Select a child form and build it right in the same form builder. No need to go out and create separate forms anymore. These forms can be set to repeatable questions, and their records can still be viewed separately in the records view, allowing you to find the exact data you need.</p>
            <h3 class="text-sm font-semibold mb-1">Create and edit collections straight from the form builder</h3>
            <p class="mb-4">From quick lists, to rich data collections with attributes, media and geometry you can now upload, create and edit your collections whilst you build your form.</p>
            <h2 class="text-lg font-semibold mb-4">Other updates</h2>
            <h3 class="text-sm font-semibold mb-1">Re-designed questions</h3>
            <p class="mb-4">On the left you'll see that we have redesigned the question types you can add to your form, combining and categorising to make its easer to find the question you you need. You’ll see the question cards have a new look too and you can now easily keep your form tidy with the drag and drop page dividers. </p>
            <h3 class="text-sm font-semibold mb-1">Improvements to individual question types</h3>
            <p class="mb-4">Each question type has been refined to clean up the interface and make instructions clearer. We've combined similar question types and given you the choice of how to use them. We've also added more helpful tool tips to reference if you get stuck.</p>
            <h3 class="text-sm font-semibold mb-1">New form settings</h3>
            <p>A redesigned form settings with helpful tool tips can now be found next to the Save Form button in the top right corner.</p>`;

            this.confirmationService.confirm({
                message,
                header: 'Welcome to the new Coreo Form builder',
                acceptLabel: "Continue",
                acceptIcon: "none",
                acceptButtonStyleClass: "p-button p-button-lg",
                rejectVisible: false,
                defaultFocus: "none",
                closeOnEscape: false,
                accept: () => {
                    this.setNoticeSeen();
                }
            });
        } 
    }

    setNoticeSeen() {
        const query = `mutation AAProjectFormUpdateUserPreferences($input: UpdatePreferences!){
            updateUserPreferences(input: $input){
                preferences
            }
        }`;
        const input = {
            preferences: {
                [projectFormNoticePreferenceKey]: projectFormNoticePreferenceVersion
            }
        }

        this.apiService.graphql(query, { input }).pipe(
            take(1),
            catchError((e) => {
                console.warn(e);
                return of(null);
            })
        ).subscribe(res => {
            console.log(res);
            
        });
    }
}