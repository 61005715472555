<div>
    <div class="flex items-center gap-4 mb-2">
        <p>Display question if</p>
        <p-dropdown 
        [options]="anyOptions"
        [ngModel]="any()"
        (ngModelChange)="anyChanged($event)" 
        optionLabel="name"
        optionValue="value"
        class="grow" />
    </div>
    <p>of the below conditions are met.</p>
</div>

@for (rule of dummyRulesArray(); track rule; let index = $index; let last = $last) {
    <app-project-form-condition-rule
        [index]="index"
        (update)="updateRule($event, index)"
        (remove)="removeRule(index)"
    ></app-project-form-condition-rule>
    @if (last) {
        <div class="flex items-center justify-center">
            <p-button size="large" [outlined]="true" (click)="addRule()" class="w-full" styleClass="w-full">Add another condition</p-button>
        </div>
    }
}