import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output, computed, inject, signal } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { SvgIconComponent } from 'angular-svg-icon';
import { getTime } from 'date-fns';
import { ConfirmationService, MenuItem, MessageService } from "primeng/api";
import { ButtonModule } from 'primeng/button';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { DialogService } from 'primeng/dynamicdialog';
import { InputTextModule } from 'primeng/inputtext';
import { MenuModule } from 'primeng/menu';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { catchError, filter, of, take, tap, zip } from 'rxjs';
import { ApiService } from 'src/app/core';
import { JobsService } from 'src/app/core/services/jobs.service';
import { z } from 'zod';
import { CollectionImportType, ImportCollectionModalComponent } from '../../collections/collection/import-collection-modal/import-collection-modal.component';
import { CollectionsListItemComponent } from './collections-list-item.component';

const CollectionSchema = z.object({
    id: z.number(),
    name: z.string(),
    createdAt: z.string(),
    geometric: z.boolean()
});

const CollectionsSchema = z.object({
    collections: CollectionSchema.array()
});

export type Collection = z.infer<typeof CollectionSchema>;
type Collections = z.infer<typeof CollectionsSchema>;

type SortMode = 'name' | 'created';

@Component({
    selector: 'app-project-collections',
    templateUrl: './project-collections.component.html',
    imports: [
        CommonModule,
        InputTextModule,
        ReactiveFormsModule,
        ButtonModule,
        MenuModule,
        SvgIconComponent,
        CollectionsListItemComponent,
        ConfirmDialogModule,
        ProgressSpinnerModule
    ],
    standalone: true,
    styles: [
        `:host { @apply block w-full h-full overflow-hidden;}`
    ],
    providers: [
        DialogService,
        MessageService,
        ConfirmationService
    ]
})
export class ProjectCollectionsComponent implements OnInit {

    messageService = inject(MessageService);
    dialogService = inject(DialogService);
    confirmationService = inject(ConfirmationService);
    apiService = inject(ApiService);
    jobService = inject(JobsService);

    @Input() projectId: number;
    @Input() parent: any;
    @Output() collection: EventEmitter<{ collectionId: number; isNewCollection: boolean; }> = new EventEmitter();

    loading = signal(false);
    collections = signal<Collection[]>([]);
    currentCollections = computed<Collection[]>(() => {
        const collections = this.collections();
        const search = this.search();
        const sortMode = this.sortMode();
        const sortReverse = this.sortReverse();

        const c = collections
            .filter(c => !search || c.name.toLowerCase().includes(search.toLowerCase()))
            .sort((a, b) => {
                if (sortMode === 'name') {
                    return sortReverse ? b.name.localeCompare(a.name) : a.name.localeCompare(b.name);
                } else if (sortMode === 'created') {
                    /** Most recent first */
                    return sortReverse ? getTime(new Date(a.createdAt)) - getTime(new Date(b.createdAt)) : getTime(new Date(b.createdAt)) - getTime(new Date(a.createdAt));
                }
            });

        return c;
    });
    search = signal<string>('');
    sortMode = signal<SortMode>('name');
    sortReverse = signal(false);

    public importCollectionMenuItems: MenuItem[] = [
        { label: 'From CSV', command: () => this.createCollection('csv') },
        { label: 'GeoJSON', command: () => this.createCollection('geojson') },
        { label: 'Zipped Shapefile', command: () => this.createCollection('shapefile') }
    ];

    searchForm = new FormGroup({
        search: new FormControl('')
    });

    constructor() {
        this.searchForm.valueChanges.pipe(takeUntilDestroyed()).subscribe(value => {
            this.search.set(value.search);
        });
    }

    ngOnInit() {
        this.loadCollections();
    }

    private loadCollections() {
        this.loading.set(true);

        const query = `query getProjectCollections{
            project(id: ${this.projectId}){
                collections{
                    id
                    name
                    createdAt
                    geometric
                }
            }
        }`;

        this.apiService.graphql<{ project: { collections: Collections } }>(query).pipe(
            take(1),
            catchError((e) => {
                console.warn(e);
                return of(null);
            })
        ).subscribe(res => {
            if (!!res) {
                const parsedRes = CollectionsSchema.parse(res.project);
                this.collections.set(parsedRes.collections);
            }
            this.loading.set(false);
        });
    }

    sort(mode: SortMode) {
        const sortMode = this.sortMode();
        const sortReverse = this.sortReverse();

        if (mode === sortMode) {
            this.sortReverse.set(!sortReverse);
        } else {
            this.sortMode.set(mode);
            this.sortReverse.set(false);
        }
    }

    openCollection(item: Collection) {
        this.collection.emit({ collectionId: item.id, isNewCollection: false });
    }

    deleteCollectionCheck(item: Collection) {
        this.confirmationService.confirm({
            defaultFocus: 'none',
            message: 'Are you sure you want to delete this collection, and all items within?<br>This cannot be undone.',
            header: 'Delete collection',
            rejectLabel: 'Cancel',
            rejectIcon: 'none',
            rejectButtonStyleClass: 'p-button p-button-lg p-button-outlined',
            acceptLabel: 'Delete collection',
            acceptIcon: 'none',
            acceptButtonStyleClass: 'p-button p-button-lg p-button-danger',
            accept: () => {
                this.deleteCollection(item);
            }
        });
    }

    deleteCollection(item: Collection) {
        this.loading.set(true);
        const query = `mutation AADeleteCollection($input: CollectionDeleteInput!){
            deleteCollection(input: $input)
        }`;

        this.apiService.graphql<{ deleteCollection: boolean }>(query, { input: { id: item.id } }).pipe(
            take(1),
            catchError((e) => {
                console.warn(e);
                return of(null);
            })
        ).subscribe(res => {
            if (!!res && res.deleteCollection) {
                this.loadCollections();
            } else {
                this.loading.set(false);
                setTimeout(() => {
                    this.confirmationService.confirm({
                        defaultFocus: 'none',
                        message: 'Unable to delete this collection as it is required by one or more questions.',
                        header: 'Unable to delete collection',
                        rejectLabel: 'Close',
                        rejectIcon: 'none',
                        rejectButtonStyleClass: 'p-button p-button-lg p-button-outlined',
                        acceptVisible: false,
                    });
                }, 100);
            }
        });
    }

    createCollection(type: CollectionImportType) {
        const headerMap = {
            empty: 'Create empty collection',
            csv: 'Create collection from CSV',
            geojson: 'Create collection from geoJSON',
            shapefile: 'Create collection from shapefile'
        }

        const ref = this.dialogService.open(ImportCollectionModalComponent, {
            header: headerMap[type],
            modal: true,
            data: {
                importType: type,
                projectId: this.projectId,
                newCollection: true
            },
            width: '620px'
        });

        ref.onClose.pipe(filter(res => !!res)).subscribe(result => {
            if (result.id) {
                if (type === 'empty') {
                    this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Collection Created' });
                    this.collection.emit({ collectionId: result.id, isNewCollection: true });
                } else {
                    this.jobService.addJob(result).pipe(
                        filter(job => job.status === 'complete'),
                        tap(() => {
                            this.loadCollections();
                        })
                    ).subscribe();
                }
            }
        });
    }
}