<p-confirmDialog styleClass="hide-close"></p-confirmDialog>
<p-toast position="bottom-center" [life]="2000"></p-toast>

<!-- Map view for geometry collection -->
@if (isGeometryCollection()) {
    <div [ngClass]="{'hidden': !mapVisible()}" class="h-full w-full flex flex-col bg-white">
        <div class="coreo-list-header gap-4 p-4 border-b border-grey-30 h-[69px]">
            <h2 class="whitespace-nowrap truncate">{{ title() }}</h2>
            <div class="flex items-center justify-end gap-4">
                <p-button [text]="true" (click)="showMap.set(false)" [disabled]="collection().length < 1" class="text-primary">
                    <svg-icon src="/assets/icons/list.svg"></svg-icon>
                    View list
                </p-button>
                <p-button (click)="addNewGeometryItem.emit()">
                    <svg-icon src="/assets/icons/plus.svg"></svg-icon>Add
                </p-button>
            </div>
        </div>
        <app-collection-map class="relative flex-grow" (item)="this.item.emit($event)"></app-collection-map>
    </div>
}
<p-table 
    [ngClass]="{'hidden': mapVisible()}"
    [value]="collection()"
    [lazy]="true"
    (onLazyLoad)="loadCollection($event)"
    (onRowReorder)="reorderRows($event)" 
    [paginator]="true"
    [first]="first()"
    paginatorDropdownAppendTo="body"
    [rows]="rows()"
    [rowsPerPageOptions]="[10,50,100]"
    [totalRecords]="itemCount()"
    [loading]="loading()"
    [scrollable]="true"
    scrollHeight="flex"
    styleClass="bg-white p-0"
>
    <ng-template pTemplate="caption">
        <div class="coreo-list-header gap-4 p-4 border-b border-grey-30 h-[69px]">
            <h2 class="is-hidden lg:inline-block whitespace-nowrap truncate">{{ title() }}</h2>
            <div class="flex items-center justify-end gap-2">
                <!-- If geometryCollection show map button -->
                @if (isGeometryCollection()) {
                    <p-button [text]="true" (click)="showMap.set(true)" [disabled]="collection().length < 1" class="text-primary">
                        <svg-icon src="/assets/icons/location.svg"></svg-icon>
                        View map
                    </p-button>
                }
                <!--Sort button & popup -->
                <p-button [text]="true" (click)="sort.toggle($event)" [disabled]="collection().length < 1" class="text-primary">
                    <svg-icon [src]="sortReverse() ? '/assets/icons/sort-amount-down.svg' : '/assets/icons/sort-amount-up.svg'"></svg-icon>
                    Sort: {{ sortLabel() }}
                </p-button>
                <p-overlayPanel #sort>
                        <p class="text-xs text-black font-normal mb-2">Sort type</p>
                        <div class="flex items-center gap-1 mb-4">
                            <p-selectButton [options]="sortOptions()" [ngModel]="sortMode()" [multiple]="false" [unselectable]="true" optionLabel="label" optionValue="value" [disabled]="collection().length < 1" optionDisabled="disabled" (onChange)="updateSortMode($event.value)"></p-selectButton>
                            <p-button [text]="true" (click)="reverse()" class="text-primary">
                                <svg-icon [src]="sortReverse() ? '/assets/icons/sort-amount-down.svg' : '/assets/icons/sort-amount-up.svg'"></svg-icon>
                            </p-button>
                        </div>
                        @if (sortMode() === 'manual') {
                            <p class="text-xs text-black font-normal pb-2">Use the drag handles on each item to re-order them</p>
                        }
                        @if (sortMode() === 'attribute') {
                            <div class="pb-2">
                                <p class="text-xs text-black font-normal mb-2">Select attribute to sort by</p>
                                <p-dropdown [options]="sortAttributeDropdownOptions()" [ngModel]="sortAttributeId()" optionLabel="label" optionValue="id" placeholder="Select attribute" (onChange)="updateSortAttributeId($event.value)" appendTo="body" styleClass="h-8"></p-dropdown>
                            </div>
                        }
                </p-overlayPanel>
                <!-- Search -->
                <span class="p-input-icon-left">
                    <i class="fa-solid fa-magnifying-glass text-primary text-xs ml-1"></i>
                    <input pInputText #searchInput type="text" (input)="debouncedSearch()" class="w-full max-w-60 h-9 pr-10" autocomplete="off" placeholder="Search for an item" [(ngModel)]="search" [disabled]="collection().length < 1" />
                    @if (search().length > 0) {
                        <button class="absolute right-2 top-1/2 -translate-y-1/2 h-5 w-5 rounded-md cursor-pointer hover:bg-grey-10" (click)="clearSearch($event)">
                            <i class="fa-solid fa-xmark text-text-secondary text-sm absolute left-1/2 -translate-x-1/2 top-1/2 -translate-y-1/2"></i>
                        </button>
                    }
                </span>
            </div>
        </div>
        <div class="coreo-list-header w-full bg-primary-5">
            <form [formGroup]="newItemForm" (ngSubmit)="onAddNewItem()" class="w-full flex gap-4 p-4 pt-3">
                <!-- Name input -->
                <div class="w-2/3 flex flex-col gap-1">
                    <label class="text-xs font-normal" for="itemValueInput">Add an item</label>
                    <input pInputText #itemValueInput id="itemValueInput" type="text" class="w-full h-9" autocomplete="off" placeholder="Item name" formControlName="name" (input)="setKey(); duplicateKeyError.set(false)" />
                </div>
                <div class="w-1/3 flex items-end gap-4">
                    <!-- Key input -->
                    <div class="flex flex-col gap-1">
                        <label class="text-xs font-normal flex items-center justify-between" for="itemKeyInput">
                            Item key
                            <i 
                                class="fa-solid fa-circle-info text-text-secondary cursor-pointer"
                                pTooltip="The key is a permanent label for your list item, which is stored in the data base. It will remain the same, even if the item name is changed. If you do not set a custom key, the key will be the same as the item name. Once set it cannot be changed."
                                tooltipPosition="left"
                            ></i>
                        </label>
                        <input pInputText #itemKeyInput id="itemKeyInput" type="text" class="w-full h-9" autocomplete="off" placeholder="Item key" formControlName="key" [ngClass]="{'ng-invalid ng-dirty': duplicateKeyError()}" (input)="duplicateKeyError.set(false)" />
                    </div>
                    <!-- Add button -->
                    <p-button class="h-9" styleClass="h-full" type="submit" [disabled]="!newItemForm.valid">
                        <svg-icon src="/assets/icons/plus.svg" class="mr-1 is-hidden lg:inline-block"></svg-icon>Add
                    </p-button>
                </div>
            </form>
        </div>
    </ng-template>

    <ng-template pTemplate="body" let-item let-index="rowIndex">
        <tr [pReorderableRow]="index" (click)="rowSelect(item)" class="table-item table-item--thin">
            <div class="table-item__inner justify-between">
                <div class="flex items-center gap-[10px]">
                    @if (hasImages()) {
                        <p-avatar [image]="item.avatar" shape="circle" styleClass="h-9 w-9" class="h-9 w-9"></p-avatar>
                    }
                    <p class="table-item__primary-text max-w-72 truncate">{{ item.value }}</p>
                </div>
                <div class="flex items-center justify-end gap-[10px]">
                    <p class="block text-xs text-grey-120 max-w-64 truncate">{{ item.key }}</p>
                    <div class="table-item__buttons">
                        <button class="table-item__button">
                            <i (click)="deleteCollectionItemCheck($event, item)" class="fa-solid fa-trash-can"></i>
                        </button>
                        <button class="table-item__button" (click)="rowSelect(item)">
                            <i class="fa-solid fa-pen text-primary"></i>
                        </button>
                        @if (sortMode() === 'manual') {
                            <i class="fa-solid fa-grip-vertical text-grey-30" pReorderableRowHandle></i>
                        }
                    </div>
                </div>
            </div>
        </tr>
    </ng-template>
    
    <ng-template pTemplate="emptymessage">
        <div class="absolute h-full w-full flex flex-col items-center justify-center">
            <i class="fa-solid {{ search().length > 0 ? 'fa-magnifying-glass' : 'fa-layer-group' }} text-5xl text-primary-100 mb-5"></i>
            <p class="text-base font-semibold">
                @if (search().length > 0) {
                    Nothing found
                }
                @else {
                    There are no items yet.
                }
            </p>
            @if (search().length > 0) {
                <p class="max-w-80 text-sm text-text-secondary text-center mt-6">No items fit that search description. Try again with a different search term.</p>
            }
            @else {
                <p class="max-w-80 text-sm text-text-secondary text-center mt-6">Collection items can be shown as lists and selected as choices in your forms.</p>
                <p class="max-w-80 text-sm text-text-secondary text-center mt-5">Type the name of your first item above and add it to your list, or upload a whole collection.</p>
            }
        </div>
    </ng-template>
</p-table>