import { z } from 'zod';

type QBase = {
    id: string;
    category: string | null;
    label: string;
    title: string;
    type: string | null;
    questionType: string | null;
    iconUrl: string;
    toolTip: string | null;
}

export const questionRegistry = [
    // Form Design
    {
        id: 'textblock',
        category: 'form-design',
        type: null,
        questionType: 'text',
        label: 'Add a title or text block',
        title: 'Title or text block',
        iconUrl: '/assets/icons/font.svg',
        toolTip: 'Provide text within the survey form itself e.g. for clear instructions or section titles'
    }, {
        id: 'divider',
        category: 'form-design',
        type: 'divider',
        questionType: null,
        label: 'Add a page divider',
        title: 'Page divider',
        iconUrl: '/assets/icons/divider-square.svg',
        toolTip: 'Add a divider to group questions into separate pages in the app'
    }, {
        id: 'childForm',
        category: 'form-design',
        type: null,
        questionType: 'child',
        label: 'Build a child form',
        title: 'Child form',
        iconUrl: '/assets/icons/clipboard.svg',
        toolTip: 'The child form question is used to add a form into your current form. Typically this is used to add multiple records to a parent survey'
    },
    // Basic Answers
    {
        id: 'text',
        category: 'basic',
        type: 'text',
        questionType: 'short',
        label: 'Text answer',
        title: 'Text answer',
        iconUrl: '/assets/icons/align-left.svg',
        toolTip: 'Single or multi-line text field. No character limit'
    }, {
        id: 'text',
        category: null,
        type: 'text',
        questionType: 'long',
        label: 'Text answer',
        title: 'Text answer',
        iconUrl: '/assets/icons/align-left.svg',
        toolTip: null
    }, {
        id: 'email',
        category: null,
        type: 'email',
        questionType: 'email',
        label: 'Email address',
        title: 'Email address',
        iconUrl: null,
        toolTip: null
    }, {
        id: 'url',
        category: null,
        type: 'url',
        questionType: 'url',
        label: 'Website url',
        title: 'Website url',
        iconUrl: null,
        toolTip: null
    }, {
        id: 'number',
        category: 'basic',
        type: 'integer',
        questionType: 'numeric',
        label: 'Numeric answer',
        title: 'Numeric answer',
        iconUrl: '/assets/icons/hashtag.svg',
        toolTip: 'Input a number by directly typing into the field. Accepts min/max values'
    }, {
        id: 'number', // Will this cause issues keep an eye on it (duplicate ids for integer and float)
        category: null,
        type: 'float',
        questionType: 'numeric',
        label: 'Numeric answer',
        title: 'Numeric answer',
        iconUrl: '/assets/icons/hashtag.svg',
        toolTip: null
    }, {
        id: 'date',
        category: 'basic',
        type: 'date',
        questionType: 'date',
        label: 'Date/Time answer',
        title: 'Date/Time answer',
        iconUrl: '/assets/icons/clock.svg',
        toolTip: 'Date format of Day/Month/Year with optional time (24hr)'
    }, {
        id: 'date', // Will this cause issues keep an eye on it (duplicate ids for date and datetime)
        category: null,
        type: 'datetime',
        questionType: 'datetime',
        label: 'Date/Time answer',
        title: 'Date/Time answer',
        iconUrl: '/assets/icons/clock.svg',
        toolTip: null
    },
    // Choices
    {
        id: 'yesno',
        category: 'choices',
        questionType: 'boolean',
        type: 'boolean',
        label: 'Yes/No Answer',
        title: 'Yes/No Answer',
        iconUrl: '/assets/icons/yes-no.svg',
        toolTip: 'Yes/No select buttons'
    },
    {
        id: 'checkbox',
        category: 'choices',
        questionType: 'confirm',
        type: 'boolean',
        label: 'Checkbox',
        title: 'Checkbox',
        iconUrl: '/assets/icons/checkbox.svg',
        toolTip: 'Single checkbox'
    },
    {
        id: 'list', // Will this cause issues keep an eye on it (duplicate ids for modal, multi, select/multiselect)
        category: 'choices',
        questionType: 'modal',
        type: 'select',
        label: 'Choose from a list',
        title: 'Choice from a list',
        iconUrl: '/assets/icons/list.svg',
        toolTip: 'Select list which presents radio buttons, suitable for short lists or a select list which features a dropdown with a search option, suitable for long lists'
    },
    {
        id: 'list',
        category: null,
        questionType: 'modal',
        type: 'multiselect',
        label: 'Choose from a list',
        title: 'Choice from a list',
        iconUrl: '/assets/icons/list.svg',
        toolTip: null
    },
    {
        id: 'list',
        category: null,
        questionType: 'multi',
        type: 'select',
        label: 'Choose from a list',
        title: 'Choice from a list',
        iconUrl: '/assets/icons/list.svg',
        toolTip: null
    },
    {
        id: 'list',
        category: null,
        questionType: 'multi',
        type: 'multiselect',
        label: 'Choose from a list',
        title: 'Choice from a list',
        iconUrl: '/assets/icons/list.svg',
        toolTip: null
    },
    {
        id: 'slider',
        category: 'choices',
        questionType: 'slider',
        type: 'integer',
        label: 'Slider',
        title: 'Slider',
        iconUrl: '/assets/icons/sliders.svg',
        toolTip: 'Input a number using a slider interface. Accepts min/max values, a step value and default value'
    },
    {
        id: 'slider', // Will this cause issues keep an eye on it (duplicate ids for integer and float)
        category: null,
        questionType: 'slider',
        type: 'float',
        label: 'Slider',
        title: 'Slider',
        iconUrl: '/assets/icons/sliders.svg',
        toolTip: null
    },
    // Geometry
    {
        id: 'geometry',
        category: 'geometry',
        type: null,
        questionType: 'geometry',
        label: 'Add Location / Geometry',
        title: 'Location',
        iconUrl: '/assets/icons/location.svg',
        toolTip: 'Add a location (point, line, polygon) to the record'
    },
    {
        id: 'geometryPicker',
        category: 'geometry',
        type: 'select',
        questionType: 'geometryselect',
        label: 'Geometry picker',
        title: 'Geometry picker',
        iconUrl: '/assets/icons/polygon.svg',
        toolTip: 'Select list but enables you to choose an item with a geometry from the map interface'
    },
    {
        id: 'coordinateProjection',
        category: 'geometry',
        type: 'coordinatetransform',
        questionType: null,
        label: 'Coordinate projection',
        title: 'Coordinate projection',
        iconUrl: '/assets/icons/map-marked.svg',
        toolTip: 'Automatically transform and store additional coordinate references for the lat/lng recorded (e.g. GB National Grid Ref)'
    },
    {
        id: 'reverseGeolocation',
        category: 'geometry',
        type: 'rgeolocation',
        questionType: null,
        label: 'Reverse geolocation',
        title: 'Reverse geolocation',
        iconUrl: '/assets/icons/passport.svg',
        toolTip: "Uses Bing's Reverse Geolocation API to store the nearest address, town, postcode etc for the geometry"
    },
    {
        id: 'areaLookup',
        category: 'geometry',
        type: 'geometryquery',
        questionType: null,
        label: 'Area lookup',
        title: 'Area lookup',
        iconUrl: '/assets/icons/earth-europe.svg',
        toolTip: 'In a project containing reference polygon data (e.g. county or country boundaries) the Area Lookup question returns the name of the polygon your record is created in'
    },
    // Attach
    {
        id: 'photo',
        category: 'attach',
        type: 'attachment',
        questionType: 'mediaGallery',
        label: 'Photo',
        title: 'Photo',
        iconUrl: '/assets/icons/media.svg',
        toolTip: "Upload single or multiple photos from the camera or device's gallery"
    },
    {
        id: 'photo',
        category: null,
        type: 'media',
        questionType: 'photo',
        label: 'Photo',
        title: 'Photo',
        iconUrl: '/assets/icons/media.svg',
        toolTip: null
    },
    {
        id: 'audio',
        category: null,
        type: 'media',
        questionType: 'audio',
        label: 'Audio',
        title: 'Audio',
        iconUrl: '/assets/icons/audio-file.svg',
        toolTip: null
    },
    {
        id: 'signature',
        category: 'attach',
        type: 'attachment',
        questionType: 'signature',
        label: 'Signature',
        title: 'Signature',
        iconUrl: '/assets/icons/signature.svg',
        toolTip: 'Freehand signature'
    },
    // Advanced
    {
        id: 'calculated',
        category: 'advanced',
        type: null,
        questionType: 'expression',
        label: 'Calculated field',
        title: 'Calculated field',
        iconUrl: '/assets/icons/calculator.svg',
        toolTip: 'Add code to perform calculations within your survey. Uses JavaScript and, as such, requires developer level knowledge'
    },
    {
        id: 'calculated',
        category: null,
        type: null,
        questionType: 'expression',
        label: 'Calculated field',
        title: 'Calculated field',
        iconUrl: '/assets/icons/calculator.svg',
        toolTip: null
    },
    {
        id: 'calculated',
        category: null,
        type: null,
        questionType: 'expression',
        label: 'Calculated field',
        title: 'Calculated field',
        iconUrl: '/assets/icons/calculator.svg',
        toolTip: null
    },
    {
        id: 'calculated',
        category: null,
        type: 'text',
        questionType: 'expression',
        label: 'Calculated field',
        title: 'Calculated field',
        iconUrl: '/assets/icons/calculator.svg',
        toolTip: null
    },
    {
        id: 'calculated',
        category: null,
        type: 'date',
        questionType: 'expression',
        label: 'Calculated field',
        title: 'Calculated field',
        iconUrl: '/assets/icons/calculator.svg',
        toolTip: null
    },
    {
        id: 'calculated',
        category: null,
        type: 'datetime',
        questionType: 'expression',
        label: 'Calculated field',
        title: 'Calculated field',
        iconUrl: '/assets/icons/calculator.svg',
        toolTip: null
    },
    {
        id: 'calculated',
        category: null,
        type: 'float',
        questionType: 'expression',
        label: 'Calculated field',
        title: 'Calculated field',
        iconUrl: '/assets/icons/calculator.svg',
        toolTip: null
    },
    {
        id: 'calculated',
        category: null,
        type: 'boolean',
        questionType: 'expression',
        label: 'Calculated field',
        title: 'Calculated field',
        iconUrl: '/assets/icons/calculator.svg',
        toolTip: null
    },
    {
        id: 'association',
        category: 'advanced',
        type: null,
        questionType: 'association',
        label: 'Link to another form',
        title: 'Link record',
        iconUrl: '/assets/icons/link.svg',
        toolTip: 'Link record enables you to associate your survey data with an existing record in your project. It is used in e.g. fixed point monitoring surveys'
    }
] as const satisfies readonly QBase[];

export type Question = typeof questionRegistry[number];
export type QuestionType = Question['type'];
export type QuestionQuestionType = Question['questionType'];
export type QuestionId = Question['id'];
export type QuestionCategory = Question['category'];

export type AttributeConditionRule = {
    path: string;
    selectComparand?: string;
    selectCondition?: string;
    numberCondition?: string;
    numberComparand?: number;
    textCondition?: string;
    textComparand?: string;
    dateCondition?: string;
    dateComparand?: string;
    datetimeCondition?: string;
    datetimeComparand?: string;
    isTrue?: boolean;
}

export type AttributeConditions = {
    any: boolean;
    rules: AttributeConditionRule[];
}

export const UNARY_OPERATORS = ['answered', 'unanswered'];

export const FormSettingsSchema = z.object({
    name: z.string(),
    title: z.string(),
    titleAttributeId: z.number().nullable(),
    secondaryTitleAttributeId: z.number().nullable(),
    thankyou: z.string().nullable(),
    allowMemberUpdate: z.boolean(),
    allowOwnRecordDelete: z.boolean(),
    isPrivate: z.boolean(), // private is a reserved word so store private as isPrivate
    visible: z.boolean()
});

export type FormSettings = z.infer<typeof FormSettingsSchema>;

interface ChildForm extends FormSettings {
    attributes: Attribute[];
}

export type Attribute = {
    id: number;
    uuid: string;
    label: string;
    formId: number;
    text: string;
    description: string;
    type: QuestionType;
    questionType: QuestionQuestionType;
    order: number;
    sectionIdx: number;
    conditions: AttributeConditions;
    help: string;
    exportPath: string;
    config: any;
    path: string;
    associatedSurveyId: number;
    collectionId: number;
    required: boolean;
    visible: boolean;
    form: ChildForm;
    /** Below are form the form builder only not on the api */
    dirty: boolean;
    deleted: boolean;
};

export type CoreoAttribute = Omit<Attribute, 'dirty' | 'deleted'>;

export type FormCollectionItem = {
    key: string;
    value: any;
}

export type FormCollection = {
    id: number;
    name: string;
    geometric: boolean;
    items: FormCollectionItem[];
    data: any;
}

export type UserCalculated = {
    id: number;
    username: string;
    displayName: string;
    [key: string]: any;
};

export type UpdateSubformInputType = {
    name: string;
    title: string;
    titleAttributeId: number;
    secondaryTitleAttributeId: number;
    thankyou: string;
    allowMemberUpdate: boolean;
    allowOwnRecordDelete: boolean;
    private: boolean;
    attributes: UpdateFormAttributeInputType[];
}

export type UpdateFormAttributeInputType = {
    id: number;
    sectionIdx: number;
    required: boolean;
    type: QuestionType;
    questionType: QuestionQuestionType;
    description: string;
    text: string;
    exportPath: string;
    form: UpdateSubformInputType;
    conditions: AttributeConditions;
    config: string; // SequelizeJSON
    uuid: string;
    label: string;
    order: number;
    help: string;
    path: string;
    visible: boolean;
    associatedSurveyId: number;
    collectionId: number;
}

export type UpdateFormInputType = {
    id: number;
    name: string;
    title: string;
    titleAttributeId: number;
    secondaryTitleAttributeId: number;
    thankyou: string;
    allowMemberUpdate: boolean;
    allowOwnRecordDelete: boolean;
    private: boolean;
    attributes: UpdateFormAttributeInputType[];
}