import { CommonModule } from '@angular/common';
import { Component, computed, EventEmitter, inject, OnInit, Output, signal } from '@angular/core';
import { ButtonModule } from 'primeng/button';
import { TableModule, TableRowReorderEvent } from 'primeng/table';
import { catchError, of, take } from 'rxjs';
import { ApiService } from 'src/app/core';
import { TooltipModule } from 'primeng/tooltip';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { SelectButtonModule } from 'primeng/selectbutton';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { InputTextModule } from 'primeng/inputtext';
import { ConfirmationService, MessageService } from 'primeng/api';
import { ToastModule } from 'primeng/toast';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ProjectFormService } from 'src/app/features/projects/project-form/project-form.service';
import { CollectionAttribute } from '../../collection.model';
import { CollectionService } from '../collection.service';
import { InputSwitchModule } from 'primeng/inputswitch';
import { SvgIconComponent } from 'angular-svg-icon';

const attributeTypes = [
    'text',
    'float',
] as const;
type AttributeType = typeof attributeTypes[number];

type CollectionAttributesResponse = {
    project: {
        collections: {
            attributes: CollectionAttribute[]
        }[];
    };
}

@Component({
    selector: 'app-attribute-table',
    templateUrl: 'attribute-table.component.html',
    imports: [
        CommonModule,
        TableModule,
        ButtonModule,
        TooltipModule,
        OverlayPanelModule,
        SelectButtonModule,
        ReactiveFormsModule,
        InputTextModule,
        ToastModule,
        InputSwitchModule,
        ConfirmDialogModule,
        SvgIconComponent
    ],
    providers: [
        MessageService,
        ConfirmationService,
        ProjectFormService
    ],
    standalone: true
})

export class CollectionAttributeTableComponent implements OnInit {

    apiService = inject(ApiService);
    messageService = inject(MessageService);
    confirmationService = inject(ConfirmationService);
    formService = inject(ProjectFormService);
    collectionService = inject(CollectionService);

    @Output() hasUpdated: EventEmitter<void> = new EventEmitter();

    projectId = this.collectionService.projectId.asReadonly();
    collectionId = this.collectionService.collectionId.asReadonly();
    location = this.collectionService.location;
    mode = this.collectionService.mode;
    loading = signal(false);
    editCollectionAttributes = signal<CollectionAttribute[]>([]); // Existing collection, edit mode
    newCollectionAttributes = this.collectionService.newCollectionAttributes; // New collection, create mode

    attributes = computed(() => {
        const mode = this.mode();
        if (mode === 'edit') {
            return this.editCollectionAttributes();
        } else {
            return this.newCollectionAttributes();
        }
    });

    typeOptions: {label: string; value: AttributeType}[] = [
        { label: 'Alphabetic', value: 'text' },
        { label: 'Numeric', value: 'float' },
    ];

    newAttributeForm = new FormGroup({
        label: new FormControl('', Validators.required),
        type: new FormControl('text', { nonNullable: true }),
        visible: new FormControl(true, { nonNullable: true })
    });

    editAttributeId = signal<number>(null);
    editAttributeForm = new FormGroup({
        label: new FormControl('', Validators.required),
        type: new FormControl('text', { nonNullable: true }),
        visible: new FormControl(true, { nonNullable: true })
    });

    ngOnInit() {
        if (this.mode() === 'edit') {
            this.loadAttributes();
        }
    }

    private loadAttributes() {
        this.loading.set(true);

        const query = `query loadCollectionAttributes($projectId: Int!, $collectionId: Int!){
            project(id: $projectId){
                collections(where: { id: $collectionId }){
                    attributes {
                        id
                        label
                        type
                        visible
                        order
                    }
                }
            }
        }`;

        const input = { 
            projectId: this.projectId(), 
            collectionId: this.collectionId(), 
        };

        this.apiService.graphql<CollectionAttributesResponse>(query, input).pipe(
            take(1),
            catchError((e) => {
                console.warn(e);
                // show error toast/message?
                return of(null);
            })
        ).subscribe(res => {
            if (!!res) {
                this.editCollectionAttributes.set(res.project.collections[0].attributes);
            }
            this.loading.set(false);
        });
    }

    public onNewAttributeSubmit() {
        if (this.mode() === 'create') {
            const { value } = this.newAttributeForm;
            const id = this.formService.generateId();
            const attribute: CollectionAttribute = {
                id,
                path: value.label.toLowerCase().trim().replace(/\s+/g, '_'),
                label: value.label,
                type: value.type,
                visible: value.visible,
                order: this.attributes().length - 1
            }
            // this.newAttribute.emit(attribute);
            this.collectionService.addNewCollectionAttribute(attribute);
            this.newAttributeForm.reset();
        } else if (this.mode() === 'edit') {
            /** For existing collection */
            this.loading.set(true);
            
            const query = `mutation addNewCollectionAttribute($input: CollectionAttributeCreateInput!){
                createCollectionAttribute(input: $input){
                    id
                }
            }`;
    
            const { value } = this.newAttributeForm;
    
            let input = {
                projectId: this.projectId(),
                parentCollectionId: this.collectionId(),
                path: value.label.toLowerCase().trim().replace(/\s+/g, '_'),
                label: value.label,
                type: value.type,
                visible: value.visible
            };
    
            this.apiService.graphql(query, { input }).pipe(
                take(1),
                catchError((e) => {
                    console.error(e);
                    this.messageService.add({ severity: 'error', summary: 'Error', detail: 'There was an error adding the attribute' });
                    return of(null);
                })
            ).subscribe(res => {
                if (!!res) {
                    this.loadAttributes();
                    this.hasUpdated.emit();
                    this.newAttributeForm.reset();
                } else {
                    this.loading.set(false);
                }
            });
        }
    }

    public editAttribute(attribute: CollectionAttribute) {
        this.editAttributeId.set(attribute.id);
        this.editAttributeForm.controls['label'].setValue(attribute.label);
        this.editAttributeForm.controls['type'].setValue(attribute.type);
        this.editAttributeForm.controls['visible'].setValue(attribute.visible);
    }

    public onEditAttributeSubmit() {
        if (this.mode() === 'create') {
            const { value } = this.editAttributeForm;
            let attribute: CollectionAttribute = {
                id: this.editAttributeId(),
                label: value.label,
                type: value.type,
                visible: value.visible,
                order: this.attributes().find(a => a.id === this.editAttributeId()).order
            };
            // this.updateNewAttribute.emit(attribute);
            this.collectionService.updateNewCollectionAttribute(attribute);
            this.newAttributeForm.reset();
        } else if (this.mode() === 'edit') {
            /** For existing collection */
            this.loading.set(true);
    
            const query = `mutation updateCollectionAttribute($input: collectionAttributeUpdateInput!){
                updateCollectionAttribute(input: $input){
                    id
                }
            }`;
    
            const { value } = this.editAttributeForm;
    
            let input = {
                id: this.editAttributeId(),
                label: value.label,
                type: value.type,
                visible: value.visible
            };
    
            this.apiService.graphql(query, { input }).pipe(
                take(1),
                catchError((e) => {
                    console.error(e);
                    this.messageService.add({ severity: 'error', summary: 'Error', detail: 'There was an error editing the attribute' });
                    return of(null);
                })
            ).subscribe(res => {
                if (!!res) {
                    this.loadAttributes();
                    this.hasUpdated.emit();
                    this.newAttributeForm.reset();
                } else {
                    this.loading.set(false);
                }
            });
        }
    }

    public deleteAttributeCheck($event: Event, id: number) {
        $event.preventDefault();
        $event.stopPropagation();

        this.confirmationService.confirm({
            defaultFocus: 'none',
            message: 'Are you sure you want to delete this attribute?',
            target: $event.target,
            header: 'Delete attribute',
            rejectLabel: 'Cancel',
            rejectIcon: 'none',
            rejectButtonStyleClass: 'p-button p-button-lg p-button-outlined',
            acceptLabel: 'Delete',
            acceptIcon: 'none',
            acceptButtonStyleClass: 'p-button p-button-lg p-button-danger',
            accept: () => {
                this.deleteAttribute(id);
            }
        });
    }

    private deleteAttribute(id: number) {
        if (this.mode() === 'create') {
            // this.removeNewAttribute.emit(id);
            this.collectionService.removeNewCollectionAttribute(id);
        } else if (this.mode() === 'edit') {
            const query = `mutation deleteCollectionAttribute{
                deleteAttribute(input: { id: ${id} })
            }`;
    
            this.apiService.graphql<{ deleteAttribute: number }>(query).pipe(
                take(1),
                catchError((e) => {
                    console.error(e);
                    this.messageService.add({ severity: 'error', summary: 'Error', detail: 'There was an error deleting the attribute' });
                    return of(null);
                })
            ).subscribe(res => {
                if (!!res) {
                    this.loadAttributes();
                    this.hasUpdated.emit();
                }
            });
        }
    }

    public reorderRows($event: TableRowReorderEvent) {
        const { dragIndex, dropIndex } = $event;

        if (dragIndex === dropIndex) {
            return;
        }

        if (this.mode() === 'create') {
            // TODO:  might be able to ignore and grab the order when submitting?
        } else if (this.mode() === 'edit') {
            this.loading.set(true)
            
            const newAttributes = this.attributes().map((a, index) => {
                return {
                    ...a,
                    order: index
                }
            });
    
            const query = `mutation updateAttributes($input: AttributesUpdateInput!){
                updateAttributes(input: $input)
            }`;
    
            const input = {
                attributes: newAttributes
            }
    
            this.apiService.graphql<{ updateCollectionAttribute: CollectionAttribute }>(query, { input }).pipe(
                take(1),
                catchError((e) => {
                    console.warn(e);
                    // show error toast/message?
                    return of(null);
                })
            ).subscribe(res => {
                if (!!res) {
                    this.editCollectionAttributes.set(newAttributes);
                } else {
                    // if there was an error then the order returns to how it was
                    this.editCollectionAttributes.set([...this.attributes().sort((a, b) => a.order - b.order)]);
                }
                this.loading.set(false);
            });
        }
    }

    public refresh() {
        this.loadAttributes();
    }
}