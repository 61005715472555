<form [formGroup]="searchForm">
    <span class="p-input-icon-left w-full mb-6">
        <i class="pi pi-search"></i>
        <input type="text" pInputText formControlName="search" placeholder="Search for a folder" class="w-full" />
    </span>
</form>

@if(!searchForm.value.search){
<div class="w-full flex items-start justify-between">
    @if(homeService.current(); as c){
    @if(c.path.length){
    <button class="cursor-pointer" (click)="up()">
        <i class="pi pi-angle-left text-grey-50 text-[24px] mr-2 hover:text-primary transition-colors"></i>
    </button>
    }
    <h2 class="text-[#444] text-lg font-semibold flex flex-col flex-1 overflow-x-hidden">
        <span class="truncate">{{c.node.name ?? 'Projects'}}</span>
        @if(homeService.breadcrumbs().length > 1){
        <p-breadcrumb class="move-modal" [model]="homeService.breadcrumbs()" (onItemClick)="breadcrumbClick($event)"/>
        }
    </h2>
    }
    <div class="flex flex-1 items-center justify-end gap-2">
        <p class="text-gray-grey">Current {{cta}} Position</p>
        <p-button [outlined]="true"><span class="max-w-20 truncate">{{currentLocation().name ?? 'Projects'}}</span></p-button>
    </div>
</div>
}

<app-home-move-location [currentLocationId]="currentLocation().id" [excludedFolderIds]="folderIds"
    [searchedFolders]="searchedFolders()" (select)="selectFolder($event)"
    class="block flex-grow overflow-hidden"></app-home-move-location>

<div class="p-dialog-footer p-0 pt-5 flex justify-between flex-grow-0 mt-auto">
    <p-button size="large" [outlined]="true" (click)="openNewFolderModal()">
        <i class="fas fa-folder-plus text-primary"></i>
        New Folder
    </p-button>
    <p-button size="large" (click)="moveItems()" [disabled]="!canMove()">
        <svg-icon src="/assets/icons/move.svg"></svg-icon>
        Move {{cta}}
        @if(selectedFolderName()){
        into {{ selectedFolderName() }}
        }
    </p-button>
</div>