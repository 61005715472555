import { Component, computed, EventEmitter, inject, OnDestroy, Output } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ProjectFormService } from '../project-form.service';
import { Attribute } from '../project-form.model';
import { DropdownModule } from 'primeng/dropdown';
import { ApiService } from 'src/app/core';
import { NgClass } from '@angular/common';
import { ButtonModule } from 'primeng/button';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { ListCreateEditModalComponent } from './list-create-edit-modal.component';
import { MenuItem } from 'primeng/api';
import { CollectionImportType, ImportCollectionModalComponent } from 'src/app/features/collections/collection/import-collection-modal/import-collection-modal.component';
import { filter, tap } from 'rxjs';
import { JobsService } from '@core/services/jobs.service';
import { MenuModule } from 'primeng/menu';

@Component({
    selector: 'app-geometry-picker-settings',
    templateUrl: 'geometry-picker-settings.component.html',
    standalone: true,
    imports: [
        NgClass,
        FormsModule,
        DropdownModule,
        ButtonModule,
        MenuModule
    ],
    providers: [
        DialogService
    ]
})

export class GeometryPickerSettingsComponent implements OnDestroy {

    form = inject(ProjectFormService);
    apiService = inject(ApiService);
    dialogService = inject(DialogService);
    jobService = inject(JobsService);

    @Output() update: EventEmitter<Partial<Attribute>> = new EventEmitter();

    private dialogRef: DynamicDialogRef;

    projectId = this.form.projectId;
    attribute = this.form.selectedAttribute;
    collections = this.form.collections;

    collectionId = computed(() => this.attribute().collectionId);
    saved = computed(() => this.attribute().id > 0);
    collectionName = computed(() => {
        const id = this.collectionId();
        if (id) {
            return this.collections().find(c => c.id === id).name;
        } else {
            return '';
        }
    });
    geometryCollections = computed(() => this.collections().filter(c => c.geometric));

    public importCollectionMenuItems: MenuItem[] = [
        { label: 'From CSV', command: () => this.importGeometryCollection('csv') },
        { label: 'GeoJSON', command: () => this.importGeometryCollection('geojson') },
        { label: 'Zipped Shapefile', command: () => this.importGeometryCollection('shapefile') }
    ];

    ngOnDestroy(): void {
        if (this.dialogRef) {
            this.dialogRef.close();
        }
    }

    openListModal() {
        this.dialogRef = this.dialogService.open(ListCreateEditModalComponent, {
            data: {
                collectionId: this.collectionId(),
                projectId: this.projectId()
            },
            closeOnEscape: false,
            showHeader: false,
            modal: true,
            height: '90vh',
            width: '90vw',
            contentStyle: { 'padding': '0px', 'overflow': 'hidden', 'background': 'transparent', 'position': 'relative' }
        });

        /** On close load collection as there may be changes */ 
        this.dialogRef.onClose.subscribe(_ => {
            this.form.loadCollection(this.collectionId());
        });
    }

    importGeometryCollection(type: CollectionImportType) {
        const headerMap = {
            csv: 'Create collection from CSV',
            geojson: 'Create collection from geoJSON',
            shapefile: 'Create collection from shapefile'
        }

        const ref = this.dialogService.open(ImportCollectionModalComponent, {
            header: headerMap[type],
            modal: true,
            data: {
                importType: type,
                projectId: this.projectId(),
                newCollection: true
            },
            width: '620px'
        });

        ref.onClose.pipe(filter(res => !!res)).subscribe(result => {
            if (result.id) {
                this.jobService.addJob(result).pipe(
                    filter(job => job.status === 'complete'),
                    tap((job) => {
                        this.form.loadCollection(parseInt(job.url));
                    })
                ).subscribe();
            }
        });
    }
}
