import { Component, computed, effect, ElementRef, EventEmitter, inject, input, Input, OnDestroy, OnInit, Output, signal, viewChild, ViewContainerRef } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { NG_REDUX } from "@core/core.module";
import bbox from '@turf/bbox';
import mapboxgl, { Map as MapboxMap, MapOptions } from 'mapbox-gl';
import ngRedux from 'ng-redux';
import { ConfirmationService, MessageService } from 'primeng/api';
import { ButtonModule } from 'primeng/button';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { InputNumberModule } from 'primeng/inputnumber';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { PanelModule } from 'primeng/panel';
import { ToastModule } from 'primeng/toast';
import { catchError, of, take } from 'rxjs';
import { MapsService } from 'src/app/core/services/maps.service';
import { ColorPickerComponent } from 'src/app/shared/components/color-picker/color-picker.component';
import { environment } from 'src/environments/environment';
import { CollectionItemMediaListComponent } from './collection-item-media-list.component';
import { CollectionItemPinUploadComponent } from './collection-item-pin-upload.component';
import { CollectionItemPreviewComponent } from './collection-item-preview.component';
import { CollectionItemService } from './collection-item.service';
import { ReduxService } from '@core/services/redux.service';
import { getProjectOrgFeatures } from 'src/store';
import { NgClass } from '@angular/common';
import { SvgIconComponent } from 'angular-svg-icon';
import { CollectionAttribute, CollectionItem } from '../collection.model';
import { CollectionItemMapComponent } from './collection-item-map.component';

const DEFAULT_COLOR = '#888888';
const DEFAULT_FEATURE = {
    type: "FeatureCollection",
    features: [
        {
            type: "Feature",
            geometry: {
                type: "MultiPolygon",
                coordinates: [
                    [
                        [
                            [-2.6297563737701637, 51.44745288679022],
                            [-2.627665637232326, 51.44728199936685],
                            [-2.627905557818167, 51.44616409502876],
                            [-2.6308188792243357, 51.44647739582018],
                            [-2.6297563737701637, 51.44745288679022]
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            geometry: {
                type: "Point",
                coordinates: [-2.63095, 51.4468]
            }
        }
    ]
} as GeoJSON.FeatureCollection;

@Component({
    selector: 'app-collection-item-detail',
    templateUrl: 'collection-item-detail.component.html',
    standalone: true,
    imports: [
        ButtonModule,
        PanelModule,
        ReactiveFormsModule,
        InputTextModule,
        InputNumberModule,
        CollectionItemMediaListComponent,
        ConfirmDialogModule,
        InputTextareaModule,
        ToastModule,
        ColorPickerComponent,
        NgClass,
        CollectionItemPreviewComponent,
        SvgIconComponent,
        CollectionItemMapComponent
    ],
    providers: [
        CollectionItemService,
        ConfirmationService,
        DialogService,
        MessageService
    ]
})

export class CollectionItemDetailComponent implements OnInit, OnDestroy {

    collectionItemService = inject(CollectionItemService);
    confirmationService = inject(ConfirmationService);
    dialogService = inject(DialogService);
    messageService = inject(MessageService);
    viewContainerRef = inject(ViewContainerRef);
    mapsService = inject(MapsService);
    $ngRedux = inject<ngRedux.INgRedux>(NG_REDUX);
    redux = inject(ReduxService);

    /** input signal errors - is not a function - so using old style */
    @Input() itemId: number;
    @Input() collectionId: number;
    @Input() projectId: number;
    mode = input<'create' | 'edit'>('edit');
    location = input<'page' | 'modal'>('page');
    selectedNewItem = input<CollectionItem>(null); // New collection in create mode
    newAttributes = input<CollectionAttribute[]>([]); // New collection in create mode

    @Output() back: EventEmitter<any> = new EventEmitter();
    @Output() mapb: EventEmitter<void> = new EventEmitter();
    @Output() saveNewCollectionItem: EventEmitter<CollectionItem> = new EventEmitter();
    @Output() deleteNewCollectionItem: EventEmitter<number> = new EventEmitter();
    @Output() deleteEditCollectionItem: EventEmitter<void> = new EventEmitter();
    @Output() isDirty: EventEmitter<void> = new EventEmitter();
    
    mediaInput = viewChild<ElementRef>('mediaInput');
    geoCustomMap = viewChild<ElementRef>('geoCustomMap');

    loading = this.collectionItemService.loading;
    mediaLoading = this.collectionItemService.mediaLoading;
    collectionName = this.collectionItemService.collectionName;
    item = this.collectionItemService.item;
    geometry = this.collectionItemService.geometry;
    mediaItems = computed(() => {
        if (this.mode() === 'create') {
            return this.collectionItemService.newMediaItems();
        } else if (this.mode() === 'edit') {
            return this.collectionItemService.mediaItems();
        }
    });
    
    attributeQuestions = this.collectionItemService.attributeQuestions;
    
    valueForm = this.collectionItemService.valueForm;
    valueFormDirty = this.collectionItemService.valueFormDirty;
    valueError = this.collectionItemService.valueError;
    attributeForm = this.collectionItemService.attributeForm;
    attributeFormDirty = this.collectionItemService.attributeFormDirty;
    geoCustomizationForm = this.collectionItemService.geoCustomizationForm;
    geoCustomizationFormDirty = this.collectionItemService.geoCustomizationFormDirty;
    newMediaItemsDirty = this.collectionItemService.newMediaItemsDirty;
    geometryDirty = this.collectionItemService.geometryDirty;
    
    addGeometry = signal(false);
    showMap = computed(() => {
        console.log('geometry', this.geometry());
        
        return !!this.geometry() || this.addGeometry();
    });

    isNoGeoPin = computed<boolean>(() => !this.item().icon);
    isNoGeoColor = computed<boolean>(() => !this.item().color);

    ddsEnabled = false;

    hasChanges = computed(() => {
        const hasChanges = this.valueFormDirty() || this.geoCustomizationFormDirty() || (this.attributeForm() && this.attributeFormDirty()) || (this.mode() === 'create' && this.newMediaItemsDirty()) || (this.mode() === 'create' && this.geometryDirty());
        return hasChanges
    });

    canSave = computed(() => {
        const hasChanges = this.hasChanges();
        const loading = this.loading();
        const valueError = this.valueError();
        return (
            !valueError &&
            !loading &&
            hasChanges
        );
    });

    preview = signal(false);

    private customMap: MapboxMap;
    private geoCustomResizeObserver: ResizeObserver;
    private dialogRef: DynamicDialogRef;

    constructor() {
        effect(() => {
            const item = this.item();
            const map = this.geoCustomMap();
            if (!!item && !!map && !this.customMap) {
                this.initGeoCustomMap();
            }
        });

        effect(() => {
            const hasChanges = this.hasChanges();
            if (hasChanges) {
                this.isDirty.emit();
            }
        });
    }

    ngOnInit() {
        this.collectionItemService.projectId.set(this.projectId);
        this.collectionItemService.collectionId.set(this.collectionId);
        this.collectionItemService.mode.set(this.mode());

        if (this.mode() === 'create' && this.selectedNewItem()) {
            this.collectionItemService.setValues(this.selectedNewItem(), this.newAttributes());
        } else if (this.mode() === 'edit') {
            this.collectionItemService.itemId.set(this.itemId);
            this.collectionItemService.loadItem();
        }

        const state = this.redux.state();
        const orgFeatures = getProjectOrgFeatures(state);
        this.ddsEnabled = orgFeatures && (orgFeatures.dataDrivenStyling === true);
    }

    ngOnDestroy() {
        if (this.dialogRef) {
            this.dialogRef.close();
        }
    }

    goBackCheck($event) {
        const hasChanges = this.hasChanges();

        if (hasChanges) {
            this.confirmationService.confirm({
                defaultFocus: 'none',
                message: 'You have unsaved changes.<br>Are you sure you want to go back?.',
                target: $event.target,
                header: 'Unsaved changes',
                rejectLabel: 'Cancel',
                rejectIcon: 'none',
                rejectButtonStyleClass: 'p-button p-button-lg p-button-outlined',
                acceptLabel: 'Discard changes',
                acceptIcon: 'none',
                acceptButtonStyleClass: 'p-button p-button-lg p-button-danger',
                accept: () => {
                    this.back.emit();
                }
            });
        } else {
            this.back.emit();
        }      
    }

    showPreview() {
        this.preview.set(true);
    }

    hidePreview() {
        this.preview.set(false);
    }

    async saveItem() {
        if (this.mode() === 'edit') {
            this.collectionItemService.updateItem();
        } else if (this.mode() === 'create') {
            const item = await this.collectionItemService.getCreateCollectionItem();
            this.saveNewCollectionItem.emit(item);
        }
    }

    deleteItemCheck($event) {
        this.confirmationService.confirm({
            defaultFocus: 'none',
            message: 'Are you sure you want to delete this collection item?<br>This cannot be undone.',
            target: $event.target,
            header: 'Delete collection item',
            rejectLabel: 'Cancel',
            rejectIcon: 'none',
            rejectButtonStyleClass: 'p-button p-button-lg p-button-outlined',
            acceptLabel: 'Delete item',
            acceptIcon: 'none',
            acceptButtonStyleClass: 'p-button p-button-lg p-button-danger',
            accept: () => {
                this.deleteItem();
            }
        });
    }

    private deleteItem() {
        if (this.mode() === 'create') {
            this.deleteNewCollectionItem.emit(this.item().id);
            this.back.emit();
        } else if (this.mode() === 'edit') {
            this.loading.set(true);
            this.collectionItemService.deleteItem(this.itemId).pipe(
                take(1),
                catchError((e) => {
                    console.warn(e);
                    return of(null);
                })
            ).subscribe(res => {
                if (!!res) {
                    this.back.emit();
                } else {
                    setTimeout(() => {
                        this.confirmationService.confirm({
                            defaultFocus: 'none',
                            message: 'Unable to delete this collection item as it is used in one or more records.',
                            header: 'Unable to delete item',
                            rejectLabel: 'Close',
                            rejectIcon: 'none',
                            rejectButtonStyleClass: 'p-button p-button-lg p-button-outlined',
                            acceptVisible: false,
                        });
                    }, 100);
                }
                this.loading.set(false);
            });
        }
    }

    addMedia($event: Event) {
        $event.preventDefault();
        this.mediaInput().nativeElement.click();
    }

    selectMediaHandler() {
        const files = this.mediaInput().nativeElement.files;
        this.handleFiles(files!);
    }

    private handleFiles(fileList: FileList) {
        if (fileList.length > 0) {
            // TODO: add check for file size? + error message https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input/file
            const file: File = fileList[0];
            this.collectionItemService.createMediaItem(file);
        }
    }

    private initGeoCustomMap() {
        const mapOptions: MapOptions = {
            accessToken: environment.mapboxApiKey,
            container: this.geoCustomMap().nativeElement,
            style: 'mapbox://styles/mapbox/streets-v12'
        };
    
        const bounds = bbox(DEFAULT_FEATURE);
        mapOptions.bounds = bounds as mapboxgl.LngLatBoundsLike;
        mapOptions.fitBoundsOptions = { padding: 20 };
    
        this.customMap = new mapboxgl.Map(mapOptions);
    
        this.customMap.on('load', async () => {
            this.customMap.addSource('geometry-source', {
                type: 'geojson',
                data: DEFAULT_FEATURE
            });

            if (this.isNoGeoPin()) {
                this.customMap.addLayer({
                    id: 'point-layer',
                    source: 'geometry-source',
                    type: 'circle',
                    filter: ['==', '$type', 'Point'],
                    paint: {
                        'circle-color': this.item().color || DEFAULT_COLOR,
                        'circle-radius': 10,
                        'circle-stroke-width': 1,
                        'circle-stroke-color': '#FFFFFF'
                    }
                });
            } else {
                this.updatePinIcon(this.item().icon);
            }
    
            this.customMap.addLayer({
                id: 'polygon-outline-layer',
                source: 'geometry-source',
                type: 'line',
                filter: ['==', '$type', 'Polygon'],
                paint: {
                    'line-color': '#FFFFFF',
                    'line-width': 3
                }
            });
    
            this.customMap.addLayer({
                id: 'polygon-fill-layer',
                source: 'geometry-source',
                type: 'fill',
                filter: ['==', '$type', 'Polygon'],
                paint: {
                    'fill-color': this.item().color || DEFAULT_COLOR
                }
            });
        });
    
        // Disable map interactions
        this.customMap.scrollZoom.disable();
        this.customMap.boxZoom.disable();
        this.customMap.dragRotate.disable();
        this.customMap.dragPan.disable();
        this.customMap.keyboard.disable();
        this.customMap.doubleClickZoom.disable();
        this.customMap.touchZoomRotate.disable();
    
        this.geoCustomResizeObserver = new ResizeObserver(() => this.customMap.resize());
        this.geoCustomResizeObserver.observe(this.geoCustomMap().nativeElement);
    }

    setDefaultColor() {
        this.colorChange(DEFAULT_COLOR);
    }

    updatePinIcon(base64: string) {
        this.removePinIcon();

        const image = new Image();
        image.crossOrigin = 'anonymous';
        image.src = base64;

        image.onload = () => {
            this.customMap.addImage('custom-icon', image);

            this.customMap.addLayer({
                id: 'point-layer',
                source: 'geometry-source',
                type: 'symbol',
                filter: ['==', '$type', 'Point'],
                layout: {
                    'icon-image': 'custom-icon',
                    'icon-size': 0.085
                }
            });
        };
    }

    removePinIcon() {
        if (this.customMap.getLayer('point-layer')) {
            this.customMap.removeLayer('point-layer');
        }
        if (this.customMap.hasImage('custom-icon')) {
            this.customMap.removeImage('custom-icon');
        }
    }

    colorChange(color: string) {
        const mapColor = color || DEFAULT_COLOR;
        const colorControl = this.geoCustomizationForm().get('color');
        colorControl?.markAsDirty();
        colorControl?.setValue(color);
        
        this.customMap.setPaintProperty('polygon-fill-layer', 'fill-color', mapColor);
        this.isNoGeoPin() && this.customMap.setPaintProperty('point-layer', 'circle-color', mapColor);
    }

    iconChange(icon: string | null) {
        const iconControl = this.geoCustomizationForm().get('icon');
        iconControl?.markAsDirty();
        iconControl?.setValue(icon);

        if (icon) {
            this.updatePinIcon(icon);
        } else {
            this.removePinIcon();
            this.customMap.addLayer({
                id: 'point-layer',
                source: 'geometry-source',
                type: 'circle',
                filter: ['==', '$type', 'Point'],
                paint: {
                    'circle-color': this.item().color || DEFAULT_COLOR,
                    'circle-radius': 10,
                    'circle-stroke-width': 1,
                    'circle-stroke-color': '#FFFFFF'
                }
            });
        }
    }

    showUploadCustomPin() {
        this.dialogRef = this.dialogService.open(CollectionItemPinUploadComponent, {
            header: 'Upload a custom icon',
            width: '560px',
            height: '608px',
            modal: true
        });
        this.dialogRef.onClose.subscribe(res => {
            res?.icon && this.iconChange(res.icon);
        });
    }
}