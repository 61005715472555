import { HttpClient } from "@angular/common/http";
import { Injectable, inject } from "@angular/core";
import { FeatureCollection, Geometry } from "geojson";
import { Source } from "mapbox-gl";
import ngRedux from 'ng-redux';
import { lastValueFrom } from "rxjs";
import { environment } from "src/environments/environment";
import { urlToCDN } from "src/helpers/utils";
import { MapLayerState } from "src/store/records/records.reducer";
import { getProjectBespoke, getProjectCollection, getProjectId, getProjectMapLayer, getProjectSlug } from "src/store/selectors";
import { CoreoCollection, CoreoDataLayerStyle } from "src/types";
import { NG_REDUX } from "../core.module";
import { safelyRemoveLayer, safelyRemoveSource } from "./maps.service";
export type MapLayerType = 'records' | 'collection' | 'custom';

export interface MapDataLayerConfig {
    style: CoreoDataLayerStyle;
}

export interface MapRecordLayerConfig {
    formId: number;
}

export interface MapCollectionLayerConfig {
    collectionId: number;
}

export interface MapCustomLayerConfig {
    layerType: string;
    type: string;
    sourceId: number;
    sourceType: 'records' | 'collection' | 'image' | 'geojson';
    source: string;
    paint: any;
    layout: any;
    bounds: number[];
}

let layerId = 1;

export type AnyMapLayerConfig =
    | MapRecordLayerConfig
    | MapCollectionLayerConfig
    | MapCustomLayerConfig;

export abstract class MapDataSource {
    sourceLayer: string;
    abstract init(): Promise<mapboxgl.AnySourceData>;
}

interface MapRecordsSourceConfig {
    projectId: number;
    query: any;
    clusterMaxZoom?: number;
    boundary?: any;
    boundaryId?: any;
    attributes?: string[];
    styled?: boolean;
}

export class MapRecordsSource extends MapDataSource {

    override sourceLayer: string = 'records';
    constructor(private mapsUrl: string, private $http: HttpClient, private config: MapRecordsSourceConfig) {
        super();
    }

    async init(): Promise<mapboxgl.VectorSourceSpecification> {
        const mapTilesUrl = `${this.mapsUrl}/tiles`;

        const { projectId, boundary, attributes = [], boundaryId, query } = this.config;

        const body: any = {
            projectId: projectId,
            query: {
                ...query
            },
            clusterMaxZoom: this.config.clusterMaxZoom ?? 11,
            attributes: ['surveyId', ...attributes],
            boundaryId,
            boundary,
            styled: this.config.styled ?? false
            // styled: true
        };

        const result = await lastValueFrom<any>(this.$http.post(mapTilesUrl, body));
        const mapId = result.mapId;
        const url = `${mapTilesUrl}/${mapId}/{z}/{x}/{y}.mvt`;

        return {
            'type': 'vector',
            tiles: [url],
            promoteId: {
                [this.sourceLayer]: 'id'
            }
        };
    }
}

export class MapGeoJSONSource extends MapDataSource {

    constructor(initialData: string | FeatureCollection<Geometry>) {
        super();
        if (initialData) {
            this.setData(initialData);
        }
    }

    public data: string | FeatureCollection<Geometry> = {
        type: 'FeatureCollection',
        features: []
    };

    public setData(data: string | FeatureCollection<Geometry>) {
        this.data = data;
    }

    async init(): Promise<mapboxgl.AnySourceData> {
        return {
            type: 'geojson',
            data: this.data
        };
    }
}

export class MapCollectionSource extends MapDataSource {
    constructor(private mapsUrl: string, private projectSlug: string, private collectionId: number) {
        super();
        this.sourceLayer = `collections-${this.collectionId}`;
    }

    async init(): Promise<mapboxgl.AnySourceData> {
        const url = `${this.mapsUrl}/projects/${this.projectSlug}/source/c${this.collectionId}/{z}/{x}/{y}.mvt`;
        return {
            'type': 'vector',
            tiles: [url],
            promoteId: {
                [this.sourceLayer]: 'id'
            }
        };
    }
}

export abstract class MapLayer {
    public id: string;
    protected map: mapboxgl.Map;
    protected visible: boolean = true;

    abstract remove(): void;
    abstract addTo(map: mapboxgl.Map): Promise<void>;
    abstract layerIds(): string[];
    abstract clusterIds(): string[];

    constructor() {
        this.id = `coreo-${layerId++}`;
    }

    setVisible(visible: boolean) {
        this.visible = visible;
    }

    show() {
        this.visible = true;
        for (const l of [...this.layerIds(), ...this.clusterIds()]) {
            this.map.setLayoutProperty(l, 'visibility', 'visible');
        }
    }

    hide() {
        this.visible = false;
        for (const l of [...this.layerIds(), ...this.clusterIds()]) {
            this.map.setLayoutProperty(l, 'visibility', 'none');
        }
    }

    move() {
        for (const l of [...this.layerIds(), ...this.clusterIds()]) {
            this.map.moveLayer(l);
        }
    }
}

interface MapStyleableLayerStyle extends CoreoDataLayerStyle {
    id: number;
    sort: number;
};

interface MapStyleableLayerComputedStyle {
    pointLayout: mapboxgl.CircleLayout;
    pointPaint: mapboxgl.CirclePaint;
    lineLayout: mapboxgl.LineLayout;
    linePaint: mapboxgl.LinePaint;
    polygonBorderLayout: mapboxgl.LineLayout;
    polygonBorderPaint: mapboxgl.LinePaint;
    polygonFillLayout: mapboxgl.FillLayout;
    polygonFillPaint: mapboxgl.FillPaint;
}


export class MapStyleableLayer extends MapLayer {

    override id: string;
    private styles: (MapStyleableLayerStyle & { id: number; sort: number })[] = [];
    private styleKey = 'surveyId';
    private sourceImpl: Source;
    private styleSourceId: string;
    private styleSourceImpl: Source;

    constructor(protected source: MapDataSource, protected styleSource: MapDataSource | string = 'style') {
        super();
    }

    private pointPaint(style: CoreoDataLayerStyle): MapStyleableLayerComputedStyle['pointPaint'] {
        return {
            'circle-color': ['coalesce', ['get', '__color'], style.color],
            'circle-opacity': this.featureStateProperty(
                style.pointOpacity,
                Math.min(style.pointBorderOpacity + 0.1, 1.0),
                Math.min(style.pointBorderOpacity + 0.2, 1.0)
            ),
            'circle-radius': this.featureStateProperty(style.pointRadius, style.pointRadius + 5, style.pointRadius + 10),
            'circle-stroke-color': style.pointBorderColor,
            'circle-stroke-width': this.featureStateProperty(style.pointBorderWidth, style.pointBorderWidth + 2, style.pointBorderWidth + 3),
            'circle-stroke-opacity': style.pointBorderOpacity
        };
    }

    private linePaint(style: CoreoDataLayerStyle): MapStyleableLayerComputedStyle['linePaint'] {
        return {
            'line-color': ['coalesce', ['get', '__color'], style.color],
            'line-width': this.featureStateProperty(
                style.lineWidth,
                style.lineWidth + 2,
                style.lineWidth + 4
            )
        };
    }

    private polygonFillPaint(style: CoreoDataLayerStyle): MapStyleableLayerComputedStyle['polygonFillPaint'] {
        return {
            'fill-color': ['coalesce', ['get', '__color'], style.color],
            'fill-opacity': this.featureStateProperty(
                style.polygonOpacity,
                Math.min(style.pointBorderOpacity + 0.1, 1),
                Math.min(style.polygonOpacity + 0.2, 1)
            )
        };
    }

    private polygonBorderPaint(style: CoreoDataLayerStyle): MapStyleableLayerComputedStyle['polygonBorderPaint'] {
        return {
            'line-opacity': style.polygonBorderOpacity,
            'line-width': this.featureStateProperty(
                style.polygonBorderWidth,
                style.polygonBorderWidth + 2,
                style.polygonBorderWidth + 4
            ),
            'line-color': style.polygonBorderColor
        };
    }

    setStyle(id: number, style: CoreoDataLayerStyle, sort: number, fallbackColor: string = '#ff0000') {
        const idx = this.styles.findIndex(s => s.id === id);
        if (idx === -1) {
            this.styles.push({
                ...defaultDataLayerStyle(fallbackColor),
                ...style,
                sort,
                id
            });
        } else {
            this.styles[idx] = {
                ...this.styles[idx],
                sort,
                ...style
            };
        }
    }

    private featureStateProperty(baseValue: any, selectedValue: any, hoverValue: any): mapboxgl.ExpressionSpecification {
        return [
            'case',
            ['boolean', ['feature-state', 'selected'], false],
            selectedValue,
            ['boolean', ['feature-state', 'hover'], false],
            hoverValue,
            baseValue
        ]
    }

    private createSortKeyExpression(): mapboxgl.ExpressionSpecification {
        const sort: mapboxgl.ExpressionSpecification = ['match', ['get', this.styleKey]];
        for (const style of this.styles) {
            sort.push(~~style.id);
            sort.push(style.sort);
        }
        sort.push(0);
        return sort;
    }

    createPointLayout(): mapboxgl.CircleLayerSpecification['layout'] {
        if (this.styles.length <= 1) {
            return {
                'visibility': this.visible ? 'visible' : 'none'

            };
        }
        return {
            'visibility': this.visible ? 'visible' : 'none',
            'circle-sort-key': this.createSortKeyExpression()
        };
    }

    createPointPaint(): mapboxgl.CircleLayerSpecification['paint'] {

        if (this.styles.length === 0) {
            return {};
        }

        if (this.styles.length === 1) {
            return this.pointPaint(this.styles[0]);
        }

        const cColor: mapboxgl.ExpressionSpecification = ['match', ['get', this.styleKey]];
        const cOpacity: mapboxgl.ExpressionSpecification = ['match', ['get', this.styleKey]];
        const cRadius: mapboxgl.ExpressionSpecification = ['match', ['get', this.styleKey]];
        const cStrokeColor: mapboxgl.ExpressionSpecification = ['match', ['get', this.styleKey]];
        const cStrokeWidth: mapboxgl.ExpressionSpecification = ['match', ['get', this.styleKey]];
        const cStrokeOpacity: mapboxgl.ExpressionSpecification = ['match', ['get', this.styleKey]];

        const defaultStyle = defaultDataLayerStyle();

        for (const style of this.styles) {
            const matchId = ~~style.id;
            const paint = this.pointPaint(style);

            cColor.push(matchId);
            cColor.push(paint["circle-color"]);

            cOpacity.push(matchId);
            cOpacity.push(paint['circle-opacity']);


            cRadius.push(matchId);
            cRadius.push(paint['circle-radius']);

            cStrokeColor.push(matchId);
            cStrokeColor.push(paint["circle-stroke-color"]);

            cStrokeWidth.push(matchId);
            cStrokeWidth.push(paint['circle-stroke-width']);

            cStrokeOpacity.push(matchId);
            cStrokeOpacity.push(paint["circle-stroke-opacity"]);;
        }

        cColor.push(defaultStyle.color);
        cOpacity.push(defaultStyle.pointOpacity);
        cRadius.push(defaultStyle.pointRadius);
        cStrokeColor.push(defaultStyle.pointBorderColor);
        cStrokeWidth.push(defaultStyle.pointBorderWidth);
        cStrokeOpacity.push(defaultStyle.pointBorderOpacity);

        return {
            'circle-color': cColor,
            'circle-opacity': cOpacity,
            'circle-radius': cRadius,
            'circle-stroke-color': cStrokeColor,
            'circle-stroke-width': cStrokeWidth,
            'circle-stroke-opacity': cStrokeOpacity
        };
    }

    createLinePaint(): mapboxgl.LinePaint {
        if (this.styles.length === 0) {
            return {};
        }

        if (this.styles.length === 1) {
            return this.linePaint(this.styles[0]);
        }

        const lColor: mapboxgl.ExpressionSpecification = ['match', ['get', this.styleKey]];
        const lWidth: mapboxgl.ExpressionSpecification = ['match', ['get', this.styleKey]];
        const defaultStyle = defaultDataLayerStyle();

        for (const style of this.styles) {
            const matchId = ~~style.id;
            const paint = this.linePaint(style);
            lColor.push(matchId);
            lColor.push(paint["line-color"]);

            lWidth.push(matchId);
            lWidth.push(paint['line-width']);
        }

        lColor.push(defaultStyle.color);
        lWidth.push(defaultStyle.lineWidth);

        return {
            'line-width': lWidth,
            'line-color': lColor
        };
    }

    createLineLayout(): mapboxgl.LineLayout {
        if (this.styles.length <= 1) {
            return {
                'visibility': this.visible ? 'visible' : 'none'
            };
        }
        return {
            'visibility': this.visible ? 'visible' : 'none',
            'line-sort-key': this.createSortKeyExpression()
        };
    }

    createPolygonFillPaint(): mapboxgl.FillPaint {

        if (this.styles.length === 0) {
            return {}
        }

        if (this.styles.length === 1) {
            return this.polygonFillPaint(this.styles[0]);
        }

        const pColor: mapboxgl.Expression = ['match', ['get', this.styleKey]];
        const pOpacity: mapboxgl.Expression = ['match', ['get', this.styleKey]];
        const defaultStyle = defaultDataLayerStyle();

        for (const style of this.styles) {
            const matchId = ~~style.id;
            const paint = this.polygonFillPaint(style);
            pColor.push(matchId);
            pColor.push(paint['fill-color']);

            pOpacity.push(matchId);
            pOpacity.push(paint['fill-opacity']);
        }
        pColor.push(defaultStyle.color);
        pOpacity.push(defaultStyle.polygonOpacity);



        return {
            'fill-color': pColor,
            'fill-opacity': pOpacity
        };
    }

    createPolygonFillLayout(): mapboxgl.FillLayout {
        if (this.styles.length <= 1) {
            return {
                'visibility': this.visible ? 'visible' : 'none'
            };
        }
        return {
            'visibility': this.visible ? 'visible' : 'none',
            'fill-sort-key': this.createSortKeyExpression()
        };
    }

    createPolygonBorderPaint(): mapboxgl.LinePaint {
        if (this.styles.length === 0) {
            return {};
        }

        if (this.styles.length === 1) {
            return this.polygonBorderPaint(this.styles[0]);
        }

        const pBorderOpacity: mapboxgl.ExpressionSpecification = ['match', ['get', this.styleKey]];
        const pBorderColor: mapboxgl.ExpressionSpecification = ['match', ['get', this.styleKey]];
        const pBorderWidth: mapboxgl.ExpressionSpecification = ['match', ['get', this.styleKey]];
        const defaultStyle = defaultDataLayerStyle();

        for (const style of this.styles) {
            const matchId = ~~style.id;
            const paint = this.polygonBorderPaint(style);

            pBorderOpacity.push(matchId);
            pBorderOpacity.push(paint['line-opacity']);

            pBorderColor.push(matchId);
            pBorderColor.push(paint['line-color']);

            pBorderWidth.push(matchId);
            pBorderWidth.push(paint['line-width']);
        }

        pBorderOpacity.push(defaultStyle.polygonBorderOpacity);
        pBorderColor.push(defaultStyle.polygonBorderColor);
        pBorderWidth.push(defaultStyle.polygonBorderWidth);

        return {
            'line-opacity': pBorderOpacity,
            'line-color': pBorderColor,
            'line-width': pBorderWidth
        };
    }

    createPolygonBorderLayout(): mapboxgl.LineLayerSpecification['layout'] {
        if (this.styles.length <= 1) {
            return {
                'visibility': this.visible ? 'visible' : 'none'
            };
        }
        return {
            'visibility': this.visible ? 'visible' : 'none',
            'line-sort-key': this.createSortKeyExpression()
        };
    }


    createClusterLayers() {

        const circleLayer: mapboxgl.CircleLayerSpecification = {
            id: `${this.id}-clusters`,
            type: 'circle',
            source: this.id,
            filter: ["has", "pc"],
            paint: {
                "circle-color": '#fff',
                "circle-radius": [
                    "step",
                    ["get", "pc"],
                    20,
                    100,
                    30,
                    750,
                    40
                ],
                'circle-stroke-width': 3,
                'circle-stroke-color': this.styles.length === 1 ? this.styles[0].color : '#0069DF',
            }
        };

        const countLayer: mapboxgl.SymbolLayerSpecification = {
            id: `${this.id}-clusters_count`,
            type: 'symbol',
            source: this.id,
            filter: ["has", "pc"],
            layout: {
                "text-field": "{pca}",
                'text-allow-overlap': false,
                'text-size': 16,
            },
            paint: {
                'text-color': '#000000'
            }
        };

        if (this.source.sourceLayer) {
            circleLayer["source-layer"] = this.source.sourceLayer;
            countLayer["source-layer"] = this.source.sourceLayer;
        }

        this.map.addLayer(circleLayer);
        this.map.addLayer(countLayer);
    }

    createStyleLayout(): mapboxgl.SymbolLayerSpecification['layout'] {
        const allowOverlap = true;
        const ignorePlacement = true;
        const defaultStyle = defaultDataLayerStyle();

        let labelFontSize: mapboxgl.ExpressionSpecification | number = defaultStyle.labelFontSize;

        if (this.styles.length > 0) {
            labelFontSize = ['match', ['get', this.styleKey]];
            for (const style of this.styles) {
                labelFontSize.push(~~style.id);
                labelFontSize.push(style.labelFontSize);
            }
            labelFontSize.push(defaultStyle.labelFontSize);
        }

        const iconSourceSize = 256;
        const targetSize = 32;
        const iconSize = 1 / (iconSourceSize / targetSize);

        return {
            "text-field": "{__label}",
            'text-allow-overlap': allowOverlap,
            'text-ignore-placement': ignorePlacement,
            'text-size': labelFontSize,
            'text-optional': false,
            'text-anchor': 'top',
            // Icons
            "icon-image": "{__icon}",
            'icon-allow-overlap': allowOverlap,
            'icon-ignore-placement': ignorePlacement,
            'icon-size': iconSize,
            'icon-optional': false,
            'icon-anchor': 'bottom'
        }
    }

    createStylePaint(): mapboxgl.SymbolLayerSpecification['paint'] {
        return {
            'text-color': '#000000',
            'icon-color': '#000000'
        }
    }

    createStyleLayer() {
        const labelLayer: mapboxgl.SymbolLayerSpecification = {
            id: `${this.id}-style`,
            type: 'symbol',
            source: this.id,
            layout: this.createStyleLayout(),
            paint: this.createStylePaint()
        }

        if (this.styleSource instanceof MapDataSource) {
            labelLayer.source = this.styleSourceId;
        } else {
            labelLayer["source-layer"] = this.styleSource;
        }
        this.map.addLayer(labelLayer);
    }

    setOrder(id: number, sort: number) {
        const idx = this.styles.findIndex(s => s.id === id);
        this.styles[idx].sort = sort;
    }

    protected createPointLayer() {

        const pointLayer: mapboxgl.CircleLayerSpecification = {
            id: `${this.id}-point`,
            source: this.id,
            type: 'circle',
            filter: ["all",
                ["==", ["geometry-type"], "Point"],
                ["!", ["has", "pc"]],
                ["any",
                    ["!", ["has", "__icon"]],
                    ["==", ["get", "__icon"], false]
                ]
            ],
            layout: this.createPointLayout(),
            paint: this.createPointPaint()
        };

        if (this.source.sourceLayer) {
            pointLayer["source-layer"] = this.source.sourceLayer;
        }

        this.map.addLayer(pointLayer);
    }

    protected createLineLayer() {

        const lineLayer: mapboxgl.LineLayerSpecification = {
            id: `${this.id}-linestring`,
            source: this.id,
            type: 'line',
            filter: ["all",
                ["==", ["geometry-type"], "LineString"],
                ["!", ["has", "pc"]]
            ],
            paint: this.createLinePaint(),
            layout: this.createLineLayout()
        };

        if (this.source.sourceLayer) {
            lineLayer["source-layer"] = this.source.sourceLayer;
        }
        this.map.addLayer(lineLayer);
    }

    protected createPolygonLayer() {

        const polygonFillLayer: mapboxgl.FillLayer = {
            id: `${this.id}-polygon`,
            source: this.id,
            type: 'fill',
            filter: ["all",
                ["==", ["geometry-type"], "Polygon"],
                ["!", ["has", "pc"]]
            ],
            paint: this.createPolygonFillPaint(),
            layout: this.createPolygonFillLayout()
        };

        const polygonBorderLayer: mapboxgl.LineLayer = {
            id: `${this.id}-polygon-border`,
            source: this.id,
            type: 'line',
            filter: ["all",
                ["==", ["geometry-type"], "Polygon"],
                ["!", ["has", "pc"]]
            ],
            paint: this.createPolygonBorderPaint(),
            layout: this.createPolygonBorderLayout()
        };

        if (this.source.sourceLayer) {
            polygonFillLayer["source-layer"] = this.source.sourceLayer;
            polygonBorderLayer["source-layer"] = this.source.sourceLayer;
        }

        this.map.addLayer(polygonFillLayer);
        this.map.addLayer(polygonBorderLayer);
    }

    private updateLayerPaint(layerId: string, obj: { [key: string]: any }) {
        for (const [p, v] of Object.entries(obj)) {
            this.map.setPaintProperty(layerId, p as any, v);
        }
    }

    private updateLayerLayout(layerId: string, obj: { [key: string]: any }) {
        for (const [p, v] of Object.entries(obj)) {
            this.map.setLayoutProperty(layerId, p as any, v);
        }
    }

    private updateLayer(layerId: string, paint: { [key: string]: any }, layout: { [key: string]: any }) {
        this.updateLayerPaint(layerId, paint);
        this.updateLayerLayout(layerId, layout);
    }

    update() {
        this.updateLayer(`${this.id}-polygon-border`, this.createPolygonBorderPaint(), this.createPolygonBorderLayout());
        this.updateLayer(`${this.id}-polygon`, this.createPolygonFillPaint(), this.createPolygonFillLayout());
        this.updateLayer(`${this.id}-linestring`, this.createLinePaint(), this.createLineLayout());
        this.updateLayer(`${this.id}-point`, this.createPointPaint(), this.createPointLayout());
        if (!(this instanceof MapGeoJSONLayer)) {
            this.updateLayer(`${this.id}-style`, this.createStylePaint(), this.createStyleLayout());
        }
    }

    remove() {
        if (!this.map) {
            return;
        }

        for (const l of this.layerIds()) {
            safelyRemoveLayer(this.map, l);
        }
        for (const c of this.clusterIds()) {
            safelyRemoveLayer(this.map, c);
        }
        safelyRemoveSource(this.map, this.id);
        safelyRemoveSource(this.map, this.styleSourceId);
        this.map = undefined;
    }

    layerIds(): string[] {
        return [
            `${this.id}-polygon`,
            `${this.id}-polygon-border`,
            `${this.id}-linestring`,
            `${this.id}-point`,
            `${this.id}-style`
        ];
    }

    setData(data: FeatureCollection) {
        (this.sourceImpl as any).setData(data);
    }

    setStyleData(data: FeatureCollection) {
        (this.styleSourceImpl as any).setData(data);
    }

    async addTo(map: mapboxgl.Map) {
        this.map = map;
        const source = await this.source.init();

        map.addSource(this.id, source);
        this.sourceImpl = map.getSource(this.id);

        if (this.styleSource && this.styleSource instanceof MapDataSource) {
            this.styleSourceId = `${this.id}-style`;
            const styleSource = await this.styleSource.init();
            map.addSource(this.styleSourceId, styleSource);
            this.styleSourceImpl = map.getSource(this.styleSourceId);
        }

        this.createPolygonLayer();
        this.createLineLayer();
        this.createPointLayer();
        this.createClusterLayers();

        if (!(this instanceof MapGeoJSONLayer)) {
            this.createStyleLayer();
        }

        for (const l of this.layerIds()) {
            map.on('mousemove', l, event => {
                this.map.fire('coreo.mousemove', event);
            });
            map.on('mouseleave', l, event => {
                this.map.fire('coreo.mouseleave', event);
            });
        }

        for (const f of this.layerIds()) {
            map.on('click', f, event => {
                console.log('Clicked', f, event.features);
                this.map.fire(`coreo.click`, event);
            });
        }

        for (const c of this.clusterIds()) {
            map.on('click', c, event => {
                this.map.fire('coreo.clusterClick', event);
            });
        }

        map.on('styleimagemissing', event => {
            if (event.id && event.id !== 'null' && event.id.startsWith('http')) {
                const url = new URL(event.id);
                const twicUrl = `https://coreo.twic.pics${url.pathname}`;
                if (map.hasImage(event.id)) {
                    return;
                }

                map.loadImage(twicUrl, (error, image) => {
                    if (error) {
                        console.log('ERROR', error);
                    } else {
                        if (!map.hasImage(event.id)) {
                            map.addImage(event.id, image);
                        }
                    }
                });
            }
        });
    }

    clusterIds(): string[] {
        return [
            `${this.id}-clusters`,
            `${this.id}-clusters_count`
        ]
    }
}

export class MapRasterImageLayer extends MapLayer {

    type: MapLayerType = 'custom';
    sourceLayer = null;
    override id: string;

    constructor(private config: MapCustomLayerConfig) {
        super();
        this.id = `coreo-${layerId++}`;

    }


    protected async createSource(): Promise<mapboxgl.ImageSourceSpecification> {
        let coordinates: mapboxgl.ImageSourceSpecification['coordinates'];
        if (this.config.bounds) {
            const [minX, minY, maxX, maxY] = this.config.bounds;
            coordinates = [
                [minX, minY],
                [maxX, minY],
                [maxX, maxY],
                [minX, maxY]
            ];
        } else {
            coordinates = this.config.layout as mapboxgl.ImageSourceSpecification['coordinates'];
        }
        const url = urlToCDN(this.config.source);

        return {
            type: 'image',
            url,
            coordinates
        };
    }


    layerIds(): string[] {
        return [this.id];
    }

    clusterIds(): string[] {
        return [];
    }


    async addTo(map: mapboxgl.Map): Promise<void> {
        this.map = map;
        const source = await this.createSource();
        this.map.addSource(this.id, source);
        this.map.addLayer({
            id: this.id,
            type: 'raster',
            source: this.id,
            layout: {
                'visibility': this.visible ? 'visible' : 'none'
            }
        });
    }

    update(): void {

    }

    remove(): void {
        safelyRemoveLayer(this.map, this.id);
        safelyRemoveSource(this.map, this.id);
    }
}

export class MapGeoJSONLayer extends MapStyleableLayer {

    constructor(override source: MapGeoJSONSource) {
        super(source);
        this.id = `coreo-${layerId++}`;
    }
}

export class MapHeatmapLayer extends MapLayer {

    constructor(private source: MapDataSource, private config: any) {
        super();
    }

    layerIds(): string[] {
        return [this.id];
    }

    clusterIds(): string[] {
        return [];
    }

    async addTo(map: mapboxgl.Map): Promise<void> {
        this.map = map;
        const source = await this.source.init();
        this.map.addSource(this.id, source);
        this.map.addLayer({
            id: this.id,
            type: 'heatmap',
            filter: ["!", ["has", "pc"]],
            layout: {
                'visibility': this.visible ? 'visible' : 'none'
            },
            paint: this.config.paint,
            source: this.id,
            "source-layer": this.source.sourceLayer
        });
    }

    updatePaint(paint: mapboxgl.HeatmapLayerSpecification['paint']) {
        for (const p in paint) {
            this.map.setPaintProperty(this.id, p as keyof mapboxgl.HeatmapLayerSpecification['paint'], paint[p]);
        }
    }

    remove(): void {
        safelyRemoveLayer(this.map, this.id);
        safelyRemoveSource(this.map, this.id);
    }
}

export interface MapData {
    createRecordsSource(config: MapRecordsSourceConfig): MapRecordsSource;
    createCollectionSource(collectionId: number): MapCollectionSource;

    createStyleableLayer(source: MapDataSource): MapStyleableLayer;
    createRasterImageLayer(config: MapCustomLayerConfig): MapRasterImageLayer;
    createHeatmapLayer(source: MapDataSource, config: any): MapHeatmapLayer;
    createGeoJSONLayer(source: MapDataSource, config: any): MapGeoJSONLayer;
    fromState(state: MapLayerState): MapLayer;
    // createLayer(source: MapDataSource, style: CoreoDataLayerStyle): MapStyleableLayer;
}


@Injectable({
    providedIn: 'root'
})
export class MapDataService {

    http = inject(HttpClient);
    $ngRedux = inject<ngRedux.INgRedux>(NG_REDUX);
    mapsUrl = environment.mapsUrl;

    createRasterImageLayer(config: MapCustomLayerConfig): MapRasterImageLayer {
        return new MapRasterImageLayer(config);
    }

    createRecordsSource(config: MapRecordsSourceConfig): MapRecordsSource {
        return new MapRecordsSource(this.mapsUrl, this.http, config);
    }

    createCollectionSource(collectionId: number): MapCollectionSource {
        const projectSlug = getProjectSlug(this.$ngRedux.getState())
        return new MapCollectionSource(this.mapsUrl, projectSlug, collectionId);
    }

    createGeoJSONSource(data: string): MapGeoJSONSource {
        return new MapGeoJSONSource(data);
    }

    createStyleableLayer(source: MapDataSource, styleSource?: MapDataSource): MapStyleableLayer {
        return new MapStyleableLayer(source, styleSource);
    }

    createHeatmapLayer(source: MapDataSource, config: any): MapHeatmapLayer {
        return new MapHeatmapLayer(source, config);
    }

    createGeoJSONLayer(source: MapGeoJSONSource): MapGeoJSONLayer {
        return new MapGeoJSONLayer(source);
    }

    private sourceFromLayer(mapLayer: any): MapDataSource {
        if (mapLayer.sourceType === 'records') {
            const state = this.$ngRedux.getState();
            return new MapRecordsSource(this.mapsUrl, this.http, {
                attributes: ['surveyId'],
                query: {},
                clusterMaxZoom: 0,
                projectId: getProjectId(state),
                styled: !getProjectBespoke(state)
            });
        } else {
            return new MapCollectionSource(this.mapsUrl, getProjectSlug(this.$ngRedux.getState()), mapLayer.sourceId)
        }
    };

    fromState(state: MapLayerState): MapLayer {
        const storeState = this.$ngRedux.getState();
        const projectSlug = getProjectSlug(storeState);

        switch (state.layerType) {
            case 'collection': {
                const collection: CoreoCollection = getProjectCollection(state.id)(storeState);
                const source = new MapCollectionSource(this.mapsUrl, projectSlug, state.id);
                const layer = new MapStyleableLayer(source);
                layer.setStyle(collection.id, collection.style, 1);
                return layer;
            }
            case 'custom': {
                const layer = getProjectMapLayer(state.id)(storeState);

                // Raster Image
                if (layer.sourceType === 'image') {
                    return new MapRasterImageLayer(layer);
                }

                // GeoJSON
                if (layer.sourceType === 'geojson') {
                    const source = this.createGeoJSONSource(layer.source);
                    const mapLayer = new MapGeoJSONLayer(source);
                    mapLayer.setStyle(layer.id, layer.style, 1);
                    return mapLayer;
                }

                const source = this.sourceFromLayer(layer);
                if (layer.type === 'heatmap') {
                    return new MapHeatmapLayer(source, layer);
                }
            }
        }
    }
}


export const defaultDataLayerStyle = (color: string = '#ff0000'): CoreoDataLayerStyle => ({
    color,
    labelFontSize: 16,
    pointRadius: 6,
    pointOpacity: 1,
    pointBorderColor: '#ffffff',
    pointBorderWidth: 1,
    pointBorderOpacity: 1,
    polygonOpacity: 0.3,
    polygonBorderWidth: 1,
    polygonBorderColor: '#ffffff',
    polygonBorderOpacity: 1,
    lineWidth: 1
});

