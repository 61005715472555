<div class="w-full min-h-100">
    @if (settingsForm()) {
        <form [formGroup]="settingsForm()" (ngSubmit)="save()">
            <fieldset class="pb-5 border-b border-grey-30 space-y-4" [disabled]="submitting()">
                <h3 class="text-base font-semibold mb-5">{{ isChildForm() ? 'Child form' : 'Form' }} general settings</h3>
                @if (!isChildForm()) {
                    <div class="w-full flex gap-4">
                        <div class="w-1/2">
                            <label class="flex items-center justify-between w-full text-sm text-text-primary mb-2" for="name">
                                <span>Name <sup>*</sup></span>
                                <i class="fa-solid fa-circle-info text-text-secondary" pTooltip="The name of this form, also used for categorising responses" tooltipPosition="left"></i>
                            </label>
                            <input pInputText type="text" formControlName="name" id="name" class="w-full" />
                            @if (settingsForm().get('name').hasError('required')) {
                                <p class="text-xs text-danger leading-none pt-4">Name is required</p>
                            }
                        </div>
                        <div class="w-1/2">
                            <label class="flex items-center justify-between w-full text-sm text-text-primary mb-2" for="title">
                                <span>Title <sup>*</sup></span>
                                <i class="fa-solid fa-circle-info text-text-secondary" pTooltip="The title of this form, as presented to users when submitting data" tooltipPosition="left"></i>
                            </label>
                            <input pInputText type="text" formControlName="title" id="title" class="w-full" />
                            @if (settingsForm().get('title').hasError('required')) {
                                <p class="text-xs text-danger leading-none pt-4">Title is required</p>
                            }
                        </div>
                    </div>
                }
                <div class="flex w-full gap-4">
                    <div class="w-1/2">
                        <label class="w-full flex items-center justify-between text-sm text-text-primary mb-2" for="titleAttributeId">
                            Record overview title Question
                            <i class="fa-solid fa-circle-info text-text-secondary" pTooltip="When looking at record overviews, the answer to this question will be used as the title" tooltipPosition="left"></i>
                        </label>
                        <p-dropdown [options]="titleAttributeOptions()" optionLabel="label" optionValue="id" formControlName="titleAttributeId" id="titleAttributeId" [showClear]="false" (onChange)="titleAttributeId.set($event.value)" class="w-full"></p-dropdown>
                    </div>
                    <div class="w-1/2">
                        <label class="w-full flex items-center justify-between text-sm text-text-primary mb-2" for="secondaryTitleAttributeId">
                            Record overview description Question
                            <i class="fa-solid fa-circle-info text-text-secondary" pTooltip="When looking at record overviews, the answer to this question will be used as the description" tooltipPosition="left"></i>
                        </label>
                        <p-dropdown [options]="secondaryTitleAttributeOptions()" optionLabel="label" optionValue="id" formControlName="secondaryTitleAttributeId" id="secondaryTitleAttributeId" [showClear]="false" (onChange)="secondaryTitleAttributeId.set($event.value)" class="w-full"></p-dropdown>
                    </div>
                </div>
                <div class="w-full">
                    <label class="w-full flex items-center justify-between text-sm text-text-primary mb-2" for="thankyou">
                        Thank you message when the form is completed
                        <i class="fa-solid fa-circle-info text-text-secondary" pTooltip="Optional message to display to users on submission of a record" tooltipPosition="left"></i>
                    </label>
                    <textarea 
                        pInputTextarea 
                        [autoResize]="true"
                        [rows]="4"
                        formControlName="thankyou"
                        id="thankyou"
                        placeholder="Thank you for completing the form"
                        class="w-full">
                    </textarea>
                </div>
            </fieldset>
            <fieldset class="py-5 space-y-4" [disabled]="submitting()">
                <h3 class="text-base font-semibold mb-5">{{ isChildForm() ? 'Child form' : 'Form' }} advanced settings</h3>
                <div class="flex items-center gap-2">
                    <p-inputSwitch id="allowMemberUpdate" formControlName="allowMemberUpdate" />
                    <label class="text-sm text-text-primary flex items-center gap-3" for="allowMemberUpdate">
                        Allow member updates
                        <i class="fa-solid fa-circle-info text-text-secondary" pTooltip="Project members may update each other's records, if available in your app" tooltipPosition="right"></i>
                    </label>
                </div>
                <div class="flex items-center gap-2">
                    <p-inputSwitch id="allowOwnRecordDelete" formControlName="allowOwnRecordDelete" />
                    <label class="text-sm text-text-primary flex items-center gap-3" for="allowOwnRecordDelete">
                        Allow members to delete own records
                        <i class="fa-solid fa-circle-info text-text-secondary" pTooltip="Project members may delete their own records" tooltipPosition="right"></i>
                    </label>
                </div>
                <div class="flex items-center gap-2">
                    <p-inputSwitch id="private" formControlName="private" />
                    <label class="text-sm text-text-primary flex items-center gap-3" for="private">
                        Keep form private
                        <i class="fa-solid fa-circle-info text-text-secondary" pTooltip="If a form is private, only a user's own records will be visible for that form inside the app. All records can still be viewed by moderators and admins in the admin area" tooltipPosition="right"></i>
                    </label>
                </div>
                @if (isChildForm()) {
                    <div class="flex items-center gap-2">
                        <p-inputSwitch id="childVisible" formControlName="childVisible" />
                        <label class="text-sm text-text-primary flex items-center gap-3" for="childVisible">
                            Use as parent form
                            <!-- <i class="fa-solid fa-circle-info text-text-secondary" pTooltip="" tooltipPosition="right"></i> -->
                        </label>
                    </div>
                }
                @else {
                    <div class="flex items-center gap-2">
                        <p-inputSwitch id="hidden" formControlName="hidden" />
                        <label class="text-sm text-text-primary flex items-center gap-3" for="hidden">
                            Hide form
                            <!-- <i class="fa-solid fa-circle-info text-text-secondary" pTooltip="" tooltipPosition="right"></i> -->
                        </label>
                    </div>
                }
            </fieldset>
            <div class="flex justify-end">
                <p-button size="large" type="submit" label="Save settings" [disabled]="!settingsForm().valid || !settingsForm().dirty" [loading]="submitting()"></p-button>
            </div>
        </form>
    }
</div>