import bbox from "@turf/bbox";
import { FeatureCollection } from "@turf/helpers";
import transformTranslate from '@turf/transform-translate';
import angular, { IQService } from "angular";
import { Feature, Geometry, LineString, Point, Polygon } from "geojson";
import mapboxgl, { LngLatBoundsLike, Map } from 'mapbox-gl';
import ngRedux from 'ng-redux';
import CoreoAPIService from "../../main/coreoApi";
// import { defaultDataLayerStyle, MapData, MapDataSource, MapGeoJSONSource, MapHeatmapLayer, MapLayer, MapStyleableLayer } from "../../main/mapData.service";
import ModalService from '../../main/modal.service';
import { getProjectAttributesForForm, getProjectBoundingBox, getProjectCollections, getProjectForm, getProjectFormsContainingGeometry, getProjectId, getProjectMapLayers, getProjectParent } from "../../store/selectors";
import { CoreoCollection, CoreoForm } from "../../types";
import ProjectService from '../project.service';
import ProjectMapEditorNewLayerController from "./project-map-editor-new-layer.controller";
import { defaultDataLayerStyle, MapDataService, MapDataSource, MapGeoJSONLayer, MapGeoJSONSource, MapHeatmapLayer, MapLayer, MapStyleableLayer } from "src/app/core/services/mapData.service";
import { MAP_MENU_LAYERS_CHANGED_EVENT, MAP_MENU_STYLE_CHANGED_EVENT } from "src/app/core/services/maps.service";

interface ProjectMapEditorSourceType {
    id: string;
    label: string;
    styleable?: boolean;
    hasUrl?: boolean;
    information?: string;
}


interface MapEditorLayerStateBase {
    id: number;
    name: string;
    type: string;
    dirty: boolean;
    visible: boolean;
    style: any;
    sort: number;
    enabled: boolean;
}

interface MapEditorStyleableLayerState extends MapEditorLayerStateBase {
    layerType: 'data'
    type: 'records' | 'collection';
    mapLayer: MapStyleableLayer;
    layerLabel: string;
}

interface MapEditorCustomLayerState extends MapEditorLayerStateBase {
    layerType: 'raster' | 'heatmap' | 'geojson';
    layerLabel: string;
    type: string;
    sourceType: 'image' | 'collections' | 'records';
    sourceId: number;
    paint: any;
    layout: any;
    source: string;
    mapLayer: MapLayer;
    bounds: number[];
}

type MapLayerState = MapEditorStyleableLayerState | MapEditorCustomLayerState;

export default class ProjectMapEditorController {

    sortConfig = {
        axis: 'y',
        containment: 'parent',
        tolerance: 'pointer'
    };

    projectId: number;

    layerId: string = 'layer-id';
    sourceId: string = 'source-id';
    layerTypes: any[];
    sourceTypes: ProjectMapEditorSourceType[];

    layer: MapLayerState;

    sourceType: ProjectMapEditorSourceType;
    // layers: MapLayerState[] = [];
    dataSourceUrl: string;
    dirty: boolean = false;
    projectBoundingBox: LngLatBoundsLike;

    formLayers: MapEditorStyleableLayerState[] = [];
    otherLayers: MapLayerState[] = [];
    mapRecordsLayer: MapStyleableLayer;

    canToggleVisibility: boolean = false;
    canDelete: boolean = false;
    parent: any;

    showTestData: boolean = false;

    deleteProjectMapLayer: (id: number) => void;

    geometryTypes: string[] = [];
    private map: Map;

    static $inject = ['$scope', '$compile', '$ngRedux', '$q', 'Mapbox', 'MapboxStyle', 'CoreoAPI', 'ProjectActions', 'mapData', '$mdDialog', 'ModalService', 'ProjectService'];
    constructor(private $scope,
        private $compile,
        private $ngRedux: ngRedux.INgRedux,
        private $q: IQService,
        private Mapbox,
        MapboxStyle,
        private CoreoAPI: CoreoAPIService,
        private ProjectActions,
        private MapData: MapDataService,
        private $mdDialog: angular.material.IDialogService,
        private ModalService: ModalService,
        private ProjectService: ProjectService
    ) {

        const state = $ngRedux.getState();
        this.$mdDialog = $mdDialog;
        this.projectId = getProjectId(state);
        const forms: CoreoForm[] = getProjectFormsContainingGeometry(state).filter(f => !f.readonly);
        const collections: CoreoCollection[] = getProjectCollections(state).filter(c => c.geometric && !c.readonly);
        this.projectBoundingBox = getProjectBoundingBox(state) as any;
        const customLayers = getProjectMapLayers(state).filter(f => !f.readonly);
        this.parent = getProjectParent(state);

        this.$ngRedux.connect(null, { deleteProjectMapLayer: ProjectActions.deleteProjectMapLayer })(this);

        for (const form of forms) {
            const formLayer: MapEditorStyleableLayerState = {
                id: form.id,
                name: form.name,
                layerType: 'data',
                type: 'records',
                visible: form.mapVisible,
                sort: form.mapSort,
                style: form.style ? angular.copy(form.style) : defaultDataLayerStyle(),
                dirty: false,
                mapLayer: null,
                layerLabel: null,
                enabled: true
            }
            this.formLayers.push(formLayer);
        }

        for (const collection of collections) {
            const collectionLayer: MapEditorStyleableLayerState = {
                id: collection.id,
                name: collection.name,
                layerType: 'data',
                type: 'collection',
                mapLayer: null,
                sort: collection.mapSort,
                visible: collection.mapVisible,
                style: collection.style ? angular.copy(collection.style) : defaultDataLayerStyle('#ffffff'),
                dirty: false,
                layerLabel: 'Collection',
                enabled: collection.mapLayer ?? true
            }
            this.otherLayers.push(collectionLayer);
        }

        const supportedCustomLayers = customLayers.filter(c => c.sourceType === 'image' || c.sourceType === 'geojson' || c.type !== null);

        for (const mapLayer of supportedCustomLayers) {
            const layerType = mapLayer.sourceType === 'image' ? 'raster' : (mapLayer.sourceType === 'geojson' ? 'geojson' : 'heatmap');
            const layerLabel = mapLayer.sourceType === 'image' ? 'Image' : (mapLayer.sourceType === 'geojson' ? 'GeoJSON' : 'Heatmap');

            const customLayer: MapEditorCustomLayerState = {
                id: mapLayer.id,
                name: mapLayer.name,
                sort: mapLayer.sort,
                type: mapLayer.type,
                visible: mapLayer.visible,
                dirty: false,
                mapLayer: null,
                sourceType: mapLayer.sourceType,
                source: mapLayer.source,
                sourceId: mapLayer.sourceId,
                layout: angular.copy(mapLayer.layout),
                paint: angular.copy(mapLayer.paint),
                style: angular.copy(mapLayer.style),
                layerType: layerType,
                layerLabel,
                enabled: true,
                bounds: mapLayer.bounds
            }
            this.otherLayers.push(customLayer);
        }

        this.formLayers.sort((a, b) => a.sort - b.sort);
        this.otherLayers.sort((a, b) => a.sort - b.sort);
        console.log('this.formLayers', this.formLayers);
        console.log('this.otherLayers', this.otherLayers);
        this.formLayers.reverse();
        this.otherLayers.reverse();

        this.layerTypes = MapboxStyle.getLayerTypes();
        if (this.formLayers.length > 0) {
            this.selectLayer(this.formLayers[0]);
        }

        this.sourceTypes = [{
            id: 'records',
            label: 'Coreo Records',
            styleable: true
        }, {
            id: 'collections',
            label: 'Coreo Collections',
            styleable: true
        },
        {
            id: 'image',
            label: 'Raster Image',
            hasUrl: true,
            information: 'GeoTIFF must be in EPSG:4326 (WGS 84) projection. Image data will be automatically resized to a maximum width and/or height of 4096 pixels.'
        }
        ];
    }

    async $onInit() {
        const mapboxgl = await this.Mapbox.get();

        const mapOptions: mapboxgl.MapboxOptions = {
            container: 'project-maps-layer-map',
            style: 'mapbox://styles/mapbox/streets-v12',
            renderWorldCopies: false,
            fadeDuration: 0
        };
        if (this.projectBoundingBox) {
            mapOptions.bounds = this.projectBoundingBox;
            mapOptions.fitBoundsOptions = {
                padding: 40,
                maxZoom: 12
            };
        }
        this.map = new mapboxgl.Map(mapOptions);
        const scope = this.$scope.$new(false);
        scope.map = this.map;
        const mapEl = angular.element(this.$compile('<app-map-menu-control [map]="map" [show-layers]="false"></app-map-menu-control>')(scope));
        mapEl[0].classList.add('bottom-right');

        this.map.addControl({
            onAdd: () => mapEl[0],
            onRemove: () => mapEl.remove()
        }, 'bottom-right');

        this.map.on('load', () => {
            this.renderLayers();
            this.map.on(MAP_MENU_STYLE_CHANGED_EVENT, () => this.renderLayers());
        });
    }

    addLayer(ev) {

        this.$mdDialog.show({
            controller: ProjectMapEditorNewLayerController,
            controllerAs: 'ctrl',
            parent: angular.element(document.body),
            targetEvent: ev,
            clickOutsideToClose: false,
            template: require('!raw-loader!./project-map-editor-new-layer.html').default
        }).then(({ config, name }: { config: { layerType, sourceId, sourceType, source, layout, paint, bounds }, name: string }) => {
            const layerLabel = config.sourceType === 'image' ? 'Image' : 'Heatmap';

            const newLayer: MapEditorCustomLayerState = {
                id: new Date().valueOf() * -1,
                dirty: true,
                visible: true,
                type: config.layerType === 'heatmap' ? 'heatmap' : null,
                name,
                paint: config.paint ?? {},
                layout: config.layout ?? {},
                layerType: config.layerType,
                layerLabel,
                sourceType: config.sourceType,
                sourceId: config.sourceId,
                mapLayer: null,
                style: null,
                sort: this.otherLayers.length + 1,
                source: config.source,
                enabled: true,
                bounds: config.bounds ?? null
            };

            this.saveCustomLayer(newLayer).then((result) => {
                newLayer.id = result;
                this.otherLayers.unshift(newLayer);
                this.selectLayer(newLayer);
                this.renderOtherLayers();
                this.mapRecordsLayer.move();
            });
        }, angular.noop);
    }

    deleteLayer() {
        if (!this.layer) {
            return;
        }

        this.ModalService.confirm({
            title: 'Delete Map Layer?',
            text: 'Are you sure you want to delete this map layer?'
        }).then(async result => {
            if (!result) {
                return;
            }
            const idx = this.otherLayers.indexOf(this.layer as MapEditorCustomLayerState);

            this.deleteProjectMapLayer(this.otherLayers[idx].id);

            this.otherLayers[idx].mapLayer?.remove();

            this.otherLayers = [
                ...this.otherLayers.slice(0, idx),
                ...this.otherLayers.slice(idx + 1)
            ];
            this.updateOtherLayerOrder(this.otherLayers);
            if (this.otherLayers.length > 0) {
                this.selectLayer(this.otherLayers[this.otherLayers.length - 1])
            } else if (this.formLayers.length > 0) {
                this.selectLayer(this.formLayers[this.formLayers.length - 1]);
            } else {
                this.layer = null;
            }
        });
    }

    selectLayer(layer: MapLayerState) {
        this.layer = layer;
        this.canToggleVisibility = !(this.layer.layerType === 'data' && this.layer.type === 'records');
        this.canDelete = this.layer.layerType !== 'data';

        if (this.layer.layerType === 'data') {
            if (this.layer.type === 'collection') {
                this.geometryTypes = ['point', 'linestring', 'polygon'];
            } else {
                // Find the geometry attribute
                const attribute = getProjectAttributesForForm(this.layer.id)(this.$ngRedux.getState()).find(a => a.questionType === 'geometry');
                this.geometryTypes = attribute?.config?.types ?? ['point', 'linestring', 'polygon'];
            }
        }
    }

    mapFitBounds(bounds: LngLatBoundsLike) {
        this.map.fitBounds(bounds, {
            padding: 40
        });
    }

    zoomToRecordsLayer(id: number) {
        this.ProjectService.getRecordsBoundingBox(this.projectId, {
            surveyId: id
        }).then(result => this.mapFitBounds(result));
    }

    zoomToCollectionLayer(id: number) {
        this.ProjectService.getCollectionBoundingBox(this.projectId, id).then(result => this.mapFitBounds(result));
    }

    zoomToLayer() {
        const { id, type, layerType } = this.layer;
        if (layerType === 'data') {
            if (type === 'records') {
                this.zoomToRecordsLayer(id);
            } else {
                this.zoomToCollectionLayer(id);
            }
        } else if (layerType === 'raster') {
            let bounds: LngLatBoundsLike;
            if (this.layer.bounds) {
                const [minx, miny, maxx, maxy] = this.layer.bounds;
                bounds = [[minx, miny], [maxx, maxy]];
            } else {
                bounds = [
                    this.layer.layout[0][0],
                    this.layer.layout[2][1],
                    this.layer.layout[2][0],
                    this.layer.layout[0][1],
                ];
            }

            this.map.fitBounds(bounds as LngLatBoundsLike, {
                padding: 40,
                duration: 0,
                animate: false
            });
        } else if (layerType === 'heatmap') {
            if (this.layer.sourceType === 'records') {
                this.zoomToRecordsLayer(this.layer.sourceId);
            } else {
                this.zoomToCollectionLayer(this.layer.sourceId);
            }
        } else if (layerType === 'geojson') {
            this.map.fitBounds(this.layer.bounds as LngLatBoundsLike, {
                padding: 40,
                duration: 0,
                animate: false
            });
        }
    }

    layerTracker(layer: MapLayerState) {
        return `${layer.id}`;
    }

    toggleLayerVisible(event: Event, layer: MapLayerState) {
        event.preventDefault();
        event.stopPropagation();
        layer.visible = !layer.visible;
        if (layer.visible) {
            layer.mapLayer.show();
        } else {
            layer.mapLayer.hide();
        }
        layer.dirty = true;
        this.dirty = true;
    }

    private polygonFromPoint(point: Point): Polygon {

        const size = 0.1;
        const a = point;
        const b = transformTranslate(point, size, 90);
        const c = transformTranslate(b, size, 180);
        const d = transformTranslate(c, size, 270);

        return {
            type: 'Polygon',
            coordinates: [[a.coordinates, b.coordinates, c.coordinates, d.coordinates, a.coordinates]]
        };
    }

    private lineFromPoint(point: Point): LineString {

        const size = 0.3;
        const a = point;
        const b = transformTranslate(point, size, 90);

        return {
            type: 'LineString',
            coordinates: [a.coordinates, b.coordinates]
        };
    }

    private generateTestData(): FeatureCollection<Geometry> {
        const features: Feature<Geometry>[] = [];
        const center = this.map.getCenter();
        let origin: Point = {
            type: 'Point',
            coordinates: [center.lng, center.lat]
        };

        for (let i = 0; i < this.formLayers.length; i++) {
            const forigin = transformTranslate<Point>(origin, i + 1, 90);

            // Point
            features.push({
                type: 'Feature',
                properties: {
                    surveyId: this.formLayers[i].id,
                    name: this.formLayers[i].name
                },
                geometry: forigin
            });

            // LineString
            features.push({
                type: 'Feature',
                properties: {
                    surveyId: this.formLayers[i].id
                },
                geometry: this.polygonFromPoint(transformTranslate(forigin, 0.2, 180))
            });

            // Polygon
            features.push({
                type: 'Feature',
                properties: {
                    surveyId: this.formLayers[i].id
                },
                geometry: this.lineFromPoint(transformTranslate(forigin, 0.4, 180))
            });
        }
        return {
            type: 'FeatureCollection',
            features
        };
    }

    private generateRecordsSource(): MapDataSource {
        if (this.showTestData) {
            const otherSource = new MapGeoJSONSource(this.generateTestData());
            return otherSource;
        }
        return this.MapData.createRecordsSource({
            projectId: this.projectId,
            query: {

            },
        });
    }
    private async renderRecordLayers() {
        const source = this.generateRecordsSource();
        this.mapRecordsLayer = this.MapData.createStyleableLayer(source);

        for (const dataLayer of this.formLayers.filter(t => t.type === 'records')) {
            const survey: CoreoForm = getProjectForm(dataLayer.id)(this.$ngRedux.getState());
            this.mapRecordsLayer.setStyle(survey.id, {
                ...survey.style
            }, survey.mapSort, survey.color);
        }
        await this.mapRecordsLayer.addTo(this.map);

        if (this.showTestData) {
            this.map.addLayer({
                id: 'labels',
                source: this.mapRecordsLayer.id,
                type: 'symbol',
                layout: {
                    'text-field': ['get', 'name'],
                    'text-offset': [0, -2],
                    'text-allow-overlap': true,
                    'text-ignore-placement': true
                }
            });
            const box = bbox((source as MapGeoJSONSource).data);
            this.map.fitBounds(box as mapboxgl.LngLatBoundsLike, {
                duration: 0,
                padding: 20
            });
        }
    }

    private async renderOtherLayers() {
        const othersEnabled = this.otherLayers.filter(a => a.enabled);
        const othersDisabled = this.otherLayers.filter(a => !a.enabled);

        for (const layer of othersDisabled) {
            if (layer.mapLayer) {
                layer.mapLayer.remove();
            }
        }

        for (let i = othersEnabled.length - 1; i >= 0; i--) {
            const layer = othersEnabled[i];
            layer.mapLayer?.remove();
            layer.mapLayer = undefined;

            if (layer.layerType === 'data' && layer.type === 'collection') {
                const source = this.MapData.createCollectionSource(layer.id);
                layer.mapLayer = this.MapData.createStyleableLayer(source);
                layer.mapLayer.setStyle(layer.id, layer.style, layer.sort);
            } else if (layer.layerType === 'raster') {
                layer.mapLayer = this.MapData.createRasterImageLayer({
                    source: layer.source,
                    layerType: null,
                    sourceId: null,
                    sourceType: 'image',
                    type: null,
                    layout: angular.copy(layer.layout),
                    paint: angular.copy(layer.paint),
                    bounds: layer.bounds
                });
            } else if (layer.layerType === 'heatmap') {
                const source = layer.sourceType === 'records' ? this.MapData.createRecordsSource({
                    projectId: this.projectId,
                    query: {},
                    clusterMaxZoom: 0
                }) : this.MapData.createCollectionSource(layer.sourceId);
                layer.mapLayer = this.MapData.createHeatmapLayer(source, layer);
            } else if (layer.layerType === 'geojson') {
                const source = this.MapData.createGeoJSONSource(layer.source);
                layer.mapLayer = this.MapData.createGeoJSONLayer(source);
                (layer.mapLayer as MapGeoJSONLayer).setStyle(layer.id, layer.style, layer.sort);
            }
            await layer.mapLayer?.addTo(this.map);
        }
    }

    async renderLayers() {
        this.renderOtherLayers();
        this.renderRecordLayers();
    }

    getSourceType(sourceType: string) {
        return this.sourceTypes.find(s => s.id === sourceType);
    }

    updateLayer() {
        this.layer.dirty = true;
        this.dirty = true;
    }

    updateLayerVisibility() {
        this.updateLayer();
        this.renderOtherLayers();
    }

    updateHeatmap(paint) {
        (this.layer as MapEditorCustomLayerState).paint = paint;
        (this.layer.mapLayer as MapHeatmapLayer).updatePaint(paint);
        this.layer.dirty = this.dirty = true;
    }

    updateDataLayer() {
        const layer = this.layer as MapEditorStyleableLayerState;
        layer.dirty = true;
        const mapLayer = layer.type === 'records' ? this.mapRecordsLayer : layer.mapLayer;
        mapLayer.setStyle(layer.id, {
            ...layer.style
        }, layer.sort);
        mapLayer.update();
        this.dirty = true;
    }

    updateDataLayerOrder(dataLayers: MapEditorStyleableLayerState[]) {
        let j = 1;
        for (let i = dataLayers.length - 1; i >= 0; i--) {
            dataLayers[i].sort = j++;
            dataLayers[i].dirty = true;
            this.mapRecordsLayer.setOrder(dataLayers[i].id, dataLayers[i].sort);
            this.mapRecordsLayer.update();
        }
        this.dirty = true;
    }

    updateOtherLayerOrder(otherLayers: MapLayerState[]) {
        let j = 1;
        for (let i = otherLayers.length - 1; i >= 0; i--) {
            otherLayers[i].sort = j++;
            otherLayers[i].dirty = true;
            otherLayers[i].mapLayer.move();
        }
        // Put the records layer back on top;
        this.mapRecordsLayer.move();
        this.dirty = true;
    }

    private saveRecordsDataLayer(layer: MapEditorStyleableLayerState) {
        return this.$ngRedux.dispatch(this.ProjectActions.updateForm({
            id: layer.id,
            color: layer.style.color,
            mapSort: layer.sort,
            mapVisible: layer.visible,
            style: {
                ...layer.style
            }
        }));
    }

    private saveCollectionsDataLayer(layer: MapEditorStyleableLayerState) {
        return this.$ngRedux.dispatch(this.ProjectActions.updateCollectionSettings({
            id: layer.id,
            mapSort: layer.sort,
            mapLayer: layer.enabled,
            mapVisible: layer.visible,
            style: {
                ...layer.style
            }
        }));
    }

    private saveCustomLayer(layer: MapEditorCustomLayerState) {
        const mutationName = layer.id < 0 ? 'AAMapEditorCreateCustomLayer' : 'AAMapEditorUpdateCustomLayer';
        const mutationInput = layer.id < 0 ? 'ProjectMapLayerCreateInput' : 'ProjectMapLayerUpdateInput';
        const mutationOperation = layer.id < 0 ? 'createProjectMapLayer' : 'updateProjectMapLayer';

        const mutation = `mutation ${mutationName}($input: ${mutationInput}!){
            result: ${mutationOperation}(input: $input){
                id
            }
        }`;

        const input: any = {
            name: layer.name,
            sourceType: layer.sourceType,
            sourceId: layer.sourceId,
            sort: layer.sort,
            paint: JSON.stringify(layer.paint || {}),
            layout: JSON.stringify(layer.layout || {}),
            type: layer.type,
            source: layer.source,
            projectId: this.projectId,
            visible: layer.visible,
            bounds: layer.bounds,
            style: {
                color: '#000'
            }
        };
        if (layer.id > 0) {
            input.id = layer.id;
        }

        return this.CoreoAPI.gql(mutation, {
            input: input
        }).then(response => {
            return response.result.id;
        });
    }

    saveMap() {
        const promises = [];

        // Save Data Layers
        for (const layer of this.formLayers.filter(d => d.dirty)) {
            promises.push(this.saveRecordsDataLayer(layer).then(() => layer.dirty = false));
        }

        for (const layer of this.otherLayers.filter(d => d.dirty)) {
            if (layer.layerType === 'data' && layer.type === 'collection') {
                promises.push(this.saveCollectionsDataLayer(layer as MapEditorStyleableLayerState).then(() => layer.dirty = false));
            } else if (layer.layerType === 'heatmap' || layer.layerType === 'raster') {
                promises.push(this.saveCustomLayer(layer).then(() => layer.dirty = false));
            }
        }

        this.$q.all(promises).then(() => {
            this.dirty = false;
        });
    }

    toggleData() {
        this.showTestData = !this.showTestData;
        if (!this.showTestData) {
            this.map.removeLayer('labels');
        }
        this.mapRecordsLayer.remove();
        this.renderRecordLayers();
    }

}