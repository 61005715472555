import { Component, Input, Output, EventEmitter, signal, inject, computed } from "@angular/core";
import { DialogModule } from "primeng/dialog";
import { CheckboxModule } from "primeng/checkbox";
import { FormGroup, FormControl, FormsModule, ReactiveFormsModule } from "@angular/forms";
import { ButtonModule } from "primeng/button";
import { OrganisationMembershipItem, PendingOrganisationMembershipItem } from "@core/services/subscription.service";
import { ConfirmationService } from "primeng/api";
import { ConfirmDialogModule } from "primeng/confirmdialog";
import { InputTextModule } from "primeng/inputtext";
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { TabViewModule } from 'primeng/tabview';

@Component({
    selector: "app-remove-seats-filled",
    templateUrl: "./remove-seats-filled.component.html",
    standalone: true,
    imports: [
        DialogModule,
        ButtonModule,
        CheckboxModule,
        FormsModule,
        InputTextModule,
        ReactiveFormsModule,
        ConfirmDialogModule,
        TabViewModule
    ],
    providers: [ConfirmationService]
})
export class RemoveSeatsFilledComponent {

    @Input() userId: number;
    @Input() seatsFilled: number;
    @Input() seatsSelected: number;
    @Input() memberships: OrganisationMembershipItem[] = [];
    @Input() pendingMemberships: PendingOrganisationMembershipItem[] = [];

    @Input() isVisibleRemoveSeatsDialog: boolean = false;
    @Output() visibleRemoveSeatsChange = new EventEmitter<{ isVisibleRemoveSeatsDialog: boolean, userIds: number[], isBack: boolean }>();
    @Output() cancelPendingInvitation = new EventEmitter<{ pendingEmail: string}>();

    confirmationService = inject(ConfirmationService);
    activeIndex = signal<0 | 1>(0);
    searchForm = new FormGroup({ search: new FormControl<string>('') });
    filteredMemberships = signal<OrganisationMembershipItem[]|[]>([]);
    filteredPendingMemberships = signal<PendingOrganisationMembershipItem[] | []>([]);

    sortColumn = signal<{ field: string, type: 'asc' | 'desc' }>({ field: 'name', type: 'asc' });
    pendingSortColumn = signal<{ field: string, type: 'asc' | 'desc' }>({ field: 'pendingEmail', type: 'asc' });

    isSelectAll = computed<boolean | null>(() => {
        const relevantMemberships = this.filteredMemberships().filter((member: OrganisationMembershipItem) => member.userId !== this.userId);
        const selectedStatuses = new Set(relevantMemberships.map(member => member.selected));
        return selectedStatuses.size === 1 ? selectedStatuses.values().next().value : null;
    });

    selectedCount = computed<number>(() => this.filteredMemberships().filter((m: OrganisationMembershipItem) => m.selected).length);

    allowRemove = computed<boolean>(() => {
        const seatsDiff = this.seatsFilled - this.seatsSelected;
        return seatsDiff && this.selectedCount() >= seatsDiff;
    });

    constructor() {
        this.searchForm.valueChanges.pipe(takeUntilDestroyed()).subscribe(({search}) => {
            const filtered = this.memberships.filter((member: OrganisationMembershipItem) =>
                member.name.toLowerCase().includes(search.toLowerCase()) ||
                member.email.toLowerCase().includes(search.toLowerCase()) ||
                member.role.toLowerCase().includes(search.toLowerCase())
            );
            this.filteredMemberships.set(filtered);
            const filteredPending = this.pendingMemberships.filter((member: PendingOrganisationMembershipItem) =>
                member.pendingEmail.toLowerCase().includes(search.toLowerCase()) ||
                member.role.toLowerCase().includes(search.toLowerCase())
            );
            this.filteredPendingMemberships.set(filteredPending);
        });
    }

    ngOnChanges() {
        this.filteredMemberships.set([...this.memberships]);
        this.filteredPendingMemberships.set([...this.pendingMemberships]);

    }

    selectAll() {
        const isSelectedAll = this.isSelectAll() !== true;
        this.filteredMemberships.set(this.filteredMemberships().map(member => ({
            ...member,
            selected: member.userId === this.userId ? member.selected : isSelectedAll
        })));
    }

    sort(field: string) {
        const currentSort = this.sortColumn();
        if (currentSort.field === field) {
            this.sortColumn.set({ field, type: currentSort.type === 'asc' ? 'desc' : 'asc' });
        } else {
            this.sortColumn.set({ field, type: 'asc' });
        }
        this.filteredMemberships.set(this.memberships.sort((a, b) => this.sortColumn().type === 'asc'
            ? (a[field] as string).localeCompare(b[field] as string)
            : (b[field] as string).localeCompare(a[field] as string)
        ));
    }

    sortPending(field: string) {
        const currentSort = this.pendingSortColumn();
        if (currentSort.field === field) {
            this.pendingSortColumn.set({ field, type: currentSort.type === 'asc' ? 'desc' : 'asc' });
        } else {
            this.pendingSortColumn.set({ field, type: 'asc' });
        }
        this.filteredPendingMemberships.set(this.pendingMemberships.sort((a, b) => this.pendingSortColumn().type === 'asc'
            ? (a[field] as string).localeCompare(b[field] as string)
            : (b[field] as string).localeCompare(a[field] as string)
        ));
    }

    selectMember(member: OrganisationMembershipItem) {
        const index = this.filteredMemberships().findIndex((a: OrganisationMembershipItem) => a.userId === member.userId);
        const members = [...this.filteredMemberships()];
        members[index] = { ...member, selected: !member.selected };
        this.filteredMemberships.set(members);
    }

    confirmRemove() {
        const userIds = this.filteredMemberships().filter((m: OrganisationMembershipItem) => m.selected).map(m => m.userId);
        if (!userIds.length) return this.onHideDialog();

        this.confirmationService.confirm({
            message: "Remove selected members?",
            header: "Confirm remove members",
            acceptIcon: "pi pi-check mr-2",
            rejectIcon: "pi pi-times mr-2",
            rejectButtonStyleClass: "p-button",
            acceptButtonStyleClass: "p-button-outlined p-button",
            accept: () => this.onHideDialog(userIds)
        });
    }

    confirmCancel(member: PendingOrganisationMembershipItem) {
        this.confirmationService.confirm({
            message: "Are you sure you want to cancel this users invitation?",
            header: "Cancel Invitation",
            acceptIcon: "pi pi-check mr-2",
            rejectIcon: "pi pi-times mr-2",
            rejectButtonStyleClass: "p-button",
            acceptButtonStyleClass: "p-button-outlined p-button",
            accept: () => {
                this.cancelPendingInvitation.emit({ pendingEmail: member.pendingEmail });
                const remainingToRemove = this.seatsFilled - this.seatsSelected;
                if (remainingToRemove <= 1) {
                    this.onHideDialog([], true);
                }
            }
        });
    }

    onHideDialog(userIds: number[] = [], isBack = false) {
        this.activeIndex.set(0);
        this.isVisibleRemoveSeatsDialog = false;
        this.visibleRemoveSeatsChange.emit({ isVisibleRemoveSeatsDialog: false, userIds, isBack });
    }
}