import { computed, inject, Injectable, signal } from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { featureCollection } from "@turf/helpers";
import { randomLineString, randomPoint } from "@turf/random";
import { Feature, FeatureCollection, Geometry, MultiPolygon } from "geojson";
import mapboxgl, { LngLatBounds } from "mapbox-gl";
import { distinctUntilChanged, forkJoin, Subject, takeUntil } from "rxjs";
import { ApiService } from "src/app/core";
import { defaultDataLayerStyle } from "src/app/core/services/mapData.service";
import { environment } from "src/environments/environment";
import { MapBuilderAttributeResponse, MapBuilderCollectionsItemResponse, MapBuilderCollectionsResponse, MapBuilderForm, MapBuilderInitResponse, MapBuilderItem, MapBuilderLayer, MapBuilderLayerResponse, MapBuilderPointStyleType, MapBuilderStyleType } from "./map-builder.types";
import { generateNonOverlappingPolygons } from "./map-builder.utils";
import center from "@turf/center";
import along from "@turf/along";
import length from '@turf/length';
import { flatten } from '@turf/flatten';
import { ReduxService } from "@core/services/redux.service";
import { getProjectOrgFeatures } from "src/store/selectors";

interface FeatureProperties {
    surveyId: number;
    __label?: string;
    __color?: string;
    __icon?: string;
}

@Injectable()
export class MapBuilderService {
    api = inject(ApiService);
    redux = inject(ReduxService);

    private readonly initQuery = `query AAMapBuilder($projectId: Int!) {
        project(id: $projectId){
            geometry{
                type
                coordinates
            }
            parent {
                name
            }
            attributes {
                id
                label
                path
                type
                questionType
                config
                surveyId
                collectionId
                parentCollectionId
            }
            forms: surveys {
                id
                name
                style
                mapSort
                iconSourceId
                iconSourceType
                labelAttributeId
                labelCollectionAttributeId
                styleAttributeId
            }
            layers: mapLayers {
                id
                name
                sourceType
                sourceId
                sort
                type
                layout
                paint
                style
                source
                visible
                bounds
            }
            collections: collections {
                id
                name
                type
                style
                mapSort
                mapLayer
                geometric
                items(limit: 100){
                    key
                    value
                    icon
                    color
                    data
                }
            }
        }
    }`;

    private readonly updateFormQuery = `mutation AAMapBuilderUpdateForm($input: SurveyUpdateInput!){
        updateSurvey(input: $input){
            id
        }
    }`;
    private readonly updateCollectionQuery = `mutation AAMapBuilderUpdateCollection($input: CollectionUpdateInput!){
        updateCollection(input: $input){
            id
        }
    }`;
    private readonly updateLayerQuery = `mutation AAMapBuilderUpdateLayer($input: ProjectMapLayerUpdateInput!){
        updateProjectMapLayer(input: $input){
            id
        }
    }`;

    private $destroy = new Subject<void>();

    private readonly projectId = signal<number>(null);
    private readonly attributes = signal<MapBuilderAttributeResponse[]>([]);
    private readonly collections = signal<MapBuilderCollectionsResponse[]>([]);
    private readonly layers = signal<MapBuilderItem[]>([]);
    public readonly parent = signal<MapBuilderInitResponse['project']['parent']>(null);
    public readonly selectedUuid = signal<string>(null);
    public readonly dirtyIds = signal<string[]>([]);
    public readonly ddsEnabled = signal<boolean>(false);
    public readonly projectBounds = signal<MultiPolygon>(null);
    public readonly projectBoundingBox = computed<mapboxgl.LngLatBounds>(() => {
        return multiPolygonToBounds(this.projectBounds());
    });
    public readonly projectBoundingBoxFlat = computed<[number, number, number, number]>(() => {
        return this.projectBoundingBox()?.toArray()?.flat() as [number, number, number, number];
    });

    public formData = signal<FeatureCollection<Geometry, FeatureProperties>>({
        type: "FeatureCollection",
        features: []
    });

    public readonly selected = computed<MapBuilderItem>(() => {
        const uuid = this.selectedUuid();
        return this.layers().find(a => a.uuid === uuid);
    });

    public readonly formLayers = computed(() => {
        return this.layers().filter((a): a is MapBuilderForm => a.type === 'form').sort((a, b) => a.config.sort - b.config.sort);
    });

    public readonly otherLayers = computed(() => {
        return this.layers().filter((a): a is MapBuilderItem => a.type !== 'form').sort((a, b) => a.config.sort - b.config.sort);
    });

    public readonly dirty = computed(() => this.dirtyIds().length > 0);

    private readonly dirtyLayers = computed(() => {
        const dirtyIds = this.dirtyIds();
        return this.layers().filter(a => dirtyIds.includes(a.uuid));
    });

    public readonly selectedLabelAttributes = computed(() => {
        const selected = this.selected();
        const attributes = this.attributes();
        const t = selected.type === 'form' ? attributes.filter(a => a.surveyId === selected.config.id && ['text', 'select'].includes(a.type)) : [];
        t.sort((a, b) => a.label.localeCompare(b.label));
        return t;
    });

    public readonly selectedLabelAttribute = computed(() => {
        const selected = this.selected();
        if (selected?.type === 'form' && selected.config.labelAttributeId) {
            return this.attributes().find(a => a.id === selected.config.labelAttributeId);
        }
        return null;
    });

    public readonly selectedLabelCollectionAttributes = computed(() => {
        const attribute = this.selectedLabelAttribute();
        return attribute && attribute.type === 'select' ? this.attributes().filter(a => a.parentCollectionId === attribute.collectionId) : [];
    });

    public readonly selectedDataAttributes = computed(() => {
        const selected = this.selected();
        if (selected?.type === 'form') {
            return this.attributes().filter(a => a.surveyId === selected.config.id && a.type === 'select');
        }
        return [];
    });


    private map: mapboxgl.Map;

    public formDataCount = signal(20);

    private featurePropertiesForForm(form: MapBuilderForm): FeatureProperties {
        const attributes = this.attributes();
        const collections = this.collections();

        let label = undefined;
        let color = undefined;
        let icon = undefined;
        let item: MapBuilderCollectionsItemResponse;

        const labelAttribute = attributes.find(a => a.id === form.config.labelAttributeId);
        const labelCollectionAttribute = attributes.find(a => a.id === form.config.labelCollectionAttributeId);

        if (labelAttribute?.type === 'text') {
            label = 'Lorem Ipsum';
        } else if (labelAttribute?.type === 'select') {
            const collection = collections.find(a => a.id === labelAttribute.collectionId);
            if (collection) {
                // Choose a random item from the collection
                item = collection.items[Math.floor(Math.random() * collection.items.length)];
                if (labelCollectionAttribute) {
                    label = item.data?.[labelCollectionAttribute.path] ?? '';
                } else {
                    label = item?.value ?? '';
                }
            }
        }

        const styleAttribute = form.config.styleAttributeId ? attributes.find(a => a.id === form.config.styleAttributeId) : null;
        const styleCollection = collections.find(a => a.id === styleAttribute?.collectionId);
        if (styleCollection) {
            // This check asserts that if the attributes are the same we don't choose ad ifferent item
            if (form.config.styleAttributeId !== form.config.labelAttributeId) {
                item = styleCollection.items[Math.floor(Math.random() * styleCollection.items.length)];
            }
            // Choose a random item from the collection
            color = item.color;
            icon = item.icon;
        }

        return {
            surveyId: form.config.id,
            __label: label,
            __color: color,
            __icon: icon
        };
    }

    public generateData() {
        const features: Feature<Geometry, FeatureProperties>[] = [];

        const forms = this.formLayers();
        const bounds = this.projectBoundingBoxFlat();
        const count = this.formDataCount();

        // How many polygons do we need
        const polyggonForms = forms.filter(f => f.geometryTypes.includes('polygon') || f.geometryTypes.includes('multipolygon'));
        const polygonCount = polyggonForms.length * count;
        const polgons = generateNonOverlappingPolygons<FeatureProperties>(bounds, polygonCount).features;

        for (const form of forms) {
            const formFeatures: Feature<Geometry, FeatureProperties>[] = [];

            if (form.geometryTypes.includes('polygon') || form.geometryTypes.includes('multipolygon')) {
                // formFeatures.push(...generateNonOverlappingPolygons<FeatureProperties>(bounds, count).features);
                // formFeatures.push(polgons.pop())
                formFeatures.push(...polgons.splice(0, count));
            }

            if (form.geometryTypes.includes('linestring') || form.geometryTypes.includes('multilinestring')) {
                formFeatures.push(...randomLineString(count, { bbox: bounds, max_rotation: Math.PI / 4 }).features);
            }

            if (form.geometryTypes.includes('point') || form.geometryTypes.includes('multipoint')) {
                formFeatures.push(...randomPoint(count, { bbox: bounds }).features);
            }

            for (let i = 0; i < formFeatures.length; i++) {
                formFeatures[i].properties = this.featurePropertiesForForm(form);
            }
            features.push(...formFeatures);
        }
        this.formData.set(featureCollection<Geometry, FeatureProperties>(features));
    }

    public formStyleData = computed<FeatureCollection<Geometry, FeatureProperties>>(() => {
        const data = this.formData();
        const features: Feature<Geometry, FeatureProperties>[] = [];

        for (const feature of data.features) {
            const flat = flatten(feature) as FeatureCollection<any, FeatureProperties>;
            switch (feature.geometry.type) {
                case 'MultiPolygon':
                case 'Polygon': {
                    for (const f of flat.features) {
                        features.push({
                            ...f,
                            geometry: center(f.geometry).geometry
                        } as Feature<Geometry, FeatureProperties>);
                    }
                    break;
                }
                case 'LineString':
                case 'MultiLineString': {
                    for (const f of flat.features) {
                        features.push({
                            ...f,
                            geometry: along(f.geometry, length(f) / 2).geometry
                        } as Feature<Geometry, FeatureProperties>);
                    }
                    break;
                }
                case 'Point':
                case 'MultiPoint': {
                    features.push(...flat.features as Feature<Geometry, FeatureProperties>[]);
                    break;
                }
            }
        }
        return featureCollection<Geometry, FeatureProperties>(features);
    })

    generateUuid() {
        return window.crypto.getRandomValues(new Uint32Array(1))[0].toString(16);
    }

    public form = new FormGroup({
        // visible: new FormControl<boolean>(false),
        color: new FormControl<string>('#000000'),

        // Collection Only
        enabled: new FormControl<boolean>(false),

        // Image, GeoJSON, Heatmap Only
        name: new FormControl<string>(''),

        // Label Configuration
        labelAttributeId: new FormControl<number>(null),
        labelCollectionAttributeId: new FormControl<number>(null),
        labelFontSize: new FormControl<number>(16),
        styleAttributeId: new FormControl<number>(null),

        // Point Config
        pointStyle: new FormControl<MapBuilderPointStyleType>(null),
        pointRadius: new FormControl<number>(0),
        pointOpacity: new FormControl<number>(0),
        pointBorderWidth: new FormControl<number>(0),
        pointBorderOpacity: new FormControl<number>(0),
        pointBorderColor: new FormControl<string>('#000000'),

        // Polygon Config
        polygonStyle: new FormControl<MapBuilderStyleType>(null),
        polygonOpacity: new FormControl<number>(0),
        polygonBorderWidth: new FormControl<number>(0),
        polygonBorderColor: new FormControl<string>('#000000'),
        polygonBorderOpacity: new FormControl<number>(0),

        // Linestring Config
        lineStyle: new FormControl<MapBuilderStyleType>(null),
        lineWidth: new FormControl<number>(0)
    });

    constructor() {
        const state = this.redux.state();
        const orgFeatures = getProjectOrgFeatures(state);
        this.ddsEnabled.set((orgFeatures && (orgFeatures.dataDrivenStyling === true)) ?? false);

        this.form.valueChanges.pipe(
            distinctUntilChanged((prev, curr) => JSON.stringify(prev) === JSON.stringify(curr)),
            takeUntil(this.$destroy)
        ).subscribe(({
            color, enabled, name, labelAttributeId, labelCollectionAttributeId, styleAttributeId, labelFontSize,
            pointStyle, pointRadius, pointOpacity, pointBorderWidth, pointBorderOpacity, pointBorderColor,
            polygonOpacity, polygonBorderWidth, polygonBorderColor, polygonBorderOpacity, lineWidth
        }) => {
            const layers = this.layers();
            const selected = this.selected();
            const idx = layers.indexOf(selected);
            const uuid = selected?.uuid;
            const type = selected?.type;

            const updatedLayer = {
                ...selected,
                config: {
                    ...selected.config,
                    ...(type === 'form' && { styleAttributeId, labelAttributeId, labelCollectionAttributeId }),
                    ...(type === 'collection' && { enabled }),
                    ...(type !== 'form' && type !== 'collection' && { name }),
                    style: {
                        color,
                        labelFontSize,
                        pointStyle,
                        pointRadius,
                        pointOpacity,
                        pointBorderWidth,
                        pointBorderOpacity,
                        pointBorderColor,
                        polygonOpacity,
                        polygonBorderWidth,
                        polygonBorderColor,
                        polygonBorderOpacity,
                        lineWidth
                    }
                }
            } as MapBuilderItem;

            this.layers.update(l => ([
                ...l.slice(0, idx),
                updatedLayer,
                ...l.slice(idx + 1)
            ]));

            this.dirtyIds.update(l => Array.from(new Set([...l, uuid])));
        });
    }


    init(projectId: number) {
        const input = { projectId };
        this.projectId.set(projectId);

        this.api.graphql<MapBuilderInitResponse>(this.initQuery, input).subscribe((res) => {
            const layers: MapBuilderItem[] = [];
            const { attributes, forms, layers: projectLayers, collections, geometry } = res.project;

            const geometricForms = forms.filter(f => attributes.some(a => a.surveyId === f.id && a.questionType === 'geometry'));
            const supportedCustomLayers = projectLayers.filter(l => l.sourceType === 'image' || l.sourceType === 'geojson' || l.type !== null);
            const collectionGeometricLayers = collections.filter(c => c.geometric);

            // Forms
            for (const f of geometricForms) {
                const geometryAttribute = attributes.find(a => a.questionType === 'geometry');
                const { id, name, mapSort, style, labelAttributeId, labelCollectionAttributeId, styleAttributeId } = f;
                layers.push({
                    type: 'form',
                    uuid: this.generateUuid(),
                    config: {
                        id,
                        name,
                        sort: mapSort,
                        visible: true,
                        style: style || defaultDataLayerStyle(),
                        mapLayer: null,
                        enabled: true,
                        styleAttributeId,
                        labelAttributeId,
                        labelCollectionAttributeId,
                    },
                    geometryTypes: geometryAttribute.config.types
                });
            }

            // Map Layers
            for (const l of supportedCustomLayers) {
                const { id, name, sourceType, sourceId, sort, type, layout, paint, style, source, visible, bounds } = l;
                const layerType = (sourceType ?? 'heatmap') as any;

                layers.push({
                    type: layerType,
                    uuid: this.generateUuid(),
                    config: {
                        id,
                        name,
                        sort,
                        visible,
                        style: {
                            ...defaultDataLayerStyle(),
                            ...style
                        },
                        mapLayer: null,
                        enabled: true,
                        type,
                        sourceType,
                        source,
                        sourceId,
                        layout,
                        paint,
                        bounds
                    }
                });
            }

            // Collection Layers
            for (const c of collectionGeometricLayers) {
                const { id, name, mapSort, style, mapLayer } = c;
                layers.push({
                    type: 'collection',
                    uuid: this.generateUuid(),
                    config: {
                        id,
                        name,
                        sort: mapSort,
                        mapLayer,
                        enabled: mapLayer,
                        style: style || defaultDataLayerStyle(),
                    }
                });
            }

            this.layers.set(layers);
            this.attributes.set(attributes);
            this.parent.set(res.project.parent);

            // const defaultBounds: MultiPolygon = {
            //     type: 'MultiPolygon',
            //     coordinates: [[[[-180, -90], [-180, 90], [180, 90], [180, -90], [-180, -90]]]]
            // }

            // const defaultBoundsUK: MultiPolygon = {
            //     type: 'MultiPolygon',
            //     coordinates: [[[
            //         [-10.8544921875, 49.82380908513249],
            //         [1.669921875, 49.82380908513249],
            //         [1.669921875, 59.35559611010676],
            //         [-10.8544921875, 59.35559611010676],
            //         [-10.8544921875, 49.82380908513249]
            //     ]]]
            // }

            const defaultBoundsBristol: MultiPolygon = {
                type: 'MultiPolygon',
                coordinates: [[[
                    [-2.674560546875, 51.45400691005982],
                    [-2.58056640625, 51.45400691005982],
                    [-2.58056640625, 51.495064695951],
                    [-2.674560546875, 51.495064695951],
                    [-2.674560546875, 51.45400691005982]
                ]]]
            }

            this.projectBounds.set(geometry ?? defaultBoundsBristol);
            this.collections.set(collections);

            if (this.map) {
                this.map.fitBounds(this.projectBoundingBox(), {
                    maxDuration: 0,
                    animate: false
                });
            }

            if (layers.length > 0) {
                this.select(layers[0]);
            }
            this.generateData();
        });
    }

    initMap(mapContainer: HTMLElement) {
        this.map = new mapboxgl.Map({
            container: mapContainer,
            style: 'mapbox://styles/mapbox/streets-v11',
            bounds: this.projectBoundingBox(),
            accessToken: environment.mapboxApiKey,
        });

        return this.map;
    }

    select(item: MapBuilderItem | null) {
        this.selectedUuid.set(item?.uuid ?? null);
    }

    reorderLayers(isForm: boolean) {
        const layers = isForm ? this.formLayers() : this.otherLayers();
        const layersIdx = layers.map(l => l.uuid);

        this.layers.update(layer =>
            layer.map((l, index) => {
                const sort = layersIdx.indexOf(l.uuid);
                return sort !== -1
                    ? { ...l, config: { ...l.config, sort } } as MapBuilderItem
                    : l;
            })
        );

        this.dirtyIds.update(l => Array.from(new Set([...l, ...layersIdx])));
    }

    getRecordsBoundingBox(projectId: number, surveyId: number): Promise<[number, number, number, number]> {
        const collectionBound = `query CoreoAARecordsBoundingBox($where: SequelizeJSON, $projectId: Int!){
            project(id: $projectId){
                bounds: recordsBoundingBox(where: $where)
            }
        }`;

        return new Promise((resolve, reject) => {
            this.api.graphql<any>(collectionBound, { projectId, where: { surveyId } }).subscribe({
                next: (res) => {
                    try {
                        const coordinates = res.project.bounds && res.project.bounds.length
                            ? res.project.bounds
                            : null;
                        resolve(coordinates);
                    } catch (error) {
                        reject(error);
                    }
                },
                error: (err) => reject(err)
            });
        });
    }

    getCollectionBoundingBox(projectId: number, collectionId: number): Promise<[number, number, number, number]> {
        const collectionBound = `
            query CoreoAAGetCollectionBoundingBox($collectionId: Int!, $projectId: Int!) {
                project(id: $projectId) {
                    collections(where: {id: $collectionId}) {
                        bounds: boundingBox {
                            coordinates
                        }
                    }
                }
            }
        `;

        return new Promise((resolve, reject) => {
            this.api.graphql<any>(collectionBound, { projectId, collectionId }).subscribe({
                next: (res) => {
                    try {
                        const coordinates = res.project.collections[0].bounds.coordinates.flat().map(i => parseFloat(i));
                        resolve(coordinates);
                    } catch (error) {
                        reject(error);
                    }
                },
                error: (err) => reject(err)
            });
        });
    }

    async uploadGeoTIFF(projectId: number, file: File): Promise<{ url: string; bounds: number[][] }> {
        const query = `query AAUploadGeoTIFF($projectId: Int!){
            processGeoTIFF(projectId: $projectId){
                url,
                bounds{
                    coordinates
                }
            }
        }`;

        return new Promise((resolve, reject) => {
            this.api.graphql<any>(query, { projectId }, { geotiff: file }).subscribe({
                next: (res) => {
                    try {
                        const { url, bounds } = res.processGeoTIFF;
                        resolve({ url, bounds: bounds.coordinates });
                    } catch (error) {
                        reject(error);
                    }
                },
                error: (err) => reject(err)
            });
        });
    }

    async uploadShapefile(projectId: number, file: File): Promise<{ url: string; bounds: number[] }> {
        const query = `query AAUploadShapefile($projectId: Int!){
            processShapefile(projectId: $projectId){
                url,
                bounds
            }
        }`;

        return new Promise((resolve, reject) => {
            this.api.graphql<any>(query, { projectId }, { geotiff: file }).subscribe({
                next: (res) => {
                    try {
                        const { url, bounds } = res.processShapefile;
                        resolve({ url, bounds });
                    } catch (error) {
                        reject(error);
                    }
                },
                error: (err) => reject(err)
            });
        });
    }

    saveCustomLayer(projectId: number, layer: any): Promise<MapBuilderLayerResponse> {
        const mutationName = layer.id < 0 ? 'AAMapEditorCreateCustomLayer' : 'AAMapEditorUpdateCustomLayer';
        const mutationInput = layer.id < 0 ? 'ProjectMapLayerCreateInput' : 'ProjectMapLayerUpdateInput';
        const mutationOperation = layer.id < 0 ? 'createProjectMapLayer' : 'updateProjectMapLayer';

        const mutation = `mutation ${mutationName}($input: ${mutationInput}!){
            result: ${mutationOperation}(input: $input){
                id
                name
                sourceType
                sourceId
                sort
                type
                layout
                paint
                style
                source
                visible
                bounds
            }
        }`;

        const { name, sourceType, sourceId, sort, paint, layout, type, source, visible, bounds } = layer;

        const input: any = {
            name,
            sourceType,
            sourceId,
            sort,
            type,
            source,
            projectId,
            visible,
            bounds,
            paint: JSON.stringify(paint || {}),
            layout: JSON.stringify(layout || {}),
            style: {
                color: '#000'
            }
        };
        if (layer.id > 0) {
            input.id = layer.id;
        }

        return new Promise((resolve, reject) => {
            this.api.graphql<any>(mutation, { input }).subscribe({
                next: (res) => {
                    const { result } = res;
                    try {
                        resolve(result);
                    } catch (error) {
                        reject(error);
                    }
                },
                error: (err) => reject(err)
            });
        });
    }

    addCustomLayer(id: number, config: any): MapBuilderItem {
        const {
            name, visible, sort, style, type,
            sourceType, source, sourceId, layout, paint, bounds
        } = config;

        const layerType = { image: 'image', geojson: 'geojson' }[sourceType] || 'heatmap';

        const newLayer: MapBuilderLayer = {
            type: layerType,
            uuid: this.generateUuid(),
            config: {
                id,
                name,
                sort,
                visible,
                style,
                type,
                sourceType,
                source,
                sourceId,
                layout,
                paint,
                bounds,
                mapLayer: null,
                enabled: true
            }
        };

        this.layers.update(l => ([...l, newLayer]));
        return newLayer;
    }

    async createCustomLayer(layer: any) {
        const newLayer = await this.saveCustomLayer(this.projectId(), layer);
        const l = this.addCustomLayer(newLayer.id, layer);
        this.selectedUuid.set(l.uuid);
        this.map.fire('load');
    }

    save() {
        const dirtyLayers = this.dirtyLayers();

        return forkJoin(dirtyLayers.map(f => {
            switch (f.type) {
                case 'form':
                    return this.saveForm(f.uuid);
                case 'collection':
                    return this.saveCollection(f.uuid);
                default:
                    return this.saveLayer(f.uuid);
            }
        })).subscribe(() => {
            this.dirtyIds.set([]);
        });
    }

    saveForm(uuid: string) {
        const form = this.formLayers().find(a => a.uuid === uuid);
        const { id, sort, labelAttributeId, labelCollectionAttributeId, styleAttributeId, style } = form.config;
        return this.api.graphql(this.updateFormQuery, {
            input: {
                id,
                style,
                sort,
                labelAttributeId,
                labelCollectionAttributeId,
                styleAttributeId
            }
        });
    }

    saveCollection(uuid: string) {
        const collection = this.otherLayers().find(a => a.uuid === uuid);
        const { id, enabled: mapLayer, sort: mapSort, style } = collection.config;
        return this.api.graphql(this.updateCollectionQuery, {
            input: { id, mapLayer, style, mapSort }
        });
    }

    saveLayer(uuid: string) {
        const layer = this.otherLayers().find(a => a.uuid === uuid);
        const { id, name, sort, style } = layer.config;
        return this.api.graphql(this.updateLayerQuery, {
            input: { id, name, style, sort }
        });
    }

    deleteLayer() {
        const layer = this.selected();
        const uuid = layer.uuid;
        const { id } = layer.config;
        const mutation = `mutation AAMapEditorDeleteLayer($id: Int!){
            deleteProjectMapLayer(input: { id: $id } )
        }`;

        return this.api.graphql(mutation, { id }).subscribe(() => {
            this.selectedUuid.set(null);
            this.layers.update(l => l.filter(a => a.uuid !== uuid));
        });
    }

    ngOnDestroy() {
        this.$destroy.next();
        this.$destroy.complete();
    }
}

function multiPolygonToBounds(geometry: GeoJSON.MultiPolygon): LngLatBounds {
    if (geometry?.type !== 'MultiPolygon') {
        return null;
    }

    // Extract all coordinates from the MultiPolygon
    const coordinates = geometry.coordinates;

    // Flatten the array of coordinates to get a list of all points
    const points = coordinates.flat(2); // MultiPolygon -> Polygon -> LinearRing -> [lng, lat]

    if (points.length === 0) {
        throw new Error('No coordinates found in MultiPolygon');
    }

    // Determine the bounding box
    const [minLng, minLat, maxLng, maxLat] = points.reduce(
        ([minLng, minLat, maxLng, maxLat], [lng, lat]) => [
            Math.min(minLng, lng),
            Math.min(minLat, lat),
            Math.max(maxLng, lng),
            Math.max(maxLat, lat),
        ],
        [Infinity, Infinity, -Infinity, -Infinity]
    );

    // Create and return LngLatBounds
    return new LngLatBounds([minLng, minLat], [maxLng, maxLat]);
}