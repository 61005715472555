import { AsyncPipe, CommonModule, DatePipe } from '@angular/common';
import { Component, computed, effect, EventEmitter, inject, input, Output, signal } from '@angular/core';
import { catchError, of, take } from 'rxjs';
import { ApiService } from 'src/app/core';
import { AttributeQuestionType, AttributeType } from '../../record-form/form.types';
import { DialogService, DynamicDialogModule } from 'primeng/dynamicdialog';
import { ImageModalComponent, ImageModalProps } from '../../../../shared/components/image-modal/image-modal.component';
import { RecordAttachment } from '../../records.service';
import { TwicPicPipe } from 'src/app/shared/pipes/twicpic.pipe';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { Feature, Geometry } from 'geojson';
import { RecordMapComponent } from '../record-map/record-map.component';

interface RecordDataSummaryResponse {
    id: number;
    geometry: Geometry;
    data: { [key: string]: any; };
    attachments: {
        id: number;
        url: string;
        mimeType: string;
        attributeId: number;
    }[];
    associates: {
        association: {
            id: number;
            path: string;
            label: string;
            survey: {
                title: string;
            }
        };
        record: {
            id: number;
            title: string;
        }
    }[];
    survey: {
        attributes: {
            id: number;
            label: string;
            path: string;
            order: number;
            type: AttributeType;
            questionType: AttributeQuestionType;
            collection: {
                items: {
                    key: string;
                    value: string;
                }[];
            };
        }[];
    };
}

interface RecordDataSummaryItem {
    attribute: {
        label: string;
        type: string;
        questionType: string;
        path: string;
    };
    value: any;
    highlight?: string;
}

type OtherAssociates = {
    label: string;
    associates: {
        title: string;
        id: number;
    }[]
}

@Component({
    selector: 'app-record-summary',
    templateUrl: 'record-summary.component.html',
    standalone: true,
    imports: [
        CommonModule,
        DynamicDialogModule,
        TwicPicPipe,
        ProgressSpinnerModule,
        RecordMapComponent
    ],
    providers: [
        DialogService
    ]
})

export class RecordSummaryComponent {

    apiService = inject(ApiService);
    datePipe = inject(DatePipe);
    dialogService = inject(DialogService);

    recordId = input<number>();
    diff = input<any[]>([]);
    highlight = input<boolean>();
    replace = input<boolean>();
    mapStyleName = input<string>()
    readonly = input<boolean>(false); // ie in lookup we don;t want to click on images or associated records
    noMap = input<boolean>(false); // For use in record lookup where it isn't needed

    @Output() record: EventEmitter<number> = new EventEmitter();
    @Output() onMapStyleChanged: EventEmitter<string> = new EventEmitter();

    recordData = signal<RecordDataSummaryItem[]>([]);
    recordGeometry = signal<Geometry | null>(null);
    otherAssociates = signal<OtherAssociates[]>([]);

    feature = computed<Feature>(() => {
        const geometry = this.recordGeometry();

        if (geometry) {
            return { type: 'Feature', geometry, properties: {} };
        } else {
            return null;
        }
    });

    constructor() { 
        effect(() => {
            const recordId = this.recordId();
            this.getRecordData(recordId);
        });
    }

    private getRecordData(id: number) {
        const query = `query getRecordData($id: Int){
            record(id: $id){
                id
                geometry{
                    type
                    coordinates
                }
                data
                attachments{
                    id
                    url
                    mimeType
                    attributeId
                }
                associates{
                    association{
                        id
                        path
                        label
                        survey{
                            title
                        }
                    }
                    record{
                        id
                        title
                    }
                }
                survey{
                    attributes{
                        id
                        label
                        path
                        order
                        type
                        questionType
                        collection{
                            items{
                                key
                                value
                            }
                        }
                    }
                }
            }
        }`;

        this.apiService.graphql<{ record: RecordDataSummaryResponse }>(query, { id }).pipe(
            take(1),
            catchError((err) => {
                console.error(err);
                return of(null)
            })
        ).subscribe(res => {
            if (!!res) {
                this.recordData.set(this.formatData(res.record));
                this.recordGeometry.set(res.record.geometry);
            }
        });
    }

    private formatData(record: RecordDataSummaryResponse): RecordDataSummaryItem[] {
        const { associates, attachments, data, survey } = record;
        const { attributes } = survey;

        /** Return associated records that aren't part of the survey attributes ie reverse lookup direction */
        const otherAssociates = associates.filter(a => {
            const idx = attributes.findIndex(attr => attr.path === a.association.path);
            return idx < 0;
        })

        const sortedObj = otherAssociates.reduce((acc, item) => {
            return {
                ...acc,
                [item.association.survey.title]: [
                    ...(acc[item.association.survey.title] || []),
                    item.record
                ]
            }
        }, {});

        const result = Object.keys(sortedObj).map((key) => {
            const item = sortedObj[key];
            return {
                label: key,
                associates: item
            }
        });

        this.otherAssociates.set(result);

        return attributes
            .filter(a => a.questionType !== 'geometry')
            .filter(a => {
                /** If highlighting or replace remove attachments and media as they don't show up in diff */
                if (this.highlight() || this.replace()) {
                    return !(a.type === 'attachment' || a.type === 'media');
                } else {
                    return true;
                }
            })
            .sort((a, b) => a.order - b.order)
            .map(attribute => {
                let att = { label: attribute.label, type: attribute.type, questionType: attribute.questionType, path: attribute.path };
                let value = data[attribute.path];
                let highlight = null;

                if (attribute.type === 'boolean') {
                    value = value === undefined || value === null
                        ? null
                        : (typeof value === 'string' && ['true', 'false'].includes(value))
                            ? JSON.parse(value) ? 'Yes' : 'No'
                            : value ? 'Yes' : 'No';
                }
                if (!!value && attribute.type === 'datetime') {
                    value = this.datePipe.transform(data[attribute.path], "d MMMM y 'at' hh:mm a");
                }
                if (!!value && attribute.type === 'date') {
                    value = this.datePipe.transform(data[attribute.path], "d MMMM y");
                }
                if (attribute.type === 'media' || attribute.type === 'attachment') {
                    const media = attachments.filter(attachment => attachment.attributeId === attribute.id);
                    if (media.length > 0) {
                        value = media;
                    } else {
                        value = null;
                    }
                }
                if (!!value && attribute.type === 'select') {
                    const item = attribute.collection.items.find(a => a.key === data[attribute.path]);
                    value = item.value;
                }
                if (!!value && attribute.type === 'multiselect') {
                    const items = data[attribute.path].map(item => {
                        return attribute.collection.items.find(a => a.key === item).value;
                    });
                    value = items;
                }
                if (!!value && attribute.type === 'geometryquery') {
                    const item = attribute.collection.items.find(a => a.key === data[attribute.path]);
                    value = item.value;
                }
                const hasAssociate: boolean = !!associates.find(a => a.association.path === attribute.path);
                if (hasAssociate) {
                    const records = associates.filter(a => a.association.path === attribute.path).map(a => a.record);
                    value = records;
                }

                /** If highlighting or replacing add highlight colour and replace value as necessary */
                if (this.highlight() || this.replace()) {
                    const diff: any = this.diff().find(d => {
                        const splitPath = d.path.split('/');
                        const diffPath = splitPath.pop();
                        return diffPath === attribute.path;
                    });

                    if (!!diff) {
                        highlight = this.highlight() ? '#009E1933' : '#CD564233';

                        if (this.replace()) {
                            value = diff.value;
                        }
                    }
                }

                return { attribute: att, value, highlight };
            })
            .filter(data => {
                /** If highlighting or replacing show all values, else remove null, undefined or empty values from summary */
                if (this.highlight() || this.replace()) {
                    return true;
                } else {
                    return !(data.value === undefined || data.value === null || data.value.length < 1);
                }
            });
    }

    public clickRecord(id: number) {
        if (this.readonly()) {
            return;
        }
        this.record.emit(id);
    }

    public clickImage(label: string, path: string, images: RecordAttachment[], selectedImage: RecordAttachment) {
        if (this.readonly()) {
            return;
        }

        const imageProps: ImageModalProps = {
            images: images.map(image => {
                const { url } = image;
                const split = url.split('/');
                const filename: string = split[split.length - 1];
                const name = `${path}-${filename}`;
                return { url, name }
            }),
            index: images.findIndex(image => image.id === selectedImage.id)
        };

        this.dialogService.open(ImageModalComponent, {
            data: imageProps,
            header: `${label}`,
            width: '75vw',
            height: '90vh',
            modal: true,
            breakpoints: {
                '960px': '75vw',
                '640px': '90vw'
            },
            contentStyle: { 'padding': '0px' }
        });
    }

    public refresh() {
        this.getRecordData(this.recordId());
    }
}