import { Component, computed, inject, OnInit, signal } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { ApiService } from 'src/app/core';
import { z } from 'zod';
import { ProjectFormService } from './project-form.service';
import { catchError, of, take } from 'rxjs';
import { DropdownModule } from 'primeng/dropdown';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { InputSwitchModule } from 'primeng/inputswitch';
import { ButtonModule } from 'primeng/button';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { TooltipModule } from 'primeng/tooltip';
import { FormSettings, FormSettingsSchema } from './project-form.model';

type DropdownOption = {
    id: number;
    label: string;
}

const validTitleAttributeTypes = ['text', 'select', 'multiselect', 'media', 'float', 'number', 'integer', 'date', 'datetime', 'email', 'url'];

@Component({
    selector: 'app-project-form-settings-modal',
    templateUrl: 'project-form-settings-modal.component.html',
    standalone: true,
    imports: [
        ReactiveFormsModule,
        DropdownModule,
        InputTextModule,
        InputTextareaModule,
        InputSwitchModule,
        ButtonModule,
        TooltipModule
    ]
})

export class ProjectFormSettingsModalComponent implements OnInit {

    apiService = inject(ApiService);
    formService = inject(ProjectFormService);
    dialogRef = inject(DynamicDialogRef);
    dialogConfig = inject(DynamicDialogConfig);

    projectId = this.formService.projectId;
    attributes = this.formService.attributes;
    formSettings = this.formService.formSettings;

    formId = signal<number | null>(null);
    isChildForm = signal(false);
    settings = signal<FormSettings | null>(null);
    settingsForm = signal<FormGroup | null>(null);

    titleAttributeId = signal<number | null>(null);
    secondaryTitleAttributeId = signal<number | null>(null);

    formAttributes = computed(() => {
        const formId = this.formId();
        const attributes = this.attributes();
        const formAttributes = attributes.filter(a => a.formId === formId);
        return formAttributes;
    });

    titleAttributeOptions = computed<DropdownOption[]>(() => {
        const attributes = this.formAttributes();
        const secondaryTitleAttributeId = this.secondaryTitleAttributeId();
        const titleOptions: DropdownOption[] = attributes
            .filter(a => a.id > 0 && validTitleAttributeTypes.indexOf(a.type) !== -1 && a.id !== secondaryTitleAttributeId)
            .map(a => ({ id: a.id, label: a.label }));

        return [{ id: null, label: 'Automatic' }, ...titleOptions];
    });
    secondaryTitleAttributeOptions = computed<DropdownOption[]>(() => {
        const attributes = this.formAttributes();
        const titleAttributeId = this.titleAttributeId();
        const secondaryTitleOptions: DropdownOption[] = attributes
            .filter(a => a.id > 0 && validTitleAttributeTypes.indexOf(a.type) !== -1 && a.id !== titleAttributeId)
            .map(a => ({ id: a.id, label: a.label }));

            return [{ id: null, label: 'Automatic' }, ...secondaryTitleOptions];
    });

    submitting = signal(false);

    ngOnInit() {
        this.formId.set(this.dialogConfig.data['formId']);
        this.isChildForm.set(this.dialogConfig.data['isChildForm']);
        this.settings.set(this.dialogConfig.data['settings']);
        this.buildForm();
    }
    
    private buildForm() {
        this.titleAttributeId.set(this.settings().titleAttributeId);
        this.secondaryTitleAttributeId.set(this.settings().secondaryTitleAttributeId);

        const controls = {
            titleAttributeId: new FormControl(this.settings().titleAttributeId),
            secondaryTitleAttributeId: new FormControl(this.settings().secondaryTitleAttributeId),
            thankyou: new FormControl(this.settings().thankyou),
            allowMemberUpdate: new FormControl(this.settings().allowMemberUpdate),
            allowOwnRecordDelete: new FormControl(this.settings().allowOwnRecordDelete),
            private: new FormControl(this.settings().isPrivate),
        };
        
        if (this.isChildForm()) {
            controls['childVisible'] = new FormControl(this.settings().visible)
        } else {
            controls['name'] = new FormControl(this.settings().name, Validators.required);
            controls['title'] = new FormControl(this.settings().title, Validators.required);
            controls['hidden'] = new FormControl(!this.settings().visible)
        }

        this.settingsForm.set(new FormGroup(controls));
    }

    save() {
        if (this.formId() > 0) {
            /** Form exists in the database */
            this.submitting.set(true);
        
            const query = `mutation CoreoAAUpdateSurveySettings($input: SurveyUpdateInput!){
                updateSurvey(input: $input){
                    name
                    title
                    titleAttributeId
                    secondaryTitleAttributeId
                    thankyou
                    allowMemberUpdate
                    allowOwnRecordDelete
                    isPrivate: private
                    visible
                    sort
                }
            }`;

            const settingsForm = this.settingsForm();
        
            let input = {
                id: this.formId(),
                titleAttributeId: settingsForm.controls['titleAttributeId'].value,
                secondaryTitleAttributeId: settingsForm.controls['secondaryTitleAttributeId'].value,
                thankyou: settingsForm.controls['thankyou'].value,
                allowMemberUpdate: settingsForm.controls['allowMemberUpdate'].value,
                allowOwnRecordDelete: settingsForm.controls['allowOwnRecordDelete'].value,
                private: settingsForm.controls['private'].value,
            };

            if (this.isChildForm()) {
                input = {
                    ...input,
                    ...{ 
                        visible: settingsForm.controls['childVisible'].value
                    }
                }
            } else {
                input = {
                    ...input,
                    ...{ 
                        name: settingsForm.controls['name'].value,
                        title: settingsForm.controls['title'].value,
                        visible: !settingsForm.controls['hidden'].value
                    }
                }
            }
            
            this.apiService.graphql<{ updateSurvey: FormSettings }>(query, { input }).pipe(
                take(1),
                catchError((e) => {
                    console.error(e);
                    return of(null);
                })
            ).subscribe(res => {
                if (!!res) {
                    try {
                        const settings = FormSettingsSchema.parse(res.updateSurvey);
                        if (this.isChildForm()) {
                            const form = this.formService.selectedAttribute().form;
                            const update = {
                                ...form,
                                ...settings
                            }      
                            this.formService.updateSelectedAttribute({ form: update });                 
                        } else {
                            this.formSettings.set(settings);
                        }
                    } catch (error) {
                        if (error instanceof z.ZodError) {
                            console.warn(error.issues);
                        }
                    }
                    this.dialogRef.close();
                }
                this.submitting.set(false);
            });
        } else {
            /** Form hasn't been saved yet */
            this.submitting.set(true);
            const settings = this.settingsForm().getRawValue();
            const form = this.formService.selectedAttribute().form;
            const update = {
                ...form,
                ...{
                    name: settings.name,
                    title: settings.title,
                    titleAttributeId: settings.titleAttributeId,
                    secondaryTitleAttributeId: settings.secondaryTitleAttributeId,
                    thankyou: settings.thankyou,
                    allowMemberUpdate: settings.allowMemberUpdate,
                    allowOwnRecordDelete: settings.allowOwnRecordDelete,
                    isPrivate: settings.private,
                    visible: settings.visible,
                    sort: settings.sort
                }
            }
            this.formService.updateSelectedAttribute({ form: update });
            this.dialogRef.close();
            this.submitting.set(false);
        }
    }
}