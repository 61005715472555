import { Component, EventEmitter, Output, input } from '@angular/core';

@Component({
    selector: 'app-form-audio',
    templateUrl: './form-audio.component.html',
    standalone: true
})

export class FormAudioComponent {

    audioSrc = input.required<string>();

    @Output() remove: EventEmitter<void> = new EventEmitter();
}