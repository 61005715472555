<div class="table-item__inner">
    <div class="flex items-center justify-start">
        <div class="flex flex-col items-start gap-1">
            <p class="table-item__primary-text truncate">{{ form().name }}</p>
            <p class="text-xs text-text-primary truncate">{{ form().title }}</p>
        </div>
    </div>
    <div class="flex items-center justify-between gap-5">
        <div class="flex items-center justify-start gap-5">
            <div class="w-17 flex-none flex items-center justify-end gap-5">
                @if (parentAndChild()) {
                    <svg-icon src="/assets/icons/parent-and-child.svg" class="h-6 w-6 text-text-primary" pTooltip="This form is used both as a standalone form and as a child form within another form. Editing it in either context will update all instances of the form."></svg-icon>
                }
                @if (form().visible === false) {
                    <i class="fa-solid fa-eye-slash text-text-primary" pTooltip="This form has been marked as hidden and won't be visible in the form list in the app. You can change this in form settings."></i>
                }
                @else if (visibleQuestionCount() < 1) {
                    <i class="fa-solid fa-eye-slash text-text-primary" pTooltip="This form has no visible questions and will not be displayed"></i>
                }
            </div>
            <p class="text-text-primary text-sm"><span class="font-semibold">{{questionCount()}}</span> question{{questionCount() !== 1 ? 's' : ''}}</p>
        </div>
        <div class="table-item__buttons">
            <button class="table-item__button" [ngClass]="hovering() ? 'inline-block' : 'hidden'" (click)="deleteItem($event)">
                <i class="fa-solid fa-trash-can"></i>
            </button>
            <button class="text-grey-30 hover:text-primary-200 text-2xl drag-handle" [disabled]="!canSort()" [ngClass]="{'opacity-30': !canSort()}">
                <i class="fa-solid fa-grip-vertical"></i>
            </button>
        </div>
    </div>
</div>