import { polygon, featureCollection, bboxPolygon } from '@turf/turf';
import { randomPosition } from '@turf/random';
import { Feature, FeatureCollection, Polygon } from 'geojson';

/**
 * Generate non-overlapping polygons within a bounding box.
 */
export const generateNonOverlappingPolygons = <T>(
    bbox: [number, number, number, number],
    numPolygons: number,
    minVertices = 4,
    maxVertices = 10
): FeatureCollection<Polygon, T> => {
    const subAreas = divideBBox(bbox, numPolygons); // Divide bbox into sub-areas
    const polygons: Feature<Polygon, T>[] = [];

    subAreas.forEach((subArea) => {
        const randomVertices = Math.floor(Math.random() * (maxVertices - minVertices + 1)) + minVertices;
        const newPolygon = createRandomPolygon<T>(subArea, randomVertices);
        polygons.push(newPolygon);
    });

    return featureCollection<Polygon, T>(polygons);
}

/**
 * Divide a bounding box into smaller sub-areas.
 */
function divideBBox(
    bbox: [number, number, number, number],
    count: number
): [number, number, number, number][] {
    const [minX, minY, maxX, maxY] = bbox;
    const subAreas: [number, number, number, number][] = [];
    const rows = Math.ceil(Math.sqrt(count));
    const cols = Math.ceil(count / rows);
    const xStep = (maxX - minX) / cols;
    const yStep = (maxY - minY) / rows;

    for (let row = 0; row < rows; row++) {
        for (let col = 0; col < cols; col++) {
            const x1 = minX + col * xStep;
            const y1 = minY + row * yStep;
            const x2 = Math.min(maxX, x1 + xStep);
            const y2 = Math.min(maxY, y1 + yStep);
            subAreas.push([x1, y1, x2, y2]);
        }
    }

    return subAreas.slice(0, count); // Limit to exactly `count` sub-areas
}

/**
 * Create a random polygon within a bounding box.
 */
const createRandomPolygon = <T>(
    bbox: [number, number, number, number],
    vertices: number
): Feature<Polygon, T> => {
    const points = Array.from({ length: vertices }, () => randomPosition(bbox));

    // Calculate centroid and sort points by angle
    const centroid = points.reduce(
        (acc, [x, y]) => [acc[0] + x / vertices, acc[1] + y / vertices],
        [0, 0]
    );

    points.sort(([x1, y1], [x2, y2]) => {
        const angle1 = Math.atan2(y1 - centroid[1], x1 - centroid[0]);
        const angle2 = Math.atan2(y2 - centroid[1], x2 - centroid[0]);
        return angle1 - angle2;
    });

    // Close the polygon by repeating the first point
    points.push(points[0]);

    return polygon<T>([points]);
}

// Example usage
const bbox: [number, number, number, number] = [-1, -1, 1, 1]; // Small bounding box
const numPolygons = 5; // Number of polygons
const minVertices = 4; // Minimum vertices
const maxVertices = 10; // Maximum vertices