import { Component, ElementRef, EventEmitter, Output, computed, effect, inject, input, viewChild } from "@angular/core";
import { FormsModule } from "@angular/forms";
import mapboxgl, { LngLatBoundsLike } from 'mapbox-gl';
import { ButtonModule } from "primeng/button";
import { MapDataService, MapGeoJSONSource, MapStyleableLayer } from "src/app/core/services/mapData.service";
import { MapBuilderService } from "./map-builder.service";
import { MapBuilderItem } from "./map-builder.types";
import { TooltipModule } from "primeng/tooltip";

@Component({
    selector: 'app-map-builder-map',
    template: `
        @if(showDataButton()){
        <p-button
            class="absolute top-4 left-4 z-10" label="Regenerate Test Data" [outlined]="true" (click)="regenerateData()" [pTooltip]="tooltipText"
            tooltipPosition="bottom" [showDelay]="500"></p-button>
        }
        <div class="absolute inset-0" #map></div>
    `,
    standalone: true,
    imports: [
        FormsModule,
        ButtonModule,
        TooltipModule
    ]
})
export class MapBuilderMapComponent {
    projectId = input<number>();
    @Output() onUpdateTestData = new EventEmitter<boolean>();

    mapContainer = viewChild<ElementRef>('map');

    mapBuilderService = inject(MapBuilderService);
    mapDataService = inject(MapDataService);

    selected = this.mapBuilderService.selected;

    tooltipText = "To allow you to set up styling on your forms before records are recorded, the data shown here is test data only. Click regenerate test data to see a different layout";

    map: mapboxgl.Map;
    resizeObserver: ResizeObserver;
    mapRecordsLayer: MapStyleableLayer;
    source: MapGeoJSONSource;

    forms = this.mapBuilderService.formLayers;
    showDataButton = computed(() => this.forms().length > 0);
    otherLayers = this.mapBuilderService.otherLayers;

    dataTypeOptions: {label: string, value: boolean}[] = [{ label: 'Real Data', value: false },{ label: 'Test Data', value: true }];
    isShowTestData: boolean = false;

    constructor() {

        // Create map
        const e = effect(() => {
            const bounds = this.mapBuilderService.projectBounds();
            const container = this.mapContainer();

            if (!bounds || !container?.nativeElement) {
                return;
            }

            this.init();
            e.destroy();
        });

        effect(() => {
            const formData = this.mapBuilderService.formData();
            const styleData = this.mapBuilderService.formStyleData();
            this.mapRecordsLayer?.setData(formData);
            this.mapRecordsLayer?.setStyleData(styleData);
        });

        effect(() => this.updateDataLayer(this.selected()));
    }

    init() {
        this.map = this.mapBuilderService.initMap(this.mapContainer()?.nativeElement);
        this.map.on('load', () => this.renderLayers());
        const mapResizeObserver = new ResizeObserver(() => this.map.resize());
        mapResizeObserver.observe(this.mapContainer()?.nativeElement);
    }

    regenerateData() {
        this.mapBuilderService.generateData();
    }

    async renderLayers() {
        this.renderOtherLayers();
        this.renderRecordLayers();
    }

    private async renderRecordLayers() {
        const source = new MapGeoJSONSource(this.mapBuilderService.formData());
        const styleSource = new MapGeoJSONSource(this.mapBuilderService.formStyleData());
        this.mapRecordsLayer = this.mapDataService.createStyleableLayer(source, styleSource);

        for (const layer of this.forms()) {
            const { id, style, sort } = layer.config;
            this.mapRecordsLayer.setStyle(id, style as any, sort);
        }
        this.source = source;
        await this.mapRecordsLayer.addTo(this.map);
    }

    private async renderOtherLayers() {

        for (let i = 0; i < this.otherLayers().length; i++) {
            const layer = this.otherLayers()[i];
            const { id, style, sort, enabled } = layer.config; 

            if (layer.config.mapLayer && typeof layer.config.mapLayer.remove === 'function') {
                layer.config.mapLayer.remove();
            }
            
            layer.config.mapLayer = undefined;

            // if (!enabled) return;

            let styleableLayer = null;

            switch (layer.type) {
                case 'collection': {
                    const source = this.mapDataService.createCollectionSource(id);
                    styleableLayer = this.mapDataService.createStyleableLayer(source);
                    styleableLayer?.setStyle(id, style, sort);
                    break;
                }
                case 'geojson': {
                    const source = this.mapDataService.createGeoJSONSource(layer.config.source);
                    styleableLayer = this.mapDataService.createGeoJSONLayer(source);
                    styleableLayer?.setStyle(id, style, sort);
                    break;
                }
                case 'heatmap': {
                    const source = layer.config.sourceType === 'records'
                        ? this.mapDataService.createRecordsSource({ projectId: this.projectId(), query: {}, clusterMaxZoom: 0 }) 
                        : this.mapDataService.createCollectionSource(layer.config.sourceId);
                    styleableLayer = this.mapDataService.createHeatmapLayer(source, layer);
                    break;
                }
                case 'image': {
                    const { source, layout, paint, bounds } = layer.config;
                    styleableLayer = this.mapDataService.createRasterImageLayer({ 
                        source,
                        layout,
                        paint,
                        bounds,
                        type: null,
                        layerType: null, 
                        sourceId: null, 
                        sourceType: 'image',
                    });
                    break;
                }
            }
            layer.config.mapLayer = styleableLayer;
            await layer.config.mapLayer?.addTo(this.map);
        }
    }

    updateDataLayer(layer: MapBuilderItem) {
        if (!layer || !layer?.config) return;
        const { id, style, sort, mapLayer, enabled } = layer?.config;
        if (layer.type === 'collection') {
            if (enabled) {
                mapLayer?.show();
            } else {
                mapLayer?.hide();
            }
        }
        const updatedMapLayer = layer.type === 'form' ? this.mapRecordsLayer : mapLayer;
        updatedMapLayer?.setStyle?.(id, { ...style }, sort);
        updatedMapLayer?.update();
    }

    zoomToLayer() {
        const selected = this.selected();
        const { id } = selected.config;

        switch (selected.type) {
            case 'form': {
                const bounds = this.mapBuilderService.projectBoundingBox();
                this.mapFitBounds(bounds as LngLatBoundsLike);
                // this.zoomToRecordsLayer(id);
                break;
            }
            case 'collection': {
                this.zoomToCollectionLayer(id);
                break;
            }
            case 'geojson': {
                const { bounds } = selected.config;
                this.mapFitBounds(bounds as LngLatBoundsLike);
                break;
            }
            case 'heatmap': {
                const { sourceType, sourceId } = selected.config;
                sourceType === 'records' ? this.zoomToRecordsLayer(sourceId) : this.zoomToCollectionLayer(sourceId);
                break;
            }
            case 'image': {
                const { bounds, layout } = selected.config;
                const imageBounds = !!bounds
                    ? [[bounds[0], bounds[1]], [bounds[2], bounds[3]]] 
                    : [layout[0][0], layout[2][1], layout[2][0], layout[0][1]];
                this.mapFitBounds(imageBounds as LngLatBoundsLike);
                break;
            }
        }
    }

    async zoomToCollectionLayer(id: number) {
        const bounds = await this.mapBuilderService.getCollectionBoundingBox(this.projectId(), id);
        bounds && bounds.length && this.mapFitBounds(bounds);
    }

    async zoomToRecordsLayer(id: number) {
        const bounds = await this.mapBuilderService.getRecordsBoundingBox(this.projectId(), id);
        bounds && bounds.length && this.mapFitBounds(bounds);
    }

    mapFitBounds(bounds: LngLatBoundsLike) {
        this.map.fitBounds(bounds, { padding: 40 });
    }

    onTestDataChange(isTestData: boolean) {
        this.onUpdateTestData.emit(isTestData);
        if (isTestData) {
            this.selected().type !== 'form' && this.mapBuilderService.select(this.forms()[0]);
        } else if (this.map.getLayer('labels')) {
            this.map.removeLayer('labels');
        }
        this.mapRecordsLayer.remove();
        this.renderRecordLayers();
    }
}
