<p-confirmDialog />
<p-toast position="bottom-center" [life]="2000"></p-toast>

<div class="p-10 h-full flex flex-col gap-y-5 overflow-hidden">
    @if(parent){
        <div class="flex-none w-full bg-danger text-white text-center font-semibold px-10 py-4">
            This project is derived from a master project "{{parent.name}}". Its forms, collections, pages and map layers cannot be edited. You may upload additional ones to augment the project.
        </div>
    }
    <!-- Top bar -->
    <div class="flex-none w-full flex items-center justify-between gap-4">
        <h2 class="text-3xl font-semibold">Forms</h2>

        <div class="flex items-center justify-end">
            <p-button label="New form" size="large" (click)="createForm()">
                <svg-icon src="/assets/icons/plus.svg"></svg-icon>
            </p-button>

            <form [formGroup]="searchForm" class="ml-4">
                <span class="p-input-icon-left" [class.p-input-icon-right]="searchForm.value.search">
                    <i class="pi pi-search text-primary"></i>
                    <input type="text" pInputText formControlName="search" placeholder="Search for a form" class="w-80 h-11" />
                    @if(searchForm.value.search){
                        <i class="pi pi-times cursor-pointer" (click)="searchForm.reset()"></i>
                    }
                </span>
            </form>
        </div>
    </div>

    @if (childFormAttributes().length) {
        <div class="flex-none flex items-center gap-[10px] p-5 bg-text-primary rounded-lg text-white">
            <svg-icon src="/assets/icons/exclamation-circle.svg" class="flex-none h-6 w-6"></svg-icon>
            <div class="text-xs">
                <p><strong>We've improved how forms work!</strong> You must now create "child" forms directly within a parent form using
                    the form builder - you won't be able to create them separately first. Because of this change, hidden child forms
                    will no longer appear in the list below. To view or edit them, open the parent form and manage them from within the
                    form builder. <a href="https://docs.coreo.io/getting-started/coreo-components/child-forms/" target="_blank"
                        class="text-white underline">For more information on child forms click here</a></p>
            </div>
        </div>
    }

    @if (loading()) {
        <div class="w-full h-full flex flex-col items-center justify-center">
            <svg-icon src="/assets/icons/spinner.svg" class="h-10 w-10"></svg-icon>
        </div>
    }
    @else if (formList().length > 0) {
        <div class="overflow-y-auto no-scrollbar pb-4 min-w-[720px] mt-5">
            <ul #formsList class="select-none rounded-lg overflow-hidden shadow-panel">
                @for (form of formList(); track form.id) {
                    <li [attr.data-form]="form.id">
                        <app-project-forms-item 
                            class="table-item bg-white"
                            [form]="form"
                            [childFormIds]="childFormIds()"
                            (open)="openForm(form)"
                            (delete)="deleteFormCheck(form)"
                            [canSort]="canSort()"
                        ></app-project-forms-item>
                    </li>
                }
            </ul>
        </div>    
    }
    @else if (!!search()) {
        <div class="w-full h-full flex flex-col items-center justify-center gap-7">
            <i class="fa-solid fa-magnifying-glass text-6xl text-primary-100"></i>
            <h2 class="text-base font-semibold text-text-primary">We couldn't find any forms that match your search.</h2>
        </div>
    }
    @else {
        <div class="w-full h-full flex flex-col items-center justify-center">
            <div class="max-w-xs flex items-center justify-center flex-col text-center gap-4">
                <svg-icon class="w-[60px] h-[60px]" src="/assets/icons/apps.svg"/>
                <h2 class="text-base font-semibold text-text-primary text-center">You don't have any forms yet.</h2>
                <p class="text-sm text-text-secondary text-center">Start building your first form</p>
                <p-button label="Create new form" size="large" (click)="createForm()">
                    <svg-icon src="/assets/icons/plus.svg"></svg-icon>
                </p-button>
            </div>
        </div>
    }
</div>