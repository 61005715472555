<form class="w-full p-4 flex flex-col gap-1">
    @if (canChooseForm()) {
        <p class="text-sm text-text-primary font-normal">Choose the pre-existing form you would like to use for your reference lookup. You will be able to select from records within this form.</p>
        <label class="text-xs text-text-secondary font-normal" for="formOptions">Linked form</label>
        <p-dropdown [options]="formOptions()" [ngModel]="associatedSurveyId()" (ngModelChange)="update.emit({ associatedSurveyId: $event})" name="formOptions" optionLabel="name" optionValue="id" [showClear]="false" placeholder="Select a form to link to"></p-dropdown>
    }
    @else {
        <label class="flex-none text-xs text-text-secondary font-normal flex items-center justify-between">
            Linked form
            <i class="fa-solid fa-circle-info text-text-secondary text-base" pTooltip="As this form may have data already associated with it, you cannot switch it to another form. If you wish to link to a different form, create a new “record lookup” in the form builder and then delete this one. Any records already associated with this question will be lost." tooltipPosition="left"></i>
        </label>
        <p class="text-sm font-semibold text-text-primary">{{ linkedFormName() }}</p>
    }   
</form>