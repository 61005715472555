import { AsyncPipe, NgClass } from '@angular/common';
import { Component, ElementRef, HostBinding, computed, effect, inject, input, viewChild } from '@angular/core';
import { ProjectFormAttributeComponent } from './project-form-attribute.component';
import { ProjectFormBuilderSectionComponent } from './project-form-builder-section.component';
import { Attribute, QuestionId } from './project-form.model';
import { ProjectFormService } from './project-form.service';
import Sortable, { SortableEvent } from 'sortablejs';
import { SvgIconComponent } from 'angular-svg-icon';
import { ButtonModule } from 'primeng/button';
import { DialogService } from 'primeng/dynamicdialog';
import { MessageService } from 'primeng/api';
import { ToastModule } from 'primeng/toast';
// import { ProjectFormDeletedAttributesModalComponent } from './project-form-deleted-attributes-modal.component';

@Component({
    selector: 'app-project-form-builder',
    templateUrl: './project-form-builder.component.html',
    styleUrls: ['./project-form-builder.component.scss'],
    standalone: true,
    imports: [
        NgClass,
        AsyncPipe,
        ProjectFormAttributeComponent,
        ProjectFormBuilderSectionComponent,
        SvgIconComponent,
        ButtonModule,
        ToastModule
    ],
    providers: [
        DialogService,
        MessageService
    ]
})
export class ProjectFormBuilderComponent {
    
    emptyContainer = viewChild<ElementRef>('empty');
    
    form = inject(ProjectFormService);
    dialogService = inject(DialogService);
    messageService = inject(MessageService);

    formId = input.required<number>();
    child = input<boolean>(false);

    loading = this.form.formLoading;
    selectedAttributeId = this.form.selectedAttributeId;
    errors = this.form.errors;

    attributes = computed<Attribute[]>(() => {
        const formId = this.formId();
        return this.form.attributes().filter(a => a.formId === formId);
    });

    empty = computed(() => this.attributes().length === 0);

    emptySortable: Sortable | undefined;

    @HostBinding('class.child') get isChild() { return this.child() }

    constructor() {
        effect(() => {
            /** If the form has errors on save show and select the first attribute from the errors list */
            const errors = this.errors();
            if (errors && Object.keys(errors).length) {
                const firstErrorId = Object.keys(errors)[0];
                const firstErrorEl = document.querySelector(`[data-aid='${firstErrorId}']`);
                firstErrorEl.scrollIntoView({ behavior: "auto", block: "center" });
                this.form.selectedAttributeId.set(parseInt(firstErrorId))
            }
        }, {
            allowSignalWrites: true
        });

        effect(() => {
            const empty = this.emptyContainer();
            if (empty) {
                this.emptySortable = new Sortable(empty.nativeElement, {
                    group: 'questions',
                    sort: false,
                    onAdd: (event: SortableEvent) => {
                        const questionId: QuestionId = (event as any).originalEvent.dataTransfer.getData('Text');

                        if (questionId) {
                            (event as any).item.parentNode.removeChild(event.item);
                            
                            if (questionId ==='childForm') {
                                /** If trying to add a child form check to see if allowed - maximum four forms deep */
                                if (this.form.limitChildForms(this.formId())) {
                                    return;
                                }
                            }
                            
                            /** Don't allow dropping a divider into an empty form */
                            if (questionId === 'divider') {
                                return;
                            }

                            /** Don't allow geometry questions that are dependent on a location question if no location question in the form */
                            if (questionId === 'areaLookup' || questionId === 'coordinateProjection' || questionId === 'reverseGeolocation') {
                                if (!this.attributes().some(a => a.questionType === 'geometry')) {
                                    this.addGeometryError(questionId);
                                    return;
                                }
                            }
                            
                            const attributeId = this.form.addAttribute(questionId, this.formId(), event.newIndex);
                            this.form.selectAttribute(attributeId);
                        }
                    }
                });
            } else {
                if (this.emptySortable) {
                    this.emptySortable.destroy();
                    this.emptySortable = undefined;
                }
            }
        });
    }

    private addGeometryError(questionId: QuestionId) {
        let question = '';
        if (questionId === 'areaLookup') {
            question = 'Area lookup';
        } else if (questionId === 'coordinateProjection') {
            question = 'Coordinate projection';
        } else if (questionId === 'reverseGeolocation') {
            question = 'Reverse geolocation';
        }
        this.messageService.add({ 
            severity: 'error',
            summary: `Unable to add ${question} question`,
            detail: 'The form requires a Location / Geometry question first.'
        });
    }
}