import { AsyncPipe, CommonModule, DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output, computed, inject, signal, viewChild } from '@angular/core';
import { ApiService } from 'src/app/core';
import { catchError, of, take } from 'rxjs';
import { ButtonModule } from 'primeng/button';
import { DropdownModule } from 'primeng/dropdown';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { AvatarModule } from 'primeng/avatar';
import { MenuModule } from 'primeng/menu';
import { FormsModule } from '@angular/forms';
import { ConfirmationService, MenuItem, MessageService } from 'primeng/api';
import { DateToNowPipe } from 'src/app/shared/pipes/date-to-now.pipe';
import { RecordsService, RecordUpdateData } from '../records.service';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { RecordCommentsComponent } from './comments/record-comments.component';
import { RecordActivityComponent } from './activity/record-activity.component';
import { Geometry } from 'geojson';
import { RecordBreadcrumbComponent } from './breadcrumb/record-breadcrumb.component';
import { RecordMessagesComponent } from './messages/record-messages.component';
import { ToastModule } from 'primeng/toast';
import { RecordSummaryComponent } from './record-summary/record-summary.component';
import { RecordFormComponent } from '../record-form/record-form.component';
import { DialogService } from 'primeng/dynamicdialog';
import { DownZip } from "downzip";
import { SvgIconComponent } from 'angular-svg-icon';

interface ProjectVerificationState {
    projectId: number;
    stateId: number;
    sort: number;
    color: string;
    name: string;
}
export interface VerificationStateMap {
    [key: number]: ProjectVerificationState;
}
export interface RecordDetailViewer {
    id: number;
    role: string;
    memberships: {
        role: 'member' | 'moderator' | 'admin' | 'contributor'; 
        projectId: number; 
    }[];
    organisationMemberships: {
        role: string;
        organisationId: number;
    }[];
    displayName: string;
    imageUrl: string;
}
export interface RecordDetailUser {
    displayName: string;
    username: string;
    imageUrl: string;
    id: number;
}
interface RecordDetailSurvey {
    name: string;
    allowOwnRecordDelete: boolean;
    allowMemberUpdate: boolean;
}
interface RecordDetailAttachment {
    url: string;
    size: number;
    attribute: {
        path: string;
    }
}
interface RecordDetailResponse {
    id: number;
    surveyId: number;
    projectId: number;
    createdAt: string;
    updatedAt: string;
    title: string;
    state: number;
    user: RecordDetailUser;
    survey: RecordDetailSurvey;
    project: { 
        name: string;
        organisationId: number;
        verification: boolean;
        states: ProjectVerificationState[];
        bespoke: boolean;
    }
    attachments: RecordDetailAttachment[];
}
export interface RecordDetailData { 
    id: number;
    createdAt: string;
    title: string;
    surveyId: number;
    surveyName: string;
    projectId: number;
    projectName: string;
    organisationId: number;
    state: number;
    verification: boolean;
    user: RecordDetailUser;
}

type Tab = 'activity' | 'comments' | 'messages';

type ImageDownload = {
    name: string;
    downloadUrl: string;
    size: number;
}

@Component({
    selector: 'app-record-detail',
    templateUrl: './record-detail.component.html',
    imports: [
        CommonModule,
        FormsModule,
        ButtonModule,
        DropdownModule,
        ProgressSpinnerModule,
        AvatarModule,
        MenuModule,
        DatePipe,
        ConfirmDialogModule,
        RecordSummaryComponent,
        RecordFormComponent,
        RecordCommentsComponent,
        RecordActivityComponent,
        RecordMessagesComponent,
        RecordBreadcrumbComponent,
        ToastModule,
        SvgIconComponent
    ],
    providers: [
        ConfirmationService,
        MessageService,
        DialogService
    ],
    standalone: true,
})

export class RecordDetailComponent implements OnInit {

    apiService = inject(ApiService);
    confirmationService = inject(ConfirmationService);
    recordsService = inject(RecordsService);
    messageService = inject(MessageService);
    dialogService = inject(DialogService);

    @Input() recordId: number;

    @Output() record: EventEmitter<any> = new EventEmitter();
    @Output() records: EventEmitter<any> = new EventEmitter();

    recordActivityComponent = viewChild<RecordActivityComponent>('activityTab');
    recordFormComponent = viewChild<RecordFormComponent>('recordForm');

    verificationStateMap = signal<VerificationStateMap | null>(null);
    stateLoading = signal(false);
    verificationStates = signal<ProjectVerificationState[]>([]);
    verificationState = signal<ProjectVerificationState | null>(null);

    recordData = signal<RecordDetailData | null>(null);
    updatedAt = signal<string | null>(null);

    viewer = signal<RecordDetailViewer | null>(null);
    allowOwnRecordDelete = signal<boolean>(false);
    allowMemberUpdate = signal<boolean>(false);

    mapStyleName = signal<string | null>(null);

    editRecord = signal(false);
    submittingRecord = signal(false);

    recordAttachments = signal<RecordDetailAttachment[]>([]);

    mediaFiles = computed<ImageDownload[]>(() =>{
        const attachments = this.recordAttachments();
        const files: ImageDownload[] = attachments.map(a => {
            const { url, attribute } = a;
            const split = url.split('/');
            const filename: string = split[split.length - 1];
            const name = `${attribute.path}-${filename}`;
            return  {
                name,
                downloadUrl: url,
                size: a.size
            }
        });
        return files;
    });

    projectMembership = computed(() => {
        const viewer = this.viewer();
        const record = this.recordData();
        const projectId = record.projectId;
        const memberships = viewer.memberships ?? [];
        return memberships.find(m => m.projectId === projectId);
    });
    isProjectAdmin = computed<boolean>(() => {
        const projectMembership = this.projectMembership();
        if (!!projectMembership) {
            return projectMembership.role === 'admin';
        } else {
            return false;
        }
    });
    isProjectModerator = computed<boolean>(() => {
        const projectMembership = this.projectMembership();
        if (!!projectMembership) {
            return projectMembership.role === 'moderator';
        } else {
            return false;
        }
    });
    isProjectMember = computed<boolean>(() => {
        const projectMembership = this.projectMembership();
        if (!!projectMembership) {
            return projectMembership.role === 'member';
        } else {
            return false;
        }
    });
    organisationMembership = computed(() => {
        const viewer = this.viewer();
        const record = this.recordData();
        const organisationId = record.organisationId;
        const orgMemberships = viewer.organisationMemberships ?? [];
        return orgMemberships.find(m => m.organisationId === organisationId);
    });
    isOrganisationAdminOrOwner = computed<boolean>(() => {
        const organisationMembership = this.organisationMembership();
        if (!!organisationMembership) {
            return organisationMembership.role === 'admin' || organisationMembership.role === 'owner';
        } else {
            return false;
        }
    });
    isSuperAdmin = computed<boolean>(() => {
        const viewer = this.viewer();
        return viewer.role === 'admin';
    });
    allowAccess = computed<boolean>(() => {
        const isSuperAdmin = this.isSuperAdmin();
        const isOrganisationAdminOrOwner = this.isOrganisationAdminOrOwner();
        const isProjectAdmin = this.isProjectAdmin();
        const isProjectModerator = this.isProjectModerator();
        return (
            isSuperAdmin || 
            isOrganisationAdminOrOwner || 
            isProjectAdmin || 
            isProjectModerator
        );
    });
    canDelete = computed<boolean>(() => {
        /** project moderators, project admins, org admins/owners & super admins only */
        /** handle survey allowOwnRecordDelete */
        const allowAccess = this.allowAccess();
        const allowOwnRecordDelete = this.allowOwnRecordDelete();
        const viewer = this.viewer();
        const record = this.recordData();
        const isMember = this.isProjectMember();
        
        if (allowAccess) {
            return true;
        } else if (allowOwnRecordDelete) {
            return viewer.id === record.user.id && isMember;
        } else {
            return false;
        }
    });
    canEdit = computed<boolean>(() => {
        /** project moderators, project admins, org admins/owners & super admins only */
        /** handle survey allowMemberUpdate */
        const allowAccess = this.allowAccess();
        const isMember = this.isProjectMember();
        const allowMemberUpdate = this.allowMemberUpdate();

        if (allowAccess) {
            return true;
        } else if (allowMemberUpdate) {
            return isMember;
        } else {
            return false;
        }
    });

    canClone = computed<boolean>(() => {
        return this.isSuperAdmin();
    });

    showActivityTabs = computed<boolean>(() => {
        /** project moderators, project admins, org admins/owners & super admins only */
        const allowAccess = this.allowAccess();
        return allowAccess;
    });

    bespokeProject = signal(false);

    tabs = computed<Tab[]>(() => {
        const bespoke = this.bespokeProject();
        let tabs: Tab[] = ['activity', 'comments'];
        if (bespoke) {
            tabs = [...tabs, 'messages'];
        }
        return tabs;
    });
    activeTab = signal<Tab>('activity');

    settingsMenuItems = computed<MenuItem[]>(() => {
        const canDelete = this.canDelete();
        const mediaFiles = this.mediaFiles();
        let items: MenuItem[] = [];

        if (this.canClone()) {
            items.push({
                label: 'Clone record',
                command: () => this.cloneRecord()
            });
        }

        if (mediaFiles.length > 0) {
            items.push({
                label: 'Download all media',
                command: () => {
                    this.downloadMedia();
                }
            });
        }
        if (canDelete) {
            items.push({
                label: 'Delete record',
                command: ($event) => {
                    this.checkDeleteRecord($event);
                }
            });
        }

        return items;
    });

    editReady = computed(() => this.recordFormComponent()?.formReady());
    canSave = computed(() => this.recordFormComponent()?.geometryTouched() || this.recordFormComponent()?.formDirty());

    ngOnInit() {    
        this.loadRecord();
    }
    
    public loadRecord() {
        this.getRecordData(this.recordId);
    }

    private getRecordUpdatedAt() {
        const query = `query getRecordUpdatedAt{
            record(id: ${this.recordId}){
                updatedAt
            }
        }`;

        this.apiService.graphql<{ record: { updatedAt: string } }>(query).pipe(
            take(1),
            catchError((e) => {
                console.error(e);
                return of(null);
            })
        ).subscribe(res => {
            if (!!res) {
                this.updatedAt.set(res.record.updatedAt);
            }
        });
    }
    
    private getRecordData(id: number) {
        const query = `query getCoreoRecordData($id: Int){
            record(id: $id){
                id
                surveyId
                projectId
                createdAt
                updatedAt
                title
                state
                user {
                    id
                    displayName
                    username
                    imageUrl
                }
                survey{
                    name
                    allowOwnRecordDelete
                    allowMemberUpdate
                }
                project{
                    name
                    organisationId
                    verification
                    states{
                        projectId
                        stateId
                        sort
                        color
                        name
                    }
                    bespoke
                }
                attachments{
                    url
                    size
                    attribute{
                        path
                    }
                }
            }
            viewer{
                id
                role
                memberships{
                    role
                    projectId
                }
                organisationMemberships{
                    role
                    organisationId
                }
                displayName
                imageUrl
            }
        }`;
        
        return this.apiService.graphql<{ record: RecordDetailResponse; viewer: RecordDetailViewer }>(query, { id }).pipe(
            take(1),
            catchError((e) => {
                console.error(e);
                return of(null);
            })
        ).subscribe(res => {
            if(!!res) {
                const { record, viewer } = res;
                const { createdAt, project, projectId, state, survey, surveyId, title, user, attachments } = record;
                
                this.viewer.set(viewer);
                this.allowMemberUpdate.set(survey.allowMemberUpdate);
                this.allowOwnRecordDelete.set(survey.allowOwnRecordDelete);
                this.updatedAt.set(record.updatedAt);
                const verificationStateMap = this.getVerificationStateMap(project.states);
                this.verificationStateMap.set(verificationStateMap);
                this.verificationState.set(verificationStateMap[record.state]);
                this.verificationStates.set(project.states);
                this.bespokeProject.set(project.bespoke);
                this.recordAttachments.set(attachments);

                const recordData: RecordDetailData = { 
                    id: record.id,
                    createdAt,
                    title,
                    surveyId,
                    surveyName: survey.name,
                    organisationId: project.organisationId,
                    state,
                    verification: project.verification,
                    user,
                    projectId,
                    projectName: project.name,
                };
                this.recordData.set(recordData);
            }
        })
    }

    private getVerificationStateMap(states: ProjectVerificationState[]): VerificationStateMap {
        return states.reduce((acc, state) => {
            return {
                ...acc,
                [state.stateId]: state
            }
        }, {});
    }

    public goToRecord(id: number) {
        this.record.emit(id);
    }

    public goToRecords() {        
        this.records.emit();
    }

    public verificationSateChanged($event) {
        this.stateLoading.set(true);
        const previousState = this.verificationState();
        const map = this.verificationStateMap();

        this.recordsService.updateRecordState(this.recordId, $event.value.stateId).pipe(
            take(1),
            catchError((e) => {
                console.error(e);
                this.verificationState.set({...previousState});
                
                return of(null);
            })
        ).subscribe(state => {
                if (state) {
                    this.verificationState.set({...map[state]})
                } 
                this.stateLoading.set(false);
                /** Update the activity tab to show change */
                if (this.recordActivityComponent()) {
                    this.recordActivityComponent().refresh();
                }
                this.getRecordUpdatedAt();
            }
        );
    }

    public saveRecord($event: Event) {
        const isValid = this.recordFormComponent().recordUpdateDataValid && this.recordFormComponent().geometryValid;
        
        if (isValid) {
            this.updateRecord();
        } else {
            this.confirmationService.confirm({
                target: $event.target as EventTarget,
                message: 'Please fill in all mandatory fields and correct any errors to save your data.<br>Look for red text below fields to identify missing information or errors.',
                header: 'Complete All Required Fields',
                acceptIcon: 'none',
                acceptLabel: 'Ok',
                accept: () => {
                },
                rejectVisible: false,
            });  
        }
    }

    private updateRecord() {
        this.submittingRecord.set(true);
        const recordUpdateData: RecordUpdateData = this.recordFormComponent().formRecordUpdateData;
        const updatedGeometry: Geometry = this.recordFormComponent().updatedGeometry;
        let geometry = undefined;
        /** Only set geometry if it's changed */
        if (this.recordFormComponent().geometryTouched()) {
            geometry = updatedGeometry;
        }

        this.recordsService.updateRecord(recordUpdateData, geometry).pipe(
            take(1),
            catchError((e) => {
                console.error(e);
                this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Could not update record' });
                return of(null);
            })
        ).subscribe(res => {    
            if (!!res) {
                this.editRecord.set(false);
                if (this.recordActivityComponent()) {
                    this.recordActivityComponent().refresh();
                }
                this.updatedAt.set(res.updatedAt);
            }
            this.submittingRecord.set(false);
        });
    }

    private checkDeleteRecord($event) {
        this.confirmationService.confirm({
            target: $event.target as EventTarget,
            message: 'Are you sure you want to delete this record?',
            header: 'Delete record',
            acceptIcon: 'none',
            acceptLabel: 'Delete',
            acceptButtonStyleClass: 'p-button-danger',
            rejectIcon: 'none',
            rejectLabel: 'Cancel',
            rejectButtonStyleClass: 'p-button-text',
            accept: () => {
                this.deleteRecord();
            },
            reject: () => {
            }
        });
    }

    private deleteRecord() {
        this.recordsService.deleteRecord(this.recordId).pipe(
            take(1),
            catchError((e) => {
                console.error(e);
                // show error toast/message?
                return of(null);
            })
        ).subscribe(res => {    
            if (!!res) {
                // Confirmation toast/message?
                /** Go back to records page */
                this.records.emit();
            }
        });
    }

    private cloneRecord() {
        this.recordsService.cloneRecord(this.recordId).pipe(
            take(1),
            catchError((e) => {
                console.error(e);
                return of(null);
            })
        ).subscribe(cloneId => {
            this.messageService.add({ severity: 'success', summary: 'Record Clone' });
            return this.goToRecord(cloneId);
        });
    }

    async initDownZip() {
        const DownZip: any = await import('downzip');
        const downZip: DownZip = new DownZip.default();
        await downZip.register();
        return downZip;
    }

    private async downloadMedia() {
        const files = this.mediaFiles();
        const downloadId = new Date().valueOf();
        const zipFileName = `record-${this.recordId}-media`;

        const downZip = await this.initDownZip();
        
        const downloadUrl = await downZip.downzip(
            downloadId,
            zipFileName,
            files
        );

        window.open(downloadUrl, '_blank');
    }
}