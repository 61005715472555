import { Component, computed, effect, ElementRef, EventEmitter, inject, Input, OnInit, Output, signal, viewChild } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { ApiService } from '@core/services';
import { SvgIconComponent } from 'angular-svg-icon';
import { ConfirmationService, MessageService } from 'primeng/api';
import { ButtonModule } from 'primeng/button';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { DialogService } from 'primeng/dynamicdialog';
import { InputTextModule } from 'primeng/inputtext';
import { catchError, filter, map, of, take, zip } from 'rxjs';
import { z } from 'zod';
import { ProjectFormsItemComponent } from './project-forms-item.component';
import Sortable, { SortableEvent } from 'sortablejs';
import { ProjectFormsCreateComponent } from './project-forms-create.component';
import { ToastModule } from 'primeng/toast';

const AttributeSchema = z.object({
    surveyId: z.number(),
    visible: z.boolean(),
    associatedSurveyId: z.number().nullable(),
    label: z.string().nullable(),
    questionType: z.string().nullable(),
});


const FormSchema = z.object({
    id: z.number(),
    name: z.string(),
    title: z.string(),
    sort: z.number(),
    visible: z.boolean(),
    attributes: AttributeSchema.array()
});

const FormsSchema = FormSchema.array();

export type Attribute = z.infer<typeof AttributeSchema>;
export type Form = z.infer<typeof FormSchema>;
type Forms = z.infer<typeof FormsSchema>;

@Component({
    selector: 'app-project-forms',
    templateUrl: './project-forms.component.html',
    standalone: true,
    imports: [
        InputTextModule,
        ReactiveFormsModule,
        ButtonModule,
        SvgIconComponent,
        ConfirmDialogModule,
        ProjectFormsItemComponent,
        ToastModule
    ],
    providers: [
        DialogService,
        MessageService,
        ConfirmationService
    ],
    styles: [
        `:host { @apply block w-full h-full overflow-hidden;}`
    ],
})

export class ProjectFormsComponent implements OnInit {

    apiService = inject(ApiService);
    confirmationService = inject(ConfirmationService);
    dialogService = inject(DialogService);
    messageService = inject(MessageService);

    @Input() projectId: number;
    @Input() parent: any;

    @Output() form = new EventEmitter<number>();
    @Output() formCreated = new EventEmitter<any>();

    formsList = viewChild<ElementRef>('formsList');

    loading = signal(false);
    sortLoading = signal(false);
    surveys = signal<Form[]>([]); // all project surveys
    search = signal<string>('');

    /** Attributes from all forms/project */
    attributes = computed(() => {
        const surveys = this.surveys();
        return surveys.map(s => s.attributes).flat();
    });

    childFormAttributes = computed(() => {
        const attributes = this.attributes();
        return attributes.filter(a => a.questionType === 'child');
    });

    associatedFormAttributes = computed(() => {
        const attributes = this.attributes();
        return attributes.filter(a => a.questionType === 'association');
    });

    childForms = computed(() => {
        const surveys = this.surveys();
        const childAttributes = this.childFormAttributes();
        const childSurveyIds = childAttributes.map(c => c.associatedSurveyId);
        const childForms = surveys.filter(s => childSurveyIds.includes(s.id));
        return childForms;
    });

    childFormIds = computed(() => this.childForms().map(f => f.id));

    hiddenChildFormIds = computed(() => this.childForms().filter(f => f.visible === false).map(f => f.id));

    /** Forms to display in the list -  all forms except child forms marked as not visible */
    forms = computed(() => {
        const surveys = this.surveys();
        const hiddenChildFormIds = this.hiddenChildFormIds();
        /** Exclude any hidden child forms */
        return surveys.filter(s => !hiddenChildFormIds.includes(s.id));
    });

    /** Forms to display in the list after search */
    formList = computed<Form[]>(() => {
        const forms = this.forms();
        const search = this.search();
        const f = forms
            .filter(s => !search || s.name.toLowerCase().includes(search.toLowerCase()))
            .sort((a, b) => a.sort - b.sort);

        return f;
    });

    canSort = computed(() => {
        return !this.search() && !this.sortLoading();
    });

    searchForm = new FormGroup({
        search: new FormControl('')
    });

    formsSortable: Sortable | undefined;

    constructor() {
        this.searchForm.valueChanges.pipe(takeUntilDestroyed()).subscribe(value => {
            this.search.set(value.search);
        });

        effect(() => {
            const list = this.formsList();
            const canSort = this.canSort();

            if (list) {
                this.formsSortable = new Sortable(list.nativeElement, {
                    group: {
                        name: 'forms',
                        put: false,
                        pull: false,
                        revertClone: false
                    },
                    dataIdAttr: 'data-form',
                    sort: canSort,
                    handle: '.drag-handle',
                    onUpdate: (e: SortableEvent) => {
                        const order = this.formsSortable.toArray();
                        this.updateOrder(order);
                    },
                });
            }
        });
    }

    ngOnInit() {
        this.loadForms();
    }

    private loadForms() {
        this.loading.set(true);

        const query = `query AAGetProjectForms{
            project(id: ${this.projectId}){
                surveys{
                    id
                    name
                    title
                    sort
                    visible
                    attributes{
                        surveyId
                        visible
                        associatedSurveyId
                        label
                        questionType
                    }
                }
            }
        }`;

        this.apiService.graphql<{ project: { surveys: Forms } }>(query).pipe(
            take(1),
            catchError((e) => {
                console.warn(e);
                return of(null);
            })
        ).subscribe(res => {
            if (res) {
                const parsedForms = FormsSchema.parse(res.project.surveys);
                this.surveys.set(parsedForms);
            }
            this.loading.set(false);
        })
    }

    openForm(form: Form) {
        this.form.emit(form.id);
    }

    updateOrder(order: string[]) {
        this.sortLoading.set(true);

        const query = `mutation AAUpdateFormSort($input: SurveyUpdateInput!){
            updateSurvey( input: $input){
                id
            }
         }`;

        const sortObservables = order.map((id, index) => {
            return this.apiService.graphql<{ updateSurvey: { id: number } }>(query, { input: { id: parseInt(id), sort: index } });
        });

        zip(sortObservables).pipe(
            take(1),
            catchError((e) => {
                console.warn(e);
                return of(null);
            })
        ).subscribe(_ => {
            this.sortLoading.set(false);
        });
    }

    private getFormRecordCount(formId: number) {
        const query = `query AAGetFormRecordCount($projectId: Int!, $formId: Int!){
            project(id: $projectId){
                surveys( where: { id: $formId } ) {
                    recordCount
                }
            }
        }`;
        return this.apiService.graphql<{ project: { surveys: { recordCount: number }[] } }>(query, { projectId: this.projectId, formId }).pipe(
            map(res => res.project.surveys[0].recordCount),
            catchError((e) => {
                console.warn(e);
                return of(null);
            })
        );
    }

    deleteFormCheck(form: Form) {
        const surveys = this.surveys();

        this.getFormRecordCount(form.id).subscribe(recordCount => {
            const associatedAttributes = this.associatedFormAttributes().filter(a => a.associatedSurveyId === form.id);
            const childAttributes = this.childFormAttributes().filter(a => a.associatedSurveyId === form.id);
            const associations = [...childAttributes, ...associatedAttributes];

            const associatedAttributesMessage = associations.length > 0 ? `You can not currently delete this form as ${associations.length} question${associations.length === 1 ? '' : 's'} depend${associations.length === 1 ? 's' : ''} on it.<br><br>Delete the following questions from your other forms:<br><br>${associations.map(a => `&bull; <strong>${a.label}</strong> (${surveys.find(s => s.id === a.surveyId).title})<br>`)}` : 'Are you sure you want to delete this form?<br>This cannot be undone.';
            const formattedCount = new Intl.NumberFormat().format(recordCount);
            const recordMessage = `Unable to delete this form as it has ${formattedCount} record${recordCount > 1 ? 's' : ''} associated with it.`;

            const message = recordCount > 0 ? recordMessage : associatedAttributesMessage;

            this.confirmationService.confirm({
                defaultFocus: 'none',
                message,
                header: 'Delete form',
                rejectLabel: 'Cancel',
                rejectIcon: 'none',
                rejectButtonStyleClass: 'p-button p-button-lg p-button-outlined',
                acceptLabel: 'Delete form',
                acceptIcon: 'none',
                acceptButtonStyleClass: 'p-button p-button-lg p-button-danger',
                acceptVisible: associatedAttributes.length === 0 && recordCount === 0,
                accept: () => {
                    this.deleteForm(form);
                }
            });
        });
    }

    deleteForm(form: Form) {
        this.loading.set(true);
        const query = `mutation AADeleteForm($input: deleteSurveyInput!){
            deleteSurvey(input: $input)
        }`;

        this.apiService.graphql<{ deleteSurvey: boolean }>(query, { input: { id: form.id } }).pipe(
            take(1),
            catchError((e) => {
                console.warn(e);
                this.loading.set(false);
                this.messageService.add({ severity: 'danger', summary: 'Error', detail: 'Error deleting form' });
                return of(null);
            })
        ).subscribe(res => {
            if (!!res && res.deleteSurvey) {
                this.loadForms();
            }
        });
    }

    createForm() {
        const ref = this.dialogService.open(ProjectFormsCreateComponent, {
            header: 'Create form',
            modal: true,
            data: {
                projectId: this.projectId,
            },
        });

        ref.onClose.pipe(filter(res => !!res)).subscribe(form => {
            if (form) {
                this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Form created' });
                this.formCreated.emit(form);
                this.form.emit(form.id);
            }
        });
    }
}
