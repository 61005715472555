import { Component, computed, EventEmitter, inject, Output } from '@angular/core';
import { ProjectFormService } from '../project-form.service';
import { Attribute } from '../project-form.model';
import { FormsModule } from '@angular/forms';
import { InputSwitchModule } from 'primeng/inputswitch';

type DatetimeConfig = {
    auto: boolean;
}

@Component({
    selector: 'app-datetime-settings',
    templateUrl: 'datetime-settings.component.html',
    standalone: true,
    imports: [
        FormsModule,
        InputSwitchModule
    ]
})

export class DatetimeSettingsComponent {

    form = inject(ProjectFormService);

    attribute = this.form.selectedAttribute;

    @Output() update: EventEmitter<Partial<Attribute>> = new EventEmitter();

    config = computed(() => {
        const attribute = this.attribute();
        const config = attribute.config as DatetimeConfig;
        return {
            auto: config.auto ?? true, // default to true
        };
    });

    type = computed(() => this.attribute().type);
    includeTime = computed(() => {
        const type = this.type();
        return type === 'datetime';
    });

    auto = computed(() => this.config().auto);

    includeTimeChanged(includeTime: boolean) {
        const type = includeTime ? 'datetime' : 'date';
        const questionType = includeTime ? 'datetime' : 'date';
        this.update.emit({ type, questionType });
    }
}