<form class="w-full p-4 space-y-5">
    <div class="flex flex-col gap-1">
        @if (saved()) {
            <label class="text-sm text-text-primary font-normal" for="collections">For the geometry picker answers you are using:</label>
            <p class="font-semibold">{{ collectionName() }}</p>
        }
        @else {
            <label class="text-sm text-text-primary font-normal">The geometry picker will allow you to pick from pre-existing geometry</label>
            <label class="text-xs text-text-secondary">Select your preloaded geometry collection <sup>*</sup></label>
            <form>
                <p-dropdown [options]="geometryCollections()" [ngModel]="collectionId()" (ngModelChange)="update.emit({collectionId: $event})" name="collections" optionLabel="name" optionValue="id" [showClear]="false" placeholder="Choose a collection" required #collectionControl="ngModel" [ngClass]="{'ng-dirty ng-invalid': collectionControl.hasError('required')}"></p-dropdown>
                @if(collectionControl.hasError('required')){
                    <small class="text-danger">A collection is required</small>
                }
            </form>
        }
    </div>

    <div class="flex items-center justify-center gap-5">
        @if (collectionId()) {
            <p-button styleClass="px-5 w-full" class="w-full" [label]="saved() ? 'Edit selected collection' : 'Edit selected'" size="large" [outlined]="true" (click)="openListModal()"></p-button>
        }
        @if (!saved()) {
            <p-button styleClass="px-5 w-full" class="w-full" [label]="collectionId() ? 'Upload collection' : 'Upload new geometry collection'" size="large" [outlined]="true" (click)="importMenu.toggle($event)"></p-button>
        }
    </div>
    <p-menu #importMenu [popup]="true" [model]="importCollectionMenuItems" appendTo="body">
        <ng-template pTemplate="item" let-item>
            <div class="p-menuitem-link">
                <span>{{ item.label }}</span>
            </div>
        </ng-template>
    </p-menu>
</form>