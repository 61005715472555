<p-toast position="bottom-center" [life]="4000"></p-toast>
<div class="px-4 py-3 border-b-[#2680FF] border-b bg-[#bcd5ff]">
    <h2 class="w-min lg:w-max uppercase font-semibold tracking-tight text-xs">Form Builder</h2>
</div>

<div id="form-questions" class="space-y-4 px-4 py-5 overflow-y-auto no-scrollbar">
    @for(group of questions(); track group.name){
    <div class="form-questions-group space-y-2 w-[70px] lg:w-44 xl:w-full" [ngClass]="group.id" #questions>
        <h3 class="filtered uppercase font-semibold text-xs w-min lg:w-max">{{group.name}}</h3>
        @for(question of group.questions; track question.id){
        <div [class.filtered]="disablePageDivider() && question.type === 'divider'" class="form-question h-9 lg:h-11 xl:h-9 px-[10px] bg-white border rounded cursor-pointer text-xs text-text-primary flex items-center justify-between gap-2" [attr.qid]="question.id" [pTooltip]="question.toolTip" tooltipPosition="right" [positionLeft]="4">
            <svg-icon [src]="question.iconUrl" class="min-w-5 w-5"></svg-icon>
            <span class="is-hidden lg:block flex-grow text-xs text-wrap">{{question.label}}</span>
            @if (question.type === 'divider') {
                <svg-icon src="/assets/icons/grip-vertical.svg" class="text-primary hover:text-primary-darken-5"></svg-icon>
            }
            @else {
                <button (click)="add(question)" class="text-primary hover:text-primary-darken-5">
                    <svg-icon src="/assets/icons/plus.svg" class="block h-5 w-5"></svg-icon>
                </button>
            }
        </div>
        }
    </div>
    }
</div>