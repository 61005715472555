<form class="w-full p-4 space-y-5">
    <div class="flex items-center justify-between gap-2">
        <label class="text-sm text-text-primary font-normal" for="includeTime">
            Include time picker
        </label>
        <p-inputSwitch inputId="includeTime" name="includeTime" [ngModel]="includeTime()" (ngModelChange)="includeTimeChanged($event)" />
    </div>

    <div class="flex items-center justify-between gap-2">
        <label class="text-sm text-text-primary font-normal" for="auto">
            Autocomplete with current date{{ includeTime() ? ' & time' : '' }}
        </label>
        <p-inputSwitch inputId="auto" name="auto" [ngModel]="auto()" (ngModelChange)="update.emit({ config: { auto: $event } })" />
    </div>
</form>