import { ChangeDetectionStrategy, Component, inject, Input, OnInit, signal } from "@angular/core";
import { toSignal } from "@angular/core/rxjs-interop";
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from "@angular/forms";
import { ButtonModule } from "primeng/button";
import { InputTextModule } from "primeng/inputtext";
import { catchError } from "rxjs";
import { GraphqlError } from "src/app/core";
import { AuthService } from "../auth.service";
import { CommonModule } from "@angular/common";

enum ErrorCode {
    ORGANISATION_DOES_NOT_EXIST = 'organisationDoesNotExist',
    ORGANISATION_SSO_DISABLED = 'organisationNotEnabledForSSO',
    ACCOUNT_SSO_DISABLED = 'accountNotSSOEnabled',
    ORGANISATION_SEATS_FULL = 'noFreeOrganisationSeats',
    USER_NOT_ORGANISATION_USER = 'userNotAssociatedWithOrganisation'
};

@Component({
    selector: 'app-sso-login',
    templateUrl: './sso-login.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    styleUrls: ['./sso-login.component.scss'],
    imports: [
        CommonModule,
        ReactiveFormsModule,
        InputTextModule,
        ButtonModule
    ]
})
export class SSOLoginComponent implements OnInit {

    @Input() errorCode: string | null = null;
    @Input() errorDescription: string | null = null;

    fb = inject(FormBuilder);
    auth = inject(AuthService);

    ssoLoginForm: FormGroup = this.fb.group({
        domain: ['', [Validators.required]]
    });

    domain = toSignal(this.ssoLoginForm.get('domain').valueChanges, { initialValue: '' });
    formStatus = toSignal(this.ssoLoginForm.statusChanges, { initialValue: 'VALID' });

    submitting = signal<boolean>(false);
    ssoLoginError = signal<string>('');

    ngOnInit() {
        this.handleError();
    }

    handleError() {
        if (this.errorCode && this.errorDescription) {
            this.ssoLoginError.set(this.errorDescription);
        }
    }

    ssoLogin() {
        this.submitting.set(true);
        this.auth.ssoLogin(this.domain()).pipe(
            catchError((err: GraphqlError) => {
                console.error(err);
                this.ssoLoginError.set(err.message);
                this.submitting.set(false);
                return [];
            })
        ).subscribe(data => {
            if (data.redirectUrl) {
                this.submitting.set(false);
                window.location = data.redirectUrl;
            }
        });
    }
}