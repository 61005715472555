<div class="h-full w-full flex flex-col overflow-hidden">
    <div class="w-full p-5 border-b border-grey-30">
        <div [ngClass]="{'w-full rounded-[4px] border border-grey-border transition-all': true, 'h-11': !commenting, 'h-60': commenting}">
            @if (commenting) {
                <div class="h-full w-full flex flex-col p-5">
                    @if (viewer()) {
                        <div class="flex items-center gap-3">
                            @if (viewer().imageUrl) {
                                <p-avatar [image]="viewer().imageUrl" shape="circle" styleClass="h-10 w-10 text-2xl"></p-avatar>
                            }
                            @else {
                                <p-avatar [label]="viewer().displayName.charAt(0).toUpperCase()" shape="circle" styleClass="h-10 w-10 text-2xl"></p-avatar>
                            }
                            <p class="text-sm text-text-primary font-semibold mb-2">{{ viewer().displayName }}</p>
                        </div>
                    }
                    <div class="grow py-5">
                        <textarea #textArea pInputTextarea [autoResize]="false" rows="4" spellcheck autoCapitalize="on" class="w-full p-0 border-none outline-none ring-0 no-scrollbar resize-none"></textarea>
                    </div>
                    <div class="w-full flex justify-end gap-[10px]">
                        <p-button label="Cancel" [outlined]="true" (click)="cancelComment()"></p-button>
                        <p-button label="Add comment" (click)="postComment()"></p-button>
                    </div>
                </div>
            }
            @else {
                <div (click)="addComment()" class="h-full px-5 flex items-center text-text-secondary cursor-text">
                    <p>Add comment here</p>
                </div>
            }
        </div>
    </div>
    <ul class="grow overflow-y-auto no-scrollbar p-5">
        @for (comment of comments$ | async; track comment) {
            <li class="py-3">
                <div class="flex items-start gap-3 mb-4">
                    @if (comment.user.imageUrl) {
                        <p-avatar [image]="comment.user.imageUrl" shape="circle" styleClass="h-10 w-10 text-2xl"></p-avatar>
                    }
                    @else {
                        <p-avatar [label]="comment.user.displayName.charAt(0).toUpperCase()" shape="circle" styleClass="h-10 w-10 text-2xl"></p-avatar>
                    }
                    <div>
                        <p class="text-sm text-text-primary font-semibold mb-2">{{ comment.user.displayName }}</p>
                        <p class="text-xs font-normal text-text-secondary">{{ comment.createdAt | dateToNow }}</p>
                    </div>
                </div>
                <p class="text-sm text-text-primary">{{ comment.text }}</p>
            </li>
        }
        @empty {
            <li class="text-sm text-text-primary font-semibold">No comments yet</li>
        }
    </ul>
</div>