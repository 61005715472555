import { Component, EventEmitter, OnDestroy, OnInit, Output, computed, inject, input } from "@angular/core";
import { toObservable } from '@angular/core/rxjs-interop';
import { ReactiveFormsModule } from "@angular/forms";
import { ButtonModule } from 'primeng/button';
import { CheckboxModule } from "primeng/checkbox";
import { DropdownModule } from "primeng/dropdown";
import { InputTextModule } from 'primeng/inputtext';
import { TooltipModule } from 'primeng/tooltip';
import { Subject, filter, takeUntil } from "rxjs";
import { MapBuilderStyleEditorComponent } from "./map-builder-style-editor.component";
import { MapBuilderService } from "./map-builder.service";
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ConfirmationService } from 'primeng/api';
import { SliderModule } from "primeng/slider";

@Component({
    selector: 'app-map-builder-editor',
    standalone: true,
    imports: [
        ButtonModule,
        CheckboxModule,
        DropdownModule,
        ReactiveFormsModule,
        SliderModule,
        TooltipModule,
        InputTextModule,
        MapBuilderStyleEditorComponent,
        ConfirmDialogModule
    ],
    templateUrl: './map-builder-editor.component.html',
    providers: [
        ConfirmationService
    ],
    styles: [`
        label {
            @apply text-xs text-text-primary font-normal flex justify-between items-center;
        }
    `]
})
export class MapBuilderEditorComponent implements OnInit, OnDestroy {

    @Output() zoomLayer = new EventEmitter<void>();
    @Output() deleteLayer = new EventEmitter<void>();

    isTestData = input<boolean>();

    mapBuilderService = inject(MapBuilderService);
    confirmationService = inject(ConfirmationService);

    destroy$ = new Subject<void>();

    form = this.mapBuilderService.form;
    selected = this.mapBuilderService.selected;
    selectedDataAttributes = this.mapBuilderService.selectedDataAttributes;

    isForm = computed(() => this.selected()?.type === 'form');
    isLayer = computed(() => !['form', 'collection'].includes(this.selected()?.type));
    isCollection = computed(() => this.selected()?.type === 'collection');
    isGeoJSONLayer = computed(() => this.selected()?.type === 'geojson');

    showDDS = computed(() => this.isForm() && this.mapBuilderService.ddsEnabled());
    canDelete = computed(() => {
        const selected = this.selected();
        if (!selected) {
            return false;
        }
        return selected.type === 'geojson' || selected.type === 'heatmap' || selected.type === 'image';
    });

    selectedLabelAttributes = this.mapBuilderService.selectedLabelAttributes;
    selectedLabelAttribute = this.mapBuilderService.selectedLabelAttribute;
    selectedLabelCollectionAttributes = this.mapBuilderService.selectedLabelCollectionAttributes;

    selected$ = toObservable(this.selected);

    ngOnInit() {
        this.selected$.pipe(
            takeUntil(this.destroy$),
            filter(selected => !!selected)
        ).subscribe(({ config }) => {
            this.form.patchValue({
                // Collection Only
                enabled: config.enabled,

                // Image, GeoJSON, Heatmap Only
                name: config.name,

                // Label Configuration
                labelAttributeId: config['labelAttributeId'] || null,
                labelCollectionAttributeId: config['labelCollectionAttributeId'] || null,
                labelFontSize: config.style?.['labelFontSize'] || 16,
                styleAttributeId: config['styleAttributeId'] || null,

                color: config.style?.color || '#000000',

                // Point Config
                pointStyle: config.style?.pointStyle || null,
                pointRadius: config.style?.pointRadius,
                pointOpacity: config.style?.pointOpacity,
                pointBorderWidth: config.style?.pointBorderWidth,
                pointBorderOpacity: config.style?.pointBorderOpacity,
                pointBorderColor: config.style?.pointBorderColor,

                // Polygon Config
                polygonStyle: config.style?.polygonStyle,
                polygonOpacity: config.style?.polygonOpacity,
                polygonBorderWidth: config.style?.polygonBorderWidth,
                polygonBorderColor: config.style?.polygonBorderColor,
                polygonBorderOpacity: config.style?.polygonBorderOpacity,

                // Linestring Config
                lineStyle: config.style?.lineStyle,
                lineWidth: config.style?.lineWidth
            }, { emitEvent: false });
        });
    }

    del(event: Event) {
        this.confirmationService.confirm({
            target: event.target as EventTarget,
            message: 'Are you sure that you want to delete this layer?',
            header: 'Confirmation',
            icon: 'pi pi-exclamation-triangle',
            acceptIcon: "none",
            rejectIcon: "none",
            rejectButtonStyleClass: "p-button-text",
            accept: () => {
                this.deleteLayer.emit();
            }
        });
    }

    regenerateData() {
        this.mapBuilderService.generateData();
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }
}