import { Component, signal, inject, computed } from "@angular/core";
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { ButtonModule } from "primeng/button";

@Component({
    selector: "app-successful-subscription-modal",
    template: `
        <img class="coreo-logo h-[90px] w-auto mt-12" src="../../../../assets/images/logo.svg" />
        <p class="text-lg text-[#444444] font-semibold">Update Successful</p>
        <p class="text-sm text-[#878F97]" [innerHTML]="message()"></p>
        <p-button type="button" size="large" label="Continue" class="w-full" styleClass="w-full" (click)="ref.close()"></p-button>
    `,
    standalone: true,
    styles: [`
        :host {
            @apply flex flex-col items-center justify-center gap-6;
        }
    `],
    imports: [ButtonModule]
})
export class SuccessfulSubscriptionModalComponent {

    ref = inject(DynamicDialogRef);
    dialogConfig = inject(DynamicDialogConfig);

    tierName = signal<string>('');
    planSeats = signal<number>(0);
    addonSeats = signal<number>(0);

    message = computed<string>(() => {
        if (this.tierName()) {
            return `You are now on the Coreo <strong>${this.tierName()}</strong> Plan`;
        } else if (this.planSeats() && this.addonSeats()) {
            return `
                You have ${this.planSeats() > 0 ? 'added' : 'removed'} 
                <strong>${Math.abs(this.planSeats())} seat${Math.abs(this.planSeats()) > 1 ? 's' : ''}</strong> 
                and ${this.addonSeats() > 0 ? 'added' : 'removed'} 
                <strong>${Math.abs(this.addonSeats())} UKHab licence${Math.abs(this.addonSeats()) > 1 ? 's' : ''}</strong>
                ${this.planSeats() > 0 || this.addonSeats() > 0 ? 'to' : 'from'} your plan
            `;
        } else if (this.planSeats()) {
            return `You have ${this.planSeats() > 0 ? 'added' : 'removed'} 
                <strong>${Math.abs(this.planSeats())} seat${Math.abs(this.planSeats()) > 1 ? 's' : ''}</strong> on your current plan`;
        } else {
            return `You have ${this.addonSeats() > 0 ? 'added' : 'removed'} 
                <strong>${Math.abs(this.addonSeats())} UKHab licence${Math.abs(this.addonSeats()) > 1 ? 's' : ''}</strong>
                ${this.addonSeats() > 0 ? 'to' : 'from'} your plan`;
        }
    });

    ngOnInit() { 
        this.tierName.set(this.dialogConfig.data['tierName']);
        this.planSeats.set(this.dialogConfig.data['planSeats']);
        this.addonSeats.set(this.dialogConfig.data['addonSeats']);
    }

}