<form class="w-full p-4 space-y-5">
    <div class="flex flex-col gap-1">
        <label class="text-sm text-text-primary font-normal" for="calculatedFieldType">Display type <sup>*</sup></label>
        <p-dropdown
            [options]="calculatedFieldDisplayTypes"
            [ngModel]="calculatedFieldType()"
            (ngModelChange)="update.emit({type: $event})"
            name="calculatedFieldType"
            optionLabel="label"
            optionValue="value"
            [showClear]="false"
            placeholder="Choose a display type"
            #typeControl="ngModel"
            required
            [ngClass]="{'ng-dirty ng-invalid': typeControl.hasError('required')}"
        ></p-dropdown>
        @if(typeControl.hasError('required')){
            <small class="text-danger">Display type is required</small>
        }
    </div>

    <p-button label="Manage calculation" [outlined]="true" [disabled]="!calculatedFieldType()" size="large" class="block" (click)="openCalculatedFieldEditor()"></p-button>
</form>