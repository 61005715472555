import { Component, computed, inject, OnInit, signal, viewChild } from '@angular/core';
import { ApiService } from 'src/app/core';
import { InputTextModule } from 'primeng/inputtext';
import { ButtonModule } from 'primeng/button';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { CollectionDetailComponent } from 'src/app/features/collections/collection/collection-detail.component';
import { CollectionItemDetailComponent } from 'src/app/features/collections/collection-item/collection-item-detail.component';
import { CollectionItem } from 'src/app/features/collections/collection.model';
import { CollectionService } from 'src/app/features/collections/collection/collection.service';
import { ProjectFormService } from '../project-form.service';
import { ConfirmationService } from 'primeng/api';
import { ConfirmDialogModule } from 'primeng/confirmdialog';

@Component({
    selector: 'app-list-create-edit-modal',
    templateUrl: 'list-create-edit-modal.component.html',
    standalone: true,
    imports: [
        InputTextModule,
        ButtonModule,
        CollectionDetailComponent,
        CollectionItemDetailComponent,
        ConfirmDialogModule
    ],
    providers: [
        CollectionService,
        ConfirmationService
    ]
})

export class ListCreateEditModalComponent implements OnInit {

    apiService = inject(ApiService);
    dialogRef = inject(DynamicDialogRef);
    dialogConfig = inject(DynamicDialogConfig);
    confirmationService = inject(ConfirmationService);
    collectionService = inject(CollectionService);
    formService = inject(ProjectFormService);

    collectionDetail = viewChild<CollectionDetailComponent>('collectionDetail');

    projectId = this.collectionService.projectId.asReadonly();
    collectionId = this.collectionService.collectionId.asReadonly();
    location = this.collectionService.location.asReadonly();
    mode = this.collectionService.mode.asReadonly();
    newCollectionItems = this.collectionService.newCollectionItems.asReadonly();
    newCollectionAttributes = this.collectionService.newCollectionAttributes.asReadonly();
    newCollectionJob = this.collectionService.newCollectionJob.asReadonly();

    showCollectionItemDetail = signal(false);
    collectionItemId = signal<number>(null);
    selectedNewCollectionItem = signal<CollectionItem>(null);

    collectionDirty = this.collectionService.collectionDirty;
    collectionItemDirty = signal(false);

    showCloseWarning = computed(() => (this.collectionDirty() || this.collectionItemDirty()));

    ngOnInit() { 
        const { data } = this.dialogConfig;

        this.collectionService.projectId.set(data['projectId']);
        this.collectionService.location.set('modal');
        
        if (data['collectionId']) {
            this.collectionService.mode.set('edit');
            this.collectionService.collectionId.set(data['collectionId']);
        } else {
            this.collectionService.mode.set('create');
        }
    }

    itemSelect(id: number) {
        if (this.mode() === 'create') {
            const item = this.newCollectionItems().find(i => i.id === id);
            this.selectedNewCollectionItem.set(item);
        } else if (this.mode() === 'edit') {
            this.collectionItemId.set(id);
        }
        this.showCollectionItemDetail.set(true);
    }

    handleEditItemDelete() {
        this.collectionDetail().refreshItems();
        this.showCollectionItemDetail.set(false);
    }

    updateNewCollectionItem(item: CollectionItem) {
        this.collectionService.updateNewCollectionItem(item);
        this.showCollectionItemDetail.set(false);
    }

    removeNewCollectionItem(id: number) {
        this.collectionService.removeNewCollectionItem(id);
        this.showCollectionItemDetail.set(false);
    }

    async saveNewCollection() {
        const collection = await this.collectionService.saveNewCollection();
    
        if (collection) {
            /** Add collection to form service collections */
            this.formService.collections.update(collections => [...collections, collection]);
            /** Close dialog and pass id back */
            this.dialogRef.close(collection.id);
        }
    }

    closeCheck($event) {
        const collectionId = this.collectionId();
        const job = this.newCollectionJob();
        if (this.showCloseWarning()) {
            this.confirmationService.confirm({
                defaultFocus: 'none',
                message: 'Are you sure?<br>You have unsaved changes which will be lost.',
                target: $event.target,
                header: 'Unsaved changes',
                rejectLabel: 'Cancel',
                rejectIcon: 'none',
                rejectButtonStyleClass: 'p-button p-button-lg p-button-outlined',
                acceptLabel: 'Discard changes',
                acceptIcon: 'none',
                acceptButtonStyleClass: 'p-button p-button-lg p-button-danger',
                accept: () => {
                    this.dialogRef.close({ collectionId, job });
                }
            });
        } else {
            this.dialogRef.close({ collectionId, job });
        }
    }
}