import { z } from 'zod';

export const OrganisationTierAddonSchema = z.object({
    name: z.string(),
    monthlyPrice: z.number().nullable(),
    yearlyPrice: z.number().nullable(),
    monthlyPriceId: z.string().nullable(),
    yearlyPriceId: z.string().nullable(),
    productId: z.string().nullable()
});

export const OrganisationTierSchema = z.object({
    id: z.number().optional(),
    name: z.string(),
    monthlyPrice: z.number().nullable().optional(),
    yearlyPrice: z.number().nullable().optional(),
    monthlyPriceId: z.string().nullable().optional(),
    yearlyPriceId: z.string().nullable().optional(),
    productId: z.string().nullable().optional(),
    addons: OrganisationTierAddonSchema.array().nullable().optional(),
});

export const OrganisationOwnerSchema = z.object({
    id: z.number(),
    displayName: z.string()
});

export const OrganisationSubscriptionScheduleSchema = z.object({
    scheduleStart: z.string(),
    scheduleEnd: z.string(),
    pendingTier: z.string(),
    pendingSeatQuantity: z.number(),
    pendingLicenseQuantity: z.number()
});

export const OrganisationSubscriptionSchema = z.object({
    interval: z.enum(['month', 'year']),
    periodEnd: z.string(),
    periodStart: z.string(),
    isExpired: z.boolean(),
    isCancelled: z.boolean(),
    isPastDue: z.boolean(),
    scheduledChanges: OrganisationSubscriptionScheduleSchema.nullable()
});

export const OrganisationSchema = z.object({
    id: z.number(),
    name: z.string(),
    slug: z.string(),
    seats: z.number(),
    contributorSeats: z.number(),
    organisationTierId: z.number(),
    freeTrialEnd: z.string().nullable(),
    freeTrialExpired: z.boolean().optional(),
    subscriptionId: z.string().nullable(),
    customerId: z.string().nullable(),
    imageUrl: z.string().nullable().optional(),
    subscription: OrganisationSubscriptionSchema.nullable().optional(),
    tier: OrganisationTierSchema.nullable().optional(),
    owner: OrganisationOwnerSchema,
    ukhabSeats: z.number().nullable(),
    renewalDate: z.string().nullable(),
    memberships: z.array(
        z.object({
          id: z.number()
        })
    ),
});

const OrganisationProjectSettingsSchema = z.object({
    id: z.number(),
    name: z.string(),
    slug: z.string(),
    imageUrl: z.string().nullable(),
    hasEmptySeats: z.boolean()
});

export const OrganisationSettingsSchema = z.object({
    id: z.number(),
    name: z.string(),
    slug: z.string(),
    role: z.enum(['owner', 'admin', 'member']),
    seats: z.number(),
    imageUrl: z.string().nullable(),
    ukhabSeats: z.number(),
    customerId: z.string().nullable(),
    subscriptionId: z.string().nullable(),
    contributorCount: z.number(),
    contributorSeats: z.number(),
    freeTrialEnd: z.string().nullable(),
    freeTrialExpired: z.boolean(),
    projects: z.array(OrganisationProjectSettingsSchema),
    renewalDate: z.string().optional(),
    tier: OrganisationTierSchema,
    subscription: OrganisationSubscriptionSchema.nullable()
});

export const OrganisationInfoSchema = z.object({
    organisation: OrganisationSchema,
    subscription: OrganisationSubscriptionSchema.nullable().optional(),
    organisationTiers: OrganisationTierSchema.array().nullable().optional()
});

export type Organisation = z.infer<typeof OrganisationSchema>;
export type OrganisationTier = z.infer<typeof OrganisationTierSchema>;
export type OrganisationTierAddon = z.infer<typeof OrganisationTierAddonSchema>;
export type OrganisationSubscription = z.infer<typeof OrganisationSubscriptionSchema>;
export type OrganisationSettings = z.infer<typeof OrganisationSettingsSchema>;