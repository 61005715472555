import { animate, style, transition, trigger } from "@angular/animations";
import { AsyncPipe, NgClass, NgIf } from "@angular/common";
import { Component, EventEmitter, Input, signal, input, Output } from "@angular/core";
import MapboxGIS from "@natural-apptitude/coreo-mapbox";
import { UIState } from "@natural-apptitude/coreo-mapbox/dist/selectors";
import { SvgIconComponent } from "angular-svg-icon";
import { BehaviorSubject, Subject, fromEvent, takeUntil } from "rxjs";
import { TooltipModule } from 'primeng/tooltip';
// import { ProjectMapToolbarSettingsComponent } from "./project-map-toolbar-settings.component"
export type CreateGeoType = 'Polygon' | 'LineString' | 'Point';

const geoTypesList: {label: CreateGeoType; icon: string;}[] = [
  { label: 'Polygon', icon: 'polygon' },
  { label: 'Point', icon: 'point' },
  { label: 'LineString', icon: 'linestring' }
] as const;

@Component({
  selector: 'app-collection-map-toolbar',
  templateUrl: './collection-map-toolbar.component.html',
  styleUrls: ['./collection-map-toolbar.component.scss'],
  standalone: true,
  imports: [
    SvgIconComponent,
    NgIf,
    NgClass,
    AsyncPipe,
    TooltipModule,
    // ProjectMapToolbarSettingsComponent
  ],
  animations: [
    trigger(
      'enterAnimation', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('500ms', style({ opacity: 1 }))
      ]),
      transition(':leave', [
        style({ opacity: 0 }),
        // animate('100ms', style({ opacity: 0 }))
      ])
    ]
    )
  ]
})
export class CollectionMapToolbarComponent {

  gis = input.required<MapboxGIS>();

  @Output() geoTypeChanged = new EventEmitter<CreateGeoType>();
  // @Output() diffAll = new EventEmitter<void>();
  destroy$ = new Subject<void>();

  canDelete$ = new BehaviorSubject<boolean>(false);
  canComplete$ = new BehaviorSubject<boolean>(false);
  canDraw$ = new BehaviorSubject<boolean>(false);
  canEdit$ = new BehaviorSubject<boolean>(false);
  canCompleteEdit$ = new BehaviorSubject<boolean>(false);
  showCompleteEdit$ = new BehaviorSubject<boolean>(false);
  editing$ = new BehaviorSubject<boolean>(false);
  currentMode$ = new BehaviorSubject<string>('');
  isPolygonSelected$ = new BehaviorSubject<boolean>(false);

  preventOverlaps = false;
  snapping = false;
  drawing = false;

  showTooltipDelay = 500;
  hideTooltipDelay = 300;

  tooltips = {
    snapping: 'When enabled new points will snap to existing features',
    merge: 'Extend the selected feature by drawing and then combining with the original',
    diff: 'Take the difference between the selected feature and the original',
    diffAll: 'Automatically remove all other features from the current feature',
    cutout: 'Cut out a selection from the current feature',
    overlaps: 'When enabled all feature edits will automatically prevent overlaps with other features',
    split: 'Split the selected feature into two separate features',
    draw: 'Use the mouse to free-draw a feature',
    complete: 'Complete the current feature',
    delete: 'Delete the currently selected point or feature'
  }

  geoTypes = geoTypesList;

  currentGeoTypeIndex = signal<number>(0);

  ngOnInit() {
    fromEvent(this.gis(), 'state').pipe(
      takeUntil(this.destroy$)
    ).subscribe((state: any) => this.updateUIState(state));

    this.updateUIState(this.gis().getUIState());
  }

  private updateUIState(state: UIState) {
    this.canDelete$.next(state.canDelete);
    this.canDraw$.next(state.canDraw);
    this.canCompleteEdit$.next(state.canCompleteEdit);
    this.showCompleteEdit$.next(state.editMode !== 'diff');
    this.canEdit$.next(state.canEdit);
    this.editing$.next(!!state.editMode);
    this.canComplete$.next(state.canComplete);
    this.currentMode$.next(state.mode);
    this.drawing = state.drawing;
    this.snapping = state.snapping;
    this.preventOverlaps = state.preventOverlaps;
    this.isPolygonSelected$.next(!!this.gis()?.getActiveFeature());
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  setMode(mode: any) {
    this.gis().startEdit(mode);
  }

  diffAll() {
    this.gis().diffAll();
  }

  toggleOverlaps() {
    this.preventOverlaps = !this.preventOverlaps;
    this.gis().setPreventOverlaps(this.preventOverlaps);
  }

  toggleSnapping() {
    this.snapping = !this.snapping;
    this.gis().setSnapping(this.snapping);
  }

  toggleDrawing() {
    this.drawing = !this.drawing;
    this.drawing ? this.gis().startDraw() : this.gis().cancelDraw();
  }

  complete() {
    this.gis().complete();
  }

  completeEdit() {
    this.gis().completeEdit();
  }

  cancelEdit() {
    this.gis().cancelEdit();
  }

  delete() {
    this.gis().delete();
  }

  changeGeoType(isNext: boolean) {
    this.currentGeoTypeIndex.set((this.currentGeoTypeIndex() + (isNext ? 1 : -1) + this.geoTypes.length) % this.geoTypes.length);
    this.geoTypeChanged.emit(this.geoTypes[this.currentGeoTypeIndex()].label);
  }
}
