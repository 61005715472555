import { ChangeDetectionStrategy, Component, inject } from "@angular/core";
import { ProjectFormService } from "./project-form.service";
import { ButtonModule } from 'primeng/button';
import { DialogService } from "primeng/dynamicdialog";
import { ProjectFormSettingsModalComponent } from "./project-form-settings-modal.component";
import { ToastModule } from "primeng/toast";
import { ConfirmationService, MessageService } from "primeng/api";
import { ConfirmDialogModule } from "primeng/confirmdialog";

@Component({
    selector: 'app-project-form-toolbar',
    standalone: true,
    changeDetection: ChangeDetectionStrategy.OnPush,
    styleUrls: ['./project-form-toolbar.component.scss'],
    templateUrl: './project-form-toolbar.component.html',
    imports: [
        ButtonModule,
        ToastModule,
        ConfirmDialogModule
    ],
    providers: [
        DialogService,
        MessageService,
        ConfirmationService
    ]
})
export class ProjectFormToolbarComponent {

    form = inject(ProjectFormService);
    dialogService = inject(DialogService);
    messageService = inject(MessageService);
    confirmationService = inject(ConfirmationService);

    formSettings = this.form.formSettings;
    formId = this.form.formId;
    dirty = this.form.dirty;

    saveCheck(event: Event) {
        if (this.form.deletedAttributes().length > 0) {
            const deletedAttributes = this.form.deletedAttributes();
            const saved = deletedAttributes.some(a => (a.id > 0));
            let message: string;
            if (saved) {
                message = `You are about to delete ${deletedAttributes.length} question${deletedAttributes.length > 1 ? 's' : ''}${deletedAttributes.length > 1 ? ', some of' : ''} which may have data attached to ${deletedAttributes.length > 1 ? 'them' : 'it'}. By deleting the question${deletedAttributes.length > 1 ? 's' : ''} you will lose any data associated with ${deletedAttributes.length > 1 ? 'them' : 'it'} in this form. After you have saved you will not be able to restore any deleted questions. Would you still like to proceed?`;
            } else {
                message = `You are about to delete ${deletedAttributes.length} question${deletedAttributes.length > 1 ? 's' : ''}. After you have saved you will not be able to restore any deleted questions. Would you still like to proceed?`
            }
            /** Show deleted modal/alert */
            this.confirmationService.confirm({
                target: event.target as EventTarget,
                message,
                header: 'Delete questions?',
                acceptLabel: "Delete",
                rejectLabel: "Cancel",
                acceptIcon: "none",
                rejectIcon: "none",
                acceptButtonStyleClass: "p-button p-button-lg p-button-danger",
                rejectButtonStyleClass: "p-button p-button-lg p-button-outlined",
                defaultFocus: "none",
                closeOnEscape: false,
                accept: () => {
                    this.save();
                }
            })
            return;
        } else {
            this.save();
        }
    }
    save() {
        if (this.form.canSave()) {
            this.form.save();    
        } else {
            this.messageService.add({ severity: 'error', summary: 'Unable to save form', detail: 'The form contains errors. Please complete all fields outlined red and then try again.' });
        }
    }

    settings() {
        const settings = this.formSettings();
        this.dialogService.open(ProjectFormSettingsModalComponent, {
            header: 'Form settings',
            data: {
                isChildForm: false,
                formId: this.formId(),
                settings
            },
            modal: true,
            width: '640px'
        });
    }
}