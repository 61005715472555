import { Component, computed, EventEmitter, inject, OnInit, Output } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ProjectFormService } from '../project-form.service';
import { Attribute } from '../project-form.model';
import { DropdownModule } from 'primeng/dropdown';
import { ApiService } from 'src/app/core';
import { NgClass } from '@angular/common';

type Collection = {
    id: number;
    name: string;
    geometric: boolean;
}

@Component({
    selector: 'app-area-lookup-settings',
    templateUrl: 'area-lookup-settings.component.html',
    standalone: true,
    imports: [
        NgClass,
        FormsModule,
        DropdownModule
    ]
})

export class AreaLookupSettingsComponent {

    form = inject(ProjectFormService);
    apiService = inject(ApiService);

    projectId = this.form.projectId;
    attribute = this.form.selectedAttribute;
    collections = this.form.collections;

    @Output() update: EventEmitter<Partial<Attribute>> = new EventEmitter();

    collectionId = computed(() => this.attribute().collectionId);
    geometryCollections = computed(() => this.collections().filter(c => c.geometric));

    onSelect(id: number) {
        this.update.emit({
            collectionId: id,
            config: {operation: 'within'}
        });
    }
}