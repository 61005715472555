import { Component, EventEmitter, Output, inject } from "@angular/core";
import { SvgIconComponent } from "angular-svg-icon";
import { ButtonModule } from "primeng/button";
import { HomeService } from "./home.service";

@Component({
    selector: 'app-home-empty',
    imports: [
        ButtonModule,
        SvgIconComponent
    ],
    template: `
        @if (homeService.isFavouritesOnly()) {
        <div class="w-full h-full flex flex-col items-center justify-center gap-7">
            <i class="fa-solid fa-star text-6xl text-grey-105"></i>
            <h2 class="text-base font-semibold text-grey-120">You don’t have any favourites saved</h2>
            <p class="text-sm text-center font-normal text-grey-50 w-[330px]">Click the star on a project or folder to add it to your favourites. You can also favourite multiple projects and folders at once</p>
        </div>
        } @else if (homeService.search()) {
        <div class="w-full h-full flex flex-col items-center justify-center gap-7">
            <i class="fa-solid fa-magnifying-glass text-6xl text-primary"></i>
            <h2 class="text-base font-semibold text-grey-120">We couldn't find any projects matching that name.</h2>
            <p class="text-sm font-normal text-grey-50">Please try again with a different project name.</p>
        </div>
        } @else {
        <div class="w-full h-full flex flex-col items-center justify-center">
            <div class="max-w-xs flex items-center justify-center flex-col text-center gap-4">
                <svg-icon class="w-[102px] h-[102px]" src="/assets/icons/placeholder.svg"/>
                <h2 class="text-base font-semibold text-grey-120">There is nothing in this folder yet.</h2>

                @if(homeService.isOrgAdmin()){
                <p class="text-sm font-normal text-grey-50">You can create a project, or move or copy an existing project into this folder.</p>
                <p-button size="large" (click)="newProject.emit()"><svg-icon src="/assets/icons/plus.svg"></svg-icon>New Project</p-button>
                }
            </div>
        </div>
        }
    `,
    standalone: true
})
export class HomeEmptyComponent {
    @Output() newProject = new EventEmitter<void>();


    homeService = inject(HomeService);
}