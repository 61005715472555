import { CommonModule } from '@angular/common';
import { AfterViewInit, Component, computed, ElementRef, EventEmitter, inject, input, OnInit, Output, signal, viewChild } from '@angular/core';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { AvatarModule } from 'primeng/avatar';
import { ButtonModule } from 'primeng/button';
import { DropdownModule } from 'primeng/dropdown';
import { SelectButtonModule } from 'primeng/selectbutton';
import { TableLazyLoadEvent, TableModule, TableRowReorderEvent } from 'primeng/table';
import { IconFieldModule } from 'primeng/iconfield';
import { InputIconModule } from 'primeng/inputicon';
import { catchError, of, take } from 'rxjs';
import { ApiService } from 'src/app/core';
import { InputTextModule } from 'primeng/inputtext';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ConfirmationService, MessageService } from 'primeng/api';
import { debounce } from 'lodash';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { ToastModule } from 'primeng/toast';
import { TooltipModule } from 'primeng/tooltip';
import { ProjectFormService } from 'src/app/features/projects/project-form/project-form.service';
import { CollectionService } from '../collection.service';
import { PanelModule } from 'primeng/panel';
import { CollectionMapComponent } from '../collection-map/collection-map.component';
import { SvgIconComponent } from 'angular-svg-icon';

interface CollectionItemsResponse {
    items: {
        id: number;
        key: string;
        value: string;
        mediaItems: {
            type: string;
            url: string;
            sort: number;
        }[]
    }[],
    itemCount: number;
    sortMode: SortMode;
    sortReverse: boolean;
    sortAttributeId: number;
}

interface FirstLoadResponse extends CollectionItemsResponse {
    attributes: {
        id: number;
        label: string;
    }[]
}

type CollectionTableItem = {
    id: number;
    key: string;
    value: string;
    avatar: string;
}

const sortModes = [
    'alphabetical',
    'manual',
    'attribute'
] as const;
export type SortMode = typeof sortModes[number];

const initialLoadQuery = `query collectionItemsFirstLoad($projectId: Int!, $collectionId: Int!, $offset: Int!, $limit: Int!, $where: SequelizeJSON){
    project(id: $projectId){
        collections(where: { id: $collectionId }){
            items(offset: $offset, limit: $limit, where: $where){
                id
                key
                value
                mediaItems{
                    type
                    url
                    sort
                }
            }
            itemCount(where: $where)
            sortMode
            sortReverse
            sortAttributeId
            attributes {
                id
                label
            }
        }
    }
}`;

const lazyLoadQuery = `query collectionItemsLazyLoad($projectId: Int!, $collectionId: Int!, $offset: Int!, $limit: Int!, $where: SequelizeJSON){
    project(id: $projectId){
        collections(where: {id: $collectionId }){
            items(offset: $offset, limit: $limit, where: $where){
                id
                key
                value
                mediaItems{
                    type
                    url
                    sort
                }
            }
            itemCount(where: $where)
            sortMode
            sortReverse
            sortAttributeId
        }
    }
}`;

@Component({
    selector: 'app-collection-table',
    templateUrl: 'collection-table.component.html',
    imports: [
        CommonModule,
        FormsModule,
        ButtonModule,
        TableModule,
        SelectButtonModule,
        AvatarModule,
        DropdownModule,
        IconFieldModule,
        InputIconModule,
        InputTextModule,
        ConfirmDialogModule,
        OverlayPanelModule,
        ReactiveFormsModule,
        ToastModule,
        TooltipModule,
        PanelModule,
        CollectionMapComponent,
        SvgIconComponent
    ],
    providers: [
        ConfirmationService,
        MessageService,
        ProjectFormService
    ],
    standalone: true,
    styles: [
        `:host{ @apply block h-full w-full; }`
    ],
})

export class CollectionTableComponent implements OnInit, AfterViewInit {

    apiService = inject(ApiService);
    confirmationService = inject(ConfirmationService);
    messageService = inject(MessageService);
    formService = inject(ProjectFormService);
    collectionService = inject(CollectionService);
    
    title = input<string>('Collection items');

    projectId = this.collectionService.projectId.asReadonly();
    collectionId = this.collectionService.collectionId.asReadonly();
    location = this.collectionService.location.asReadonly();
    mode = this.collectionService.mode.asReadonly();
    newCollectionItems = this.collectionService.newCollectionItems;
    newCollectionAttributes = this.collectionService.newCollectionAttributes.asReadonly();
    newCollectionSortMode = this.collectionService.newCollectionSortMode;
    newCollectionSortAttributeId = this.collectionService.newCollectionSortAttributeId;
    newCollectionSortReverse = this.collectionService.newCollectionSortReverse;
    isGeometryCollection = this.collectionService.isGeometryCollection;

    @Output() item: EventEmitter<number> = new EventEmitter();
    @Output() addNewGeometryItem: EventEmitter<void> = new EventEmitter(); 
    
    isFirstLoad = signal(true); /** For existing collections, edit mode */
    loading = signal(false); /** For existing collections, edit mode */
    editCollectionItems = signal<CollectionTableItem[]>([]); /** For existing collections, edit mode */
    editCollectionItemCount = signal(0); /** For existing collections, edit mode */
    editCollectionItemsHasImages = signal(false); /** For existing collections, edit mode */
    editAttributeDropdownOptions = signal<{id: number, label: string}[]>([]); /** For existing collections, edit mode */

    showMap = signal(false);
    mapVisible = computed(() => {
        const showMap = this.showMap();
        const geometryCollection = this.isGeometryCollection();
        return showMap && geometryCollection;
    });

    collection = computed(() => {
        const mode = this.mode();
        if (mode === 'edit') {
            return this.editCollectionItems();
        } else {
            const first = this.first();
            const rows = this.rows();
            const search = this.search();
            let items = this.newCollectionItems();
            const mode = this.newCollectionSortMode();
            const sortAttributeId = this.newCollectionSortAttributeId();
            const reverse = this.newCollectionSortReverse();
            
            if (mode === 'alphabetical') {
                items = items.sort((a, b) => {
                    if (a.value < b.value) {
                        return -1;
                      }
                      if (a.value > b.value) {
                        return 1;
                      }
                      return 0;
                });
            } else if (mode === 'attribute') {
                if (sortAttributeId) {
                    const attribute = this.newCollectionAttributes().find(a => a.id === sortAttributeId);
                    const path = attribute.path;
                    items = items.sort((a, b) => {
                        /** Handle item not having a value for the attribute */
                        if (!a.data?.[path] || !b.data?.[path]) {
                            return 0;
                        }
                        if (a.data[path] < b.data[path]) {
                            return -1;
                          }
                          if (a.data[path] > b.data[path]) {
                            return 1;
                          }
                          return 0;
                    });
                }
            } else if (mode === 'manual') {
                items = items.sort((a, b) => a.sort - b.sort);
            }
            if (reverse) {
               items = items.reverse(); 
            }
            let tableItems: CollectionTableItem[] = items.map(i => {
                const { id, key, value, mediaItems } = i;
                const images = mediaItems?.filter(mi => mi.type.startsWith('image')).sort((a, b) => a.sort - b.sort);
                const avatar = images?.length > 0 ? images[0].url : null;
                return {
                    id,
                    key,
                    value,
                    avatar
                }
            });
            if (search) {
                const rgx = new RegExp(search, 'g');
                return tableItems.filter(i => i.value.match(rgx)).slice(first, first + rows);
            } else {
                return tableItems.slice(first, first + rows);
            }
        }
    });

    itemCount = computed(() => {
        const mode = this.mode();
        if (mode === 'edit') {
            return this.editCollectionItemCount();
        } else {
            const items = this.newCollectionItems();
            const search = this.search();
            if (search) {
                const rgx = new RegExp(search, 'g');
                return items.filter(i => i.value.match(rgx)).length;
            } else {
                return items.length;
            }
        }
    });

    hasImages = computed(() => {
        const mode = this.mode();
        if (mode === 'edit') {
            return this.editCollectionItemsHasImages();
        } else if (mode === 'create') {
            const items = this.newCollectionItems();
            return items.findIndex(i => i.mediaItems?.length > 0) > -1;
        }
    });

    first = signal(0);
    rows = signal(10);

    search = signal('');

    sortOptions = computed<{label: string; value: SortMode, disabled?: boolean}[]>(() => {
        let attributeOptionDisabled = true;
        if (this.mode() === 'create') {
            attributeOptionDisabled = this.newCollectionAttributes().length < 1;
        } else if (this.mode() === 'edit') {
            attributeOptionDisabled = this.editAttributeDropdownOptions().length < 1;
        }
        return [
            { label: 'Alphabetically', value: 'alphabetical' },
            { label: 'Manually', value: 'manual' },
            { label: 'By Attribute', value: 'attribute', disabled: attributeOptionDisabled }
        ]
    });
    collectionSortMode = signal<SortMode>('manual');
    collectionSortAttributeId = signal<number>(null);
    collectionSortReverse = signal(false);
    sortMode = computed(() => {
        const mode = this.mode();
        if (mode === 'edit') {
            return this.collectionSortMode();
        } else if (mode === 'create') {
            return this.newCollectionSortMode();
        }
    });
    sortAttributeId = computed(() => {
        const mode = this.mode();
        if (mode === 'edit') {
            return this.collectionSortAttributeId();
        } else if (mode === 'create') {
            return this.newCollectionSortAttributeId();
        }
    });
    sortReverse = computed(() => {
        const mode = this.mode();
        if (mode === 'edit') {
            return this.collectionSortReverse();
        } else if (mode === 'create') {
            return this.newCollectionSortReverse();
        }
    });
    sortLabel = computed(() => {
        const options = this.sortOptions();
        const mode = this.sortMode();
        return options.find(o => o.value === mode)?.label ?? '';
    });
    
    sortAttributeDropdownOptions = computed<{id: number, label: string}[]>(() => {
        if (this.mode() === 'edit') {
            return this.editAttributeDropdownOptions();
        } else if (this.mode() === 'create') {
            const attributes = this.newCollectionAttributes();
            return attributes.map(a => ({ id: a.id, label: a.label }));
        }
    });

    newItemForm = new FormGroup({
        name: new FormControl('', Validators.required),
        key: new FormControl('', [
            Validators.required,
            Validators.pattern('^[^ ]*$')
        ]),
    });
    duplicateKeyError = signal(false);

    searchInput = viewChild<ElementRef>('searchInput');
    itemValueInput = viewChild<ElementRef>('itemValueInput');
    itemKeyInput = viewChild<ElementRef>('itemKeyInput');

    ngOnInit() {
        /** Used on collection page only not in form builder modal */
        const params = new URLSearchParams(window.location.search);

        if (params.has('offset')) {
            this.first.set(Number(params.get('offset')));
        }
        if (params.has('limit')) {
            this.rows.set(Number(params.get('limit')));
        }
        if (params.has('q')) {
            this.search.set(params.get('q'));
        }
    }
        
    ngAfterViewInit(): void {
        /** Prevent dropping rows into the search input when reordering - results in a 'b' in the search box if allowed because of this: https://github.com/primefaces/primeng/blob/3a2f6c00d38feb1db72cb0ac02ca1fdba1d180e1/src/app/components/table/table.ts#L1945 */
        const inputs = [this.searchInput(), this.itemValueInput(), this.itemKeyInput()];
        for (const input of inputs) {
            input.nativeElement.addEventListener('drop', function(e) {
                e.preventDefault();
                // console.log("Drop event blocked!");
            });
        }
    }

    public loadCollection($event: TableLazyLoadEvent = null) {
        if (this.mode() === 'create') {
            if ($event) {   
                this.first.set($event.first);
                this.rows.set($event.rows);
            }
        } else {
            this.loading.set(true);

            if (!this.isFirstLoad() && $event) {   
                this.first.set($event.first);
                this.rows.set($event.rows);
            }

            if (!this.isFirstLoad()) {
                this.updateSearchParams();
            }

            const where = {and: [
                { value:  { iLike: `%${this.search()}%` }}
            ]};

            const input = { 
                projectId: this.projectId(), 
                collectionId: this.collectionId(), 
                offset: this.first(), 
                limit: this.rows(), 
                where 
            };

            const query = this.isFirstLoad() ? initialLoadQuery : lazyLoadQuery;

            this.apiService.graphql<{ project: { collections: any[] } }>(query, input).pipe(
                take(1),
                catchError((e) => {
                    console.error(e);
                    // show error toast/message?
                    return of(null);
                })
            ).subscribe(res => {
                if (!!res) {         
                    const result = res.project.collections[0] as CollectionItemsResponse;
                    const { items, itemCount, sortMode, sortAttributeId, sortReverse } = result;
                    const collection = items.map(i => {
                        const images = i.mediaItems.filter(mi => mi.type.startsWith('image')).sort((a, b) => a.sort - b.sort);
                        return {
                            id: i.id,
                            key: i.key,
                            value: i.value,
                            avatar: images.length > 0 ? images[0].url : null
                        }
                    });
                    this.editCollectionItems.set(collection);
                    this.editCollectionItemCount.set(itemCount);
                    const hasImages = items.reduce((acc, item) => {
                        const images = item.mediaItems.filter(mi => mi.type.startsWith('image'));
                        if (images.length > 0) {
                            acc.push(images[0]);
                        }
                        return acc;
                    }, []).length > 0;
                    this.editCollectionItemsHasImages.set(hasImages);
                    
                    this.collectionSortMode.set(sortMode);
                    this.collectionSortAttributeId.set(sortAttributeId);
                    this.collectionSortReverse.set(sortReverse);
                    
                    if (this.isFirstLoad()) {
                        const firstLoadResult = res.project.collections[0] as FirstLoadResponse;
                        const { attributes } = firstLoadResult;
                        this.isFirstLoad.set(false);
                        // this.sortOptions.set([
                        //     { label: 'Alphabetically', value: 'alphabetical' },
                        //     { label: 'Manually', value: 'manual' },
                        //     { label: 'By Attribute', value: 'attribute', disabled: attributes.length < 1 }
                        // ]);
                        this.editAttributeDropdownOptions.set(attributes);
                    }
                }
                this.loading.set(false);
            });
        }
    }

    public setKey() {
        if (this.newItemForm.controls.key.dirty) {
            return;
        }
        const value = this.newItemForm.controls.name.value.toLowerCase().trim().replace(/\s+/g, '_');
        this.newItemForm.controls.key.setValue(value);
    }

    public onAddNewItem() {
        if (this.mode() === 'create') {
            const key = this.newItemForm.get('key').value;
            const value = this.newItemForm.get('name').value;
            const id = this.formService.generateId();
            // check for duplicate key
            const keys = this.newCollectionItems().map(item => item.key);
            if (keys.includes(key)) {
                this.duplicateKeyError.set(true);
                this.messageService.add({ severity: 'error', summary: 'Error', detail: 'New item has a duplicate key. Keys must be unique.' });
                return;
            }
            this.collectionService.addNewCollectionItem({ id, key, value });
            this.newItemForm.reset();
        } else {
            /** If existing collection */
            this.loading.set(true);
    
            const query = `mutation addNewCollectionItem($input: ItemCreateInput!){
                createCollectionItem(input: $input){
                    id
                }
            }`;
    
            const input = {
                collectionId: this.collectionId(),
                key: this.newItemForm.get('key').value,
                value: this.newItemForm.get('name').value
            };
    
            this.apiService.graphql<{ createCollectionItem: { id: number } }>(query, { input }).pipe(
                take(1),
                catchError((e) => {
                    console.error(e);
                    this.duplicateKeyError.set(true);
                    this.messageService.add({ severity: 'error', summary: 'Error', detail: `${e.message}` });
                    return of(null);
                })
            ).subscribe(res => {
                if (!!res) {
                    this.loadCollection();
                    this.newItemForm.reset();
                } else {
                    this.loading.set(false);
                }
            });
        }
    }

    public rowSelect(item: CollectionTableItem) {
        this.item.emit(item.id);
    }

    public clearSearch($event: Event) {
        $event.preventDefault();
        $event.stopPropagation();
        this.search.set('');
        this.handleSearch();
    }

    public debouncedSearch = debounce(this.handleSearch, 500);

    private handleSearch() {
        this.first.set(0);
        this.rows.set(10);
        this.updateSearchParams();
        this.loadCollection();
    }

    public updateSortMode(mode: SortMode) {
        this.collectionSortMode.set(mode);
        this.newCollectionSortMode.set(mode);
        this.updateSort();
    }

    public updateSortAttributeId(id: number) {
        this.collectionSortAttributeId.set(id);
        this.newCollectionSortAttributeId.set(id);
        this.updateSort();
    }

    public reverse() {
        const sortReverse = this.sortReverse();
        this.collectionSortReverse.set(!sortReverse);
        this.newCollectionSortReverse.set(!sortReverse);
        this.updateSort();
    }

    public updateSort() {
        if (this.mode() === 'edit') {
            this.loading.set(true);
    
            const query = `mutation updateCollectionSortOrder($input: CollectionUpdateInput!){
                updateCollection(input: $input){
                    id
                }
            }`;
    
            const input = { 
                id: this.collectionId(),
                sortMode: this.sortMode(),
                sortReverse: this.sortReverse(),
                sortAttributeId: this.sortAttributeId()
            };
    
            this.apiService.graphql<{ updateCollection: { collection: { id: number } } }>(query, { input }).pipe(
                take(1),
                catchError((e) => {
                    console.error(e);
                    // show error toast/message?
                    this.loading.set(false);
                    return of(null);
                })
            ).subscribe(res => {
                if (!!res) {
                    // success toast
                    this.loadCollection();
                }
            });
        }
    }

    public reorderRows($event: TableRowReorderEvent) {
        if ($event.dragIndex === $event.dropIndex) {
            return;
        }
        const collectionItems = this.collection();
        if (this.mode() === 'create') {
            for (const [index, item] of collectionItems.entries()) {
                this.newCollectionItems.update(items => {
                    return items.map(i => {
                        if (i.id === item.id) {
                            return {
                                ...i,
                                sort: index + this.first()
                            }
                        } else {
                            return i;
                        }
                    });
                });
            }
        } else if (this.mode() === 'edit') {
            const query = `mutation reorderCollectionItems($input: ItemsUpdateInput!){
                updateCollectionItems(input: $input)
                }`;
                
            const input = {
                /** Add first value to index to allow for page offset when updating the sort order */
                items: collectionItems.map((item, index) => ({id: item.id, sort: index + this.first()}))
            };
            
            this.apiService.graphql<{ updateCollectionItems: boolean }>(query, { input }).pipe(
                take(1),
                catchError((e) => {
                    console.error(e);
                    /** Reload collection as local order will be wrong */
                    this.loadCollection();
                    return of(null);
                })
            ).subscribe();
        }
    }

    public deleteCollectionItemCheck($event: Event, item: CollectionTableItem) {
        $event.preventDefault();
        $event.stopPropagation();

        this.confirmationService.confirm({
            defaultFocus: 'none',
            message: 'Are you sure you want to delete this item?',
            target: $event.target,
            header: 'Delete item',
            rejectLabel: 'Cancel',
            rejectIcon: 'none',
            rejectButtonStyleClass: 'p-button p-button-lg p-button-outlined',
            acceptLabel: 'Delete',
            acceptIcon: 'none',
            acceptButtonStyleClass: 'p-button p-button-lg p-button-danger',
            accept: () => {
                this.deleteCollectionItem(item);
            }
        });
    }

    private deleteCollectionItem(item: CollectionTableItem) {
        if (this.mode() === 'create') {
            this.collectionService.removeNewCollectionItem(item.id);
        } else if (this.mode() === 'edit') {
            this.loading.set(true);
    
            const query = `mutation deleteCollectionItem($input: ItemDeleteInput!){
                deleteCollectionItem(input: $input)
            }`;
    
            const input = {
                id: item.id
            }; 
    
            this.apiService.graphql<{ deleteCollectionItem: number }>(query, { input }).pipe(
                take(1),
                catchError((e) => {
                    console.warn(e);
                    return of(null);
                })
            ).subscribe(res => {
                if (!!res) {
                    this.loadCollection();
                } else {
                    setTimeout(() => {
                        this.confirmationService.confirm({
                            defaultFocus: 'none',
                            message: 'Unable to delete this collection item as it is used in one or more records.',
                            header: 'Unable to delete item',
                            rejectLabel: 'Close',
                            rejectIcon: 'none',
                            rejectButtonStyleClass: 'p-button p-button-lg p-button-outlined',
                            acceptVisible: false,
                        });
                    }, 100);
                }
                this.loading.set(false);
            });
        }
    }

    public refresh(full: boolean = false) {
        if (full) {
            this.isFirstLoad.set(true);
        }
        this.first.set(0);
        this.rows.set(10);
        this.search.set('');
        this.updateSearchParams();
        this.loadCollection();
    }

    private updateSearchParams() {
        if (this.location() === 'page') {
            const searchParams = new URLSearchParams({ offset: `${this.first()}`, limit: `${this.rows()}`, q: this.search() });
            window.history.replaceState({}, '', `${location.pathname}?${searchParams}`);
        }
    }
}