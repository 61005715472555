@if(selected()){
<div class="flex justify-between items-center mb-4">
    <label>Zoom to layer</label>
    <p-button (click)="zoomLayer.emit()">
        <i class="fa-solid fa-magnifying-glass"></i>
    </p-button>
</div>
}
@if(canDelete()){
<div class="flex justify-between items-center mb-4">
    <label>Delete Layer</label>
    <p-button (click)="del($event)" color="danger">
        <i class="fa-solid fa-trash"></i>
    </p-button>
</div>
}
<!-- Form -->
<form [formGroup]="form" class="flex flex-col gap-y-4">
    @if(isCollection()) {
    <div class="flex items-center justify-between">
        <label>Enabled <i
                class="fa-solid fa-circle-exclamation text-sm text-text-secondary hover:text-text-primary cursor-pointer mr-auto ml-2"
                [pTooltip]="tooltipCollectionEnabled" tooltipStyleClass="min-w-[278px] max-w-[278px]"
                tooltipPosition="right"></i></label>
        <p-checkbox formControlName="enabled" [binary]="true" />
    </div>
    }

    @if(isLayer()) {
    <div class="flex flex-col gap-y-4">
        <label>Layer Name</label>
        <input type="text" pInputText formControlName="name" />
    </div>
    }

    @if(showDDS()) {
    <div class="flex flex-col gap-y-4">
        <label>
            Label
            <i class="fa-solid fa-circle-exclamation text-sm text-text-secondary hover:text-text-primary cursor-pointer mr-auto ml-2"
                [pTooltip]="tooltipLabel"
                tooltipStyleClass="min-w-[278px] max-w-[278px]"
                tooltipPosition="right"
            ></i>
        </label>
        <p-dropdown [options]="selectedLabelAttributes()" formControlName="labelAttributeId" optionLabel="label"
            optionValue="id" placeholder="No Label" [showClear]="true"  (onChange)="regenerateData()"/>
    </div>
    @if(selectedLabelAttribute()){
    <div class="flex flex-col gap-y-4">
        <label>Label Font Size</label>
        <p-slider [min]="10" [max]="20" [step]="1" formControlName="labelFontSize" />
    </div>
    }

    @if(selectedLabelCollectionAttributes().length > 0){
    <div class="flex flex-col gap-y-4">
        <label>
            Label Attribute 
            <i class="fa-solid fa-circle-exclamation text-sm text-text-secondary hover:text-text-primary cursor-pointer"
                [pTooltip]="tooltipLabelAttribute"
                tooltipStyleClass="min-w-[278px] max-w-[278px]"
                tooltipPosition="right"
            ></i>
        </label>
        <p-dropdown [options]="selectedLabelCollectionAttributes()" formControlName="labelCollectionAttributeId"
            optionLabel="label" optionValue="id" placeholder="Default Attribute" [showClear]="true" (onChange)="regenerateData()" />
    </div>
    }
<div class="flex flex-col gap-y-4">
    <label>Style Attribute</label>
    <p-dropdown [options]="selectedDataAttributes()" formControlName="styleAttributeId" optionLabel="label"
        optionValue="id" [showClear]="true" placeholder="Default Styling" (onChange)="regenerateData()" />
</div>
    }

    @if (isForm() || isGeoJSONLayer() || (isCollection() && form.get('enabled').value)) {
    <app-map-builder-style-editor [form]="form" />
    }
</form>

<ng-template #tooltipLabel>
    <div class="flex flex-col gap-y-4 text-xs">
        <span>
            Add labels to your map geometry by selecting a collection. The main 
            values (names) of items in the collection will be used as the labels.
        </span>
        <span>
            Once you have selected a collection you may also select a specific 
            attribute to use as a label instead of the value
        </span>
    </div>
</ng-template>

<ng-template #tooltipLabelAttribute>
    <span class="text-xs">
        Select an attribute from the selected collection to use as a label on the map
    </span>
</ng-template>
<ng-template #tooltipCollectionEnabled>
    <span class="text-xs">
        If disabled, the collection will not be displayed as a map layer.
    </span>
<p-confirmDialog />