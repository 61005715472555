<p-toast position="bottom-center" [life]="2000"></p-toast>
<p-dialog header="Create Project" [(visible)]="isVisibleDialog" [modal]="true" #createDialog (onHide)="onHideDialog()">
  <form class="w-96" [formGroup]="createProjectForm">
    <div class="flex flex-col gap-1 mb-4">
      <label for="name">Name</label>
      <input pInputText id="name" formControlName="name" />
      <span class="text-xs font-normal text-grey-50">Must be at least 5 characters</span>
    </div>
    <div class="flex flex-col gap-1 mb-4">
      <label for="name">Description</label>
      <textarea rows="1" pInputTextarea id="description" formControlName="description"></textarea>
    </div>
    <div class="flex flex-col gap-1">
      <label for="owner">Organisation</label>
      <span class="p-fluid">
        <p-autoComplete appendTo="body" inputId="organisation" formControlName="organisation"
          field="name" placeholder="Search organisation by name..." [showClear]="true" [forceSelection]="true"
          [suggestions]="organisations" [showEmptyMessage]="true" (completeMethod)="getOrganisation($event)">
          <ng-template let-organisation pTemplate="item">
            <div class="flex flex-col gap-2">
              <span class="block">{{organisation.name}}</span>
              <span class="block text-sm text-grey-80 italic flex space-between">ID: {{organisation.id}}</span>
            </div>
          </ng-template>
        </p-autoComplete>
      </span>
    </div>
  </form>
  <ng-template pTemplate="footer">
    <p-button [text]="true" size="large" (click)="onHideDialog()">Cancel</p-button>
    <p-button type="submit" [disabled]="!createProjectForm.valid" size="large" (click)="onSubmit()">Create<i class="pi pi-angle-right"></i></p-button>
  </ng-template>
</p-dialog>