<form class="w-full p-4 space-y-5">
    @for (type of geometryTypes; track type) {
        <div class="flex items-center justify-between gap-2">
            <label class="text-sm text-text-primary font-normal capitalize" [for]="type">
                {{ type }}
            </label>
            <p-inputSwitch [inputId]="type" [name]="type" [ngModel]="hasGeometryType(type)" (ngModelChange)="updateTypes(type)" />
        </div>
    }
    <div class="flex items-center justify-between gap-2" [ngClass]="{'opacity-70': !projectHasBounds()}">
        <label class="text-sm text-text-primary font-normal flex items-center justify-between" for="enforceBounds">
            Enforce bounds
            <i class="fa-solid fa-circle-info text-text-secondary" [pTooltip]="tooltip()" tooltipPosition="left"></i>
        </label>
        <p-inputSwitch [disabled]="!projectHasBounds()" inputId="enforceBounds" name="enforceBounds" [ngModel]="enforceBounds()" (ngModelChange)="configChanged({ enforceBounds: $event })" />
    </div>
</form>