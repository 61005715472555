import { Component, Input, Output, EventEmitter, inject } from "@angular/core";
import { NgIf } from '@angular/common';
import { ReactiveFormsModule, FormControl, FormGroup, Validators } from "@angular/forms";
import { ToastModule } from "primeng/toast";
import { MessageService } from "primeng/api";
import { ButtonModule } from 'primeng/button';
import { DialogModule } from 'primeng/dialog';
import { RadioButtonModule } from 'primeng/radiobutton';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { InputTextModule } from 'primeng/inputtext';
import { CheckboxModule } from 'primeng/checkbox';
import { ApiService } from "src/app/core";
import { ProjectCloneUser, ProjectCloneProject, ProjectCloneOrganisation } from 'src/app/core/models/project.model';
import { CoreoJob, JobsService, jobFieldsFragment } from "src/app/core/services/jobs.service";
import { Observable, map, switchMap, tap } from "rxjs";

@Component({
    templateUrl: './project-clone.component.html',
    selector: 'app-project-clone',
    standalone: true,
    imports: [
        NgIf,
        ReactiveFormsModule,
        ToastModule,
        ButtonModule,
        DialogModule,
        InputTextModule,
        CheckboxModule,
        RadioButtonModule,
        AutoCompleteModule
    ],
    providers: [MessageService]
})
export class ProjectCloneComponent {

    @Input() isAdmin: boolean;
    @Input() projectId: number;
    @Input() organisationId: number;

    @Input() isVisibleCloneDialog: boolean = false;
    @Output() visibleCloneChange = new EventEmitter<{ isVisibleCloneDialog: boolean; }>();

    jobsService = inject(JobsService);
    apiService = inject(ApiService);
    messageService = inject(MessageService);

    selectModeForm = new FormGroup({ mode: new FormControl<'clone' | 'merge'>(null) });

    mergeProjectForm = new FormGroup({
        organisation: new FormControl<ProjectCloneOrganisation>(null),
        project: new FormControl<ProjectCloneProject>(null, [Validators.required])
    });
    cloneProjectForm = new FormGroup({
        organisation: new FormControl<ProjectCloneOrganisation>(null),
        user: new FormControl<ProjectCloneUser>(null),
        name: new FormControl<string>('', [Validators.required]),
        locked: new FormControl<boolean>(false, [Validators.required]),
        usersLimit: new FormControl<number>(0, [Validators.required]),
    });

    users: ProjectCloneUser[] | [] = [];
    projects: ProjectCloneProject[] | [] = [];
    organisations: ProjectCloneOrganisation[] | [] = [];

    ngOnInit() {
        if (!this.isAdmin) return;
        this.mergeProjectForm.get('organisation').setValidators(Validators.required);
        this.cloneProjectForm.get('organisation').setValidators(Validators.required);
        this.fieldHandling(this.mergeProjectForm, 'project');
        this.fieldHandling(this.cloneProjectForm, 'user');
    }

    fieldHandling(form: FormGroup, controlName: string) {
        const control = form?.get(controlName);
        if (!control) return;
        control.disable();
        form.get('organisation').valueChanges.subscribe(value => {
            control.reset();
            value ? control.enable() : control.disable();
        });
    }

    getOrganisation(event: { originalEvent: Event; query: string; }) {
        const query = `query CoreoAASearchOrganisations {
      organisations(
        where: { name: { iLike: "%${event.query}%" } },
        limit: 300
      ) { id, name }
    }`;
        return this.apiService.graphql<{ organisations: ProjectCloneOrganisation[] }>(query).subscribe(res => this.organisations = res.organisations);
    }

    getUsers(event: { originalEvent: Event; query: string; }) {
        const organisationId = this.cloneProjectForm.value?.organisation?.id;
        if (!organisationId) return;
        const query = `query CoreoAASearchOrganisationUsers {
      organisation(id: ${organisationId}){
        memberships(where: {
          user: {
            or: [
              { displayName: { iLike: "%${event.query}%" } },
              { username: { iLike: "%${event.query}%" } }
            ]
            deletedAt: { eq: null }
          }
        }
      ) { user { displayName username id } }
      }
    }`;
        return this.apiService.graphql<{ organisation: { memberships: ProjectCloneUser[] } }>(query).subscribe(res => this.users = res.organisation.memberships);
    }

    getProjects(event: { originalEvent: Event; query: string; }) {
        const organisationId = this.isAdmin ? this.mergeProjectForm.value?.organisation?.id : this.organisationId;
        if (!organisationId) return;
        const query = `query CoreoAASearchProjects {
      organisation(id: ${organisationId}) {
        projects (
          where: { name: { iLike: "%${event.query}%"} }
        ) { id, name }
      }
    }`;
        return this.apiService.graphql<{ organisation: { projects: ProjectCloneProject[] } }>(query).subscribe(res => this.projects = res.organisation.projects);
    }

    onSubmitClone() {
        const { organisation, user, name, locked, usersLimit } = this.cloneProjectForm.value;

        if (this.isAdmin && organisation.id && user.user.id) {
            this.adminCloneProject(this.projectId, organisation.id, user.user.id, locked, usersLimit, name);
        } else {
            this.userCloneProject(this.projectId, name);
        }
    }

    onSubmitMerge() {
        const { project } = this.mergeProjectForm.value;
        this.mergeProject(this.projectId, project.id);
    }

    adminCloneProject(projectId, organisationId, ownerId, locked, usersLimit, name) {
        const query = `mutation CoreoAAAdminCloneProject ($input: AdminCloneProjectInput!){
        adminCloneProject(input: $input) { ...jobFields }
        }${jobFieldsFragment}`;
        return this.apiService.graphql(query, { input: { projectId, organisationId, ownerId, locked, usersLimit, name } }).pipe(
            map((response: { adminCloneProject: CoreoJob }) => response.adminCloneProject),
            switchMap(job => {
                this.onHideDialog();
                return this.jobsService.addJob(job);
            })
        ).subscribe({
            next: () => {
                this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Project is cloned' });
            },
            error: (error) => {
                console.error(error);
                this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Project failed to clone' });
            }
        })
    }

    userCloneProject(projectId, name) {
        const query = `mutation CoreoAAUserCloneProject($input: UserCloneProjectInput!){
            userCloneProject(input: $input){...jobFields}
        }${jobFieldsFragment}`;
        return this.apiService.graphql(query, { input: { projectId, name } }).pipe(
            map((response: { userCloneProject: CoreoJob }) => response.userCloneProject),
            switchMap(job => {
                this.onHideDialog();
                return this.jobsService.addJob(job);
            })
        ).subscribe({
            next: () => {
                this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Project is cloned' });
            },
            error: (error) => {
                console.error(error);
                this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Project failed to clone' });
            }
        });
    }

    mergeProject(projectId, targetId) {
        const query = `mutation CoreoAAMergeProject ($input: MergeProjectInput!) {
            mergeProject(input: $input) { ...jobFields }
        }${jobFieldsFragment}`;
        return this.apiService.graphql(query, { input: { projectId, targetId } }).pipe(
            map((response: { mergeProject: CoreoJob }) => response.mergeProject),
            switchMap(job => {
                this.onHideDialog();
                return this.jobsService.addJob(job);
            })
        ).subscribe({
            complete: () => {
                this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Project is copied' });
            },
            error: (error) => {
                console.error(error);
                this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Project failed to copy' });
            }
        });
    }

    onHideDialog() {
        this.isVisibleCloneDialog = false;
        this.visibleCloneChange.emit({ isVisibleCloneDialog: this.isVisibleCloneDialog });
        this.selectModeForm.reset();
        this.mergeProjectForm.reset();
        this.cloneProjectForm.reset();
    }
}
