import { Component } from "@angular/core";
import { ButtonModule } from "primeng/button";

@Component({
    selector: 'free-trial-expired',
    standalone: true,
    template: `
        <img class="h-20 mb-10" src="../../assets/images/logo.svg" />
        <div class="bg-white rounded-lg p-8 flex flex-col gap-4">
            <h1 class="font-extrabold text-2xl text-grey-100 leading-8">Your free trial has ended!</h1>
            <div class="text-grey-80">
                But don't worry. Your projects and data will be retained for a further 30 days. 
                To continue where you left off, upgrade your membership.
            </div>
        </div>
    `,
    styles: [`
        :host { @apply w-[540px] mx-auto flex flex-col items-center justify-center; }
    `],
    imports: [
        ButtonModule
    ]
})

export class FreeTrialExpiredComponent { }